import React from 'react'

// @ts-ignore
import { useStyles } from 'floral'

import { useMediaContext } from 'App/MediaContext'
import { Flex, BaseButton } from 'components/ui'

import s from './style.module.css'

interface CompanySelectStepProps {
  user: any
  onComplete: (user: number) => void
}

interface CompanySelectButtonProps {
  children: string
  onTap: () => void
}

let CompanySelectButton = (props: CompanySelectButtonProps) => {
  let { isMobile } = useMediaContext()
  let styles = (
    props: React.ComponentProps<typeof BaseButton>,
    tapState: {
      isHovered: boolean
    }
  ) => ({
    root: {
      textAlign: 'left',
      fontSize: isMobile ? '1.25rem' : '2.5rem',
      lineHeight: 1.2,
      cursor: 'pointer',
      background: tapState.isHovered ? '#efebff' : 'transparent',
      borderRadius: 6,
      padding: '5px 10px',
      fontWeight: 700,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      WebkitTapHighlightColor: 'transparent',
    },
  })
  return (
    <BaseButton styles={styles} onTap={props.onTap}>
      {props.children}
    </BaseButton>
  )
}

let companySelectStepStyles = (props: CompanySelectStepProps, isMobile: boolean) => ({
  root: {
    maxWidth: '100%',
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: isMobile ? 'auto' : 500,
    maxWidth: isMobile ? 'auto' : 800,
  },
})

let CompanySelectStep = (props: CompanySelectStepProps) => {
  let { user, onComplete } = props
  let { isMobile } = useMediaContext()
  let styles = useStyles(companySelectStepStyles, [props, isMobile])
  return (
    <div style={styles.root}>
      <div className={s.label}>Выберите компанию</div>
      <Flex direction="column" gap="1.5rem" style={styles.list}>
        {user.companies?.map((company: any) => (
          <CompanySelectButton onTap={() => onComplete(company.id)}>
            {company.name}
          </CompanySelectButton>
        ))}
      </Flex>
    </div>
  )
}

export default CompanySelectStep
