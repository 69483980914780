import colorMap from './avatar-colors.json'

export function getInitials(name: string) {
  return !name
    ? ''
    : name
      ?.toUpperCase()
      .replace(/^ООО\s+/, '')
      .replace(/^ИП\s+/, '')
      .replace(/[«»"']/, '')
      .replace(/\s+[-–—]\s+/, ' ')
      .replace(/\./, ' ')
      .replace(/\s+/, ' ')
      .split(' ')
      .slice(0, 2)
      .map((p) => p[0])
      .join('')
}

const colors = Object.values(colorMap)
const colorsCount = colors.length
const cyrillicA = 'а'
const englishA = 'a'

export function getColor(name: string) {
  const fstLetter = name[0].toLowerCase()
  const fstLetterCode = fstLetter.charCodeAt(0)

  if ('ъьё'.includes(fstLetter)) return undefined
  if (fstLetter >= cyrillicA && fstLetter <= 'я')
    // в диапазон не входит ё, но это тут уже не важно
    return colors[Math.round(((fstLetterCode - 'а'.charCodeAt(0)) / 32) * colorsCount)]
  if (fstLetter >= englishA && fstLetter <= 'z')
    // 26 letters range
    return colors[Math.round(((fstLetterCode - 'a'.charCodeAt(0)) / 26) * colorsCount)]

  return undefined
}
