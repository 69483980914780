import * as React from 'react'
import { useMediaContext } from 'App/MediaContext'
import {
  Button,
  Field,
  Flex,
  FormRow,
  H1,
  Input,
  Modal,
  SimpleBackLink,
  useStateToast,
  Tabs,
  Link,
} from 'components/ui'
import {
  ContractorEntity,
  ContractorPaymentMethod,
  RejectedTransactionEntity,
} from 'entities'
import { observer } from 'mobx-react-lite'
import { Entity, initialActionState } from 'mobx/MobxApi'
import { cardMask, cardPlaceholder, createDigitsMask } from 'utils/validations'
import { repeat } from 'lodash'
import { PaymentDetailsFormStore } from 'pages/contractors/ContractorPaymentDetails'
import { runInAction } from 'mobx'

const styles = {
  sub: {
    lineHeight: 1.25,
    color: 'var(--color-secondary)',
    display: 'block',
    marginTop: '.625rem',
    fontSize: 12,
  },
}

const CheckPaymentDetailsForm = observer(
  ({
    store,
    step,
    disabled,
  }: {
    store: PaymentDetailsFormStore
    disabled: boolean
    step: PaymentDetailsModalStep
  }) => {
    let fields

    if (store.kind === 'card' && step !== 'check') {
      fields = (
        <>
          <Field field={store.cardForm.fields.card_number} key="card">
            {({ inputProps, error }) => (
              <FormRow label="Номер карты" size="l" error={error}>
                <Input
                  {...inputProps}
                  numericKeyboard
                  isWide
                  size="l"
                  mask={cardMask}
                  placeholder={cardPlaceholder}
                />
              </FormRow>
            )}
          </Field>
        </>
      )
    } else {
      let form = store.accountForm

      fields = (
        <>
          <Field field={form.fields.rcbic} key="rcbic">
            {({ inputProps, error }) => (
              <FormRow label="БИК" size="l" error={error}>
                <Input
                  {...inputProps}
                  numericKeyboard
                  isWide
                  size="l"
                  mask={createDigitsMask(9)}
                  placeholder={repeat('0', 9)}
                  disabled={disabled}
                  autoFocus
                />
              </FormRow>
            )}
          </Field>
          <Field field={form.fields.account} key="account">
            {({ inputProps, error }) => (
              <FormRow label="Номер счета" size="l" error={error}>
                <Input
                  {...inputProps}
                  numericKeyboard
                  isWide
                  size="l"
                  mask={createDigitsMask(20)}
                  placeholder={repeat('0', 20)}
                  disabled={disabled}
                />
              </FormRow>
            )}
          </Field>
          <Field field={form.fields.name} key="name">
            {({ inputProps, error }) => (
              <FormRow label="ФИО получателя" size="l" error={error}>
                <Input {...inputProps} isWide size="l" disabled={disabled} />
              </FormRow>
            )}
          </Field>
        </>
      )
    }

    let ChangeKind = ({ children }: { children: React.ReactNode }) => <>{children}</>
    if (store.methods.length > 1 && step === 'edit') {
      ChangeKind = ({ children }) => (
        <Tabs
          title="Оплата по"
          options={[
            { value: 'bank_account', label: 'реквизитам счета' },
            { value: 'card', label: 'номеру карты' },
          ]}
          value={store.kind}
          onChange={(val) => {
            runInAction(() => (store.kind = val as ContractorPaymentMethod))
          }}
        >
          {children}
        </Tabs>
      )
    }

    return (
      <ChangeKind>
        <Flex gap="1rem" direction="column">
          {fields}
          {store.commission[store.kind] && (
            <div style={{ fontWeight: 500 }}>{store.commission[store.kind]}</div>
          )}
        </Flex>
      </ChangeKind>
    )
  }
)

export type InvalidPaymentDetailsModalProps = {
  contractor: Entity<ContractorEntity>
  onClose: () => void
  transaction: Entity<RejectedTransactionEntity>
  onSuccess: () => void
}

type PaymentDetailsModalStep = 'check' | 'edit' | 'done'

const mapStepToTitle: {
  [K in PaymentDetailsModalStep]: string
} = {
  check: 'Проверьте ваши реквизиты',
  edit: 'Изменить реквизиты и отправить',
  done: 'Реквизиты обновлены и платеж отправлен',
}

const mapStepToSubtitle: {
  [K in PaymentDetailsModalStep]: string
} = {
  check:
    'Проверьте данные — счет должен принадлежать вам, написание вашего ФИО должно совпадать с тем, как оно указано в банковских реквизитах',
  edit: 'Проверьте данные — счет должен принадлежать вам, написание вашего ФИО должно совпадать с тем, как оно указано в банковских реквизитах',
  done: 'При возникновении проблем, напишите, пожалуйста, в чат поддержки',
}

export const InvalidPaymentDetailsModal = observer(
  ({ contractor, transaction, onClose, onSuccess }: InvalidPaymentDetailsModalProps) => {
    let { isMobile } = useMediaContext()
    let toast = useStateToast()

    // steps
    let [step, setStep] = React.useState<PaymentDetailsModalStep>('check')
    let title = mapStepToTitle[step]
    let subtitle = mapStepToSubtitle[step]
    let goToCheckStep = () => {
      store.accountForm.reset()
      setStep('check')
    }
    let goToEditStep = () => {
      setStep('edit')
    }

    // form
    let { payment_details, payment_methods, full_name } = contractor.data
    let [store] = React.useState(
      () =>
        new PaymentDetailsFormStore({
          details: { ...payment_details, kind: 'bank_account' },
          methods: payment_methods,
          commission: {
            // card: 'Комиссия за перевод на карту — 1% от суммы платежа, минимум 75 ₽',
            card: '',
            bank_account: '',
          },
          recipientName: full_name,
        })
    )
    let [submitState, setSubmitState] = React.useState(initialActionState)
    let submit = async () => {
      const updatePaymentDetails = async () => {
        try {
          const state = contractor.action({
            action: 'payment_details',
            payload: store.payload,
          })
          setSubmitState(state)
          const data = await state
          contractor.setData(data)
          toast.success({ title: 'Реквизиты изменены' })
        } catch (error: any) {
          toast.error({
            title: 'Не удалось изменить реквизиты',
            description: error.message,
          })
          throw error
        }
      }
      const resendMoney = async () => {
        try {
          const state = contractor.create({
            url: 'withdrawals',
            payload: { id: transaction.id },
          })
          setSubmitState(state)
          await state
          toast.success({ title: 'Платеж отправлен' })
        } catch (error: any) {
          toast.error({
            title: 'Ошибка',
            description: error.message,
          })
          throw error
        }
      }

      try {
        await updatePaymentDetails()
        await resendMoney()
        setStep('done')
        onSuccess()
      } catch (error) {}
    }

    let actions =
      step === 'check' ? (
        <>
          <Button onTap={goToEditStep}>Изменить реквизиты</Button>
          <sub style={styles.sub}>
            Please contact us at <Link href='mailto:hello@restaff.pro' target='_blank'>hello@restaff.pro</Link> if you have any issues
          </sub>
        </>
      ) : step === 'done' ? (
        <Button onTap={onClose}>Хорошо</Button>
      ) : (
        <>
          <Button
            isDisabled={!store.isValid}
            isLoading={submitState.state === 'pending'}
            onTap={submit}
          >
            Сохранить и отправить
          </Button>

          <sub style={styles.sub}>
            Please contact us at <Link href='mailto:hello@restaff.pro' target='_blank'>hello@restaff.pro</Link> if you have any issues
          </sub>
        </>
      )

    return (
      <Modal
        isOpen={true}
        onClose={onClose}
        size={isMobile ? '97%' : 600}
        closeOnOverlayClick={false}
      >
        <Flex gap="2rem" direction="column">
          {step === 'edit' && (
            <SimpleBackLink onClick={goToCheckStep}>
              Назад к проверке реквизитов
            </SimpleBackLink>
          )}
          <H1>{title}</H1>
          <p>{subtitle}</p>
          {step !== 'done' && (
            <CheckPaymentDetailsForm
              step={step}
              disabled={step === 'check'}
              store={store}
            />
          )}
          <Flex gap="1rem" direction="column" align="stretch" wrap>
            {actions}
          </Flex>
        </Flex>
      </Modal>
    )
  }
)
