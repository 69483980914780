import React from 'react'
//@ts-ignore
import { useStyles } from 'floral'

import type { FloralProps } from 'components/ui/floral.types'

import { ReactComponent as LogoIcon } from './logo.svg'
import { ReactComponent as LogoWithTextIcon } from './logo_with_text.svg'

interface LogoProps
  extends Omit<React.HTMLProps<HTMLInputElement>, 'size' | 'style'>,
    FloralProps {
  size: number
  withText?: boolean
}

const logoStyles = ({ size, withText }: LogoProps) => ({
  root: { width: withText ? size * 4 : size, height: size },
})

const Logo = (props: LogoProps) => {
  let styles = useStyles(logoStyles, [props])
  return props.withText ? (
    // @ts-ignore
    <LogoWithTextIcon {...props} style={styles.root} />
  ) : (
    // @ts-ignore
    <LogoIcon {...props} style={styles.root} />
  )
}

Logo.defaultProps = { size: 32 }

export default Logo
