// @ts-ignore
import { FormStore, FieldStore } from 'shadowform'

import { useStateToast } from 'components/ui'
import { omit, pickBy } from 'lodash'
import { makeAutoObservable, reaction } from 'mobx'
import { initialActionState, Entity } from 'mobx/MobxApi'
import FileUploader from './FileUploaderStore'
import extraFieldsConfig from '../configs/ExtraFieldsConfig'
import registrationApi from '../api'
import { UserRegistrationEntity } from 'entities'
import UserStore from 'stores/UserStore'
import { setRegistrationData } from '../forms/utils'

export default class DocumentsFormStore {
  constructor(
    documents: {
      caption: string
      icon: string
      is_required: boolean
      label: string
      name: string
      type: string
      default_value?: any
    }[],
    registration: Entity<UserRegistrationEntity>,
    currentUser: UserStore,
    toast: ReturnType<typeof useStateToast>
  ) {
    this.registration = registration
    this.currentUser = currentUser
    this.toast = toast

    // готовим fields и initialValue для формы
    let fields: { [key: string]: any } = {}
    let initialValues: { [key: string]: any } = {}
    documents.forEach(({ name, type, is_required, default_value }) => {
      const { config, initialValue } = extraFieldsConfig[type]
      fields[name] = { ...config, isRequired: is_required, type }
      initialValues[name] = default_value || initialValue
    })

    // создаем форму
    this.form = new FormStore({ fields, initialValues })

    // добавляем события загрузки
    Object.keys(fields).forEach((name: string) => {
      const { type } = fields[name]
      if (type === 'file') {
        let uploader = new FileUploader((file) => this.uploadFile(name, file))
        reaction(
          () => this.form.fields[name].value,
          (file) =>
            uploader.upload(file).then(null, (error: any) => {
              this.toast.error({
                title: 'Не удалось загрузить файл',
                description: error.message,
              })
              this.form.fields[name].setValue(undefined)
            })
        )
        this.form.fields[name].uploader = uploader
      }
    })

    makeAutoObservable(this)
  }

  registration: Entity<UserRegistrationEntity>
  toast: ReturnType<typeof useStateToast>
  currentUser: UserStore
  form: FormStore
  submitState = initialActionState

  uploadFile(name: string, file: File) {
    return registrationApi.uploadDocuments({
      [name]: file,
    })
  }

  formIsLoading(form: FormStore) {
    return Object.values(form.fields).some((f: FieldStore) => f.uploader?.isLoading)
  }

  get isValid() {
    return this.form.isValid && !this.formIsLoading(this.form)
  }

  get isLoading() {
    return this.submitState.state === 'pending'
  }

  getSubmitValues(form: FormStore) {
    return omit(this.form.values, Object.keys(pickBy(form.fields, (f) => f.uploader)))
  }

  get values() {
    let values: { [key: string]: any } = this.getSubmitValues(this.form)
    return values
  }

  submit(additional?: boolean) {
    this.submitState = registrationApi.submitDocuments(this.values, additional)
    this.submitState.then(
      (data: any) => {
        setRegistrationData(this.registration, data, this.currentUser)
      },
      (err: any) => this.toast.error({ title: 'Ошибка', description: err.message })
    )
  }
}
