import WindowWithYametrika from './model/WindowWithYametrika'

const window = global as unknown as WindowWithYametrika

const METRIKA_ID = process.env.REACT_APP_YAMETRIKA_ID || '89644380'

class YametrikaManager {
  private ymIdentify = (kind?: 'contractor' | 'company') => {
    window.ym(METRIKA_ID, 'init', {
      clickmap: true,
      trackLinks: true,
      accurateTrackBounce: true,
      webvisor: true,
    })
    window.ym(METRIKA_ID, 'userParams', {
      id: window.userStore.user?.id,
      kind,
    })
  }

  public insert = (kind?: 'contractor' | 'company') => {
    const interval = setInterval(() => {
      if (window.userStore.user !== undefined && window.ym !== undefined) {
        clearInterval(interval)
        this.ymIdentify(kind)
      }
    }, 250)
  }
}

export const yametrikaManager = new YametrikaManager()

export default yametrikaManager
