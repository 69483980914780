import React, {
  forwardRef,
  useState,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react'
import MaskedInput from 'react-text-mask'
//@ts-ignore
import { useStyles } from 'floral'

import { ReactComponent as ClearIcon } from '@material-design-icons/svg/round/clear.svg'

import IconButton from './IconButton'
import type { FloralProps } from './floral.types'

interface InputProps
  extends Omit<
      React.HTMLProps<HTMLInputElement>,
      'onChange' | 'value' | 'style' | 'size'
    >,
    FloralProps {
  value: string
  children?: React.ReactNode
  onChange?: (value: string) => void
  autofocus?: boolean
  isInvalid?: boolean
  isDisabled?: boolean
  onFocus?: () => void
  onBlur?: () => void
  isMultiline?: boolean
  rows?: number
  maxRows?: number
  allowDecimal?: boolean | undefined
  size?: 'm' | 'l'
  icon?: React.ReactNode
  rightIcon?: React.ReactNode
  isWide?: boolean
  numericKeyboard?: boolean
  isClearable?: boolean
  type?: string
  mask?: any
}

const inputStyles = (props: InputProps, isFocused: boolean) => {
  let root: React.CSSProperties = {
    position: 'relative',
    display: 'flex',
  }

  let borderBottom
  if (props.isDisabled) {
    borderBottom = `1px dotted var(--color-secondary)`
  } else {
    let borderColor = 'var(--color-underline)'
    if (props.isInvalid) {
      borderColor = 'var(--color-red)'
    } else if (isFocused) {
      borderColor = 'var(--konsol-color-outline)'
    }
    let borderWidth = isFocused ? 2 : 1
    borderBottom = `${borderWidth}px solid ${borderColor}`
  }

  let wrapper: React.CSSProperties = {
    display: 'grid',
    borderBottom,
    paddingTop: 4,
    paddingBottom: 4,
    marginBottom: isFocused ? -1 : 0,
    width: '100%',
  }

  let color = props.isDisabled ? 'var(--color-secondary)' : 'auto'
  let input: React.CSSProperties = {
    background: 'transparent',
    margin: 0,
    borderRadius: 0,
    color,
    WebkitTextFillColor: color, // Override iOS font color change
    opacity: 1,
    font: 'inherit',
    gridArea: '1 / 1 / 2 / 2',
    width: '100%',
    resize: 'none',
  }

  let lineHeight = 1.5
  let { rows, maxRows } = props
  let replica: React.CSSProperties = {
    font: 'inherit',
    whiteSpace: 'pre-wrap',
    visibility: 'hidden',
    gridArea: '1 / 1 / 2 / 2',
  }
  if (rows) replica.minHeight = `${rows * lineHeight}rem`
  if (maxRows) replica.maxHeight = `${maxRows * lineHeight}rem`

  if (props.size === 'l') {
    wrapper.fontSize = '1.5rem'
  }

  if (props.isWide) {
    root.width = '100%'
  }

  let icon
  if (props.icon) {
    icon = {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      left: 0,
      width: 24,
      height: 24,
      pointerEvents: 'none',
      fill: 'var(--color-icon)',
    }
    wrapper.paddingLeft = 30
  }

  let clearButton = {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    right: 0,
    borderRadius: 3,
    display: 'flex',
  }
  let clearIcon = { width: 21, height: 21 }

  return { root, wrapper, input, replica, icon, clearButton, clearIcon }
}

const Input = forwardRef((props: InputProps, ref) => {
  const {
    value,
    onChange,
    isDisabled,
    autofocus,
    onFocus,
    onBlur,
    isMultiline,
    icon,
    numericKeyboard,
    isClearable,
    type = 'text',
    mask,
    ...restProps
  } = props
  let [isFocused, setIsFocused] = useState(false)
  let styles = useStyles(inputStyles, [props, isFocused])

  let inputRef = useRef<HTMLInputElement | any>()
  let focus = () => {
    if (mask) inputRef.current?.inputElement?.focus?.()
    else inputRef.current?.focus?.()
  }
  useImperativeHandle(ref, () => ({ focus }), [])
  useEffect(() => {
    if (autofocus) focus()
  }, [])

  let elem = isMultiline ? 'textarea' : mask ? MaskedInput : 'input'
  let elemProps = {
    ref: inputRef,
    value,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
      if (onChange) onChange(e.target.value)
    },
    disabled: isDisabled,
    onFocus: () => {
      setIsFocused(true)
      onFocus && onFocus()
    },
    onBlur: () => {
      setIsFocused(false)
      onBlur && onBlur()
    },
    rows: 1,
    ...restProps,
    style: styles.input,
  }

  if (mask) Object.assign(elemProps, { mask, guide: false })

  if (type) Object.assign(elemProps, { type })

  if (numericKeyboard) {
    elemProps = Object.assign(elemProps, { inputmode: 'numeric', pattern: '[0-9]*' })
  }

  let clearButton
  if (isClearable && !isDisabled) {
    clearButton = value?.length > 0 && (
      <IconButton
        style={styles.clearButton}
        onTap={() => onChange && onChange('')}
        tooltip="Удалить"
      >
        <ClearIcon style={styles.clearIcon} />
      </IconButton>
    )
  }

  return (
    <div style={styles.root}>
      <div style={styles.wrapper}>
        {/* @ts-ignore */}
        {React.createElement(elem, elemProps)}
        {isMultiline && <div style={styles.replica}>{value} </div>}
      </div>
      {icon && <div style={styles.icon}>{icon}</div>}
      {clearButton}
    </div>
  )
})

Input.defaultProps = {
  value: '',
  rows: 1,
  size: 'm',
}

export default Input
