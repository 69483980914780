import React from 'react'
import cl from 'clsx'
import { TypographyVariant } from 'ui-kit'
import { Tab, TabVariant } from 'ui-kit'

import css from './styles.module.css'

export type TabGroupProps = {
  items: TabGroupItem[]
  activeIndex: number
  variant: TabVariant
  titleTabVariant:
    | TypographyVariant.H1
    | TypographyVariant.H2
    | TypographyVariant.H3
    | TypographyVariant.H4
  className?: string
  onClick: (activeIndex: number) => void
}

type TabGroupItem = {
  label: string
  count?: number
}

const TabGroup: React.FunctionComponent<TabGroupProps> = ({
  items,
  activeIndex = 0,
  variant = TabVariant.Page,
  titleTabVariant,
  onClick,
}) => {
  return (
    <div className={cl(css.tabGroup, css[variant])}>
      {items.map(({ label, count }, i) => {
        const handleClick = (i: number) => () => onClick(i)

        return (
          <Tab
            variant={variant}
            titleTabVariant={titleTabVariant}
            label={label}
            count={count}
            active={activeIndex === i}
            onClick={handleClick(i)}
          />
        )
      })}
    </div>
  )
}

export default TabGroup
