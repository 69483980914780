import { rest } from 'msw'

import { json } from './helpers'

const badges = {
  unread_notifications: 1,
  unread_invites: 2,
  unread_tasks: 3,
  acts_for_sign: 4,
  offers_responses: 22,
}

export default [
  rest.get('/api/company/badges', json(badges)),
  rest.get('/api/contractor/badges', json(badges)),
]
