import { fetchApi } from 'api'

export const checkEmail = (email: string) =>
  fetchApi({
    method: 'POST',
    url: 'authorizations/recovery',
    withPrefix: false,
    data: {
      email
    },
  })

export const checkCode = (uuid: string, code: string) =>
  fetchApi({
    method: 'POST',
    url: 'authorizations/recovery_confirmation',
    withPrefix: false,
    data: {
      uuid,
      code
    },
  })