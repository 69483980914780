import React from 'react'
import cx from 'clsx'
import { Button, ButtonSize, ButtonVariant, H4, P1 } from 'ui-kit'
import { ReactComponent as Info } from 'assets/info-icon.svg'

import css from './styles.module.css'

export enum BannerGlobalType {
  Fail = 'fail',
  Progress = 'progress',
  Info = 'info',
}

type BannerGlobalProps = {
  type: BannerGlobalType
  title: string
  description: string
  buttonLabel: string
  className?: string
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

const BannerGlobal: React.FunctionComponent<BannerGlobalProps> = ({
  type,
  title,
  description,
  buttonLabel,
  className,
  onClick,
}) => (
  <div className={cx(css.bannerGlobal, css[type], className && className)}>
    <div className={css.leftWrapper}>
      <Info className={css.icon} />
      <div>
        <H4 className={css.title}>{title}</H4>
        <P1 className={css.description}>{description}</P1>
      </div>
    </div>
    <Button
      variant={ButtonVariant.Tertiary}
      size={ButtonSize.Small}
      label={buttonLabel}
      onClick={onClick}
    ></Button>
  </div>
)

export default BannerGlobal
