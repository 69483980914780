import React from 'react'

import { ListCell, List, ListHeader } from 'components/ui'

import IndicatorListItem from './IndicatorListItem'

import { useMediaContext } from 'App/MediaContext'
import { Flex, Tooltip } from '@chakra-ui/react'

import { ReactComponent as QuestionIcon } from 'assets/question.svg'

const listEntries: IListEntry[] = [
  {
    id: 'mass_registration',
    label: 'Массовая регистрация',
    comment:
      'Исполнители, у которых между датой получения статуса самозанятого и датой заключения договора с компанией прошло менее 7 дней',
  },
  {
    id: 'only_source_of_income',
    label: 'Единственный источник дохода',
    comment: 'Исполнители, у которых нет других источников дохода',
  },
  {
    id: 'two_payments_per_month',
    label: 'Периодичность выплат',
    comment: 'Исполнители, у которых есть платежи дважды в месяц за последние 3 месяца',
  },
  {
    id: 'unique_payments',
    label: 'Одинаковые платежи',
    comment:
      'Исполнители, у которых есть платежи на одну и ту же сумму на протяжении 3-х последних месяцев',
  },
  {
    id: 'working_duration',
    label: 'Продолжительность сотрудничества',
    comment: 'Исполнители, с которыми вы сотрудничаете более 3-х месяцев',
  },
]

interface IListEntry {
  id: string
  label: string
  comment: string
}

type IListData = Record<
  string,
  {
    count: number
    risk_percent: number
  }
>

interface IIndicatorListProps {
  data: IListData
  linkFilters?: {
    name: string
    value: string
  }[]
}

export const IndicatorList = ({ data, linkFilters }: IIndicatorListProps) => {
  const { isMobile } = useMediaContext()

  const items = listEntries.map(({ id, ...other }) => ({
    id,
    percent: data[id].risk_percent,
    quantity: data[id].count,
    ...other,
  }))

  const cols = {
    label: {
      width: (fullWidth: number) => Math.max(fullWidth / 3.8, 255),
    },
    percent: {
      width: (fullWidth: number) => Math.max(fullWidth / 9.6, 101),
    },
    quantity: {
      width: (fullWidth: number) => Math.max(fullWidth / 12, 81),
    },
    comment: {
      width: (fullWidth: number) => Math.max(fullWidth / 2.55, 382),
    },
    contractor: {
      width: (fullWidth: number) => Math.max(fullWidth / 7.8, 125),
    },
  }

  return (
    <List cols={cols}>
      {!isMobile && (
        <ListHeader>
          <ListCell col="label">Критерий</ListCell>
          <ListCell col="percent">
            <Tooltip
              label="Процент исполнителей, по которым сработал риск-критерий"
              placement="bottom"
            >
              <Flex style={{ cursor: 'pointer' }} align="center" gap="2px">
                Процент
                <QuestionIcon
                  style={{
                    height: 12,
                    width: 12,
                  }}
                />
              </Flex>
            </Tooltip>
          </ListCell>
          <ListCell col="quantity">
            <Tooltip
              label="Количество самозанятых исполнителей, которые участвовали в оценке"
              placement="bottom"
            >
              <Flex style={{ cursor: 'pointer' }} align="center" gap="2px">
                Кол-во
                <QuestionIcon
                  style={{
                    height: 12,
                    width: 12,
                  }}
                />
              </Flex>
            </Tooltip>
          </ListCell>
          <ListCell col="comment">Комментарий</ListCell>
          <ListCell col="contractor">Исполнители</ListCell>
        </ListHeader>
      )}
      {items.map((item, idx) => (
        <IndicatorListItem key={idx} {...item} linkFilters={linkFilters} />
      ))}
    </List>
  )
}

export default IndicatorList
