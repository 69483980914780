import { rest } from 'msw'

import { json } from './helpers'

const operations = [
  {
    id: '1',
    title: 'Накопление по акту №209',
    kind: 'deposit',
    amount: '111.11',
    date: '2021-05-31T14:25:30Z',
    items: [
      {
        title: 'На уплату налога по акту от "ООО Яндекс" за 23.05.2021 на сумму 4500.46Р',
        amount: '201.12',
      },
      { title: 'На уплату задолженности', amount: '1201.12' },
    ],
  },
  {
    id: '2',
    title: 'Уплата налогов',
    kind: 'withdrawal',
    amount: '111.11',
    date: '2021-05-31T14:25:30Z',
    items: [],
  },
]

const tax_box = {
  id: '1',
  tax_payment_permission: false,
  savings: {
    amount: '1000.12',
    enough_to_pay_taxes: true,
    text: 'Денег достаточно на оплату налога за май',
  },
  // вместо estimated_taxes
  taxes: {
    total_amount: '1000.32',
    items: [
      {
        title: 'Налог за июнь',
        income_amount: '50000',
        tax_amount: '3012.54',
        due_date: '10.05.2021',
        is_estimated: false, // Ещё не начислен
      },
      {
        title: 'Налог за май',
        income_amount: '50000',
        tax_amount: '3012.54',
        due_date: '10.05.2021',
        is_estimated: true,
      },
    ],
  },
  unpaid_taxes: {
    amount: '1678.77',
    text:
      'До подключения копилки у вас были не оплачены налог за май и задолженность ' +
      'в размере 1678,77₽',
    enabled_withholding: false,
  },
  // operations: [],
  operations,
}

const tax_box_enabled_withholding = {
  ...tax_box,
  unpaid_taxes: {
    ...tax_box.unpaid_taxes,
    enabled_withholding: true,
  },
}

const tax_box_api = [
  rest.get('/api/contractor/tax_boxes/:id', json(tax_box)),

  rest.put(
    '/api/contractor/tax_boxes/:id/send_request',
    json({ status: 'request_sent' })
  ),

  rest.put(
    '/api/contractor/tax_boxes/:id/enable_withholding_for_unpaid_taxes',
    json(tax_box_enabled_withholding)
  ),
  rest.put(
    '/api/contractor/tax_boxes/:id/disable_withholding_for_unpaid_taxes',
    json(tax_box)
  ),

  // tax account
]

export default tax_box_api
// export default tax_account_api

export { tax_box }
