import { useState, useRef, DragEventHandler, useMemo, useEffect } from 'react'

export interface DropHandlers {
  dragenter: DragEventHandler
  dragleave: DragEventHandler
  drop: DragEventHandler
  dragend: DragEventHandler
  dragover: DragEventHandler
}

const useDropZone = (
  onDrop: (files: File[] | null) => void,
  $root: HTMLElement | null
): boolean => {
  const [isOverDropZone, setIsOverDropZone] = useState<boolean>(false)
  const counter = useRef(0)

  useEffect(() => {
    if (!$root) return
    $root.style[('pointer-events' as any) as number] = isOverDropZone ? 'none' : ''
    return () => void ($root.style[('pointer-events' as any) as number] = '')
  }, [isOverDropZone, $root])

  const handlers: DropHandlers | undefined = useMemo(
    () =>
      $root
        ? {
            dragenter(event) {
              event.preventDefault()
              counter.current++
              setIsOverDropZone(true)
            },
            dragleave(event) {
              event.preventDefault()
              counter.current--
              if (counter.current === 0) {
                setIsOverDropZone(false)
              }
            },
            drop(event) {
              event.preventDefault()
              counter.current = 0
              setIsOverDropZone(false)
              event.persist?.()
              const files = Array.from(event?.dataTransfer?.files ?? [])
              onDrop(files.length === 0 ? null : files)
            },
            dragend(event) {
              event.preventDefault()
              counter.current = 0
              setIsOverDropZone(false)
              event.persist?.()
            },
            dragover(event) {
              event.preventDefault()
              event.persist?.()
            },
          }
        : undefined,
    [onDrop, $root]
  )

  useEffect(() => {
    applyAllOnBody(handlers, 'addEventListener')
    return () => void applyAllOnBody(handlers, 'removeEventListener')
  })

  return isOverDropZone
}

const applyAllOnBody = (
  handlers: DropHandlers | undefined,
  action: 'addEventListener' | 'removeEventListener'
) => {
  if (!handlers) return
  Object.entries(handlers).map(([eventName, callback]) =>
    window.document.body[action](eventName, callback)
  )
}

export default useDropZone
