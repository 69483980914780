import React, { useEffect, useState } from 'react'
import cl from 'clsx'
import { Button, ButtonVariant, H2 } from 'ui-kit'
import { ReactComponent as CloseIcon } from 'assets/close.svg'
import css from './styles.module.css'

export type ModalProps = {
  open: boolean
  title: string
  children: React.ReactNode
  onClose: () => void
  className?: string
}

const toggleBodyLimit = (body: HTMLBodyElement, limit: boolean) => {
  if (limit) {
    body.classList.add(css.noScroll)
  } else {
    body.classList.remove(css.noScroll)
  }
}

const Modal: React.FunctionComponent<ModalProps> = ({
  open,
  title,
  children,
  onClose,
  className,
}) => {
  const [internalOpenFlag, setInternalOpenFlag] = useState(false)
  const body = document.getElementsByTagName('body')[0]

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        toggleBodyLimit(body, true)
        setInternalOpenFlag(true)
      }, 100)
    } else {
      setTimeout(() => {
        toggleBodyLimit(body, false)
        setInternalOpenFlag(false)
      }, 100)
    }
  }, [open])

  useEffect(() => {
    return () => {
      toggleBodyLimit(body, false)
    }
  }, [])

  if (open || (!open && internalOpenFlag)) {
    return (
      <div className={cl(css.backdrop, open && internalOpenFlag && css.open)}>
        <div className={cl(css.modal, className && className)}>
          <div className={css.modalHeader}>
            <H2 className={css.modalTitle}>{title}</H2>
            <Button
              variant={ButtonVariant.Tertiary}
              onClick={onClose}
              className={css.close}
            >
              <CloseIcon className={css.closeIcon} />
            </Button>
          </div>
          {children}
        </div>
      </div>
    )
  }

  return null
}

export default Modal
