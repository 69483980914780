import React from 'react'
import cl from 'clsx'
import { ReactComponent as Spinner } from 'assets/spinner.svg'
import { InlineText } from 'ui-kit'

import css from './styles.module.css'

export enum ButtonSize {
  Large = 'large',
  Base = 'base',
  Small = 'small',
  ExtraSmall = 'extraSmall',
}

export enum ButtonVariant {
  Primary = 'primary',
  Secondary = 'secondary',
  Tertiary = 'tertiary',
  Quaternary = 'quaternary',
}

export type ButtonProps = {
  size?: ButtonSize
  variant?: ButtonVariant
  label?: string
  disabled?: boolean
  fullWidth?: boolean
  blocked?: boolean
  className?: string
  children?: React.ReactNode
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

const Button: React.FunctionComponent<ButtonProps> = ({
  size = ButtonSize.Base,
  variant = ButtonVariant.Primary,
  label = '',
  disabled = false,
  fullWidth = false,
  blocked = false,
  className,
  children,
  onClick,
}) => (
  <button
    disabled={disabled}
    className={cl(
      css.button,
      css[size],
      css[variant],
      fullWidth && css.fullWidth,
      blocked && css.blocked,
      className && className
    )}
    onClick={disabled || blocked ? undefined : onClick}
  >
    {children ? children : <InlineText className={css.label}>{label}</InlineText>}
    {blocked && <Spinner className={css.spinner} />}
  </button>
)

export default Button
