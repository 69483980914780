import React, { createContext, useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { makeAutoObservable } from 'mobx'
// @ts-ignore
import { useStyles } from 'floral'
import { Tooltip } from '@chakra-ui/react'
import { omit, last } from 'lodash'

import { ReactComponent as ArrowIcon } from '@material-design-icons/svg/round/expand_more.svg'

import { useMediaContext } from 'App/MediaContext'
import { TaskEntity, ContractorEntity, SubtaskTemplateEntity } from 'entities'
import { useUserStore, useSignDialogStore } from 'stores/context'
import { Entity, Collection } from 'mobx/mobx'
import { printIsoDate, printPeriod, serializeDate } from 'utils/datetime'
import { emdash } from 'utils/typo'

import { Amount } from 'components'
import {
  BaseButton,
  List,
  ListHeader,
  ListItem,
  ListCell,
  Checkbox,
  StatusBadge,
  IconButton,
  Flex,
  DatePicker,
  useStateToast,
  Card,
  CardRow,
  CardValue,
  H3,
} from 'components/ui'
import type { DateRange } from 'components/ui/DatePicker'
import ContractorSelector from 'components/ContractorSelector'
import SubtaskTemplateSelector from 'components/SubtaskList/SubtaskTemplateSelector'

import { SelectedItemsStore, SelectAllCheckbox } from 'components/FilteredList2'

import { ReactComponent as ReceiptIcon } from '@material-design-icons/svg/round/receipt.svg'
import { ReactComponent as ReceiptErrorIcon } from './receipt_error.svg'
import { ReactComponent as ReceiptManyIcon } from './receipt_many.svg'
import { ReactComponent as ErrorIcon } from '@material-design-icons/svg/round/error_outline.svg'

import { getTaskProblems, TaskProblemList } from './taskProblems'
import {
  taskStatusMap,
  // actStatusMap,
  paymentStatusMap
} from './statuses'
import TaskActionsStore from './TaskActionsStore'
import { ListItemSelectionCol } from 'components/ui/List'

export interface Sorting {
  col: string
  direction: 'asc' | 'desc'
}

interface TaskListItemProps {
  task: Entity<TaskEntity>

  url: string

  isSelectable: boolean
  isSelected: boolean
  onToggleSelected?: () => void

  hasCompany: boolean
  hasContractor: boolean
  hasTaskStatus: boolean

  hasDrafts: boolean
  contractors?: Collection<ContractorEntity>
  templates?: Collection<SubtaskTemplateEntity>

  hasDashboardActions: boolean
}

interface TaskListProps {
  tasks: Collection<TaskEntity>

  baseUrl: string

  isSelectable: boolean
  selectedItemsStore?: SelectedItemsStore<TaskEntity>

  isSortable: boolean
  sorting?: Sorting
  onChangeSorting: (sorting: Sorting) => void

  hasCompany: boolean
  hasContractor: boolean
  hasTaskStatus: boolean

  hasDrafts: boolean
  contractors?: Collection<ContractorEntity>
  templates?: Collection<SubtaskTemplateEntity>

  hasDashboardActions: boolean
}

interface SortableListHeaderProps extends React.ComponentProps<typeof ListHeader> {
  sorting?: Sorting
  onChangeSorting: (sorting: Sorting) => void
  sortableCols: { [key: string]: boolean }
}

const SortableListContext = createContext<SortableListHeaderProps | null>(null)

const SortableListHeader = (props: SortableListHeaderProps) => {
  let { sorting, onChangeSorting, sortableCols, ...restProps } = props
  return (
    <SortableListContext.Provider value={{ sorting, onChangeSorting, sortableCols }}>
      <ListHeader {...restProps} />
    </SortableListContext.Provider>
  )
}

const sortableListHeaderCellStyles = (
  props: React.ComponentProps<typeof ListCell>,
  direction: 'asc' | 'desc'
) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  arrow: {
    fill: 'var(--color-icon)',
    transform: `rotate(${direction === 'asc' ? 0 : 180}deg)`,
    flexShrink: 0,
    width: 20,
    height: 20,
  },
  button: {
    borderRadius: 6,
    fontWeight: direction !== undefined ? '600' : 'normal',
    display: 'flex',
    alignItems: 'center',
    textTransform: 'uppercase',
    textAlign: 'start',
  },
})

interface SortableListCellProps extends React.ComponentProps<typeof ListCell> {
  sortingCol?: string
}

const SortableListCell = (props: SortableListCellProps) => {
  let { col, children, ...restProps } = props
  let sortingCol = props.sortingCol ?? col
  let { sorting, onChangeSorting, sortableCols } = useContext(SortableListContext)!
  let direction = sorting && sorting.col === sortingCol ? sorting.direction : undefined
  let styles = useStyles(sortableListHeaderCellStyles, [props, direction])

  let onTap = () => {
    if (sorting && sorting.col === sortingCol) {
      onChangeSorting({
        col: sortingCol,
        direction: sorting.direction === 'asc' ? 'desc' : 'asc',
      })
    } else {
      onChangeSorting({ col: sortingCol!, direction: 'asc' })
    }
  }
  return (
    <ListCell {...restProps} col={col} style={{ ...styles.root }}>
      {sortableCols[col!] ? (
        <BaseButton onTap={onTap} style={styles.button}>
          {children}
          {direction !== undefined && <ArrowIcon style={styles.arrow} />}
        </BaseButton>
      ) : (
        children
      )}
    </ListCell>
  )
}

const taskListItemStyles = {
  icon: {
    display: 'flex',
    height: 21,
    alignItems: 'center',
    fill: 'var(--color-icon)',
  },
  // mobile
  item: {
    padding: 0,
    WebkitTapHighlightColor: 'transparent',
    border: 'none',
  },
  title: { fontSize: 14, lineHeight: '18px', fontWeight: 600, marginRight: 6 },
  amount: {
    color: 'var(--color-active)',
    fontSize: 16,
    lineHeight: '18px',
    fontWeight: 500,
  },
}

const TaskContractor = observer(({ task }: { task: Entity<TaskEntity> }) => {
  let contractor = task.data.contractor
  if (!contractor) return <>{emdash}</>
  let problems = getTaskProblems(task)
  let name = contractor.data.name
  if (problems.length === 0) return <>{name}</>
  let tooltip = <TaskProblemList problems={problems} />
  return (
    <Tooltip label={tooltip} placement="top">
      <Flex gap=".25rem" align="center">
        <ErrorIcon
          style={{ width: 18, height: 18, flexShrink: 0 }}
          fill={'var(--color-red)'}
        />
        <div>{name}</div>
      </Flex>
    </Tooltip>
  )
})

interface DraftStoreOptions {
  task: Entity<TaskEntity>
  contractors: Collection<ContractorEntity>
  templates: Collection<SubtaskTemplateEntity>
  toast: ReturnType<typeof useStateToast>
}

class DraftStore {
  constructor({ task, contractors, templates, toast }: DraftStoreOptions) {
    this.task = task
    this.contractors = contractors
    this.templates = templates
    this.toast = toast
    makeAutoObservable(this)
  }

  task: Entity<TaskEntity>
  contractors: Collection<ContractorEntity>
  templates: Collection<SubtaskTemplateEntity>
  toast: ReturnType<typeof useStateToast>

  updateSubtasks(id: number | null) {
    if (id === null) {
      this.task.update({
        payload: { title: '', subtasks: [] },
        optimistic: true,
      })
    } else {
      let template = this.templates.map[id]
      this.task.update({
        payload: {
          title: template.data.title,
          subtasks: [
            {
              ...omit(template.data, 'id', 'unit'),
              unit_id: template.data.unit.id,
              quantity: 1,
            },
          ],
        },
        clientPayload: {
          title: template.data.title,
          subtasks: [{ ...omit(template.data, 'id'), quantity: 1 }],
        },
        optimistic: true,
      })
    }
  }

  updateContractor(id: number | null) {
    this.task.update({
      payload: { contractor_id: id },
      clientPayload: { contractor: id === null ? null : this.contractors.map[id] },
      optimistic: true,
    })
  }

  updatePeriod([since, upto]: DateRange) {
    this.task.data.since = since ? serializeDate(since) : undefined
    this.task.data.upto = upto ? serializeDate(upto) : undefined
    if (since && upto) {
      this.task.update({
        payload: { since: this.task.data.since, upto: this.task.data.upto },
      })
    }
  }
}

const buttonSelectStyles = {
  valueContainer: (base: any) => ({
    ...base,
    padding: '0 4px',
  }),
  control: (base: any, state: any) => ({
    ...base,
    border: 'none',
    borderRadius: 6,
    background: state.isFocused ? 'rgba(0,0,0,.1)' : 'transparent',
    boxShadow: 'none',
    cursor: 'pointer',
    flexWrap: 'no-wrap',
    '&:hover': {
      background: 'rgba(0,0,0,.1)',
    },
  }),
  dropdownIndicator: () => ({ display: 'none' }),
  container: (base: any) => ({ ...base, fontWeight: 'normal', left: -5 }),
}

const TaskListItem = observer((props: TaskListItemProps) => {
  let {
    url,
    task,
    isSelectable,
    isSelected,
    onToggleSelected,
    hasCompany,
    hasContractor,
    hasTaskStatus,
    contractors,
    templates,
  } = props
  let { isMobile } = useMediaContext()
  let currentUser = useUserStore()
  let toast = useStateToast()
  let styles = taskListItemStyles

  let isEditableDraft = false
  // isEditable &&
  // task.data.status === 'draft' &&
  // currentUser.hasAbility('tasks.update')
  let [draftStore] = useState(() =>
    isEditableDraft
      ? new DraftStore({
          task,
          contractors: contractors!,
          templates: templates!,
          toast,
        })
      : undefined
  )
  let signDialog = useSignDialogStore()
  let [actionsStore] = useState(
    () => new TaskActionsStore({ currentUser, task, toast, signDialog })
  )

  let docNumber = task.data.document_number?.number

  let company = hasCompany && task.data.company.data.name

  let contractor
  if (hasContractor) {
    contractor = isEditableDraft ? (
      <div onClick={(e) => e.preventDefault()}>
        <ContractorSelector
          contractors={contractors!}
          value={task.data.contractor ? task.data.contractor.id : undefined}
          // onChange={(id) => draftStore.updateContractor(id)}
          onChange={() => {}}
          placeholder="Исполнитель…"
          menuPosition="fixed"
          styles={buttonSelectStyles}
        />
      </div>
    ) : (
      <TaskContractor task={task} />
    )
  }

  let title
  if (isEditableDraft) {
    title = (
      <div onClick={(e) => e.preventDefault()}>
        <SubtaskTemplateSelector
          templates={templates!}
          onChange={(id) => draftStore!.updateSubtasks(id)}
          value={
            task.data.title == null || task.data.title === ''
              ? undefined
              : { label: task.data.title }
          }
          placeholder="Вид работы…"
          styles={buttonSelectStyles}
        />
      </div>
    )
  } else {
    title = task.data.title == null || task.data.title === '' ? '–' : task.data.title
  }

  let period
  if (isEditableDraft) {
    period = (
      <div onClick={(e) => e.preventDefault()}>
        <DatePicker
          value={
            task.data.since
              ? [
                  new Date(task.data.since),
                  // @ts-ignore
                  task.data.upto ? new Date(task.data.upto) : null,
                ]
              : undefined
          }
          placeholder="Период…"
          design="button"
          selectsRange={true}
          onChange={(range) => draftStore!.updatePeriod(range as DateRange)}
          minDate={undefined}
          style={{ left: -5 }}
        />
      </div>
    )
  } else if (task.data.since && task.data.upto) {
    period = printPeriod(task.data.since, task.data.upto, false)
  } else {
    period = '–'
  }

  let amount = task.data.display_amount && <Amount value={task.data.display_amount} />

  let taskStatus = hasTaskStatus && task.data.task && (
    <StatusBadge status={task.data.task!.status} map={taskStatusMap} />
  )

  // let actStatus = <StatusBadge status={task.data.act.status} map={actStatusMap} />

  let receiptIcon
  if (task.data.multi_receipts && task.data.fns_receipts.length > 1) {
    receiptIcon = task.data.fns_receipts.length > 0 && (
      <div onClick={(e) => e.stopPropagation()}>
        <IconButton as="a" style={styles.icon} href={`${url}#receipts`} tooltip="Чеки">
          <ReceiptManyIcon style={{ width: 20, height: 20 }} />
        </IconButton>
      </div>
    )
  } else {
    let receipt = last(task.data.fns_receipts)
    receiptIcon = receipt && (
      <div onClick={(e) => e.stopPropagation()}>
        <IconButton
          as="a"
          style={styles.icon}
          href={receipt.preview_url}
          target="_blank"
          tooltip={receipt.status === 'annulled' ? 'Чек (аннулирован)' : 'Чек'}
        >
          {receipt.status === 'annulled' ? (
            <ReceiptErrorIcon style={{ width: 24, height: 24 }} />
          ) : (
            <ReceiptIcon style={{ width: 20, height: 20 }} />
          )}
        </IconButton>
      </div>
    )
  }

  let paymentStatus = (
    <Flex gap=".25rem">
      <StatusBadge status={task.data.payment.status} map={paymentStatusMap} />
      {receiptIcon}
      {task.data.payment.error && (
        <div style={styles.icon}>
          <Tooltip label={task.data.payment.error} placement="top" aria-label="button">
            <ErrorIcon fill={'var(--color-red)'} />
          </Tooltip>
        </div>
      )}
    </Flex>
  )

  if (isMobile) {
    return (
      <ListItem hover={false} padding={false} as={Link} to={url} style={styles.item}>
        {isSelectable && (
          <ListItemSelectionCol value={isSelected!} onClick={onToggleSelected!} />
        )}
        <Card withShadow kind="secondary" style={{ flex: 1 }}>
          <Flex direction="column" gap="6px">
            <CardRow justify="space-between">
              <CardValue label={period} />
              {docNumber && (
                <CardValue label={`${hasTaskStatus ? 'Задание' : 'Акт'} №${docNumber}`} />
              )}
            </CardRow>
            <CardRow wrap={false} justify="space-between">
              <H3 style={styles.title}>{title}</H3>
              <H3 style={styles.amount}>{amount}</H3>
            </CardRow>
            {hasContractor && (
              <CardRow>
                <CardValue
                  label="Исполнитель:"
                  variant="active"
                  value={<p>{contractor}</p>}
                />
              </CardRow>
            )}
            <CardRow>
              {/* <CardValue
                label="Акт:"
                value={<StatusBadge status={task.data.act.status} map={actStatusMap} />}
              /> */}
              <CardValue
                label="Платёж:"
                value={
                  <StatusBadge status={task.data.payment.status} map={paymentStatusMap} />
                }
              />
              {hasTaskStatus && task.data.task && (
                <CardValue
                  label="Задание:"
                  value={
                    <StatusBadge status={task.data.task!.status} map={taskStatusMap} />
                  }
                />
              )}
            </CardRow>
          </Flex>
          {actionsStore.render()}
        </Card>
      </ListItem>
    )
  }

  return (
    <ListItem as={Link} to={url} wrap>
      <ListCell row style={{ alignItems: isEditableDraft ? 'center' : 'start' }}>
        {isSelectable && (
          <ListCell col="selection">
            <div onClick={(e) => e.preventDefault()}>
              <Checkbox value={isSelected!} onChange={onToggleSelected!} />
            </div>
          </ListCell>
        )}
        <ListCell col="number">
          {docNumber && (
            <div
              style={{
                fontSize: docNumber > 9999 ? '.85rem' : '1rem',
                lineHeight: '1.5rem',
              }}
            >
              {docNumber}
            </div>
          )}
        </ListCell>
        <ListCell col="title">{title}</ListCell>
        {hasContractor && (
          <ListCell
            col="contractor"
            style={{
              overflow: isEditableDraft ? 'visible' : 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {contractor}
          </ListCell>
        )}
        {hasCompany && <ListCell col="company">{company}</ListCell>}
        <ListCell col="period">{period}</ListCell>
        {!hasTaskStatus && (
          <ListCell col="actDate">{printIsoDate(task.data.act.date, false)}</ListCell>
        )}
        <ListCell col="amount">{amount}</ListCell>
        {hasTaskStatus && <ListCell col="taskStatus">{taskStatus}</ListCell>}
        {/* <ListCell col="actStatus">{actStatus}</ListCell> */}
        <ListCell col="paymentStatus">{paymentStatus}</ListCell>
      </ListCell>
      {actionsStore.render()}
    </ListItem>
  )
})

const taskListCols: any = {
  selection: { width: 20 },
  number: { width: 40, style: { color: 'var(--color-secondary)' } },
  title: { grow: 4, style: { overflowWrap: 'anywhere' } },
  contractor: { grow: 3 },
  company: { grow: 3 },
  period: { width: 135 },
  actDate: { width: 90 },
  amount: {
    width: 100,
    style: { textAlign: 'right', justifyContent: 'flex-end', paddingRight: '.5rem' },
  },
  taskStatus: { width: 100 },
  // actStatus: { width: 100 },
  paymentStatus: { width: 120, style: { display: 'flex' } },
}

const TaskList = observer((props: TaskListProps) => {
  let { isMobile } = useMediaContext()
  let {
    baseUrl,
    tasks,
    hasCompany,
    hasContractor,
    hasTaskStatus,
    hasDrafts,
    contractors,
    templates,
    isSortable,
    sorting,
    onChangeSorting,
    isSelectable,
    selectedItemsStore,
    hasDashboardActions,
  } = props

  let header = !isMobile && (
    <SortableListHeader
      sorting={sorting}
      onChangeSorting={onChangeSorting}
      sortableCols={
        isSortable
          ? {
              number: true,
              contractor: true,
              title: true,
              company: true,
              period: true,
              amount: true,
              taskStatus: true,
              actDate: true,
              // actStatus: true,
              paymentStatus: true,
            }
          : {}
      }
    >
      {isSelectable && (
        <SortableListCell col="selection">
          <SelectAllCheckbox store={selectedItemsStore!} />
        </SortableListCell>
      )}
      <SortableListCell col="number">№</SortableListCell>
      <SortableListCell col="title">Название</SortableListCell>
      {hasCompany && <SortableListCell col="company">Заказчик</SortableListCell>}
      {hasContractor && <SortableListCell col="contractor">Исполнитель</SortableListCell>}
      <SortableListCell col="period">Период</SortableListCell>
      {!hasTaskStatus && (
        <SortableListCell col="actDate" sortingCol="act_date">
          Дата акта
        </SortableListCell>
      )}
      <SortableListCell col="amount">Стоимость</SortableListCell>
      {hasTaskStatus && (
        <SortableListCell col="taskStatus" sortingCol="task_status">
          Задание
        </SortableListCell>
      )}
      {/* <SortableListCell col="actStatus" sortingCol="act_status">
        Акт
      </SortableListCell> */}
      <SortableListCell col="paymentStatus" sortingCol="payment_status">
        Платёж
      </SortableListCell>
    </SortableListHeader>
  )
  return (
    <List cols={taskListCols} gap={isMobile ? '1rem' : 0}>
      {header}
      {tasks.items.map((task) => (
        <TaskListItem
          url={`${baseUrl}/${task.id}`}
          key={task.id}
          task={task}
          hasDrafts={hasDrafts}
          contractors={contractors}
          templates={templates}
          hasCompany={hasCompany}
          hasContractor={hasContractor}
          hasDashboardActions={hasDashboardActions}
          hasTaskStatus={hasTaskStatus}
          isSelectable={isSelectable}
          isSelected={isSelectable ? selectedItemsStore!.isItemSelected(task) : false}
          onToggleSelected={() =>
            isSelectable && selectedItemsStore!.toggleSelectItem(task)
          }
        />
      ))}
    </List>
  )
})

export default TaskList
