import React, { useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { useMobxApi, Entity } from 'mobx/mobx'
import { PageProps } from 'app.types'
import UserInfoBlock from 'components/UserInfoBlock'

import { OfferResponseEntity } from 'entities'
import { InfoList, InfoListItem } from 'components/InfoList'
import { Layout, BackLink, ActionStateView, Flex } from 'components/ui'

import { contractorKindsMap } from '../contractors/maps'

interface CompanyOfferViewProps {
  response: Entity<OfferResponseEntity>
}

const CompanyOfferView = observer((props: CompanyOfferViewProps) => {
  let { response } = props
  let contractor = response.data.contractor

  let contractorInfo = (
    <InfoList title="Анкета">
      <InfoListItem label="ФИО">{contractor.data.name}</InfoListItem>
      <InfoListItem label="Тип занятости">
        {contractorKindsMap[contractor.data.kind]}
      </InfoListItem>
      <InfoListItem label="ИНН">{contractor.data.inn}</InfoListItem>
      {contractor.data.interview_url && (
        <InfoListItem label="Видеоинтервью">
          <a
            href={contractor.data.interview_url}
            target="_blank"
            rel="noopener noreferrer"
          >
            Смотреть
          </a>
        </InfoListItem>
      )}
    </InfoList>
  )

  let header = (
    <Flex style={{ marginBottom: '3em' }}>
      <UserInfoBlock user={contractor.data as any} />
    </Flex>
  )

  return (
    <>
      {header}
      {contractorInfo}
    </>
  )
})

const CompanyOfferResponsePage = observer(({ match }: PageProps) => {
  let id = match.params.id
  let api = useMobxApi()
  let fetchState = useMemo(() => api.fetch({ type: 'offers/responses', id }), [])
  return (
    <Layout smallPaddingTop>
      <BackLink to="/offers">К откликам</BackLink>

      <ActionStateView state={fetchState} animate={false}>
        {(response) => <CompanyOfferView response={response} />}
      </ActionStateView>
    </Layout>
  )
})

export default CompanyOfferResponsePage
