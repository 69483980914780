import React from 'react'
import { observer } from 'mobx-react-lite'
import { repeat } from 'lodash'

import { ConnectToPlatformStore } from '../../store/ConnectToPlatformStore'
import { createDigitsMask } from 'utils/validations'

import { Flex, H1, Field, FormRow, Input, Button } from 'components/ui'
import { Link } from 'ui'

const gosuslugiInnLink = (
  <Link href="https://www.gosuslugi.ru/16816/2" target="_blank">
    Узнать свой ИНН на сайте Госуслуг
  </Link>
)

const JoinAsIpStep = observer(({ store }: { store: ConnectToPlatformStore }) => {
  return (
    <Flex direction="column" gap="2rem" align="start">
      <H1>Подключение в качестве ИП</H1>
      <div>
        Для продолжения в качестве индивидуального предпринимателя введите ваш ИНН
      </div>
      <Field field={store.form.fields.inn}>
        {({ inputProps, error }) => (
          <FormRow label="ИНН" size="l" error={error} style={{ maxWidth: 400 }}>
            <Input
              {...inputProps}
              numericKeyboard
              mask={createDigitsMask(12)}
              isWide
              size="l"
              placeholder={repeat('0', 12)}
            />
          </FormRow>
        )}
      </Field>
      <Button
        isDisabled={!store.form.fields.inn.isValid}
        onTap={() => store.joinAsIp()}
        isLoading={store.actionState.state === 'pending'}
        style={{ alignSelf: 'start' }}
      >
        Далее
      </Button>
      {gosuslugiInnLink}
    </Flex>
  )
})

export default JoinAsIpStep
