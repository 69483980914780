import React from 'react'
import { observer } from 'mobx-react-lite'
import { useTitle } from 'react-use'
import { Flex, Layout, LayoutHeading } from 'components/ui'
import { JoinCompanyForm } from './JoinCompanyForm'
import s from './styles.module.css'

const JoinCompanyPage = observer(() => {
  useTitle('Restaff - Форма для сотрудника')

  return (
    <Layout smallPaddingTop className={s.root}>
      <Flex direction="column" gap="1rem">
        <LayoutHeading>Добро пожаловать в Restaff!</LayoutHeading>
        <JoinCompanyForm />
      </Flex>
    </Layout>
  )
})

export default JoinCompanyPage
