import React from 'react'

import { observer } from 'mobx-react-lite'

import { H1, Flex } from 'ui'

import { InplaceInput, Input as UiInput, Field, PageHeader } from 'components/ui'

import useProjectItemStore from '../../../hooks/useProjectItemStore'

export const HeadRow = observer(() => {
  const modalStore = useProjectItemStore()
  return (
    <PageHeader
      onBack={modalStore.closeModal}
      title={
        <Flex justify="start" align="center" gap="15px">
          <H1>Проект:</H1>
          <Field field={modalStore.staticForm.fields.name}>
            {({ inputProps }) => (
              <InplaceInput
                value={inputProps.value}
                placeholder="Новый проект"
                onChange={(value) => {
                  modalStore.staticForm.fields.name.change(value)
                }}
                style={{
                  fontSize: '32px',
                  fontWeight: 800,
                  minHeight: '1em',
                  marginBottom: -2,
                  flex: 1,
                  color: 'var(--konsol-color-grey)',
                }}
                components={{
                  Input: React.forwardRef((props, ref) => (
                    <UiInput {...props} ref={ref} />
                  )),
                }}
              />
            )}
          </Field>
        </Flex>
      }
    />
  )
})

export default HeadRow
