import React from 'react'
import { range, compact } from 'lodash'
// @ts-ignore
import { useStyles } from 'floral'

import { BaseButton } from './Button'

interface PaginationProps {
  pageCount: number
  page: number
  pagesRange?: number
  onChange: (page: number) => void
}

const paginationStyles = {
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  current: {
    background: 'var(--color-primary)',
    color: 'white',
  },
  ellipsis: {
    fontWeight: 500,
    color: 'var(--color-secondary)',
    marginRight: '0.5rem'
  },
}

const paginationButtonStyles = (props: any, { isHovered, isFocused }: any) => {
  let root: React.CSSProperties = {
    cursor: 'pointer',
    padding: '5px 10px',
    marginRight: '.5rem',
    borderRadius: 6,
    fontWeight: 500,
  }
  if (props.isDisabled) {
    root.color = 'var(--color-secondary)'
  } else if (isHovered || isFocused) {
    root.background = 'var(--konsol-color-light-background)'
  }
  return { root }
}

const generatePagesLinks = (current: number, total: number, rangeSize: number) => {
  let first = Math.max(1, current - rangeSize)
  let last = Math.min(total, current + rangeSize)
  let count = rangeSize * 2 + 1
  if (last - first + 1 < count) {
    if (current < total / 2) {
      last = Math.min(total, first + count - 1)
    } else {
      first = Math.max(1, last - count + 1)
    }
  }
  return compact([
    first > 1 && 1,
    first > 2 && '...',
    ...range(first, last + 1),
    last < total - 1 && '...',
    last < total && total,
  ])
}

const Pagination = (props: PaginationProps) => {
  let { pageCount, page: currentPage, onChange, pagesRange = 2 } = props
  let s = useStyles(paginationStyles, [props])
  let links = generatePagesLinks(currentPage, pageCount, pagesRange)
  return (
    <div style={s.root}>
      <BaseButton
        styles={paginationButtonStyles}
        onTap={() => onChange(currentPage - 1)}
        isDisabled={currentPage === 1}
        key="prev"
      >
        ←
      </BaseButton>
      {links.map((page) => {
        if (typeof page === 'string') return <div style={s.ellipsis}>...</div>
        let isCurrent = page === currentPage
        return (
          <BaseButton
            style={isCurrent ? s.current : null}
            styles={paginationButtonStyles}
            onTap={isCurrent ? undefined : () => onChange(page)}
            key={page}
          >
            {page}
          </BaseButton>
        )
      })}
      <BaseButton
        styles={paginationButtonStyles}
        onTap={() => onChange(currentPage + 1)}
        isDisabled={currentPage === pageCount}
        key="next"
      >
        →
      </BaseButton>
    </div>
  )
}

export default Pagination
