import React from 'react'
import { createContext } from 'react'
import { useContext } from 'react'

import { PageProps } from 'app.types'

import { makeAutoObservable, reaction } from 'mobx'
import { useLocalObservable } from 'mobx-react-lite'

import { useMobxApi } from 'mobx/MobxApiContext'
import { useStateToast } from 'components/ui'

import {
  OnboardingScenarioDocumentItem,
  OnboardingScenario,
} from 'entities/onboardingScenario'
import { CustomField, Template } from 'entities'

import { fetchApi } from 'api'

type Toast = ReturnType<typeof useStateToast>
type Api = ReturnType<typeof useMobxApi>

class ScenarioListStore {
  searchText = ''
  searchTextD = ''

  get query() {
    return {
      search: this.searchText,
    }
  }

  fetchState?: any

  constructor(readonly api: Api, readonly pageProps: PageProps, readonly toast: Toast) {
    makeAutoObservable(this)
    reaction(
      () => this.searchText,
      () => {
        this.searchTextD = this.searchText
      },
      { delay: 555 }
    )
  }

  customFields: CustomField[] = []
  documentFields: OnboardingScenarioDocumentItem[] = []
  templates: Template[] = []

  private loadCustomFieldList = async () => {
    try {
      return (await fetchApi({ url: `contractors/custom_fields` })) as CustomField[]
    } catch (e: any) {
      this.toast.error({
        title: `Ошибка загрузки полей сценария`,
        description: e.message,
      })
      throw e
    }
  }

  private loadTemplatesList = async () => {
    try {
      return (await fetchApi({ url: `templates` })) as Template[]
    } catch (e: any) {
      this.toast.error({
        title: `Ошибка загрузки шаблонов договора`,
        description: e.message,
      })
      throw e
    }
  }

  private loadDocumentFieldList = async () => {
    try {
      return (await fetchApi({
        url: `onboarding_scenarios/document_fields`,
      })) as OnboardingScenarioDocumentItem[]
    } catch (e: any) {
      this.toast.error({
        title: `Ошибка загрузки списка документов`,
        description: e.message,
      })
      throw e
    }
  }

  itemSetArchived = async (id: number, archived: boolean) => {
    try {
      await fetchApi({
        method: 'PATCH',
        url: `onboarding_scenarios/${id}`,
        data: {
          archived,
        },
      })
      this.toast.success({
        title: `Сценарий ${archived ? 'заархивирован' : 'восстановлен'}`,
      })
      return true
    } catch (error: any) {
      this.toast.error({
        title: `Не удалось ${archived ? 'заархивировать' : 'восстановить'} сценарий`,
        description: error.message,
      })
      return false
    }
  }

  itemSetEnabled = async (id: number, enabled: boolean) => {
    try {
      await fetchApi({
        method: 'PATCH',
        url: `onboarding_scenarios/${id}`,
        data: {
          enabled,
        },
      })
      this.toast.success({
        title: `Сценарий ${enabled ? 'включен' : 'выключен'}`,
      })
      return true
    } catch (error: any) {
      this.toast.error({
        title: `Не удалось ${enabled ? 'включить' : 'выключить'} сценарий`,
        description: error.message,
      })
      return false
    }
  }

  loadData = async () => {
    try {
      this.documentFields = await this.loadDocumentFieldList()
      this.customFields = await this.loadCustomFieldList()
      this.templates = await this.loadTemplatesList()
      this.fetchState = this.api.fetch({
        type: 'onboarding_scenarios'
      })
      return true
    } catch (error: any) {
      this.toast.error({
        title: 'Ошибка загрузки списка сценариев',
        description: error.message,
      })
      return false
    }
  }

  clearData = () => {
    this.fetchState = undefined
  }

  setSearchText = (searchText: string) => {
    this.searchText = searchText
  }

  setItemData = (rawData: Partial<OnboardingScenario>) => {
    const scenarios = this.fetchState!.value
    const id = rawData.id
    if (id && scenarios) {
      scenarios.map[id]?.setData(rawData)
    }
  }
}

const ScenarioContext = createContext<ScenarioListStore>(null as never)

export const ScenarioListStoreProvider = ({
  children,
  pageProps,
}: React.PropsWithChildren<{
  pageProps: PageProps
}>) => {
  const toast = useStateToast()
  const api = useMobxApi()
  const store = useLocalObservable(() => {
    return new ScenarioListStore(api, pageProps, toast)
  }, [pageProps, toast, api])
  return <ScenarioContext.Provider value={store}>{children}</ScenarioContext.Provider>
}

export const useScenarioListStore = () => useContext(ScenarioContext)

export default useScenarioListStore
