import React, { useEffect } from 'react'

import { Field, Flex, Col, SolidLabels } from 'components/ui'

import {
  H2,
  InputGroup,
  InputLeftAddon,
  Input,
  Button,
  Checkbox,
  Select,
  Spinner,
  Divider,
  Field as FormRow,
  useTheme,
  Link,
} from 'ui'

import useScenarioModalStore from '../../hooks/useScenarioModalStore'

import { useMediaContext } from 'App/MediaContext'

import { observer } from 'mobx-react-lite'

import classes from './styles.module.css'
import { useUserStore } from 'stores/context'
import { getSpectrumDataPrice } from 'utils/company'

import { ReactComponent as AddIcon } from 'assets/add.svg';
import { ReactComponent as InfoIcon } from 'assets/info.svg';

import CustomField from './CustomField'
import { Tooltip } from '@chakra-ui/react'

export interface IScenarioModalProps {
  onClose: () => void
  onSave: () => void
  mode: 'create' | 'update'
  payload?: Record<string, any>
}

export const ScenarioModal = observer(
  ({ onClose, onSave, mode, payload = {} }: IScenarioModalProps) => {
    const currentUser = useUserStore()
    const spectrumDataReportPrice = getSpectrumDataPrice(currentUser)
    const { config } = useTheme()
    const { isMobile } = useMediaContext()
    const size = isMobile ? 'm' : 'l'
    const scenarioModalStore = useScenarioModalStore()

    useEffect(() => {
      scenarioModalStore
        .init(mode, payload.id)
        .then((result) => {
          if (!result) {
            onClose()
          }
        })
        .catch(onClose)
      return () => scenarioModalStore.clear()
    }, [])

    const scenarioPermalinkPrefix = `${config.url}/join/` // `${window.location.origin}/join/`;

    const templateId = scenarioModalStore.commonForm?.values.contract_template_id

    const templateList = scenarioModalStore.templates
      .filter(({ enabled, kind }) => enabled && kind === 'contract')
      .map(({ id, name }) => ({
        label: name,
        value: id,
      }))

    const templateFieldList =
      scenarioModalStore.templates
        .find(({ id }) => id === templateId)
        ?.variables.map(({ name }) => name) || []

    const documentList = scenarioModalStore.documentFields.map(({ key, name }) => ({
      label: name,
      value: key,
    }))

    return (
      <>
        {scenarioModalStore.loading && (
          <Flex
            gap="5px"
            direction="column"
            align="center"
            justify="center"
            style={{ height: 100 }}
          >
            <Spinner size="l" />
          </Flex>
        )}
        {scenarioModalStore.commonForm && (
          <>
            <Flex gap="5px" direction="column">
              <Field field={scenarioModalStore.commonForm.fields.name}>
                {({ inputProps, error }) => {
                  return (
                    <>
                      <H2>Настройка сценария</H2>
                      <FormRow
                        error={error}
                        label="Название сценария"
                        style={{
                          marginTop: '10px',
                        }}
                      >
                        <Input
                          size="l"
                          value={inputProps.value}
                          placeholder="Укажите название сценария"
                          onChange={(value) => {
                            scenarioModalStore.commonForm.fields.name.change(value)
                          }}
                        />
                      </FormRow>
                    </>
                  )
                }}
              </Field>
            </Flex>
            <Col gap="var(--gap-m)">
              <div className={classes.block}>
                <Field field={scenarioModalStore.commonForm.fields.permalink}>
                  {({ inputProps, error }) => (
                    <FormRow label="Ссылка" error={error}>
                      <InputGroup>
                        <InputLeftAddon>{scenarioPermalinkPrefix}</InputLeftAddon>
                        <Input
                          {...inputProps}
                          placeholder="konsol_kuriery_moskva"
                          style={{ flex: 1 }}
                          fullWidth={isMobile}
                          withLeftAddon
                        />
                      </InputGroup>
                    </FormRow>
                  )}
                </Field>
                <span style={{ marginTop: isMobile ? 0 : 25 }}>
                  Для каждого сценария регистрации исполнителей задается уникальная ссылка
                </span>
                <Divider className={classes.separator} />
                <Flex direction="column" gap="10px">
                  <Field
                    field={scenarioModalStore.commonForm.fields.contract_template_id}
                  >
                    {({ inputProps, error }) => (
                      <FormRow label="Шаблон договора" error={error}>
                        <Select
                          {...inputProps}
                          value={
                            inputProps.value
                              ? templateList.find((o) => o.value === inputProps.value)
                              : undefined
                          }
                          onChange={(option: any) => {
                            scenarioModalStore.commonForm.fields.contract_template_id.change(
                              option ? option.value : null
                            )
                          }}
                          size={size}
                          options={templateList}
                          isSearchable={false}
                          placeholder="Выберите шаблон договора"
                          noOptionsMessage={() => 'Нет шаблонов договора'}
                        />
                      </FormRow>
                    )}
                  </Field>
                  {!!templateFieldList.length && (
                    <>
                      <span>
                        В этом шаблоне используются переменные, которые нужно будет
                        заполнить при отправке приглашения исполнителю:
                      </span>
                      <SolidLabels items={templateFieldList} size="s" />
                    </>
                  )}
                </Flex>
                <Flex direction="column" gap="10px">
                  <span style={{ marginTop: isMobile ? 0 : 25 }}>
                    После прохождения регистрации исполнителю будет предложено подписать
                    договор по выбранному шаблону
                  </span>
                </Flex>
                <Divider className={classes.separator} />
                <Flex>
                  <Field field={scenarioModalStore.commonForm.fields.document_fields_ids}>
                    {({ inputProps, error }) => {
                      const ids = inputProps.value.split(',')
                      return (
                        <FormRow
                          style={{ flex: 1 }}
                          label="Сбор документов"
                          error={error}
                        >
                          <Select
                            value={documentList.filter(({ value }) =>
                              ids.includes(value)
                            )}
                            onChange={(options: any) => {
                              const newValue = options.map((o: any) => o.value).join(',')
                              scenarioModalStore.commonForm.fields.document_fields_ids.change(
                                newValue
                              )
                            }}
                            isMulti
                            options={documentList}
                            placeholder="Выберите документ"
                            noOptionsMessage={() => 'Нет документов'}
                          />
                        </FormRow>
                      )
                    }}
                  </Field>
                </Flex>
                <Flex direction="column" gap="10px">
                  <span style={{ marginTop: isMobile ? 0 : 25 }}>
                    Если необходимо, можно запрашивать дополнительные документы у
                    исполнителя при регистрации
                  </span>
                </Flex>
                <Divider className={classes.separator} />
                <Flex direction='column' gap="10px">
                  <FormRow label="Дополнительные поля">
                    {!scenarioModalStore.customFields.length && (
                      <Flex
                        direction='row'
                        gap="10px"
                        style={{
                          background: 'rgba(59, 143, 227, 0.08)',
                          borderRadius: 8,
                          paddingLeft: 10,
                          paddingRight: 15,
                          paddingTop: 10,
                          paddingBottom: 10,
                        }}
                      >
                        <div>
                          <InfoIcon />
                        </div>
                        <span>
                          Дополнительные поля еще не созданы.<br />
                          Как создать: <Link href="https://support.konsol.pro/segments-and-additional-fields">Дополнительные поля и сегменты</Link>
                        </span>
                      </Flex>
                    )}
                    {scenarioModalStore.additionalFieldList.map(({ field, idx }) => (
                      <CustomField
                        field={field}
                        key={idx}
                        idx={idx}
                      />
                    ))}
                  </FormRow>
                  {!!scenarioModalStore.customFields.length && (
                    <>
                      {scenarioModalStore.canAddField ? (
                        <Button
                          variant="text"
                          style={{ marginTop: 15 }}
                          icon={<AddIcon />}
                          size={isMobile ? 's' : 'm'}
                          onTap={() => scenarioModalStore.addField()}
                        >
                          Добавить поле
                        </Button>
                      ) : (
                        <Tooltip label="Все доступные поля использованы">
                          <Button
                            variant="text"
                            style={{ marginTop: 15 }}
                            icon={<AddIcon />}
                            size={isMobile ? 's' : 'm'}
                            onTap={() => scenarioModalStore.addField()}
                            isDisabled
                          >
                            Добавить поле
                          </Button>
                        </Tooltip>
                      )}
                    </>
                  )}
                </Flex>
                <Flex direction='column' gap="10px">
                  <span>
                    Используя дополнительные поля при регистрации исполнителя можно собирать любые данные, которые нужны для работы или заключения договора.
                  </span>
                  <span>
                    Выберите из списка одно из полей и укажите кем оно будет заполняться.
                  </span>
                  <span>
                    Если дополнительное поле заполняет исполнитель, то значение для него он укажет самостоятельно при регистрации.
                  </span>
                  <span>
                    Если указать, что поле заполняет компания, то нужно сразу указать его значение — оно будет присвоено каждому исполнителю, который пройдет регистрацию по этому сценарию.
                  </span>
                  <Link href="https://support.konsol.pro/segments-and-additional-fields">
                    Работа с дополнительными полями
                  </Link>
                </Flex>
                <Divider className={classes.separator} />
                <Flex direction="column" gap="10px">
                  <Field field={scenarioModalStore.commonForm.fields.join_options}>
                    {({ error }) => (
                      <FormRow
                        label="В каком статусе может регистрироваться исполнитель"
                        error={error}
                      >
                        <Field field={scenarioModalStore.commonForm.fields.self_employed}>
                          {({ inputProps }) => (
                            <Checkbox
                              {...inputProps}
                              label={
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    color: 'black',
                                  }}
                                >
                                  <span style={{ fontWeight: 500 }}>Самозанятый</span>
                                  <span style={{ color: 'var(--color-grey-4)' }}>
                                    Действующий или хочет стать самозанятым
                                  </span>
                                </div>
                              }
                            />
                          )}
                        </Field>
                        <Field
                          field={
                            scenarioModalStore.commonForm.fields.individual_entrepreneur
                          }
                        >
                          {({ inputProps }) => (
                            <Checkbox
                              {...inputProps}
                              label={
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    color: 'black',
                                  }}
                                >
                                  <span style={{ fontWeight: 500 }}>
                                    Индивидуальный предприниматель
                                  </span>
                                  <span style={{ color: 'var(--color-grey-4)' }}>
                                    Есть действующий статус индивидуального
                                    предпринимателя
                                  </span>
                                </div>
                              }
                            />
                          )}
                        </Field>
                        <Field field={scenarioModalStore.commonForm.fields.gph}>
                          {({ inputProps }) => (
                            <Checkbox
                              {...inputProps}
                              label={
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    color: 'black',
                                  }}
                                >
                                  <span style={{ fontWeight: 500 }}>
                                    Физическое лицо (договор ГПХ)
                                  </span>
                                  <span style={{ color: 'var(--color-grey-4)' }}>
                                    Будет работать в качестве физ.лица по договору ГПХ
                                  </span>
                                </div>
                              }
                            />
                          )}
                        </Field>
                        <Field field={scenarioModalStore.commonForm.fields.non_resident}>
                          {({ inputProps }) => (
                            <Checkbox
                              {...inputProps}
                              label={
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    color: 'black',
                                  }}
                                >
                                  <span style={{ fontWeight: 500 }}>
                                    Нерезидент РФ (договор ГПХ)
                                  </span>
                                  <span style={{ color: 'var(--color-grey-4)' }}>
                                    Живет в другой стране и будет работать по договору ГПХ
                                  </span>
                                </div>
                              }
                            />
                          )}
                        </Field>
                      </FormRow>
                    )}
                  </Field>
                </Flex>
                <Divider className={classes.separator} />
                {currentUser.company?.data.features.check_contractor_spectrum_data && (
                  <Flex direction="column" gap="10px">
                    <FormRow label="Проверка исполнителя">
                      <Field
                        field={
                          scenarioModalStore.commonForm.fields
                            .check_contractor_spectrum_data
                        }
                      >
                        {({ inputProps }) => (
                          <Checkbox
                            {...inputProps}
                            label={
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  color: 'black',
                                }}
                              >
                                <span style={{ fontWeight: 500 }}>
                                  Проверять всех исполнителей в рамках сценария
                                </span>
                                <span style={{ color: 'var(--color-grey-4)' }}>
                                  и предоставлять отчет о благонадежности
                                </span>
                              </div>
                            }
                          />
                        )}
                      </Field>
                    </FormRow>
                    <div className={classes.fieldAdditionalInfo}>
                      <p>
                        По каждому исполнителю при регистрации будет сформирован отчет о
                        благонадежности.
                      </p>
                      <p>Стоимость одного отчета — {spectrumDataReportPrice} руб.</p>
                      <p>
                        <a
                          href="https://support.konsol.pro/verification-of-trustworthiness"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Подробнее о проверке исполнителей
                        </a>
                      </p>
                    </div>
                  </Flex>
                )}
              </div>
            </Col>
            <Flex direction="row-reverse">
              <Button
                size={size}
                style={{ maxWidth: 275 }}
                isDisabled={!scenarioModalStore.commonForm?.isValid}
                onTap={() => {
                  scenarioModalStore.save(mode, payload.id).then((result) => {
                    if (result) {
                      onSave()
                    }
                  })
                }}
              >
                {mode === 'create' ? 'Создать' : 'Сохранить'}
              </Button>
            </Flex>
          </>
        )}
      </>
    )
  }
)

export default ScenarioModal
