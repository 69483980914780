import React, { useState, useMemo } from 'react'
import { observer } from 'mobx-react-lite'

import { initialActionState, Entity } from 'mobx/mobx'
import { TaxBoxEntity, TaxBoxOperationEntity } from 'entities'
import { useUserStore } from 'stores/context'
import { useMediaContext } from 'App/MediaContext'
import {
  Layout,
  LayoutHeading,
  ActionStateView,
  Flex,
  Col,
  Button,
  Link,
  List,
  ListHeader,
  ListItem,
  ListCell,
  Modal,
  Card,
  H2,
  useStateToast,
} from 'components/ui'
import Amount from 'components/Amount'
import { printIsoDateTime } from 'utils/datetime'
import { endash, nbsp } from 'utils/typo'

import howToPayUrl from './how-to-pay.png'

import c from './styles.module.css'

import step1 from './permit1.png'
import step2 from './permit2.png'
import step3 from './permit3.png'
import step4 from './permit4.png'

interface TaxBoxViewProps {
  account: Entity<TaxBoxEntity>
}

const ConnectMoiNalogCard = observer(() => {
  let { isMobile } = useMediaContext()
  let toast = useStateToast()
  let currentUser = useUserStore()
  let contractor = currentUser.user!.data.contractor!

  let [showModal, setShowModal] = useState(false)
  let [actionState, setActionState] = useState(initialActionState)
  let [requestIsSent, setRequestIsSent] = useState(false)

  let connect = () => {
    let state = contractor.action({ action: 'connect_moi_nalog' })
    setActionState(state)
    state.then(
      (data: any) => {
        if (data.moi_nalog.status === 'connected') {
          contractor.setData(data)
          toast.success({ title: 'Успешно подключено' })
        } else if (data.moi_nalog.status === 'request_sent') {
          setShowModal(true)
          setRequestIsSent(true)
          if (requestIsSent) {
            toast.warning({
              title: 'Запрос отправлен',
              description: (
                <div dangerouslySetInnerHTML={{ __html: data.moi_nalog.message }} />
              ),
            })
          }
        } else {
          toast.error({
            title: 'Не удалось подключить',
            description: (
              <div dangerouslySetInnerHTML={{ __html: data.moi_nalog.message }} />
            ),
          })
        }
      },
      (e) => toast.error({ title: 'Ошибка', description: e.message })
    )
  }

  let isLoading = actionState.state === 'pending'

  let modal = showModal && (
    <Modal
      size={isMobile ? '95%' : 500}
      isOpen={true}
      onClose={() => {
        setShowModal(false)
        setRequestIsSent(false)
      }}
      styles={{ content: { marginTop: 20 } }}
    >
      <Flex direction="column" gap="var(--gap-m)">
        <H2 style={{ fontSize: isMobile ? 21 : 26 }}>Подключение к «Мой Налог»</H2>
        <Col gap="var(--gap-s)">
          <div style={{ fontWeight: 600 }}>
            Вам отправлен запрос в приложение «Мой Налог»
          </div>
          <div>
            1. Откройте приложение «Мой Налог» и перейдите в раздел «Прочее»
            <img src={step1} className={c.manualStep} alt="" />
          </div>
          <div>
            2. Выберите «Партнёры»
            <img src={step2} className={c.manualStep} alt="" />
          </div>
          <div>
            3. Найдите и примите запрос от Платформы Restaff
            <img src={step3} className={c.manualStep} alt="" />
          </div>
        </Col>
        <Button onTap={connect} isLoading={isLoading}>
          Проверить
        </Button>
      </Flex>
    </Modal>
  )
  if (
    contractor.data.moi_nalog &&
    contractor.data.moi_nalog.status === 'not_registered'
  ) {
    return (
      <Card
        kind="error"
        title="Вы потеряли статус самозанятого и не подключены к «Мой налог»"
        actions={
          <Button isLoading={isLoading} onTap={connect} size="s">
            Подключить
          </Button>
        }
      >
        <div>
          Подключите платформу Restaff в приложении «Мой налог» для продолжения работы на
          платформе.
        </div>
        {modal}
      </Card>
    )
  } else {
    return (
      <Card
        kind="error"
        title="Вы не подключены к «Мой налог»"
        actions={
          <Button isLoading={isLoading} onTap={connect} size="s">
            Подключить
          </Button>
        }
      >
        <div>
          Подключите платформу Restaff в приложении «Мой налог» для продолжения работы на
          платформе.
        </div>
        {modal}
      </Card>
    )
  }
})

const TaxPaymentPermissionCard = observer(({ account }: TaxBoxViewProps) => {
  let { isMobile } = useMediaContext()
  let toast = useStateToast()

  let [requestIsSent, setRequestIsSent] = useState(false)
  let [requestState, setRequestState] = useState(initialActionState)
  let [showModal, setShowModal] = useState(false)
  let request = () => {
    let state = account.action({ action: 'send_request' })
    state.then(
      (result) => {
        if (result.status === 'request_sent') {
          if (requestIsSent) toast.error({ title: 'Не смогли подтвердить разрешение' })
          setShowModal(true)
          setRequestIsSent(true)
        } else if (result.status === 'success') {
          toast.success({ title: 'Разрешение подтверждено' })
          account.setData(result.data)
          setShowModal(false)
        }
      },
      (e) => toast.error({ title: 'Ошибка', description: e.message })
    )
    setRequestState(state)
  }

  let isLoading = requestState.state === 'pending'

  let modal = showModal && (
    <Modal
      size={isMobile ? '92%' : 450}
      isOpen={true}
      onClose={() => {
        setShowModal(false)
        setRequestIsSent(false)
      }}
    >
      <Flex direction="column" gap="var(--gap-m)">
        <H2>Разрешение на оплату налогов</H2>
        <Col gap="var(--gap-s)">
          <div style={{ fontWeight: 600 }}>
            Вам отправлен запрос в приложение «Мой Налог»
          </div>
          <div>
            1. Откройте приложение «Мой Налог» и перейдите в раздел «Прочее»
            <img src={step1} className={c.manualStep} alt="" />
          </div>
          <div>
            2. Выберите «Партнёры»
            <img src={step2} className={c.manualStep} alt="" />
          </div>
          <div>
            3. Найдите запрос от Платформы Restaff
            <img src={step3} className={c.manualStep} alt="" />
          </div>
          <div>
            4. Дайте разрешение на оплату налогов
            <img src={step4} className={c.manualStep} alt="" />
          </div>
        </Col>
        <Button onTap={request} isLoading={isLoading}>
          Проверить
        </Button>
      </Flex>
    </Modal>
  )

  return (
    <Card
      kind="error"
      title="Подтвердите разрешение на оплату налогов от вашего имени"
      actions={
        <Button onTap={request} isLoading={isLoading} size="s">
          Дать разрешение
        </Button>
      }
    >
      Без этого мы не сможем получать ваши налоговые начисления и оплачивать их за вас.
      {modal}
    </Card>
  )
})

interface UnpaidTaxesCardProps extends TaxBoxViewProps {
  onEnableWitholding?: () => void
}

const UnpaidTaxesCard = observer(
  ({ account, onEnableWitholding }: UnpaidTaxesCardProps) => {
    let { isMobile } = useMediaContext()
    let { text, enabled_withholding } = account.data.unpaid_taxes!

    let [showSelfPayModal, setShowSelfPayModal] = useState(false)

    let [requestState, setRequestState] = useState(() => initialActionState)
    let enableWithholding = () => {
      let state = account.action({ action: 'enable_withholding_for_unpaid_taxes' })
      state.then((data) => {
        account.setData(data)
        onEnableWitholding && onEnableWitholding()
      })
      setRequestState(state)
    }
    let disableWithholding = () => {
      let state = account.action({ action: 'disable_withholding_for_unpaid_taxes' })
      state.then((data) => account.setData(data))
      setRequestState(state)
    }

    let selfPayModal
    if (showSelfPayModal) {
      selfPayModal = (
        <Modal
          isOpen={true}
          onClose={() => setShowSelfPayModal(false)}
          size={isMobile ? '92%' : 400}
        >
          <Flex direction="column" gap="1rem">
            <H2>Как оплатить налог самостоятельно</H2>
            <div>
              1. Установите на телефон официальное приложение ФНС для самозанятыx {endash}{' '}
              «Мой{nbsp}налог».
              <Flex gap="1rem" align="center" style={{ marginTop: '1rem' }}>
                <a
                  className={c.link}
                  href="https://apps.apple.com/ru/app/мой-налог/id1437518854"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className={c.appStoreBadge} />
                </a>
                <a
                  className={c.link}
                  href="https://play.google.com/store/apps/details?id=com.gnivts.selfemployed"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className={c.googlePlayBadge} />
                </a>
              </Flex>
            </div>
            <div>2. Войдите указав ваш ИНН или номер телефона.</div>
            <div>3. Оплатите налог удобным для вас способом.</div>
            <img src={howToPayUrl} style={{ maxWidth: 375 }} alt="" />
            <Button onTap={() => setShowSelfPayModal(false)}>Понятно</Button>
          </Flex>
        </Modal>
      )
    }

    let selfPayLink = (
      <Link onTap={() => setShowSelfPayModal(true)}>Как оплатить самостоятельно?</Link>
    )

    let card
    if (enabled_withholding) {
      card = (
        <Card
          kind="success"
          title={text}
          actions={
            <>
              <Button
                design="normal"
                size="s"
                onTap={disableWithholding}
                isLoading={requestState.state === 'pending'}
              >
                Я хочу оплатить самостоятельно
              </Button>
              {selfPayLink}
            </>
          }
        >
          Сумма на оплату будет автоматически удержана из ближайшей выплаты
        </Card>
      )
    } else {
      card = (
        <Card
          kind="error"
          title={text}
          actions={
            <>
              <Button
                onTap={enableWithholding}
                isLoading={requestState.state === 'pending'}
                size="s"
              >
                Удержать из выплаты
              </Button>
              {selfPayLink}
            </>
          }
        >
          Мы можем удержать из ближайшей выплаты по акту и оплатить их за вас, либо вы
          можете оплатить самостоятельно
        </Card>
      )
    }

    return (
      <Flex direction="column" gap="1rem" align={isMobile ? 'stretch' : 'start'}>
        {card}
        {selfPayModal}
      </Flex>
    )
  }
)

interface ModalProps extends TaxBoxViewProps {
  onClose: () => void
}

const estimatedTaxStyles: { [key: string]: React.CSSProperties } = {
  caption: {
    fontWeight: 500,
    textTransform: 'uppercase',
    color: 'var(--color-secondary)',
  },
}

const EstimatedTaxModal = observer(({ account, onClose }: ModalProps) => {
  let { isMobile } = useMediaContext()
  let s = estimatedTaxStyles

  let content = (
    <Flex gap="2rem" direction="column">
      <H2>Расчёт налога</H2>
      <div>
        Сумма налога рассчитывается в зависимости от вашего дохода за каждый месяц и
        уплачивается автоматически до 25 числа следующего месяца.
      </div>
      {account.data.taxes.items.map((item) => (
        <div>
          <Flex direction="column" gap=".5rem" style={{ minWidth: isMobile ? 0 : 360 }}>
            <div style={s.caption}>{item.title}</div>
            <Flex justify="space-between">
              <div>Доход за период</div>
              <Amount value={item.income_amount} style={{ fontWeight: 500 }} />
            </Flex>
            <Flex justify="space-between">
              <div>Сумма налога{item.is_estimated && ' (предварительно)'}</div>
              <Amount value={item.tax_amount} style={{ fontWeight: 500 }} />
            </Flex>
            <Flex justify="space-between">
              <div>Срок оплаты</div>
              <div style={{ fontWeight: 500 }}>{item.due_date}</div>
            </Flex>
          </Flex>
        </div>
      ))}
      <Button onTap={onClose}>Понятно</Button>
    </Flex>
  )

  return (
    <Modal isOpen={true} onClose={onClose} size={isMobile ? '92%' : 600}>
      {content}
    </Modal>
  )
})

const HowItWorksModal = observer(({ onClose }: ModalProps) => {
  let { isMobile } = useMediaContext()
  let content = (
    <Flex gap="1rem" direction="column">
      <div>
        Копилка автоматически откладывает часть суммы с ваших доходов, чтобы хватило на
        оплату налогов. Когда придёт налог — он будет оплачен из накопленной суммы
        автоматически.
      </div>
      <div style={{ fontWeight: 500 }}>
        {endash} Откладывает 6% с доходов от юрлиц и 4% с доходов от физлиц.
      </div>
      <div style={{ fontWeight: 500 }}>
        {endash} Учитывает налоговый бонус и откладывает чуть меньше пока бонус не
        израсходуется.
      </div>
      <Flex direction="column" gap=".25rem">
        <div style={{ fontWeight: 500 }}>
          {endash} Учитывает доходы, задекларированные не в Консоли.
        </div>
        <div>
          Например: вы работаете одновременно в двух компаниях. С одной из них — через
          Restaff. После декларации дохода в других местах Копилка автоматически
          рассчитает сумму налога и отложит со следующей выплаты.
        </div>
      </Flex>
    </Flex>
  )
  return (
    <Modal isOpen={true} onClose={onClose} size={isMobile ? '92%' : 600}>
      <Flex gap="2rem" direction="column">
        <H2>Как работает копилка</H2>
        {content}
        <Button onTap={onClose}>Понятно</Button>
      </Flex>
    </Modal>
  )
})

const SavingsSection = observer(({ account }: TaxBoxViewProps) => {
  let { isMobile } = useMediaContext()
  let { enough_to_pay_taxes, text, amount } = account.data.savings

  let [showHowItWorksModal, setShowHowItWorksModal] = useState(false)
  let howItWorksModal = showHowItWorksModal && (
    <HowItWorksModal account={account} onClose={() => setShowHowItWorksModal(false)} />
  )

  let [showEstimatedTaxModal, setShowEstimatedTaxModal] = useState(false)
  let estimatedTaxModal = showEstimatedTaxModal && (
    <EstimatedTaxModal
      account={account}
      onClose={() => setShowEstimatedTaxModal(false)}
    />
  )

  return (
    <Flex direction="column" gap="1rem" align={isMobile ? 'stretch' : 'start'}>
      <Flex direction="column" gap={3}>
        <div style={{ fontSize: 18 }}>Баланс копилки</div>
        <Amount value={amount} style={{ fontWeight: 600, fontSize: 28 }} />
      </Flex>
      <Card
        kind={enough_to_pay_taxes ? 'success' : 'error'}
        title={text}
        actions={
          <Button size="s" design="normal" onTap={() => setShowEstimatedTaxModal(true)}>
            Показать расчет
          </Button>
        }
      />
      <Link onTap={() => setShowHowItWorksModal(true)}>Как работает копилка?</Link>
      {howItWorksModal}
      {estimatedTaxModal}
    </Flex>
  )
})

const Operation = observer(
  ({ operation }: { operation: Entity<TaxBoxOperationEntity> }) => {
    let { isMobile } = useMediaContext()
    let { date, title, kind, items, amount } = operation.data

    let amountElem = (
      <div style={{ color: kind === 'withdrawal' ? 'auto' : 'var(--color-green)' }}>
        {kind === 'withdrawal' ? '-' : '+'}
        <Amount value={amount} />
      </div>
    )
    let dateElem = printIsoDateTime(date)

    if (isMobile) {
      return (
        <Flex direction="column" className={c.operation}>
          <Flex justify="space-between" gap="1rem" className={c.operationItem}>
            <Flex direction="column" gap=".25rem">
              <div style={{ fontWeight: 'bold' }}>{title}</div>
              <div style={{ color: 'var(--color-secondary)' }}>{dateElem}</div>
            </Flex>
            {amountElem}
          </Flex>
          {items.map((item) => (
            <Flex justify="space-between" gap="1rem" className={c.operationItem}>
              <div>{item.title}</div>
              <div style={{ color: 'var(--color-secondary)' }}>
                {kind === 'withdrawal' ? '-' : '+'}
                <Amount value={item.amount} />
              </div>
            </Flex>
          ))}
        </Flex>
      )
    }

    return (
      <ListItem padding={false} hover={false} style={{ alignItems: 'start' }}>
        <ListCell col="date">{dateElem}</ListCell>
        <ListCell col="content">
          <Flex gap=".5rem" direction="column">
            <Flex gap="1rem" justify="space-between">
              <div style={{ fontWeight: 'bold' }}>{title}</div>
              <div style={{ fontWeight: 500 }}>{amountElem}</div>
            </Flex>
            {items.map((item) => (
              <Flex
                gap="1rem"
                justify="space-between"
                style={{ color: 'var(--color-secondary)' }}
              >
                <div>{item.title}</div>
                <div>
                  {kind === 'withdrawal' ? '-' : '+'}
                  <Amount value={item.amount} />
                </div>
              </Flex>
            ))}
          </Flex>
        </ListCell>
      </ListItem>
    )
  }
)

const OperationsSection = observer(({ account }: TaxBoxViewProps) => {
  let { isMobile } = useMediaContext()
  let operations = account.data.operations!

  let list
  if (isMobile) {
    list = (
      <Flex direction="column" gap="1rem">
        {operations.items.map((operation) => (
          <Operation operation={operation} />
        ))}
      </Flex>
    )
  } else {
    let cols: any = {
      date: { style: { color: 'var(--color-secondary)' }, width: 100 },
      amount: { width: 120 },
    }
    list = (
      <List cols={cols}>
        <ListHeader padding={false}>
          <ListCell col="date">Дата</ListCell>
          <ListCell col="title">Операция</ListCell>
          <ListCell width="amount" style={{ textAlign: 'right' }}>
            Сумма
          </ListCell>
        </ListHeader>
        {operations.items.map((operation) => (
          <Operation operation={operation} />
        ))}
      </List>
    )
  }

  return (
    <Flex direction="column" gap="1rem">
      <H2>Операции</H2>
      {list}
    </Flex>
  )
})

const TaxAccountView = observer(({ account }: TaxBoxViewProps) => {
  let currentUser = useUserStore()

  let contractor = currentUser.user!.data.contractor!
  let showConnectMoiNalogCard =
    contractor.data.moi_nalog && contractor.data.moi_nalog.status !== 'connected'
  let connectMoiNalogSection = showConnectMoiNalogCard && <ConnectMoiNalogCard />

  let permissionSection = !account.data.tax_payment_permission && (
    <TaxPaymentPermissionCard account={account} />
  )

  let unpaidTaxesSection = account.data.unpaid_taxes && (
    <UnpaidTaxesCard account={account} />
  )

  let savingsSection = <SavingsSection account={account} />

  let operationsSection = !account.data.operations.isEmpty && (
    <OperationsSection account={account} />
  )

  return (
    <Col gap="var(--gap-l)" style={{ maxWidth: 800 }}>
      {connectMoiNalogSection}
      {permissionSection}
      {savingsSection}
      {unpaidTaxesSection}
      {operationsSection}
    </Col>
  )
})

const TaxBoxPage = observer(() => {
  let currentUser = useUserStore()

  let fetchTaxAccountState = useMemo(
    () => currentUser.user!.data.contractor!.data.tax_box!.fetch(),
    []
  )

  return (
    <Layout>
      <LayoutHeading style={{ marginBottom: '2rem' }}>Налоговая копилка</LayoutHeading>
      <ActionStateView state={fetchTaxAccountState}>
        {() => (
          <TaxAccountView account={currentUser.user!.data.contractor!.data.tax_box!} />
        )}
      </ActionStateView>
    </Layout>
  )
})

export default TaxBoxPage
export { ConnectMoiNalogCard, TaxPaymentPermissionCard, UnpaidTaxesCard }
