import React, { useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { useLocation } from 'react-router'
import { Channel } from 'pusher-js'

import type { PageProps } from 'app.types'
import { usePusherSubscription, usePusherChannelContext } from 'App/pusher'
import { useMediaContext } from 'App/MediaContext'
import { MobxApi, useMobxApi, Collection } from 'mobx/mobx'
import { TaskEntity, TaskAction } from 'entities'
import {
  useUserStore,
  UserStore,
  useSignDialogStore,
  SignDialogStore,
} from 'stores/context'

import TasksMultiActionsStore from 'components/tasks/TasksMultiActionsStore'
import TaskList from 'components/tasks/TaskList'
import { PaginationStore, PaginationView } from 'components/PaginationView'
import { Chips } from 'components'
import {
  Button,
  Layout,
  LayoutHeading,
  Flex,
  Col,
  Menu,
  MenuButton,
  MenuList,
  useStateToast,
  Placeholder,
} from 'components/ui'
import { AbilityButton, AbilityMenuItem } from 'components/abilityButtons'
import { getHashState } from 'utils/useHashState'

import { ReactComponent as MoreIcon } from '@material-design-icons/svg/round/more_horiz.svg'

const tasksSections: any = {
  company: {
    all: { id: 'all', title: 'Все', emptyText: 'Нет заданий' },
    draft: {
      id: 'draft',
      title: 'Черновики',
      emptyText: 'Нет черновиков',
      statuses: ['draft'],
    },
    created: {
      id: 'created',
      title: 'На подпись',
      emptyText: 'Нет заданий на подпись',
      statuses: ['created'],
    },
    active: {
      id: 'active',
      title: 'Активные',
      emptyText: 'Нет активных заданий',
      statuses: ['draft', 'assigned', 'in_progress', 'done'],
    },
  },

  contractor: {
    all: { id: 'all', title: 'Все', emptyText: 'Нет заданий' },
    new: {
      id: 'new',
      title: 'Новые',
      emptyText: 'Нет новых заданий',
      statuses: ['created', 'assigned'],
    },
    active: {
      id: 'active',
      title: 'Активные',
      emptyText: 'Нет активных заданий',
      statuses: ['in_progress', 'done', 'started_by_contractor'],
    },
    acceptance_ready: {
      id: 'acceptance_ready',
      title: 'На подпись',
      emptyText: 'Нет заданий на подпись',
      statuses: ['acceptance_ready', 'accepted_by_company'],
    },
    paid: {
      id: 'paid',
      title: 'Подтвердить оплату',
      emptyText: 'Нет заданий на подтверждение оплаты',
      statuses: ['paid'],
    },
  },
}

const actsSections: any = {
  company: {
    all: { id: 'all', title: 'Все', emptyText: 'Нет актов' },
    done: {
      id: 'done',
      title: 'Требуется подпись исполнителя',
      emptyText: 'Нет актов не подписанных исполнителем',
      statuses: ['done', 'accepted_by_company'],
    },
    acceptance_ready: {
      id: 'acceptance_ready',
      title: 'На подпись',
      emptyText: 'Нет актов на подпись',
      statuses: ['done', 'acceptance_ready', 'accepted_by_contractor'],
    },
    accepted: {
      id: 'accepted',
      title: 'На оплату',
      emptyText: 'Нет актов на оплату',
      statuses: ['accepted'],
    },
    paid: {
      id: 'paid',
      title: 'Оплачены',
      emptyText: 'Нет оплаченных актов',
      statuses: [
        'paid',
        'completed',
        'completed_without_contractor_accept',
        'paid_without_contractor_accept',
      ],
    },
  },
  contractor: {
    all: { id: 'all', title: 'Все', emptyText: 'Нет актов' },
    done: {
      id: 'done',
      title: 'На подпись',
      emptyText: 'Нет актов на подпись',
      statuses: [
        'done',
        'accepted_by_company',
        'completed_without_contractor_accept',
        'paid_without_contractor_accept',
      ],
    },
    accepted: {
      id: 'accepted',
      title: 'Ожидают оплату',
      emptyText: 'Нет актов ожидающих оплату',
      statuses: ['acceptance_ready', 'accepted'],
    },
    paid: {
      id: 'paid',
      title: 'Оплачены',
      emptyText: 'Нет оплаченных актов',
      statuses: ['paid', 'completed'],
    },
  },
}

const noTasksForActionMessages: { [key: string]: string } = {
  sign_terms: 'Нет ТЗ на подпись',
  accept: 'Нет актов на подпись',
}

interface TaskListStoreProps {
  currentUser: UserStore
  api: MobxApi
  toast: ReturnType<typeof useStateToast>
  signDialog: SignDialogStore
  channel?: Channel
}

class TaskListStore {
  constructor({ currentUser, api, toast, signDialog, channel }: TaskListStoreProps) {
    this.api = api
    this.toast = toast
    this.tasksMultiActions = new TasksMultiActionsStore({
      currentUser,
      api,
      toast,
      signDialog,
      channel,
    })
  }

  api: MobxApi
  toast: ReturnType<typeof useStateToast>
  tasksMultiActions: TasksMultiActionsStore

  openMultiActionDialog({ url, action }: { url: string; action: TaskAction }) {
    this.api
      .fetch({ type: 'tasks', url: `tasks/${url}` })
      // @ts-ignore
      .then((tasks: Collection<TaskEntity>) => {
        if (tasks.items.length === 0) {
          this.toast.success({ title: noTasksForActionMessages[action] })
        } else {
          this.tasksMultiActions.openDialog(action, tasks.items)
        }
      })
  }
}

interface TasksListPageProps extends PageProps {
  type: 'tasks' | 'acts'
}

const BaseTaskListPage = observer(({ history, match, type }: TasksListPageProps) => {
  let page = Number(match.params.page) || 1
  let { isMobile } = useMediaContext()
  let api = useMobxApi()
  let toast = useStateToast()
  let currentUser = useUserStore()

  let location = useLocation()
  let section = getHashState<string>(location, 'all')
  let paginationStore = useMemo(
    () =>
      new PaginationStore<TaskEntity>(
        ({ page, abortSignal }) =>
          api.fetch({
            type: 'tasks/page',
            url: `${type}/page/${page}`,
            payload: { filter: getHashState<string>(history.location, 'all') },
            options: { signal: abortSignal },
          }),
        page
      ),
    []
  )
  useMemo(() => paginationStore.setPage(page), [page, section])
  let { fetchedValue } = paginationStore

  usePusherSubscription('update-task', (event: any) => {
    let task = api.entities.tasks.get(event.id)
    if (task) {
      task.setData(event)
    }
  })

  let signDialog = useSignDialogStore()
  let channel = usePusherChannelContext()
  let store = useMemo(
    () => new TaskListStore({ currentUser, api, toast, signDialog, channel }),
    []
  )

  let menu
  if (currentUser.kind === 'company') {
    let actions = []
    if (type === 'tasks' && section === 'created') {
      actions.push(
        <AbilityMenuItem
          onClick={() =>
            store.openMultiActionDialog({ url: 'for_sign_terms', action: 'sign_terms' })
          }
          isDisabled={fetchedValue && fetchedValue.data.total_count === 0}
          ability={currentUser.hasAbility('tasks.sign_terms')}
        >
          Подписать все ТЗ
        </AbilityMenuItem>
      )
    }
    if (type === 'acts' && section === 'acceptance_ready') {
      actions.push(
        <AbilityMenuItem
          onClick={() =>
            store.openMultiActionDialog({ url: 'for_accept', action: 'accept' })
          }
          isDisabled={fetchedValue && fetchedValue.data.total_count === 0}
          ability={currentUser.hasAbility('tasks.accept')}
        >
          Подписать все акты
        </AbilityMenuItem>
      )
    }
    if (type === 'acts' && section === 'accepted') {
      let payAction = currentUser.companyPayAction
      let payActionNames = {
        pay: 'Оплатить все акты',
        mark_paid: 'Отметить все акты оплаченными',
        mark_paid_with_receipt: 'Отметить все акты оплаченными и сформировать чеки',
      }
      actions.push(
        <AbilityMenuItem
          ability={currentUser.hasAbility(`tasks.${payAction}`)}
          onClick={() =>
            store.openMultiActionDialog({ url: 'for_pay', action: payAction })
          }
          isDisabled={fetchedValue && fetchedValue.data.total_count === 0}
        >
          {payActionNames[payAction]}
        </AbilityMenuItem>
      )
    }
    menu = actions.length > 0 && (
      <Menu placement="bottom-end">
        <MenuButton
          style={{ outline: 'none', minWidth: 42, padding: 0 }}
          as={Button}
          design="normal"
        >
          <MoreIcon style={{ fill: 'var(--color-active)' }} />
        </MenuButton>
        <MenuList style={{ minWidth: 275 }}>{actions}</MenuList>
      </Menu>
    )
  }

  let buttons = []
  if (currentUser.kind === 'company' && currentUser.hasFeature('templates')) {
    buttons.push(
      <AbilityButton
        ability={currentUser.hasAbility('tasks.create')}
        onTap={() => history.push(type === 'tasks' ? '/tasks/new' : '/acts/new')}
        size={isMobile ? 's' : 'm'}
      >
        {isMobile ? 'Создать' : type === 'tasks' ? 'Создать задание' : 'Создать акт'}
      </AbilityButton>
    )
  }

  if (
    type === 'acts' &&
    currentUser.kind === 'contractor' &&
    currentUser.user!.data.contractor!.data.companies?.items.some(
      (c) => c.data.features.acts_create
    )
  ) {
    buttons.push(
      <Button onTap={() => history.push('/acts/new')} size={isMobile ? 's' : 'm'}>
        Создать акт
      </Button>
    )
  }

  let header = (
    <Flex justify="space-between" align="center" gap="1rem">
      <LayoutHeading count={fetchedValue && fetchedValue.data.total_count}>
        {type === 'tasks'
          ? currentUser.kind === 'company'
            ? 'Задания'
            : 'Мои задания'
          : 'Акты'}
      </LayoutHeading>
      <Flex gap="1rem">
        {buttons}
        {menu}
      </Flex>
    </Flex>
  )

  let onChangePage = (page: number) => history.push(`/${type}/page/${page}#${section}`)
  let onSectionChange = (section: string) => history.push(`/${type}/page/1#${section}`)
  let sections =
    type === 'acts' ? actsSections[currentUser.kind!] : tasksSections[currentUser.kind!]

  return (
    <Layout>
      <Col gap="2rem">
        {header}
        {store.tasksMultiActions.render()}
        <Chips
          items={Object.values(sections)}
          value={section}
          onChange={onSectionChange}
        />
        <PaginationView
          store={paginationStore}
          render={(collection) =>
            collection.isEmpty ? (
              <Placeholder>
                {section
                  ? sections[section].emptyText
                  : `Нет ${type === 'acts' ? 'актов' : 'заданий'}`}
              </Placeholder>
            ) : (
              <TaskList
                tasks={collection}
                options={{
                  kind: type,
                  who: true,
                  isSelectable: false,
                  isEditable: false,
                  actionButtons: false,
                }}
              />
            )
          }
          onChangePage={onChangePage}
        />
      </Col>
    </Layout>
  )
})

const TaskListPage = (props: any) => <BaseTaskListPage type="tasks" {...props} />
const ActListPage = (props: any) => <BaseTaskListPage type="acts" {...props} />

export { TaskListPage, ActListPage }
