import { Entity } from 'mobx/mobx'
import {
  CompanyEntity,
  OfferResponseStatus,
  OfferAction,
  OfferEntity,
  OfferForDateEntity,
  OfferResponseEntity,
} from 'entities'

export interface OfferData {
  id: number
  title: string
  description: string
  company: Entity<CompanyEntity>
  amount: string
  unit: string
  rate: string
  quantity: number
  work_areas: string[]
  city: string
  metro?: string
  address?: string
  date?: string
  status?: OfferResponseStatus
  actions: OfferAction[]
  published_status: string
}

const offerDataFromOffer = (offer: Entity<OfferEntity>) => {
  let data: any = { ...offer.data }
  if (offer.data.response) data.status = offer.data.response.data.status
  return data
}

const offerDataFromOfferForDate = (entity: Entity<OfferForDateEntity>) => {
  let { offer, date, response, actions } = entity.data
  let data: any = { ...offer.data }
  data.date = date
  data.actions = actions
  if (response) data.status = response.data.status
  return data
}

const offerDataFromResponse = (response: Entity<OfferResponseEntity>) => {
  let { offer, offer_date, status } = response.data
  let data: any = { ...offer.data }
  data.date = offer_date
  data.status = status
  data.actions = []
  return data
}

export { offerDataFromOffer, offerDataFromOfferForDate, offerDataFromResponse }
