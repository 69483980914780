import React, {
  useState,
  forwardRef,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from 'react'
import { useKey, useSetState } from 'react-use'

import { useList, useListKeyboardEvents } from 'ui/list'
import { useStyles, StyleProps, StylesMap } from 'ui/styled'
import { Taply, TapState, initialTapState } from 'ui/taply'
import { Dropdown } from 'ui/dropdown'
import { PopupPlacement } from 'ui/popup/PopupController'
import mergeRefs from 'ui/utils/mergeRefs'
import AutogrowInput from 'ui/utils/AutogrowInput'

/// useSelect

interface SelectState {
  isFocused: boolean
  isOpen: boolean
  query: string
  selectedIndex: number
}

const initialState = {
  isFocused: false,
  isOpen: false,
  query: '',
}

const useSelect = (props: SelectProps & typeof defaultProps) => {
  const {
    value,
    getOptionValue,
    onChange,
    openMenuOnFocus,
    options,
    filterFunction,
    isMulti,
    openMenuOnClick,
    closeMenuOnSelect,
  } = props
  const inputRef = useRef<HTMLInputElement>(null)
  const [state, setState] = useSetState(initialState)
  const { query, isOpen, isFocused } = state

  const filteredOptions = useMemo(
    () =>
      query === '' ? options : options.filter((option) => filterFunction(option, query)),
    [query, options, filterFunction]
  )

  const selectedValues = useMemo(() => {
    if (isMulti) {
      return asMultiValue(value).map(getOptionValue)
    } else {
      return value ? [getOptionValue(asSingleValue(value))] : []
    }
  }, [isMulti, value, getOptionValue])

  const isOptionSelected = useCallback(
    (option: any) => selectedValues.includes(getOptionValue(option)),
    [selectedValues, getOptionValue]
  )

  const onTap = useCallback(
    (event: any) => {
      if (!isFocused) {
        setTimeout(() => {
          inputRef.current?.focus()
          setState({ isOpen: true })
        })
      } else if (!isOpen) {
        if (openMenuOnClick) setState({ isOpen: true })
      } else {
        if (event.target === inputRef.current) return
        setState({ isOpen: false })
      }
      event.preventDefault()
    },
    [isOpen, isFocused, openMenuOnClick, setState]
  )

  const onFocus = useCallback(() => {
    const newState: Partial<SelectState> = { isFocused: true }
    if (openMenuOnFocus) newState.isOpen = true
    setState(newState)
  }, [openMenuOnFocus, setState])

  const onBlur = useCallback(() => {
    setState({ query: '', isOpen: false, isFocused: false })
  }, [setState])

  const onQueryChange = useCallback(
    (query: string) => {
      setState({ query, isOpen: true })
    },
    [setState]
  )

  const selectOption = useCallback(
    (option: any) => {
      let newValue
      if (isMulti) {
        const optionValue = getOptionValue(option)
        const isDeselected = isOptionSelected(option)
        const multiValue = asMultiValue(value)
        newValue = isDeselected
          ? multiValue.filter((option) => getOptionValue(option) !== optionValue)
          : [...multiValue, option]
      } else {
        newValue = option
      }
      onChange?.(newValue)
      const nextState: Partial<SelectState> = { query: '' }
      if (closeMenuOnSelect) nextState.isOpen = false
      setState(nextState)
    },
    [
      isMulti,
      value,
      onChange,
      setState,
      isOptionSelected,
      closeMenuOnSelect,
      getOptionValue,
    ]
  )

  const deleteOption = useCallback(
    (option: any) => {
      const optionValue = getOptionValue(option)
      const newValue = asMultiValue(value).filter(
        (option) => getOptionValue(option) !== optionValue
      )
      onChange?.(newValue)
    },
    [value, onChange, getOptionValue]
  )

  const deleteLastOption = useCallback(() => {
    const newValue = isMulti ? asMultiValue(value).slice(0, -1) : undefined
    onChange?.(newValue)
  }, [isMulti, value, onChange])

  return {
    props,
    inputRef,
    state,
    setState,
    isOptionSelected,
    filteredOptions,
    onTap,
    onFocus,
    onBlur,
    onQueryChange,
    selectOption,
    deleteOption,
    deleteLastOption,
  }
}

/// HELPERS

interface DummyInputProps extends React.HTMLProps<HTMLInputElement> {}

const dummyInputStyle: React.CSSProperties = {
  background: 0,
  border: 0,
  caretColor: 'transparent',
  fontSize: 'inherit',
  outline: 0,
  padding: 0,
  // without `width` browsers won't allow focus
  width: 1,
  // remove cursor on desktop
  color: 'transparent',
  // remove cursor on mobile
  left: -100,
  opacity: 0,
  position: 'relative',
  transform: 'scale(.01)',
}

const DummyInput = forwardRef<HTMLInputElement, DummyInputProps>((props, ref) => {
  const { style: _style, ...restProps } = props
  return <input ref={ref} {...restProps} style={{ ...props.style, ...dummyInputStyle }} />
})

/// COMPONENTS

interface SelectComponentProps {
  select: ReturnType<typeof useSelect>
}

export type SelectControlStyleProps = [SelectControlProps, { tapState: TapState }]

interface SelectControlProps
  extends SelectComponentProps,
    StyleProps<SelectControlStyleProps> {
  onTapStart: (event: MouseEvent | TouchEvent | KeyboardEvent) => void
}

const controlStyles = () => {
  const root = {
    display: 'flex',
    alignItems: 'center',
  }
  const container: React.CSSProperties = {
    flexGrow: 1,
    display: 'grid',
    gridArea: '1 / 1 / 1 / 1',
    boxSizing: 'border-box',
    overflow: 'hidden',
  }
  const placeholder: React.CSSProperties = {
    gridArea: '1 / 1 / 1 / 1',
  }
  const values: React.CSSProperties = {
    gridArea: '1 / 1 / 1 / 1',
    display: 'flex',
    flexWrap: 'wrap',
    overflow: 'hidden',
  }
  const input: React.CSSProperties = {
    gridArea: '1 / 1 / 1 / 1',
  }
  const indicators = {
    display: 'flex',
    alignItems: 'center',
  }
  return { root, container, placeholder, values, input, indicators }
}

const SelectControl = forwardRef<HTMLDivElement, SelectControlProps>((props, ref) => {
  const { select, onTapStart } = props
  const {
    props: selectProps,
    state,
    inputRef,
    onFocus,
    onBlur,
    onQueryChange,
    deleteOption,
  } = select
  const {
    isDisabled,
    isSearchable,
    components,
    isMulti,
    value,
    controlShouldRenderValue,
    getOptionLabel,
    getOptionValue,
    placeholder,
  } = selectProps
  const { query } = state

  const [tapState, setTapState] = useState(initialTapState)
  // @ts-ignore
  const styles = useStyles(controlStyles, [props, { tapState }])
  const innerInputRef = useRef<HTMLInputElement>(null)
  const mergedInputRef = mergeRefs(innerInputRef, inputRef)

  const input = isSearchable ? (
    <AutogrowInput
      style={styles.input}
      value={query}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => onQueryChange(e.target.value)}
      onFocus={onFocus}
      onBlur={onBlur}
      inputRef={mergedInputRef}
      disabled={isDisabled}
      key="__INPUT__"
    />
  ) : (
    <DummyInput
      style={styles.input}
      onFocus={onFocus}
      onBlur={onBlur}
      ref={mergedInputRef}
      disabled={isDisabled}
      key="__INPUT__"
    />
  )

  const hasValue = isMulti ? asMultiValue(value).length > 0 : value !== undefined
  const showPlaceholder = !hasValue && query === ''

  let controlValue: React.ReactNode
  if (controlShouldRenderValue && hasValue) {
    if (isMulti) {
      controlValue = asMultiValue(value).map((option) => (
        <components.MultiValue
          key={getOptionValue(option)}
          onDelete={() => deleteOption(option)}
          select={select}
        >
          {getOptionLabel(option)}
        </components.MultiValue>
      ))
    } else {
      controlValue = query === '' && (
        <components.SingleValue select={select}>
          {getOptionLabel(asSingleValue(value))}
        </components.SingleValue>
      )
    }
  }

  const onTapDropdownButton = (e: any) => {
    inputRef.current?.focus()
    e.preventDefault()
  }

  const indicators = (
    <div style={styles.indicators}>
      <components.DropdownButton onTap={onTapDropdownButton} select={select} />
    </div>
  )

  return (
    <Taply
      isDisabled={isDisabled}
      isFocusable={false}
      tapState={tapState}
      onChangeTapState={setTapState}
      onTapStart={onTapStart}
    >
      <div style={styles.root} ref={ref}>
        <div style={styles.container}>
          {showPlaceholder && (
            <components.Placeholder
              style={styles.placeholder}
              select={select}
              key="placeholder"
            >
              {placeholder}
            </components.Placeholder>
          )}
          {isMulti ? (
            <div style={styles.values}>
              {controlValue}
              {input}
            </div>
          ) : (
            <>
              <div style={styles.values}>{controlValue}</div>
              {input}
            </>
          )}
        </div>
        {indicators}
      </div>
    </Taply>
  )
})

SelectControl.displayName = 'SelectControl'

export interface SelectSingleValueProps
  extends SelectComponentProps,
    StyleProps<[SelectSingleValueProps]> {
  children: React.ReactNode
}

const SelectSingleValueC = (props: SelectSingleValueProps) => {
  const { children } = props
  return <div>{children}</div>
}

export interface SelectMultiValueProps
  extends SelectComponentProps,
    StyleProps<[SelectMultiValueProps]> {
  children: React.ReactNode
  onDelete: () => void
}

const SelectMultiValueC = (props: SelectMultiValueProps) => {
  const { children } = props
  return <div>{children}</div>
}

export interface SelectPlaceholderProps
  extends SelectComponentProps,
    StyleProps<[SelectPlaceholderProps]> {
  children: React.ReactNode
}

const SelectPlaceholder = (props: SelectPlaceholderProps) => {
  const { children } = props
  const styles = useStyles(undefined, [props])
  return <div style={styles.root}>{children}</div>
}

export interface SelectDropdownButtonProps
  extends SelectComponentProps,
    StyleProps<[SelectDropdownButtonProps]> {
  onTap: (e: any) => void
}

const SelectDropdownButton = () => {
  return <div>▼</div>
}

export interface SelectClearButtonProps
  extends SelectComponentProps,
    StyleProps<[SelectClearButtonProps]> {
  onTap: () => void
}

const SelectClearButton = () => {
  return <div>X</div>
}

interface SelectMenuProps extends SelectComponentProps, StyleProps<[SelectMenuProps]> {}

const menuStyles: StylesMap = {
  root: {
    overflowX: 'scroll',
  },
}

const SelectMenu = forwardRef<HTMLDivElement, SelectMenuProps>((props, ref) => {
  const { select } = props
  const [elementRef, setElementRef] = useState<HTMLDivElement | null>(null)
  const {
    state,
    props: selectProps,
    isOptionSelected,
    filteredOptions,
    selectOption,
  } = select
  const {
    components,
    noOptionsMessage,
    getOptionValue,
    getOptionLabel,
    isOptionDisabled,
  } = selectProps
  const { query } = state

  const styles = useStyles(menuStyles, [props])
  const list = useList<any>({
    items: filteredOptions,
    isItemDisabled: isOptionDisabled,
  })
  const { selectedIndex, setSelectedIndex, selectFirstItem } = list
  useListKeyboardEvents<any>(list, (item) => selectOption(item))
  const autoSelected = useRef(false)
  useEffect(() => {
    if (!autoSelected.current) {
      setTimeout(() => {
        selectFirstItem()
        autoSelected.current = true
      })
      // TODO clear timeout just in case
    }
  }, [selectFirstItem])

  useEffect(() => {
    selectFirstItem()
  }, [query, selectFirstItem])

  let content
  if (filteredOptions.length === 0) {
    content = (
      <components.NoOptionsMessage select={select} key="no_options">
        {typeof noOptionsMessage === 'function'
          ? noOptionsMessage(query)
          : noOptionsMessage}
      </components.NoOptionsMessage>
    )
  } else {
    content = filteredOptions.map((option, index) => {
      const value = getOptionValue(option)
      return (
        <components.Option
          key={value}
          select={select}
          value={value}
          isSelected={isOptionSelected(option)}
          isDisabled={isOptionDisabled(option)}
          isHovered={index === selectedIndex}
          onHover={() => setSelectedIndex(index)}
          onSelect={() => selectOption(option)}
        >
          {getOptionLabel(option)}
        </components.Option>
      )
    })
  }

  /**
   * На маках и мобильных устройствах CSS свойство scroll-behavior работает не так,
   * как на компах с ОС Windows. Каким-то образом это конфликтует с Taply и скролл пропадает.
   * @see https://github.com/iamdustan/smoothscroll#force-polyfill-implementation
   */
  const handleRef = (el: HTMLDivElement | null) => {
    if (typeof ref === 'function') {
      ref(el)
    } else if (ref) {
      ref.current = el
    }
    setElementRef(el)
  }

  useEffect(() => {
    const handleStopBubbling = (e: any) => {
      e.stopPropagation()
    }
    elementRef && elementRef.addEventListener('wheel', handleStopBubbling)
    elementRef && elementRef.addEventListener('touchstart', handleStopBubbling)
    elementRef && elementRef.addEventListener('touchmove', handleStopBubbling)
    return () => {
      elementRef && elementRef.removeEventListener('wheel', handleStopBubbling)
      elementRef && elementRef.removeEventListener('touchstart', handleStopBubbling)
      elementRef && elementRef.removeEventListener('touchmove', handleStopBubbling)
    }
  }, [elementRef])

  return (
    <div style={styles.root} ref={handleRef}>
      {content}
    </div>
  )
})

export interface SelectNoOptionsMessageProps
  extends SelectComponentProps,
    StyleProps<[SelectNoOptionsMessageProps]> {
  children: React.ReactNode
}

const SelectNoOptionsMessage = (props: SelectNoOptionsMessageProps) => {
  const { children } = props
  const styles = useStyles(undefined, [props])
  return <div style={styles.root}>{children}</div>
}

export interface SelectOptionProps
  extends SelectComponentProps,
    StyleProps<[SelectOptionProps]> {
  children: React.ReactNode
  value: string
  isSelected: boolean
  isHovered: boolean
  isDisabled: boolean
  onHover: () => void
  onSelect: () => void
}

const SelectOption = forwardRef<HTMLDivElement, SelectOptionProps>((props, ref) => {
  const { children, isDisabled, onHover, onSelect } = props
  const styles = useStyles(undefined, [props])
  return (
    <Taply
      onChangeTapState={({ isHovered }) => {
        if (isHovered) onHover()
      }}
      isDisabled={isDisabled}
      isFocusable={false}
      onTap={onSelect}
    >
      <div onMouseDown={onSelect} style={styles.root} ref={ref}>
        {children}
      </div>
    </Taply>
  )
})

/// SELECT

export type SelectSingleValueType = object | undefined
export type SelectMultiValueType = object[]
export type SelectValueType = SelectSingleValueType | SelectMultiValueType

const asMultiValue = (value: SelectValueType) => value as SelectMultiValueType
const asSingleValue = (value: SelectValueType) => value as SelectSingleValueType

export interface SelectComponents {
  Control: React.ComponentType<SelectControlProps>
  Placeholder: React.ComponentType<SelectPlaceholderProps>
  SingleValue: React.ComponentType<SelectSingleValueProps>
  MultiValue: React.ComponentType<SelectMultiValueProps>
  DropdownButton: React.ComponentType<SelectDropdownButtonProps>
  ClearButton: React.ComponentType<SelectClearButtonProps>
  Menu: React.ComponentType<SelectMenuProps>
  Option: React.ComponentType<SelectOptionProps>
  NoOptionsMessage: React.ComponentType<SelectNoOptionsMessageProps>
}

export type SelectStyleProps = [SelectProps & typeof defaultProps]

type NoOptionsMessageType = React.ReactNode | ((search: string) => React.ReactNode)

export interface SelectProps extends StyleProps<SelectStyleProps> {
  /** List of options */
  options: any[]

  /** Value of the select */
  value?: SelectValueType

  onChange?: (value: SelectValueType) => void

  onFocus?: () => void

  onBlur?: () => void

  className?: string

  components?: Partial<SelectComponents>

  isDisabled?: boolean

  /** Show clear button in the select */
  isClearable?: boolean

  /** Allows to type search query in the select */
  isSearchable?: boolean

  /** Custom function to filter options */
  filterFunction?: (option: any, query: string) => boolean

  /** Message to display when there are no options found */
  noOptionsMessage?: NoOptionsMessageType

  /** Allows to select multiple options */
  isMulti?: boolean

  /** Opens menu when user focuses select */
  openMenuOnFocus?: boolean

  /** Opens menu when user clicks on the control */
  openMenuOnClick?: boolean

  /** Closes menu after user selects and option */
  closeMenuOnSelect?: boolean

  /** Maximum height of the menu, in px. */
  maxHeight?: number

  /** Maximum width of the menu, in px. */
  maxWidth?: number

  /** Placement of the menu relative to the control */
  placement?: Partial<PopupPlacement>

  /** Placeholder that renders when select has no value */
  placeholder?: React.ReactNode

  /** Whether the control should render value */
  controlShouldRenderValue?: boolean

  getOptionValue?: (option: any) => string

  getOptionLabel?: (option: any) => React.ReactNode

  isOptionDisabled?: (option: any) => boolean
}

export const defaultComponents = {
  Control: SelectControl,
  Placeholder: SelectPlaceholder,
  Menu: SelectMenu,
  Option: SelectOption,
  SingleValue: SelectSingleValueC,
  MultiValue: SelectMultiValueC,
  NoOptionsMessage: SelectNoOptionsMessage,
  DropdownButton: SelectDropdownButton,
  ClearButton: SelectClearButton,
}

const defaultFilterFunction = (option: any, query: string) =>
  option?.label.toLowerCase().includes(query.toLowerCase())

const defaultPlacement = {
  side: 'bottom',
  align: 'start',
  offset: 4,
  flip: true,
  constrain: false,
  padding: 16,
}

const defaultProps = {
  value: undefined,
  components: defaultComponents,
  isClearable: false,
  isDisabled: false,
  isMulti: false,
  isSearchable: false,
  placement: defaultPlacement,
  // closeMenuOnSelect: undefined,
  openMenuOnClick: true,
  openMenuOnFocus: false,
  noOptionsMessage: 'No options' as NoOptionsMessageType,
  getOptionValue: (option: any) => option.value,
  getOptionLabel: (option: any) => option.label,
  isOptionDisabled: (option: any) => option.isDisabled,
  controlShouldRenderValue: true,
  filterFunction: defaultFilterFunction,
  maxHeight: 300,
}

const withDefaultProps = (props: SelectProps) => {
  const {
    components: _components,
    value: _value,
    closeMenuOnSelect,
    isMulti,
    ...restProps
  } = props
  return {
    components: { ...defaultProps.components, ...props.components },
    value: props.value === undefined && props.isMulti ? [] : props.value,
    isMulti,
    closeMenuOnSelect: closeMenuOnSelect === undefined ? !isMulti : closeMenuOnSelect,
    ...restProps,
  } as SelectProps & typeof defaultProps
}

const Select = (_props: SelectProps) => {
  const props = withDefaultProps(_props)
  const { placement, closeMenuOnSelect, components, style, maxHeight, maxWidth } = props

  const select = useSelect(props)
  const { state, setState, onTap, deleteLastOption } = select
  const { isFocused, isOpen, query } = state

  const openIfFocused = () => {
    if (isFocused && !isOpen) setState({ isOpen: true })
  }
  useKey('ArrowDown', openIfFocused, undefined, [isFocused, isOpen, setState])
  useKey('Enter', openIfFocused, undefined, [isFocused, isOpen, setState])
  useKey(
    'Backspace',
    () => {
      if (isFocused && query === '') deleteLastOption()
    },
    undefined,
    [query, isFocused, deleteLastOption]
  )

  const menu = (ref: any, { style }: { style: React.CSSProperties }) => (
    <components.Menu ref={ref} select={select} style={style} />
  )

  const onChangeIsOpen = (isOpen: boolean) => {
    const nextState: Partial<SelectState> = { isOpen }
    if (!isOpen) nextState.query = ''
    setState(nextState)
  }

  return (
    <Dropdown
      popup={menu}
      placement={placement}
      // @ts-ignore
      isOpen={isOpen}
      onChangeIsOpen={onChangeIsOpen}
      matchWidth={true}
      maxHeight={maxHeight}
      maxWidth={maxWidth}
      overlay={false}
      closeOnSelect={closeMenuOnSelect}
      closeOnClickOutside={false}
      focusLock={false}
    >
      {(ref: any) => (
        <components.Control ref={ref} onTapStart={onTap} select={select} style={style} />
      )}
    </Dropdown>
  )
}

Select.defaultProps = defaultProps

Select.displayName = 'Select'

export { Select }
