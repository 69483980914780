import React from 'react'

import { Reveal } from 'components/ui'
import { Logo } from 'ui'

import css from './styles.module.css'

const styles: { [key: string]: React.CSSProperties } = {
  loader: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logo: {
    opacity: 0.33,
  },
  content: {
    background: 'var(--color-background-secondary)',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
}

const LoadingScreen = ({ children }: { children?: React.ReactNode }) => {
  let loader = (
    <div style={styles.loader}>
      <Logo className={css.logo} />
    </div>
  )
  return (
    <>
      {loader}
      {children && (
        <Reveal animation="fadeIn" style={styles.content}>
          {children}
        </Reveal>
      )}
    </>
  )
}

export default LoadingScreen
