import React from 'react'
import cx from 'clsx'
import { Button, ButtonSize, ButtonVariant, H4, P1, Link } from 'ui-kit'
import { ReactComponent as Info } from 'assets/info-icon.svg'
import { ReactComponent as Logo } from 'assets/logo-circle.svg'
import { ReactComponent as QuestionIcon } from 'assets/question-mark.svg'

import css from './styles.module.css'

export enum NotificationType {
  Fail = 'fail',
  Progress = 'progress',
  Info = 'info',
  Question = 'question',
}

type NotificationProps = {
  type: NotificationType
  withLogo?: boolean
  title?: string
  description?: string
  linkHref?: string
  linkOnClick?: () => void
  linkText?: string
  buttonLabel?: string
  className?: string
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

const Notification: React.FunctionComponent<NotificationProps> = ({
  type,
  withLogo = false,
  title,
  description,
  linkHref,
  linkOnClick,
  linkText,
  buttonLabel,
  className,
  onClick,
}) => (
  <div className={cx(css.notification, css[type], className && className)}>
    <div className={css.leftWrapper}>
      {type === NotificationType.Question ? (
        <QuestionIcon className={css.icon} />
      ) : (
        <Info className={css.icon} />
      )}
      {withLogo && <Logo className={css.logo} />}
      <div className={css.textWrapper}>
        {title && <H4 className={css.title}>{title}</H4>}
        {description && <P1 className={css.description}>{description}</P1>}
        {linkHref && (
          <Link href={linkHref} className={css.link}>
            {linkText}
          </Link>
        )}
        {linkOnClick && (
          <Link onClick={linkOnClick} className={css.link}>
            {linkText}
          </Link>
        )}
      </div>
    </div>
    {onClick && (
      <Button
        variant={ButtonVariant.Primary}
        size={ButtonSize.Small}
        label={buttonLabel}
        onClick={onClick}
        className={css.button}
      ></Button>
    )}
  </div>
)

export default Notification
