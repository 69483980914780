import React from 'react'
import { Typography, TypographyProps, TypographyVariant } from 'ui-kit'

export type H1Props = Omit<TypographyProps, 'variant'>

const H1 = ({ children, ...restProps }: H1Props) => (
  <Typography variant={TypographyVariant.H1} {...restProps}>
    {children}
  </Typography>
)

export default H1
