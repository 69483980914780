import React, { forwardRef, useEffect, useRef } from 'react'
import { useSpring, animated, SpringConfig } from 'react-spring'

import functions from './functions'
import type { AnimationFunction } from './functions'
import configs from './springConfigs'

const { fade, translate, scale } = functions

interface AppearProps extends React.ComponentProps<typeof animated.div> {
  animation?: AnimationFunction | AnimationFunction[]
  config?: SpringConfig
  children?: React.ReactNode
  delay?: number
}

const defaultProps = {
  animation: fade(),
  delay: 0,
  config: configs.normal,
}

const Appear = forwardRef<HTMLDivElement, AppearProps>((_props: AppearProps, ref) => {
  const props = _props as AppearProps & typeof defaultProps
  const { children, animation, config, delay, style, ...restProps } = props
  const [spring, api] = useSpring(() => ({ value: 0, config }))
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>()
  useEffect(() => {
    timeoutRef.current = setTimeout(() => api.start({ value: 1 }), delay)
    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current)
    }
  }, [])
  const animatedStyle = functions.compose(animation)(spring.value)
  return (
    // @ts-ignore
    <animated.div ref={ref as any} style={{ ...style, ...animatedStyle }} {...restProps}>
      {children}
    </animated.div>
  )
})

Appear.defaultProps = defaultProps

const presets = {
  fade: fade(),
  slideDown: [fade(), translate({ vert: -10 })],
  slideUp: [fade(), translate({ vert: 10 })],
  slideRight: [fade(), translate({ horiz: -20 })],
  slideLeft: [fade(), translate({ horiz: 20 })],
  scale: [fade(), scale({ initialScale: 0.66 })],
}

const staticProps = { presets }
const AppearWithPresets = Appear as typeof Appear & typeof staticProps
AppearWithPresets.presets = presets

export default AppearWithPresets
