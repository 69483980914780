import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import cx from 'clsx'
import { clamp } from 'lodash'
import { Tooltip } from '@chakra-ui/react'

import { Collection } from 'mobx/mobx'
import { SubtaskTemplateEntity } from 'entities'

import { Amount } from 'components'
import { useMediaContext } from 'App/MediaContext'
import { IconButton, BaseButton, Flex, SelectInput, Input } from 'components/ui'
import NumberInput from 'components/NumberInput'

import { ReactComponent as DeleteGreyIcon } from 'assets/delete-grey.svg'
import { ReactComponent as InfoIcon } from '@material-design-icons/svg/round/info.svg'

import SubtaskTemplateSelector from './SubtaskTemplateSelector'
import type { Subtask } from './types'

import { toFixed } from 'utils/validations'

import s from './styles.module.css'

const roundButtonStyles = ({ isDisabled }: any, { isHovered, isPressed }: any) => ({
  root: {
    width: 24,
    height: 24,
    borderRadius: '50%',
    background: '#eef0f3',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'opacity, background 150ms ease-in-out',
    cursor: isDisabled ? 'not-allowed' : 'pointer',
    opacity: isDisabled ? 0.4 : 1,
    filter: isHovered || isPressed ? 'brightness(0.95)' : 'none',
  },
})

const RoundButton = (props: React.ComponentProps<typeof BaseButton>) => (
  <BaseButton {...props} styles={[roundButtonStyles, props.styles]} />
)

interface QuantityInputProps {
  value: number
  onChange: (value: number) => void
  min?: number
  max?: number
  allowDecimal?: boolean
}

const QuantityInput = ({ value, onChange, min, max }: QuantityInputProps) => {
  const decimalLimit = 4

  return (
    <Flex gap=".5rem" align="center">
      <RoundButton
        onTap={() => onChange(toFixed(Math.max(value - 1, min!), decimalLimit))}
        isDisabled={value <= min!}
      >
        −
      </RoundButton>
      <NumberInput
        allowDecimal={true}
        decimalLimit={decimalLimit}
        value={String(value)}
        onChange={(value) => {
          onChange(clamp(Number(value!), min!, max!))
        }}
        styles={{ root: { width: 45 }, input: { textAlign: 'center' } }}
      />
      <RoundButton
        onTap={() => onChange(toFixed(value + 1, decimalLimit))}
        isDisabled={value >= max!}
      >
        +
      </RoundButton>
    </Flex>
  )
}

QuantityInput.defaultProps = {
  min: -Infinity,
  max: Infinity,
}

interface SubtaskRowProps {
  subtask?: Subtask
  onChange?: (subtask: Subtask) => void
  canChangeCost?: boolean

  // отчёт о выполнении задачи
  origQuantity?: number
  origUnitCost?: string
  canChangeQuantity: boolean

  // редактирование списка работ при создании задачи
  isBlank: boolean
  templates?: Collection<SubtaskTemplateEntity>
  // units
  onSelectTemplate?: (id: number) => void
  canDelete: boolean
  onDelete?: () => void
  onEnter?: (value: string) => void

  // если добавлено только в акте
  isNew?: boolean
  units?: any
}

const SubtaskRow = (props: SubtaskRowProps) => {
  let { isMobile } = useMediaContext()

  let {
    subtask,
    templates,
    onSelectTemplate,
    isBlank,
    origQuantity,
    origUnitCost,
    canChangeQuantity,
    canChangeCost,
    onChange,
    canDelete,
    onDelete,
    onEnter,
    isNew,
    units,
  } = props

  const [editToggle, setEditToggle] = useState(false)
  const [editDescriptionValue, setEditDescriptionValue] = useState<string | undefined>()

  useEffect(() => {
    setEditDescriptionValue(subtask?.description)
  }, [subtask])

  const isCanSaveDescription = () => subtask?.description !== editDescriptionValue

  const onEditDescriptionHandle = () => {
    if (isCanSaveDescription()) {
      onChange!({ ...subtask!, description: editDescriptionValue! })
    }
    setEditToggle(false)
  }

  let InputRef: any = React.createRef()

  useEffect(() => {
    if (!editToggle) {
      InputRef?.current?.focus()
    }
  }, [editToggle])

  let nameCell
  if (isBlank) {
    nameCell = (
      <SubtaskTemplateSelector
        templates={templates!}
        onChange={onSelectTemplate}
        onEnter={onEnter}
      />
    )
  } else {
    nameCell = (
      <>
        <div className={s.title}>
          {isNew ? (
            <Input
              autoFocus
              value={subtask!.title}
              onChange={(value) =>
                onChange!({
                  ...subtask!,
                  title: String(value!),
                  description: String(value!),
                })
              }
            />
          ) : (
            subtask!.title
          )}
        </div>
        <Flex className={s.description} gap="8px">
          {editToggle && (
            <input
              autoFocus
              ref={InputRef}
              className={s.editDescriptionField}
              value={editDescriptionValue}
              onChange={(e) => setEditDescriptionValue(e.target.value)}
              onKeyDown={(e: any) =>
                e.key === 'Enter' ? onEditDescriptionHandle() : null
              }
            />
          )}
          {canChangeQuantity && canChangeCost ? (
            <Flex>
              {!isNew && (
                <button
                  type="button"
                  className={s.editLink}
                  onClick={() => {
                    setEditToggle(!editToggle)
                    if (editToggle) {
                      onEditDescriptionHandle()
                    }
                  }}
                >
                  {!editToggle && subtask!.description}
                  <span className={s.editLinkIcon}>
                    {!editToggle &&
                      (editDescriptionValue?.length === 0 ? 'Добавить описание' : ' ✎')}
                    {editToggle && '✔ Сохранить'}
                  </span>
                </button>
              )}
            </Flex>
          ) : (
            subtask!.description
          )}
        </Flex>
      </>
    )
  }

  let unitCostCell
  if (!isBlank) {
    if (canChangeCost) {
      const unitSelected = units
        ? units.find((item: any) => {
            return subtask!.unit_id
              ? item.value === subtask!.unit_id
              : item.label === subtask!.unit?.title
          })
        : null
      unitCostCell = (
        <Flex gap="0.5rem" align="baseline">
          <Flex gap="0.5rem" align="baseline">
            <NumberInput
              value={String(toFixed(Number(subtask!.unit_cost), 2))}
              onChange={(value) => {
                onChange!({ ...subtask!, unit_cost: value! })
              }}
              allowDecimal={true}
              allowMinus={true}
              styles={{ root: { width: 60 }, input: { textAlign: 'right' } }}
            />
          </Flex>
          /
          {unitSelected ? (
            <SelectInput
              className={s.unitField}
              options={units}
              onChange={(value) =>
                onChange!({
                  ...subtask!,
                  unit: { id: value.value, title: value.label },
                  unit_id: value.value,
                })
              }
              value={unitSelected}
            />
          ) : (
            <div className={s.unitField}>{subtask?.unit?.title}</div>
          )}
        </Flex>
      )
    } else {
      let { currency, unit_cost } = subtask!
      if (origUnitCost === undefined || origUnitCost === unit_cost) {
        unitCostCell = unit_cost !== null && (
          <Amount value={{ value: unit_cost, currency }} className={s.unitCost} />
        )
      } else {
        unitCostCell = (
          <>
            <Amount
              value={{ value: origUnitCost!, currency }}
              className={cx(s.unitCost, s.origUnitCost)}
            />
            <Amount
              value={{ value: unit_cost, currency }}
              className={cx(s.unitCost, s.reportedUnitCost)}
            />
            <Tooltip
              label="Исполнитель изменил стоимость работы в отчёте"
              aria-label="hz"
            >
              <InfoIcon className={s.unitCostInfo} />
            </Tooltip>
          </>
        )
      }
    }
  }

  let quantityCell
  if (!isBlank) {
    if (canChangeQuantity) {
      quantityCell = (
        <QuantityInput
          allowDecimal={true}
          min={0}
          value={subtask!.quantity}
          onChange={(value) => onChange!({ ...subtask!, quantity: value })}
        />
      )
    } else if (origQuantity !== undefined) {
      quantityCell = (
        <div
          className={cx(s.quantityBox, origQuantity === subtask!.quantity && s.ok)}
          title={`В задании было ${origQuantity}`}
        >
          × {subtask!.quantity}
        </div>
      )
    } else {
      quantityCell = `× ${subtask!.quantity}`
    }
  }

  let amountCell
  if (!isBlank) {
    let totalAmount =
      'amount' in subtask!
        ? subtask!.amount
        : (parseFloat(subtask!.unit_cost) || 0) * (subtask!.quantity || 0)
    amountCell = (
      <Amount
        value={{ value: totalAmount, currency: subtask!.currency }}
        className={s.amount}
      />
    )
  }

  let deleteCell
  if (canDelete && !isBlank) {
    deleteCell = (
      <IconButton onTap={onDelete!} tooltip="Удалить">
        <DeleteGreyIcon />
      </IconButton>
    )
  }

  if (isMobile) {
    return (
      <div className={s.subtask}>
        <div className={s.firstRow}>
          <div className={cx(s.cell, s.nameCell)}>{nameCell}</div>
          {canDelete && <div className={cx(s.cell, s.deleteCell)}>{deleteCell}</div>}
        </div>
        <div className={s.secondRow}>
          <div className={cx(s.cell, s.unitCostCell)}>{unitCostCell}</div>
          <div className={cx(s.cell, s.quantityCell)}>{quantityCell}</div>
          <div className={cx(s.cell, s.amountCell)}>{amountCell}</div>
        </div>
      </div>
    )
  }

  return (
    <div className={s.subtask}>
      <div className={cx(s.cell, s.nameCell)}>{nameCell}</div>
      <div className={cx(s.cell, s.unitCostCell)}>{unitCostCell}</div>
      <div className={cx(s.cell, s.quantityCell)}>{quantityCell}</div>
      <div className={cx(s.cell, s.amountCell)}>{amountCell}</div>
      <div className={cx(s.cell, s.deleteCell)}>{deleteCell}</div>
    </div>
  )
}

SubtaskRow.defaultProps = {
  isBlank: false,
  canDelete: false,
  canChangeQuantity: false,
}

export default observer(SubtaskRow)
