import React from 'react'
import { Switch, Route, Redirect, useHistory } from 'react-router'

interface SectionRouterProps {
  sections: { [key: string]: () => React.ReactNode }
  basePath?: string
  defaultSection?: string
  render?: (options: {
    section: string
    content: React.ReactNode
    setSection: (section: string) => void
  }) => React.ReactNode
}

const SectionRouter = ({
  sections,
  basePath = '',
  defaultSection,
  render,
}: SectionRouterProps) => {
  let history = useHistory()
  let setSection = (section: string) => history.push(`${basePath}/${section}`)
  return (
    <Switch>
      <Route
        path={`${basePath}/:section?`}
        //@ts-ignore
        render={({ match }) => {
          let section = match.params.section || defaultSection
          if (!sections[section!]) return <Redirect to={basePath} />
          let content = sections[section!]()
          return render ? render({ section: section!, content, setSection }) : content
        }}
      />
    </Switch>
  )
}

export default SectionRouter
