import React from 'react'
import cl from 'clsx'
import { InlineText } from 'ui-kit'

import css from './styles.module.css'

export type TableHeadersProps = {
  colNames?: (string | null)[]
  classNames?: (string | null)[]
}

const TableHeaders: React.FunctionComponent<TableHeadersProps> = ({
  colNames = [],
  classNames = [],
}) => {
  if (colNames.length === 0) {
    return null
  }

  return (
    <thead>
      <tr>
        {colNames.map((name, i) => (
          <th className={cl(css.th, classNames[i] && classNames[i])} key={i}>
            {name && <InlineText className={css.colNameText}>{name}</InlineText>}
          </th>
        ))}
      </tr>
    </thead>
  )
}

export default TableHeaders
