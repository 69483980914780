import { useState } from 'react'
import { upperFirst } from 'lodash'

const useControlledState = <T>(props: any, name: string, defaultState: T) => {
  const initialState = props[`initial${upperFirst(name)}`]
  const [localState, setLocalState] = useState(
    initialState === undefined ? defaultState : initialState
  )
  const state = name in props ? props[name] : localState
  const setState = (value: T) => {
    if (name in props) {
      const cb = props[`onChange${upperFirst(name)}`]
      if (cb) cb(value)
    } else {
      setLocalState(value)
    }
  }
  return [state, setState]
}

export default useControlledState
