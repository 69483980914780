export const responseStatusMap = {
  sent: { text: 'Отклик отправлен' },
  declined: { text: 'Отклик отклонён', color: 'red' },
  invited: { text: 'Отклик принят', color: 'green' },
  assigned: { text: 'Назначено задание', color: 'green' },
}

export const offerPuclishedStatusMap = {
  published: { text: 'Опубликовано', color: 'green' },
  unpublished: { text: 'Скрыто', color: 'red' }
}

export const workAreas = [
  'Курьер пешком',
  'Курьер на велосипеде',
  'Курьер на самокате',
  'Курьер на личном авто',
  'Перегонщик авто с правами категории B',
]

export const cities = [
  'Москва',
  'Санкт-Петербург',
  'Волгоград',
  'Екатеринбург',
  'Нижний Новгород',
  'Одинцово',
  'Пермь',
  'Тюмень',
  'Сочи',
  'Химки',
]
