import React from 'react'

import { Link } from './Link'
import { ReactComponent as ArrowLeft } from 'ui/konsol/icons/arrow-left.svg'

interface BackLinkProps {
  children?: React.ReactNode
  onClick?: () => void
}

const defaultProps = {
  children: 'Назад',
}

const BackLink = (_props: BackLinkProps) => {
  const props = _props as BackLinkProps & typeof defaultProps
  const { children, onClick } = props
  return (
    <Link onClick={onClick} icon={<ArrowLeft />}>
      {children}
    </Link>
  )
}

BackLink.defaultProps = defaultProps

export { BackLink }
