import React from 'react'
import { animated } from 'react-spring'
import useMeasure from 'react-use-measure'
import { ResizeObserver } from '@juggle/resize-observer'

interface AnimationProps {
  openValue: any
  children: React.ReactNode | ((ref: any) => React.ReactNode)
}

interface CollapseProps
  extends AnimationProps,
    Omit<React.HTMLProps<HTMLDivElement>, 'ref' | 'children'> {}

const CollapseAnimation = ({ openValue, children, ...restProps }: CollapseProps) => {
  const [ref, { height }] = useMeasure({
    polyfill: ResizeObserver,
  })

  const style: { [key: string]: any } = {
    opacity: openValue,
    overflow: 'visible',
    ...restProps.style,
  }

  style.height = openValue.interpolate({ output: [0, height] })

  return (
    <animated.div {...restProps} style={style}>
      {typeof children === 'function' ? (
        children(ref)
      ) : (
        <div ref={ref as any}>{children}</div>
      )}
    </animated.div>
  )
}

export default CollapseAnimation
