import React from 'react'

import { StyleProps, useStyles } from 'ui/styled'

interface DividerProps extends StyleProps<[DividerProps]> {
  className?: string
}

const dividerStyles = {
  root: {
    height: 0,
    width: '100%',
    border: 'none',
    borderBottom: '1px solid var(--konsol-color-grey-24)',
    margin: '4px 0',
  },
}

const Divider = (props: DividerProps) => {
  const styles = useStyles(dividerStyles, [props])
  return <hr style={styles.root} className={props.className} />
}

export { Divider }
