import { makeAutoObservable } from 'mobx'

import { UserStore, SignDialogStore } from 'stores/context'
import { Entity, initialActionState, Data } from 'mobx/mobx'
import { DocumentEntity } from 'entities'
import { useStateToast } from 'components/ui'

export const getSignedBy = (
  kind: 'contractor' | 'company',
  document: Entity<DocumentEntity>
) => {
  let { status } = document.data
  let signedByContractor = status === 'signed_by_contractor' || status === 'signed_by_all'
  let signedByCompany = status === 'signed_by_legal_entity' || status === 'signed_by_all'
  return {
    contractor: signedByContractor,
    company: signedByCompany,
    currentUser: kind === 'contractor' ? signedByContractor : signedByCompany,
    anySide: signedByContractor || signedByCompany,
    bothSides: signedByContractor && signedByCompany,
  }
}

interface DocumentStoreProps {
  document: Entity<DocumentEntity>
  toast: ReturnType<typeof useStateToast>
  currentUser: UserStore
  signDialog: SignDialogStore
}

class DocumentStore {
  constructor({ document, toast, currentUser, signDialog }: DocumentStoreProps) {
    this.document = document
    this.toast = toast
    this.currentUser = currentUser
    this.signDialog = signDialog
    makeAutoObservable(this)
  }

  document: Entity<DocumentEntity>
  currentUser: UserStore
  toast: ReturnType<typeof useStateToast>
  signDialog: SignDialogStore

  updateState = initialActionState
  uploadState = initialActionState
  deleteState = initialActionState

  openSignDialog() {
    this.document.action({ action: 'code_for_sign', options: { method: 'POST' } })
    let name = this.document.data.kind === 'contract' ? 'договор' : 'документ'
    let title = this.document.data.title || name
    this.signDialog.open({
      document: { title, url: this.document.data.file_url },
      text: `подписать ${name}`,
      onSign: this.sign.bind(this),
    })
  }

  sign(payload: Object | undefined) {
    let state = this.document.action({ action: 'sign', payload })
    state.then((data: Data) => this.document.setData(data))
    return state
  }

  update(values: any) {
    let { title, date, comment } = values
    let payload: any = { title }
    if (values.date) payload.date = date.format('YYYY-MM-DD')
    if (values.comment) payload.comment = comment
    this.updateState = this.document.update({ payload, optimistic: true })
    this.updateState.then(null, (error) =>
      this.toast.error({ title: 'Не удалось сохранить', description: error.message })
    )
  }

  get signedBy() {
    return getSignedBy(this.currentUser.kind!, this.document)
  }
}

export default DocumentStore
