const searchStrings = (strings: Array<string | undefined>, query: string) => {
  if (query.length === 0) return true
  let lowerCaseQuery = query.toLowerCase()
  for (let i = 0; i < strings.length; i++) {
    let str = strings[i]
    if (typeof str === 'string' && str.toLowerCase().includes(lowerCaseQuery)) return true
  }
  return false
}

const splitQueryToTerms = (query: string) => {
  let terms = query.split(' ').filter((s) => s.length > 0)
  return terms.length > 0 ? terms : undefined
}

const stripPhoneSymbols = (query: string) => query.replace(/[-()+]/g, '')

const searchStringsWithTerms = (strings: Array<string | undefined>, terms: string[]) =>
  terms.every((term) => searchStrings(strings, term))

export default searchStrings
export { splitQueryToTerms, searchStringsWithTerms, stripPhoneSymbols }
