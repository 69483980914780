import React, { useState } from 'react'
import { fromPromise } from 'mobx-utils'

import useForm from 'hooks/useForm'
import { normalizePhone, validatePhoneInternational } from 'utils/validations'
import { useMediaContext } from 'App/MediaContext'

import { Button, PhoneInput, Field, Flex, Col, Appear, Notification } from 'ui'

import { register } from '../../../requests'

interface PhoneStepProps {
  permalink: string
  query: Object
  onComplete: (data?: any) => void
}

const PhoneStep = (props: PhoneStepProps) => {
  const { permalink, query, onComplete } = props

  const { isMobile } = useMediaContext()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')

  const { handleSubmit, handleChange, values } = useForm({
    validations: {
      phone: {
        custom: {
          isValid: (value) => validatePhoneInternational(value),
          message: '',
        },
      },
    },
    initialValues: {
      phone: '+7',
    },
    onSubmit: (data) => {
      if (phoneIsComplete) {
        const submitPhone = '+' + normalizePhone(data.phone)
        setIsLoading(true)
        setError('')

        let registerData: any = {
          phone: submitPhone,
          query,
        }
        if (permalink) registerData = { ...registerData, permalink }

        fromPromise(register(registerData)).then(
          () => {
            onComplete(submitPhone)
            setIsLoading(false)
          },
          (error) => {
            setError(error.message)
            setIsLoading(false)
          }
        )
      }
    },
  })

  // @ts-ignore
  const phoneIsComplete = validatePhoneInternational(values.phone)

  return (
    <Col gap="20px">
      <form onSubmit={handleSubmit} noValidate>
        <Field label="Введите номер телефона">
          <Flex direction={isMobile ? 'col' : 'row'} gap={isMobile ? 16 : 10}>
            <PhoneInput
              size="l"
              autofocus={true}
              value={values?.phone as string}
              onChange={(value) => handleChange('phone', value)}
              style={{ flexGrow: 1 }}
            />
            <Button isLoading={isLoading} isDisabled={!phoneIsComplete} size="l">
              Продолжить
            </Button>
          </Flex>
        </Field>
      </form>
      {error && (
        <Appear animation={Appear.presets.scale}>
          <Notification kind="error">{error}</Notification>
        </Appear>
      )}
    </Col>
  )
}

export default PhoneStep
