import React from 'react'
import { Typography, TypographyProps, TypographyVariant } from 'ui-kit'

export type P1Props = Omit<TypographyProps, 'variant'>

const P1 = ({ children, ...restProps }: P1Props) => (
  <Typography variant={TypographyVariant.P1} {...restProps}>
    {children}
  </Typography>
)

export default P1
