import React from 'react'

import { useStyles, StyleProps } from 'ui/styled'
import { Row } from 'ui/flex'
import { ReactComponent as InfoIcon } from 'ui/konsol/icons/info.svg'

interface NotificationProps extends StyleProps<[NotificationProps]> {
  kind?: 'success' | 'warning' | 'error' | 'info'
  children: React.ReactNode
  className?: string
}

const defaultProps = {
  kind: 'info',
}

const colors = {
  success: {
    background: 'var(--konsol-color-green-08)',
    color: 'var(--konsol-color-green)',
  },
  warning: {
    background: 'var(--konsol-color-orange-08)',
    color: 'var(--konsol-color-orange)',
  },
  error: { background: 'var(--konsol-color-red-08)', color: 'var(--konsol-color-red)' },
  info: {
    background: 'var(--konsol-color-lightblue-08)',
    color: 'var(--konsol-color-lightblue)',
  },
}

const notificationStyles = (props: NotificationProps & typeof defaultProps) => {
  const { color, background } = colors[props.kind]
  const root = {
    display: 'inline-block',
    padding: '8px 12px',
    borderRadius: 6,
    color,
    fill: color,
    background,
    fontSize: 'var(--konsol-font-size-medium)',
    lineHeight: 'var(--konsol-line-height)',
  }
  const icon = {
    display: 'flex',
    alignItems: 'center',
    height: 13 * 1.5, // text height
  }
  return { root, icon }
}

const Notification = (_props: NotificationProps) => {
  const props = _props as NotificationProps & typeof defaultProps
  const { className, children } = props
  const styles = useStyles(notificationStyles, [props])
  return (
    <div className={className} style={styles.root}>
      <Row gap="6px" align="start">
        <div style={styles.icon}>
          <InfoIcon />
        </div>
        <div>{children}</div>
      </Row>
    </div>
  )
}

Notification.defaultProps = defaultProps

export { Notification }
