import React, { useEffect, useRef, useState } from 'react'
import cl from 'clsx'
import css from './styles.module.css'
import {
  EVENT_KEY_ARROW_LEFT,
  EVENT_KEY_ARROW_RIGHT,
  EVENT_KEY_BACKSPACE,
} from 'utils/event-key'
import { InlineText, Link, P1 } from 'ui-kit'

enum CodeInputSize {
  Large = 'large',
  Small = 'small',
  Medium = 'medium',
}

export type CodeInputProps = {
  size?: CodeInputSize
  title?: string
  error?: boolean
  className?: string
  onFocus?: () => void
  onSubmit: (code: string) => void
  getCode: () => void
}

const initialTimerValue = 59

const CodeInput: React.FunctionComponent<CodeInputProps> = ({
  size = CodeInputSize.Medium,
  title,
  error = false,
  className,
  onFocus,
  onSubmit,
  getCode,
}) => {
  const [activeCell, setActiveCell] = useState<number>(0)
  const [codeNumbers, setCodeNumbers] = useState<string[]>(['', '', '', ''])
  const [code, setCode] = useState<string>('')
  const [timer, setTimer] = useState<number>(initialTimerValue)
  const inputsRefs = useRef<(HTMLInputElement | null)[]>([])

  const createRefHandler = (i: number) => (el: HTMLInputElement) =>
    (inputsRefs.current[i] = el)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!window.isFinite(Number(event.target.value))) {
      return
    }

    const newCodeNumbers = [...codeNumbers]
    newCodeNumbers[activeCell] = event.target.value
    setCodeNumbers(newCodeNumbers)

    if (event.target.value === '') {
      if (activeCell - 1 >= 0) {
        setActiveCell(activeCell - 1)
      }
    } else if (activeCell + 1 < newCodeNumbers.length) {
      setActiveCell(activeCell + 1)
    }
  }

  const handleKeydown = (event: React.KeyboardEvent) => {
    if (event.key === EVENT_KEY_ARROW_RIGHT && activeCell + 1 < codeNumbers.length) {
      setActiveCell(activeCell + 1)
    } else if (event.key === EVENT_KEY_ARROW_LEFT && activeCell - 1 >= 0) {
      setActiveCell(activeCell - 1)
    } else if (
      event.key === EVENT_KEY_BACKSPACE &&
      codeNumbers[activeCell] === '' &&
      activeCell - 1 >= 0
    ) {
      setActiveCell(activeCell - 1)
    } else if (
      codeNumbers[activeCell].length === 1 &&
      window.isFinite(Number(event.key))
    ) {
      const newCodeNumbers = [...codeNumbers]
      newCodeNumbers[activeCell] = event.key
      setCodeNumbers(newCodeNumbers)

      if (activeCell + 1 < codeNumbers.length) {
        setActiveCell(activeCell + 1)
      }
    }
  }

  const createFocusHandler = (i: number) => () => {
    if (i !== activeCell) {
      setActiveCell(i)
    }
    if (onFocus) {
      onFocus()
    }
  }

  const handleGetCode = () => {
    setTimer(initialTimerValue)
    getCode()
  }

  useEffect(() => {
    getCode()
    inputsRefs.current[0]?.focus()
    setTimeout(() => {
      setTimer(timer - 1)
    }, 1000)

    return () => {
      setTimer(0)
    }
  }, [])

  useEffect(() => {
    inputsRefs.current[activeCell]?.focus()
  }, [activeCell])

  useEffect(() => {
    if (code.length === codeNumbers.length) {
      onSubmit(code)
    }
  }, [code])

  useEffect(() => {
    setCode(codeNumbers.join(''))
  }, [codeNumbers])

  useEffect(() => {
    if (timer - 1 >= 0) {
      setTimeout(() => {
        setTimer(timer - 1)
      }, 1000)
    }
  }, [timer])

  return (
    <div className={cl(css.codeInput, css[size], className && className)}>
      {title && <P1 className={css.title}>{title}</P1>}
      <div className={cl(css.codeNumbers)}>
        {codeNumbers.map((codeNumber, i) => {
          return (
            <input
              type="text"
              key={i}
              ref={createRefHandler(i)}
              className={cl(css.textField, error && css.error)}
              maxLength={1}
              value={codeNumber}
              onChange={handleChange}
              onKeyDown={handleKeydown}
              onFocus={createFocusHandler(i)}
            />
          )
        })}
      </div>
      {timer > 0 && (
        <P1 className={css.timerText}>
          Повторно отправить код можно через:{' '}
          <InlineText className={css.timerSeconds}>{timer} сек</InlineText>
        </P1>
      )}
      {timer === 0 && (
        <Link onClick={handleGetCode} className={css.getCode}>
          Отправить код повторно
        </Link>
      )}
    </div>
  )
}

export default CodeInput
