import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Entity } from 'mobx/mobx'

import { UserRegistrationEntity } from 'entities'
import { ConnectToPlatformStore } from 'pages/registration/store/ConnectToPlatformStore'

import { Flex, SimpleBackLink } from 'components/ui'

import { Spinner } from 'ui'

import SelectKindStep from '../SelectKindStep'
import JoinAsSelfEmployedStep from '../JoinAsSelfEmployedStep'
import JoinAsIpStep from '../JoinAsIpStep'
import SelfRegisterSelfEmployedStep from '../SelfRegisterSelfEmployedStep'
import RegistrationFooter from '../Footer'

const Step: any = ({
  store,
  onLoading,
}: {
  store: ConnectToPlatformStore
  onLoading: (value: boolean) => void
}) => {
  switch (store.state.kind) {
    case 'initial':
      return <SelectKindStep store={store} />
    case 'self_employed':
      return <JoinAsSelfEmployedStep store={store} />
    case 'individual_entrepreneur':
      return <JoinAsIpStep store={store} />
    case 'register_self_employed':
      return <SelfRegisterSelfEmployedStep store={store} />
    case 'register_non_resident': {
      onLoading(true)
      store.joinAsNonResident().then(() => onLoading(false))
      break
    }
    case 'gph': {
      onLoading(true)
      store.joinAsGPH().then(() => onLoading(false))
      break
    }
  }
  return null
}

const ConnectToPlatformStep = observer(
  ({
    registration,
    store,
  }: {
    registration: Entity<UserRegistrationEntity>
    store: ConnectToPlatformStore
  }) => {
    const [isLoading, setIsLoading] = useState(false)

    let { kind } = store.state

    if (isLoading)
      return (
        <Flex align="center" justify="center" style={{ flex: 1 }}>
          <Spinner fill="var(--konsol-color-primary)" />
        </Flex>
      )

    return (
      <>
        <Step store={store} onLoading={(isLoading: boolean) => setIsLoading(isLoading)} />
        {kind !== 'initial' && <RegistrationFooter kind={kind} />}
      </>
    )
  }
)

export default ConnectToPlatformStep
