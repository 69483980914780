import React from 'react'

import { Option } from '../Option'
import { DropdownButton } from '../DropdownButton'

import { DateFilterValue, DateFilterType, NotFilledFilterValue } from 'entities'
import { parseDate, serializeDate } from 'utils/datetime'

import { Flex, Col } from 'components/ui'
import DatePicker from 'components/ui/DatePicker'

import { NOT_FILLED_VALUE, isNotFilledValue } from '../../constants'

interface FilterProps<T> {
  initialFocusRef?: any
  value?: T
  onChange: (value: T) => void
  onClose: () => void
  withNotFilled?: boolean
}

interface ParsedDateFilterValue {
  type: DateFilterType
  from?: Date
  to?: Date
}

export const dateOptions = {
  today: 'Сегодня',
  yesterday: 'Вчера',
  current_week: 'Текущая неделя',
  current_month: 'Текущий месяц',
  period: 'Период',
}

const parseDateFilterValue = ({ type, from, to }: DateFilterValue) => ({
  type,
  from: from ? parseDate(from).toDate() : undefined,
  to: to ? parseDate(to).toDate() : undefined,
})

const serializeDateFilterValue = ({ type, from, to }: ParsedDateFilterValue) => ({
  type,
  from: from ? serializeDate(from) : undefined,
  to: to ? serializeDate(to) : undefined,
})

const DateFilter = ({
  value,
  withNotFilled,
  onChange,
  onClose,
}: FilterProps<DateFilterValue | NotFilledFilterValue>) => {
  const isNotFilledValueSelected = isNotFilledValue(value)
  const { type, from, to } =
    value && !isNotFilledValueSelected
      ? parseDateFilterValue(value)
      : { type: undefined, from: undefined, to: undefined }

  return (
    <Col gap="var(--gap-s)">
      {Object.entries(dateOptions).map(([key, value]) => (
        <Option
          isSelected={type === key}
          key={key}
          onSelect={() => onChange({ type: key as DateFilterType })}
        >
          {value}
        </Option>
      ))}
      {type === 'period' && (
        <Flex gap="1rem" style={{ marginLeft: '2.25rem', marginTop: '-.5rem' }}>
          <Flex gap=".5rem" align="center">
            <div>От</div>
            <DatePicker
              style={{ minWidth: 110 }}
              value={from}
              onChange={(value) =>
                onChange(
                  serializeDateFilterValue({
                    type: 'period',
                    from: value as Date | undefined,
                    to,
                  })
                )
              }
              selectsRange={false}
              isClearable={true}
            />
          </Flex>
          <Flex gap=".5rem" align="center">
            <div>До</div>
            <DatePicker
              style={{ minWidth: 110 }}
              value={to}
              onChange={(value) =>
                onChange(
                  serializeDateFilterValue({
                    type: 'period',
                    from,
                    to: value as Date | undefined,
                  })
                )
              }
              selectsRange={false}
              isClearable={true}
            />
          </Flex>
        </Flex>
      )}
      {withNotFilled && (
        <Option
          isSelected={isNotFilledValueSelected}
          key="not_filled"
          onSelect={() => onChange(NOT_FILLED_VALUE)}
        >
          Не заполнена
        </Option>
      )}
      <DropdownButton onTap={onClose}>Готово</DropdownButton>
    </Col>
  )
}

export default DateFilter
