import { useState, useRef } from 'react'

import { useFirstMountState } from 'react-use'
// import useFirstRender from './useFirstRender'
import useLastValue from './useLastValue'

export interface UseItProps<C, P, S> {
  initialCtx: C | (() => C)
  props: P
  initialState: S
}

export type It<C extends {}, P, S> = C & {
  props: P
  state: S
  setState: (newState: Partial<S>) => void
}

// `it` is like `this` in class components.
// It has properties `props`, `state` and `setState`, and can be used to store
// any properties.
const useIt = <C, P, S>({
  initialCtx,
  props,
  initialState,
}: UseItProps<C, P, S>): It<C, P, S> => {
  const isFirstMount = useFirstMountState()
  const ref = useRef(
    isFirstMount && typeof initialCtx === 'function' ? (initialCtx as any)() : initialCtx
  )
  const ctx = ref.current
  const getProps = useLastValue(props)
  const [state, setState] = useState(
    typeof initialState === 'function' && isFirstMount ? initialState(ctx) : initialState
  )
  const getState = useLastValue(state)
  if (isFirstMount) {
    Object.defineProperty(ctx, 'props', { get: getProps })
    Object.defineProperty(ctx, 'state', { get: getState })
    Object.defineProperty(ctx, 'setState', {
      value: (newState: Partial<S>) => {
        setState({ ...getState(), ...newState })
      },
    })
  }
  return ctx as It<C, P, S>
}

export default useIt
