import { useState, useEffect } from 'react'
import { throttle } from 'lodash'

export enum CurrentBreakpoint {
  Mobile = 'mobile',
  Desktop = 'desktop',
}

const getDeviceConfig = (width: number): CurrentBreakpoint => {
  if (width <= 768) {
    return CurrentBreakpoint.Mobile
  }

  return CurrentBreakpoint.Desktop
}

const useBreakpoint = (): CurrentBreakpoint => {
  const [brkPnt, setBrkPnt] = useState(() => getDeviceConfig(window.innerWidth))

  useEffect(() => {
    const calcInnerWidth = throttle(function () {
      setBrkPnt(getDeviceConfig(window.innerWidth))
    }, 200)
    window.addEventListener('resize', calcInnerWidth)
    return () => window.removeEventListener('resize', calcInnerWidth)
  }, [])

  return brkPnt
}

export default useBreakpoint
