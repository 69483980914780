import React, { useMemo } from 'react'
import cx from 'clsx'

import { H1, Flex, icons, Appear } from 'ui'

import css from './styles.module.css'

interface StepperProps {
  steps: {
    id: string
    title: string
    heading?: string
    render: () => React.ReactNode
  }[]
  step: any
}

const StepsController = ({ steps, step }: StepperProps) => {
  let foundActive = false

  const items = useMemo(
    () =>
      steps.map((item: any, index: number) => {
        let isActive = step.id === item.id
        let isCompleted = !foundActive
        if (isActive) foundActive = true

        return (
          <div
            className={cx(css.step, isActive && css.active, isCompleted && css.completed)}
          >
            <Appear delay={100 * index} animation={Appear.presets.slideDown}>
              <Flex direction="col" className={css.stepWrapper}>
                <div className={css.indexNumber}>
                  {isCompleted && !isActive ? (
                    <icons.Checkbox
                      fill="var(--konsol-color-primary)"
                      width="18px"
                      height="18px"
                    />
                  ) : (
                    index + 1
                  )}
                </div>
                <div className={css.line} />
              </Flex>
              <div className={css.stepTitle}>{item.title}</div>
            </Appear>
          </div>
        )
      }),
    [steps, step]
  )

  return (
    <Flex direction="col" gap="20px">
      <Flex>{items}</Flex>
      <div className={css.content}>
        {step.heading && <H1 style={{ marginBottom: '2rem' }}>{steps[step].heading}</H1>}
        {step.render()}
      </div>
    </Flex>
  )
}

export default StepsController
