import * as React from 'react'
import { Button, Field, Flex, FormRow, Input, Link, useStateToast } from 'components/ui'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { initialActionState } from 'mobx/MobxApi'
import clsx from 'clsx'
import { internationalPhoneMask, normalizePhone } from 'utils/validations'
import { useMediaContext } from 'App/MediaContext'
import { Notification } from 'components/Notification'

import s from './styles.module.css'
import { createJoinCompanyForm } from './join_company_store'
import joinCompanyApi from './api'
import FileInput from 'components/FileInput'

type FileRowProps = {
  icon: 'passport_main' | 'passport_registration' | 'selfie'
  field: string
  label: React.ReactNode
}

const FileRow = ({ icon, field, label }: FileRowProps) => {
  return (
    <Field field={field}>
      {({ inputProps }) => (
        <div className={clsx(s.docWrapper, s[`docWrapper--${icon}`])}>
          <div className={s.fileInputLabel}>{label}</div>
          <FileInput label="Загрузить" accept=".tiff, .jpg, .pdf, .png" {...inputProps} />
        </div>
      )}
    </Field>
  )
}

export const JoinCompanyForm = observer(() => {
  let form = useLocalObservable(createJoinCompanyForm)
  let { isMobile } = useMediaContext()
  let size: 'm' | 'l' = isMobile ? 'm' : 'l'

  let toast = useStateToast()

  let [submitState, setSubmitState] = React.useState(initialActionState)
  let [isDone, setDone] = React.useState(false)
  let onSubmit = () => {
    let {
      full_name,
      email,
      mobile_phone,
      passport_main,
      passport_registration,
      passport_selfie,
    } = form.values
    let state = joinCompanyApi.registerMember({
      full_name,
      email,
      phone: normalizePhone(mobile_phone),
      passport_main,
      passport_registration,
      passport_selfie,
    })
    state.then(
      () => {
        toast.success({ title: 'Заявка отправлена' })
        setDone(true)
      },
      (error: Error) => toast.error({ title: 'Ошибка', description: error.message })
    )
    setSubmitState(state)
  }

  if (isDone && submitState.state === 'fulfilled')
    return (
      <Notification type="success" title="Мы получили вашу заявку и уже проверяем ее">
        Как только руководитель вашей организации подпишет доверенность, мы пришлем вам
        СМС.
      </Notification>
    )

  return (
    <Flex gap="2rem" direction="column">
      <Flex gap="1rem" direction="column">
        <div>
          Please contact us at <Link href='mailto:hello@restaff.pro' target='_blank'>hello@restaff.pro</Link> if you have any issues
        </div>
        <Field field={form.fields.full_name}>
          {({ inputProps, error }) => (
            <FormRow label="ФИО" size={size} error={error}>
              <Input
                {...inputProps}
                isWide
                size={size}
                placeholder="Петров Андрей Алексеевич"
                autoFocus
              />
            </FormRow>
          )}
        </Field>
        <Field field={form.fields.mobile_phone}>
          {({ inputProps, error }) => (
            <FormRow
              label="Телефон"
              size={size}
              error={error}
              caption="Будем уведомлять о подписанных документах"
            >
              <Input
                {...inputProps}
                mask={internationalPhoneMask}
                numericKeyboard
                isWide
                size={size}
              />
            </FormRow>
          )}
        </Field>
        <Field field={form.fields.email}>
          {({ inputProps, error }) => (
            <FormRow
              label="E-mail"
              size={size}
              error={error}
              caption="Будем уведомлять о подписанных документах"
            >
              <Input
                {...inputProps}
                isWide
                size={size}
                placeholder="yourmail@domain.ru"
              />
            </FormRow>
          )}
        </Field>
      </Flex>

      <Flex gap="1rem" direction="column">
        <div>
          Для полноценной работы в сервисе нам необходимо вас идентифицировать. После
          этого мы сможем автоматически создавать все необходимые документы, а вы —
          подписывать их электронной подписью.
        </div>

        <FileRow
          icon="passport_main"
          field={form.fields.passport_main}
          label="Основной разворот паспорта"
        />
        <FileRow
          icon="passport_registration"
          field={form.fields.passport_registration}
          label="Разворот паспорта с пропиской"
        />
        <FileRow
          icon="selfie"
          field={form.fields.passport_selfie}
          label="Селфи с паспортом"
        />
      </Flex>

      <Button
        isDisabled={!form.isValid}
        isLoading={submitState.state === 'pending'}
        onTap={onSubmit}
        style={{
          alignSelf: 'start',
        }}
      >
        Далее
      </Button>

      <span className={s.subText}>
        Нажимая на кнопку «Далее», я подтверждаю, что я ознакомлен и согласен с{' '}
        <Link href="https://konsol.pro/agreement" target="_blank">
          пользовательским соглашением
        </Link>{' '}
        и{' '}
        <Link href="https://konsol.pro/policy" target="_blank">
          условиями предоставления персональных данных
        </Link>
      </span>
    </Flex>
  )
})
