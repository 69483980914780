import { useMediaContext } from 'App/MediaContext'
import { Button, DatePicker, Flex, FormRow, H2, Modal } from 'components/ui'
import { DateRange } from 'components/ui/DatePicker'
import * as React from 'react'

type ExportInvitesModalProps = {
  onClose: () => void
  onSubmit: (range: DateRange) => Promise<void>
}

export const ExportInvitesModal = ({ onClose, onSubmit }: ExportInvitesModalProps) => {
  const { isMobile } = useMediaContext()
  const [range, setRange] = React.useState<DateRange | undefined>()
  const [isLoading, setIsLoading] = React.useState<boolean>(false)

  const isValid = range && range[0] && range[1]

  const handleSubmit = () => {
    if (!range) return
    setIsLoading(true)
    onSubmit(range).then(onClose)
  }

  return (
    <Modal
      closeOnOverlayClick={false}
      size={isMobile ? '97%' : '500px'}
      onClose={onClose}
      isOpen
    >
      <Flex direction="column" gap="var(--gap-m)">
        <H2>Экспортировать в xlsx</H2>
        <FormRow label="Период">
          <DatePicker
            value={range}
            selectsRange
            onChange={(date) => setRange(date as DateRange)}
            size="l"
          />
        </FormRow>
        <Button
          style={{ marginTop: 24 }}
          isDisabled={!isValid}
          isLoading={isLoading}
          onTap={handleSubmit}
        >
          Скачать
        </Button>
      </Flex>
    </Modal>
  )
}
