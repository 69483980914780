import React, { forwardRef } from 'react'
import { animated, SpringValue } from 'react-spring'

import type { AnimationFunction } from './functions'
import functions from './functions'

export interface OpenAnimationProps extends React.HTMLAttributes<HTMLDivElement> {
  openValue: SpringValue
  animation: AnimationFunction | AnimationFunction[]
  children?: React.ReactNode
}

const OpenAnimation = forwardRef<HTMLDivElement, OpenAnimationProps>(
  (props: OpenAnimationProps, ref) => {
    const { children, openValue, animation, style, ...restProps } = props
    return (
      <animated.div
        ref={ref}
        style={{ ...style, ...functions.compose(animation)(openValue) }}
        {...restProps}
      >
        {children}
      </animated.div>
    )
  }
)

export default OpenAnimation
