import React from 'react'

import { Flex } from 'components/ui'

import s from './styles.module.css'

interface InfoListProps {
  title?: React.ReactNode
  children: React.ReactNode
}

interface InfoListItemProps {
  label?: string
  children?: React.ReactNode
}

let InfoList = ({ title, children }: InfoListProps) => (
  <Flex direction="column" gap="1.5rem" className={s.list}>
    {title && <div className={s.title}>{title}</div>}
    {children}
  </Flex>
)

let InfoListItem = ({ label, children }: InfoListItemProps) => {
  return (
    <div className={s.item}>
      <div className={s.label}>{label}</div>
      {children && <div className={s.value}>{children}</div>}
    </div>
  )
}

let InfoListSubtitle = (props: React.HTMLProps<HTMLDivElement>) => 
    <div className={s.subtitle} {...props} />

export { InfoList, InfoListItem, InfoListSubtitle }
