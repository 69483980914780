import React, { useState, useRef, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Link } from 'react-router-dom'
import { confetti } from 'dom-confetti'

import { useMediaContext } from 'App/MediaContext'
import { Button, Flex, Layout, Link as UiLink, H1 } from 'components/ui'

import s from './styles.module.css'
import { useUserStore } from 'stores/context'

interface StepProps {
  onComplete: () => void
}

const TaxBoxEnabledStep = observer(({ onComplete: _onComplete }: StepProps) => {
  return (
    <Flex direction="column" gap="2rem">
      <H1>Вам подключена налоговая копилка</H1>
      <Button style={{ alignSelf: 'flex-start' }}>Дальше</Button>
    </Flex>
  )
})

const UnpaidTaxesStep = observer(({ onComplete: _onComplete }: StepProps) => {
  const currentUser = useUserStore()
  if (currentUser.user!.data.contractor!.data.tax_box!.data.is_flexible) {
    return (
      <Flex direction="column" gap="2rem">
        <H1>Вам подключена налоговая копилка:</H1>
        <ul>
          <li>
            Копилка показывает сумму начисленных налогов, долгов и уведомления от
            налоговой
          </li>
          <li>
            Автоматически откладывает 6% с доходов от юрлиц и 4% с доходов от физлиц
          </li>
          <li>
            Учитывает налоговый бонус (если есть бонус, то в копилку откладывается меньшая
            сумма с учетом бонуса)
          </li>
          <li>Дает возможность откладывать налог на доход полученный вне системы</li>
          <li>Автоматически оплачивает налог после выставления счета налоговой</li>
        </ul>
      </Flex>
    )
  }

  return (
    <Flex direction="column" gap="2rem">
      <H1>Вам подключена налоговая копилка</H1>
      <div>Мы обнаружили у вас неоплаченные на текущий момент налоги.</div>
      <div>
        Мы можем удержать сумму из ближайшей выплаты по акту и оплатить налоги за вас,
        либо вы можете оплатить самостоятельно.
      </div>
      <Button style={{ alignSelf: 'flex-start' }}>Удержать из ближайшей выплаты</Button>
      <UiLink>Я оплачу самостоятельно</UiLink>
    </Flex>
  )
})

const CompletedStep = observer(() => {
  let { isMobile } = useMediaContext()
  let confettiRef = useRef<HTMLDivElement>(null)
  let confettiConfig = {
    angle: 60,
    spread: 60,
    startVelocity: 40,
    elementCount: 50,
    dragFriction: 0.15,
    duration: 3500,
    stagger: 5,
    width: '12px',
    height: '12px',
  }
  useEffect(() => {
    setTimeout(
      () =>
        !isMobile && confettiRef.current && confetti(confettiRef.current, confettiConfig),
      300
    )
  }, [])
  return (
    <div className={s.completedStep}>
      <div className={s.partyImage}>
        <div className={s.confetti} ref={confettiRef} />
      </div>
      <Flex direction="column" gap="2rem">
        <H1>Спасибо за регистрацию!</H1>
        <ul style={{ maxWidth: 650 }}>
          <li className={s.li}>Новые задания будут появляться в разделе "Мои задания"</li>
          <li className={s.li}>Акты можно будет подписать в разделе "Акты"</li>
          <li className={s.li}>Сменить банковские реквизиты можно в профиле</li>
          <li className={s.li}>
            Налоги и баланс копилки можно проверить в разделе "Налоги"
          </li>
          <li className={s.li}>
            По любым вопросам мы с радостью ответим в чате поддержки или в любом удобном
            мессенджере
          </li>
        </ul>
        <Button as={Link} to="/" style={{ alignSelf: 'flex-start' }}>
          Перейти к сервису
        </Button>
      </Flex>
    </div>
  )
})

const RegistrationCompletedPage = observer(() => {
  let [step, setStep] = useState('completed')

  let content
  if (step === 'tax_box_enabled') {
    content = <TaxBoxEnabledStep onComplete={() => setStep('unpaid_taxes')} />
  } else if (step === 'unpaid_taxes') {
    content = <UnpaidTaxesStep onComplete={() => setStep('unpaid_taxes')} />
  } else if (step === 'completed') {
    content = <CompletedStep />
  }

  return <Layout smallPaddingTop>{content}</Layout>
})

export default RegistrationCompletedPage
