import React, { useState, useEffect } from 'react'

import { H2, Appear, useTheme } from 'ui'

import useSmoothScrollTo from 'hooks/useSmoothScrollTo'

import css from './styles.module.css'

const AsideItem = ({
  iconName,
  text,
  index,
}: {
  iconName: string
  text: string
  index: number
}) => {
  const { getJoinIcon } = useTheme()
  const [icon, setIcon] = useState()

  useEffect(() => {
    getJoinIcon(iconName).then(setIcon)
  }, [])

  return (
    <>
      <li>
        <Appear delay={(index + 3) * 100} animation={Appear.presets.scale}>
          <div className={css.icon}>{icon}</div>
        </Appear>
        <Appear delay={(index + 3) * 100} animation={Appear.presets.slideLeft}>
          <div dangerouslySetInnerHTML={{ __html: text }} />
        </Appear>
      </li>
    </>
  )
}

const Aside = () => {
  const bind = useSmoothScrollTo('#about')

  const { config } = useTheme()

  const text = config.text.pages.join
  const list: any[] = text.list

  return (
    <div {...bind} className={css.container}>
      <Appear delay={300}>
        <H2 style={{ marginBottom: 60 }}>
          <span dangerouslySetInnerHTML={{ __html: text.asideTitle }} />
        </H2>
      </Appear>
      <ul className={css.list}>
        {list.map((item, index) => (
          <AsideItem
            key={item.text}
            iconName={item.icon}
            text={item.text}
            index={index}
          />
        ))}
      </ul>
    </div>
  )
}

export default Aside
