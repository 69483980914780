import React, { useMemo, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { runInAction } from 'mobx'
import cx from 'clsx'

import { useMediaContext } from 'App/MediaContext'
import { badges } from 'App/badges'
import { usePusherSubscription } from 'App/pusher'
import { useMobxApi, Entity, Collection } from 'mobx/mobx'
import { NotificationEntity } from 'entities'
import {
  ActionStateView,
  Placeholder,
  Layout,
  LayoutHeading,
  List,
  ListHeader,
  ListItem,
  ListCell,
  Flex,
} from 'components/ui'
import { printIsoDateTime } from 'utils/datetime'

import c from './styles.module.css'

const NotificationListItem = observer(
  ({ notification }: { notification: Entity<NotificationEntity> }) => {
    let { isMobile } = useMediaContext()
    let [isOpen, setIsOpen] = useState(false)
    let onClick = () => {
      if (isOpen) {
        setIsOpen(false)
      } else {
        setIsOpen(true)
        if (!notification.data.read) notification.action({ action: 'mark_as_read' })
        runInAction(() => {
          notification.data.read = true
        })
      }
    }
    let markerCell = (
      <ListCell width={24} className={c.markerCell}>
        <div className={c.marker} />
      </ListCell>
    )

    let dateCell = (
      <ListCell width={isMobile ? undefined : 180} className={c.dateCell}>
        {printIsoDateTime(notification.data.date)}
      </ListCell>
    )
    let fromCell = (
      <ListCell width={isMobile ? undefined : 180} className={c.fromCell}>
        {notification.data.from}
      </ListCell>
    )
    let titleCell = (
      <ListCell className={c.titleCell} width={isMobile ? '100%' : undefined}>
        {notification.data.title}
      </ListCell>
    )
    let contentCell = isOpen && (
      <ListCell width="100%" className={c.contentCell}>
        {notification.data.content}
      </ListCell>
    )
    return (
      <>
        <ListItem
          className={cx(
            c.item,
            !notification.data.read && c.isUnread,
            isOpen && c.isOpen
          )}
          onClick={onClick}
        >
          {isMobile ? (
            <>
              <ListCell width="100%" style={{ marginBottom: '.5rem' }}>
                <Flex gap="1rem" justify="space-between" align="center">
                  {markerCell}
                  {fromCell}
                  {dateCell}
                </Flex>
              </ListCell>
              {titleCell}
              {contentCell}
            </>
          ) : (
            <>
              {markerCell}
              {fromCell}
              {titleCell}
              {dateCell}
              {contentCell}
            </>
          )}
        </ListItem>
      </>
    )
  }
)

const NotificationsList = observer(
  ({ notifications }: { notifications: Collection<NotificationEntity> }) => {
    let { isMobile } = useMediaContext()
    return (
      <List>
        {!isMobile && (
          <ListHeader>
            <ListCell width={24} className={c.markerCell} />
            <ListCell width={180}>От кого</ListCell>
            <ListCell>Сообщение</ListCell>
            <ListCell width={180}>Время</ListCell>
          </ListHeader>
        )}
        {notifications.items.map((notification) => (
          <NotificationListItem key={notification.id} notification={notification} />
        ))}
      </List>
    )
  }
)
const NotificationsPage = observer(() => {
  let api = useMobxApi()
  let fetchState = useMemo(() => api.fetch({ type: 'notifications' }), [])
  return (
    <Layout>
      <LayoutHeading count={badges.unread_notifications} style={{ marginBottom: '2rem' }}>
        Уведомления
      </LayoutHeading>
      <ActionStateView state={fetchState}>
        {(notifications) => {
          usePusherSubscription('update-notifications', () => {
            notifications.fetch()
          })

          return notifications.isEmpty ? (
            <Placeholder>У вас нет уведомлений</Placeholder>
          ) : (
            <NotificationsList notifications={notifications} />
          )
        }}
      </ActionStateView>
    </Layout>
  )
})

export default NotificationsPage
