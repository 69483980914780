import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import cx from 'clsx'
import { nbsp } from 'utils/typo'

import { ConnectToPlatformStore } from '../../store/ConnectToPlatformStore'
import { useMediaContext } from 'App/MediaContext'
import { fetchApi } from '../../../../api'

import { Button, Flex, H1 } from 'components/ui'

import DownloadMoiNalogModal from '../DownloadMoiNalogModal'
import FnsPartnerBlock from '../FnsPartnerBlock'
import PhoneOrInnForm from '../PhoneOrInnForm'
import Faq from '../Faq'

import css from '../../styles.module.css'
import { Link } from 'ui-kit'

const JoinAsSelfEmployedStep = observer(
  ({ store }: { store: ConnectToPlatformStore }) => {
    let [showDownloadMoiNalog, setshowDownloadMoiNalog] = useState(false)
    let downloadMoiNalogModal = showDownloadMoiNalog && (
      <DownloadMoiNalogModal onClose={() => setshowDownloadMoiNalog(false)} />
    )

    let { isMobile } = useMediaContext()

    let { step } = store.state
    let content
    if (step === 'initial') {
      content = (
        <>
          <FnsPartnerBlock />
          <Button
            onTap={() => {
              store.state.step = 'enter_phone_or_inn'
            }}
          >
            Предоставить доступ
          </Button>
        </>
      )
    } else if (step === 'enter_phone_or_inn') {
      let isValid =
        store.form.values.kind === 'phone'
          ? store.form.fields.phone.isValid
          : store.form.fields.inn.isValid
      content = (
        <>
          <div className={css.title}>Отправьте запрос в приложение «Мой Налог»</div>
          <div>
            Укажите телефон, с которым вы регистрировались на сайте ФНС, либо ваш ИНН.
          </div>
          <PhoneOrInnForm form={store.form} />
          <Button
            isDisabled={!isValid}
            isLoading={store.actionState.state === 'pending'}
            onTap={() => {
              fetchApi({
                url: 'users/events',
                method: 'PUT',
                data: { event: 'registration_send_fns' },
              })
              store.sendJoinAsSelfEmployedRequest()
            }}
            style={{ marginTop: '2rem' }}
          >
            Отправить запрос
          </Button>
          {/* @ts-ignore */}
          {store.actionState.value?.result === 'fail' && (
            <div
              className={cx(css.message, css.error)}
              // тут приходит ссылка (<a>)
              // @ts-ignore
              dangerouslySetInnerHTML={{ __html: store.actionState.value?.message }}
            />
          )}
        </>
      )
    } else if (step === 'not_self_employed') {
      content = (
        <>
          <div className={css.title}>Вы не зарегистрированы как самозанятый</div>
          <div>
            Мы не смогли подтвердить ваш статус самозанятости. Вы можете пройти
            регистрацию прямо сейчас, это займёт всего 10 минут.
          </div>
          <Flex
            gap={isMobile ? '1rem' : '2rem'}
            align="center"
            style={{ marginTop: '2rem' }}
          >
            <Button onTap={() => store.goToRegisterSelfEmployed()}>
              Перейти к регистрации
            </Button>
            <Link
              className={css.link}
              onClick={() => {
                store.state.step = 'enter_phone_or_inn'
              }}
            >
              Назад
            </Link>
          </Flex>
        </>
      )
    } else if (step === 'request_sent') {
      content = (
        <>
          <div className={css.title}>
            Подтвердите подключение в приложении «Мой налог»
          </div>
          <div>
            Перейдите в раздел "Прочее" → "Партнеры" → "Доступные" и подтвердите
            подключение.
          </div>
          <Flex
            gap={isMobile ? '1rem' : '2rem'}
            align="center"
            style={{ marginTop: '2rem' }}
          >
            <Button
              onTap={() => store.checkJoinAsSelfEmployedRequest()}
              isLoading={store.actionState.state === 'pending'}
            >
              Проверить
            </Button>
            <Link
              className={css.link}
              onClick={() => {
                store.state.step = 'enter_phone_or_inn'
              }}
            >
              Назад
            </Link>
          </Flex>
        </>
      )
    }

    return (
      <Flex direction="column" gap="1.5rem">
        <H1>Предоставьте доступ к «Мой{nbsp}Налог»</H1>
        <div style={{ maxWidth: 650 }}>
          Доступ нужен, чтобы мы&nbsp;отслеживали актуальность статуса самозанятого
          и&nbsp;формировали чеки.
        </div>
        <Flex direction="column" gap="2rem" style={{ fontSize: 18 }} align="start">
          <Button design="normal" onTap={() => setshowDownloadMoiNalog(true)}>
            Что такое «Мой{nbsp}Налог»
          </Button>
        </Flex>
        {downloadMoiNalogModal}
        <Flex
          direction="column"
          gap="1rem"
          className={css.block}
          align={isMobile ? 'stretch' : 'start'}
        >
          {content}
        </Flex>
        <Faq />
      </Flex>
    )
  }
)

export default JoinAsSelfEmployedStep
