import React, { useState } from 'react'

import Chips from 'components/Chips'

import { Flex } from 'ui'

import { useUserStore } from 'stores/context'

import AnalyticsAccessBanner from 'components/AnalyticsAccessBanner'

import ContractorTrustRisks from './ContractorTrustRisks'
import ContractorStopListRisks from './ContractorStopListRisks'
import ContractorAnalyticsRisks from './ContractorAnalyticsRisks'

interface ContractorRisksProps {
  id: number
  name: string
  blacklisted: boolean
  self_employed: boolean
  contractor: any
}

export const ContractorRisks = (props: ContractorRisksProps) => {
  const currentUser = useUserStore()

  const { self_employed } = props

  const hasTrustRiskFeature =
    currentUser.company?.data.features.check_contractor_spectrum_data
  const hasBlacklistFeature =
    currentUser.company?.data.features.contractors_blacklist &&
    currentUser.company?.data.enable_blacklist
  const hasAnalyticsFeature = currentUser.company?.data.features.contractors_risk_profiles
  const hasAnalyticsPlaceholder =
    !hasAnalyticsFeature &&
    currentUser.company?.data.features.contractors_risk_placeholder

  const sections = [
    ...(self_employed && hasAnalyticsFeature
      ? [
          {
            id: 'analytics',
            title: 'Аналитика рисков',
          },
        ]
      : []),
    ...(self_employed && hasAnalyticsPlaceholder
      ? [
          {
            id: 'analytics_placeholder',
            title: 'Аналитика рисков',
          },
        ]
      : []),
    ...(hasTrustRiskFeature
      ? [
          {
            id: 'trust',
            title: 'Благонадежность',
          },
        ]
      : []),
    ...(self_employed && hasBlacklistFeature
      ? [
          {
            id: 'stop_list',
            title: 'Стоп-лист',
          },
        ]
      : []),
  ]

  const [tab, setTab] = useState<string>(sections[0]?.id || '')

  return (
    <Flex direction="col" gap="15px">
      <Chips items={sections} value={tab} onChange={setTab} />
      {tab === 'analytics' && <ContractorAnalyticsRisks {...props} />}
      {tab === 'analytics_placeholder' && <AnalyticsAccessBanner />}
      {tab === 'trust' && (
        <div style={{ marginTop: '20px' }}>
          <ContractorTrustRisks {...props} />
        </div>
      )}
      {tab === 'stop_list' && (
        <div style={{ marginTop: '20px' }}>
          <ContractorStopListRisks {...props} />
        </div>
      )}
    </Flex>
  )
}

export default ContractorRisks
