import React, { Fragment } from 'react'
import { useState } from 'react'

import { Popover, PopoverContent, PopoverTrigger, Portal } from '@chakra-ui/react'
import { Flex } from 'components/ui'

import classes from './classes.module.css'

interface IUnitPopoverProps {
  item: any
}

export const UnitPopover = ({ item }: IUnitPopoverProps) => {
  const [isOpen, setIsOpen] = useState(false)

  const unitName = (item.units[0] && item.units[0].title) || 'Не указано'
  const unitLabel =
    item.units.length > 1
      ? `Ещё ${item.units.length - 1} ${item.units.length === 2 ? 'работа' : 'работы'}`
      : ''

  const handleOpen = () => setIsOpen(true)
  const handleClose = () => setIsOpen(false)

  const unitItems = item.units.map((item: any) => ({
    label: item.title || 'Не указано',
    value: `${item.cost} x ${item.quantity} = ${item.total_cost} ₽`,
  }))

  return (
    <div style={{ display: 'flex' }}>
      <Flex direction="column" gap="1px">
        <span>{unitName}</span>
        {unitLabel && (
          <Popover placement="right" closeOnBlur={false} isOpen={isOpen}>
            {/* @ts-ignore */}
            <PopoverTrigger>
              <span
                style={{ color: '#5d35fe', borderRight: '5px solid transparent' }}
                onMouseEnter={handleOpen}
                onMouseLeave={handleClose}
              >
                {unitLabel}
              </span>
            </PopoverTrigger>
            <Portal>
              <PopoverContent
                className={classes.popover}
                bg="#272E40"
                color="white"
                minWidth="225px"
                border="0"
                width="auto"
              >
                <div className={classes.container}>
                  {unitItems.map((item: any, idx: any) => (
                    <Fragment key={idx}>
                      <span style={{ fontWeight: 500 }}>{item.label}</span>
                      <span style={{ fontWeight: 500, color: '#858DA2' }}>
                        {item.value}
                      </span>
                    </Fragment>
                  ))}
                  <div className={classes.arrow} />
                </div>
              </PopoverContent>
            </Portal>
          </Popover>
        )}
      </Flex>
    </div>
  )
}

export default UnitPopover
