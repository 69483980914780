import React, { useMemo, useState } from 'react'
import { observer } from 'mobx-react-lite'

import { useMobxApi, initialActionState, ActionState, Data } from 'mobx/mobx'
import { OfferResponseStatus } from 'entities'
import { useUserStore } from 'stores/context'
import { useMediaContext } from 'App/MediaContext'
import { PageProps } from 'app.types'
import { Amount, Avatar } from 'components'
import {
  ActionStateView,
  Layout,
  Col,
  Flex,
  useStateToast,
  Button,
  H1,
  BackLink,
  Card,
} from 'components/ui'
import { printDate } from 'utils/datetime'

import {
  OfferData,
  offerDataFromOffer,
  offerDataFromOfferForDate,
  offerDataFromResponse,
} from './govno'
import { fetchApi } from 'api'

const statuses: { [key in OfferResponseStatus]: React.ComponentProps<typeof Card> } = {
  sent: {
    kind: 'info',
    title: 'Вы откликнулись на это предложение',
    children: 'Ждите рассмотрения отклика компанией',
  },
  invited: {
    kind: 'success',
    title: 'Компания приняла ваш отклик на это задание',
    children: 'Компания отправит вам предложение о сотрудничестве',
  },
  declined: { kind: 'error', title: 'Компания отклонила ваш отклик на это задание' },
  assigned: {
    kind: 'success',
    title: 'Компания приняла ваш отклик на это задание',
    children: 'Вам назначено задание',
  },
}

interface ItemProps {
  label: React.ReactNode
  children: React.ReactNode
  style?: any
}

const Item = ({ label, children, style }: ItemProps) => (
  <div style={style}>
    <div style={{ marginBottom: 4, color: 'var(--color-secondary)' }}>{label}</div>
    <div>{children}</div>
  </div>
)

const OfferDataView = observer(
  ({ offer, respond }: { offer: OfferData; respond?: () => ActionState<Data> }) => {
    let { isMobile } = useMediaContext()
    let toast = useStateToast()
    let currentUser = useUserStore()

    let [respondState, setRespondState] = useState(initialActionState)
    let onRespond = () => {
      fetchApi({
        url: 'users/events',
        method: 'PUT',
        data: { event: 'marketplace_offer_response' },
      })
      let state = respond!()
      state.then(
        () => toast.success({ title: 'Отклик отправлен' }),
        () => toast.error({ title: 'Не удалось отправить отклик' })
      )
      setRespondState(state)
    }

    let status, button
    if (currentUser.kind === 'contractor') {
      if (offer.status) status = <Card {...statuses[offer.status]} />
      if (offer.actions.includes('respond')) {
        button = (
          <Button
            isLoading={respondState.state === 'pending'}
            onTap={onRespond}
            style={{ alignSelf: isMobile ? 'stretch' : 'start' }}
          >
            Откликнуться
          </Button>
        )
      }
    }

    return (
      <Layout style={{ maxWidth: 800 }} smallPaddingTop>
        <BackLink to="/offers">К предложениям</BackLink>
        <Col gap="1rem">
          <H1>{offer.title}</H1>
          <Item label="Заказчик">
            <Flex gap=".5rem" align="center">
              <Avatar
                size="28px"
                name={offer.company.data.name}
                value={offer.company.data.avatar_url}
              />
              <div style={{ fontWeight: 600 }}>{offer.company.data.name}</div>
            </Flex>
          </Item>
          {offer.date && <Item label="Дата выхода">{printDate(offer.date)}</Item>}
          <Item label="Стоимость">
            <Amount value={offer.amount} style={{ fontWeight: 600 }} />
          </Item>
          <Flex gap="1rem" style={{ width: isMobile ? '100%' : 450 }}>
            <Item label="Стоимость услуги" style={{ flexBasis: 0, flexGrow: 1 }}>
              <Amount value={offer.rate} />/{offer.unit}
            </Item>
            <Item label="Количество" style={{ flexBasis: 0, flexGrow: 1 }}>
              {offer.quantity}
            </Item>
          </Flex>
          <Item label="Вид услуги">{offer.work_areas.join(', ')}</Item>
          <Flex gap="1rem" style={{ width: isMobile ? '100%' : 450 }}>
            <Item label="Город" style={{ flexBasis: 0, flexGrow: 1 }}>
              {offer.city}
            </Item>
            {offer.metro && (
              <Item label="Метро" style={{ flexBasis: 0, flexGrow: 1 }}>
                {offer.metro}
              </Item>
            )}
          </Flex>
          {offer.address && <Item label="Адрес">{offer.address}</Item>}
          <Item label="Описание">
            <div style={{ whiteSpace: 'pre-line' }}>{offer.description}</div>
          </Item>
          {offer.company.data.description && (
            <Item label="О компании">{offer.company.data.description}</Item>
          )}
          {status}
          {button}
        </Col>
      </Layout>
    )
  }
)

const OfferPage = observer((props: PageProps) => {
  let { match } = props
  let id = match.params.id
  let api = useMobxApi()
  let fetchState = useMemo(() => api.fetch({ type: 'offers', id }), [])
  return (
    <ActionStateView state={fetchState}>
      {(entity) => (
        <OfferDataView
          offer={offerDataFromOffer(entity)}
          respond={() => {
            let state = entity.action({ action: 'respond' })
            state.then((data: Data) => entity.setData(data))
            return state
          }}
        />
      )}
    </ActionStateView>
  )
})

const OfferForDatePage = observer((props: PageProps) => {
  let { match } = props
  let id = match.params.id
  let api = useMobxApi()
  let fetchState = useMemo(
    () => api.fetch({ type: 'offers/for_date', id, url: `offers/recommendations/${id}` }),
    []
  )
  return (
    <ActionStateView state={fetchState}>
      {(entity) => (
        <OfferDataView
          offer={offerDataFromOfferForDate(entity)}
          respond={() => {
            let state = entity.action({
              action: 'respond',
              url: `offers/recommendations/${entity.id}/respond`,
            })
            state.then((data: Data) => entity.setData(data))
            return state
          }}
        />
      )}
    </ActionStateView>
  )
})

const OfferResponsePage = observer((props: PageProps) => {
  let { match } = props
  let id = match.params.id
  let api = useMobxApi()
  let fetchState = useMemo(() => api.fetch({ type: 'offers/responses', id }), [])
  return (
    <ActionStateView state={fetchState}>
      {(entity) => <OfferDataView offer={offerDataFromResponse(entity)} />}
    </ActionStateView>
  )
})

export { OfferPage, OfferForDatePage, OfferResponsePage }
