import React from 'react'
import cl from 'clsx'

import css from './styles.module.css'

export enum TypographyVariant {
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  P1 = 'p1',
  P2 = 'p2',
  Span = 'span',
}

export type TypographyProps = {
  variant?: TypographyVariant
  className?: string
  children: React.ReactNode
}

const Typography: React.FunctionComponent<TypographyProps> = ({
  variant = TypographyVariant.P1,
  children,
  className,
}) =>
  React.createElement(
    variant === TypographyVariant.P1 || variant === TypographyVariant.P2 ? 'p' : variant,
    {
      className: cl(css.typography, css[variant], className && className),
    },
    children
  )

export default Typography
