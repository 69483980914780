import React from 'react'
import { Card } from 'components/ui'
import { ITableCell, ITableCol } from '../Table'

import s from './styles.module.css'
import { DateCell } from '../DateCell'
import { v4 as uuid } from 'uuid'

interface CompanyRisksCardProps {
  cols: ITableCol[]
  row: ITableCell[]
}

const CompanyRisksCard = ({ cols, row }: CompanyRisksCardProps) => {
  return (
    <Card kind="secondary" className={s.card}>
      {row.map((cell, index) => (
        <div className={s.cardContent} key={uuid()}>
          {!cols[index].isHiddenOnMobile && (
            <div className={s.cardDataTitle}>{cols[index].text}:&nbsp;</div>
          )}
          {cell.date && <DateCell date={cell.date} />}
          {cell.url ? <a href={cell.url}>{cell.text}</a> : cell.text}
        </div>
      ))}
    </Card>
  )
}

export default CompanyRisksCard
