import React from 'react'
//@ts-ignore
import { useStyles } from 'floral'

import Flex from './Flex'
import numForm from 'utils/numForm'

interface ProgressBarProps {
  success: number
  errors: number
  total: number
}

const progressBarStyles = (props: ProgressBarProps) => {
  let isFull = props.success + props.errors === props.total
  let root: React.CSSProperties = { position: 'relative' }
  let bar = {
    display: 'flex',
    background: '#d1d7e0',
    borderRadius: 4,
    height: 8,
    marginTop: 6,
    marginBottom: 4,
    paddingRight: isFull ? 0 : 5,
  }
  let segment: React.CSSProperties = {
    height: 8,
    borderRadius: 4,
    minWidth: 8,
    boxSizing: 'content-box',
  }
  let success: React.CSSProperties = {
    ...segment,
    background: '#51BC7C',
    width: `${(props.success / props.total) * 100}%`,
    zIndex: 1,
    position: 'relative',
  }
  let errors = {
    ...segment,
    background: '#E86161',
    width: `${(props.errors / props.total) * 100}%`,
  }
  if (props.success > 0 && props.errors > 0) {
    Object.assign(errors, { marginLeft: -4, paddingLeft: 4 })
  }
  let caption = {
    fontSize: 13,
    color: 'var(--color-secondary)',
  }
  return { root, bar, success, errors, caption }
}

const ProgressBar = (props: ProgressBarProps) => {
  let { success, errors, total } = props
  let s = useStyles(progressBarStyles, [props])
  return (
    <div style={s.root}>
      <div style={s.bar}>
        {success > 0 && <div style={s.success} />}
        {errors > 0 && <div style={s.errors} />}
      </div>
      <Flex gap=".5rem" justify="center" style={s.caption}>
        <div>
          {success} из {total}
        </div>
        {errors > 0 && (
          <div style={{ color: 'var(--color-red)' }}>
            {numForm(errors, { one: 'ошибка', two: 'ошибки', many: 'ошибок' })}
          </div>
        )}
      </Flex>
    </div>
  )
}

ProgressBar.defaultProps = { errors: 0 }

export default ProgressBar
