import { UserRegistrationEntity } from 'entities'
import { Entity } from 'mobx/MobxApi'
import { Data } from 'mobx/types'
import UserStore from 'stores/UserStore'

export const handleRegistrationResponse = (data: Data, user: UserStore) => {
  if (data.status === 'completed') {
    user.user!.data.flags.completed_registration = true
    user.history.replace('/registration/completed')
    user.fetchUser()
  }
}

export const setRegistrationData = (
  registration: Entity<UserRegistrationEntity>,
  data: Data,
  user: UserStore
) => {
  registration.setData(data)
  handleRegistrationResponse(data, user)
}
