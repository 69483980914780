import React from 'react'

import { Taply } from './taply'
import { useStyles, StyleProps, StylesMap } from './styled'

const closeButtonStyles: StylesMap = {
  root: {
    position: 'absolute',
    top: '1rem',
    right: '1rem',
    width: '1rem',
    height: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
}

interface CloseButtonProps extends StyleProps<[CloseButtonProps]> {
  children?: React.ReactNode
  onTap: () => void
}

const defaultProps = {
  children: '✕',
}

const CloseButton = (props: CloseButtonProps) => {
  const { children, onTap } = props as CloseButtonProps & typeof defaultProps
  const styles = useStyles(closeButtonStyles, [props])
  return (
    <Taply onTap={onTap}>
      <div style={styles.root}>{children}</div>
    </Taply>
  )
}

CloseButton.defaultProps = defaultProps

export { CloseButton }
