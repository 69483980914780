type ReactRef<T> = React.Ref<T> | React.RefObject<T> | React.MutableRefObject<T>

const mergeRefs = <T extends unknown>(...refs: (ReactRef<T> | undefined)[]) =>
  (value: T) => {
    refs.forEach((ref) => {
      if (ref == null) return
      if (typeof ref === 'function') {
        ref(value)
        return
      }
      try {
        // @ts-ignore
        ref.current = value
      } catch(e) {}
    })
  }

export default mergeRefs
