import * as React from 'react'
import { observer } from 'mobx-react-lite'
import { Entity } from 'mobx/MobxApi'
import { Card, Col, Flex, H3, Row, useStateToast } from 'components/ui'
import { Amount, Avatar } from 'components'
import { useMediaContext } from 'App/MediaContext'
import { TaskStatusBadge } from '../TaskStatusBadge'
import { ReactComponent as LocationPinIcon } from '../location-pin.svg'
import { ReactComponent as SuitcaseIcon } from './suitcase.svg'

import styles from '../Tasks.module.css'
import dayjs from 'utils/dayjs'
import clsx from 'clsx'
import { TaskActionButton } from '../TaskAction/TaskAction'
import { Link } from 'react-router-dom'
import { ScenarioTaskActionsStore } from '../TaskAction'
import { formatTime } from 'utils/datetime'

type ScenarioTaskEntity = any

const dayFormat = 'DD.MM.YY'

export const formatDayPeriod = (since: string, upto: string | null): string => {
  if (!upto) {
    return dayjs(since).format(dayFormat)
  } else {
    return `${dayjs(since).format(dayFormat)} – ${dayjs(upto).format(dayFormat)}`
  }
}

type PlatformTaskListItemProps = Omit<TaskListItemProps, 'baseUrl'> & {
  store: ScenarioTaskActionsStore
}

const DesktopTaskListItem = observer(({ task, store }: PlatformTaskListItemProps) => {
  const { subtasks } = task.data || []

  return (
    <Card kind="secondary" withShadow>
      <Flex align="center" gap="24px" className={styles.wrapper}>
        <Avatar
          size="60px"
          name={task.data.company.data.name}
          value={task.data.company.data.avatar_url}
        />
        <Col gap="1.2rem">
          <Row align="center">
            {task.data.status && (
              <TaskStatusBadge status={task.data.status} style={{ marginRight: 10 }} />
            )}{' '}
            <H3 className={styles.bigText}>{task.data.company.data.name}</H3>
          </Row>
          {task.data.location?.address && (
            <Row align="center" gap=".625rem">
              <LocationPinIcon className={clsx(styles.icon, styles.purpleText)} />{' '}
              <span className={clsx(styles.smallText, styles.greyText)}>
                {task.data.location?.address}
              </span>
            </Row>
          )}
          {task.data.actions && task.data.actions.length > 0 && (
            <Row>
              {task.data.actions.map((action: any, idx: number) => (
                <TaskActionButton
                  store={store}
                  size="s"
                  action={action}
                  key={`action${action}-${idx}`}
                />
              ))}
            </Row>
          )}
        </Col>
        <Col align="end" style={{ flex: 1, flexShrink: 0 }} gap="1rem">
          {task.data.since_date && (
            <div className={clsx(styles.smallText)}>
              <span className={styles.greyText}>
                {formatDayPeriod(task.data.since_date, task.data.upto_date)}
              </span>{' '}
              {task.data.since_time && (
                <span style={{ fontWeight: 700 }}>
                  с {formatTime(task.data.since_time)}
                  {task.data.upto_time ? ` по ${formatTime(task.data.upto_time)}` : ''}
                </span>
              )}
            </div>
          )}
          <Row justify="end" align="center" gap="1rem">
            <Col align="end">
              {subtasks &&
                subtasks.map((subtask: any) => (
                  <div className={styles.mediumText}>
                    <span className={styles.greyText}>{subtask.title}: </span>
                    <span>
                      {subtask.quantity} x <Amount value={subtask.cost} />
                    </span>
                  </div>
                ))}
              <Amount
                className={clsx(styles.purpleText, styles.bigText)}
                value={task.data.amount}
              />
            </Col>
          </Row>
        </Col>
      </Flex>
    </Card>
  )
})

const MobileTaskListItem = observer(({ task, store }: PlatformTaskListItemProps) => {
  const gap = '.325rem'

  const { subtasks } = task.data || []

  return (
    <Card kind="secondary" withShadow>
      <Col gap=".625rem">
        <Row align="center" justify="space-between" gap={gap}>
          <Amount
            className={clsx(styles.purpleText, styles.bigText)}
            value={task.data.amount}
          />
          <TaskStatusBadge status={task.data.status} />
        </Row>

        <Row align="start" justify="space-between" gap={gap}>
          <span className={styles.mediumText} style={{ lineHeight: '16px' }}>
            {task.data.company.data.name}{' '}
          </span>
          <Col align="end" style={{ flexShrink: 0 }} className={styles.smallText}>
            {task.data.since_date && (
              <span className={styles.greyText}>
                {formatDayPeriod(task.data.since_date, task.data.upto_date)}
              </span>
            )}{' '}
            {task.data.since_time && (
              <span>
                с {formatTime(task.data.since_time)}
                {task.data.upto_time ? ` по ${formatTime(task.data.upto_time)}` : ''}
              </span>
            )}
          </Col>
        </Row>

        {subtasks &&
          subtasks.map((subtask: any) => (
            <Row align="center" gap=".625rem">
              <SuitcaseIcon className={clsx(styles.icon, styles.iconGreyText)} />{' '}
              <span className={clsx(styles.smallText)}>
                {subtask.title}: {subtask.quantity} x <Amount value={subtask.cost} />
              </span>
            </Row>
          ))}

        {task.data.location?.address && (
          <Row align="center" gap=".625rem">
            <LocationPinIcon className={clsx(styles.icon, styles.iconGreyText)} />{' '}
            <span className={clsx(styles.smallText)}>{task.data.location?.address}</span>
          </Row>
        )}

        <Flex gap="12px">
          {task.data.actions &&
            task.data.actions.length > 0 &&
            task.data.actions.map((action: any, idx: number) => (
              <TaskActionButton
                store={store}
                size="s"
                action={action}
                key={`action${action}-${idx}`}
              />
            ))}
        </Flex>
      </Col>
    </Card>
  )
})

type TaskListItemProps = {
  baseUrl?: string
  task: Entity<ScenarioTaskEntity>
}

export const TaskListItem = observer(({ baseUrl, task }: TaskListItemProps) => {
  const { isNarrow } = useMediaContext()
  const toast = useStateToast()
  const [store] = React.useState(() => new ScenarioTaskActionsStore({ task, toast }))

  return (
    <>
      <Link to={`${baseUrl ?? ''}/scenario_tasks/${task.data.id}`}>
        {isNarrow ? (
          <MobileTaskListItem store={store} task={task} />
        ) : (
          <DesktopTaskListItem store={store} task={task} />
        )}
      </Link>
      {store.render()}
    </>
  )
})
