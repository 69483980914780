import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { fromPromise } from 'mobx-utils'

import cl from 'clsx'

import { validatePhoneInternational } from 'utils/validations'

import PhoneInput from 'components/PhoneInput'

import { checkPhone } from '../../requests'

import { Title, Label, FormGroup } from 'components/ui/LayoutSimple'

import { useTheme, Link } from 'ui'

import s from './style.module.css'

interface PhoneStepProps {
  initialPhone: string
  onComplete: (phone: string, status: string) => void
}

const PhoneStep = observer(({ onComplete, initialPhone }: PhoneStepProps) => {
  const { config } = useTheme()

  let [phone, setPhone] = useState(initialPhone || '+7')
  let [shakeInput, setShakeInput] = useState(false)
  let [checkState, setCheckState] = useState<any>(fromPromise.resolve())
  let updatePhone = (phone: string) => {
    setPhone(phone)
    setCheckState(fromPromise.resolve())
  }
  let submit = () => {
    let state = fromPromise(checkPhone(phone))
    state.then(
      ({ status }) => onComplete(phone, status),
      () => {
        setShakeInput(true)
        setTimeout(() => setShakeInput(false), 500)
      }
    )
    setCheckState(state)
  }
  let phoneIsComplete = validatePhoneInternational(phone)
  let errorElem
  if (checkState.state === 'rejected') {
    errorElem = checkState.value.message
  }

  let agreementText = (
    <div className={s.agreementText}>
      Нажимая на&nbsp;кнопку &laquo;Далее&raquo;, я&nbsp;подтверждаю, что
      я&nbsp;ознакомлен и&nbsp;согласен с&nbsp;
      <Link href={config.links.agreement} target="_blank">
        пользовательским соглашением
      </Link>{' '}
      и&nbsp;
      <Link href={config.links.policy} target="_blank">
        условиями предоставления персональных данных
      </Link>
    </div>
  )

  const isPending = checkState.state === 'pending'

  return (
    <>
      <Title>Вход</Title>
      <FormGroup
        additionalLink={agreementText}
        additionalLinkAlign="left"
        error={errorElem}
        isLoading={isPending}
        isDisabled={!phoneIsComplete}
        onSubmit={submit}
      >
        <Label>Введите номер телефона</Label>
        <PhoneInput
          value={phone}
          onChange={updatePhone}
          autoFocus
          className={cl(shakeInput && s.shakeOnError)}
          onEnter={() => {
            if (phoneIsComplete) submit()
          }}
        />
      </FormGroup>
    </>
  )
})

export default PhoneStep
