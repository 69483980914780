import React from 'react'
import { tail } from 'lodash'
import { conformToMask } from 'react-text-mask'

import { Input } from 'ui/konsol/input'

import { ReactComponent as PhoneIcon } from './phone.svg'

export const normalizePhone = (val: string) => (val ? val.replace(/[^\d]/g, '') : val)

export const isForeignPhone = (value: string) => {
  const normalized = normalizePhone(value)
  return !(normalized.length === 0 || normalized.startsWith('7'))
}

// prettier-ignore
export const phoneMask = [
  '+', /\d/, ' ',
  '(', /\d/, /\d/, /\d/, ')', ' ',
  /\d/, /\d/, /\d/, '-',
  /\d/, /\d/, '-', /\d/, /\d/
]

export const foreignPhoneMask = ['+', ...Array(13).fill(/\d/)]

export const internationalPhoneMask = (value: string) =>
  isForeignPhone(value) ? foreignPhoneMask : phoneMask

export const phoneMaskWithoutPrefix = tail(phoneMask)

interface PhoneInputProps
  extends Omit<
    React.ComponentProps<typeof Input>,
    'mask' | 'placeholder' | 'persistentPlaceholder'
  > {
  value: string
  onChange: (value: string) => void
}

const pipe = (conformedValue: string) =>
  conformedValue === '' ? { value: '+', indexesOfPipedChars: [0] } : conformedValue

const makePlaceholder = (currentValue: string) => {
  const normalized = normalizePhone(currentValue)
  return (
    '+' +
    (normalized.startsWith('7')
      ? conformToMask(currentValue, phoneMaskWithoutPrefix, { placeholderChar: '0' })
          .conformedValue
      : '')
  )
}

const PhoneInput = (props: PhoneInputProps) => {
  const { value, style, ...restProps } = props
  return (
    <Input
      icon={<PhoneIcon />}
      mask={internationalPhoneMask}
      placeholder={makePlaceholder(value)}
      persistentPlaceholder={true}
      style={{ fontVariant: 'tabular-nums no-contextual', ...style }}
      // @ts-ignore
      pipe={pipe}
      value={value}
      numericKeyboard
      {...restProps}
    />
  )
}

export { PhoneInput }
