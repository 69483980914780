import { makeAutoObservable, action } from 'mobx'

import { usePusherSubscription } from './pusher'
import { fetchApi } from 'api'

const badges = {
  unread_notifications: 0,
  unread_invites: 0,
  unread_tasks: 0,
  acts_for_sign: 0,
  offers_responses: 0
}
makeAutoObservable(badges)

const fetchBadges = () =>
  fetchApi({ url: 'badges' }).then(action((data: Object) => Object.assign(badges, data)))

const useBadgesSubscription = () => {
  usePusherSubscription('update-badges', (update: Object) =>
    Object.assign(badges, update)
  )
}

export { badges, fetchBadges, useBadgesSubscription }
