import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { fromPromise } from 'mobx-utils'

import { useHarmonicIntervalFn } from 'react-use'
import cl from 'clsx'

import { CodeInput } from 'components'
import { BaseButton } from 'components/ui'
import { Title, Label, FormGroup } from 'components/ui/LayoutSimple'

import { checkPhone, checkSms, loginAuth } from '../../requests'

import s from './style.module.css'

const SMS_RESEND_TIME = 59 // seconds

interface SmsStepProps {
  phone: string
  password?: string
  onComplete: (user: any) => void
}

const SmsStep = observer(({ phone, password, onComplete }: SmsStepProps) => {
  let [sms, setSms] = useState('')
  let [checkState, setCheckState] = useState<any>(fromPromise.resolve())
  let [shakeInput, setShakeInput] = useState(false)
  // чтобы после успеха не убиралась крутилка
  let [isCompleted, setCompleted] = useState(false)

  let updateSms = (sms: string) => {
    setSms(sms)
    if (sms.length === 4) {
      let state = fromPromise(checkSms(sms))
      state.then(
        (value) => {
          onComplete(value)
          setCompleted(true)
        },
        () => {
          setSms('')
          setResendTimer(SMS_RESEND_TIME)
          setShakeInput(true)
          setTimeout(() => setShakeInput(false), 500)
        }
      )
      setCheckState(state)
    }
  }

  let [resendTimer, setResendTimer] = useState(SMS_RESEND_TIME)
  useHarmonicIntervalFn(() => {
    setResendTimer((val) => (val > 0 ? val - 1 : val))
  }, 1000)

  let resendCode = () => {
    if (password) {
      loginAuth(phone, password)
    } else {
      checkPhone(phone)
    }
    setResendTimer(SMS_RESEND_TIME)
  }

  let errorElem
  if (checkState.state === 'rejected') {
    errorElem = <span className={s.errorText}>{checkState.value.message}</span>
  }

  const isPending = checkState.state === 'pending'

  return (
    <>
      <Title>Вход</Title>
      <FormGroup
        error={errorElem}
        isLoading={isPending}
        additionalLink={
          !resendTimer ? (
            <BaseButton onTap={() => resendCode()} className={s.linkButton}>
              Отправить код повторно
            </BaseButton>
          ) : null
        }
      >
        <Label>Введите код смс</Label>
        <CodeInput
          autoFocus
          value={sms}
          onChange={updateSms}
          className={cl(shakeInput && s.shakeOnError)}
          isLoading={checkState.state === 'pending' || isCompleted}
        />
        {resendTimer ? (
          <div className={s.resendSmsMessage}>
            Повторно отправить код через <span>{resendTimer} сек</span>
          </div>
        ) : null}
      </FormGroup>
    </>
  )
})

export default SmsStep
