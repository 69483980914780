import React, { useState, useEffect } from 'react'

import { Input as UiInput } from 'components/ui'

interface NumberInputProps
  extends Omit<React.ComponentProps<typeof UiInput>, 'value' | 'onChange'> {
  value?: string
  onChange: (value: string) => void
  allowDecimal?: boolean
  decimalLimit?: number
  allowMinus?: boolean
  Input?: typeof UiInput
}

const NumberInput = ({
  value,
  onChange,
  allowDecimal = false,
  decimalLimit = 2,
  allowMinus = false,
  Input = UiInput,
  ...restProps
}: NumberInputProps) => {
  let [inputValue, setInputValue] = useState<any>()

  let onInputChange = (inputValue: string) => {
    let val = inputValue
    if (val) {
      val = (
        allowDecimal ? inputValue.replace(',', '.') : parseInt(inputValue, 10)
      ).toString()
      if (isNaN(parseInt(val))) val = '0'
    }

    const regexp = `^${allowMinus ? '-?' : ''}\\d*\\.?(\\d{0,${decimalLimit}})?$`
    if (new RegExp(regexp).test(val)) {
      setInputValue(val)
      onChange(val)
    }
  }

  useEffect(() => {
    setInputValue(value)
  }, [value])

  return (
    <Input
      numericKeyboard
      value={inputValue}
      onChange={onInputChange}
      type="text"
      allowDecimal={allowDecimal}
      {...restProps}
    />
  )
}

export default NumberInput
