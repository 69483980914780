import { nbsp } from './typo'

export type WordForms = { one: string; two: string; many: string }

export const getNumForm = (num: number, { one, two, many }: WordForms): string => {
  if (num === 0 || (num >= 11 && num < 20)) return many
  let lastDigit = num % 10
  if (lastDigit === 0) return many
  if (lastDigit === 1) return one
  if (lastDigit < 5) return two
  return many
}

const numForm = (num: number, forms: WordForms) =>
  `${num}${nbsp}${getNumForm(num, forms)}`

export default numForm
