import React, { useState, forwardRef } from 'react'

import { ReactComponent as CheckboxIcon } from 'ui/konsol/icons/checkbox.svg'
import { ReactComponent as IndeterminateIcon } from 'ui/konsol/icons/indeterminate.svg'

import { Taply, TapState, initialTapState } from 'ui/taply'
import { useStyles, StyleProps } from 'ui/styled'

interface CheckboxProps extends StyleProps<[CheckboxProps]> {
  value?: boolean
  onChange?: (value: boolean) => void
  isIndeterminate?: boolean
  isDisabled?: boolean
  label?: React.ReactNode
}

const defaultProps = {
  value: false,
  isIndeterminate: false,
  isDisabled: false,
}

const checkboxStyles = (
  props: CheckboxProps & typeof defaultProps,
  tapState: TapState
) => {
  const { value, isDisabled, isIndeterminate } = props

  const root: React.CSSProperties = {
    display: 'flex',
    alignItems: 'start',
    cursor: 'pointer',
    fontSize: 'var(--konsol-font-size)',
    WebkitTapHighlightColor: 'transparent',
    borderRadius: 6,
    width: 'fit-content',
    outline: 'none',
    lineHeight: '130%',
  }

  if (tapState.isFocused) root.boxShadow = 'var(--konsol-focus-shadow) inset'

  const control: React.CSSProperties = {
    width: 30,
    height: 30,
    borderRadius: 6,
    flexShrink: 0,
    background: tapState.isHovered ? 'var(--konsol-color-grey-08)' : 'transparent',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }

  let background
  if (value) {
    background = 'var(--konsol-color-primary)'
  } else if (isIndeterminate) {
    background = 'var(--konsol-color-primary)'
  } else {
    background = tapState.isPressed
      ? 'var(--konsol-color-grey-64)'
      : 'var(--konsol-color-grey-40)'
  }

  const icon = {
    fill: 'white',
    borderRadius: 2,
    width: 16,
    height: 16,
    background,
  }

  const label: React.CSSProperties = {
    marginLeft: 2,
    marginRight: 7,
    marginTop: 7,
    marginBottom: 7,
  }

  if (isDisabled) {
    root.cursor = 'not-allowed'
    icon.background = 'var(--konsol-color-grey-16)'
    icon.fill = 'var(--konsol-color-grey-40)'
    label.color = 'var(--konsol-color-text-disabled)'
  }

  return { root, control, icon, label }
}

const Checkbox = forwardRef<HTMLDivElement, CheckboxProps>(
  (_props: CheckboxProps, ref) => {
    const props = _props as CheckboxProps & typeof defaultProps
    const { value, onChange, label, isDisabled, isIndeterminate } = props
    const [tapState, setTapState] = useState(initialTapState)
    const styles = useStyles(checkboxStyles, [props, tapState])

    const renderMark = () => {
      if (isIndeterminate) {
        return <IndeterminateIcon />
      } else if (value) {
        return <CheckboxIcon />
      } else {
        return null
      }
    }

    return (
      <Taply
        onChangeTapState={setTapState}
        onTap={() => onChange && onChange(!value)}
        isDisabled={isDisabled}
      >
        <div style={styles.root} ref={ref}>
          <div style={styles.control}>
            <div style={styles.icon}>{renderMark()}</div>
          </div>
          {label && <div style={styles.label}>{label}</div>}
        </div>
      </Taply>
    )
  }
)

Checkbox.defaultProps = defaultProps

export { Checkbox }
