import React, { useState, forwardRef } from 'react'
//@ts-ignore
import Taply from 'taply'
//@ts-ignore
import { useStyles } from 'floral'

import { ReactComponent as CheckBoxIcon } from '@material-design-icons/svg/round/check_box.svg'
import { ReactComponent as BlankCheckBoxIcon } from '@material-design-icons/svg/round/check_box_outline_blank.svg'
import { ReactComponent as IndeterminateCheckBoxIcon } from '@material-design-icons/svg/round/indeterminate_check_box.svg'

interface CheckboxProps {
  value: boolean
  onChange?: (value: boolean) => void
  isIndeterminate?: boolean
  isDisabled?: boolean
  label?: React.ReactNode
  size?: 'l' | 'm'
  styles?: any
  color?: any
}

const checkboxStyles = (props: CheckboxProps, tapState: any) => {
  let root: React.CSSProperties = {
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    fontSize: props.size === 'l' ? '1.5rem' : '1.15rem',
    WebkitTapHighlightColor: 'transparent',
    borderRadius: 7,
    width: 'fit-content',
  }

  let iconContainer = {
    position: 'relative',
    width: 20,
    height: 20,
    flexShrink: 0,
  }

  let hover = {
    position: 'absolute',
    width: 30,
    height: 30,
    borderRadius: '50%',
    left: '50%',
    top: '50%',
    transform: 'translateY(-50%) translateX(-50%)',
    background: tapState.isHovered ? 'rgba(0,0,0,.1)' : 'transparent',
  }

  let icon: React.CSSProperties = {
    width: 20,
    height: 20,
    fill:
      props.value || props.isIndeterminate
        ? 'var(--color-active)'
        : 'var(--color-grey-4)',
  }

  if (tapState.isFocused) {
    root.boxShadow =
      '0 0 0px 2px var(--color-background), 0 0 0px 4px rgb(83 23 254 / 0.3)'
  }

  let label: React.CSSProperties = {
    marginLeft: '.75rem',
    transition: 'color .15s ease-in-out',
    fontWeight: 500,
    fontSize: '1rem',
    color: props.color || 'var(--color-active)',
  }

  if (props.isDisabled) {
    root.cursor = 'not-allowed'
    label.color = 'var(--color-secondary)'
    icon.fill = 'var(--color-secondary)'
  }

  return { root, icon, iconContainer, hover, label }
}

const Checkbox = forwardRef((props: CheckboxProps, ref: any) => {
  let { value, onChange, label, isDisabled, isIndeterminate } = props
  let [tapState, setTapState] = useState({})
  let styles = useStyles(checkboxStyles, [props, tapState])
  let IconComponent = isIndeterminate
    ? IndeterminateCheckBoxIcon
    : value
    ? CheckBoxIcon
    : BlankCheckBoxIcon
  return (
    <Taply
      onChangeTapState={setTapState}
      onTap={() => onChange && onChange(!value)}
      isDisabled={isDisabled}
    >
      <div style={styles.root} ref={ref}>
        <div style={styles.iconContainer}>
          <div style={styles.hover} />
          <IconComponent style={styles.icon} />
        </div>
        {label && <div style={styles.label}>{label}</div>}
      </div>
    </Taply>
  )
})

Checkbox.defaultProps = {
  size: 'm',
}

export default Checkbox
