import { fromPromise } from 'mobx-utils'

import { fetchApi } from 'api'

export const register = ({
  phone,
  permalink,
  query,
}: {
  phone: string
  permalink: string
  query: Object
}) =>
  fetchApi({
    method: 'POST',
    url: 'registration',
    withPrefix: false,
    data: { mobile_phone: phone, permalink, query },
  })

export const verifyRegistration = ({ code }: { code: string }) =>
  fetchApi({
    method: 'PUT',
    url: 'registration/verify',
    withPrefix: false,
    data: { code },
  })

export const fetchInvite = (permalink: string) =>
  fromPromise(
    fetchApi({
      method: 'GET',
      url: `registration/info?permalink=${permalink}`,
      withPrefix: false,
    })
  )

export const fetchCheckJoin = (permalink: string, query: any) =>
  fetchApi({
    url: 'companies/join',
    method: 'PUT',
    data: { permalink, query }
  })

export const fetchUserEvents = (event: string) =>
  fetchApi({
    url: 'users/events',
    method: 'PUT',
    data: { event },
  })