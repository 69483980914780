import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'

import { LayoutSimple } from 'components/ui'
import { Title } from 'components/ui/LayoutSimple'

import SendEmail from './components/SendEmail'
import SendCode from './components/SendCode'
import SendPassword from './components/SendPassword'

const PasswordForgot = () => {
  const history = useHistory()
  const [uuid, setUuid] = useState('')
  const [email, setEmail] = useState('')
  const [step, setStep] = useState<string>()


  let stepElem
  let onBack
  if (!step) {
    onBack = () => {
      // @ts-ignore
      window.ReactNativeWebView?.postMessage('backPassword')
      window.postMessage('backPassword', '*')
      history.push('/login')
    }
    stepElem = (
      <SendEmail
        onComplete={(value, email) => {
          setEmail(email)
          setUuid(value)
          setStep('code')
        }}
      />
    )
  } else if (step === 'code') {
    onBack = () => setStep('')
    stepElem = (
      <SendCode
        email={email}
        uuid={uuid}
        onComplete={() => {
          setStep('password')
        }}
      />
    )
  } else if (step === 'password') {
    onBack = () => setStep('')
    stepElem = (
      <SendPassword
        uuid={uuid}
        onComplete={() => {
          setStep('done')
        }}
      />
    )
  } else if (step === 'done') {
    stepElem = (
      <>
        <Title>
          Вы&nbsp;успешно восстановили пароль,
          <br />
          теперь вы&nbsp;можете{' '}
          <Link
            onClick={() => {
              // @ts-ignore
              window.ReactNativeWebView?.postMessage('backPhone')
              window.postMessage('backPhone', '*')
            }}
            to="/login"
          >
            войти
          </Link>
        </Title>
      </>
    )
  }

  return <LayoutSimple onBack={onBack}>{stepElem}</LayoutSimple>
}

export default PasswordForgot
