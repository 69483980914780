import React from 'react'

import GlobalNotificationWrapper from 'components/GlobalNotification/components/GlobalNotificationsWrapper'

import { Flex, Link } from 'ui'

import { ReactComponent as IconNotice } from '../../assets/icon-notice.svg'

const UnsignedCounterpartyDocuments = ({ data }: any) => {
  const {
    data: { list, invoice_act },
  } = data

  return (
    <GlobalNotificationWrapper isHidden={false}>
      <Flex gap="12px">
        <div style={{ width: '18px' }}>
          <IconNotice style={{ width: '16x', height: '16px' }} />
        </div>
        <Flex direction="col" align="start" gap="12px">
          <div>
            У вас есть неподписанн{list.length > 1 ? 'ые' : 'ый'} документ
            {list.length > 1 ? 'ы' : ''}: <b>{list.join(', ')}</b>. Подписать можно
            в&nbsp;разделе &laquo;Тарифы и&nbsp;документы&raquo;.{' '}
            {invoice_act && (
              <div>
                Оплачивать счет отдельно по&nbsp;реквизитам не&nbsp;нужно, вознаграждение
                спишется автоматически с&nbsp;номинального счета.
              </div>
            )}
          </div>
          <Link
            href="/company/tariff"
            style={{
              color: '#fff',
              borderBottom: '1px solid #fff',
              textDecoration: 'none',
            }}
          >
            Перейти к&nbsp;подписанию
          </Link>
        </Flex>
      </Flex>
    </GlobalNotificationWrapper>
  )
}

export default UnsignedCounterpartyDocuments
