import React, { ReactElement } from 'react'
import clsx from 'clsx'

import { Logo, Appear, Row } from 'ui'

import css from './styles.module.css'

interface ILayoutEntryProps {
  aside?: ReactElement
  logoAside?: ReactElement | null
  className?: string
  children: React.ReactNode
}

const LayoutEntry = (props: ILayoutEntryProps) => {
  const { aside, logoAside, className, children } = props
  return (
    <Appear>
      <div className={clsx(css.container, className)}>
        <div className={css.content}>
          <div className={clsx(css.wrapper, !aside ? css.withoutAside : '')}>
            <Row
              justify="space-between"
              align="center"
              className={css.logoWrapper}
              gap="10px"
            >
              <Logo />
              {logoAside && logoAside}
            </Row>
            <div>{children}</div>
          </div>
        </div>
        {aside && (
          <div className={css.aside}>
            <div className={css.wrapper}>{aside}</div>
          </div>
        )}
      </div>
    </Appear>
  )
}

export default LayoutEntry
