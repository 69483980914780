import { IComputedValue, makeAutoObservable } from 'mobx'
import { sortBy, reverse } from 'lodash'

interface Sorting {
  col: string
  direction: 'asc' | 'desc'
}

class SortableStore<T> {
  constructor({
    items,
    sortFunc,
    sorting,
  }: {
    items: IComputedValue<Array<T>>
    sortFunc: (item: T, sorting?: string) => any
    sorting?: Sorting
  }) {
    this.allItems = items
    this.sortFunc = sortFunc
    this.sorting = sorting
    makeAutoObservable(this)
  }

  allItems: IComputedValue<Array<T>>
  sortFunc: (item: T, col: string) => any
  sorting?: Sorting

  get items() {
    let items = this.allItems.get()
    if (this.sorting) {
      let sorted = sortBy(items, (item) => this.sortFunc(item, this.sorting!.col))
      return this.sorting.direction === 'asc' ? sorted : reverse(sorted)
    } else {
      return items
    }
  }

  setSorting = (sorting: Sorting) => {
    this.sorting = sorting
  }
}

export default SortableStore
