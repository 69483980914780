import * as React from 'react'
import { observer } from 'mobx-react-lite'
import {
  FilterMenuGroups,
  FiltersStore,
  SelectAllItems,
  SelectedItemsActionsPanel,
  SelectedItemsCount,
  SelectedItemsStore,
} from '.'
import { FilterView } from './components'
import { Button, Flex } from 'components/ui'
import { Menu, MenuGroup, MenuItem } from './ui'
import { ReactComponent as AddIcon } from '@material-design-icons/svg/round/add.svg'
import { useMediaContext } from 'App/MediaContext'
import clsx from 'clsx'
import styles from './SortFilterPanel.module.css'
import { ComponentProps } from 'app.types'

export type SortFiltersPanelProps<T> = ComponentProps & {
  selectedStore: SelectedItemsStore<T>
  filtersStore?: FiltersStore
  wordForms: { one: string; two: string; many: string }
  sticky?: boolean
  menuGroups?: FilterMenuGroups
  actions?: React.ReactNode
  stickyActions?: React.ReactNode
}

const ActiveFiltersList = observer(({ store }: { store: FiltersStore }) => {
  return store.currentState.length > 0 ? (
    <Flex gap="1rem" wrap style={{ marginTop: '-.5rem' }}>
      {store.currentState.map(
        (filter) =>
          filter.name in store.configs && (
            <>
              <FilterView
                style={{ marginTop: '.5rem' }}
                ref={(ref) => store.onRenderFilter(filter.name, ref)}
                key={filter.name}
                // @ts-ignore
                config={store.configs[filter.name]}
                value={filter.value}
                // @ts-ignore
                onChange={(value) => store.setFilter(filter.name, value)}
                onRemove={() => store.removeFilter(filter.name)}
              />
            </>
          )
      )}
    </Flex>
  ) : null
})

const AddFilterButton = observer(
  ({ store, menuGroups }: { store: FiltersStore; menuGroups?: FilterMenuGroups }) => {
    return (
      <Menu
        menu={() => {
          let groups = menuGroups || [{ filters: Object.keys(store.configs) }]
          return groups.map(({ header, filters }) => (
            <MenuGroup header={header}>
              {filters.map((name) => (
                <MenuItem key={name} value={name} isDisabled={store.hasFilter(name)}>
                  {store.configs[name].title}
                </MenuItem>
              ))}
            </MenuGroup>
          ))
        }}
        onSelect={(name: any) => store.addFilter(name)}
        placement={{ offset: 5 }}
        maxHeight={350}
      >
        {(ref: any, { open }: any) => (
          <Button size="xs" design="text" icon={<AddIcon />} onTap={open} ref={ref}>
            Добавить фильтр
          </Button>
        )}
      </Menu>
    )
  }
)

export const SortFiltersPanel = observer(
  <T,>({
    selectedStore,
    wordForms,
    filtersStore,
    menuGroups,
    actions,
    sticky = false,
    stickyActions,
  }: SortFiltersPanelProps<T>) => {
    const { isMobile } = useMediaContext()

    React.useEffect(() => {
      if (isMobile) {
        if (selectedStore.selectedCount > 0) {
          selectedStore.setSelectable(true)
        } else {
          selectedStore.setSelectable(false)
        }
      } else {
        selectedStore.setSelectable(true)
      }
    }, [isMobile, selectedStore])

    return (
      <>
        {!!filtersStore && (
          <>
            <Flex direction="column" align="start">
              <ActiveFiltersList store={filtersStore} />
              {!isMobile && <AddFilterButton store={filtersStore} menuGroups={menuGroups} />}
            </Flex>

            {isMobile && (
              <Flex align="start" justify="space-between">
                <SelectAllItems store={selectedStore} wordForms={wordForms} />
                <AddFilterButton store={filtersStore} menuGroups={menuGroups} />
              </Flex>
            )}
          </>
        )}

        <Flex
          className={clsx(styles.panel, {
            [styles.sticky]: sticky && isMobile,
          })}
          direction="column"
          gap={isMobile ? 0 : '.5rem'}
        >
          <Flex
            direction={isMobile ? 'column' : 'row'}
            gap={isMobile ? '.325rem' : '1rem'}
          >
            <SelectedItemsCount store={selectedStore} wordForms={wordForms} />
            <SelectedItemsActionsPanel store={selectedStore} actions={actions} />
            {!isMobile && stickyActions && (
              <div style={{ marginLeft: 'auto' }}>{stickyActions}</div>
            )}
          </Flex>
          {!isMobile && <SelectAllItems store={selectedStore} wordForms={wordForms} />}
        </Flex>
      </>
    )
  }
)
