import React, { useMemo } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { makeAutoObservable } from 'mobx'
import { observer } from 'mobx-react-lite'
import { Tooltip } from '@chakra-ui/react'
import { omit, last } from 'lodash'

import { ReactComponent as ReceiptIcon } from '@material-design-icons/svg/round/receipt.svg'
import { ReactComponent as ReceiptErrorIcon } from './receipt_error.svg'
import { ReactComponent as ReceiptManyIcon } from './receipt_many.svg'
import { ReactComponent as ErrorIcon } from '@material-design-icons/svg/round/error_outline.svg'

import { useMediaContext } from 'App/MediaContext'
import { useUserStore, useSignDialogStore } from 'stores/context'
import { Entity, Collection } from 'mobx/mobx'
import { TaskEntity, ContractorEntity, SubtaskTemplateEntity } from 'entities'
import { printPeriod, serializeDate } from 'utils/datetime'
import { emdash } from 'utils/typo'

import { Amount } from 'components'
import {
  Button,
  List,
  ListHeader,
  ListItem,
  ListCell,
  Checkbox,
  StatusBadge,
  IconButton,
  Flex,
  DatePicker,
  useStateToast,
} from 'components/ui'
import type { DateRange } from 'components/ui/DatePicker'
import ContractorSelector from 'components/ContractorSelector'
import SubtaskTemplateSelector from 'components/SubtaskList/SubtaskTemplateSelector'

import { taskStatusMap, actStatusMap, paymentStatusMap } from './statuses'
import TaskActionsStore from './TaskActionsStore'
import { getTaskProblems, TaskProblemList } from './taskProblems'

const taskListCols: any = {
  number: { width: 40, style: { color: 'var(--color-secondary)' } },
  title: { grow: 4, style: { overflowWrap: 'anywhere' } },
  who: { grow: 3 },
  period: { width: 135 },
  amount: {
    width: 100,
    style: { textAlign: 'right', justifyContent: 'flex-end', paddingRight: '.5rem' },
  },
  taskStatus: { width: 100 },
  actStatus: { width: 100 },
  paymentStatus: { width: 120, style: { display: 'flex' } },
  actions: { width: 40 },
}

const buttonSelectStyles = {
  valueContainer: (base: any) => ({
    ...base,
    padding: '0 4px',
  }),
  control: (base: any, state: any) => ({
    ...base,
    border: 'none',
    borderRadius: 6,
    background: state.isFocused ? 'rgba(0,0,0,.1)' : 'transparent',
    boxShadow: 'none',
    cursor: 'pointer',
    flexWrap: 'no-wrap',
    '&:hover': {
      background: 'rgba(0,0,0,.1)',
    },
  }),
  dropdownIndicator: () => ({ display: 'none' }),
  container: (base: any) => ({ ...base, fontWeight: 'normal', left: -5 }),
}

interface DraftStoreOptions {
  task: Entity<TaskEntity>
  contractors: Collection<ContractorEntity>
  templates: Collection<SubtaskTemplateEntity>
  toast: ReturnType<typeof useStateToast>
}

class DraftStore {
  constructor({ task, contractors, templates, toast }: DraftStoreOptions) {
    this.task = task
    this.contractors = contractors
    this.templates = templates
    this.toast = toast
    makeAutoObservable(this)
  }

  task: Entity<TaskEntity>
  contractors: Collection<ContractorEntity>
  templates: Collection<SubtaskTemplateEntity>
  toast: ReturnType<typeof useStateToast>

  updateSubtasks(id: number | null) {
    if (id === null) {
      this.task.update({
        payload: { title: '', subtasks: [] },
        optimistic: true,
      })
    } else {
      let template = this.templates.map[id]
      this.task.update({
        payload: {
          title: template.data.title,
          subtasks: [
            {
              ...omit(template.data, 'id', 'unit'),
              unit_id: template.data.unit.id,
              quantity: 1,
            },
          ],
        },
        clientPayload: {
          title: template.data.title,
          subtasks: [{ ...omit(template.data, 'id'), quantity: 1 }],
        },
        optimistic: true,
      })
    }
  }

  updateContractor(id: number | null) {
    this.task.update({
      payload: { contractor_id: id },
      clientPayload: { contractor: id === null ? null : this.contractors.map[id] },
      optimistic: true,
    })
  }

  updatePeriod([since, upto]: DateRange) {
    this.task.data.since = since ? serializeDate(since) : undefined
    this.task.data.upto = upto ? serializeDate(upto) : undefined
    if (since && upto) {
      this.task.update({
        payload: { since: this.task.data.since, upto: this.task.data.upto },
      })
    }
  }
}

const TaskContractor = observer(({ task }: { task: Entity<TaskEntity> }) => {
  let contractor = task.data.contractor
  if (!contractor) return <>{emdash}</>
  let problems = getTaskProblems(task)
  let name = contractor.data.name
  if (problems.length === 0) return <>{name}</>
  let tooltip = <TaskProblemList problems={problems} />
  return (
    <Tooltip label={tooltip} placement="top">
      <Flex gap=".25rem" align="center">
        <ErrorIcon fill={'var(--color-red)'} />
        <div>{name}</div>
      </Flex>
    </Tooltip>
  )
})

interface TaskListOptions {
  kind: 'tasks' | 'acts'
  who: boolean // Исполнитель / заказчик
  isSelectable: boolean // Чекбокс
  isEditable: boolean // Редактирование черновиков в проекте
  actionButtons: boolean
}

interface TaskListItemProps {
  url?: string
  task: Entity<TaskEntity>
  options: TaskListOptions
  isSelected?: boolean
  onToggleSelected?: (value: boolean) => void
  actions?: React.ReactNode
  contractors?: Collection<ContractorEntity>
  templates?: Collection<SubtaskTemplateEntity>
}

const taskListItemStyles = {
  icon: {
    display: 'flex',
    height: 21,
    alignItems: 'center',
    fill: 'var(--color-icon)',
  },
  // mobile
  item: {
    boxShadow: '0px 0px 1px rgb(0 0 0 / 15%), 0px 2px 12px rgb(0 0 0 / 10%)',
    borderRadius: 10,
    WebkitTapHighlightColor: 'transparent',
  },
  row: { marginBottom: '.25rem', alignItems: 'start' },
  label: {
    color: 'var(--color-secondary)',
    width: 90,
    flexShrink: 0,
    marginRight: '.5rem',
  },
  title: { fontWeight: 600 },
}

const TaskListItem = observer((props: TaskListItemProps) => {
  let {
    options,
    url,
    task,
    isSelected,
    onToggleSelected,
    actions,
    contractors,
    templates,
  } = props
  let { isMobile } = useMediaContext()
  let currentUser = useUserStore()
  let toast = useStateToast()
  let styles = taskListItemStyles
  let history = useHistory()

  let isEditableDraft =
    options.isEditable &&
    task.data.status === 'draft' &&
    currentUser.hasAbility('tasks.update')
  let draftStore = useMemo(
    () =>
      isEditableDraft &&
      new DraftStore({ task, contractors: contractors!, templates: templates!, toast }),
    []
  )
  let signDialog = useSignDialogStore()
  let actionsStore = useMemo(
    () => new TaskActionsStore({ currentUser, task, toast, signDialog }),
    []
  )

  let docNumber = task.data.document_number?.number

  let renderWho = () => {
    if (currentUser.kind === 'company') {
      if (isEditableDraft) {
        return (
          <div onClick={(e) => e.preventDefault()}>
            <ContractorSelector
              contractors={contractors!}
              value={task.data.contractor ? task.data.contractor.id : undefined}
              onChange={(id) => draftStore.updateContractor(id)}
              placeholder="Исполнитель…"
              menuPosition="fixed"
              styles={buttonSelectStyles}
            />
          </div>
        )
      } else {
        return <TaskContractor task={task} />
      }
    } else {
      return task.data.company.data.name
    }
  }

  let title
  if (isEditableDraft) {
    title = (
      <div onClick={(e) => e.preventDefault()}>
        <SubtaskTemplateSelector
          templates={templates!}
          onChange={(id) => draftStore.updateSubtasks(id)}
          value={
            task.data.title == null || task.data.title === ''
              ? undefined
              : { label: task.data.title }
          }
          placeholder="Вид работы…"
          styles={buttonSelectStyles}
        />
      </div>
    )
  } else {
    title = task.data.title == null || task.data.title === '' ? '–' : task.data.title
  }

  let period
  if (isEditableDraft) {
    period = (
      <div onClick={(e) => e.preventDefault()}>
        <DatePicker
          value={
            task.data.since
              ? [
                  new Date(task.data.since),
                  // @ts-ignore
                  task.data.upto ? new Date(task.data.upto) : null,
                ]
              : undefined
          }
          placeholder="Период…"
          design="button"
          selectsRange={true}
          onChange={(range) => draftStore.updatePeriod(range as DateRange)}
          minDate={undefined}
          style={{ left: -5 }}
        />
      </div>
    )
  } else if (task.data.since && task.data.upto) {
    period = printPeriod(task.data.since, task.data.upto, false)
  } else {
    period = '–'
  }

  let amount = task.data.display_amount && <Amount value={task.data.display_amount} />

  let taskStatus = task.data.task && (
    <StatusBadge status={task.data.task.status} map={taskStatusMap} />
  )

  let actStatus = <StatusBadge status={task.data.act.status} map={actStatusMap} />

  let receiptIcon
  if (task.data.multi_receipts && task.data.fns_receipts.length > 1) {
    receiptIcon = task.data.fns_receipts.length > 0 && (
      <div onClick={(e) => e.stopPropagation()}>
        <IconButton as="a" style={styles.icon} href={`${url}#receipts`} tooltip="Чеки">
          <ReceiptManyIcon />
        </IconButton>
      </div>
    )
  } else {
    let receipt = last(task.data.fns_receipts)
    receiptIcon = receipt && (
      <div onClick={(e) => e.stopPropagation()}>
        <IconButton
          as="a"
          style={styles.icon}
          href={receipt.preview_url}
          target="_blank"
          tooltip={receipt.status === 'annulled' ? 'Чек (аннулирован)' : 'Чек'}
        >
          {receipt.status === 'annulled' ? <ReceiptErrorIcon /> : <ReceiptIcon />}
        </IconButton>
      </div>
    )
  }

  let paymentStatus = (
    <Flex gap=".25rem">
      <StatusBadge status={task.data.payment.status} map={paymentStatusMap} />
      {receiptIcon}
      {task.data.payment.error && (
        <div style={styles.icon}>
          <Tooltip label={task.data.payment.error} placement="top" aria-label="button">
            <ErrorIcon fill={'var(--color-red)'} />
          </Tooltip>
        </div>
      )}
    </Flex>
  )

  let size = isMobile ? 's' : 'xs'
  let buttons = []
  if (options.actionButtons) {
    if (task.data.actions?.includes('make_work_acceptance')) {
      let { isDisabled, problems } = actionsStore.getActionState('make_work_acceptance')
      buttons.push(
        <Button
          onTap={(e: any) => {
            e.preventDefault()
            actionsStore.openContractorAcceptDialog({
              checkPaymentDetails: task.data.check_payment_details,
            })
          }}
          size={size}
          isDisabled={isDisabled}
        >
          Подписать акт
        </Button>
      )
      if (isDisabled) {
        buttons.push(
          <div style={{ color: 'var(--color-red)', fontSize: '.9rem' }}>
            <TaskProblemList problems={problems} />
          </div>
        )
      }
    }
    if (task.data.actions?.includes('reject')) {
      buttons.push(
        <Button
          design="normal"
          onTap={(e: any) => {
            e.preventDefault()
            history.push(
              `/dashboard/${task.data.kind === 'task' ? 'tasks' : 'acts'}/${
                task.id
              }?open_reject_form=1`
            )
          }}
          size={size}
        >
          Отклонить акт
        </Button>
      )
    }
    if (task.data.actions?.includes('accept_annul')) {
      buttons.push(
        <Button
          onTap={(e: any) => {
            e.preventDefault()
            actionsStore.openAcceptAnnulDialog()
          }}
          size={size}
          isDisabled={actionsStore.isActionDisabled('accept_annul')}
        >
          Подписать соглашение
        </Button>
      )
    }
  }

  if (isMobile) {
    let renderRow = (label: React.ReactNode, children: React.ReactNode) => (
      <ListCell row style={styles.row}>
        <div style={styles.label}>{label}</div>
        <div>{children}</div>
      </ListCell>
    )
    return (
      <ListItem as={Link} to={url} wrap={true} style={styles.item}>
        <ListCell row style={{ color: 'var(--color-secondary)' }}>
          <ListCell>№ {docNumber}</ListCell>
          <ListCell style={{ textAlign: 'right' }}>{period}</ListCell>
        </ListCell>
        <ListCell row style={styles.title}>
          {title}
        </ListCell>
        {options.who &&
          renderRow(
            currentUser.kind === 'company' ? 'Исполнитель' : 'Заказчик',
            renderWho()
          )}
        {renderRow('Cумма', amount)}
        {options.kind === 'tasks' && renderRow('Задание', taskStatus)}
        {renderRow('Инвойс', actStatus)}
        {renderRow('Платёж', paymentStatus)}
        {buttons.length > 0 && (
          <ListCell row style={{ marginTop: '1rem' }}>
            <Flex direction="column" gap=".5rem" style={{ width: '100%' }}>
              {buttons}
            </Flex>
          </ListCell>
        )}
        {actionsStore.render()}
      </ListItem>
    )
  }

  return (
    <ListItem as={Link} to={url} wrap>
      <ListCell row style={{ alignItems: isEditableDraft ? 'center' : 'start' }}>
        {options.isSelectable && (
          <ListCell col="selection">
            <div onClick={(e) => e.preventDefault()}>
              <Checkbox value={isSelected!} onChange={onToggleSelected!} />
            </div>
          </ListCell>
        )}
        <ListCell col="number">
          {docNumber && (
            <div
              style={{
                fontSize: docNumber > 9999 ? '.85rem' : '1rem',
                lineHeight: '1.5rem',
              }}
            >
              {docNumber}
            </div>
          )}
        </ListCell>
        <ListCell col="title">{title}</ListCell>
        {options.who && (
          <ListCell
            col="who"
            style={{
              overflow: isEditableDraft ? 'visible' : 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {renderWho()}
          </ListCell>
        )}
        <ListCell col="period">{period}</ListCell>
        <ListCell col="amount">{amount}</ListCell>
        {options.kind === 'tasks' && <ListCell col="taskStatus">{taskStatus}</ListCell>}
        <ListCell col="actStatus">{actStatus}</ListCell>
        <ListCell col="paymentStatus">{paymentStatus}</ListCell>
        {actions && <ListCell col="actions">{actions}</ListCell>}
      </ListCell>
      {buttons.length > 0 && (
        <ListCell row>
          <Flex gap="1rem" style={{ marginLeft: 50 }} align="center">
            {buttons}
          </Flex>
        </ListCell>
      )}
      {actionsStore.render()}
    </ListItem>
  )
})

interface TaskListProps {
  options: TaskListOptions
  baseUrl?: string
  tasks: Collection<TaskEntity>
  appendUrl?: string
}

const TaskList = observer((props: TaskListProps) => {
  let { isMobile } = useMediaContext()
  let { tasks, options, baseUrl = '', appendUrl = '' } = props
  let currentUser = useUserStore()

  let renderItem = (item: Entity<TaskEntity>) => {
    return (
      <TaskListItem
        key={item.id}
        options={options}
        task={item}
        url={`${baseUrl}/${options.kind}/${item.id}${appendUrl}`}
      />
    )
  }

  return (
    <List cols={taskListCols} gap={isMobile ? '1rem' : 0}>
      {!isMobile && (
        <ListHeader>
          <ListCell col="number">№</ListCell>
          <ListCell col="title">Название</ListCell>
          {options.who && (
            <ListCell col="who">
              {currentUser.kind === 'company' ? 'Исполнитель' : 'Заказчик'}
            </ListCell>
          )}
          <ListCell col="period">Период</ListCell>
          <ListCell col="amount">Стоимость</ListCell>
          {options.kind === 'tasks' && <ListCell col="taskStatus">Задание</ListCell>}
          <ListCell col="actStatus">Инвойс</ListCell>
          <ListCell col="paymentStatus">Платёж</ListCell>
        </ListHeader>
      )}
      {tasks.items.map(renderItem)}
    </List>
  )
})

export default TaskList
export { TaskListItem, taskListCols }
