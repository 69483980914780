import React from 'react'
import { Typography, TypographyProps, TypographyVariant } from 'ui-kit'

export type InlineTextProps = Omit<TypographyProps, 'variant'> & {
  variant?: TypographyVariant.Span
}

const InlineText = ({
  variant = TypographyVariant.Span,
  children,
  ...restProps
}: InlineTextProps) => (
  <Typography variant={variant} {...restProps}>
    {children}
  </Typography>
)

export default InlineText
