import { rest } from 'msw'

import { json } from './helpers'

const contractor_segments = [
  {
    id: 1,
    title: 'Сохранённый сегмент',
    filters: [
      { name: 'kind', value: 'individual_entrepreneur' },
      { name: 'added_at', value: { from: '2021-08-31' } },
    ],
    count: 137,
  },
  {
    id: 2,
    title: 'Курьеры',
    filters: [{ name: 'cf_3', value: ['6'] }],
    count: 1,
  },
  {
    id: 99,
    title: 'DELETED',
    filters: [
      { name: 'DELETED', value: ['6'] },
      { name: 'cf_3', value: ['6', 'DELETED'] },
    ],
    count: 1,
  },
]

const new_segment = {
  id: 111,
  title: 'Новый сегмент',
  filters: [{ name: 'kind', value: 'individual_entrepreneur' }],
}

export default [
  rest.get('/api/company/segments/contractors', json(contractor_segments)),
  // rest.get('/api/company/segments/contractor', json([])),

  rest.post('/api/company/segments/contractors', json(new_segment)),

  rest.delete('/api/company/segments/:id', json({})),
  rest.patch('/api/company/segments/:id', json({ title: 'Поменял' })),
]
