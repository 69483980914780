export type AnimationFunction = (value: any) => object

export interface FadeProps {
  initialOpacity?: number
}

const fade =
  ({ initialOpacity = 0 }: FadeProps = {}): AnimationFunction =>
  (value) => ({ opacity: value.to([0, 1], [initialOpacity, 1]) })

export interface TranslateProps {
  vert?: number
  horiz?: number
}

const translate =
  ({ vert = 0, horiz = 0 }: TranslateProps = {}): AnimationFunction =>
  (value) => ({
    translateX: value.to([0, 1], [horiz, 0]),
    translateY: value.to([0, 1], [vert, 0]),
  })

export type Side = 'top' | 'left' | 'bottom' | 'right'

export interface SlideProps {
  side?: Side
  distance?: number
}

const slide = ({ side = 'top', distance = 10 }: SlideProps = {}): AnimationFunction => {
  const axis = side === 'left' || side === 'right' ? 'horiz' : 'vert'
  const dir = side === 'right' || side === 'bottom' ? 1 : -1
  return translate({ [axis]: distance * dir })
}

const origins = {
  center: '50% 50%',
  top: '50% 0',
  bottom: '50% 100%',
  left: '0 50%',
  right: '100% 50%',
  'top-left': '0 0',
  'top-right': '100% 0',
  'bottom-left': '0 100%',
  'bottom-right': '100% 100%',
}

export type ScaleOrigin = keyof typeof origins

export interface ScaleProps {
  initialScale?: number
  origin?: keyof typeof origins
}

const scale =
  ({ initialScale = 0, origin = 'center' }: ScaleProps = {}) =>
  (value: any) => ({
    transformOrigin: origins[origin],
    transform: value
      .interpolate([0, 1], [initialScale, 1])
      .interpolate((v: number) => `scale(${v})`),
  })

const compose = (fns: AnimationFunction | AnimationFunction[]) =>
  Array.isArray(fns)
    ? (value: any) => {
        const res = {}
        fns.forEach((fn) => Object.assign(res, fn(value)))
        return res
      }
    : fns

const functions = { fade, translate, slide, scale, compose }

export default functions
