import * as React from 'react'
import { Modal, Button, Col, Flex, H2 } from 'components/ui'
import { observer } from 'mobx-react-lite'
import { ScenarioTaskActionsStore } from './ScenarioTaskActionsStore'

type ConfirmActionDialogProps = {
  title: string
  onClose: () => void
  onConfirm: () => void
  store: ScenarioTaskActionsStore
  description: React.ReactNode
  confirmText?: string
}

export const ConfirmActionDialog = observer(
  ({
    title,
    description,
    confirmText = 'Подтвердить',
    store,
    onConfirm,
    onClose,
  }: ConfirmActionDialogProps) => {
    return (
      <Modal size={1000} isOpen={true} onClose={onClose}>
        <Col gap="var(--gap-m)">
          <H2>{title}</H2>
          <div style={{ fontWeight: 500, fontSize: '1.15rem' }}>{description}</div>

          <Flex gap="1rem">
            <Button
              design="normal"
              onClick={onClose}
              isDisabled={store.actionState.state === 'pending'}
            >
              Отмена
            </Button>
            <Button onClick={onConfirm} isLoading={store.actionState.state === 'pending'}>
              {confirmText}
            </Button>
          </Flex>
        </Col>
      </Modal>
    )
  }
)
