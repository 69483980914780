import React, { forwardRef, useState } from 'react'

import { StyleProps, useStyles } from 'ui/styled'
import { Taply, TapState, initialTapState } from 'ui/taply'
import { ReactComponent as CloseFilledIcon } from 'ui/konsol/icons/close-filled.svg'

interface DeleteButtonProps extends StyleProps<[DeleteButtonProps, TapState]> {
  onTap?: () => void
}

const deleteButtonStyles = (props: DeleteButtonProps, tapState: TapState) => {
  const { isHovered, isFocused } = tapState
  return {
    root: {
      display: 'flex',
      width: 16,
      height: 16,
      borderRadius: '50%',
      fill: isHovered ? 'var(--konsol-color-black)' : 'var(--konsol-color-grey)',
      transition: 'fill .2s ease-in-out',
      cursor: 'pointer',
      outline: 'none',
      boxShadow: isFocused ? 'var(--konsol-focus-shadow)' : 'none',
    },
  }
}

const DeleteButton = forwardRef((props: DeleteButtonProps) => {
  const { onTap } = props
  const [tapState, setTapState] = useState(initialTapState)
  const styles = useStyles(deleteButtonStyles, [props, tapState])
  return (
    <Taply onTap={onTap} tapState={tapState} onChangeTapState={setTapState}>
      <div style={styles.root} title="Удалить">
        <CloseFilledIcon />
      </div>
    </Taply>
  )
})

type TagSize = 's' | 'm' | 'l'

type TagVariant = 'white' | 'grey'

interface TagProps extends StyleProps<[TagProps & typeof defaultProps]> {
  children: React.ReactNode
  size?: TagSize
  variant?: TagVariant
  isDeletable?: boolean
  onDelete?: () => void
}

const defaultProps = {
  size: 'm' as TagSize,
  variant: 'white' as TagVariant,
  isDeletable: false,
}

const heightMap = {
  l: 40,
  m: 32,
  s: 24,
}

const paddingMap = {
  l: 8,
  m: 6,
  s: 4,
}

const tagStyles = (props: TagProps & typeof defaultProps) => {
  const { size, variant } = props

  const root: React.CSSProperties = {
    minHeight: heightMap[size],
    display: 'inline-flex',
    alignItems: 'center',
    padding: `2px ${paddingMap[size]}px`,
    borderRadius: 6,
    fontSize: 'var(--konsol-font-size)',
    boxSizing: 'border-box',
    overflow: 'hidden',
  }

  if (variant === 'white') {
    root.background = 'white'
    root.border = '1px solid var(--konsol-color-grey-16)'
  } else {
    root.background = 'var(--konsol-color-grey-16)'
  }

  const label = {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }

  const deleteButton = {
    marginLeft: 4,
  }

  return { root, label, deleteButton }
}

const Tag = forwardRef((_props: TagProps) => {
  const props = _props as TagProps & typeof defaultProps
  const styles = useStyles(tagStyles, [props])
  const { children, isDeletable, onDelete } = props
  return (
    <div style={styles.root}>
      <div style={styles.label}>{children}</div>
      {isDeletable && <DeleteButton style={styles.deleteButton} onTap={onDelete} />}
    </div>
  )
})

Tag.defaultProps = defaultProps

export { Tag }
