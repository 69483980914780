import React, { forwardRef, useState, createContext, useContext } from 'react'

import { StyleProps, useStyles } from 'ui/styled'
import { Taply, TapState, initialTapState } from 'ui/taply'
import { Row } from 'ui/flex'

type ChipStyleProps = [
  ChipProps & typeof defaultProps,
  { isActive: boolean; tapState: TapState }
]

interface ChipsContextProps {
  value?: string
  onChange?: (value: string) => void
}

const ChipsContext = createContext<ChipsContextProps>({})

interface ChipsProps extends StyleProps<[ChipsProps]> {
  value: string
  onChange: (value: string) => void
  children?: React.ReactNode
  className?: string
}

const Chips = forwardRef<HTMLDivElement, ChipsProps>((props: ChipsProps, ref) => {
  const { value, onChange, children, className } = props
  const context = { value, onChange }
  const styles = useStyles(undefined, [props])
  return (
    <ChipsContext.Provider value={context}>
      <Row gap="4px" ref={ref} className={className} style={styles.root}>
        {children}
      </Row>
    </ChipsContext.Provider>
  )
})

interface ChipProps extends StyleProps<ChipStyleProps> {
  children: React.ReactNode
  onTap?: () => void
  isActive?: boolean
  value?: string
}

const defaultProps = {
  isActive: false,
}

const chipStyles = (...[_props, { isActive, tapState }]: ChipStyleProps) => {
  const root: React.CSSProperties = {
    height: 24,
    display: 'inline-flex',
    alignItems: 'center',
    padding: '0 16px',
    borderRadius: 12,
    fontSize: 'var(--konsol-font-size)',
    fontWeight: 600,
    outline: 'none',
    boxShadow: tapState.isFocused ? 'var(--konsol-focus-shadow)' : 'none',
    cursor: 'pointer',
  }

  if (isActive) {
    root.background = 'var(--konsol-color-blue-16)'
    root.color = 'var(--konsol-color-text-accent)'
  } else if (tapState.isHovered) {
    root.background = 'var(--konsol-color-grey-08)'
    root.color = 'var(--konsol-color-text)'
  } else {
    root.background = 'transparent'
    root.color = 'var(--konsol-color-grey)'
  }

  return { root }
}

const Chip = forwardRef<HTMLDivElement, ChipProps>((_props: ChipProps, ref) => {
  const props = _props as ChipProps & typeof defaultProps
  const { children, value } = props
  const [tapState, setTapState] = useState(initialTapState)
  const context = useContext(ChipsContext)
  const isActive = context.value !== undefined ? context.value === value : props.isActive
  const styles = useStyles(chipStyles, [props, { isActive, tapState }])
  const onTap = () => {
    props.onTap?.()
    if (value !== undefined) context.onChange?.(value)
  }

  return (
    <Taply onTap={onTap} tapState={tapState} onChangeTapState={setTapState}>
      <div style={styles.root} ref={ref}>
        {children}
      </div>
    </Taply>
  )
})

Chip.defaultProps = defaultProps

export { Chip, Chips }
