import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { capitalize } from 'lodash'

import { ActionState, initialActionState } from 'mobx/mobx'
import { useMediaContext } from 'App/MediaContext'
import { useUserStore } from 'stores/context'

import { CodeInput } from 'components'
import { Flex, Button } from 'components/ui'

interface SignInputProps {
  onSign: (payload?: Object) => ActionState<any>
  skipTextPrefix?: boolean
  text: string
}

const SignInput = observer(({ onSign, text, skipTextPrefix }: SignInputProps) => {
  let { isMobile } = useMediaContext()
  let currentUser = useUserStore()
  let [code, setCode] = useState('')
  let [action, setAction] = useState<any>(initialActionState)
  let onChangeCode = (code: string) => {
    if (code.length === 4) {
      setCode('')
      setAction(onSign({ code }))
    } else {
      setCode(code)
    }
  }

  let isLoading = action.state === 'pending'
  let method
  if (currentUser.kind === 'company') {
    method = 'code'
  } else {
    method = currentUser.platform === 'mobile_app' ? 'button' : 'code'
  }
  let elem
  if (method === 'code') {
    elem = (
      <CodeInput autoFocus value={code} onChange={onChangeCode} isLoading={isLoading} />
    )
  } else {
    elem = (
      <Button
        onTap={() => setAction(onSign())}
        isLoading={isLoading}
        style={{ alignSelf: isMobile ? 'stretch' : 'start' }}
      >
        {capitalize(text)}
      </Button>
    )
  }

  let messageElem
  if (method === 'code' && action.state === 'fulfilled' && !skipTextPrefix) {
    messageElem = <span>Введите код из СМС, чтобы {text}</span>
  } else if (method === 'code' && action.state === 'fulfilled') {
    messageElem = <span>{text}</span>
  } else if (method === 'code' && action.state === 'pending') {
    messageElem = <span style={{ color: 'var(--color-secondary)' }}>Проверяю код...</span>
  } else if (action.state === 'rejected') {
    messageElem = (
      <span style={{ color: 'var(--color-red)', fontWeight: 500 }}>
        {action.value.message}
      </span>
    )
  }

  return (
    <Flex
      align="center"
      gap={isMobile ? '1rem' : '2rem'}
      direction={isMobile ? 'column' : 'row'}
    >
      {elem}
      {messageElem}
    </Flex>
  )
})

export default SignInput
