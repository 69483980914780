import React, { useState, useCallback } from 'react'
import { printDate } from 'utils/datetime'
import { ReactComponent as ClearIcon } from '@material-design-icons/svg/round/clear.svg'
import IconButton from '../IconButton'
import { Popover, PopoverContent, PopoverTrigger, Portal } from '@chakra-ui/react'
import styles from './DatePicker.module.css'
import clsx from 'clsx'
import type { ComponentProps } from 'app.types'

import { DatePickerCalendar } from 'ui'

export type DateRange = [Date | null, Date | null]

interface DateInputProps extends ComponentProps {
  excludeDates?: Date[]
  isDisabled?: boolean
  placeholder?: string
  minDate?: Date
  design?: 'input' | 'button'
  size?: 'm' | 'l'
  value?: Date | DateRange
  onChange: (date: DateRange | Date | undefined) => void
  selectsRange: boolean
  isClearable?: boolean
  printMode?: 'short' | 'full'
}

const ClearButton = ({ onChange }: Pick<DateInputProps, 'onChange'>) => (
  <IconButton
    className={styles.clearButton}
    onTap={(e: any) => {
      e.stopPropagation()
      onChange && onChange(undefined)
    }}
    tooltip="Удалить"
  >
    <ClearIcon className={styles.clearIcon} />
  </IconButton>
)

const formatContent = ({
  value,
  selectsRange,
  placeholder,
  printMode,
}: Pick<
  DateInputProps,
  'value' | 'selectsRange' | 'placeholder' | 'printMode'
>): string => {
  if (value) {
    let printedValue
    let printOptions = { mode: printMode }
    if (selectsRange) {
      let range = value as DateRange
      let [since, upto] = [
        printDate(range[0], printOptions),
        printDate(range[1], printOptions),
      ]
      if (since === upto) {
        printedValue = `${since} (на день)`
      } else if (since && !upto) {
        printedValue = printMode === 'full' ? `с ${since} по ...` : `${since} – ...`
      } else {
        printedValue =
          printMode === 'full' ? `с ${since} по ${upto}` : `${since} – ${upto}`
      }
    } else {
      printedValue = printDate(value as Date, printOptions)
    }
    return printedValue
  } else {
    return placeholder || (selectsRange ? 'Выберите период' : 'Выберите дату')
  }
}

export const DatePicker: React.FC<DateInputProps> = ({
  excludeDates,
  design = 'input',
  size = 'm',
  printMode = 'full',
  placeholder,
  value,
  onChange,
  minDate,
  selectsRange,
  isClearable,
  style,
  className,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const open = () => setIsOpen(!isOpen)
  const close = () => setIsOpen(false)

  const onSelect = useCallback(
    (value: Date | DateRange | undefined) => {
      if (selectsRange) {
        let [since, upto] = value as DateRange
        if (since && upto) setIsOpen(false)
        onChange([since, upto])
      } else {
        onChange(value as Date)
        setIsOpen(false)
      }
    },
    [onChange, selectsRange]
  )

  const showClearButton = isClearable && value !== undefined

  return (
    <Popover placement="bottom-start" closeOnBlur isOpen={isOpen} onClose={close}>
      {/* @ts-ignore */}
      <PopoverTrigger>
        <label
          onClick={open}
          className={clsx(className, {
            [styles.designInput]: design === 'input',
            [styles.designButton]: design === 'button',
            [styles.placeholder]: !value,
            [styles.sizeL]: size === 'l',
            [styles.inputFocused]: isOpen,
            [styles.clearable]: showClearButton,
          })}
          style={style}
        >
          {formatContent({ value, selectsRange, placeholder, printMode })}
          {showClearButton && <ClearButton onChange={onChange} />}
        </label>
      </PopoverTrigger>
      <Portal>
        <PopoverContent className={styles.popover} width="auto">
          <DatePickerCalendar
            excludeDates={excludeDates}
            minDate={minDate}
            onChange={onSelect}
            selectsRange={selectsRange}
            value={value}
          />
        </PopoverContent>
      </Portal>
    </Popover>
  )
}
