import React from 'react'
import { TableCell, TableCellProps } from 'ui-kit/Table/components'

export type TableRowProps = {
  cells: TableCellProps[]
}

const TableRow: React.FunctionComponent<TableRowProps> = ({ cells }) => (
  <tr>
    {cells.map((cell, i) => (
      <TableCell type={cell.type} content={cell.content} key={i} />
    ))}
  </tr>
)

export default TableRow
