import React from 'react'

import cx from 'clsx'

import { useMediaContext } from 'App/MediaContext'

import s from './styles.module.css'

type NotificationType = 'error' | 'warning' | 'success'

interface NotificationProps {
  title: string
  type: NotificationType
  children?: React.ReactNode
}

export const Notification = ({ title, children, type }: NotificationProps) => {
  const { isMobile } = useMediaContext()

  return (
    <div
      className={cx(s.notificationWrapper, s[`notificationWrapper_${type}`], {
        [`${s[`notificationWrapper_mobile`]}`]: isMobile,
      })}
    >
      <div className={cx(s.notificationTitle, s[`notificationTitle_${type}`])}>
        {title}
      </div>
      {children}
    </div>
  )
}
