import React from 'react'
import cl from 'clsx'

import css from './styles.module.css'

export type DividerProps = {
  className?: string
}

const Divider: React.FunctionComponent<DividerProps> = ({ className }) => (
  <div className={cl(css.divider, className && className)}></div>
)

export default Divider
