import React, { useEffect, useMemo, useState } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import cl from 'clsx'

import { badges, fetchBadges, useBadgesSubscription } from 'App/badges'
import CompanySelector from 'components/CompanySelector'
import { useUserStore } from 'stores/context'
import companyMemberRoles from 'pages/companies/companyMemberRoles'

import { Flex, BaseButton } from 'components/ui'
import { Avatar } from 'components'

import s from './style.module.css'

import { ReactComponent as LogoutIcon } from './assets/logout.svg'
import { ReactComponent as CloseIcon } from './close.svg'
import { ReactComponent as LockIcon } from 'assets/lock.svg'

import { fetchApi } from 'api'

import { Logo, useTheme } from 'ui'

interface MenuItemProps {
  to: string
  label: React.ReactNode
  icon: string
  onClick?: () => void
}

const MenuItem = ({ to, label, icon, onClick }: MenuItemProps) => {
  const [IconImage, setIconimage] = useState<any>(null)
  const { getSidebarIcon } = useTheme()

  useEffect(() => {
    getSidebarIcon(icon).then(setIconimage)
  }, [])

  return (
    //@ts-ignore
    <NavLink
      to={to}
      activeClassName={s.menuItemActive}
      className={s.menuItem}
      onClick={onClick}
    >
      {/* {icon && <img className={s.menuIcon} src={icon} alt="I" />} */}
      {icon && <div className={s.menuIcon}>{IconImage}</div>}
      {label}
    </NavLink>
  )
}

interface SidebarProps {
  isOpen: boolean
  onOpen: (isOpen: boolean) => void
  showCloseButton: boolean
}

const SideBar = observer(({ onOpen, showCloseButton }: SidebarProps) => {
  const history = useHistory()
  const currentUser = useUserStore()
  const { user, kind, role } = currentUser
  const isLongName = user!.data.name.split(' ').some((s) => s.length > 16)

  useMemo(() => fetchBadges(), [])
  useBadgesSubscription()

  const [FireIcon, setFireIcon] = useState<any>(null)
  const { getSidebarIcon } = useTheme()

  useEffect(() => {
    getSidebarIcon('fire').then(setFireIcon)
  }, [])

  const handleMenuClick = () => {
    if (currentUser.user!.data.flags.offers) {
      fetchApi({
        url: 'users/events',
        method: 'PUT',
        data: { event: 'marketplace_contractor_click' },
      })
    }
  }

  let menuItems
  if (kind === 'company') {
    menuItems = (
      <>
        {/* {currentUser.hasFeature('offers') && (
          <MenuItem
            to="/offers"
            icon={'search'}
            label={
              <>
                <div style={{ flexGrow: 1 }}>Маркетплейс</div>
                {badges.offers_responses > 0 && (
                  <div className={s.badge}>{badges.offers_responses}</div>
                )}
              </>
            }
          />
        )} */}
        <MenuItem
          to="/invites"
          // icon={addPeopleIcon}
          icon={'add-people'}
          label={
            <>
              <div style={{ flexGrow: 1 }}>Приглашения</div>
              {badges.unread_invites > 0 && (
                <div className={s.badge}>{badges.unread_invites}</div>
              )}
            </>
          }
        />
        {/* <MenuItem to="/contractors" icon={peopleIcon} label="Исполнители" /> */}
        <MenuItem to="/contractors" icon={'people'} label="Исполнители" />

        {currentUser.hasFeature('documents') && (
          // <MenuItem to="/documents" icon={contractIcon} label="Договоры и документы" />
          <MenuItem to="/documents" icon={'contract'} label="Документы" />
        )}

        {currentUser.hasFeature('templates') && (
          // <MenuItem to="/templates" icon={templatesIcon} label="Справочник работ" />
          <MenuItem to="/templates" icon={'templates'} label="Справочник работ" />
        )}
        {(currentUser.hasFeature('projects') ||
          currentUser.hasFeature('scenario_tasks')) && (
          <>
            {/* <MenuItem to="/tasks" icon={tasksIcon} label="Задания" />
            <MenuItem to="/projects" icon={orgchartIcon} label="Проекты" /> */}
            <MenuItem to="/tasks" icon={'task'} label="Задания" />
            <MenuItem to="/projects" icon={'project'} label="Проекты" />
          </>
        )}
        {/* <MenuItem
          to="/acts"
          icon={'document'}
          label={
            <>
              <div style={{ flexGrow: 1 }}>Акты</div>
              {badges.acts_for_sign > 0 && (
                <div className={s.badge}>{badges.acts_for_sign}</div>
              )}
            </>
          }
        /> */}
        {/* {currentUser.hasFeature('bundles') && (
          <MenuItem to="/bundles" icon={'bundle'} label="Пачки актов" />
        )} */}
      </>
    )
  } else {
    menuItems = (
      <>
        {currentUser.user!.data.flags.dashboard && (
          <MenuItem to="/dashboard" icon={'main'} label="Главная" />
        )}
        {/* <MenuItem
          to="/notifications"
          icon={'notification'}
          label={
            <>
              <div style={{ flexGrow: 1 }}>Уведомления</div>
              {badges.unread_notifications > 0 && (
                <div className={s.badge}>{badges.unread_notifications}</div>
              )}
            </>
          }
        /> */}
        {currentUser.user!.data.flags.offers && (
          <MenuItem
            to="/offers"
            icon={'search'}
            label="Поиск работы"
            onClick={handleMenuClick}
          />
        )}
        <MenuItem to="/companies" icon={'company'} label="Компании" />

        <MenuItem
          to="/tasks"
          icon={'task'}
          label={
            <>
              <div style={{ flexGrow: 1 }}>Задания</div>
              {!currentUser.user!.data.flags.scenario_tasks &&
                badges.unread_tasks > 0 && (
                  <div className={s.badge}>{badges.unread_tasks}</div>
                )}
            </>
          }
        />

        {/* <MenuItem
          to="/acts#done"
          icon={'document'}
          label={
            <>
              <div style={{ flexGrow: 1 }}>Акты</div>
              {badges.acts_for_sign > 0 && (
                <div className={s.badge}>{badges.acts_for_sign}</div>
              )}
            </>
          }
        /> */}
        {user!.data.contractor!.data.tax_box && (
          <MenuItem
            to="/taxes"
            icon={'taxes'}
            label={
              <>
                <div style={{ flexGrow: 1 }}>Налоговая копилка</div>
              </>
            }
          />
        )}
        {user!.data.contractor!.data.tax_savings_account && (
          <MenuItem
            to="/taxes"
            icon={'taxes'}
            label={
              <>
                <div style={{ flexGrow: 1 }}>Налоговая копилка</div>
              </>
            }
          />
        )}
      </>
    )
  }

  const { is_admin_user_login } = user!.data.flags

  return (
    <aside className={s.root}>
      {showCloseButton && (
        <div onClick={() => onOpen(false)} className={s.closeButton}>
          <CloseIcon fill="var(--color-icon)" />
        </div>
      )}
      {kind === 'company' ? (
        <CompanySelector style={{ marginTop: 25, marginBottom: 30 }} />
      ) : (
        <NavLink to="/">
          <Logo className={s.logo} />
        </NavLink>
      )}

      <div className={s.menu}>
        {menuItems}
        <div className={s.menuSeparator} />
        {/* <MenuItem to="/help" icon={helpIcon} label="Помощь" /> */}
        <MenuItem to="/help" icon={'help'} label="Помощь" />
        {currentUser.hasFeature('contractors_risk_profiles') && (
          <MenuItem to="/analytics" icon={'chart'} label="Аналитика рисков" />
        )}
        {!currentUser.hasFeature('contractors_risk_profiles') &&
          currentUser.hasFeature('contractors_risk_placeholder') && (
            <MenuItem
              to="/analytics"
              icon={'chart'}
              label={
                <Flex direction="row" align="center" justify="stretch" gap="5px">
                  <span>Аналитика рисков</span>
                  <LockIcon
                    style={{
                      height: '12px',
                      width: '12px',
                    }}
                  />
                </Flex>
              }
            />
          )}
      </div>

      <div className={s.bottom}>
        <div className={s.userControls}>
          <BaseButton onTap={user && handleClick} style={{ textAlign: 'left' }}>
            <Flex justify="space-between" align="center" className={s.userInfo}>
              <Flex gap=".8rem" align="center" className={s.userInfo}>
                <Avatar
                  value={user!.data.avatar_url}
                  size="40px"
                  name={user!.data.name}
                  style={{ flexShrink: 0 }}
                />
                <div>
                  <div className={cl(s.name, { [s.longName]: isLongName })}>
                    {user!.data.name}
                  </div>
                  {role && (
                    <div className={s.role}>{companyMemberRoles[role].name || role}</div>
                  )}
                </div>
              </Flex>
              <BaseButton
                onTap={(e: any) => {
                  e.stopPropagation()
                  currentUser.logout()
                }}
                className={s.logout}
              >
                <LogoutIcon />
              </BaseButton>
            </Flex>
          </BaseButton>
          {user &&
            user.data.companies &&
            user.data.companies.items.length > 0 &&
            user.data.contractor && (
              <div
                className={s.changeKindButton}
                onClick={() =>
                  currentUser.changeKind(
                    currentUser.kind === 'company' ? 'contractor' : 'company'
                  )
                }
              >
                {currentUser.kind === 'company'
                  ? 'Войти за исполнителя'
                  : 'Войти за заказчика'}
              </div>
            )}
        </div>
      </div>
    </aside>
  )

  function handleClick() {
    history.push(
      kind === 'company' ? `/profile/` : `/contractors/${user?.data.contractor?.id}`
    )
  }
})

export default SideBar
