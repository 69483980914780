import { cloneElement } from 'react'

import mergeRefs from './mergeRefs'

const cloneElementWithRef = <T>(
  elem: React.ReactElement<T>,
  props: T & { ref: any },
  ...children: React.ReactNode[]
) =>
  cloneElement(
    elem,
    {
      ...props,
      ref: mergeRefs(props.ref, (elem as any).ref),
    },
    ...children
  )

export default cloneElementWithRef
