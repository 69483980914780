import React, { ReactNode } from 'react'
import cl from 'clsx'
import { CSSProperties } from 'styled-components'

import type { ComponentProps } from 'app.types'
import { Flex } from 'components/ui'

// import { ReactComponent as UploadIcon } from './assets/upload.svg'
import s from './style.module.css'

interface Props extends ComponentProps {
  data: {
    url?: string | undefined
    signed?: boolean
    // title или children
    title?: string
    date?: string
  }
  children?: ReactNode
  status?: string
  animated?: boolean
  error?: boolean
  icon?: ReactNode
  miniIcon?: ReactNode
  classes?: { root?: string; title?: string; img?: string; statusBadge?: string }
  titleStyle?: CSSProperties
  isRiskReport?: boolean
  isDisabled?: boolean
}

export default function FileThumb(props: Props) {
  const {
    data,
    children,
    status,
    animated,
    error,
    icon,
    miniIcon,
    classes,
    titleStyle,
    isRiskReport,
    isDisabled = false,
    ...rest
  } = props
  const text = children || data.title || ''
  let imgSrc

  if (error) {
    imgSrc = require('./assets/failed-file.png')
  } else if (isRiskReport) {
    imgSrc = require('./assets/risk-report.svg')
  } else if (data.signed) {
    imgSrc = require('./assets/signed-file.png')
  } else {
    imgSrc = require('./assets/normal-file.png')
  }

  const handleClick = (event: React.MouseEvent) => {
    if (isDisabled) {
      event.preventDefault()
    }
  }

  return (
    <a
      href={data.url}
      download
      onClick={handleClick}
      {...rest}
      target="_blank"
      rel="noopener noreferrer"
      className={cl(isDisabled && s.disabledLink)}
    >
      <Flex
        gap="1rem"
        align="center"
        className={cl(s.root, classes?.root, { [s.animated]: animated })}
      >
        <div style={{ flexShrink: 0 }}>
          {icon || (
            <img
              alt=""
              src={imgSrc}
              width="60"
              height="69"
              className={cl(classes?.img)}
            />
          )}
          {miniIcon && <div className={s.miniIcon}>{miniIcon}</div>}
        </div>
        <Flex direction="column" gap="0.5rem">
          <div
            className={cl(s.title, classes?.title, isDisabled && s.disabledTitle)}
            style={{
              ...titleStyle,
              ...(error ? { textDecoration: 'line-through' } : undefined),
            }}
          >
            {text}
          </div>
          {status ? (
            <div className={cl(s.statusBadge, classes?.statusBadge)}>{status}</div>
          ) : undefined}
          {data.date ? <div className={s.dateBadge}>{data.date}</div> : undefined}
        </Flex>
      </Flex>
    </a>
  )
}
