import React, { useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { initialActionState } from 'mobx/mobx'
import clsx from 'clsx'
import qs from 'query-string'

import { PageProps } from 'app.types'
import { useUserStore } from 'stores/context'
import { ActionStateView, useStateToast } from 'components/ui'
import LayoutEntry from 'components/ui/LayoutEntry'

import { Appear, H1, Text, Link, useTheme } from 'ui'

import Aside from './components/Aside'
import CodeStep from './components/CodeStep'
import InviteNotFound from './components/InviteNotFound'
import RedirectExistingUser from './components/RedirectExistingUser'
import { fetchInvite } from './components/requests'
import css from './styles.module.css'
import AuthSwitcher from './components/AuthSwitcher'

const isNotFound = (state: any) =>
  state.state === 'rejected' &&
  state.value?.kind === 'http' &&
  state.value?.response?.status === 404

type JoinStep = 'switcher' | 'code'

interface CompanyInfo {
  name: string
  avatar_url: string
}

const JoinPage = observer(({ match }: PageProps) => {
  const { config } = useTheme()

  const history = useHistory()
  const currentUser = useUserStore()
  const toast = useStateToast()

  const permalink = match.params.permalink || ''
  const location = useLocation()
  const query = qs.parse(location.search)

  const [step, setStep] = useState<JoinStep>('switcher')
  const [phone, setPhone] = useState<string>('')

  const classes = clsx('ui', css.container)

  if (currentUser.user !== undefined) {
    return <RedirectExistingUser permalink={permalink} query={query} />
  }

  const fetchState = useMemo(
    () => (permalink ? fetchInvite(permalink) : initialActionState),
    []
  )

  if (isNotFound(fetchState)) {
    return <InviteNotFound />
  }

  const text = config.text.pages.join

  return (
    <ActionStateView state={fetchState}>
      {(companyInfo?: CompanyInfo) => {
        const companyName = companyInfo?.name

        return (
          <LayoutEntry
            aside={<Aside />}
            logoAside={
              text.linkToAside ? (
                <div className={css.linkToAbout}>
                  <Link href="#about">{text.asideTitle}</Link>
                </div>
              ) : null
            }
            className={classes}
          >
            <Appear delay={100} animation={Appear.presets.slideDown}>
              <H1 style={{ marginBottom: '16px' }}>
                <span dangerouslySetInnerHTML={{ __html: companyName || text.title }} />
              </H1>
            </Appear>
            {companyName && (
              <Appear
                delay={150}
                animation={Appear.presets.slideDown}
                className={css.invitationText}
              >
                <Text size="l" color="secondary">
                  <span dangerouslySetInnerHTML={{ __html: text.description }} />
                </Text>
              </Appear>
            )}
            <Appear
              delay={200}
              animation={Appear.presets.slideDown}
              className={css.aboutText}
            >
              <Text color="secondary">
                {companyName ? (
                  <>
                    Обмен документами и&nbsp;оплата будут проходить через
                    платформу&nbsp;&mdash; взаимодействовать будет проще, а&nbsp;деньги
                    будут приходить быстрее
                  </>
                ) : (
                  <>
                    Для получения доступа к&nbsp;заказам нужно пройти регистрацию по номеру телефона
                  </>
                )}
              </Text>
            </Appear>
            {step === 'switcher' && (
              <AuthSwitcher
                permalink={permalink}
                query={query}
                onComplete={(phone) => {
                  setPhone(phone)
                  setStep('code')
                }}
              />
            )}
            {step === 'code' && (
              <CodeStep
                phone={phone}
                permalink={permalink!}
                query={query}
                onComplete={(user: any) => {
                  currentUser.login({ user })
                  if (user.flags.completed_registration) {
                    toast.success({ title: 'Вы уже зарегистрированы' })
                    history.push('/')
                  } else {
                    history.push('/registration')
                  }
                }}
                onBack={() => setStep('switcher')}
              />
            )}
          </LayoutEntry>
        )
      }}
    </ActionStateView>
  )
})

export default JoinPage
