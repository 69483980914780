import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { useStateToast } from 'components/ui'
import { fetchCheckJoin } from '../requests'

import { Spinner, Flex } from 'ui'

interface ICheckUserProps {
  permalink: string
  query: any
}

const CheckUser: React.FC<ICheckUserProps> = ({ permalink, query }) => {
  const history = useHistory()
  const toast = useStateToast()

  useEffect(() => {
    fetchCheckJoin(permalink, query).then(
      ({ message }) => {
        if (message !== null) toast.success({ title: message })
        history.push('/')
      },
      (err) => {
        toast.error({ title: 'Ошибка', description: err.message })
        history.push('/')
      }
    )
  }, [])

  return (
    <Flex align="center" justify="center">
      <Spinner size="l" />
    </Flex>
  )
}

export default CheckUser
