import React from 'react'
import { mapValues } from 'lodash'

import { Chips } from 'components'
import SectionRouter from './SectionRouter'

export interface SectionNavigatorSection {
  title: string
  render: () => React.ReactNode
}

interface SectionNavigatorProps {
  sections: { [key: string]: SectionNavigatorSection }
  basePath?: string
  defaultSection?: string
}

const SectionNavigator = ({
  sections,
  basePath,
  defaultSection,
}: SectionNavigatorProps) => (
  <SectionRouter
    basePath={basePath}
    sections={mapValues(sections, 'render')}
    defaultSection={defaultSection}
    render={({ section, content, setSection }) => (
      <>
        <Chips
          items={Object.entries(sections).map(([key, section]) => ({
            id: key,
            title: section.title,
          }))}
          value={section}
          onChange={setSection}
          style={{ marginBottom: '3.5rem' }}
        />
        {content}
      </>
    )}
  />
)

export default SectionNavigator
