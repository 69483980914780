import React, { useState, useRef, useEffect } from 'react'
import MaskedInput, { conformToMask } from 'react-text-mask'
// @ts-ignore
import { useStyles } from 'floral'

import { useMediaContext } from 'App/MediaContext'
import mergeRefs from 'components/ui/mergeRefs'
import {
  normalizePhone,
  phoneMaskWithoutPrefix,
  internationalPhoneMaskWithoutPrefix,
} from 'utils/validations'

const makePlaceholder = (currentValue: string) => {
  let normalized = normalizePhone(currentValue)
  return normalized.startsWith('7')
    ? conformToMask(currentValue, phoneMaskWithoutPrefix, { placeholderChar: '0' })
        .conformedValue
    : ''
}

interface PhoneInputProps {
  value: string
  onChange: (value: string) => void
  autoFocus?: boolean
  onEnter?: () => void
  className?: string
}

const phoneInputStyles = () => {
  let fontSize = '14px'
  let lineHeight = '20px'
  let left = 26

  let root = {
    borderRadius: '6px',
    background: '#F4F7F9',
    position: 'relative',
    width: '100%',
    height: '48px',
    transition: 'box-shadow .2s',
  }
  let plus = {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    left: 15,
    fontSize,
    lineHeight,
    fontWeight: 500,
    pointerEvents: 'none',
  }
  let placeholder = {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    left,
    fontVariant: 'tabular-nums',
    fontSize,
    lineHeight,
    fontWeight: 500,
    opacity: 0.1,
    pointerEvents: 'none',
  }
  let input = {
    position: 'absolute',
    top: 0,
    left: 0,
    fontSize,
    fontVariant: 'tabular-nums',
    fontWeight: 500,
    lineHeight,
    background: 'transparent',
    border: 'none',
    padding: 0,
    paddingLeft: left,
    margin: 0,
    width: '100%',
    height: '100%',
  }
  return { root, plus, placeholder, input }
}

const PhoneInput = (props: PhoneInputProps) => {
  let { isMobile } = useMediaContext()
  let [isFocused, setIsFocused] = useState(false)
  let inputRef = useRef<HTMLInputElement>(null)
  let { value, onChange, autoFocus, onEnter, className } = props
  // render "+" separately, so it cannot be erased
  let valueWithoutPlus = value.replace(/^\+/, '')
  let styles = useStyles(phoneInputStyles, [props, isMobile, isFocused])
  useEffect(() => {
    // @ts-ignore
    if (autoFocus) inputRef.current?.focus()
  }, [])
  return (
    <div style={styles.root} className={className}>
      <div style={styles.plus}>+</div>
      <div style={styles.placeholder}>{makePlaceholder(valueWithoutPlus)}</div>
      <MaskedInput
        // @ts-ignore
        inputmode="numeric"
        pattern="[0-9]*"
        mask={internationalPhoneMaskWithoutPrefix}
        guide={false}
        style={styles.input}
        value={valueWithoutPlus}
        onChange={(e) => onChange('+' + e.target.value)}
        // @ts-ignore
        render={(ref, props) => <input {...props} ref={mergeRefs(ref, inputRef)} />}
        onKeyDown={(e) => {
          if (onEnter && e.key === 'Enter') onEnter()
        }}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      />
    </div>
  )
}

export default PhoneInput
