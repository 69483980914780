import { rest } from 'msw'

import { json } from './helpers'

const offers: any = [
  {
    id: 1,
    title: 'Рабочий',
    description: `Для работы вахтовым методо в Камчатский край требуются дорожный рабочие на строительство/реконструкцию.
Условия :
-Вахта 60/30
-6 дневная рабочая неделя по 11 часов
-Почасовая оплата
-Проживание предоставляется
- Спецодежда выдается`,
    company: { id: 45678, name: 'Супер компания' },
    amount: '15000',
    unit: 'доставка',
    rate: '110',
    quantity: 10,
    work_areas: ['Курьер на велосипеде'],
    city: 'Москва',
    metro: 'Белорусская',
    address: 'БЦ Белая Площадь',
    actions: ['respond'],
    responses: [],
    published_status: 'published',
  },
  {
    id: 2,
    title: 'Курьер',
    description: `Обязанности:
• Доставка готовой продукции ;
• Выполнение поручений руководства;
• Развоз персонала в ночное время.

Требования:
• Наличие авто;
• Грамотная речь;
• Желание работать.
`,
    company: { id: 45679, name: 'Супер компания 2' },
    amount: '15000',
    unit: 'час',
    rate: '110',
    quantity: 10,
    work_areas: ['Курьер на велосипеде', 'Пеший курьер'],
    city: 'Москва',
    address: 'БЦ Белая Площадь',
    actions: [],
    response: {
      contractor: { id: 4578, name: 'Петров Пётр Петрович' },
      offer: { id: 2 },
      status: 'sent',
    },
    responses: [
      {
        contractor: { id: 4578, name: 'Петров Пётр Петрович', kind: 'self_employed' },
        offer: { id: 2 },
        status: 'sent',
      },
    ],
    published_status: 'unpublished',
  },
]

const offers_for_date = [
  {
    id: 1,
    offer: offers[0],
    date: '2021-10-27',
    actions: ['respond'],
  },
]

const contractor_responses = [
  // обычный отклик
  {
    id: 1,
    contractor: {
      id: 1337,
      name: 'Алексеев Алексей',
      mobile_phone: '+79811852760',
      kind: 'self_employed',
    },
    status: 'sent',
    created: '2021-05-29',
    offer: offers[0],
  },
  // отклик на слот
  {
    id: 2,
    contractor: {
      id: 1337,
      name: 'Алексеев Алексей',
      mobile_phone: '+79811852760',
      kind: 'individual_entrepreneur',
    },
    status: 'sent',
    created: '2021-05-29',
    offer: offers[0],
    offer_date: '2021-10-27',
  },
]

const responses = [
  {
    id: 1,
    contractor: {
      id: 1337,
      name: 'Алексеев Алексей',
      mobile_phone: '+79811852760',
      kind: 'individual_entrepreneur',
    },
    status: 'sent',
    created: '2021-05-29',
    offer: offers[0],
    actions: ['invite', 'decline'],
  },
  {
    id: 3,
    contractor: {
      id: 1338,
      name: 'Константинопольская Констанция Константиновна',
      mobile_phone: '+79811852760',
      kind: 'individual_entrepreneur',
    },
    status: 'invited',
    created: '2021-05-28',
    offer: offers[0],
    actions: [],
  },
  {
    id: 4,
    contractor: {
      id: 4578,
      name: 'Петров Пётр Петрович',
      mobile_phone: '+79811852760',
      kind: 'individual_entrepreneur',
    },
    status: 'declined',
    created: '2021-05-27',
    offer_date: '2021-11-10',
    offer: offers[1],
    actions: [],
  },
  {
    id: 5,
    contractor: {
      id: 1337,
      name: 'Алексеев Алексей',
      mobile_phone: '+79811852760',
      kind: 'gph',
    },
    slot: '2021-10-27',
    status: 'sent',
    date: '2021-05-29',
    offer: offers[0],
    actions: ['assign', 'decline'],
  },
  {
    id: 6,
    contractor: {
      id: 1337,
      name: 'Алексеев Алексей',
      mobile_phone: '+79811852760',
      kind: 'gph',
    },
    slot: '2021-10-27',
    status: 'assigned',
    date: '2021-05-29',
    offer: offers[0],
    actions: [],
  },
]

const workplaces = [
  {
    id: '1',
    title: 'Кухня 1',
    offers: [
      {
        id: '1',
        title: 'Доставка',
        slots: [
          { date: '2021-10-25', total_count: 10, assigned_count: 5, responses_count: 3 },
          { date: '2021-10-26', total_count: 10, assigned_count: 4, responses_count: 3 },
          { date: '2021-10-27', total_count: 10, assigned_count: 3, responses_count: 3 },
          { date: '2021-10-28', total_count: 10, assigned_count: 2, responses_count: 3 },
          { date: '2021-10-29', total_count: 10, assigned_count: 1, responses_count: 2 },
          { date: '2021-10-30', total_count: 10, assigned_count: 0, responses_count: 1 },
          { date: '2021-10-31', total_count: 10, assigned_count: 8, responses_count: 0 },
        ],
      },
    ],
  },
]

export default [
  rest.get('/api/contractor/offers', json(offers)),
  rest.get('/api/contractor/offers/recommendations', json(offers_for_date)),
  rest.get('/api/contractor/offers/responses', json(contractor_responses)),
  rest.get('/api/contractor/offers/responses/:id', json(contractor_responses[0])),
  rest.get('/api/contractor/offers/:id', json(offers[0])),
  rest.put(
    '/api/contractor/offers/:id/respond',
    json({
      actions: [],
      response: responses[0],
    })
  ),

  rest.get('/api/company/offers', json(offers)),
  rest.get('/api/company/offers/responses', json(responses)),
  rest.get('/api/company/offers/responses/:id', json(responses[0])),
  rest.put('/api/company/offers/responses/:id/decline', json({ status: 'decline' })),

  // rest.get('/api/company/offers/workplaces', json([])),
  rest.get('/api/company/offers/workplaces', json(workplaces)),

  rest.get('/api/company/offers/:id', json(offers[0])),
]
