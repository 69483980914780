import React from 'react'

import { ReactComponent as CheckIcon } from '@material-design-icons/svg/round/check.svg'

import { Flex } from 'components/ui'

import { MenuItem } from '../../ui'

interface FilterProps<T> {
  initialFocusRef?: any
  value?: T
  onChange: (value: T) => void
  onClose: () => void
}

const CheckboxFilter = ({ value }: FilterProps<boolean>) => {
  const values: any = [
    { value: true, label: 'Да' },
    { value: false, label: 'Нет' },
  ]

  return (
    <>
      {values.map((option: any) => (
        <MenuItem
          value={option.value}
          style={{ fontWeight: option.value === value ? 600 : 400 }}
        >
          <Flex gap=".5rem">
            {option.value === value ? <CheckIcon /> : <div style={{ width: 24 }} />}
            <div>{option.label}</div>
          </Flex>
        </MenuItem>
      ))}
    </>
  )
}

export default CheckboxFilter
