import React from 'react'
//@ts-ignore
import { useStyles } from 'floral'
import cx from 'clsx'

import s from './styles.module.css'

interface RevealProps extends React.HTMLProps<HTMLDivElement> {
  animation?: 'slideDown' | 'fadeIn' | 'scale' | 'none'
}

const Reveal = (props: RevealProps) => {
  const styles = useStyles(null, [props])
  return (
    <div
      {...props}
      className={cx(props.animation !== 'none' && s[props.animation!], props.className)}
      style={styles.root}
    />
  )
}

Reveal.defaultProps = {
  animation: 'slideDown',
}

export default Reveal
