import React from 'react'
import { observer } from 'mobx-react-lite'

import { ReactComponent as AddIcon } from '@material-design-icons/svg/round/add.svg'

import { Flex, Col } from 'components/ui'

import FiltersStore from './FiltersStore'
import { Menu, MenuItem, MenuGroup } from './ui'
import { LinkButton, FilterView } from './components'

export type FilterMenuGroups = {
  header?: string
  aditional?: boolean
  filters: string[]
}[]

interface FiltersPanelProps {
  store: FiltersStore
  menuGroups?: FilterMenuGroups
  buttons?: React.ReactNode
}

const FiltersPanel = observer(({ store, menuGroups, buttons }: FiltersPanelProps) => {
  let { configs, currentState } = store

  let filterButtons
  if (store.currentState.length > 0) {
    filterButtons = (
      <Flex gap="1rem" wrap={true} style={{ marginTop: '-.5rem' }}>
        {currentState.map((filter) => {
          return (
            filter.name in configs && (
              <FilterView
                style={{ marginTop: '.5rem' }}
                ref={(ref) => store.onRenderFilter(filter.name, ref)}
                key={filter.name}
                // @ts-ignore
                config={configs[filter.name]}
                value={filter.value}
                // @ts-ignore
                onChange={(value) => store.setFilter(filter.name, value)}
                onRemove={() => store.removeFilter(filter.name)}
              />
            )
          )
        })}
      </Flex>
    )
  }

  let list = () => {
    let groups = menuGroups || [{ filters: Object.keys(configs) }]
    return groups.map(({ header, filters }) => (
      <MenuGroup header={header}>
        {filters.map((name) => (
          <MenuItem key={name} value={name} isDisabled={store.hasFilter(name)}>
            {configs[name].title}
          </MenuItem>
        ))}
      </MenuGroup>
    ))
  }

  let addButton = (
    <Menu
      menu={list}
      onSelect={(name: any) => store.addFilter(name)}
      placement={{ offset: 5 }}
      maxHeight={350}
    >
      {(ref: any, { open }: any) => (
        <LinkButton onTap={open} ref={ref}>
          <Flex gap=".15rem" align="center">
            <AddIcon />
            <div>Добавить фильтр</div>
          </Flex>
        </LinkButton>
      )}
    </Menu>
  )

  return (
    <Col gap="var(--gap-s)">
      {filterButtons}
      <Flex gap="1rem">
        {addButton}
        {buttons}
      </Flex>
    </Col>
  )
})

export default FiltersPanel
