import React from 'react'

import { NumberRangeFilterValue, NotFilledFilterValue } from 'entities'

import { DropdownButton } from '../DropdownButton'
import { Flex, Col, Checkbox } from 'components/ui'
import NumberInput from 'components/NumberInput'

import { NOT_FILLED_VALUE, NOT_FILLED_TEXT, isNotFilledValue } from '../../constants'

interface FilterProps<T> {
  initialFocusRef?: any
  value?: T
  withNotFilled?: boolean
  onChange: (value: T) => void
  onClose: () => void
}

interface NumberFilterProps
  extends FilterProps<NumberRangeFilterValue | NotFilledFilterValue> {}

const NumberRangeFilter = ({
  value,
  withNotFilled,
  onChange,
  onClose,
}: NumberFilterProps) => {
  const isNotFilledValueSelected = isNotFilledValue(value)

  return (
    <Col gap="var(--gap-s)">
      <Flex gap=".5rem" align="center">
        <div style={{ width: 25 }}>От</div>
        <NumberInput
          value={isNotFilledValueSelected ? '' : value?.from}
          onChange={(from) =>
            onChange({
              from: String(from),
              to: isNotFilledValueSelected ? '' : value?.to,
            })
          }
          autofocus={true}
          isClearable={true}
          allowDecimal={true}
        />
      </Flex>
      <Flex gap=".5rem" align="center">
        <div style={{ width: 25 }}>До</div>
        <NumberInput
          value={isNotFilledValueSelected ? '' : value?.to}
          onChange={(to) =>
            onChange({
              from: isNotFilledValueSelected ? '' : value?.from,
              to: String(to),
            })
          }
          isClearable={true}
          allowDecimal={true}
        />
      </Flex>
      {withNotFilled && (
        <Flex align="center" justify="start" gap="12px">
          <Checkbox
            styles={{ label: { color: 'grey', fontWeight: 'normal' } }}
            // @ts-ignore
            onChange={(value) =>
              onChange(value ? NOT_FILLED_VALUE : { from: '', to: '' })
            }
            value={isNotFilledValueSelected}
            label={NOT_FILLED_TEXT}
          />
        </Flex>
      )}
      <DropdownButton onTap={onClose}>Готово</DropdownButton>
    </Col>
  )
}

export default NumberRangeFilter
