import { useLocation } from 'react-router'
import type { History } from 'history'

const getHashState = <T>(location: ReturnType<typeof useLocation>, defaultState: T) =>
  location.hash ? ((location.hash.replace(/^#/, '') as unknown) as T) : defaultState

const useHashState = <T>(history: History, defaultState: T): [T, (state: T) => void] => {
  let location = useLocation()
  let hashState = getHashState(location, defaultState)
  let setHashState = (nextState: T) =>
    history.push(`${location.pathname}#${nextState}`, location.state)
  return [hashState, setHashState]
}

export { getHashState }
export default useHashState
