import React, { useMemo } from 'react'

import { ListCell, List, ListHeader, ListItem, Flex, Link } from 'components/ui'

import { ActionStateView, Col } from 'components/ui'

import { observer } from 'mobx-react-lite'

import { fetchApi } from 'api'
import { fromPromise } from 'mobx-utils'
import { Tooltip } from '@chakra-ui/react'

interface ContractorRisksProps {
  id: number
}

const listEntries = [
  {
    id: 'mass_registration',
    label: 'Статус и дата договора',
    enabled_label: 'Менее 7 дней между датами',
    disabled_label: 'Более 7 дней между датами',
    active_comment:
      'Между датой договора и датой регистрации в качестве СМЗ менее 7 дней',
    inactive_comment:
      'Между датой договора и датой регистрации в качестве СМЗ более 7 дней',
  },
  {
    id: 'only_source_of_income',
    label: 'Единственный источник дохода',
    enabled_label: 'Да',
    disabled_label: 'Нет',
    active_comment: 'Нет других источников дохода',
    inactive_comment: 'У исполнителя есть другие источники дохода',
  },
  {
    id: 'two_payments_per_month',
    label: 'Периодичность выплат',
    enabled_label: 'Есть платежи два раза в месяц',
    disabled_label: 'Нет платежей два раза в месяц',
    active_comment:
      'За последние 3 месяца есть выплаты два раза в месяц, как это принято в трудовых правоотношениях',
    inactive_comment:
      'За последние 3 месяца нет выплат 2 раза в месяц, как это принято в трудовых правоотношениях',
  },
  {
    id: 'unique_payments',
    label: 'Одинаковые платежи',
    enabled_label: 'Есть',
    disabled_label: 'Нет',
    active_comment:
      'Есть платежи на одну и ту же сумму на протяжении 3-х последних месяцев',
    inactive_comment:
      'Нет платежей на одну и ту же сумму на протяжении 3-х последних месяцев',
  },
  {
    id: 'working_duration',
    label: 'Продолжительность сотрудничества',
    enabled_label: 'Более 3-х месяцев',
    disabled_label: 'Менее 3-х месяцев',
    active_comment: 'Сотрудничество с исполнителем более 3-х месяцев ',
    inactive_comment: 'Продолжительность сотрудничества с исполнителем менее 3-х месяцев',
  },
]

const colorStyleMap: Record<
  string,
  {
    foregroundColor: string
    backgroundColor: string
  }
> = {
  '0': {
    foregroundColor: 'rgba(33, 160, 56, 1)',
    backgroundColor: 'rgba(33, 160, 56, 0.1)',
  },
  '1': {
    foregroundColor: 'rgba(249, 27, 27, 1)',
    backgroundColor: 'rgba(249, 27, 27, 0.1)',
  },
  null: {
    foregroundColor: '#2c3344',
    backgroundColor: '#2c33441a',
  },
}

const Circle = ({
  value,
  style,
}: {
  value: '1' | '0' | 'null'
  style?: React.CSSProperties
}) => {
  const { foregroundColor, backgroundColor } = colorStyleMap[value]
  return (
    <div
      style={{
        ...style,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 30,
        width: 30,
        borderRadius: '50%',
        background: backgroundColor,
      }}
    >
      <div
        style={{
          height: 10,
          width: 10,
          borderRadius: '50%',
          background: foregroundColor,
        }}
      />
    </div>
  )
}

const fetchData = async (id: number) => {
  try {
    return await fetchApi({
      url: `contractor_risk_profiles/${id}`,
    })
  } catch (e: any) {
    if (e.response.status === 404) {
      return null
    } else {
      throw e
    }
  }
}

const ContractorAnalyticsRisks = observer((props: ContractorRisksProps) => {
  const { id } = props

  const state = useMemo(() => fromPromise(fetchData(id)), [])

  const cols = {
    label: {
      width: () => 300,
    },
    comment: {
      width: (fullWidth: number) => Math.max(fullWidth - 300, 500),
    },
  }

  return (
    <Col gap="var(--gap-m)" style={{ marginTop: '1rem' }}>
      <div style={{ display: 'inline-block' }}>
        <span style={{ marginRight: '2pt' }}>
          Аналитика рисков — инструмент для мониторинга уровня риска в работе с
          самозанятыми. Чем меньше исполнителей компании попадает под критерий, тем меньше
          процент риска.
        </span>
        <Link href="https://support.konsol.pro/risk-analytics">
          Подробнее про аналитику рисков
        </Link>
      </div>
      <Col style={{ marginLeft: '-0.4rem' }} align="start">
        <ActionStateView state={state}>
          {(data) => {
            if (!data) {
              return (
                <span
                  style={{
                    fontSize: 18,
                    color: 'var(--color-grey-4)',
                  }}
                >
                  Пересчитываем, зайдите позже
                </span>
              )
            }

            let items: any[] = listEntries.map(({ id, ...other }) => ({
              id,
              value: String(data[id]) as '1' | '0' | 'null',
              ...other,
            }))

            items = items.map((item) => {
              let comment = 'Недостаточно данных'
              if (item.value === '1') {
                comment = item.active_comment
              } else if (item.value === '0') {
                comment = item.inactive_comment
              }
              return {
                ...item,
                comment,
              }
            })

            return (
              <List cols={cols}>
                <ListHeader>
                  <ListCell col="label">Критерий</ListCell>
                  <ListCell col="comment">Комментарий</ListCell>
                </ListHeader>
                {items.map((item, idx) => (
                  <ListItem key={idx}>
                    <ListCell col="label">
                      <Tooltip
                        placement="bottom"
                        label={`Красный - ${item.enabled_label}, Зеленый - ${item.disabled_label}, Серый - Нет данных`}
                      >
                        <Flex align="center">
                          <Circle
                            value={item.value}
                            style={{
                              marginRight: '15px',
                            }}
                          />
                          {item.label}
                        </Flex>
                      </Tooltip>
                    </ListCell>
                    <ListCell col="comment">{item.comment}</ListCell>
                  </ListItem>
                ))}
              </List>
            )
          }}
        </ActionStateView>
      </Col>
    </Col>
  )
})

export default ContractorAnalyticsRisks
