import * as React from 'react'
import { Flex } from '@chakra-ui/react'
import { Amount } from 'components'
import { Card, H3 } from 'components/ui'
import { TaskEntity } from 'entities'
import { observer } from 'mobx-react-lite'
import { Entity } from 'mobx/MobxApi'
import { printPeriod } from 'utils/datetime'
import s from './ActCard.module.css'
import { NavLink } from 'react-router-dom'

type ActCardProps = {
  task: Entity<TaskEntity>
  className?: string
  style?: Object
}

export const ActCard = observer(({ task, className, style }: ActCardProps) => {
  return (
    <NavLink to={`/dashboard/acts/${task.id}`}>
      <Card kind="secondary" className={className} style={style}>
        <Flex justify="space-between">
          <H3 className={s.amount}>
            <Amount
              value={
                task.data.act.payment_amount_after_savings ?? task.data.act.payment_amount
              }
            ></Amount>
          </H3>
          <p className={s.company}>{task.data.company.data.name}</p>
        </Flex>
        <Flex justify="space-between" className={s.smallText}>
          <p className={s.smallText}>Акт №{task.data.document_number?.number}</p>
          <p>{printPeriod(task.data.since, task.data.upto, false)}</p>
        </Flex>
      </Card>
    </NavLink>
  )
})
