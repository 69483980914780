import React from 'react'
import clsx from 'clsx'

import { Appear, Flex } from 'ui'

import css from './styles.module.css'

export type TOption = {
  id?: string
  title?: string
  tab?: React.ReactNode
  list: Array<string | React.ReactNode>
  controls: React.ReactNode
  className?: string
}

const TabsContent = ({ id = '', title, list, controls, className }: TOption) => {
  return (
    <Flex
      direction="col"
      justify="space-between"
      className={clsx(css.container, className)}
    >
      {title && (
        <Appear delay={100} animation={Appear.presets.fade}>
          <div className={clsx(css.title, css[`title${id}`])}>{title}</div>
        </Appear>
      )}
      <ul>
        {list.map((item, index) => {
          return (
            <Appear delay={(index + 4) * 50} animation={Appear.presets.slideUp}>
              <li>{item}</li>
            </Appear>
          )
        })}
      </ul>
      <div>{controls}</div>
    </Flex>
  )
}

export default TabsContent
