import React, { useState, useMemo } from 'react'
import { makeAutoObservable } from 'mobx'
import { observer } from 'mobx-react-lite'
// @ts-ignore
import { FormStore } from 'shadowform'
import { Heading } from '@chakra-ui/react'
import { chain } from 'lodash'

import { Entity, useMobxApi, initialActionState, MobxApi } from 'mobx/mobx'
import { useMediaContext } from 'App/MediaContext'
import { useUserStore, UserStore, useSignDialogStore } from 'stores/context'
import DocumentStore from 'stores/DocumentStore'
import DialogStore, { useDialogStore } from 'stores/DialogStore'
import { CompanyEntity, DocumentEntity, ContractorEntity, DocumentEvent } from 'entities'
import dayjs from 'utils/dayjs'

import {
  Flex,
  List,
  ListHeader,
  ListItem,
  ListCell,
  useStateToast,
  Button,
  IconButton,
  FormRow,
  Field,
  Input,
  Modal,
  Link,
  Col,
  H2,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from 'components/ui'
import { Chips } from 'components'
import { AbilityButton } from 'components/abilityButtons'
import EventList from 'components/EventList'
import { DocumentCreateForm } from 'components/documents/DocumentCreateForm'
import { DocumentStatusBadge, documentEvents } from 'components/documents/statuses'

import toFormData from 'utils/toFormData'
import { parseDate, printDate } from 'utils/datetime'
import { nbsp } from 'utils/typo'

import { ReactComponent as UploadIcon } from 'assets/upload-primary.svg'
import { ReactComponent as MoreIcon } from '@material-design-icons/svg/round/more_vert.svg'
import { ReactComponent as DocumentIcon } from './document.svg'

const DocumentCreateModal = observer(
  (props: React.ComponentProps<typeof DocumentCreateForm>) => (
    <Modal isOpen={true} onClose={props.onClose} size={600}>
      <Col gap="var(--gap-m)">
        <H2>Создание {props.type === 'contracts' ? 'договора' : 'документа'}</H2>
        <DocumentCreateForm {...props} />
      </Col>
    </Modal>
  )
)

const isContract = (doc: Entity<DocumentEntity>) => doc.data.kind === 'contract'

interface ContractsAndDocumentsStoreProps {
  currentUser: UserStore
  mobxApi: MobxApi
  toast: ReturnType<typeof useStateToast>
  viewer: 'member' | 'contractor'
  company?: Entity<CompanyEntity>
  contractor?: Entity<ContractorEntity>
}

class ContractsAndDocumentsStore {
  constructor(props: ContractsAndDocumentsStoreProps) {
    Object.assign(this, props)
    makeAutoObservable(this)
  }

  currentUser!: UserStore
  mobxApi!: MobxApi
  toast!: ReturnType<typeof useStateToast>
  viewer!: 'member' | 'contractor'
  company?: Entity<CompanyEntity>
  contractor?: Entity<ContractorEntity>

  get contracts() {
    return this.viewer === 'contractor'
      ? this.company!.data.contracts
      : this.contractor!.data.contracts
  }

  get documents() {
    return this.viewer === 'contractor'
      ? this.company!.data.documents
      : this.contractor!.data.documents
  }

  get createData() {
    return {
      contractor_id: String(this.contractor!.id),
      legal_entity_id: String(this.currentUser.company!.data.legal_entities.items[0]?.id),
    }
  }

  get activeContracts() {
    return this.contracts.items.filter((item) => !item.data.is_archived)
  }

  get archivedContracts() {
    return this.contracts.items.filter((item) => item.data.is_archived)
  }

  get activeDocuments() {
    return this.documents.items.filter((item) => !item.data.is_archived)
  }

  get archivedDocuments() {
    return this.documents.items.filter((item) => item.data.is_archived)
  }

  createDocument(type: 'contracts' | 'documents', values: any) {
    let payload: any = { kind: type, ...this.createData, ...values }
    if (values.file) payload = toFormData(payload)

    let url = 'documents' + (values.template_id ? '/from_template' : '')
    let state = this.mobxApi.create<DocumentEntity>({
      type: 'documents',
      payload,
      url,
    })
    state.then(
      (doc) => {
        if (type === 'contracts') this.contracts.prepend(doc)
        else this.documents.prepend(doc)
      },
      (error: any) =>
        this.toast.error({ title: 'Не удалось загрузить', description: error.message })
    )
    return state
  }

  createDialog = new DialogStore({ component: DocumentCreateModal })

  openCreateDialog(type: 'contracts' | 'documents') {
    let disabledTemplateIds =
      type === 'contracts'
        ? chain(this.activeContracts)
            .map((item) => item.data.template?.id)
            .compact()
            .uniq()
            .value()
        : []
    let templates =
      this.currentUser.company!.data[
        type === 'contracts' ? 'contract_templates' : 'document_templates'
      ]
    this.createDialog.open({
      type,
      templates,
      disabledTemplateIds,
      contracts: this.activeContracts.filter((c) =>
        ['signed_by_all', 'signed_by_legal_entity'].includes(c.data.status)
      ),
      onSubmit: (values) => this.createDocument(type, values),
    })
  }
}

const dateFormat = 'DD.MM.YYYY'
const dateMask = [/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]

const makeEditForm = (document: Entity<DocumentEntity>) => {
  let fields: any = {
    title: {
      isRequired: true,
      requiredError: 'Не заполнено название',
    },
  }
  let { title, comment } = document.data
  let initialValues: any = { title }
  if (isContract(document)) {
    let { date } = document.data
    fields.date = {
      isRequired: true,
      requiredError: 'Не указана дата',
      normalize: (value: string) => dayjs(value, dateFormat, true),
      validations: {
        isValid: {
          error: 'Неверная дата',
          validate: (date: dayjs.Dayjs) => date.isValid(),
        },
      },
    }
    initialValues.date = date ? parseDate(date).format(dateFormat) : ''
  } else {
    fields.comment = {}
    initialValues.comment = comment
  }
  return new FormStore({ fields, initialValues })
}

interface DocumentEditModalProps {
  document: Entity<DocumentEntity>
  onClose: () => void
  onSubmit: (values: Object) => void
}

const DocumentEditModal = observer((props: DocumentEditModalProps) => {
  let { document, onClose, onSubmit } = props
  let form = useMemo(() => makeEditForm(document), [])
  return (
    <Modal isOpen={true} onClose={onClose} closeOnOverlayClick={false} size={550}>
      <Col gap="var(--gap-m)">
        <Heading>Изменение {isContract(document) ? 'договора' : 'документа'}</Heading>
        <Field field={form.fields.title}>
          {({ inputProps, error }) => (
            <FormRow label="Название" size="l" error={error}>
              <Input {...inputProps} placeholder="Введите название" size="l" />
            </FormRow>
          )}
        </Field>
        {form.fields.date && (
          <Field field={form.fields.date}>
            {({ inputProps, error }) => (
              <FormRow label="Дата договора" size="l" error={error}>
                <Input
                  {...inputProps}
                  placeholder="ДД.ММ.ГГГГ"
                  mask={dateMask}
                  size="l"
                />
              </FormRow>
            )}
          </Field>
        )}
        <Flex gap="2rem" align="center" justify="space-between">
          <Button
            onTap={() => {
              onSubmit(form.normalizedValues)
              onClose()
            }}
            isDisabled={!form.isValid}
          >
            Сохранить
          </Button>
          <Link onTap={onClose}>Отмена</Link>
        </Flex>
      </Col>
    </Modal>
  )
})

const styles = {
  emptyMessage: {
    color: 'var(--color-secondary)',
    fontSize: '1.25rem',
  },
}

const cols = {
  name: {},
  date: { width: 180 },
  status: { width: 300 },
  actions: { width: 24 },
}

interface DocumentEventListDialogProps {
  document: Entity<DocumentEntity>
  onClose: () => void
}

const DocumentEventListDialog = ({ onClose, document }: DocumentEventListDialogProps) => {
  let eventListItems = document.data.events.map((event: DocumentEvent) => ({
    ...event,
    kind: documentEvents[event.kind] || event.kind,
  }))
  return (
    <Modal isOpen={true} onClose={onClose} size={800}>
      <Col gap="var(--gap-m)">
        <H2>История событий</H2>
        <EventList items={eventListItems} style={{ width: '100%' }} />
      </Col>
    </Modal>
  )
}

interface DocumentListItemProps {
  document: Entity<DocumentEntity>
  onDelete?: () => void
}

const DocumentListItem = observer(({ document, onDelete }: DocumentListItemProps) => {
  let { isMobile } = useMediaContext()
  let currentUser = useUserStore()
  let toast = useStateToast()
  let signDialog = useSignDialogStore()
  let store = useMemo(
    () => new DocumentStore({ document, toast, currentUser, signDialog }),
    []
  )

  let [_actionState, setActionState] = useState(initialActionState)

  let archiveDocument = () => {
    let state = document.action({ action: 'archive' })
    state.then(
      (data) => document.setData(data),
      (err) => toast.error({ title: 'Ошибка', description: err.message })
    )
    setActionState(state)
  }

  let unarchiveDocument = () => {
    let state = document.action({ action: 'unarchive' })
    state.then(
      (data) => document.setData(data),
      (err) => toast.error({ title: 'Ошибка', description: err.message })
    )
    setActionState(state)
  }

  let deleteDocument = () => {
    let state = document.delete()
    state.then(onDelete, (err) =>
      toast.error({ title: 'Ошибка', description: err.message })
    )
    setActionState(state)
  }

  let eventListDialog = useDialogStore({ component: DocumentEventListDialog })

  let editDialog = useDialogStore({ component: DocumentEditModal })
  let openEditDialog = () =>
    editDialog.open({
      document,
      onSubmit: (values) => store.update(values),
    })

  let title = (
    <>
      <div>
        <Link href={document.data.file_url} target="_blank">
          {document.data.title}
          {document.data.date && (
            <span style={{ color: 'var(--color-secondary)', marginBottom: 1 }}>
              {' '}
              от{nbsp}
              {parseDate(document.data.date).format(dateFormat)}
            </span>
          )}
        </Link>
      </div>
      {currentUser.kind === 'company' && document.data.template && (
        <span style={{ color: 'var(--color-secondary)' }}>
          по шаблону "{document.data.template.name}"
        </span>
      )}
    </>
  )

  let status = <DocumentStatusBadge document={document} />

  let signButton
  if (document.data.actions.includes('sign')) {
    let signAbility =
      currentUser.kind === 'contractor' || currentUser.hasAbility(`${document.type}.sign`)
    signButton = (
      <AbilityButton
        ability={signAbility}
        design="normal"
        size="xs"
        onTap={() => store.openSignDialog()}
      >
        Подписать
      </AbilityButton>
    )
  }

  let menu = (
    <Menu placement="bottom-end">
      <MenuButton as={IconButton} variant="simple">
        <MoreIcon />
      </MenuButton>
      <MenuList>
        {document.data.actions.includes('archive') && (
          <MenuItem onClick={archiveDocument}>Перенести в архив</MenuItem>
        )}
        {document.data.actions.includes('unarchive') && (
          <MenuItem onClick={unarchiveDocument}>Вернуть из архива</MenuItem>
        )}
        {document.data.actions.includes('update') && (
          <MenuItem onClick={openEditDialog}>Редактировать</MenuItem>
        )}
        {document.data.actions.includes('delete') && (
          <MenuItem onClick={deleteDocument}>Удалить</MenuItem>
        )}
        <MenuItem onClick={() => eventListDialog.open({ document })}>
          История событий
        </MenuItem>
      </MenuList>
    </Menu>
  )

  let dialogs = (
    <>
      {eventListDialog.render()}
      {editDialog.render()}
    </>
  )

  if (isMobile) {
    return (
      <ListItem padding={false} hover={false}>
        <Col gap="1rem">
          <Flex gap="1rem">
            <Flex gap="1rem" align="center">
              <DocumentIcon
                width={40}
                height={48}
                style={{
                  flexShrink: 0,
                  filter: 'drop-shadow(0px 5px 12px rgba(0,0,0,12%))',
                }}
              />
              <Col>
                {title}
                {status}
              </Col>
            </Flex>
            <div>{menu}</div>
          </Flex>
          {signButton}
          {dialogs}
        </Col>
      </ListItem>
    )
  }

  return (
    <ListItem padding={false} hover={false}>
      <ListCell col="title">{title}</ListCell>
      <ListCell col="date">
        {printDate(document.data.created_date, { mode: 'full' })}
      </ListCell>
      <ListCell col="status">
        <Flex gap=".5rem">
          {status}
          {signButton}
        </Flex>
      </ListCell>
      <ListCell col="actions">{menu}</ListCell>
      {dialogs}
    </ListItem>
  )
})

interface DocumentListProps {
  documents: Entity<DocumentEntity>[]
  onDelete: (document: Entity<DocumentEntity>) => void
}

const DocumentList = observer(({ documents, onDelete }: DocumentListProps) => {
  let { isMobile } = useMediaContext()
  let currentUser = useUserStore()
  return (
    <List cols={cols}>
      {!isMobile && (
        <ListHeader padding={false}>
          <ListCell col="title">Название</ListCell>
          <ListCell col="date">Дата создания</ListCell>
          <ListCell col="status">Статус</ListCell>
          {currentUser.kind === 'company' && <ListCell col="actions" />}
        </ListHeader>
      )}
      {documents.map((item) => (
        <DocumentListItem key={item.id} document={item} onDelete={() => onDelete(item)} />
      ))}
    </List>
  )
})

const ContractsSection = observer(({ store }: { store: ContractsAndDocumentsStore }) => {
  let { isMobile } = useMediaContext()

  let [section, setSection] = useState('active')
  let chips = (
    <Chips
      value={section}
      onChange={setSection}
      items={[
        { id: 'active', title: 'Активные' },
        { id: 'archive', title: 'Архив' },
      ]}
    />
  )

  let items = section === 'active' ? store.activeContracts : store.archivedContracts
  let content
  if (items.length > 0) {
    content = (
      <DocumentList
        documents={items}
        onDelete={(document) => store.contracts.remove(document)}
      />
    )
  } else if (section === 'active') {
    content = (
      <div style={styles.emptyMessage}>
        {store.viewer === 'member'
          ? 'Создайте или загрузите договор с исполнителем'
          : 'Заказчик ещё не загрузил договор'}
      </div>
    )
  } else {
    content = <div style={styles.emptyMessage}>Нет договоров в архиве</div>
  }

  let createButton
  if (store.viewer === 'member') {
    let canCreate =
      store.currentUser.hasFeature('additional_contracts') ||
      store.activeContracts.length === 0
    createButton = (
      <AbilityButton
        design="normal"
        onTap={() => store.openCreateDialog('contracts')}
        ability={store.currentUser.hasAbility('contracts.create')}
        icon={<UploadIcon />}
        isDisabled={!canCreate}
        tooltip={!canCreate && 'У исполнителя уже есть активный договор'}
      >
        {isMobile ? 'Создать' : 'Создать договор'}
      </AbilityButton>
    )
  }

  return (
    <Col gap="var(--gap-m)">
      <Flex justify="space-between" align="center" style={{ height: '3rem' }}>
        <div style={{ fontWeight: 600, fontSize: '1.5rem' }}>Оферта</div>
        {createButton}
      </Flex>
      {chips}
      {content}
      {store.createDialog.render()}
    </Col>
  )
})

const DocumentsSection = observer(({ store }: { store: ContractsAndDocumentsStore }) => {
  let { isMobile } = useMediaContext()

  let [section, setSection] = useState('active')
  let chips = (
    <Chips
      value={section}
      onChange={setSection}
      items={[
        { id: 'active', title: 'Активные' },
        { id: 'archive', title: 'Архив' },
      ]}
    />
  )

  let items = section === 'active' ? store.activeDocuments : store.archivedDocuments
  let content
  if (items.length > 0) {
    content = (
      <DocumentList
        documents={items}
        onDelete={(document) => store.documents.remove(document)}
      />
    )
  } else {
    content = (
      <div style={styles.emptyMessage}>
        {section === 'active'
          ? 'Загрузите любой документ на подпись исполнителю'
          : 'Нет документов в архиве'}
      </div>
    )
  }

  let createButton = store.currentUser.kind === 'company' && (
    <AbilityButton
      ability={store.currentUser.hasAbility('documents.create')}
      design="normal"
      onTap={() => store.openCreateDialog('documents')}
      icon={<UploadIcon />}
    >
      {isMobile ? 'Создать' : 'Создать документ'}
    </AbilityButton>
  )

  return (
    <Flex gap={isMobile ? '1rem' : '2rem'} direction="column">
      <Flex justify="space-between" align="center" style={{ height: '3rem' }}>
        <div style={{ fontWeight: 600, fontSize: '1.5rem' }}>Документы</div>
        {createButton}
      </Flex>
      {chips}
      {content}
    </Flex>
  )
})

interface ContractAndDocumentsProps {
  viewer: 'member' | 'contractor'
  company?: Entity<CompanyEntity>
  contractor?: Entity<ContractorEntity>
}

const ContractAndDocuments = (props: ContractAndDocumentsProps) => {
  let { isMobile } = useMediaContext()
  let toast = useStateToast()
  let currentUser = useUserStore()
  let mobxApi = useMobxApi()
  let store = useMemo(
    () =>
      new ContractsAndDocumentsStore({
        currentUser,
        mobxApi,
        toast,
        ...props,
      }),
    []
  )
  let showDocuments = !store.documents.isEmpty || props.viewer === 'member'
  return (
    <Flex direction="column" gap={isMobile ? '2rem' : '5rem'}>
      <ContractsSection store={store} />
      {showDocuments && <DocumentsSection store={store} />}
    </Flex>
  )
}

const ContractAndDocumentsForMember = observer(
  ({ contractor }: { contractor: Entity<ContractorEntity> }) => (
    <ContractAndDocuments viewer="member" contractor={contractor} />
  )
)

const ContractAndDocumentsForContractor = observer(
  ({ company }: { company: Entity<CompanyEntity> }) => (
    <ContractAndDocuments viewer="contractor" company={company} />
  )
)

export { ContractAndDocumentsForMember, ContractAndDocumentsForContractor }
