import React, { useState, useMemo, useEffect } from 'react'
import { makeAutoObservable } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useHistory, useLocation } from 'react-router-dom'
import { Heading } from '@chakra-ui/react'

import { initialActionState, Entity, Collection } from 'mobx/mobx'
import { TaxSavingsAccountEntity, TaxAccountOperationEntity } from 'entities'
import { useUserStore } from 'stores/context'
import { printIsoDate } from 'utils/datetime'
import { useMediaContext } from 'App/MediaContext'

import { nbsp } from 'utils/typo'
import { printDate } from 'utils/datetime'
import { Amount } from 'components'
import {
  Layout,
  LayoutHeading,
  ActionStateView,
  Modal,
  Flex,
  Button,
  useStateToast,
  List,
  ListHeader,
  ListCell,
  ListItem,
  ErrorPlaceholder,
  LoadingPlaceholder,
} from 'components/ui'
import SignInput from 'components/SignDialog/SignInput'

import { ReactComponent as CheckIcon } from '@material-design-icons/svg/round/check_circle.svg'

const styles: { [key: string]: React.CSSProperties } = {
  title: {
    fontSize: '1.5rem',
    fontWeight: 500,
  },
  caption: {
    color: 'var(--color-secondary)',
  },
  table: { width: 320, maxWidth: '100%' },
  label: { flexGrow: 1 },
  value: { width: 120, textAlign: 'right' },
}

interface EnableTaxAccountViewProps {
  account: Entity<TaxSavingsAccountEntity>
}

const EnableTaxAccountView = observer(({ account }: EnableTaxAccountViewProps) => {
  let history = useHistory()
  let { isMobile } = useMediaContext()
  let toast = useStateToast()

  let location = useLocation()
  let [modalIsOpen, setModalIsOpen] = useState(false)

  let [sendRequestState, setSendRequestState] = useState(initialActionState)
  let [checkRequestState, setCheckRequestState] = useState(initialActionState)

  let request = () => {
    let state = account.action({ action: 'send_request' })
    state.then(({ status, data }: any) => {
      if (status === 'success') {
        account.setData(data)
        toast.success({ title: 'Копилка подключена' })
      }
    })
    return state
  }
  let sendRequest = () => {
    setModalIsOpen(true)
    let state = request()
    state.then(({ status }: any) => {
      if (status === 'request_sent') {
        setCheckRequestState(initialActionState)
      }
    })
    setSendRequestState(state)
  }
  let checkRequest = () => setCheckRequestState(request())

  // если нажали на кнопку в баннере
  useEffect(() => {
    // @ts-ignore
    if (location.state?.showEnableModal) {
      sendRequest()
      history.replace(location.pathname)
    }
  }, [location, history])

  let modal
  if (modalIsOpen) {
    modal = (
      <Modal
        isOpen={true}
        size={isMobile ? '97%' : 550}
        onClose={() => setModalIsOpen(false)}
      >
        <Heading style={{ marginBottom: '2rem' }}>Подключение копилки</Heading>
        <ActionStateView state={sendRequestState}>
          <Flex direction="column" gap="2rem">
            <div>
              Мы запросили разрешение на оплату налогов от вашего имени. Подтвердите в
              приложении Мой Налог.
            </div>
            <Flex gap="2rem" align="center">
              <Button
                onTap={checkRequest}
                isLoading={checkRequestState.state === 'pending'}
              >
                Проверить
              </Button>
              {checkRequestState.state === 'fulfilled' &&
                checkRequestState.value.status === 'request_sent' && (
                  <div style={{ color: 'var(--color-red)' }}>
                    Разрешение не подтверждено
                  </div>
                )}
            </Flex>
          </Flex>
        </ActionStateView>
      </Modal>
    )
  }

  return (
    <Flex direction="column" gap="1.5rem">
      <div style={styles.title}>Подключите копилку для уплаты налогов</div>
      <div style={{ maxWidth: 700 }}>
        Restaff будет автоматически откладывать часть суммы с ваших доходов, чтобы у вас
        была нужная сумма для уплаты налогов. Когда придёт налог — вы сможете оплатить его
        из накопленной суммы.
      </div>
      <div>
        <Button onTap={sendRequest} isLoading={sendRequestState.state === 'pending'}>
          Подключить копилку
        </Button>
      </div>
      {modal}
    </Flex>
  )
})

interface TaxAccountOperationsListProps {
  operations: Collection<TaxAccountOperationEntity>
}

const TaxAccountOperationsList = observer(
  ({ operations }: TaxAccountOperationsListProps) => {
    let { isMobile } = useMediaContext()
    return (
      <Flex direction="column" gap="1.5rem">
        <div style={styles.title}>Операции</div>
        <List
          cols={{
            date: {
              width: isMobile ? undefined : 150,
              style: { color: 'var(--color-secondary)' },
            },
            purpose: {},
            amount: { width: isMobile ? undefined : 150, style: { textAlign: 'right' } },
          }}
        >
          {!isMobile && (
            <ListHeader>
              <ListCell col="date">Дата</ListCell>
              <ListCell col="purpose">Тип операции</ListCell>
              <ListCell col="amount">Сумма</ListCell>
            </ListHeader>
          )}
          {operations.items.map((item) =>
            isMobile ? (
              <ListItem wrap={true}>
                <ListCell row>
                  <ListCell col="date">{printIsoDate(item.data.date, false)}</ListCell>
                  <ListCell col="amount">
                    {item.data.kind === 'deposit' ? '+' : '-'}
                    <Amount value={item.data.amount} />
                  </ListCell>
                </ListCell>
                <ListCell row>{item.data.purpose}</ListCell>
              </ListItem>
            ) : (
              <ListItem>
                <ListCell col="date">{printIsoDate(item.data.date, false)}</ListCell>
                <ListCell col="purpose">{item.data.purpose}</ListCell>
                <ListCell col="amount">
                  {item.data.kind === 'deposit' ? '+' : '-'}
                  <Amount value={item.data.amount} />
                </ListCell>
              </ListItem>
            )
          )}
        </List>
      </Flex>
    )
  }
)

class TaxAccountViewStore {
  constructor(
    account: Entity<TaxSavingsAccountEntity>,
    toast: ReturnType<typeof useStateToast>
  ) {
    this.account = account
    this.toast = toast
    makeAutoObservable(this)
  }

  account: Entity<TaxSavingsAccountEntity>
  toast: ReturnType<typeof useStateToast>
  payDialogIsOpen = false
  kind: 'tax' | 'debt' = 'tax'
  codeForPayState: any = initialActionState
  payState = initialActionState

  sendCodeForPay() {
    this.codeForPayState = this.account.action({
      action: 'code_for_pay_taxes',
      payload: { kind: this.kind },
      options: { method: 'POST' },
    })
  }

  openDialog(kind: 'tax' | 'debt') {
    this.payDialogIsOpen = true
    this.kind = kind
    this.payState = initialActionState
    this.sendCodeForPay()
  }

  closeDialog() {
    this.payDialogIsOpen = false
  }

  payTaxes(payload: Object | undefined) {
    this.payState = this.account.action({
      action: 'pay_taxes',
      payload: { ...payload, sign_id: this.codeForPayState.value.sign_id },
    })
    this.payState.then((data) => {
      this.toast.success({
        title: this.kind === 'tax' ? 'Налог оплачен' : 'Задолженность оплачена',
      })
      this.account.setData(data)
      this.closeDialog()
    })
    return this.payState
  }
}

const TaxPayModal = observer(({ store }: { store: TaxAccountViewStore }) => {
  let { isMobile } = useMediaContext()

  let amount =
    store.kind === 'tax'
      ? store.account.data.tax_balance.tax?.amount
      : store.account.data.tax_balance.debt?.total_amount

  let content
  if (store.codeForPayState.state === 'pending') {
    content = <LoadingPlaceholder style={{ height: 150 }} />
  } else if (store.codeForPayState.state === 'rejected') {
    let code = store.codeForPayState.value.data?.code

    let errorMessage
    if (code === 'no_permission_for_pay') {
      errorMessage = (
        <div>
          Разрешите оплату налоговых начислений от вашего имени.
          <br />
          Подтвердите в приложении «Мой Налог» → Прочее → Партнёры → Подключенные
        </div>
      )
    } else if (code === 'taxpayer_unregistered') {
      errorMessage = (
        <div>
          Вы не являетесь налогоплательщиком «Налога на профессиональный доход»".
          <br />
          Встаньте на учёт в ФНС
        </div>
      )
    } else if (code === 'error') {
      errorMessage = (
        <div>
          Произошла ошибка получения данных из Налоговой.
          <br />
          Попробуйте позже
        </div>
      )
    }

    if (errorMessage) {
      content = (
        <Flex gap="2rem" direction="column">
          {errorMessage}
          <Flex gap="2rem" align="center">
            <Button onTap={() => store.sendCodeForPay()}>Проверить ещё раз</Button>
          </Flex>
        </Flex>
      )
    } else {
      content = (
        <ErrorPlaceholder style={{ height: 150 }}>
          {String(store.codeForPayState.value)}
        </ErrorPlaceholder>
      )
    }
  } else {
    content = (
      <Flex direction="column" gap="2rem">
        <Flex direction="column" gap="1rem">
          <div>
            Будет списано <Amount value={amount!} style={{ fontWeight: 500 }} /> из
            копилки для оплаты{' '}
            {store.kind === 'tax' ? 'налога' : 'налоговой задолженности'}.
          </div>
          <div>Налоговая увидит платёж в течение 10 дней.</div>
        </Flex>
        <SignInput
          onSign={(payload) => store.payTaxes(payload)}
          text="подтвердить оплату"
        />
      </Flex>
    )
  }

  return (
    <Modal
      isOpen={true}
      size={isMobile ? '97%' : 700}
      onClose={() => store.closeDialog()}
    >
      <Heading style={{ marginBottom: '2rem' }}>
        Оплата {store.kind === 'tax' ? 'налога' : 'налоговой задолженности'}
      </Heading>
      {content}
    </Modal>
  )
})

interface TaxAccountViewProps {
  account: Entity<TaxSavingsAccountEntity>
}

const TaxAccountView = observer(({ account }: TaxAccountViewProps) => {
  let { tax_balance, operations } = account.data
  let toast = useStateToast()
  let store = useMemo(() => new TaxAccountViewStore(account, toast), [])

  let accountSection = (
    <Flex direction="column" gap="1.5rem" align="start">
      <Flex gap="0.5rem" style={styles.title} align="center">
        <CheckIcon fill="var(--color-green)" />
        <div>Копилка подключена</div>
      </Flex>
      <div style={{ color: 'var(--color-secondary)', maxWidth: 700 }}>
        Restaff автоматически откладывает часть суммы с ваших доходов, чтобы у вас была
        нужная сумма для уплаты налогов. Когда придёт налог — вы сможете оплатить его с
        накопленной суммы.
      </div>

      <Flex direction="column" gap=".5rem" style={styles.table}>
        <Flex align="center">
          <div style={styles.label}>Накоплено</div>
          <div style={styles.value}>
            <Amount
              style={{ fontSize: 16, fontWeight: 500 }}
              value={account.data.amount}
            />
          </div>
        </Flex>
      </Flex>
    </Flex>
  )

  let taxSection
  if (tax_balance.tax) {
    let { title, amount, due_date, state } = tax_balance.tax
    taxSection = (
      <Flex direction="column" gap="1.5rem" align="start">
        <div style={styles.title}>{title}</div>
        <Flex direction="column" gap=".5rem" style={styles.table}>
          <Flex>
            <div style={styles.label}>Срок оплаты</div>
            <div style={styles.value}>{printDate(due_date, { mode: 'full' })}</div>
          </Flex>
          <Flex>
            <div style={styles.label}>Сумма налога</div>
            <div style={styles.value}>
              <Amount style={{ fontSize: 16, fontWeight: 500 }} value={amount} />
            </div>
          </Flex>
        </Flex>
        {state === 'can_pay' ? (
          <Button onTap={() => store.openDialog('tax')}>Оплатить из копилки</Button>
        ) : (
          <div style={{ maxWidth: 750, color: 'var(--color-secondary)' }}>
            Сейчас в копилке недостаточно денег для оплаты налога, мы отложим необходимую
            сумму со{nbsp}следующей выплаты.
          </div>
        )}
      </Flex>
    )
  }

  let debtSection
  if (tax_balance.debt) {
    let { debt_amount, penalty_amount, total_amount, state } = tax_balance.debt
    debtSection = (
      <Flex direction="column" gap="1.5rem" align="start">
        <div style={styles.title}>Налоговая задолженность</div>
        <div style={{ color: 'var(--color-red)', fontWeight: 500 }}>
          Вам необходимо оплатить задолженность, за каждый день просрочки начисляются пени
        </div>
        <Flex direction="column" gap=".5rem" style={styles.table}>
          <Flex>
            <div style={styles.label}>Сумма задолженности</div>
            <Amount style={styles.value} value={debt_amount} />
          </Flex>
          <Flex>
            <div style={styles.label}>Сумма пени</div>
            <Amount style={styles.value} value={penalty_amount} />
          </Flex>
          <Flex>
            <div style={styles.label}>Всего к оплате</div>
            <div style={styles.value}>
              <Amount style={{ fontSize: 16, fontWeight: 500 }} value={total_amount} />
            </div>
          </Flex>
        </Flex>
        {state === 'can_pay' ? (
          <Button onTap={() => store.openDialog('debt')}>Оплатить из копилки</Button>
        ) : (
          <div style={{ maxWidth: 750, color: 'var(--color-secondary)' }}>
            Сейчас в копилке недостаточно денег для оплаты задолженности, мы отложим
            необходимую сумму со{nbsp}следующей выплаты.
          </div>
        )}
      </Flex>
    )
  }

  let paidSection
  if (!tax_balance.tax && !tax_balance.debt) {
    paidSection = (
      <Flex gap="0.5rem" style={styles.title} align="center">
        <CheckIcon fill="var(--color-green)" />
        <div>Все налоги оплачены</div>
      </Flex>
    )
  }

  let operationsSection = !operations.isEmpty && (
    <TaxAccountOperationsList operations={operations} />
  )

  let payModal = store.payDialogIsOpen && <TaxPayModal store={store} />

  return (
    <>
      {accountSection}
      {taxSection}
      {debtSection}
      {paidSection}
      {operationsSection}
      {payModal}
    </>
  )
})

const TaxAccountPage = observer(() => {
  let currentUser = useUserStore()
  let account = currentUser.user!.data.contractor!.data.tax_savings_account!
  let fetchTaxAccountState = useMemo(() => account.fetch(), [])
  return (
    <Layout>
      <LayoutHeading style={{ marginBottom: '2rem' }}>Мои налоги</LayoutHeading>
      <ActionStateView state={fetchTaxAccountState}>
        {() => (
          <Flex direction="column" gap="3rem">
            {account.data.is_enabled ? (
              <TaxAccountView account={account} />
            ) : (
              <EnableTaxAccountView account={account} />
            )}
          </Flex>
        )}
      </ActionStateView>
    </Layout>
  )
})

export default TaxAccountPage
