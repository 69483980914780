import * as React from 'react'
import { Amount } from 'components'
import { Flex, H1, H3 } from 'components/ui'
import { Income, TaxBoxEntity } from 'entities'
import { observer } from 'mobx-react-lite'
import { Entity } from 'mobx/MobxApi'
import { useMediaContext } from 'App/MediaContext'
import s from './BalanceBlock.module.css'
import { LinkButton } from 'components/ui/Link'
import { Link } from 'react-router-dom'
import { ReactComponent as TaxBox } from 'ui/konsol/icons/tax-box.svg'

const TaxBoxBlock = observer(({ taxBox }: { taxBox: Entity<TaxBoxEntity> }) => {
  return (
    <Flex gap="1rem" align="center">
      {/* <img src={taxBoxImg} className={s.taxBoxImg} alt="tax box" /> */}
      <div className={s.taxBoxImg}>
        <TaxBox width={26} height={26} />
      </div>
      <Flex direction="column" align="start">
        <H3 className={s.subText}>
          Баланс копилки: <Amount value={taxBox.data.savings.amount} />
        </H3>
        <Link to="/taxes">
          <LinkButton underline style={{ fontWeight: 500 }}>
            Перейти в копилку
          </LinkButton>
        </Link>
      </Flex>
    </Flex>
  )
})

export const BalanceBlock = observer(
  ({ taxBox, income }: { taxBox?: Entity<TaxBoxEntity>; income: Income }) => {
    let { isMobile } = useMediaContext()

    return (
      <Flex
        gap={isMobile ? '2rem' : '4rem'}
        direction={isMobile ? 'column' : 'row'}
        align={isMobile ? 'start' : 'center'}
        wrap
      >
        <div className={s.stripe}>
          <H1 className={s.balance}>
            <Amount value={income.amount} />
          </H1>
          <div className={s.subText}>Сумма всех выплат за {income.month}</div>
        </div>
        {taxBox && <TaxBoxBlock taxBox={taxBox} />}
      </Flex>
    )
  }
)
