import React from 'react'
import cl from 'clsx'
import { ReactComponent as PictureIcon } from './assets/picture.svg'

import s from './style.module.css'
import { DivProps } from 'app.types'
import GeneratedAvatar from './GeneratedAvatar'

interface Props extends DivProps {
  className?: string
  uploadable?: boolean
  value?: string
  size?: string
  name?: string
}

export default function Avatar({
  className,
  uploadable,
  value,
  size = '84px',
  name,
  ...props
}: Props) {
  const style = { ...props.style, '--size': size }

  return (
    <div {...props} className={cl(s.root, className)} style={style} title={name}>
      {value ? (
        <div style={{ backgroundImage: `url(${value})` }} className={s.image} />
      ) : name ? (
        <GeneratedAvatar size={size} name={name} />
      ) : null}

      {uploadable && (
        <div className={s.button}>
          <PictureIcon />
        </div>
      )}
    </div>
  )
}
