import React from 'react'
import { observer } from 'mobx-react-lite'
import { useTheme } from 'ui'
import { H2, Link, PageBox, PageHeader } from 'ui-kit'
import { useUserStore } from 'stores/context'

import css from './styles.module.css'
import { useHistory } from 'react-router-dom'

const HelpPage = observer(() => {
  const currentUser = useUserStore()
  const { config } = useTheme()
  const history = useHistory()

  const handleClickRequisitesChange = () => {
    history.push(`/contractors/${currentUser.contractorId}?edit_payment_details=1`)
  }

  return (
    <PageBox>
      <PageHeader title="Помощь" />

      {/* <Link
        href={
          currentUser.isContractor
            ? 'https://help.konsol.pro/'
            : 'https://support.konsol.pro/'
        }
        target="_blank"
      >
        Как пользоваться сервисом
      </Link> */}

      <div style={{ marginTop: '1rem' }}>
        Please contact us at <Link href='mailto:hello@restaff.pro' target='_blank'>hello@restaff.pro</Link> if you have any issues
      </div>

      {currentUser.isContractor && (
        <>
          <H2 className={css.helpH2}>Частые вопросы</H2>
          <Link onClick={handleClickRequisitesChange}>Хочу сменить реквизиты</Link>
          <H2 className={css.helpH2}>Юридическая информация</H2>
          <div>
            <Link href={config.links.agreement} target="_blank">
              Пользовательское соглашение
            </Link>
          </div>
          <div>
            <Link href={config.links.policy} target="_blank">
              Условия предоставления персональных данных
            </Link>
          </div>
        </>
      )}
    </PageBox>
  )
})

export default HelpPage
