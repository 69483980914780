import React, { forwardRef, useMemo } from 'react'
import { flattenDeep, compact, omit } from 'lodash'

export interface StylesMap {
  [key: string]: React.CSSProperties
}

export type StylesFunction<D extends any[]> = (...deps: D) => StylesMap

export type StylesDeclaration<D extends any[]> = StylesFunction<D> | StylesMap

export type StylesList<D extends any[]> =
  | undefined
  | StylesDeclaration<D>
  | StylesList<D>[]

export interface StyleProps<D extends any[]> {
  style?: React.CSSProperties
  styles?: StylesList<D>
}

const omitStyleProps = <P extends {}>(props: P): Omit<P, 'style' | 'styles'> =>
  omit(props, ['style', 'styles']) as Omit<P, 'style' | 'styles'>

const computeStyles = <D extends any[]>(styles: StylesList<D>[], deps: D) => {
  const computed: StylesMap = {}
  // @ts-ignore
  const flatStylesList: StylesDeclaration<D>[] = compact(flattenDeep(styles))
  for (const i in flatStylesList) {
    const styles = flatStylesList[i]
    const result = typeof styles === 'function' ? styles(...deps) : styles
    for (const elem in result) {
      if (computed[elem] === undefined) computed[elem] = {}
      Object.assign(computed[elem], result[elem])
    }
  }
  return computed
}

const useStyles = <P extends StyleProps<[P, ...R]>, R extends any[]>(
  styles: StylesList<[P, ...R]>,
  [props, ...restDeps]: [P, ...R]
) =>
  useMemo(() => {
    const items: StylesList<[P, ...R]>[] = [styles, props.styles]
    if (props.style) items.push({ root: props.style })
    return computeStyles(items, [props, ...restDeps])
  }, [props, ...restDeps])

const extendComponentStyles = <P extends StyleProps<D>, D extends any[]>(
  Component: React.ComponentType<P>,
  styles: StylesList<D>
) =>
  forwardRef((props: P, ref) => (
    <Component
      {...props}
      ref={ref}
      styles={props.styles ? [styles, props.styles] : styles}
    />
  ))

export { useStyles, extendComponentStyles, omitStyleProps }
