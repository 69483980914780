import React, { useState } from 'react'
//@ts-ignore
import Taply from 'taply'
//@ts-ignore
import { useStyles } from 'floral'

interface ToggleInputProps {
  isDisabled?: boolean
  value: boolean
  onChange?: (value: boolean) => void
  label?: React.ReactNode
  size?: 'l' | 'm'
}

const toggleInputStyles = (props: ToggleInputProps, tapState: any) => {
  let root = {
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    fontSize: props.size === 'l' ? '1.5rem' : '1.15rem',
    WebkitTapHighlightColor: 'transparent',
  }

  let toggle: React.CSSProperties = {
    position: 'relative',
    height: 18,
    width: 32,
    marginRight: '.75rem',
    borderRadius: 7,
    flexShrink: 0,
  }

  if (tapState.isFocused) {
    toggle.boxShadow =
      '0 0 0px 2px var(--color-background), 0 0 0px 5px rgb(83 23 254 / 0.4)'
  }

  let bar = {
    position: 'absolute',
    height: 12,
    top: 3,
    left: 0,
    width: '100%',
    borderRadius: 7,
    background: props.value ? 'var(--color-active)' : 'var(--color-secondary)',
    transition: 'left, background .15s ease-in-out',
  }
  let marker = {
    position: 'absolute',
    background: 'white',
    top: 1,
    left: props.value ? 16 : 0,
    width: 16,
    height: 16,
    borderRadius: '50%',
    transition: 'left .15s ease-in-out',
    boxShadow: '0px 0px 0px 1px rgba(0,0,0,.25)',
  }

  let label: React.CSSProperties = {
    transition: 'color .15s ease-in-out',
  }

  if (props.isDisabled) {
    root.cursor = 'not-allowed'
    bar.background = '#cbd0d4'
    label.color = 'var(--color-secondary)'
  }

  return { root, toggle, bar, marker, label }
}

const ToggleInput = (props: ToggleInputProps) => {
  let { value, onChange, label, isDisabled } = props
  let [tapState, setTapState] = useState({})
  let styles = useStyles(toggleInputStyles, [props, tapState])
  return (
    <Taply
      onChangeTapState={setTapState}
      onTap={() => onChange && onChange(!value)}
      isDisabled={isDisabled}
    >
      <div style={styles.root}>
        <div style={styles.toggle}>
          <div style={styles.bar} />
          <div style={styles.marker} />
        </div>
        {label && <div style={styles.label}>{label}</div>}
      </div>
    </Taply>
  )
}

ToggleInput.defaultProps = {
  size: 'm',
}

export default ToggleInput
