import React from 'react'

import { Collection, Entity } from 'mobx/MobxApi'
import { ScenarioProjectEntityRead } from 'entities'
import { List, ListHeader, ListCell } from 'components/ui'

import ProjectsListRow from './ProjectsListRow'

import { PageProps } from 'app.types'

import css from './styles.module.css'

interface IProjectsList {
  projects: Collection<ScenarioProjectEntityRead>
  pageProps: PageProps
}

const ProjectsList = ({ projects, pageProps }: IProjectsList) => {
  return (
    <div className={css.container}>
      <List>
        <ListHeader style={{ borderBottom: '1px solid #E2E4E9' }}>
          <ListCell>Проект</ListCell>
          <ListCell style={{ maxWidth: 70 }}>Заданий</ListCell>
          <ListCell>Локация</ListCell>
          <ListCell style={{ maxWidth: 160 }}>Период</ListCell>
          <ListCell style={{ maxWidth: 100 }}>Стоимость</ListCell>
        </ListHeader>
        {projects.items.map(
          (entity: Entity<ScenarioProjectEntityRead>, index: number) => (
            <ProjectsListRow project={entity} pageProps={pageProps} key={index} />
          )
        )}
      </List>
    </div>
  )
}

export default ProjectsList
