import React from 'react'

import { ListItem, ListCell, Flex, Link, SolidLabels } from 'components/ui'

import { Switch, Button, CopyButton, Row, Menu, MenuItem, Tooltip } from 'ui'

import { ReactComponent as MoreIcon } from 'assets/more-vert.svg'
import { ReactComponent as ArchiveIcon } from 'assets/archive-icon.svg'
import { ReactComponent as RestoreIcon } from 'assets/restore-icon.svg'

import { useMediaContext } from 'App/MediaContext'

import { OnboardingScenarioEntity } from 'entities/onboardingScenario'
import { Entity } from 'mobx/MobxApi'
import { observer } from 'mobx-react-lite'

import classes from './styles.module.css'

import useScenarioListStore from '../../hooks/useScenarioListStore'
import clsx from 'clsx'

interface IScenarioListRowProps {
  scenario: Entity<OnboardingScenarioEntity>
  onEditRow: (scenario: Entity<OnboardingScenarioEntity>) => void
}

export const ScenarioListRow = observer(
  ({ scenario, onEditRow }: IScenarioListRowProps) => {
    const { isMobile } = useMediaContext()
    const scenarioStore = useScenarioListStore()

    const handleToggleArchived = () => {
      const archived = !scenario.data.archived
      scenarioStore.itemSetArchived(scenario.data.id, archived).then((result) => {
        if (result) {
          scenarioStore.loadData()
        }
      })
    }

    const handleToggleEnabled = () => {
      const enabled = !scenario.data.enabled
      scenarioStore.itemSetEnabled(scenario.data.id, enabled).then((result) => {
        if (result) {
          scenario.setData({
            enabled,
          })
        }
      })
    }

    let menu = () => (
      <>
        {scenario.data.archived && (
          <MenuItem onSelect={handleToggleArchived}>
            <Flex align="center">
              <RestoreIcon
                style={{
                  marginRight: 5,
                }}
              />
              Восстановить
            </Flex>
          </MenuItem>
        )}
        {!scenario.data.archived && (
          <MenuItem onSelect={handleToggleArchived}>
            <Flex align="center">
              <ArchiveIcon
                style={{
                  marginRight: 5,
                }}
              />
              Архивировать
            </Flex>
          </MenuItem>
        )}
      </>
    )

    const scenarioFullUrl = `${window.location.origin}/join/${scenario.data.permalink}`

    const handleClick = () => {
      onEditRow(scenario)
    }

    const document_fields_labels = scenario.data.document_fields.map((id) => {
      const documentField = scenarioStore.documentFields.find(({ key }) => key === id)
      return documentField?.name || id
    })

    const contractName =
      scenarioStore.templates.find(({ id }) => id === scenario.data.contract_template_id)
        ?.name || 'Не указан'

    const inactiveClasses = clsx({
      [classes.inactive]: scenario.data.archived,
    })

    if (!isMobile) {
      return (
        <ListItem style={{ alignItems: 'stretch' }} padding={false} hover={false}>
          <ListCell style={{ maxWidth: 35 }}>
            <Switch
              className={inactiveClasses}
              style={{ marginTop: 5 }}
              onChange={handleToggleEnabled}
              value={scenario.data.enabled && !scenario.data.archived}
              isDisabled={scenario.data.archived}
            />
          </ListCell>
          <ListCell>
            <Flex direction="column" gap="1px">
              <Link className={classes.inactiveRowIgnore} onClick={handleClick} as="span">
                {scenario.data.title}
              </Link>
              <Row gap={4}>
                {`/join/${scenario.data.permalink}`}
                <CopyButton
                  onTap={() => null}
                  copyText={scenarioFullUrl}
                  placement={{
                    side: 'bottom',
                  }}
                />
              </Row>
            </Flex>
          </ListCell>
          <ListCell className={inactiveClasses}>{contractName}</ListCell>
          {/*<ListCell>
                    <SolidLabels
                        className={inactiveClasses}
                        items={contractor_custom_fields_labels}
                        emptyLabel={<span>Нет полей</span>}
                    />
                </ListCell>*/}
          <ListCell className={inactiveClasses}>
            <SolidLabels
              size="s"
              items={document_fields_labels}
              emptyLabel={<span>Нет документов</span>}
            />
          </ListCell>
          <ListCell
            style={{
              display: 'flex',
              maxWidth: 125,
              flexDirection: 'row-reverse',
              alignItems: 'flex-start',
            }}
          >
            <div style={{ display: 'flex', gap: 45 }}>
              <Tooltip
                tooltip="Количество исполнителей, которые успешно прошли регистрацию"
                placement={{ side: 'left' }}
              >
                <span className={inactiveClasses}>{scenario.data.register_count}</span>
              </Tooltip>
              <Menu matchWidth menu={menu}>
                {(ref: any, { open }: any) => (
                  <Flex ref={ref} align="center" justify="center">
                    <Button
                      size="s"
                      variant="text"
                      onClick={(e: any) => {
                        e.stopPropagation()
                        open(e)
                      }}
                      style={{
                        width: 30,
                      }}
                    >
                      <MoreIcon
                        fill="black"
                        style={{
                          transform: 'scale(0.5)',
                        }}
                      />
                    </Button>
                  </Flex>
                )}
              </Menu>
            </div>
          </ListCell>
        </ListItem>
      )
    } else {
      return (
        <ListItem style={{ alignItems: 'stretch' }} padding={false} hover={false}>
          <ListCell
            style={{
              background: '#FFFFFF',
              boxShadow: '0px 8px 16px rgba(39, 46, 64, 0.04)',
              padding: '16px',
              borderRadius: '8px',
              position: 'relative',
            }}
          >
            <Flex direction="column" gap="5px">
              <Flex gap="1rem">
                <Switch
                  className={inactiveClasses}
                  onChange={handleToggleEnabled}
                  isDisabled={scenario.data.archived}
                  value={scenario.data.enabled && !scenario.data.archived}
                />
                <Link
                  className={classes.inactiveRowIgnore}
                  onClick={handleClick}
                  as="span"
                >
                  {scenario.data.title}
                </Link>
              </Flex>
              <Flex className={inactiveClasses} gap="1rem">
                <span style={{ color: 'gray', fontWeight: 500 }}>Ссылка:</span>
                <Row gap={4}>
                  {`/join/${scenario.data.permalink}`}
                  <CopyButton
                    onTap={() => null}
                    copyText={scenarioFullUrl}
                    placement={{
                      side: 'bottom',
                    }}
                  />
                </Row>
              </Flex>
              <Flex className={inactiveClasses} gap="1rem">
                <span style={{ color: 'gray', fontWeight: 500 }}>Договор:</span>
                <span style={{ color: 'gray' }}>{contractName}</span>
              </Flex>
              {/*<Flex className={inactiveClasses} direction="column">
                            <span style={{ color: 'gray', fontWeight: 500 }}>
                                Дополнительные поля:
                            </span>
                            <SolidLabels
                                items={contractor_custom_fields_labels}
                                emptyLabel={<span>Нет полей</span>}
                            />
                        </Flex>*/}
              <Flex className={inactiveClasses} direction="column">
                <span style={{ color: 'gray', fontWeight: 500 }}>Документы:</span>
                <SolidLabels
                  size="s"
                  items={document_fields_labels}
                  emptyLabel={<span>Нет документов</span>}
                />
              </Flex>
              <Flex className={inactiveClasses} gap="1rem">
                <span style={{ color: 'gray', fontWeight: 500 }}>Регистраций:</span>
                <Tooltip
                  tooltip="Количество исполнителей, которые успешно прошли регистрацию"
                  placement={{ side: 'left' }}
                >
                  <span>{scenario.data.register_count}</span>
                </Tooltip>
              </Flex>
            </Flex>
            <div style={{ position: 'absolute', top: 10, right: 10 }}>
              <Menu matchWidth menu={menu}>
                {(ref: any, { open }: any) => (
                  <Flex ref={ref} align="center" justify="center">
                    <Button
                      size="s"
                      variant="text"
                      onClick={(e: any) => {
                        e.stopPropagation()
                        open(e)
                      }}
                      style={{
                        width: 30,
                      }}
                    >
                      <MoreIcon
                        fill="black"
                        style={{
                          transform: 'scale(0.8)',
                        }}
                      />
                    </Button>
                  </Flex>
                )}
              </Menu>
            </div>
          </ListCell>
        </ListItem>
      )
    }
  }
)

export default ScenarioListRow
