import { Tooltip, TooltipProps } from 'ui/tooltip'
import { extendComponentStyles, StylesList } from 'ui/styled'

const konsolTooltipStyles: StylesList<[TooltipProps]> = {
  root: {
    fontSize: 'var(--konsol-font-size)',
    color: 'white',
    background: 'rgba(39, 46, 64, 1)',
    padding: '4px 8px',
    opacity: 0.8,
    borderRadius: 6,
  },
}

const KonsolTooltip = extendComponentStyles(Tooltip, konsolTooltipStyles)

export { KonsolTooltip as Tooltip }
