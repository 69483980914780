import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { Flex, Field as FormRow, Input, Select, DatePicker } from 'ui'
import { Field } from 'components/ui'

import SelectConstractors from './components/SelectConstractors'

import css from './styles.module.css'
import Units from './components/Units'
import { observer } from 'mobx-react-lite'
import { AddressSuggestions } from 'react-dadata'
import { TimeField } from '@adobe/react-spectrum'

const DADATA_TOKEN =
  process.env.REACT_DADATA_TOKEN || '6d28ad54e2fae8048203d30746183da13da8c0f6'

const TaskEditForm = observer(({ id, store }: any) => {
  const [dadataContainer, setDadataContainer] = useState<HTMLDivElement | null>(null)

  useEffect(() => {
    let dispose: any
    const dadata = dadataContainer?.querySelector('input')
    if (dadata) {
      const handleChange = (e: any) => {
        const { value } = e.target
        if (!value) {
          form.fields.location.change({
            value: '',
            data: {
              lat: 0,
              lng: 0,
            },
          })
        }
      }
      dadata.addEventListener('change', handleChange)
      dispose = () => dadata.removeEventListener('change', handleChange)
    }
    return dispose
  }, [dadataContainer])

  const handleDadataContainerRef = (element: HTMLDivElement | null) =>
    setDadataContainer(element)

  const params = useParams<any>()

  const { form, projectList: projects } = store

  const setUnits = (units: any) => {
    form.fields.units.change(units)
  }

  return (
    <Flex direction="row" align="stretch" gap="24px">
      <Flex gap="16px" direction="col" style={{ flex: 1 }}>
        {id && (
          <Flex gap="16px">
            <FormRow label="Исполнитель" style={{ flex: 1 }}>
              <Field field={form.fields.contractor}>
                {({ inputProps }) => <Input {...inputProps} isDisabled />}
              </Field>
            </FormRow>

            <FormRow label="Заказчик" style={{ flex: 1 }}>
              <Field field={form.fields.company}>
                {({ inputProps }) => {
                  return <Input {...inputProps} isDisabled />
                }}
              </Field>
            </FormRow>
          </Flex>
        )}

        <FormRow label="Название проекта">
          <Field field={form.fields.project_id}>
            {({ inputProps }) => (
              <Select
                {...inputProps}
                value={
                  inputProps.value
                    ? projects.find((p: any) => p.value === inputProps.value)
                    : undefined
                }
                onChange={(option: any) => {
                  form.fields.project_id.change(option ? option.value : null)
                }}
                options={projects}
                placeholder={params.id ? 'Не указан' : 'Выберите проект'}
                isClearable
                style={
                  params.id || params.projectId
                    ? {
                        opacity: 0.5,
                        pointerEvents: 'none',
                      }
                    : {}
                }
              />
            )}
          </Field>
        </FormRow>

        <FormRow label="Локация">
          {form.fields.project_location.value ? (
            <Field field={form.fields.location}>
              {({ inputProps }) => {
                if (inputProps.value === null) {
                  return
                }
                return (
                  <div style={{ pointerEvents: 'none', opacity: 0.5 }}>
                    <AddressSuggestions
                      key={inputProps.value}
                      token={DADATA_TOKEN}
                      inputProps={{
                        placeholder: 'Не указано',
                        value: inputProps.value,
                      }}
                      defaultQuery={inputProps.value}
                      onChange={inputProps.onChange}
                    />
                  </div>
                )
              }}
            </Field>
          ) : (
            <Field field={form.fields.location}>
              {({ inputProps }) => {
                if (inputProps.value === null) {
                  return
                }
                return (
                  <div ref={handleDadataContainerRef}>
                    <AddressSuggestions
                      token={DADATA_TOKEN}
                      inputProps={{
                        placeholder: 'Выберите локацию',
                        value: inputProps.value,
                      }}
                      defaultQuery={inputProps.value}
                      onChange={inputProps.onChange}
                    />
                  </div>
                )
              }}
            </Field>
          )}
        </FormRow>

        <Flex direction="row" align="stretch" gap="15px">
          <FormRow label="Период" style={{ flex: 2 }}>
            {form.fields.project_periodFrom.value ||
            form.fields.project_periodTo.value ? (
              <DatePicker
                value={[
                  form.fields.project_periodFrom.value,
                  form.fields.project_periodTo.value,
                ]}
                placeholder="Не указан"
                selectsRange={true}
                style={{
                  pointerEvents: 'none',
                  opacity: 0.5,
                }}
                onChange={() => null}
              />
            ) : (
              <DatePicker
                value={[form.fields.periodFrom.value, form.fields.periodTo.value]}
                placeholder="Выберите период"
                selectsRange={true}
                onChange={(range: any) => {
                  const [from = null, to = null] = Array.isArray(range) ? range : []
                  form.fields.periodFrom.change(from)
                  form.fields.periodTo.change(to)
                }}
              />
            )}
          </FormRow>
          <FormRow label="Время с" style={{ flex: 1 }}>
            {form.fields.project_timeFrom.value || form.fields.project_timeTo.value ? (
              <div
                className={css.timePickerWrapper}
                style={{ pointerEvents: 'none', opacity: 0.5 }}
              >
                <TimeField hourCycle={24} value={form.fields.project_timeFrom.value} />
              </div>
            ) : (
              <div className={css.timePickerWrapper}>
                <TimeField
                  hourCycle={24}
                  value={form.fields.timeFrom.value}
                  onChange={(timeFrom) => form.fields.timeFrom.change(timeFrom)}
                />
              </div>
            )}
          </FormRow>
          <FormRow label="Время по" style={{ flex: 1 }}>
            {form.fields.project_timeFrom.value || form.fields.project_timeTo.value ? (
              <div
                className={css.timePickerWrapper}
                style={{ pointerEvents: 'none', opacity: 0.5 }}
              >
                <TimeField hourCycle={24} value={form.fields.project_timeTo.value} />
              </div>
            ) : (
              <div className={css.timePickerWrapper}>
                <TimeField
                  hourCycle={24}
                  value={form.fields.timeTo.value}
                  onChange={(timeTo) => form.fields.timeTo.change(timeTo)}
                />
              </div>
            )}
          </FormRow>
        </Flex>

        <FormRow label="Комментарий">
          {form.fields.project_description.value ? (
            <Input
              isMultiline
              rows={4}
              value={form.fields.project_description.value}
              style={{ pointerEvents: 'none', opacity: 0.5 }}
              placeholder="Комментарий к задаче..."
            />
          ) : (
            <Field field={form.fields.description}>
              {({ inputProps }) => (
                <Input
                  isMultiline
                  rows={4}
                  {...inputProps}
                  value={inputProps.value || ''}
                  placeholder="Комментарий к задаче..."
                />
              )}
            </Field>
          )}
        </FormRow>

        <div className={css.units}>
          <Units
            data={form.fields.units.value}
            unitOptions={store.unitOptions}
            onChange={setUnits}
          />
        </div>
      </Flex>
      <div style={{ width: '300px' }}>
        {id === undefined && <SelectConstractors store={store} />}
      </div>
    </Flex>
  )
})

export default TaskEditForm
