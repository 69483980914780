import React from 'react'

import { extendComponentStyles, StylesMap } from 'ui/styled'
import { Select, defaultComponents } from 'ui/select'
import type {
  SelectProps,
  SelectControlStyleProps,
  SelectOptionProps,
  SelectPlaceholderProps,
} from 'ui/select'
import { Tag } from 'ui/konsol/tag'

import { ReactComponent as CheckboxIcon } from 'ui/konsol/icons/checkbox.svg'
import { ReactComponent as DropdownIcon } from 'ui/konsol/icons/dropdown.svg'

const selectControlStyles = (...[props, { tapState }]: SelectControlStyleProps) => {
  const { isFocused } = props.select.state

  let background
  if (props.select.props.isDisabled) {
    background = 'var(--konsol-color-grey-08)'
  } else if (isFocused) {
    background = 'white'
  } else if (tapState.isHovered) {
    background = 'var(--konsol-color-grey-24)'
  } else {
    background = 'var(--konsol-color-grey-16)'
  }

  const root: React.CSSProperties = {
    background,
    borderRadius: 6,
    boxShadow: isFocused ? 'var(--konsol-focus-shadow) inset' : 'none',
    transition: 'box-shadow .15s ease-out, background .15s ease-out',
    minHeight: 40,
    boxSizing: 'border-box',
    padding: '6px 12px',
    fontSize: 'var(--konsol-font-size)',
  }
  const values = {
    display: 'flex',
    gap: 4,
    alignItems: 'center',
  }
  const placeholder = {
    display: 'flex',
    alignItems: 'center',
  }
  const indicators = {
    marginLeft: 6,
  }
  return { root, values, placeholder, indicators }
}

const selectNoOptionsMessageStyles = {
  root: {
    color: 'var(--konsol-color-text-secondary)',
    fontSize: 'var(--konsol-font-size',
    display: 'flex',
    padding: 6,
  },
}

const KonsolSelectDropdownButton = () => {
  return <DropdownIcon />
}

const KonsolSelectNoOptionsMessage = extendComponentStyles(
  defaultComponents.NoOptionsMessage,
  selectNoOptionsMessageStyles
)

const selectMenuStyles: StylesMap = {
  root: {
    background: 'white',
    borderRadius: 6,
    boxShadow: '0px 4px 16px 0px rgba(124, 130, 146, 0.16)',
    boxSizing: 'border-box',
    padding: 6,
    fontSize: 'var(--konsol-font-size)',
  },
}

const KonsolSelectMenu = extendComponentStyles(defaultComponents.Menu, selectMenuStyles)

const konsolSelectOptionStyles = ({ isHovered, isDisabled }: SelectOptionProps) => {
  const root = {
    color: isDisabled
      ? 'var(--konsol-color-grey-40)'
      : 'var(--konsol-color-text-default)',
    padding: 8,
    borderRadius: 6,
    background: isHovered ? 'var(--konsol-color-blue-08)' : 'white',
    cursor: isDisabled ? 'default' : 'pointer',
    fontSize: 'var(--konsol-font-size)',
    WebkitTapHighlightColor: 'transparent',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
  }
  return { root }
}

const KonsolSelectOption = (props: SelectOptionProps) => {
  const { children, styles, isSelected, ...restProps } = props
  const { isMulti } = props.select.props
  return (
    <defaultComponents.Option
      styles={[konsolSelectOptionStyles, styles]}
      isSelected={isSelected}
      {...restProps}
    >
      {isMulti && (
        <CheckboxIcon
          style={{
            flexShrink: 0,
            marginRight: 4,
            opacity: isSelected ? 1 : 0,
            transition: 'opacity .15s ease-in-out',
          }}
        />
      )}
      <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{children}</div>
    </defaultComponents.Option>
  )
}

const placeholderStyles = (props: SelectPlaceholderProps) => ({
  root: {
    color: props.select.props.isDisabled
      ? 'var(--konsol-color-text-disabled)'
      : 'var(--konsol-color-text-secondary)',
  },
})

const KonsolSelectControl = extendComponentStyles(
  defaultComponents.Control,
  selectControlStyles
)

const KonsolSelectPlaceholder = extendComponentStyles(
  defaultComponents.Placeholder,
  placeholderStyles
)

const KonsolSelectMultiValue = ({ onDelete, children }: any) => (
  <Tag isDeletable onDelete={onDelete} size="s">
    {children}
  </Tag>
)
const KonsolSelect = (props: SelectProps) => {
  const { components, ...restProps } = props
  return (
    <Select
      components={{
        Option: KonsolSelectOption,
        NoOptionsMessage: KonsolSelectNoOptionsMessage,
        Control: KonsolSelectControl,
        Placeholder: KonsolSelectPlaceholder,
        MultiValue: KonsolSelectMultiValue,
        Menu: KonsolSelectMenu,
        DropdownButton: KonsolSelectDropdownButton,
        ...components,
      }}
      {...restProps}
    />
  )
}

export { KonsolSelect as Select }
