import React from 'react'
import { Typography, TypographyProps, TypographyVariant } from 'ui-kit'

export type H2Props = Omit<TypographyProps, 'variant'>

const H2 = ({ children, ...restProps }: H2Props) => (
  <Typography variant={TypographyVariant.H2} {...restProps}>
    {children}
  </Typography>
)

export default H2
