import React from 'react';

import { PageProps } from 'app.types';
import MobxApi from 'mobx/MobxApi';

import { ProjectItemStoreProvider, DialogProps } from '../../hooks/useProjectItemStore';
import { TaskListStoreProvider } from '../../hooks/useTaskListStore';

import ProjectItemInner from './ProjectItem';

interface IProjectItemProps extends DialogProps {
  mobxApi: MobxApi;
  pageProps: PageProps;
}

export const ProjectItem = ({
  projectId,
  pageProps,
  mobxApi,
}: IProjectItemProps) => (
  <ProjectItemStoreProvider
    pageProps={pageProps}
    mobxApi={mobxApi}
    projectId={projectId}
  >
    <TaskListStoreProvider pageProps={pageProps}>
      <ProjectItemInner />
    </TaskListStoreProvider>
  </ProjectItemStoreProvider>
);

export default ProjectItem;
