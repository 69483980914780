import React from 'react'
import { observer } from 'mobx-react-lite'

import { Collection } from 'mobx/mobx'
import { useMediaContext } from 'App/MediaContext'

import { Pagination, ErrorPlaceholder, LoadingPlaceholder, Col } from 'components/ui'

import PaginatedStore from './PaginatedStore'

interface PaginatedViewProps<T> {
  store: PaginatedStore<T>
  render: (collection: Collection<T>, page: number) => React.ReactNode
  onChangePage: (page: number) => void
  style?: React.CSSProperties
}

const overlayStyle = ({ isLoading }: { isLoading: boolean }): React.CSSProperties => ({
  position: 'absolute',
  left: 0,
  top: '-.5rem',
  right: 0,
  bottom: '-.5rem',
  background: 'radial-gradient(var(--color-background-secondary), transparent)',
  pointerEvents: isLoading ? 'auto' : 'none',
  opacity: isLoading ? 1 : 0,
})

const PaginatedView = observer(<T,>(props: PaginatedViewProps<T>) => {
  let { store, render, onChangePage, style } = props
  let { isMobile } = useMediaContext()
  if (store.fetchState.state === 'rejected') {
    return <ErrorPlaceholder>{String(store.fetchState.value)}</ErrorPlaceholder>
  }
  let isLoading = store.fetchState.state === 'pending'
  let value = store.fetchedValue
  return (
    <Col gap="1.5rem" style={{ position: 'relative', ...style }}>
      {value && render(value.data.items, value.data.page)}
      <div style={overlayStyle({ isLoading })}>
        <LoadingPlaceholder />
      </div>
      {value && value.data.total_pages > 1 && (
        <Pagination
          pageCount={value.data.total_pages}
          page={value.data.page}
          onChange={onChangePage}
          pagesRange={isMobile ? 1 : 2}
        />
      )}
    </Col>
  )
})

export default PaginatedView
