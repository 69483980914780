import * as React from 'react'
import {
  Textarea as ChakraTextArea,
  TextareaProps as ChakraProps,
} from '@chakra-ui/react'
import styles from './index.module.css'
import clsx from 'clsx'

type TextAreaProps = ChakraProps & {}

export const TextArea: React.FC<TextAreaProps> = ({ className, ...props }) => {
  return <ChakraTextArea className={clsx(styles.textArea, className)} {...props} />
}
