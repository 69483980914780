import { fetchApi } from 'api'
import * as React from 'react'
import styles from './RateButton.module.css'

type RateButtonType = 'upvote' | 'downvote'

const images: { [K in RateButtonType]: string } = {
  upvote: require('./thumbs-up.png'),
  downvote: require('./thumbs-down.png'),
}

type RateButtonProps = {
  type: RateButtonType
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void
}

export const RateButton = ({ type, onClick }: RateButtonProps) => {
  return (
    <button
      type="button"
      className={styles.button}
      onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
        const event = type === 'downvote' ? 'thumbs_down' : 'thumbs_up'
        fetchApi({
          url: 'users/events',
          method: 'PUT',
          data: { event },
        })
        onClick(e)
      }}
    >
      <img src={images[type]} alt={type} className={styles.img} />
    </button>
  )
}
