// форма персональных данных
export const PERSONAL_DATA_REQUIRED = 'personal_data_required'

// показываем страницу cyberity
export const CYBERITY_VERIFICATION_REQUIRED = 'cyberity_verification_required'

// страница основных документов
export const DOCUMENTS_REQUIRED = 'documents_required'

// страница дополнительных документов
export const ADDITIONAL_DOCUMENTS_REQUIRED = 'additional_documents_required'

// получили и проверяем документы
export const CHECK_IN_PROGRESS = 'manual_verification_required'

// старые бездомные статусы
export const CHECK_ERROR = 'check_error'
export const CONTRACT = 'contract'
export const CANCELLED = 'cancelled'
