import { createNumberMask } from 'text-mask-addons'
import cardValidator from 'card-validator'
import { tail } from 'lodash'
import { isDevelopment } from 'thirdParty'

export const normalizePhone = (val: string) => (val ? val.replace(/[^\d]/g, '') : val)

export const validatePhone = (val: string) => normalizePhone(val).length === 11

export const validatePhoneInternational = (val: string) => {
  let normalized = normalizePhone(val)
  return (
    (normalized[0] === '7' && normalized.length === 11) ||
    (normalized[0] !== '7' && normalized.length > 3)
  )
}

export const validateEmail = (val: string) =>
  /^[^@\s]+@[^@\s]+\.[^@\s]+$/i.test(val)

export const validateDigitsAndLatin = (val: string) => !/[\da-zA-Z]+/i.test(val)

export const validateNotDigits = (val: string) => !/[\d]+/i.test(val)

export const validateInnLength = (val: string) => /^\d{12}$/.test(val)

// только для физиков (12 символов)
export const validateInn = (value: string): boolean => {
  if (
    typeof value !== 'string' ||
    value.length !== 12 ||
    value.split('').some((symbol) => isNaN(Number(symbol)))
  )
    return false

  // для разработки не валидируется инн
  if (isDevelopment()) return true

  let checkSumOne =
    (value
      .split('')
      .slice(0, -2)
      .reduce(
        (summ, symbol, index) =>
          [7, 2, 4, 10, 3, 5, 9, 4, 6, 8][index] * Number(symbol) + summ,
        0
      ) %
      11) %
    10

  let checkSumTwo =
    (value
      .split('')
      .slice(0, -1)
      .reduce(
        (summ, symbol, index) =>
          [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8][index] * Number(symbol) + summ,
        0
      ) %
      11) %
    10

  return checkSumOne === Number(value[10]) && checkSumTwo === Number(value[11])
}

export const createDigitsMask = (size: number) => Array(size).fill(/\d/)

export const integerMask = createNumberMask({
  prefix: '',
  includeThousandsSeparator: false,
})

export const createDecimalMask = (decimalLimit: number | false = 2) =>
  createNumberMask({
    prefix: '',
    includeThousandsSeparator: false,
    allowDecimal: true,
    decimalLimit,
  })

export const decimalMask = createDecimalMask()

// normal float

export const toFixed = (value: number, limit: number = 0): number =>
  parseFloat(
    Number(value)
      .toFixed(limit + 1)
      .slice(0, -1)
  )

// prettier-ignore
export const phoneMask = [
  '+', /\d/, ' ', '(', /\d/, /\d/, /\d/, ')', ' ',
  /\d/, /\d/, /\d/, '-',
  /\d/, /\d/, '-', /\d/, /\d/
]

// // prettier-ignore
// export const ipMask = ['\b((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(.|$)){4}\b']

export const validateIpAddress = (val: string) =>
  /^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)(\.(?!$)|$)){4}$/i.test(val)

export const foreignPhoneMask = ['+', ...Array(13).fill(/\d/)]

export const isForeignPhone = (value: string) => {
  let normalized = normalizePhone(value)
  return !(normalized.length === 0 || normalized.startsWith('7'))
}

export const internationalPhoneMask = (value: string) =>
  isForeignPhone(value) ? foreignPhoneMask : phoneMask

export const phoneMaskWithoutPrefix = tail(phoneMask)

export const foreignPhoneMaskWithoutPrefix = tail(foreignPhoneMask)

export const internationalPhoneMaskWithoutPrefix = (value: string) => isForeignPhone(value) ? foreignPhoneMaskWithoutPrefix : phoneMaskWithoutPrefix

// prettier-ignore
export const cardMask = [
  ...createDigitsMask(4), ' ',
  ...createDigitsMask(4), ' ',
  ...createDigitsMask(4), ' ',
  ...createDigitsMask(4)
]

export const cardPlaceholder = '0000 0000 0000 0000'

export const normalizeCardNumber = (value: string) => value.replace(/[^\d]/g, '')

export const validateCardNumber = (value: string) => cardValidator.number(value).isValid

export const dateMask = [/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]
