import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { TimeField } from '@adobe/react-spectrum'

import { Flex, Field as FormRow, Input, DatePicker, Button } from 'ui'

import { Field } from 'components/ui'

import { AddressSuggestions } from 'react-dadata'

import useProjectItemStore from '../../../hooks/useProjectItemStore'
import { useMediaContext } from 'App/MediaContext'
import { Amount } from 'components'

import classes from './styles.module.css'

const DADATA_TOKEN =
  process.env.REACT_DADATA_TOKEN || '6d28ad54e2fae8048203d30746183da13da8c0f6'

export const HeadRow = observer(() => {
  const [dadataContainer, setDadataContainer] = useState<HTMLDivElement | null>(null)

  useEffect(() => {
    let dispose: any
    const dadata = dadataContainer?.querySelector('input')
    if (dadata) {
      const handleChange = (e: any) => {
        const { value } = e.target
        if (!value) {
          formStore.fields.location.change({
            value: '',
            data: {
              lat: 0,
              lng: 0,
            },
          })
        }
      }
      dadata.addEventListener('change', handleChange)
      dispose = () => dadata.removeEventListener('change', handleChange)
    }
    return dispose
  }, [dadataContainer])

  const handleDadataContainerRef = (element: HTMLDivElement | null) =>
    setDadataContainer(element)

  const history = useHistory()
  const modalStore = useProjectItemStore()

  const { isMobile } = useMediaContext()
  const size = isMobile ? 'm' : 'l'

  const formStore = modalStore.staticForm

  const totalAmount = modalStore.tasks.reduce(
    (previous: any, current: any) => previous + parseInt(current.amount),
    0
  )

  const onAddTask = async () => {
    if (modalStore.saveTask) {
      await modalStore.saveTask
    }
    history.push(`/projects/${modalStore.projectId}/tasks/create`)
  }

  return (
    <>
      <Flex direction="col" align="stretch" gap="12px">
        <FormRow label="Локация">
          <Field field={formStore.fields.location}>
            {({ inputProps }) => (
              <div ref={handleDadataContainerRef}>
                <AddressSuggestions
                  token={DADATA_TOKEN}
                  inputProps={{
                    placeholder: 'Выберите локацию',
                    value: inputProps.value,
                  }}
                  defaultQuery={inputProps.value}
                  onChange={inputProps.onChange}
                />
              </div>
            )}
          </Field>
        </FormRow>
        <Flex direction="row" align="stretch" gap="15px">
          <FormRow label="Период" style={{ flex: 2 }}>
            <DatePicker
              value={[
                modalStore.staticForm.fields.periodFrom.value,
                modalStore.staticForm.fields.periodTo.value,
              ]}
              placeholder="Выберите период"
              selectsRange={true}
              onChange={(range: any) => {
                const [from = null, to = null] = Array.isArray(range) ? range : []
                formStore.fields.periodFrom.change(from)
                formStore.fields.periodTo.change(to)
              }}
            />
          </FormRow>
          <FormRow label="Время с" style={{ flex: 1 }}>
            <div className={classes.timePickerWrapper}>
              <TimeField
                hourCycle={24}
                value={formStore.fields.timeFrom.value}
                onChange={(timeFrom) => formStore.fields.timeFrom.change(timeFrom)}
              />
            </div>
          </FormRow>
          <FormRow label="Время по" style={{ flex: 1 }}>
            <div className={classes.timePickerWrapper}>
              <TimeField
                hourCycle={24}
                value={formStore.fields.timeTo.value}
                onChange={(timeTo) => formStore.fields.timeTo.change(timeTo)}
              />
            </div>
          </FormRow>
        </Flex>
        <FormRow label="Комментарий">
          <Field field={formStore.fields.comment}>
            {({ inputProps }) => (
              <Input
                {...inputProps}
                placeholder="Комментарий"
                size={size}
                isMultiline
                rows={4}
              />
            )}
          </Field>
        </FormRow>
      </Flex>
      <Flex
        gap="12px"
        align="center"
        justify="space-between"
        style={{ marginTop: '20px' }}
      >
        <Flex gap="12px" align="center">
          <FormRow label="Задания" />
          <Button variant="text" size="s" onClick={onAddTask}>
            Создать задания
          </Button>
        </Flex>
        <div>
          <b>
            Итого:{' '}
            <Amount
              value={{ value: String(totalAmount), currency: 'RUB' }}
              style={{ fontWeight: 600 }}
            />
          </b>
        </div>
      </Flex>
    </>
  )
})

export default HeadRow
