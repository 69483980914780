import React, { useState } from 'react'
import { fromPromise } from 'mobx-utils'


import { Title, Input, FormGroup, Label } from 'components/ui/LayoutSimple'

import { Link } from 'ui'

import { checkEmail } from '../../requests'

const SendEmail = ({
  onComplete,
}: {
  onComplete: (uuid: string, email: string) => void
}) => {
  const [email, setEmail] = useState('')

  const onSubmit = () => {
    if (email) {
      let state = fromPromise(checkEmail(email))
      state.then(
        ({ uuid }) => onComplete(uuid, email),
        () => {}
      )
    }
  }

  return (
    <>
      <Title>Восстановление пароля</Title>
      <FormGroup
        isDisabled={!email}
        submitText="Отправить код"
        onSubmit={onSubmit}
      >
        <Label>
          Укажите электронную почту, которую вы&nbsp;указывали при регистрации
        </Label>

        <Input
          value={email}
          onChange={setEmail}
          onEnter={() => {
            if (email) onSubmit()
          }}
          type="email"
          autoFocus
        />

        <div style={{ marginTop: '1rem' }}>
          Please contact us at <Link href='mailto:hello@restaff.pro' target='_blank'>hello@restaff.pro</Link> if you have any issues
        </div>
      </FormGroup>
    </>
  )
}

export default SendEmail
