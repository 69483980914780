import React, { useMemo, useEffect, useState, useRef, createContext } from 'react'
import { observer } from 'mobx-react-lite'
import { createBrowserHistory } from 'history'
import { Router } from 'react-router-dom'
import { ChakraProvider, extendTheme } from '@chakra-ui/react'
// @ts-ignore
import { OrienteProvider } from 'oriente'

import { Url } from 'utils/url'
import { fetchJson } from 'api'
import LoadingScreen from 'components/LoadingScreen'
import {
  UserStoreContext,
  UserStore,
  SignDialogStore,
  SignDialogContext,
  CompanyStore,
  CompanyStoreContext,
} from 'stores/context'
import { MobxApiContextProvider } from 'mobx/MobxApiContext'
import MultiProvider from 'utils/MultiProvider'

import { MediaContextProvider } from './MediaContext'
import createMobxApi from './createMobxApi'
import { PusherChannelContext, usePusherChannel } from './pusher'
import MainRoutes from './MainRoutes'

import { YMaps } from 'components'

import { isDevelopment, isStaging } from 'thirdParty'

import DevelopmentEnv from 'components/Development'

import { KonsolUIProvider, useTheme } from 'ui'

// @ts-ignore
import smoothscroll from 'smoothscroll-polyfill'

// mixpanel
import { MixpanelProvider } from 'components/MixPanel'

import 'ui/konsol/styles/index.css'
require(`themes/${process.env.REACT_APP_THEME}/index.css`)

declare global {
  interface Window {
    userStore: UserStore
    mobxApi: ReturnType<typeof createMobxApi>
  }
}

// (window as any).__forceSmoothScrollPolyfill__ = true;

// kick off the polyfill!
smoothscroll.polyfill()

const mobxApi = createMobxApi()
window.mobxApi = mobxApi

const userStore = new UserStore(mobxApi)
window.userStore = userStore

const browserHistory = createBrowserHistory()

window.$root = document.getElementById('root')

const chakraTheme = extendTheme({
  colors: {
    white: 'var(--color-background-secondary)',
  },
  fonts: {
    body: "'SF Pro Text', system-ui, sans-serif",
    heading: "'SF Pro Display', system-ui, sans-serif",
  },
})

const StaticVersionContext = createContext({ haveUpdates: false })
const checkUpdatesInterval = 5 * 1000 * 60

const App = observer(() => {
  let [haveUpdates, setHaveUpdates] = useState(false)
  let checkUpdatesHandler = useRef<number | null>(null)

  useEffect(() => {
    // import theme styles
    const { getStyles } = useTheme()
    getStyles()

    if (isDevelopment()) return
    checkUpdatesHandler.current = window.setInterval(async () => {
      if (document.hidden) return
      try {
        const { version } = await fetchJson({
          method: 'GET',
          url: Url('/release.json').toString(),
        })
        if (version && version !== process.env.REACT_APP_RELEASE_NAME) {
          setHaveUpdates(true)
          clearInterval(checkUpdatesHandler.current as number)
        }
      } catch (e) {}
    }, checkUpdatesInterval)
  }, [])

  let initState = useMemo(() => userStore.init(browserHistory), [])

  let channel = usePusherChannel(userStore.user ? userStore.user.id : undefined)

  let signDialog = useMemo(() => new SignDialogStore(), [])

  const companyStore = new CompanyStore()

  let providers = [
    { provider: ChakraProvider, props: { theme: chakraTheme, portalZIndex: 40 } },
    { provider: MobxApiContextProvider, props: { value: mobxApi } },
    { provider: UserStoreContext.Provider, props: { value: userStore } },
    { provider: SignDialogContext.Provider, props: { value: signDialog } },
    { provider: CompanyStoreContext.Provider, props: { value: companyStore } },
    { provider: StaticVersionContext.Provider, props: { value: { haveUpdates } } },
    { provider: MediaContextProvider },
    { provider: PusherChannelContext.Provider, props: { value: channel } },
    { provider: OrienteProvider },
    { provider: KonsolUIProvider },
    { provider: YMaps },
  ]

  return (
    <MultiProvider providers={providers}>
      {(isDevelopment() || isStaging()) && <DevelopmentEnv />}
      <MixpanelProvider>
        <Router history={browserHistory}>
          <LoadingScreen>
            {initState.state === 'fulfilled' && (
              <MainRoutes browserHistory={browserHistory} />
            )}
          </LoadingScreen>
          {signDialog.render()}
        </Router>
      </MixpanelProvider>
    </MultiProvider>
  )
})

export default App
