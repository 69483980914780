import React, { useState, useEffect } from 'react'
import { fromPromise } from 'mobx-utils'

import { useHarmonicIntervalFn } from 'react-use'
import cl from 'clsx'

import { CodeInput } from 'components'
import { BaseButton } from 'components/ui'
import { Title, Label, FormGroup } from 'components/ui/LayoutSimple'

import { checkEmail, checkCode } from '../../requests'

import s from './style.module.css'

const SMS_RESEND_TIME = 59 // seconds

const SendCode = ({
  uuid,
  email,
  onComplete,
}: {
  uuid: string
  email: string
  onComplete: () => void
}) => {
  const [code, setCode] = useState('')
  const [_uuid, _setUuid] = useState(uuid)
  const [error, setError] = useState('')
  const [shakeInput, setShakeInput] = useState(false)
  const [checkState, setCheckState] = useState<any>(fromPromise.resolve())
  const [isCompleted, setCompleted] = useState(false)
  const [resendTimer, setResendTimer] = useState(SMS_RESEND_TIME)

  const updateCode = (code: string) => {
    setCode(code)
    if (code.length === 6) {
      const state = fromPromise(checkCode(_uuid, code))
      state.then(
        () => {
          onComplete()
          setCompleted(true)
          setError('')
        },
        () => {
          setCode('')
          setShakeInput(true)
          setTimeout(() => setShakeInput(false), 500)
        }
      )
      setCheckState(state)
    }
  }

  useEffect(() => {
    if (checkState.state === 'rejected') {
      setError(checkState.value.message)
    }
  }, [checkState.state])

  useHarmonicIntervalFn(() => {
    setResendTimer((val) => (val > 0 ? val - 1 : val))
  }, 1000)

  const resendCode = () => {
    setError('')
    fromPromise(checkEmail(email)).then(
      (data) => {
        _setUuid(data.uuid)
      },
      () => {}
    )
    setResendTimer(SMS_RESEND_TIME)
  }

  const isPending = checkState.state === 'pending'

  return (
    <>
      <Title>Вход</Title>
      <FormGroup
        error={error}
        isLoading={isPending}
        additionalLink={
          !resendTimer ? (
            <BaseButton onTap={() => resendCode()} className={s.linkButton}>
              Отправить код повторно
            </BaseButton>
          ) : null
        }
      >
        <Label>
          Мы&nbsp;отправили код на&nbsp;почту <span>{email}</span>.<br />
          Введите код из&nbsp;письма
        </Label>
        <CodeInput
          autoFocus
          size={6}
          value={code}
          onChange={updateCode}
          className={cl(shakeInput && s.shakeOnError)}
          isLoading={checkState.state === 'pending' || isCompleted}
        />
        {resendTimer ? (
          <div className={s.resendSmsMessage}>
            Повторно отправить код через <span>{resendTimer} сек</span>
          </div>
        ) : null}
      </FormGroup>
    </>
  )
}

export default SendCode
