import React from 'react';

import AnalyticsBanner from 'components/AnalyticsAccessBanner';

import { useMediaContext } from 'App/MediaContext';

export const AnalyticsBannerPage = () => {
  const { isMobile } = useMediaContext();
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        minHeight: '80vh',
        marginLeft: isMobile ? 'unset' : '-60px',
      }}
    >
      <AnalyticsBanner />
    </div>
  );
};

export default AnalyticsBannerPage;
