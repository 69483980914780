import React from 'react'

import { List, ListHeader, ListCell, Placeholder } from 'components/ui'

import { Divider } from 'ui'

import { useMediaContext } from 'App/MediaContext'

import { observer } from 'mobx-react-lite'

import { OnboardingScenarioEntity } from 'entities/onboardingScenario'

import { Collection, Entity } from 'mobx/MobxApi'

import useScenarioListStore from 'pages/onboarding_scenarios/hooks/useScenarioListStore'

import ScenarioListRow from './ScenarioListRow'

interface IScenarioListProps {
  scenarios: Collection<OnboardingScenarioEntity>
  onEditRow: (scenario: Entity<OnboardingScenarioEntity>) => void
}

export const ScenarioListInner = observer(
  ({ scenarios, onEditRow }: IScenarioListProps) => {
    const { isMobile } = useMediaContext()
    const scenarioStore = useScenarioListStore()

    const totalItems = scenarios.items.filter((item) =>
      item.data.title.toLowerCase().includes(scenarioStore.searchTextD.toLowerCase())
    )
    const activeItems = totalItems.filter((item) => !item.data.archived)
    const archivedItems = totalItems.filter((item) => item.data.archived)

    if (!totalItems.length) {
      return <Placeholder>Ничего не найдено</Placeholder>
    }

    return (
      <List>
        {!isMobile && (
          <ListHeader>
            <ListCell style={{ maxWidth: 35 }}>Вкл</ListCell>
            <ListCell>Сценарий</ListCell>
            <ListCell>Договор</ListCell>
            {/*<ListCell>Доп. поля</ListCell>*/}
            <ListCell>Документы</ListCell>
            <ListCell
              style={{
                display: 'flex',
                maxWidth: 125,
                flexDirection: 'row-reverse',
              }}
            >
              Регистрации
            </ListCell>
          </ListHeader>
        )}
        {activeItems.map((entity: Entity<OnboardingScenarioEntity>, idx: number) => (
          <ScenarioListRow onEditRow={onEditRow} scenario={entity} key={idx} />
        ))}
        {!!archivedItems.length && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              minHeight: 70,
            }}
          >
            <Divider
              style={{
                borderBottom: '1px solid rgba(39, 46, 64, 0.07)',
              }}
            />
            <span
              style={{
                display: 'flex',
                alignItems: 'center',
                fontWeight: 500,
                flex: 1,
              }}
            >
              Архив сценариев
            </span>
          </div>
        )}
        {archivedItems.map((entity: Entity<OnboardingScenarioEntity>, idx: number) => (
          <ScenarioListRow onEditRow={onEditRow} scenario={entity} key={idx} />
        ))}
      </List>
    )
  }
)

export default ScenarioListInner
