import {
  useRef,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useState,
  useMemo,
} from 'react'

import useIt, { It } from 'ui/utils/useIt'
import mergeRefs from 'ui/utils/mergeRefs'
import cloneElementWithRef from 'ui/utils/cloneElementWithRef'

export interface TapState {
  isHovered: boolean
  isPressed: boolean
  isFocused: boolean
}

export interface TaplyTouch {
  identifier?: number
  x: number
  y: number
  x0: number
  y0: number
  dx: number
  dy: number
}

export type TapEvent = MouseEvent | TouchEvent | KeyboardEvent

export type UseTaplyProps = {
  onTap?: (event: TapEvent) => void
  tapState?: TapState
  onChangeTapState?: (tapState: TapState) => void
  onTapStart?: (
    event: MouseEvent | TouchEvent | KeyboardEvent,
    touches: TaplyTouch[]
  ) => void
  onTapMove?: (event: MouseEvent | TouchEvent, touches: TaplyTouch[]) => void
  onTapEnd?: (event: MouseEvent | TouchEvent | undefined, touches: TaplyTouch[]) => void
  onPinchStart?: (event: TouchEvent, touches: TaplyTouch[]) => void
  onPinchMove?: (event: TouchEvent, touches: TaplyTouch[]) => void
  onPinchEnd?: (event: TouchEvent, touches: TaplyTouch[]) => void
  onFocus?: (event: FocusEvent) => void
  onBlur?: (event: FocusEvent) => void
  isDisabled?: boolean
  preventFocusOnTap?: boolean
  shouldSetAttributes?: boolean
  isFocusable?: boolean
  isPinchable?: boolean
  tabIndex?: number
}

interface TaplyProps extends UseTaplyProps {
  children: React.ReactElement | ((tapState: TapState, ref: any) => React.ReactElement)
}

const defaultProps = {
  isDisabled: false,
  preventFocusOnTap: true,
  shouldSetAttributes: true,
  isFocusable: true,
  isPinchable: false,
  tabIndex: 0,
}

interface TaplyCtx {
  elem?: HTMLElement
  shouldIgnoreMouseEvents: boolean
  shouldPreventFocus: boolean
  isTouched: boolean
  isPinching: boolean
  unmounted: boolean
  touches: TaplyTouch[]
  mouseUpListener?: (e: MouseEvent) => void
  mouseMoveListener?: (e: MouseEvent) => void
  scrollPos: { top: number; left: number }
  scrollParents: HTMLElement[]
}

const initialCtx = {
  elem: undefined,
  touches: [],
  isTouched: false,
  // Focus is prevented on click when `preventFocusOnTap` if `true`
  // and always prevented on touch
  shouldPreventFocus: false,
  // Ignore mouse events on touching because mousedown happens after touchend
  shouldIgnoreMouseEvents: false,
  unmounted: false, // TODO test
  scrollPos: { top: 0, left: 0 },
  scrollParents: [],
  isPinching: false,
}

interface TaplyState {
  tapState: TapState
}

type TaplyIt = It<TaplyCtx, UseTaplyProps & typeof defaultProps, TaplyState>

interface TaplyHandle {
  focus: () => void
}

export const initialTapState = { isPressed: false, isHovered: false, isFocused: false }

const ENTER_KEYCODE = 13

const makeTouches = (event: TouchEvent, prevTouches: TaplyTouch[]): TaplyTouch[] =>
  Array.from(event.targetTouches).map((touch) => {
    const { clientX: x, clientY: y, identifier } = touch
    const prevTouch = prevTouches.find((t) => t.identifier === identifier)
    if (prevTouch) {
      return { ...prevTouch, x, y, dx: x - prevTouch.x0, dy: y - prevTouch.y0 }
    }
    return { identifier, x, y, x0: x, y0: y, dx: 0, dy: 0 }
  })

const setTapState = (it: TaplyIt, tapState: Partial<TapState>) => {
  if (tapState.isPressed && (it.props.preventFocusOnTap || it.isTouched)) {
    it.shouldPreventFocus = true
    // On desktop we dont want to keep preventing if the focus event not
    // happening for some reason, because `Tab` button should work.
    // On mobile platforms focus event is very unpredictable, so once element
    // is touched, it will keep preventing next focus event whenever it will
    // happen.
    if (!it.isTouched) {
      setTimeout(() => {
        it.shouldPreventFocus = false
      })
    }
  }
  const nextTapState = { ...it.state.tapState, ...tapState }
  it.setState({ tapState: nextTapState })
  if (it.props.onChangeTapState) it.props.onChangeTapState(nextTapState)
}

const removeListeners = (it: TaplyIt) => {
  if (it.mouseUpListener) {
    document.removeEventListener('mouseup', it.mouseUpListener)
    it.mouseUpListener = undefined
  }
  if (it.mouseMoveListener) {
    document.removeEventListener('mousemove', it.mouseMoveListener)
    it.mouseMoveListener = undefined
  }
}

const onMouseUp = (event: MouseEvent, it: TaplyIt) => {
  removeListeners(it)

  if (it.unmounted) return

  let isOnButton
  let elem = event.target
  while (elem) {
    if (elem === it.elem) {
      isOnButton = true
      break
    }
    elem = (elem as HTMLElement).parentElement
  }

  it.touches = []
  setTapState(it, { isPressed: false, isHovered: isOnButton })
  if (it.props.onTapEnd) it.props.onTapEnd(event, it.touches)
}

const onMouseMove = (event: MouseEvent, it: TaplyIt) => {
  if (it.state.tapState.isPressed && it.props.onTapMove) {
    const { clientX: x, clientY: y } = event
    const { x0, y0 } = it.touches[0]
    it.touches = [{ x0, y0, x, y, dx: x - x0, dy: y - y0 }]
    it.props.onTapMove(event, it.touches)
  }
}

const initScrollDetection = (it: TaplyIt) => {
  it.scrollPos = { top: 0, left: 0 }
  it.scrollParents = []
  let node: HTMLElement | null = it.elem!
  while (node) {
    if (node.scrollHeight > node.offsetHeight || node.scrollWidth > node.offsetWidth) {
      it.scrollParents.push(node)
      it.scrollPos.top += node.scrollTop
      it.scrollPos.left += node.scrollLeft
    }
    node = node.parentNode as HTMLElement | null
  }
}

const detectScroll = (it: TaplyIt) => {
  const currentScrollPos = { top: 0, left: 0 }
  it.scrollParents.forEach((elem) => {
    currentScrollPos.top += elem.scrollTop
    currentScrollPos.left += elem.scrollLeft
  })
  return (
    currentScrollPos.top !== it.scrollPos.top ||
    currentScrollPos.left !== it.scrollPos.left
  )
}

const endTouch = (it: TaplyIt, event: TouchEvent) => {
  it.isTouched = false
  setTapState(it, { isHovered: false, isPressed: false })
  if (it.isPinching) {
    if (it.props.onPinchEnd) it.props.onPinchEnd(event, it.touches)
  } else if (it.props.onTapEnd) {
    it.props.onTapEnd(event, it.touches)
  }
}

const handlers = {
  mouseenter(event: MouseEvent, it: TaplyIt) {
    if (it.props.isDisabled) return
    if (it.shouldIgnoreMouseEvents) return
    setTapState(it, { isHovered: true })
  },
  mouseleave(event: MouseEvent, it: TaplyIt) {
    if (it.props.isDisabled) return
    if (it.shouldIgnoreMouseEvents) return
    setTapState(it, { isHovered: false })
  },
  mousedown(event: MouseEvent, it: TaplyIt) {
    if (it.props.isDisabled) return
    if (it.shouldIgnoreMouseEvents) {
      it.shouldIgnoreMouseEvents = false
      return
    }
    if (event.button !== 0) return
    it.mouseUpListener = (e) => onMouseUp(e, it)
    it.mouseMoveListener = (e) => onMouseMove(e, it)
    document.addEventListener('mouseup', it.mouseUpListener)
    document.addEventListener('mousemove', it.mouseMoveListener)
    setTapState(it, { isPressed: true })
    if (it.props.onTapStart) {
      const { clientX: x, clientY: y } = event
      it.touches = [{ x, y, x0: x, y0: y, dx: 0, dy: 0 }]
      it.props.onTapStart(event, it.touches)
    }
  },
  touchstart(event: TouchEvent, it: TaplyIt) {
    if (it.props.isDisabled) return

    it.touches = makeTouches(event, it.touches)
    it.shouldIgnoreMouseEvents = true
    if (event.touches.length === 1) {
      it.isTouched = true
      initScrollDetection(it)
      setTapState(it, { isHovered: true, isPressed: true })

      if (it.props.onTapStart) it.props.onTapStart(event, it.touches)
    } else if (event.touches.length === 2 && it.props.isPinchable) {
      it.isPinching = true
      if (it.props.onTapEnd) it.props.onTapEnd(event, it.touches)
      if (it.props.onPinchStart) it.props.onPinchStart(event, it.touches)
    }
  },
  touchmove(event: TouchEvent, it: TaplyIt) {
    if (it.props.isDisabled) return

    it.touches = makeTouches(event, it.touches)
    if (it.isPinching) {
      if (it.props.onPinchMove) it.props.onPinchMove(event, it.touches)
    } else {
      if (detectScroll(it)) {
        endTouch(it, event)
        return
      }
      if (it.props.onTapMove) it.props.onTapMove(event, it.touches)
    }
  },
  touchend(event: TouchEvent, it: TaplyIt) {
    if (it.props.isDisabled) return

    it.touches = makeTouches(event, it.touches)
    if (event.touches.length === 0) {
      endTouch(it, event)
    } else if (event.touches.length === 1 && it.isPinching) {
      it.isPinching = false
      if (it.props.onPinchEnd) it.props.onPinchEnd(event, it.touches)
      if (it.props.onTapStart) it.props.onTapStart(event, it.touches)
    }
  },
  focus(event: FocusEvent, it: TaplyIt) {
    if (it.props.isDisabled) return
    // When focus somehow happened, but it should not
    if (it.props.shouldSetAttributes && !it.props.isFocusable) return
    if (it.shouldPreventFocus) {
      event.stopPropagation()
      it.shouldPreventFocus = false
    } else {
      setTapState(it, { isFocused: true })
      if (it.props.onFocus) it.props.onFocus(event)
    }
  },
  blur(event: FocusEvent, it: TaplyIt) {
    if (it.props.isDisabled) return
    setTapState(it, { isFocused: false })
    if (it.props.onBlur) it.props.onBlur(event)
  },
  keydown(event: KeyboardEvent, it: TaplyIt) {
    const { onTap, onTapStart, onTapEnd } = it.props
    const { isFocused } = it.state.tapState

    if (isFocused && event.keyCode === ENTER_KEYCODE) {
      setTapState(it, { isPressed: true })
      if (onTapStart) onTapStart(event, it.touches)
      if (onTap) onTap(event)
      setTimeout(() => {
        setTapState(it, { isPressed: false })
        if (onTapEnd) onTapEnd(undefined, it.touches)
      }, 150)
    }
  },
  click(event: MouseEvent, it: TaplyIt) {
    if (it.elem!.tagName === 'BUTTON' && event.detail === 0) return
    if (it.props.isDisabled) return
    if (it.props.onTap) it.props.onTap(event)
  },
}

const setListeners = (it: TaplyIt) => {
  Object.entries(handlers).forEach(([name, handler]) =>
    it.elem!.addEventListener(name, (event) => handler(event as any, it))
  )
}

const setAttributes = (it: TaplyIt) => {
  const { isDisabled, tabIndex, isFocusable } = it.props

  if (isDisabled) it.elem!.setAttribute('disabled', 'disabled')
  else it.elem!.removeAttribute('disabled')

  if (isFocusable && !isDisabled) it.elem!.setAttribute('tabindex', tabIndex.toString())
  else it.elem!.removeAttribute('tabindex')
}

const useTaply = (_props: UseTaplyProps) => {
  const props = useMemo(() => ({ ...defaultProps, ..._props }), [_props])
  const { isDisabled } = props

  const it: TaplyIt = useIt({
    initialState: {
      tapState: initialTapState,
    } as TaplyState,
    initialCtx: () => ({ ...initialCtx }),
    props,
  })

  const [elem, setElem] = useState<HTMLElement | null>(null)

  useEffect(() => {
    if (it.elem !== elem) {
      it.elem = elem || undefined
      if (it.elem! instanceof Element) {
        setListeners(it)
        if (props.shouldSetAttributes) setAttributes(it)
      }
    } else {
      if (props.shouldSetAttributes) setAttributes(it)
    }
  }, [elem, isDisabled])

  useEffect(() => {
    if (isDisabled) {
      setTapState(it, initialTapState)
      removeListeners(it)
      Object.assign(it, {
        shouldIgnoreMouseEvents: false,
        shouldPreventFocus: false,
        isTouched: false,
        isPinching: false,
        touches: [],
      })
    }
  }, [isDisabled])

  return { tapState: it.state.tapState, ref: setElem }
}

const Taply = forwardRef<TaplyHandle, TaplyProps>(
  (props: TaplyProps, ref): React.ReactElement => {
    const { ref: taplyRef, tapState } = useTaply(props)
    const elemRef = useRef<HTMLElement>(null)

    useImperativeHandle(
      ref,
      () => ({
        focus() {
          elemRef.current?.focus()
        },
      }),
      []
    )

    const mergedRef = mergeRefs(taplyRef, elemRef)
    if (typeof props.children === 'function') {
      return props.children(tapState, mergedRef)
    } else {
      return cloneElementWithRef(props.children, { ref: mergedRef })
    }
  }
)

Taply.displayName = 'Taply'

export { Taply, useTaply }
