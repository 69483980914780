import React from 'react'

import { Modal } from 'ui/modal'
import { Button } from 'ui/konsol/button'
import { ReactComponent as CloseIcon } from 'ui/konsol/icons/close.svg'

interface ModalProps extends Omit<React.ComponentProps<typeof Modal>, 'children'> {
  children?: React.ReactNode
}

const modalStyles = () => {
  const container = {
    paddingTop: 50,
    paddingBottom: 50,
  }

  const window = {
    borderRadius: 16,
    background: 'rgb(249,250,251)',
    padding: 24,
    minHeight: 30,
    boxShadow: '0 0 24px rgba(39,46,64,.16)',
  }

  return { container, window }
}

const KonsolModal = (props: ModalProps) => {
  const { children, styles, ...restProps } = props
  return (
    <Modal styles={[modalStyles, styles]} {...restProps}>
      {(close) => (
        <>
          {children}
          <Button
            size="s"
            variant="simple"
            icon={<CloseIcon />}
            title="Закрыть"
            onTap={close}
            style={{
              position: 'absolute',
              top: 24,
              right: 24,
            }}
          />
        </>
      )}
    </Modal>
  )
}

KonsolModal.defaultProps = { width: 800 }

export { KonsolModal as Modal }
