import React, { useMemo, useState } from 'react'

import { Base64 } from 'js-base64'

import { matchPath, useHistory, useLocation } from 'react-router-dom'

import { observer } from 'mobx-react-lite'
import { reaction } from 'mobx'
import { useMediaContext } from 'App/MediaContext'
import { useMobxApi } from 'mobx/mobx'

import {
  Button,
  Col,
  Flex,
  Input,
  Layout,
  LayoutHeading,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Placeholder,
  useStateToast,
} from 'components/ui'

import {
  FilterConfigs,
  FilterMenuGroups,
  MultiActionButton,
  SortFiltersPanel,
} from 'components/FilteredList2'

import { AbilityButton } from 'components/abilityButtons'
import { DocumentList } from './DocumentsList'
import { DocumentsStore } from './DocumentsStore'
import { documentWordForms } from 'components/documents/statuses'
import { PaginationView } from 'components/PaginationView'
import { ReactComponent as DropdownIcon } from '@material-design-icons/svg/round/arrow_drop_down.svg'
import { ReactComponent as ExportIcon } from 'assets/export.svg'
import { ReactComponent as SearchIcon } from '@material-design-icons/svg/round/search.svg'
import { ReactComponent as TriangleIcon } from 'assets/triangle.svg'
import { useUserStore, useSignDialogStore, UserStore } from 'stores/context'
import s from './Documents.module.css'
import useFileDialog from 'utils/useFileDialog'

const parseBase64Json = (state: string) => {
  try {
    return JSON.parse(Base64.decode(state))
  } catch (e) {
    return undefined
  }
}

const makeFilterConfigs = (currentUser: UserStore) => {
  let config: FilterConfigs = {
    kind: {
      title: 'Тип документа',
      kind: 'select',
      options: [
        { value: 'contract', label: 'Договор' },
        { value: 'document', label: 'Документ' },
      ],
    },
    status: {
      title: 'Статус',
      kind: 'select',
      options: [
        { value: 'created', label: 'Не подписан' },
        { value: 'signed_by_legal_entity', label: 'Подписан компанией' },
        { value: 'signed_by_contractor', label: 'Подписан исполнителем' },
        { value: 'signed_by_all', label: 'Подписан всеми' },
      ],
    },
    template: {
      title: 'Шаблон',
      kind: 'select',
      options: [
        ...(currentUser!.company?.data?.document_templates?.items ?? []),
        ...(currentUser!.company?.data?.contract_templates?.items ?? []),
      ].map((template) => ({
        value: template.id.toString(),
        label: template.data.name,
      })),
    },
    created_date: {
      title: 'Дата создания',
      kind: 'date',
    },
    date: {
      title: 'Дата начала действия',
      kind: 'date',
    },
  }

  return config
}

const DocumentsListView = observer(() => {
  let { isMobile } = useMediaContext()
  let currentUser = useUserStore()
  let api = useMobxApi()
  let signDialog = useSignDialogStore()
  let toast = useStateToast()
  let location = useLocation()
  let history = useHistory()

  let filterConfigs = useMemo(() => makeFilterConfigs(currentUser), [currentUser])
  let [store] = useState(() => {
    let match = matchPath(location.pathname, {
      path: `/documents/:state?`,
      exact: true,
    })
    let params = match!.params as any
    let initialState = params.state ? parseBase64Json(params.state) : undefined
    reaction(
      () => store.serializedState,
      (serializedState) => history.push(`/documents/${serializedState}`)
    )
    return new DocumentsStore({
      api,
      signDialog,
      toast,
      initialState,
      filterConfigs,
      currentUser,
    })
  })

  let fileDialog = useFileDialog((file) => store.createFromFile(file), {
    accept: '.csv,.xls,.xlsx',
  })

  let menuGroups = useMemo(() => {
    let groups: FilterMenuGroups = [{ filters: [...Object.keys(filterConfigs)] }]
    return groups
  }, [filterConfigs])

  const menuItems = (
    <MenuItem onClick={() => store.exportToFile()}>Экспортировать в xlsx</MenuItem>
  )

  const menu = (
    <Menu>
      <MenuButton
        as={Button}
        design="normal"
        icon={
          <div className={s.exportIcon}>
            <ExportIcon />
          </div>
        }
        size="s"
        style={{ padding: '4px 12px', transform: 'none' }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          Экспорт <TriangleIcon style={{ marginLeft: 6, flexShrink: 0 }} />
        </div>
      </MenuButton>

      <MenuList>{menuItems}</MenuList>
    </Menu>
  )

  return (
    <>
      <Col gap={isMobile ? 'var(--gap-s)' : 'var(--gap-m)'}>
        <LayoutHeading>
          <Flex gap="1rem" justify="space-between" align="center">
            <div>Документы</div>
            <AbilityButton
              ability={currentUser.hasAbility('documents.create')}
              onTap={fileDialog.open}
              isDisabled={!store.paginated.fetchedValue}
              isLoading={store.createState.state === 'pending'}
              size={isMobile ? 's' : 'm'}
            >
              {isMobile ? 'Загрузить' : 'Загрузить из файла'}
            </AbilityButton>
            {fileDialog.render()}
          </Flex>
        </LayoutHeading>
        <Input
          icon={<SearchIcon />}
          value={store.search}
          onChange={(value) => {
            store.search = value
          }}
          style={{ maxWidth: 600 }}
          placeholder="Поиск по ФИО, телефону исполнителя"
        />
        <SortFiltersPanel
          wordForms={documentWordForms}
          sticky
          stickyActions={menu}
          filtersStore={store.filters}
          selectedStore={store.selected}
          menuGroups={menuGroups}
          actions={
            <>
              <MultiActionButton action="sign" store={store.selected}>
                Подписать
              </MultiActionButton>
              <MultiActionButton store={store.selected} action="archive">
                Перенести в архив
              </MultiActionButton>
              <Menu>
                <MenuButton
                  as={Button}
                  design={isMobile ? 'text' : 'normal'}
                  isDisabled={store.selected.isFetching()}
                  size={isMobile ? 'xs' : 's'}
                  style={{ transform: 'none' }}
                >
                  <Flex gap=".5rem" align="center">
                    Ещё
                    <DropdownIcon
                      style={{ marginRight: '-1rem', fill: 'var(--color-icon)' }}
                    />
                  </Flex>
                </MenuButton>

                <MenuList>
                  <MultiActionButton
                    action="delete"
                    as="menuItem"
                    store={store.selected}
                    style={{ color: 'var(--color-red)' }}
                  >
                    Удалить {!isMobile && 'неподписанные'}
                  </MultiActionButton>
                </MenuList>
              </Menu>
            </>
          }
        />
        <PaginationView
          store={store.paginated}
          render={(collection) =>
            collection.isEmpty ? (
              <Placeholder>Нет документов</Placeholder>
            ) : (
              <DocumentList store={store} documents={collection} />
            )
          }
          onChangePage={(page) => {
            store.paginated.setPage(page)
          }}
        />
      </Col>
      {store.render()}
    </>
  )
})

const DocumentsPage = observer(() => {
  return (
    <Layout smallPaddingTop>
      <DocumentsListView />
    </Layout>
  )
})

export default DocumentsPage
