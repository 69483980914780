import React from 'react'
//@ts-ignore
import { useStyles } from 'floral'

import { useMediaContext } from 'App/MediaContext'
import { FloralProps } from './floral.types'

interface FormRowProps extends FloralProps {
  label?: string
  labelWidth?: number | string
  children: any
  size: 'm' | 'l'
  error?: React.ReactNode
  caption?: React.ReactNode
}

const formRowStyles = () => {
  let root = { width: '100%' }
  let label: React.CSSProperties = {
    fontSize: '1rem',
    color: 'var(--color-secondary)',
    letterSpacing: '0.02rem',
  }
  let input = { width: '100%' }
  let error = { fontSize: 14, color: 'var(--color-red)', paddingTop: '4px' }
  let caption = { fontSize: 14, color: 'var(--color-secondary)', paddingTop: '4px' }
  return { root, label, input, error, caption }
}

const FormRow = (props: FormRowProps) => {
  let { isMobile } = useMediaContext()
  let { label, children, error, caption } = props
  let styles = useStyles(formRowStyles, [props, isMobile])
  return (
    <div style={styles.root}>
      {label && <div style={styles.label}>{label}</div>}
      <div style={styles.input}>
        {children}
        {error ? (
          <div style={styles.error}>{error}</div>
        ) : (
          caption && <div style={styles.caption}>{caption}</div>
        )}
      </div>
    </div>
  )
}

FormRow.defaultProps = {
  size: 'm',
}

export default FormRow
