import { createContext, useContext } from 'react'

import UserStore from './UserStore'
import SignDialogStore from './SignDialogStore'
import CompanyStore, { ICompanyStore } from './CompanyStore'

export { UserStore }
export const UserStoreContext = createContext<UserStore | null>(null)
export const useUserStore = () => useContext(UserStoreContext)!

export { SignDialogStore }
export const SignDialogContext = createContext<SignDialogStore>(new SignDialogStore())
export const useSignDialogStore = () => useContext(SignDialogContext)

export { CompanyStore }
export const CompanyStoreContext = createContext<ICompanyStore>(new CompanyStore());
export const useCompanyStore = () => useContext(CompanyStoreContext);
