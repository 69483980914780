import React from 'react'

import { Checkbox } from 'ui'

import { ScenarioProjectTaskDocument } from 'entities'

import { parseDate, formatTime } from 'utils/datetime'

import { ListItem, ListCell } from 'components/ui'
import { Button, Flex, Link, Menu, MenuItem } from 'ui'

import { ReactComponent as MoreIcon } from 'assets/more-vert.svg'
import useTaskListStore from '../../hooks/useTaskListStore'
import useProjectItemStore from '../../hooks/useProjectItemStore'
import { observer } from 'mobx-react-lite'
import UnitPopover from '../UnitPopover'
import { Badge } from 'components/Badge'
import { formatAmount } from 'utils/amount'

interface ITaskListRowProps {
  item: ScenarioProjectTaskDocument
  onSelectChange: () => void
  isSelected: boolean
}

export const TaskListRow = observer(
  ({ item, onSelectChange, isSelected }: ITaskListRowProps) => {
    const listStore = useTaskListStore()
    const itemStore = useProjectItemStore()

    const isRemoveActionDisabled = !item.actions?.includes('delete_draft')

    let menu = () => (
      <>
        <MenuItem value="duplicate">Создать копию</MenuItem>
        <MenuItem
          isDisabled={isRemoveActionDisabled}
          value="delete_draft"
          style={{
            opacity: isRemoveActionDisabled ? 0.5 : 1.0,
            color: 'var(--color-red)',
          }}
        >
          Удалить
        </MenuItem>
      </>
    )

    const statusColorMap: Record<string, any> = {
      draft: 'gray',
      created: 'gray',
      suggested: 'purple',
      declined: 'red',
      cancelled: 'red',
      in_progress: 'blue',
      not_completed: 'red',
      completed: 'green',
    }

    const statusLabelMap: Record<string, any> = {
      draft: 'Черновик',
      created: 'Черновик',
      suggested: 'Предложено',
      declined: 'Отказался',
      cancelled: 'Отменено',
      in_progress: 'В работе',
      not_completed: 'Не выполнено',
      completed: 'Выполнено',
    }

    const dateFormat = `DD.MM.YYYY`

    const dateLabel = `${
      item.since_date ? parseDate(item.since_date).format(dateFormat) : '...'
    } - ${item.upto_date ? parseDate(item.upto_date).format(dateFormat) : '...'}`
    const timeLabel = `с ${formatTime(item.since_time)} по ${formatTime(item.upto_time)}`

    const handleClick = async () => {
      if (itemStore.saveTask) {
        await itemStore.saveTask
      }
      listStore.pageProps.history.push(
        `/projects/${itemStore.projectId}/tasks/${item.id}`
      )
    }

    const handleMenuSelect = (action?: string) => {
      if (action === 'delete_draft') {
        listStore.removeById(item.id)
      } else if (action === 'duplicate') {
        listStore.duplicateById(item.id)
      }
    }

    return (
      <ListItem onClick={handleClick} style={{ alignItems: 'stretch' }} padding={false}>
        <ListCell
          onClick={(e) => e.stopPropagation()}
          style={{ maxWidth: 35 }}
          col="checkbox"
        >
          <Checkbox
            style={{ marginLeft: 5, marginTop: -5 }}
            onChange={onSelectChange}
            value={isSelected}
          />
        </ListCell>
        <ListCell col="contractor">
          <Flex direction="col" gap="1px">
            <span>{item.contractor.name}</span>
            {item.contractor.mobile_phone && (
              <span style={{ color: 'gray' }}>{item.contractor.mobile_phone}</span>
            )}
          </Flex>
        </ListCell>
        <ListCell col="units">
          <UnitPopover item={item} />
        </ListCell>
        <ListCell col="dateRange">
          <Flex direction="col" gap="1px">
            <span>{dateLabel}</span>
            <span style={{ color: 'gray' }}>{timeLabel}</span>
          </Flex>
        </ListCell>
        <ListCell col="status">
          <Badge color={statusColorMap[item.status] || 'gray'}>
            {statusLabelMap[item.status] || item.status}
          </Badge>
        </ListCell>
        <ListCell onClick={(e) => e.stopPropagation()} col="act">
          {item.act?.id ? (
            <Link href={`/acts/${item.act?.id}`}>Акт</Link>
          ) : (
            <span style={{ opacity: 0.5 }}>Не сформирован</span>
          )}
        </ListCell>
        <ListCell
          style={{
            display: 'flex',
            justifyContent: 'stretch',
          }}
          col="amount"
        >
          <span style={{ flex: 1 }}>{formatAmount(item.amount, 2, ',')}</span>
          <Flex align="start" justify="center">
            <Menu onSelect={handleMenuSelect} matchWidth menu={menu}>
              {(ref: any, { open }: any) => (
                <Button
                  ref={ref}
                  size="s"
                  variant="text"
                  onClick={(e: any) => {
                    e.stopPropagation()
                    open(e)
                  }}
                  style={{
                    width: 30,
                    marginRight: 5,
                    marginTop: -5,
                  }}
                >
                  <MoreIcon
                    fill="black"
                    style={{
                      transform: 'scale(0.5)',
                    }}
                  />
                </Button>
              )}
            </Menu>
          </Flex>
        </ListCell>
      </ListItem>
    )
  }
)

export default TaskListRow
