import React, { forwardRef } from 'react'
import cx from 'clsx'

import { useStyles, StyleProps } from 'ui/styled'

import css from './styles.module.css'

export type HeadingLevel = 1 | 2 | 3 | 4

export interface HeadingProps extends StyleProps<[HeadingProps]> {
  children: React.ReactNode
  level?: 1 | 2 | 3 | 4
  className?: string
  as?: string
}

const defaultProps = { level: 1 as HeadingLevel }

const defaultElems = {
  '1': 'h1',
  '2': 'h2',
  '3': 'h3',
  '4': 'h4',
}

const Heading = forwardRef<HTMLElement, HeadingProps>((props: HeadingProps) => {
  const { children, level } = props as HeadingProps & typeof defaultProps
  const styles = useStyles(undefined, [props])
  const elem = props.as || defaultElems[level]
  return React.createElement(
    elem,
    {
      className: cx(css.root, css[`h${level}`], props.className),
      style: styles.root,
    },
    children
  )
})

Heading.defaultProps = defaultProps

const H1 = forwardRef<HTMLElement, HeadingProps>(
  (props: Omit<HeadingProps, 'level'>, ref) => <Heading level={1} {...props} ref={ref} />
)

const H2 = forwardRef<HTMLElement, HeadingProps>(
  (props: Omit<HeadingProps, 'level'>, ref) => <Heading level={2} {...props} ref={ref} />
)

const H3 = forwardRef<HTMLElement, HeadingProps>(
  (props: Omit<HeadingProps, 'level'>, ref) => <Heading level={3} {...props} ref={ref} />
)

const H4 = forwardRef<HTMLElement, HeadingProps>(
  (props: Omit<HeadingProps, 'level'>, ref) => <Heading level={4} {...props} ref={ref} />
)

export { Heading, H1, H2, H3, H4 }
