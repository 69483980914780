import React from 'react'
import { ReactComponent as NoIssuesIcon } from './assets/no-issues.svg'
import s from './styles.module.css'

const NoIssues = () => (
  <div className={s.root}>
    <NoIssuesIcon />
  </div>
)

export default NoIssues
