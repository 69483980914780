import React, { useMemo, useState } from 'react'
import { Link, useHistory, useLocation, matchPath } from 'react-router-dom'
import { makeAutoObservable, reaction, comparer } from 'mobx'
import { observer } from 'mobx-react-lite'
import { Tooltip } from '@chakra-ui/tooltip'
import { cloneDeep, merge } from 'lodash'
import { Base64 } from 'js-base64'

import { ReactComponent as SearchIcon } from '@material-design-icons/svg/round/search.svg'
import { ReactComponent as ChevronLeftIcon } from '@material-design-icons/svg/round/chevron_left.svg'
import { ReactComponent as MoreIcon } from '@material-design-icons/svg/round/more_horiz.svg'
import { ReactComponent as FilterIcon } from '@material-design-icons/svg/round/filter_alt.svg'

import { useMediaContext } from 'App/MediaContext'
import { useMobxApi, MobxApi, Entity, Collection, ActionState } from 'mobx/mobx'
import {
  PaginationEntity,
  CustomFieldEntity,
  ContractorEntity,
  SegmentEntity,
  FiltersState,
  OnboardingScenarioEntity,
} from 'entities'
import { useUserStore, UserStore } from 'stores/context'

import {
  Layout,
  LayoutHeading,
  Placeholder,
  List,
  ListItem,
  ListCell,
  Col,
  Input,
  ActionStateView,
  Flex,
  BaseButton,
  useStateToast,
} from 'components/ui'
import { SortableListHeader } from 'components/FilteredList'

import {
  PaginatedStore,
  PaginatedView,
  FiltersStore,
  FiltersPanel,
  FilterMenuGroups,
  FilterConfig,
  FilterConfigs,
  SegmentsStore,
  SegmentSelector,
} from 'components/FilteredList2'
import { FilterButton, LinkButton } from 'components/FilteredList2/components'
import { Menu, MenuItem } from 'components/FilteredList2/ui'
import exportToFile from 'utils/exportToFile'

import { formatPhone } from 'utils/formatting'
import { printDate } from 'utils/datetime'

import { contractorKindsMap } from './maps'

const ANALYTIC_FIELDS: Record<
  string,
  {
    group_label: string
    enabled_label: string
    disabled_label: string
    unknown_label: string
  }
> = {
  mass_registration: {
    group_label: 'Массовая регистрация',
    enabled_label: 'Менее 7 дней между датами',
    disabled_label: 'Более 7 дней между датами',
    unknown_label: 'Нет данных',
  },
  only_source_of_income: {
    group_label: 'Единственный источник дохода',
    enabled_label: 'Да',
    disabled_label: 'Нет',
    unknown_label: 'Нет данных',
  },
  two_payments_per_month: {
    group_label: 'Периодичность выплат',
    enabled_label: 'Есть платежи два раза в месяц',
    disabled_label: 'Нет платежей два раза в месяц',
    unknown_label: 'Нет данных',
  },
  unique_payments: {
    group_label: 'Одинаковые платежи',
    enabled_label: 'Есть',
    disabled_label: 'Нет',
    unknown_label: 'Нет данных',
  },
  working_duration: {
    group_label: 'Продолжительность сотрудничества',
    enabled_label: 'Более 3-х месяцев',
    disabled_label: 'Менее 3-х месяцев',
    unknown_label: 'Нет данных',
  },
}

const ContractorListItem = observer(
  ({
    contractor,
    showCitizenship,
  }: {
    contractor: Entity<ContractorEntity>
    showCitizenship: boolean
  }) => {
    let { isMobile } = useMediaContext()
    let {
      full_name,
      mobile_phone,
      added_at,
      onboarding_scenario,
      status,
      problem_messages,
      citizenship,
    } = contractor.data
    let [justCopied, setJustCopied] = useState(false)
    let copyPhone = (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation()
      e.preventDefault()
      navigator.clipboard.writeText(formatPhone(mobile_phone))
      setJustCopied(true)
      setTimeout(() => setJustCopied(false), 1000)
    }

    let nameCol = (
      <Col align="start">
        <div>{full_name}</div>
        {mobile_phone && (
          <Tooltip
            label={justCopied ? 'Номер скопирован' : 'Скопировать номер'}
            placement="bottom"
          >
            <div
              style={{ color: 'var(--color-secondary)', marginTop: '4px' }}
              onClick={copyPhone}
            >
              {formatPhone(mobile_phone)}
            </div>
          </Tooltip>
        )}
      </Col>
    )

    let statusElem
    if (status === 'ready_to_work') {
      statusElem = <div style={{ color: 'var(--color-green)' }}>Готов работать</div>
    } else if (status === 'is_suspended') {
      statusElem = (
        <div style={{ color: 'var(--color-secondary)' }}>Работа приостановлена</div>
      )
    } else if (status === 'has_problems') {
      statusElem = (
        <Col gap=".5rem" style={{ color: 'var(--color-red)' }}>
          {problem_messages!.map((text) => (
            <div>{text}</div>
          ))}
        </Col>
      )
    }

    return (
      <ListItem
        as={Link}
        to={{
          pathname: `/contractors/${contractor.id}`,
          state: { url: '/contractor', goBackLink: '/contractors' },
        }}
        style={{ alignItems: 'flex-start' }}
        wrap={isMobile}
      >
        <ListCell row={isMobile} col="name">
          {nameCol}
        </ListCell>
        <ListCell row={isMobile} col="kind">
          {contractorKindsMap[contractor.data.kind]}
        </ListCell>
        <ListCell row={isMobile} col="scenario">
          {onboarding_scenario?.title}
        </ListCell>
        <ListCell
          row={isMobile}
          col="added_at"
          style={{ color: 'var(--color-secondary)' }}
        >
          {printDate(added_at, { mode: 'full' })}
        </ListCell>
        {showCitizenship && (
          <ListCell row={isMobile} col="citizenship">
            {isMobile && citizenship && <>Гражданство: </>}
            {citizenship}
          </ListCell>
        )}
        <ListCell row={isMobile} col="status">
          {statusElem}
        </ListCell>
      </ListItem>
    )
  }
)

const statusFilters: FilterConfigs = {
  status: {
    title: 'Статус',
    kind: 'select',
    options: [
      // { value: 'all', label: 'Все' },
      { value: 'ready', label: 'Готовы работать' },
      { value: 'problems', label: 'Есть проблемы' },
      {
        value: 'has_unsigned_documents',
        label: 'Есть неподписанный договор или документы',
      },
      { value: 'has_no_unsigned_documents', label: 'Все договоры и документы подписаны' },
      { value: 'suspended', label: 'Работа приостановлена' },
      { value: 'all_with_suspended', label: 'Все, включая приостановленных' },
      { value: 'blacklisted', label: 'Бывший работник' },
    ],
  },
  kind: {
    title: 'Тип занятости',
    kind: 'multiselect',
    options: [
      { value: 'individual_entrepreneur', label: 'ИП' },
      { value: 'self_employed', label: 'Самозанятый' },
      { value: 'gph', label: 'ГПХ' },
      { value: 'non_resident', label: 'Нерезидент РФ' },
    ],
  },
  added_at: {
    title: 'Дата добавления',
    kind: 'date',
  },
  contract_status: {
    title: 'Договор',
    kind: 'select',
    options: [
      { value: 'signed', label: 'Подписан' },
      { value: 'not_signed_by_company', label: 'Нe подписан компанией' },
      { value: 'not_signed_by_contractor', label: 'Нe подписан исполнителем' },
      { value: 'not_signed', label: 'Не подписан' },
    ],
  },
}

const makeContractorsFilters = ({
  // onboardingScenarios,
  customFields,
  currentUser,
}: {
  onboardingScenarios: Collection<OnboardingScenarioEntity>
  customFields: Entity<CustomFieldEntity>[]
  currentUser: UserStore
}) => {
  const filters = { ...statusFilters }
  // filters.onboarding_scenario = {
  //   title: 'Сценарий онбординга',
  //   kind: 'select',
  //   options: onboardingScenarios.items.map((s) => ({
  //     value: String(s.data.id),
  //     label: `${s.data.title}${s.data.archived ? ' (В архиве)' : ''}`,
  //   })),
  // }

  if (currentUser.company!.data.features.medical_examination_filter_show) {
    filters.medical_examination = {
      title: 'Медосвидетельствование',
      kind: 'checkbox',
    }
  }

  if (currentUser.company!.data.features.citizenship_filter_show) {
    filters.citizenship = {
      title: 'Гражданство',
      kind: 'multiselect',
      options: currentUser.company!.data.citizenship_filter_items || [],
    }
  }

  if (currentUser.company!.data.features.npd_new_filter_show) {
    filters.npd_new = {
      title: 'Статус НПД',
      kind: 'select',
      options: [
        { value: 'new', label: 'Новый' },
        { value: 'not_new', label: 'Не новый' },
      ],
    }
  }

  if (currentUser.hasFeature('contractors_risk_profiles')) {
    Object.entries(ANALYTIC_FIELDS).forEach(
      ([key, { disabled_label, enabled_label, unknown_label, group_label }]) => {
        filters[key] = {
          title: group_label,
          kind: 'select',
          options: [
            {
              value: '0',
              label: disabled_label,
            },
            {
              value: '1',
              label: enabled_label,
            },
            {
              value: '',
              label: unknown_label,
            },
          ],
        }
      }
    )
  }

  // Object.assign(filters, personalDataFilters)
  customFields.forEach((item) => {
    // text number chekbox select
    let { id, name, kind, options } = item.data
    let config: FilterConfig

    if (kind === 'dropdown') {
      config = {
        title: name,
        kind: 'multiselect',
        options: options.items.map((o) => ({ value: o.id, label: o.data.name })),
        withNotFilled: true,
      }
    } else {
      config = { title: name, kind, withNotFilled: true }
    }
    filters[`cf_${id}`] = config
  })

  return filters
}

interface ContractorsStoreProps {
  api: MobxApi
  currentUser: UserStore
  customFields: Collection<CustomFieldEntity>
  segments: Collection<SegmentEntity>
  toast: ReturnType<typeof useStateToast>
  onboardingScenarios: Collection<OnboardingScenarioEntity>
  initialSegment?: Entity<SegmentEntity>
  initialState?: any
}

const parseBase64Json = (state: string) => {
  try {
    return JSON.parse(Base64.decode(state))
  } catch (e) {
    return undefined
  }
}

const defaultSorting = { col: 'added_at', direction: 'desc' }

class ContractorsStore {
  constructor({
    api,
    currentUser,
    customFields,
    segments,
    toast,
    initialSegment,
    initialState,
    onboardingScenarios,
  }: ContractorsStoreProps) {
    this.api = api
    this.toast = toast

    this.paginated = new PaginatedStore<ContractorEntity>(
      ({ page, abortSignal }) =>
        api.fetch({
          type: 'contractors/page',
          url: `contractors/filter`,
          payload: { ...this.query, page },
          options: { signal: abortSignal, method: 'POST' },
        }) as ActionState<Entity<PaginationEntity<ContractorEntity>>>
    )

    this.filters = new FiltersStore({
      configs: makeContractorsFilters({
        customFields: customFields.items,
        onboardingScenarios,
        currentUser,
      }),
      toast,
      initialState,
    })

    this.segments = new SegmentsStore({
      segments,
      toast,
      initialSegment,
      segmentCreateUrl: 'segments/contractors',
      onSelect: (segment) => this.filters.setState(segment?.data.filters || []),
    })

    reaction(
      () => this.query,
      () => this.paginated.setPage(1),
      { fireImmediately: true, equals: comparer.structural }
    )

    reaction(
      () => this.search,
      (search) => {
        this.delayedSearch = search
      },
      { delay: 555 }
    )

    makeAutoObservable(this)
  }

  toast: ReturnType<typeof useStateToast>
  api: MobxApi
  search = ''
  delayedSearch = ''
  sorting: any = defaultSorting

  paginated: PaginatedStore<ContractorEntity>
  filters: FiltersStore
  segments: SegmentsStore

  get isModified() {
    return !comparer.structural(
      this.segments.segment?.data.filters ?? [],
      this.filters.state
    )
  }

  get serializedState() {
    let segment = this.segments.segment ? `segment/${this.segments.segment.id}/` : ''
    let state = this.isModified ? Base64.encode(JSON.stringify(this.filters.state)) : ''
    return segment + state
  }

  get query() {
    return {
      filters: cloneDeep(this.filters.state), // clone needed to observe deep changes
      search: this.delayedSearch,
      sorting: this.sorting,
    }
  }

  exportToFile(format: string) {
    exportToFile({
      url: 'contractors',
      format,
      filters: this.filters.state,
      toast: this.toast,
      filename: 'konsol_export_contractors.xslx',
    })
  }

  saveState(state: FiltersState) {
    this.segments.openSaveSegmentDialog(state)
  }

  reset() {
    this.segments.resetSegment()
    this.filters.setState([])
  }
}

interface ContractorListProps {
  contractors: Collection<ContractorEntity>
  store: ContractorsStore
  showCitizenship?: boolean
}

const ContractorList = observer(
  ({ contractors, store, showCitizenship = false }: ContractorListProps) => {
    let cols = {
      name: {},
      kind: { width: 140 },
      // scenario: { width: 190 },
      added_at: { width: 160, style: { marginRight: '1.5rem' } },
      citizenship: { width: 120 },
      status: { width: 220 },
    }

    let sortableCols: { [key: string]: any } = merge(
      {
        name: { title: 'Имя', sorting: 'name' },
        kind: { title: 'Тип занятости', sorting: 'kind' },
        scenario: { title: 'Сценарий онбординга', sorting: 'scenario' },
        added_at: { title: 'Дата добавления', sorting: 'added_at' },
      },
      showCitizenship && {
        citizenship: { title: 'Гражданство', sorting: 'citizenship' },
      },
      { status: { title: 'Статус' } }
    )

    return (
      <List cols={cols}>
        <SortableListHeader
          sorting={store.sorting}
          onChange={(sorting) => {
            store.sorting = sorting
          }}
          cols={sortableCols}
        />
        {contractors.items.map((item) => (
          <ContractorListItem contractor={item} showCitizenship={showCitizenship} />
        ))}
      </List>
    )
  }
)

const linkButtonStyles = (props: any, { isHovered }: any) => ({
  root: {
    color: isHovered ? 'var(--color-active)' : 'var(--color-secondary)',
    fill: isHovered ? 'var(--color-active)' : 'var(--color-secondary)',
    cursor: 'pointer',
    borderRadius: '.5rem',
    textAlign: 'start',
  },
})

interface ContractorListViewProps {
  customFields: Collection<CustomFieldEntity>
  segments: Collection<SegmentEntity>
  onboardingScenarios: Collection<OnboardingScenarioEntity>
}

const ContractorListView = observer((props: ContractorListViewProps) => {
  let { customFields, segments, onboardingScenarios } = props

  let history = useHistory()
  let location = useLocation()
  let currentUser = useUserStore()
  let api = useMobxApi()
  let toast = useStateToast()
  let store = useMemo(() => {
    let match = matchPath(location.pathname, {
      path: ['/contractors/segment/:segment/:state?', '/contractors/:state?'],
      exact: true,
    })
    let params = match!.params as any
    let initialSegment = params.segment ? segments.map[params.segment] : undefined
    let initialState
    if (params.state) {
      initialState = parseBase64Json(params.state)
    } else if (initialSegment) {
      initialState = initialSegment.data.filters
    }
    let store = new ContractorsStore({
      api,
      currentUser,
      customFields,
      segments,
      toast,
      initialSegment,
      initialState,
      onboardingScenarios,
    })
    reaction(
      () => store.serializedState,
      (serializedState) => history.push(`/contractors/${serializedState}`)
    )
    return store
  }, [])

  let segment = store.segments.segment

  let resetButton = segment && (
    <BaseButton
      styles={linkButtonStyles}
      style={{ alignSelf: 'start' }}
      onTap={() => store.reset()}
    >
      <Flex align="center" justify="space-between">
        <ChevronLeftIcon />
        <div>Все исполнители</div>
      </Flex>
    </BaseButton>
  )

  let heading = (
    <Flex
      gap="1rem"
      justify="space-between"
      align="start"
      style={{ marginBottom: '.5rem' }}
    >
      <Col gap=".25rem">
        {resetButton}
        <LayoutHeading>
          <Flex gap="1rem">
            {segment ? <div>{segment.data.title}</div> : <div>Исполнители</div>}
            {store.paginated.fetchState.state === 'fulfilled' &&
              store.paginated.fetchedValue && (
                <div style={{ color: 'var(--color-secondary)' }}>
                  {store.paginated.fetchedValue.data.total_count}
                </div>
              )}
          </Flex>
        </LayoutHeading>
      </Col>
      <Flex gap=".5rem">
        <SegmentSelector store={store.segments} />
        <Menu
          menu={() => (
            <>
              <MenuItem value="xlsx">Экспортировать в xlsx</MenuItem>
            </>
          )}
          onSelect={(format: string) => store.exportToFile(format)}
          placement={{ align: 'end', offset: 5 }}
        >
          {(ref: any, { open }: any) => (
            <FilterButton ref={ref} onTap={open} style={{ minWidth: 0 }}>
              <MoreIcon />
            </FilterButton>
          )}
        </Menu>
      </Flex>
    </Flex>
  )

  let searchInput = (
    <Input
      icon={<SearchIcon />}
      value={store.search}
      onChange={(value) => {
        store.search = value
      }}
      style={{ maxWidth: 600 }}
      placeholder="Поиск по ФИО, телефону"
      isClearable={true}
    />
  )

  let menuGroups = useMemo(() => {
    // let filters = [...Object.keys(statusFilters), 'onboarding_scenario']
    let filters = [...Object.keys(statusFilters)]

    if (currentUser.company!.data.features.medical_examination_filter_show) {
      filters.push('medical_examination')
    }

    if (currentUser.company!.data.features.citizenship_filter_show) {
      filters.push('citizenship')
    }

    if (currentUser.company!.data.features.npd_new_filter_show) {
      filters.push('npd_new')
    }

    let groups: FilterMenuGroups = [
      { filters },
      // { header: 'Персональные данные', filters: Object.keys(personalDataFilters) },
    ]

    if (currentUser.hasFeature('contractors_risk_profiles')) {
      groups.push({
        header: 'Аналитика рисков',
        aditional: true,
        filters: Object.keys(ANALYTIC_FIELDS),
      })
    }

    if (!customFields.isEmpty) {
      groups.push({
        header: 'Дополнительные поля',
        aditional: true,
        filters: customFields.items.map((f) => `cf_${f.id}`),
      })
    }

    return groups
  }, [])

  let showSaveButton =
    currentUser.hasAbility('segments.create') &&
    store.filters.state.length > 0 &&
    store.isModified
  let saveButton = showSaveButton && (
    <LinkButton onTap={() => store.segments.openSaveSegmentDialog(store.filters.state)}>
      <Flex gap=".15rem" align="center">
        <FilterIcon />
        <div>Сохранить сегмент</div>
      </Flex>
    </LinkButton>
  )
  let filtersPanel = (
    <FiltersPanel store={store.filters} menuGroups={menuGroups} buttons={saveButton} />
  )

  let head = (
    <>
      {heading}
      {searchInput}
      {filtersPanel}
    </>
  )

  return (
    <Col gap="var(--gap-m)">
      {head}
      <PaginatedView
        store={store.paginated}
        render={(collection) =>
          collection.isEmpty ? (
            <Placeholder>Нет исполнителей</Placeholder>
          ) : (
            <ContractorList
              store={store}
              contractors={collection}
              showCitizenship={currentUser.company!.data.features.citizenship_col_show}
            />
          )
        }
        onChangePage={(page) => store.paginated.setPage(page)}
      />
      {store.segments.render()}
    </Col>
  )
})

const ContractorListPage = observer(() => {
  let mobxApi = useMobxApi()
  let fetchState = useMemo(
    () => [
      mobxApi.fetch({ type: 'contractors/custom_fields' }),
      mobxApi.fetch({ type: 'segments', url: 'segments/contractors' }),
      mobxApi.fetch({ type: 'onboarding_scenarios' }),
    ],
    []
  )
  return (
    <Layout smallPaddingTop={true}>
      <ActionStateView state={fetchState}>
        {([customFields, segments, onboardingScenarios]) => (
          <ContractorListView
            customFields={customFields}
            segments={segments}
            onboardingScenarios={onboardingScenarios}
          />
        )}
      </ActionStateView>
    </Layout>
  )
})

export default ContractorListPage
