import { useState, useLayoutEffect, useEffect, useRef } from 'react'
import { ResizeObserver } from '@juggle/resize-observer'

interface Rect {
  width: number
  height: number
}

const initialRect = { width: 0, height: 0 }

const useMeasure = (isEnabled: boolean): [(elem: Element | null) => void, Rect] => {
  const [elem, setElem] = useState<Element | null>(null)
  const [rect, setRect] = useState<Rect>(initialRect)

  const observerRef = useRef<ResizeObserver | undefined>(undefined)
  useEffect(() => {
    if (!isEnabled) return
    observerRef.current = new ResizeObserver((entries) => {
      if (entries[0]) {
        const { width, height } = entries[0].target.getBoundingClientRect()
        setRect({ width, height })
      }
    })
  }, [isEnabled])

  useLayoutEffect(() => {
    if (!elem) return
    observerRef.current?.observe(elem)
    return () => observerRef.current?.disconnect()
  }, [elem])

  return [setElem, rect]
}

export default useMeasure
