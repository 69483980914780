import React, { useMemo, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { makeAutoObservable, reaction } from 'mobx'
import { omit } from 'lodash'
import { useHistory } from 'react-router-dom'
// @ts-ignore
import { FormStore } from 'shadowform'

import UserStore from 'stores/UserStore'
import { useUserStore } from 'stores/context'
import {
  useMobxApi,
  MobxApi,
  Collection,
  Entity,
  makeInitialActionState,
  initialActionState,
  Data,
} from 'mobx/mobx'
import {
  ContractorEntity,
  DocumentEntity,
  TaskEntity,
  ProjectEntity,
  SubtaskTemplateEntity,
} from 'entities'

import { useMediaContext } from 'App/MediaContext'
import type { Subtask, Subtasks } from 'components/SubtaskList/types'
import SubtaskList from 'components/SubtaskList'
import ContractorSelector from 'components/ContractorSelector'
import {
  Button,
  Layout,
  BackLink,
  Input,
  SelectInput,
  Field,
  AttachmentsStore,
  AttachmentsZone,
  DropShade,
  Flex,
  Col,
  useStateToast,
  DatePicker,
  LoadingPlaceholder,
  ActionStateView,
} from 'components/ui'
import useDropZone from 'utils/useDropZone'
import { serializeDate } from 'utils/datetime'

import { ReactComponent as UserGreyIcon } from 'assets/user-grey.svg'
import { ReactComponent as CompanyGreyIcon } from 'assets/company-grey.svg'
import { ReactComponent as ProjectGreyIcon } from 'assets/project-grey.svg'
import { ReactComponent as DateGreyIcon } from 'assets/date-grey.svg'
import NumberInput from 'components/NumberInput'

interface TaskFormStoreOptions {
  kind: 'task' | 'act'
  contractors?: Collection<ContractorEntity>
  projects?: Collection<ProjectEntity>
  templates?: Collection<SubtaskTemplateEntity>
  draft?: Entity<TaskEntity>
  initialValues?: any
}

interface TaskFormContext {
  api: MobxApi
  history: ReturnType<typeof useHistory>
  currentUser: UserStore
  toast: any
}

class TaskFormStore {
  ctx: TaskFormContext
  kind: 'task' | 'act'
  contractors?: Collection<ContractorEntity>
  projects?: Collection<ProjectEntity>
  templates?: Collection<SubtaskTemplateEntity>
  draft?: Entity<TaskEntity>
  attachments: AttachmentsStore
  form: FormStore

  constructor(
    {
      kind,
      draft,
      contractors,
      projects,
      templates,
      initialValues,
    }: TaskFormStoreOptions,
    ctx: TaskFormContext
  ) {
    this.ctx = ctx
    this.kind = kind
    this.draft = draft
    this.contractors = contractors
    this.projects = projects
    this.templates = templates
    this.attachments = new AttachmentsStore(
      this.ctx.api,
      draft ? draft.data.task!.attachments : undefined
    )

    let formInitialValues = {
      title: '',
      description: '',
      subtasks: [],
      ...initialValues,
    }
    if (draft) {
      Object.assign(formInitialValues, {
        title: draft.data.title,
        description: draft.data.task!.description,
        subtasks: draft.data.task!.subtasks,
        contractor: draft.data.contractor?.id,
        contract: draft.data.contract?.id,
        project: draft.data.projects?.items[0]?.id,
        period:
          draft.data.since && draft.data.upto
            ? [new Date(draft.data.since), new Date(draft.data.upto)]
            : undefined,
      })
    }

    let fields: { [key: string]: any } = {
      title: {
        isRequired: true,
        requiredError: 'Название не задано',
      },
      description: {},
      //contract: {
      //  isRequired: true,
      //  requiredError: 'Договор не выбран',
      //},
      period: {
        isRequired: true,
        requiredError: 'Период не задан',
        validations: {
          isComplete: {
            validate: (value: any) => value.every((v: any) => v !== null),
            error: 'Период указан не полностью',
          },
        },
      },
      date: {
        requiredError: 'Дата акта не задана',
      },
      subtasks: {
        isRequired: true,
        isEmpty: (value: Subtasks) => Object.keys(value).length === 0,
        requiredError: 'Работы не выбраны',
        validations: {
          amount: {
            validate: (value: Subtask[]) =>
              value.every(({ quantity }) => Number(quantity) > 0),
            error: 'Минимальное количество работ 1',
          },
          // unitCost: {
          //   validate: (value: Subtask[]) =>
          //     value.every(({ unit_cost }) => Number(unit_cost) > 0),
          //   error: 'Задайте стоимость всех работ',
          // },
          description: {
            validate: (value: Subtasks[]) =>
              value.every(({ description }: any) => description.length > 0),
            error: 'Заполните описание для всех работ в списке'
          }
        },
      },
      paymentAmount: {

      }
    }

    if (this.ctx.currentUser.kind === 'company') {
      fields.contractor = {}
      if (kind === 'task' && this.ctx.currentUser.hasFeature('projects')) {
        fields.project = {}
      }
    } else {
      fields.company = {}
      let initialCompany = this.companies.items.find(
        (c) => c.data.features['acts_create']
      )
      if (initialCompany) formInitialValues.company = initialCompany.id
    }

    this.form = new FormStore({
      fields,
      initialValues: formInitialValues,
    })

    if (this.ctx.currentUser.kind === 'company') {
      reaction(
        () => this.contractor,
        (contractor) => {
          this.form.fields.contract.change(
            contractor ? contractor.data.contracts?.items[0].id : undefined
          )
        }
      )
    }

    if (this.ctx.currentUser.kind === 'contractor') {
      reaction(
        () => this.company,
        (company) => {
          if (company) {
            this.fetchTemplatesState = this.ctx.api.fetch({
              type: 'subtasks/templates',
              url: `companies/${company.id}/subtasks/templates`,
            })
            this.fetchTemplatesState.then((data) => {
              this.templates = data as Collection<SubtaskTemplateEntity>
              this.form.fields.subtasks.reset()
            })
          } else {
            this.templates = undefined
            this.form.fields.subtasks.reset()
          }
          let firstContract = company?.data.contracts?.items[0]
          this.form.fields.contract.change(firstContract ? firstContract.id : undefined)
        },
        { fireImmediately: true }
      )
    }

    if (this.draft && this.ctx.currentUser.hasAbility('tasks.update')) {
      let dispose1 = reaction(
        () => this.form.values,
        () => {
          this.autosaveStatus = 'progress'
        }
      )
      let dispose2 = reaction(
        () => this.form.values,
        () => {
          this.draft!.update({ payload: this.getSubmitData() }).then(() => {
            this.autosaveStatus = 'saved'
          })
        },
        { delay: 1337 }
      )
      this.dispose = () => {
        dispose1()
        dispose2()
      }
    }

    makeAutoObservable(this, { dispose: false })
  }

  fetchTemplatesState = initialActionState
  autosaveStatus?: string
  dispose?: () => void

  get contractor() {
    if (this.ctx.currentUser.kind === 'company') {
      return this.form.fields.contractor.isEmpty
        ? undefined
        : this.contractors?.map[this.form.values.contractor]
    } else {
      return this.ctx.currentUser.user!.data.contractor!
    }
  }

  get companies() {
    return this.ctx.currentUser.user!.data.contractor!.data.companies
  }

  get company() {
    if (this.ctx.currentUser.kind === 'contractor') {
      return this.form.fields.company.isEmpty
        ? undefined
        : this.companies.map[this.form.values.company]
    } else {
      return this.ctx.currentUser.company
    }
  }

  get companyOptions() {
    if (this.ctx.currentUser.kind === 'contractor') {
      return this.companies.items
        .filter((c) => c.data.features['acts_create'])
        .map((c) => ({
          value: c.id,
          label: c.data.name,
        }))
    } else {
      return []
    }
  }

  get contracts() {
    return this.ctx.currentUser.kind === 'company'
      ? this.contractor?.data.contracts
      : this.company?.data.contracts
  }

  get contractOptions() {
    return this.contracts
      ? this.contracts.items.map((c: Entity<DocumentEntity>) => ({
        value: c.id,
        label: c.data.title,
      }))
      : []
  }

  get projectOptions() {
    return this.projects
      ? this.projects.items.map((project) => ({
        value: project.id,
        label: project.data.title,
      }))
      : []
  }

  getSubmitData() {
    let values = this.form.values
    let [since, upto] = values.period || []
    let data: { [key: string]: any } = {
      title: values.title,
      description: values.description,
      subtasks: (values.subtasks as Subtask[]).map((task: Subtask) => omit(task, 'id')),
      since: since && serializeDate(since),
      upto: upto && serializeDate(upto),
      attachment_ids: this.attachments.attachments.items.map(({ id }) => id),
      company_id: this.company!.id,
      contractor_id: this.contractor?.id,
      contract_id: values.contract,
      date: values.date && serializeDate(values.date)
    }
    if (this.company?.data.features.manual_payment_amount && this.kind === 'act') {
      data.payment_amount = values.paymentAmount
    }
    if (this.form.fields.project) data.project_id = values.project
    return data
  }

  validate() {
    if (!this.company) return 'Компания не выбрана'
    if (!this.contractor) return 'Контрактор не выбран'
    if (!this.form.isValid) {
      // @ts-ignore
      return Object.values(this.form.fields).find((field: any) => !field.isValid).error
        .value
    }
  }

  create(kind: 'draft' | 'task' | 'act') {
    let data = this.getSubmitData()
    let urls = {
      task: 'tasks',
      draft: 'tasks/drafts',
      act: 'tasks/acts',
    }
    let successTexts = {
      draft: 'Черновик сохранён',
      task: 'Задание создано',
      act: 'Акт создан',
    }
    let state = this.ctx.api.create<TaskEntity>({
      type: 'tasks',
      payload: data,
      url: urls[kind],
    })
    state.then(
      (task: Entity<TaskEntity>) => {
        this.ctx.toast.success({ title: successTexts[kind] })
        let redirectUrl
        if (kind === 'draft') {
          let projectId = task.data.projects.items[0]?.id
          redirectUrl = projectId ? `/projects/${projectId}` : '/tasks'
        } else if (kind === 'act') {
          redirectUrl = `/acts/${task.id}?open_sign_dialog=1`
        } else {
          redirectUrl = `/tasks/${task.id}?open_sign_dialog=1`
        }
        this.ctx.history.push(redirectUrl)
      },
      (error) => {
        this.ctx.toast.error({ title: 'Не удалось создать', description: error.message })
      }
    )
    return state
  }

  submitState = makeInitialActionState<Entity<TaskEntity>>()

  deleteState = initialActionState

  createTask() {
    let error = this.validate()
    if (error) return alert(error)
    this.submitState = this.create(this.kind)
  }

  createDraft() {
    this.submitState = this.create('draft')
  }

  updateDraft() {
    let data = this.getSubmitData()
    // @ts-ignore
    this.submitState = this.draft!.update({ payload: data })
    this.submitState.then(
      () => this.ctx.toast.success({ title: 'Черновик сохранен' }),
      (error) =>
        this.ctx.toast.error({
          title: 'Не удалось сохранить черновик',
          description: error.message,
        })
    )
  }

  assignDraft() {
    let error = this.validate()
    if (error) return alert(error)
    let data = this.getSubmitData()
    // @ts-ignore
    this.submitState = this.draft!.action({ action: 'assign_contractor', payload: data })
    this.submitState.then(
      (data: Data) => {
        this.draft!.setData(data)
        this.ctx.toast.success({ title: 'Задание сохранено' })
      },
      (error) =>
        this.ctx.toast.error({
          title: 'Не удалось сохранить задание',
          description: error.message,
        })
    )
  }

  deleteDraft() {
    this.deleteState = this.draft!.delete()
    return this.deleteState
  }
}

interface TaskCreateFormProps {
  options: TaskFormStoreOptions
  backLink: { to: string; title: string }
}

const TaskCreateForm = observer(({ options, backLink }: TaskCreateFormProps) => {
  let { isMobile } = useMediaContext()
  let history = useHistory()
  let toast = useStateToast()
  let currentUser = useUserStore()
  let api = useMobxApi()
  let ctx = { history, api, currentUser, toast }
  let store = useMemo(() => new TaskFormStore(options, ctx), [])
  useEffect(() => store.dispose, [])
  // @ts-ignore
  let isDragOver = useDropZone((files: File[]) => store.attachments.upload(files), $root)

  let backLinkElem = <BackLink to={backLink.to}>{backLink.title}</BackLink>

  const companyField = store.form.fields.company && (
    <Flex gap="1rem">
      <CompanyGreyIcon />
      <div style={{ flexGrow: 1 }}>
        <Field field={store.form.fields.company}>
          {({ inputProps }) => (
            <SelectInput
              {...inputProps}
              value={
                inputProps.value
                  ? {
                    value: inputProps.value,
                    label: store.companies.map[inputProps.value].data.name,
                  }
                  : null
              }
              onChange={(option) =>
                store.form.fields.company.change(option ? option.value : null)
              }
              options={store.companyOptions}
              placeholder="Выберите компанию"
              noOptionsMessage={() => 'Компаний не найдено'}
              isClearable
              styles={{
                valueContainer: (styles: any) => ({
                  ...styles,
                  fontSize: '1.2rem',
                  padding: 0,
                }),
              }}
            />
          )}
        </Field>
      </div>
    </Flex>
  )

  let contractorField = store.ctx.currentUser.kind === 'company' && (
    <Flex gap="1rem">
      <UserGreyIcon />
      <div style={{ flexGrow: 1 }}>
        <Field field={store.form.fields.contractor}>
          {({ inputProps }) => (
            <ContractorSelector
              {...inputProps}
              contractors={store.contractors}
              styles={{
                valueContainer: (styles: any) => ({
                  ...styles,
                  fontSize: '1.2rem',
                  padding: 0,
                }),
              }}
            />
          )}
        </Field>
      </div>
    </Flex>
  )

  // let contractField = (
  //   <Flex gap="1rem">
  //     <UserGreyIcon />
  //     <div style={{ flexGrow: 1 }}>
  //       <Field field={store.form.fields.contract}>
  //         {({ inputProps }) => (
  //           <SelectInput
  //             {...inputProps}
  //             value={
  //               inputProps.value
  //                 ? {
  //                   value: inputProps.value,
  //                   label: store.contracts!.map[inputProps.value]?.data.title,
  //                 }
  //                 : null
  //             }
  //             onChange={(option) =>
  //               store.form.fields.contract.change(option ? option.value : null)
  //             }
  //             options={store.contractOptions}
  //             placeholder="Выберите договор"
  //             noOptionsMessage={() => 'Договора не найдено'}
  //             styles={{
  //               valueContainer: (styles: any) => ({
  //                 ...styles,
  //                 fontSize: '1.2rem',
  //                 padding: 0,
  //               }),
  //             }}
  //           />
  //         )}
  //       </Field>
  //     </div>
  //   </Flex>
  // )

  const projectField = store.form.fields.project && (
    <Flex gap="1rem">
      <ProjectGreyIcon />
      <div style={{ flexGrow: 1 }}>
        <Field field={store.form.fields.project}>
          {({ inputProps }) => (
            <SelectInput
              {...inputProps}
              value={
                inputProps.value
                  ? {
                    value: inputProps.value,
                    label: store.projects!.map[inputProps.value].data.title,
                  }
                  : null
              }
              onChange={(option) =>
                store.form.fields.project.change(option ? option.value : null)
              }
              options={store.projectOptions}
              placeholder="Выберите проект"
              noOptionsMessage={() => 'Проектов не найдено'}
              isClearable
              styles={{
                valueContainer: (styles: any) => ({
                  ...styles,
                  fontSize: '1.2rem',
                  padding: 0,
                }),
              }}
            />
          )}
        </Field>
      </div>
    </Flex>
  )

  let periodField = (
    <Flex gap="1rem">
      <DateGreyIcon />
      <Field field={store.form.fields.period}>
        {({ inputProps }) => {
          const excludeDates = []

          if (store.form.fields.period.value && store.form.fields.period.value[0]) {
            excludeDates.push(store.form.fields.period.value[0])
          }

          return (
            <DatePicker
              {...inputProps}
              selectsRange
              style={{ fontSize: '1.2rem' }}
              minDate={new Date()}
              excludeDates={excludeDates}
            />
          )
        }}
      </Field>
    </Flex>
  )

  const dateField = store.kind === 'act' && (
    <Flex gap="1rem">
      <DateGreyIcon />
      <Field field={store.form.fields.date}>
        {({ inputProps }) => (
          <DatePicker
            {...inputProps}
            placeholder="Выберите дату акта"
            style={{ fontSize: '1.2rem' }}
          />
        )}
      </Field>
    </Flex>
  )

  let subtasks = (
    <ActionStateView
      state={store.fetchTemplatesState}
      Loading={
        (() => (
          <LoadingPlaceholder style={{ height: 150 }}>
            Загрузка списка работ...
          </LoadingPlaceholder>
        )) as any
      }
    >
      {() =>
        store.templates && (
          <div style={{ position: 'relative' }}>
            <div style={{ position: 'relative', zIndex: 1 }}>
              <SubtaskList
                mode="create"
                canChangeCost={true}
                templates={store.templates}
                subtasks={store.form.values.subtasks}
                onChangeSubtasks={(subtasks) =>
                  store.form.fields.subtasks.change(subtasks)
                }
                noTasksText={
                  store.kind === 'task'
                    ? 'Выберите нужные работы'
                    : 'Заполните выполненные работы'
                }
                onAmountChanged={(totalAmount) => store.form.fields.paymentAmount.change(totalAmount)}
              />
            </div>
          </div>
        )
      }
    </ActionStateView>
  )

  const paymentAmountField = (store.kind === 'act' && store.company?.data.features.manual_payment_amount) ? (
    <Flex gap="10px" align="center">
      <Field field={store.form.fields.paymentAmount}>
        {({ inputProps }) => (
          <NumberInput
            {...inputProps}
            allowDecimal
            placeholder='Сумма выплат'
            style={{ fontSize: '1.2rem' }}
          />
        )}
      </Field>
      ₽
    </Flex>
  ) : null

  let descriptionField = (
    <Field field={store.form.fields.description}>
      {({ inputProps }) => (
        <Input
          {...inputProps}
          isMultiline
          placeholder={
            store.kind === 'task'
              ? isMobile
                ? 'Информация для исполнителя'
                : 'Напишите, что еще следует знать исполнителю'
              : 'Дополнительная информация'
          }
          isWide={true}
          style={{ fontSize: '1.2rem' }}
        />
      )}
    </Field>
  )

  let isSubmitting =
    store.submitState.state === 'pending' || store.deleteState.state === 'pending'
  let buttons
  if (store.draft) {
    let canCreate = currentUser.hasAbility('tasks.create')
    let canSave = currentUser.hasAbility('tasks.update')
    let canDelete = currentUser.hasAbility('tasks.delete')
    buttons = (
      <>
        <Button
          onTap={() => store.assignDraft()}
          isLoading={isSubmitting}
          isDisabled={!canCreate || store.attachments.isLoading}
        >
          Предложить задание
        </Button>
        <Button
          onTap={() => store.updateDraft()}
          isLoading={isSubmitting}
          isDisabled={!canSave || store.attachments.isLoading}
          design="normal"
        >
          Сохранить черновик
        </Button>
        <Button
          onTap={() => store.deleteDraft().then(() => history.push(backLink.to))}
          isLoading={isSubmitting}
          isDisabled={!canDelete}
          design="normal"
        >
          Удалить черновик
        </Button>
      </>
    )
  } else {
    buttons = (
      <>
        <Button
          onTap={() => store.createTask()}
          isLoading={isSubmitting}
          isDisabled={store.attachments.isLoading}
        >
          {store.kind === 'task' ? 'Предложить задание' : 'Создать акт'}
        </Button>
        {store.kind === 'task' && (
          <Button
            onTap={() => store.createDraft()}
            isLoading={isSubmitting}
            isDisabled={store.attachments.isLoading}
            design="normal"
          >
            Сохранить черновик
          </Button>
        )}
      </>
    )
  }

  return (
    <Layout smallPaddingTop>
      <DropShade isDragOver={isDragOver} />
      <Col gap="4rem" style={{ maxWidth: 800 }}>
        <Col>
          <Flex justify="space-between">
            {backLinkElem}
            {store.autosaveStatus === 'saved' && (
              <span style={{ color: 'var(--color-green)' }}>Изменения сохранены</span>
            )}
            {store.autosaveStatus === 'progress' && (
              <span style={{ color: 'var(--color-secondary)' }}>Идёт сохранение...</span>
            )}
          </Flex>
          <Field field={store.form.fields.title} showRequiredError="never">
            {({ inputProps }) => (
              <Input
                {...inputProps}
                placeholder={store.kind === 'task' ? 'Название задания' : 'Название акта'}
                autoFocus
                style={{ fontSize: '2rem', fontWeight: 'bold' }}
                isWide={true}
              />
            )}
          </Field>
        </Col>
        <Col gap="2rem" style={{ maxWidth: 600, zIndex: 2 }}>
          {companyField}
          {contractorField}
          {/* {contractField} */}
          {projectField}
          {periodField}
          {dateField}
        </Col>
        {subtasks}
        {paymentAmountField}
        {descriptionField}
        <AttachmentsZone
          title={store.kind === 'task' ? 'файлы техзадания' : 'приложения к акту'}
          store={store.attachments}
        />
        <Flex gap="1.5rem" align="center">
          {buttons}
          {store.attachments.isLoading && <span>Файлы загружаются</span>}
        </Flex>
      </Col>
    </Layout>
  )
})

export default TaskCreateForm
