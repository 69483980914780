import React from 'react'
import { Typography, TypographyProps, TypographyVariant } from 'ui-kit'

export type P2Props = Omit<TypographyProps, 'variant'>

const P2 = ({ children, ...restProps }: P2Props) => (
  <Typography variant={TypographyVariant.P2} {...restProps}>
    {children}
  </Typography>
)

export default P2
