import React, { forwardRef } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react'
// @ts-ignore
import { useStyles } from 'floral'

import { useMediaContext } from 'App/MediaContext'

import { FloralProps } from './floral.types'

interface ModalProps
  extends Omit<React.ComponentProps<typeof Modal>, 'size'>,
    FloralProps {
  // Chakra uses predefined sizes (s/m/l), change to use css size
  size?: number | string
  fullScreen?: boolean
}

const modalStyles = (props: any, isMobile: boolean) => {
  let content = {
    paddingLeft: isMobile ? 20 : 35,
    paddingRight: isMobile ? 20 : 35,
    paddingTop: 28,
    paddingBottom: 42,
    borderRadius: 20,
    width: props.size,
    maxWidth: 'none',
  }

  let body = {
    padding: 0,
  }

  if (props.fullScreen) {
    Object.assign(content, {
      width: '100%',
      minHeight: '100%',
      borderRadius: 0,
      margin: 0,
    })
  }
  return { content, body }
}

const UiModal = (props: ModalProps) => {
  let { isMobile } = useMediaContext()
  let styles = useStyles(modalStyles, [props, isMobile])
  let { size: _size, ...rest } = props
  return (
    <Modal {...rest} style={styles.root}>
      <ModalOverlay />
      <ModalContent style={styles.content}>
        <ModalCloseButton width="20px" height="20px" />
        <ModalBody style={styles.body}>{props.children}</ModalBody>
      </ModalContent>
    </Modal>
  )
}

UiModal.defaultProps = { width: 500 }

export interface ModalInsertProps
  extends Omit<React.HTMLAttributes<HTMLElement>, 'style'>,
    FloralProps {}

const modalInsertStyles = (props: any, isMobile: boolean) => ({
  root: {
    position: 'relative',
    left: isMobile ? -20 : -40,
    paddingTop: '1rem',
    paddingBottom: '1rem',
    paddingLeft: isMobile ? 20 : 40,
    paddingRight: isMobile ? 20 : 40,
    width: '100%',
    boxSizing: 'content-box',
  },
})

const ModalInsert = forwardRef((props: ModalInsertProps, ref: any) => {
  let { isMobile } = useMediaContext()
  let styles = useStyles(modalInsertStyles, [props, isMobile])
  return <div {...props} style={styles.root} ref={ref} />
})

export default UiModal
export { ModalInsert }
