import { Entity } from 'mobx/mobx'
import { ContractorEntity } from 'entities'

export type ContractorProblem =
  | 'not_connected_moi_nalog'
  | 'not_registered_moi_nalog'
  | 'no_contract'
  | 'not_signed_contract'
  | 'no_payment_details'
  | 'is_suspended'

const getContractorProblems = (contractor: Entity<ContractorEntity>) => {
  let problems: ContractorProblem[] = []
  let { moi_nalog, contracts, is_suspended, payment_details, show_no_payment_details } = contractor.data
  if (moi_nalog && moi_nalog.status !== 'connected') {
    if (moi_nalog.status === 'not_registered') {
      problems.push('not_registered_moi_nalog')
    } else {
      problems.push('not_connected_moi_nalog')
    }
  }
  if (contracts.isEmpty) problems.push('no_contract')
  if (
    contracts.items.some((c) => !c.data.is_archived && c.data.status !== 'signed_by_all')
  ) {
    problems.push('not_signed_contract')
  }
  if (is_suspended) problems.push('is_suspended')
  if (!payment_details && show_no_payment_details) problems.push('no_payment_details')
  return problems
}

export { getContractorProblems }
