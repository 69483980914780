import React, { useEffect, useRef, useState } from 'react'
import { v4 as uuid } from 'uuid'
import { ReactComponent as UploadPrimaryIcon } from './img/upload.svg'
import { ReactComponent as FileIcon } from './img/Vector.svg'
import cl from 'clsx'

import s from './styles.module.css'
import { sleep } from 'ui/utils'

interface IFileUploaderProps {
  accept: string
  multiple?: boolean
  onUpload: (formData: FormData) => void
}

const FileUploader = ({ multiple = false, accept, onUpload }: IFileUploaderProps) => {
  const [id, setId] = useState<string>('')
  const [dragActive, setDragActive] = useState(false)
  const [filesList, setFilesList] = useState<File[]>([])
  const [isActiveTimer, setActiveTimer] = useState(false)
  const [isUploading, setUploading] = useState(false)
  const [hasUploadError, setUploadError] = useState(false)
  const [isCancelled, setCancelled] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)

  const allowedTypes = new Set(accept.split(', '))

  const handleDrag = function (event: React.MouseEvent) {
    event.preventDefault()
    event.stopPropagation()
    if (event.type === 'dragenter' || event.type === 'dragover') {
      setDragActive(true)
    } else if (event.type === 'dragleave') {
      setDragActive(false)
    }
  }

  const handleDrop = function (event: React.DragEvent) {
    event.preventDefault()
    event.stopPropagation()
    setDragActive(false)
    if (
      event.dataTransfer.files &&
      event.dataTransfer.files[0] &&
      allowedTypes.has(event.dataTransfer.files[0].type)
    ) {
      handleUpload(event.dataTransfer.files[0])
    }
  }

  const handleChange = function (event: React.ChangeEvent<HTMLInputElement>) {
    event.preventDefault()
    if (event.target.files && event.target.files[0]) {
      setFilesList([...filesList, event.target.files[0]])
      handleUpload(event.target.files[0])
    }
  }

  const handleUpload = async (file: File) => {
    const formData = new FormData()
    formData.append('file', file)
    try {
      setActiveTimer(true)
      await sleep(2000)
      setActiveTimer(false)
      if (!isCancelled) {
        setUploading(true)
        await onUpload(formData)
      } else {
        setCancelled(false)
      }
    } catch (err) {
      console.error(err)
      setUploadError(true)
    }
  }

  const cancelUpload = () => {
    setCancelled(true)
    setActiveTimer(false)
    setUploading(false)
    setFilesList([])
  }

  useEffect(() => {
    setId(uuid())

    return () => {
      setUploading(false)
    }
  }, [])

  return (
    <form
      onDragEnter={handleDrag}
      onSubmit={(event) => {
        event.preventDefault()
      }}
      className={s.form}
    >
      {!isActiveTimer && !isUploading && !hasUploadError && (
        <>
          <input
            ref={inputRef}
            type="file"
            id={id}
            multiple={multiple}
            accept={accept}
            onChange={handleChange}
            className={s.inputFile}
          />
          <label
            htmlFor={id}
            className={cl(s.inputFileLabel, dragActive && s.inputFileLabelActive)}
          >
            <UploadPrimaryIcon className={s.uploadIcon} />
            <div className={s.paleText}>Перетащите файлы или</div>
            <div className={s.dashedText}>выберите файл на диске</div>
          </label>
          {dragActive && (
            <div
              onDragEnter={handleDrag}
              onDragLeave={handleDrag}
              onDragOver={handleDrag}
              onDrop={handleDrop}
              className={s.dragZone}
            ></div>
          )}
        </>
      )}
      {(isActiveTimer || isUploading) &&
        filesList.map((file) => (
          <div className={s.filesWrapper}>
            <div className={s.file}>
              <div className={s.fileLeftSide}>
                <FileIcon className={s.fileIcon} />
                {file.name}
              </div>
              {!isUploading && (
                <button className={s.cancelButton} onClick={cancelUpload}></button>
              )}
              {isUploading && <div className={s.uploadingIndicator}></div>}
            </div>
          </div>
        ))}
    </form>
  )
}

export default FileUploader
