// @ts-ignore
import { extendComponentStyles } from 'floral'

import { BaseButton } from 'components/ui'
import { focusShadow } from 'components/ui/Button'

const filterButtonStyles = (
  props: React.ComponentProps<typeof BaseButton>,
  tapState: any
) => {
  let root: React.CSSProperties = {
    textAlign: 'left',
    height: 35,
    padding: '0 1rem',
    borderRadius: 7,
    minWidth: 150,
    maxWidth: 400,
    border: '1px solid #e9eaeb', // --color-grey-1
  }
  if (tapState.isHovered) root.background = 'var(--color-grey-1)'
  if (tapState.isFocused) root.boxShadow = focusShadow
  if (props.isDisabled) {
    root.background = 'var(--color-grey-1)'
    root.color = 'var(--color-secondary)'
    root.cursor = 'default'
  }
  return { root }
}

export const FilterButton = extendComponentStyles(BaseButton, filterButtonStyles)