import React from 'react'

import { useState, useMemo } from 'react'
import { forwardRef } from 'react'

import { createStatelessProvider } from '../utils/createProvider'
import { initialTapState, Taply } from '../taply'

import clsx from 'clsx'

const TABS_HEIGHT = 48

const TABS_PRIMARY_COLOR = 'var(--konsol-color-primary)'
const TABS_SECONDARY_COLOR = 'var(--konsol-color-grey)'

const TAB_HOVER_COLOR = 'var(--konsol-color-text)'
const TAB_HOVER_BACKGROUND = 'var(--konsol-color-grey-08)'

interface ITabsState {
  value: string
  handleChange: (value: string) => void
}

interface TabsProps {
  value?: string
  onChange: (value: string) => void
  children: React.ReactNode
  className?: string
  style?: React.CSSProperties
  noUnderline?: boolean
}

interface TabProps {
  children: React.ReactNode
  onTap?: () => void
  isActive?: boolean
  value: string
  className?: string
  style?: React.CSSProperties
}

const [TabsStateProvider, useTabsState] = createStatelessProvider<ITabsState>()

const tabsStyles: Record<string, React.CSSProperties> = {
  root: {
    position: 'relative',
    height: TABS_HEIGHT,
    width: '100%',
    overflow: 'hidden',
    overflowX: 'auto',
  },
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: -1,
    minWidth: '100%',
  },
  underline: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    left: 0,
    borderBottom: `1px solid ${TABS_SECONDARY_COLOR}`,
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
}

const Tabs = forwardRef<HTMLDivElement, TabsProps>(
  (
    {
      value: defaultValue = '',
      noUnderline = false,
      className,
      style,
      children,
      onChange,
    }: TabsProps,
    ref
  ) => {
    const [value, setValue] = useState(defaultValue)

    const handleChange = (value: string) => {
      setValue(value)
      onChange && onChange(value)
    }

    const contextPayload = useMemo(
      () => ({
        value,
        handleChange,
      }),
      [value]
    )

    return (
      <TabsStateProvider payload={contextPayload}>
        <div
          ref={ref}
          className={clsx(className, 'konsol-ui__noScrollbar')}
          style={{ ...style, ...tabsStyles.root }}
        >
          {!noUnderline && <div style={{ ...tabsStyles.underline }} />}
          <div
            style={{
              ...tabsStyles.container,
              ...tabsStyles.row,
            }}
          >
            {children}
          </div>
        </div>
      </TabsStateProvider>
    )
  }
)

Tabs.defaultProps = {}

const tabStyles: Record<string, React.CSSProperties> = {
  root: {
    height: TABS_HEIGHT,
    paddingLeft: 12,
    paddingRight: 12,
  },
  center: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    cursor: 'pointer',
    fontWeight: 600,
    fontSize: 12,
    whiteSpace: 'nowrap',
    color: TABS_SECONDARY_COLOR,
  },
  rootActiveUnderline: {
    color: TABS_PRIMARY_COLOR,
    borderBottom: `1px solid ${TABS_PRIMARY_COLOR}`,
  },
  containerActiveUnderline: {
    pointerEvents: 'none',
    color: TABS_PRIMARY_COLOR,
    marginBottom: -1,
  },
  containerHover: {
    color: TAB_HOVER_COLOR,
    background: TAB_HOVER_BACKGROUND,
  },
}

const Tab = forwardRef<HTMLDivElement, TabProps>(
  ({ className, value: tabValue, children, style, onTap }: TabProps, ref) => {
    const [tapState, setTapState] = useState(initialTapState)

    const { value, handleChange } = useTabsState()

    const isActive = tabValue === value

    const handleTap = () => {
      handleChange(tabValue)
      onTap && onTap()
    }

    return (
      <Taply onTap={handleTap} tapState={tapState} onChangeTapState={setTapState}>
        <div
          ref={ref}
          className={className}
          style={{
            ...style,
            ...tabStyles.root,
            ...tabStyles.center,
            ...(tapState.isHovered && tabStyles.containerHover),
            ...(isActive && tabStyles.rootActiveUnderline),
          }}
        >
          <div
            style={{
              ...tabStyles.container,
              ...(isActive && tabStyles.containerActiveUnderline),
            }}
          >
            {children}
          </div>
        </div>
      </Taply>
    )
  }
)

Tab.defaultProps = {}

export { Tabs, Tab }
