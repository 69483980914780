import React, { useMemo, useState } from 'react'
import { observer } from 'mobx-react-lite'

import { useUserStore } from 'stores/context'
import { useMobxApi, Collection, Entity } from 'mobx/mobx'
import { SubtaskTemplateEntity } from 'entities'
import { useMediaContext } from 'App/MediaContext'
import { Amount } from 'components'
import {
  ActionStateView,
  Placeholder,
  Layout,
  LayoutHeading,
  List,
  ListHeader,
  ListItem,
  ListCell,
  Modal,
  Flex,
} from 'components/ui'
import { AbilityButton, AbilityIconButton } from 'components/abilityButtons'
import useStateToast from 'components/ui/useStateToast'

import { ReactComponent as DeleteIcon } from '@material-design-icons/svg/round/delete.svg'
import { useDialogStore } from 'stores/DialogStore'

import SubtaskTemplateCreateForm from 'components/SubtaskCreateForm'

const cells: { [key: string]: any } = {
  id: { grow: 0, width: 40 },
  unit: { grow: 0, width: 80 },
  cost: { grow: 0, width: 120 },
  actions: { grow: 0, width: 40 },
}

interface SubtaskTemplateListItemProps {
  template: Entity<SubtaskTemplateEntity>
  canDelete?: boolean
  onDelete: () => void
}

const SubtaskTemplateListItem = observer(
  ({ template, canDelete, onDelete }: SubtaskTemplateListItemProps) => {
    let { isMobile } = useMediaContext()
    let { id, title, description, unit, unit_cost, currency } = template.data

    let deleteButton = canDelete && (
      <AbilityIconButton ability={canDelete} onClick={onDelete} tooltip="Удалить">
        <DeleteIcon width={20} />
      </AbilityIconButton>
    )

    if (isMobile) {
      return (
        <ListItem style={{ flexWrap: isMobile ? 'wrap' : 'nowrap' }}>
          <ListCell width="100%" style={{ marginBottom: '0.5rem' }}>
            <div style={{ color: 'var(--color-secondary)', marginBottom: 4 }}>№ {id}</div>
            <div style={{ fontWeight: 500 }}>{title}</div>
          </ListCell>
          <ListCell width="100%" style={{ marginBottom: '0.5rem' }}>
            {description}
          </ListCell>
          <ListCell>
            <Amount value={unit_cost} /> / {unit.title}
          </ListCell>
          <ListCell width={40}>{deleteButton}</ListCell>
        </ListItem>
      )
    }

    return (
      <ListItem style={{ alignItems: 'start', flexWrap: isMobile ? 'wrap' : 'nowrap' }}>
        <ListCell {...cells.id} style={{ color: 'var(--color-secondary)' }}>
          {id}
        </ListCell>
        <ListCell style={{ fontWeight: 500 }}>{title}</ListCell>
        <ListCell>{description}</ListCell>
        <ListCell {...cells.unit}>{unit?.title} </ListCell>
        <ListCell {...cells.cost}>
          <Amount value={{ value: unit_cost, currency }} />
        </ListCell>
        <ListCell {...cells.actions}>{deleteButton} </ListCell>
      </ListItem>
    )
  }
)

interface SubtaskTemplateListProps {
  templates: Collection<SubtaskTemplateEntity>
  canDelete?: boolean
}

const SubtaskTemplateList = observer(
  ({ templates, canDelete }: SubtaskTemplateListProps) => {
    let { isMobile } = useMediaContext()

    let deleteTemplate = (id: number) => templates.delete({ id, optimistic: true })

    return (
      <List>
        {!isMobile && (
          <ListHeader>
            <ListCell {...cells.id}>Код</ListCell>
            <ListCell>Вид задания</ListCell>
            <ListCell>Описание</ListCell>
            <ListCell {...cells.unit}>Единица</ListCell>
            <ListCell {...cells.cost}>Стоимость</ListCell>
            <ListCell {...cells.actions} />
          </ListHeader>
        )}
        {templates.items.map((template) => (
          <SubtaskTemplateListItem
            template={template}
            canDelete={canDelete}
            onDelete={() => deleteTemplate(template.id)}
          />
        ))}
      </List>
    )
  }
)

const TemplateListPage = observer(() => {
  let { isMobile } = useMediaContext()
  let mobxApi = useMobxApi()
  let currentUser = useUserStore()
  let toast = useStateToast()

  let fetchState = useMemo(() => mobxApi.fetch({ type: 'subtasks/templates' }), [])
  let unitsFetchState = useMemo(() => mobxApi.fetch({ type: 'subtasks/units' }), [])

  let [createState, setCreateState] = useState({ state: 'fulfilled' })
  let create = (data: any) => {
    let state = fetchState.value.create({ payload: data, mode: 'prepend' })
    state.then(
      () => toast.success({ title: 'Работа добавлена' }),
      (error: any) =>
        toast.error({
          title: 'Не получилось добавить работу',
          description: error.message,
        })
    )
    setCreateState(state)
  }

  // @ts-ignore
  let dialog = useDialogStore({ component: SubtaskTemplateCreateForm })

  return (
    <Layout>
      <Flex
        justify="space-between"
        align="center"
        gap="1rem"
        style={{ marginBottom: '4rem' }}
      >
        <LayoutHeading>Справочник работ</LayoutHeading>
        <AbilityButton
          ability={currentUser.hasAbility('subtask_templates.create')}
          onTap={() => {
            dialog.open({
              templates: fetchState.value,
              units: unitsFetchState.value,
              onSubmit: (data: any) => {
                create(data)
                dialog.close()
              },
            })
          }}
          size={isMobile ? 's' : 'm'}
          isLoading={createState.state === 'pending'}
          isDisabled={
            fetchState.state !== 'fulfilled' && unitsFetchState.state !== 'fulfilled'
          }
        >
          {isMobile ? 'Добавить' : 'Добавить работу'}
        </AbilityButton>
      </Flex>
      <ActionStateView state={fetchState}>
        {() =>
          fetchState.value.items.length === 0 ? (
            <Placeholder>Добавьте работы в справочник</Placeholder>
          ) : (
            <SubtaskTemplateList
              templates={fetchState.value}
              canDelete={currentUser.hasAbility('subtask_templates.destroy')}
            />
          )
        }
      </ActionStateView>
      {dialog.isOpen && (
        <>
          <Modal
            isOpen={dialog.isOpen}
            onClose={() => dialog.close()}
            size={isMobile ? '97%' : '800px'}
          >
            {dialog.render()}
          </Modal>
        </>
      )}
    </Layout>
  )
})

export default TemplateListPage
