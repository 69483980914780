import React from 'react'
import { Heading } from '@chakra-ui/react'
import cx from 'clsx'

import { Avatar } from 'components'
import { Flex } from 'components/ui'
import { useMediaContext } from 'App/MediaContext'
import { formatPhone } from 'utils/formatting'

import { ReactComponent as PhoneIcon } from './phone.svg'
import { ReactComponent as EmailIcon } from './email.svg'
import s from './styles.module.css'

interface UserData {
  avatar_url?: string
  full_name?: string
  name?: string
  mobile_phone?: string
  email?: string
}

const UserInfoBlock = ({
  user,
  children,
}: {
  user: UserData
  children?: React.ReactNode
}) => {
  let { isMobile } = useMediaContext()
  let full_name = user.full_name || user.name

  return (
    <div className={s.root}>
      <div
        style={{
          gridColumn: '1',
          gridRow: isMobile ? '1 / 2' : '1 / 3',
          alignSelf: isMobile ? 'center' : 'start',
        }}
      >
        <Avatar
          value={user.avatar_url}
          size={isMobile ? '4rem' : '6rem'}
          style={{ marginLeft: -8 }}
          name={full_name}
        />
      </div>
      <Heading
        as={isMobile ? 'h3' : 'h2'}
        style={{
          gridColumn: '2 / 3',
          gridRow: '1',
          marginTop: isMobile ? 0 : 12,
          alignSelf: isMobile ? 'center' : 'top',
        }}
        className={cx(!isMobile && s.nowrap, s.ellipsis)}
        title={full_name}
      >
        {full_name}
      </Heading>
      <Flex
        gap={isMobile ? '1rem' : '3rem'}
        style={{
          gridRow: '2',
          gridColumn: isMobile ? '1 / 4 ' : '2 / 4',
          alignSelf: 'self-start',
        }}
        className={s.subTitle}
      >
        {user.mobile_phone && (
          <a href={`tel:${user.mobile_phone}`} className={s.pseudoLink}>
            <Flex gap="4px" align="center">
              <PhoneIcon />
              <span className={s.nowrap}>{formatPhone(user.mobile_phone)}</span>
            </Flex>
          </a>
        )}
        {user.email && (
          <a
            href={`mailto:${user.email}`}
            className={s.pseudoLink}
            style={{ overflow: 'hidden' }}
          >
            <Flex gap="4px" align="center" style={{ overflow: 'hidden' }}>
              <EmailIcon />
              <span className={cx(s.nowrap, s.ellipsis)}>{user.email}</span>
            </Flex>
          </a>
        )}
      </Flex>
      {children}
    </div>
  )
}

export default UserInfoBlock
