import React from 'react'
import cl from 'clsx'

import css from './styles.module.css'

export type SwitchProps = {
  name: string
  checked: boolean
  disabled?: boolean
  className?: string
  onChange: (checked: boolean) => void
}

const Switch: React.FunctionComponent<SwitchProps> = ({
  name,
  checked,
  disabled = false,
  className,
  onChange,
}) => {
  const handleChange = () => onChange(!checked)

  return (
    <div className={cl(css.switch, disabled && css.disabled, className && className)}>
      <input
        type="checkbox"
        name={name}
        id={name}
        checked={checked}
        disabled={disabled}
        className={css.hiddenInput}
        onChange={handleChange}
      />
      <label className={css.label} htmlFor={name}></label>
    </div>
  )
}

export default Switch
