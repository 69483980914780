import * as React from 'react'
import { makeAutoObservable, reaction, computed, comparer } from 'mobx'
import { fromPromise } from 'mobx-utils'

import { MobxApi, Entity, Collection, ActionState, initialActionState } from 'mobx/mobx'

import { fetchApi } from 'api'
import { PaginationEntity, DocumentEntity } from 'entities'
import { SignDialogStore, UserStore } from 'stores/context'
import DocumentsMultiActionsStore from 'stores/DocumentsMultiActionsStore'

import { PaginationStore } from 'components/PaginationView'
import { useStateToast } from 'components/ui'

import ErrorDialog from 'components/ErrorDialog'
import DialogStore from 'stores/DialogStore'
import exportToFile from 'utils/exportToFile'
import numForm from 'utils/numForm'
import {
  ActionConfigs,
  FilterConfigs,
  FiltersStore,
  SelectedItemsStore,
} from 'components/FilteredList2'

import { Base64 } from 'js-base64'
import { cloneDeep } from 'lodash'
import {
  actionAbilities,
  actionConfigs,
  documentActionMethods,
  documentWordFormsWith,
} from './constants'
import { ConfirmArchiveDialog, ConfirmDeleteDialog } from './Dialogs'

interface DocumentsStoreProps {
  api: MobxApi
  signDialog: SignDialogStore
  toast: ReturnType<typeof useStateToast>
  filterConfigs: FilterConfigs
  initialState?: any
  currentUser: UserStore
}

export class DocumentsStore {
  api: MobxApi
  toast: ReturnType<typeof useStateToast>
  search = ''
  filters: FiltersStore
  sorting?: { col: string; direction: 'asc' | 'desc' }
  currentUser: UserStore
  documentsMultiActions: DocumentsMultiActionsStore
  paginated: PaginationStore<DocumentEntity>
  selected: SelectedItemsStore<DocumentEntity>
  errorDialog = new DialogStore({ component: ErrorDialog })
  confirmDeleteDialog = new DialogStore({ component: ConfirmDeleteDialog })
  confirmArchiveDialog = new DialogStore({ component: ConfirmArchiveDialog })
  deleteState = initialActionState
  archiveState = initialActionState

  constructor({
    api,
    signDialog,
    toast,
    filterConfigs,
    initialState,
    currentUser,
  }: DocumentsStoreProps) {
    this.api = api
    this.toast = toast
    this.currentUser = currentUser

    this.paginated = new PaginationStore<DocumentEntity>(
      ({ page, abortSignal }) =>
        api.fetch({
          type: 'documents/page',
          url: `documents/filter`,
          payload: { ...this.query, page },
          options: { signal: abortSignal, method: 'POST' },
        }) as ActionState<Entity<PaginationEntity<DocumentEntity>>>
    )

    this.documentsMultiActions = new DocumentsMultiActionsStore({
      api,
      signDialog,
      toast,
    })

    this.filters = new FiltersStore({
      configs: filterConfigs,
      toast,
      initialState,
    })

    let actions: ActionConfigs<DocumentEntity> = {}
    actionConfigs.forEach(({ action }) => {
      actions[action] = {
        ability: currentUser.hasAbility(`documents.${actionAbilities[action]}`),
        getItemsForAction: (selectedItems) =>
          api.fetch({
            type: 'documents',
            url: `documents/for_multi_${documentActionMethods[action]}`,
            options: { method: 'POST' },
            payload: selectedItems
              ? { ids: selectedItems.map((item) => item.id) }
              : { filters: this.filters.state, search: this.search },
          }) as ActionState<Collection<DocumentEntity>>,
        action: (documents: Entity<DocumentEntity>[]) => {
          switch (action) {
            case 'sign':
              return this.documentsMultiActions.openSignDialog(documents)
            case 'delete':
              return this.confirmDeleteDialog.open({ documents: documents, store: this })
            case 'archive':
              return this.confirmArchiveDialog.open({ documents: documents, store: this })
          }
        },
      }
    })

    this.selected = new SelectedItemsStore({
      list: computed(() => {
        if (this.paginated.fetchedValue) {
          let { items, total_pages, total_count } = this.paginated.fetchedValue.data
          return { items: items.items, pages: total_pages, count: total_count }
        } else {
          return { items: [], pages: 0, count: 0 }
        }
      }),
      actions,
      noItemsMessage: () => 'Не выбрано документов, доступных для этого действия.',
      someItemsMessage: ({ actionCount, selectedCount }) =>
        'Действие можно выполнить только с ' +
        numForm(actionCount, documentWordFormsWith) +
        ` из ${selectedCount}.`,
    })

    reaction(
      () => this.query,
      () => this.paginated.setPage(1),
      { fireImmediately: true, equals: comparer.structural }
    )

    reaction(
      () => this.search,
      (search) => {
        this.query.search = search
      },
      { delay: 333 }
    )

    makeAutoObservable(this)
  }

  get query() {
    return {
      filters: cloneDeep(this.filters.state), // clone needed to observe deep changes
      search: this.search,
      sorting: this.sorting,
    }
  }

  get serializedState() {
    return Base64.encode(JSON.stringify(this.filters.state))
  }

  get data() {
    return this.paginated.fetchedValue?.data
  }

  deleteDocuments(documents: Array<Entity<DocumentEntity>>) {
    this.deleteState = fromPromise(
      fetchApi({
        method: 'DELETE',
        url: 'documents/multi_delete',
        data: { ids: documents.map((d) => d.id) },
      })
    )
    this.deleteState.then(
      () => {
        this.toast.success({ title: 'Документы удалены' })
        this.confirmDeleteDialog.close()
        this.paginated.fetch()
      },
      (err) => {
        this.toast.error({ title: 'Не удалось удалить', description: err.message })
        this.confirmDeleteDialog.close()
      }
    )
  }

  archiveDocuments(documents: Array<Entity<DocumentEntity>>) {
    this.archiveState = fromPromise(
      fetchApi({
        method: 'POST',
        url: 'documents/multi_archive',
        data: { ids: documents.map((d) => d.id) },
      })
    )
    this.archiveState.then(
      () => {
        this.toast.success({ title: 'Документы отправлены в архив' })
        this.confirmArchiveDialog.close()
        this.paginated.fetch()
      },
      (err) => {
        this.toast.error({
          title: 'Не удалось отправить в архив',
          description: err.message,
        })
        this.confirmArchiveDialog.close()
      }
    )
  }

  createState = initialActionState
  createFromFile(file: File) {
    if (!file.name.match(/\.(csv|xls|xlsx)/)) {
      return alert('Поддерживаем реестры в форматах: .csv, .xls, .xslx')
    }
    let formData = new FormData()
    formData.append('file', file)
    let state = fromPromise(
      fetchApi({ url: 'documents/create_from_file', data: formData, method: 'POST' })
    )
    this.createState = state
    state.then(
      () => this.paginated.fetch(),
      (error: any) => {
        let title = 'Ошибка загрузки документов'
        if (error.data?.code === 'failed_validation') {
          this.errorDialog.open({
            title,
            message: error.message,
            errors: error.data.errors,
          })
        } else {
          this.toast.error({ title, description: error.message })
        }
      }
    )
  }

  exportToFile() {
    const query =
      this.selected.isAllSelected || this.selected.isEmpty
        ? { filters: this.filters.state }
        : { ids: this.selected.selectedItemsList.map((item) => item.id) }

    exportToFile({
      url: 'documents',
      format: 'xlsx',
      ...query,
      toast: this.toast,
      filename: `export_to_xlsx`,
    })
  }

  render() {
    return (
      <>
        {this.selected.render()}
        {this.errorDialog.render()}
        {this.confirmDeleteDialog.render()}
        {this.confirmArchiveDialog.render()}
      </>
    )
  }
}
