import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Button, Divider, FileUploader, Flex, H2, H3, Link, Modal, Switch } from 'ui'
import cl from 'clsx'

import { useCompanyStore, useUserStore } from 'stores/context'
import { ReactComponent as FileIcon } from 'assets/file.svg'

import s from './styles.module.css'
import { ITableCell, Table, TableTitle } from './components'
import { fetchApi } from 'api'
import { NoIssues } from './components/NoIssues'

interface IGetContractorsResponseItem {
  archived: boolean
  filename: string
  file_url: string
  inn_count: number
  created_at: string
}

const CompanyRisksPage = observer(() => {
  const currentUser = useUserStore()
  const { isEnabledStopList, switchStopListStatus, setStopListStatus } = useCompanyStore()

  const [isVisibleModal, setVisibleModal] = useState(false)
  const [activeList, setActiveList] = useState<ITableCell[][]>([])
  const [archivedList, setArchivedList] = useState<ITableCell[][]>([])
  const [formerEmployeesList, setFormerEmployeesList] = useState<string[]>([])
  const [isSuccessfulUpload, setSuccessfulUpload] = useState<null | boolean>(null)

  const showModal = () => setVisibleModal(true)
  const hideModal = () => {
    setVisibleModal(false)
    setFormerEmployeesList([])
    setSuccessfulUpload(null)
  }

  const createButtonClickHandler = (isEnabledStopList: boolean) => () => {
    if (isEnabledStopList) {
      showModal()
    }
  }

  const handleUpload = async (formData: FormData) => {
    try {
      const result = await fetchApi({
        url: 'contractors_blacklists',
        method: 'POST',
        data: formData,
      })
      await fetchBlacklists(true)
      // @ts-ignore
      setFormerEmployeesList([...formerEmployeesList, ...result])
      setSuccessfulUpload(true)
    } catch (err: any) {
      throw new Error(err)
    }
  }

  const fetchBlacklists = async (isReset: boolean = false) => {
    try {
      const result = await fetchApi({
        url: 'contractors_blacklists',
        method: 'GET',
      })

      if (isReset) {
        setActiveList([])
        setArchivedList([])
      }

      result.forEach((item: IGetContractorsResponseItem) => {
        const newItem = [
          {
            date: item.created_at,
          },
          {
            text: item.filename,
            url: item.file_url,
          },
          {
            text: item.inn_count.toString(),
          },
        ]

        if (item.archived) {
          setArchivedList((prevState: ITableCell[][]) => [newItem, ...prevState])
        } else {
          newItem.push(
            {
              text: 'Смотреть',
              url: '/contractors/W3sibmFtZSI6InN0YXR1cyIsInZhbHVlIjoiYmxhY2tsaXN0ZWQifV0=',
            },
            {
              text: 'Смотреть',
              url: '/invites#blacklisted',
            }
          )
          setActiveList((prevState: ITableCell[][]) => [newItem, ...prevState])
        }
      })
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    setStopListStatus(currentUser.company?.data.enable_blacklist ? true : false)
    fetchBlacklists()
  }, [])

  return (
    <div className={s.companyRisksPage}>
      <Flex justify="space-between" align="center" gap="var(--gap-s)">
        <H3 className={s.heading}>Проверка исполнителей по СТОП-листам работников</H3>
        <div className={s.switchWrapper}>
          <Switch value={isEnabledStopList} onChange={switchStopListStatus} />
        </div>
      </Flex>
      <Divider className={s.divider} />
      <Flex align="center" gap="19px" className={s.banner}>
        <FileIcon />
        <p>
          Может пригодиться:&nbsp;
          <Link href="https://support.konsol.pro/checking-stop-list" target="_blank">
            Как работает проверка исполнителей по СТОП-листам
          </Link>
        </p>
      </Flex>
      <div className={s.description}>
        Чтобы начать проверку — нужно загрузить список:
        <ul>
          <li>
            <Link href="/files/inn_list.xlsx" download>
              Скачайте шаблон документа
            </Link>
          </li>
          <li>
            заполните ИНН тех, кто работает сейчас и тех, кто работал в предыдущие 2 года;
          </li>
          <li>загрузите файл.</li>
        </ul>
        Чтобы обновить список нужно:
        <ul>
          <li>добавить или удалить ИНН в первоначальном списке</li>
          <li>загрузите файл снова.</li>
        </ul>
      </div>
      <Button
        variant="simple"
        className={s.uploadButton}
        isDisabled={!isEnabledStopList}
        onClick={createButtonClickHandler(isEnabledStopList)}
      >
        Загрузите список ИНН для проверки
      </Button>
      <div className={cl(s.tableWrapper, !isEnabledStopList && s.tableWrapperDisabled)}>
        {activeList.length > 0 && (
          <>
            <TableTitle
              title="Текущий список ИНН"
              note="(по нему в данный момент осуществляется проверка)"
            />
            <Table
              cols={[
                { text: 'Дата', isHiddenOnMobile: true, width: '130px' },
                { text: 'Файл', isHiddenOnMobile: true },
                { text: 'Кол-во ИНН', width: '110px' },
                { text: 'Исполнители', width: '120px' },
                { text: 'Приглашения', width: '120px' },
              ]}
              rows={activeList}
            />
          </>
        )}
        {archivedList.length > 0 && (
          <>
            <TableTitle title="Архив" />
            <Table
              cols={[
                { text: 'Дата', isHiddenOnMobile: true, width: '130px' },
                { text: 'Файл', isHiddenOnMobile: true },
                { text: 'Кол-во ИНН', width: '350px' },
              ]}
              rows={archivedList}
            />
          </>
        )}
        <div
          className={cl(s.tableOverlay, !isEnabledStopList && s.tableOverlayEnabled)}
        />
      </div>
      <Modal width={375} isOpen={isVisibleModal} onClose={hideModal}>
        <H2 className={s.modalHeading}>Проверка исполнителей</H2>
        {isSuccessfulUpload === null && (
          <>
            <FileUploader
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              onUpload={handleUpload}
            />
            <p className={s.modalDescription}>
              Важно: проверка происходит только по последнему загруженному файлу, поэтому
              нужно всегда грузить полный список ИНН.
            </p>
          </>
        )}
        {isSuccessfulUpload && (
          <>
            {formerEmployeesList.length > 0 && (
              <>
                <p className={s.modalDescription}>
                  Найдено {formerEmployeesList.length}{' '}
                  {formerEmployeesList.length === 1
                    ? 'исполнитель'
                    : formerEmployeesList.length === 2
                    ? 'исполнителя'
                    : 'исполнителей'}{' '}
                  в стоп-листе. Вам нужно завершить с ними сотрудничество.
                </p>
                <div>
                  {formerEmployeesList.map((employee: any) => (
                    <div className={s.employee}>
                      <div className={s.employeeName}>{employee.full_name}</div>
                      <div className={s.employeeInn}>ИНН: {employee.inn}</div>
                    </div>
                  ))}
                </div>
                <button className={s.modalOkButton} onClick={hideModal}>
                  Понятно
                </button>
              </>
            )}
            {formerEmployeesList.length === 0 && (
              <>
                <NoIssues />
                <p className={s.modalDescription}>
                  Активных и приглашенных исполнителей в стоп-листе не найдено, вы можете
                  продолжить работу.
                </p>
                <button className={s.modalOkButton} onClick={hideModal}>
                  Понятно
                </button>
              </>
            )}
          </>
        )}
      </Modal>
    </div>
  )
})

export default CompanyRisksPage
