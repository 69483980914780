import { rest } from 'msw'
import { range, times, random } from 'lodash'

import { json, error } from './helpers'

let act = {
  id: 9,
  title: 'Мойка машин',
  since: '2020-06-01',
  upto: '2020-06-15',
  amount: 124000,
  status: 'draft',
  display_amount: '111.23',
  act: { status: 'not_accepted', payment_amount: '111.33' },
  payment: { status: 'error', error: 'Текст ошибки' },
  contractor: {
    id: 202,
    name: 'Иван Иванов',
  },
  kind: 'act',
  actions: ['pay'],
}

let foreignAct = {
  ...act,
  title: 'Акт в евро',
  display_amount: { value: '111', currency: 'EUR' },
  act: { amount: { value: '111', currency: 'EUR' }, status: 'not_accepted' },
}

const repeated = range(10, 50).map((i) => ({ ...act, id: i }))

const bundle = {
  id: 1,
  title: 'Название пачки актов',
  amount: 133123,
  amount_with_commission: 133123,
  acts: [
    {
      id: 7,
      title:
        'Обзвон клиентов и составление списка клиентов, для страхования по ОСАГО в период пандемии',
      since: '2020-06-01',
      upto: '2020-06-15',
      display_amount: '111.23',
      status: 'accepted',
      act: { status: 'accepted', payment_amount: '111.33' },
      payment: { status: 'not_paid', error: 'Примечание' },
      fns_receipts: [{ preview_url: 'http://google.com', status: 'annulled' }],
      contractor: {
        id: 200,
        name: 'Константин Константинопольский',
        moi_nalog: { status: 'unbound' },
        is_suspended: true,
      },
      actions: ['accept', 'delete', 'pay'],
      fns_receipt: {
        url: 'http://google.com',
        status: 'annulled',
      },
    },
    {
      id: 8,
      title:
        'Обзвон клиентов и составление списка клиентов, для страхования по ОСАГО в период пандемии',
      since: '2020-06-01',
      upto: '2020-06-15',
      display_amount: '111.23',
      status: 'done',
      act: { status: 'annulled' },
      payment: { status: 'not_paid', payment_amount: '111.33' },
      contractor: {
        id: 201,
        name: 'Константция Константинопольская',
        is_suspended: true,
      },
      kind: 'act',
      actions: ['accept', 'annul'],
    },
    foreignAct,
    ...repeated,
  ],

  // actions: [],
  actions: ['delete', 'download_original_file'],
}

const longTitle =
  'Длинное название пачки актов вообще жесть зачем они так ' +
  times(50, () => random(35).toString(36)).join('') +
  ' ' +
  times(150, () => random(35).toString(36)).join('') +
  ' не надо такое длинное'

const bundles = [
  {
    id: 11,
    title: 'Название пачки актов',
    is_loading: true,
    count: 3,
    amount: 133123,
    amount_with_commission: 133123,
    progress: {
      accepted: 1,
      paid: 1,
      errors: 0,
    },
    actions: ['delete'],
  },
  {
    id: 1,
    title: 'Название пачки актов',
    count: 3,
    amount: 133123,
    amount_with_commission: 133123,
    progress: {
      accepted: 1,
      paid: 1,
      errors: 0,
    },
    actions: ['delete'],
  },
  {
    id: 2,
    title:
      'Обзвон клиентов и составление списка клиентов, для страхования по ОСАГО в период пандемии',
    count: 133,
    progress: {
      accepted: 132,
      paid: 99,
      errors: 33,
    },
    amount: 2014567.72,
    actions: [],
  },
  {
    id: 3,
    title: longTitle,
    count: 133,
    progress: {
      accepted: 132,
      paid: 99,
      errors: 33,
    },
    amount: 2014567.72,
    actions: [],
  },
]

let page1 = {
  items: bundles,
  page: 1,
  per_page: 2,
  total_pages: 2,
  total_count: 2,
}

let page2 = {
  items: [bundles[1]],
  page: 2,
  per_page: 1,
  total_pages: 2,
  total_count: 2,
}

export default [
  rest.get('/api/company/bundles/page/1', json(page1)),
  rest.get('/api/company/bundles/page/2', json(page2)),
  rest.get('/api/company/bundles/:id', json(bundle)),

  rest.get(
    '/api/company/bundles/:id/download_original_file',
    (req: any, res: any, ctx: any) =>
      res(
        ctx.delay(500),
        ctx.set(
          'content-disposition',
          `attachment; filename="ORIGINAL.xlsx"; filename*=UTF-8''ORIGINAL.xlsx`
        ),
        ctx.body('CONTENT')
      )
  ),

  rest.delete('/api/company/bundles/:id', json({})),

  rest.post(
    '/api/company/bundles/create_from_file',
    error(500, {
      error: {
        code: 'failed_validation',
        message: 'Неправильные данные',
        errors: [
          '<strong>Строка 25</strong>: Исполнитель с ИНН 78990123 не найден',
          'Cтрока 54: Шаблон документа 123 не найден',
        ],
      },
    })
  ),
]
