import React, { useMemo } from 'react'
import { observer } from 'mobx-react-lite'

import type { PageProps } from 'app.types'
import { useMobxApi } from 'mobx/mobx'

import { ActionStateView } from 'components/ui'
import TaskCreateForm from 'components/tasks/TaskCreateForm'
import { queryObject, Url } from 'utils/url'

interface TasksCreatePageProps extends PageProps {
  kind: 'task' | 'act'
}

const BaseTaskCreatePage = observer(({ kind, match }: TasksCreatePageProps) => {
  let mobxApi = useMobxApi()
  let initialContractor = useMemo(() => queryObject(Url()).contractor_id, [])
  let initialProject = Number(match.params.project_id)
  let contractorsFetchState = useMemo(() => mobxApi.fetch({ type: 'contractors' }), [])
  let templatesFetchState = useMemo(
    () => mobxApi.fetch({ type: 'subtasks/templates' }),
    []
  )
  let projectsFetchState = useMemo(() => mobxApi.fetch({ type: 'projects' }), [])

  return (
    <ActionStateView
      state={[templatesFetchState, contractorsFetchState, projectsFetchState]}
    >
      {([templates, contractors, projects]) => (
        <TaskCreateForm
          options={{
            kind,
            contractors,
            projects,
            templates,
            initialValues: {
              contractor: initialContractor ? Number(initialContractor) : undefined,
              project: initialProject ? Number(initialProject) : undefined,
            },
          }}
          backLink={
            kind === 'act'
              ? { to: '/acts', title: 'К актам' }
              : { to: '/tasks', title: 'К заданиям' }
          }
        />
      )}
    </ActionStateView>
  )
})

const TaskCreatePage = (props: any) => <BaseTaskCreatePage kind="task" {...props} />

const ActCreatePage = (props: any) => <BaseTaskCreatePage kind="act" {...props} />

const ContractorActCreatePage = observer(() => (
  <TaskCreateForm
    options={{ kind: 'act' }}
    backLink={{ to: '/acts', title: 'К актам' }}
  />
))

export { TaskCreatePage, ActCreatePage, ContractorActCreatePage }
