import React from 'react'

import { useMediaContext } from 'App/MediaContext'
import { printIsoDateTime } from 'utils/datetime'

import { List, ListItem, ListCell, Flex } from 'components/ui'
import { Avatar } from 'components'

const Who = (props: any) => {
  let { isMobile } = useMediaContext()
  let { avatar_url, name, mobile_phone } = props
  return (
    <Flex gap={isMobile ? '.5rem' : '1.5rem'} align="center">
      <Avatar value={avatar_url} size="36px" name={name} />
      <Flex gap="0.25rem">
        <div>{name}</div>
        {mobile_phone && (
          <div style={{ color: 'var(--color-secondary)' }}>{mobile_phone}</div>
        )}
      </Flex>
    </Flex>
  )
}

const EventsList = ({ items, style }: { items: any; style?: any }) => {
  let { isMobile } = useMediaContext()
  if (items.length === 0) return null
  let cols = {
    who: {},
    kind: {},
    created: { style: { color: 'var(--color-secondary)', width: 100 } },
  }
  return (
    <List cols={cols} style={style} lines={isMobile}>
      {items.map((item: any) =>
        isMobile ? (
          <ListItem wrap={isMobile} hover={false} padding={false}>
            <ListCell row>
              <ListCell col="who">
                <Who {...item.whodunnit} />
              </ListCell>
              <ListCell col="created">{printIsoDateTime(item.created_at)}</ListCell>
            </ListCell>
            <ListCell row>{item.kind}</ListCell>
          </ListItem>
        ) : (
          <ListItem hover={false} padding={false}>
            <ListCell col="who">
              <Who {...item.whodunnit} />
            </ListCell>
            <ListCell col="kind">{item.kind}</ListCell>
            <ListCell col="created">{printIsoDateTime(item.created_at)}</ListCell>
          </ListItem>
        )
      )}
    </List>
  )
}

export default EventsList
