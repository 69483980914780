import React, {
  createContext,
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { SpringConfig } from 'react-spring'

import { Taply, TapState, initialTapState } from 'ui/taply'
import { useStyles, StyleProps, StylesMap } from 'ui/styled'
import { useList, useListKeyboardEvents } from 'ui/list'
import { Dropdown } from 'ui/dropdown'
import type { AnimationFunction, Side } from 'ui/animation/functions'
import { springConfigs, animationFunctions } from 'ui/animation'
import {
  defaultPlacement,
  oppositeSides,
  PopupPlacement,
  PopupSide,
} from 'ui/popup/PopupController'
import {
  DescendantManager,
  useDescendant,
  useDescendants,
  Descendant,
} from 'ui/descendants'
import mergeRefs from 'ui/utils/mergeRefs'
import useControlledState from 'ui/utils/useControlledState'
import scrollIntoView from 'ui/utils/scrollIntoView'

export type MenuItemStyleProps = [MenuItemProps, { isSelected: boolean }]

export interface MenuItemProps extends StyleProps<MenuItemStyleProps> {
  /** Value of the item that will be passed to the `onSelect()` handler of the Menu */
  value?: string

  /**
   * Handler that is called when the item is selected by clicking on it or pressing
   * `Enter` key
   */
  onSelect?: () => void

  isDisabled?: boolean
  onHover?: () => void
  onBlur?: () => void
  children: React.ReactNode | ((isSelected: boolean) => React.ReactNode)
}

interface MenuDescendantProps {
  isDisabled?: boolean
  onSelect?: () => void
  value?: string
}

interface MenuContextProps {
  descendants: DescendantManager<MenuDescendantProps>
  selectedIndex: number
  setSelectedIndex: (index: number) => void
  onSelect: (index: number) => void
}

const MenuContext = createContext<MenuContextProps | undefined>(undefined)

const MenuItem = forwardRef((props: MenuItemProps, ref) => {
  const { isDisabled, onSelect, value, children, onHover, onBlur } = props
  const menuContext = useContext(MenuContext)
  if (!menuContext) {
    throw new Error('MenuItem can be used only inside Menu or MenuList')
  }
  const {
    descendants,
    selectedIndex,
    setSelectedIndex,
    onSelect: menuOnSelect,
  } = menuContext
  const { ref: descendantRef, index } = useDescendant(descendants, {
    isDisabled,
    onSelect,
    value,
  })
  const isSelected = index !== -1 && index === selectedIndex
  useEffect(() => {
    if (isSelected) {
      onHover?.()
    } else {
      onBlur?.()
    }
  }, [isSelected])
  const [tapState, setTapState] = useState(initialTapState)
  const onChangeTapState = useCallback(
    (tapState: TapState) => {
      setTapState(tapState)
      if (tapState.isHovered) setSelectedIndex(index)
    },
    [index]
  )
  const styles = useStyles(undefined, [props, { isSelected }])

  return (
    <Taply
      onChangeTapState={onChangeTapState}
      tapState={tapState}
      onTap={() => menuOnSelect(index)}
      isDisabled={isDisabled}
      isFocusable={false}
      shouldSetAttributes={false}
    >
      <div style={styles.root} ref={mergeRefs(ref, descendantRef)}>
        {typeof children === 'function' ? children(isSelected) : children}
      </div>
    </Taply>
  )
})

export interface MenuListProps {
  children: React.ReactNode
  style: React.CSSProperties
  onClose: () => void
  onSelect?: (value?: string) => void
  autoSelectFirstItem: boolean
  closeOnSelect?: boolean
}

const MenuList = forwardRef<HTMLDivElement, MenuListProps>((props, ref) => {
  const { children, onSelect, autoSelectFirstItem, onClose, closeOnSelect, style } = props

  const descendants = useDescendants<MenuDescendantProps>()
  const list = useList<Descendant<MenuDescendantProps>>({
    items: descendants.items,
    isItemDisabled: (item) => Boolean(item.props.isDisabled),
  })
  const { selectedIndex, setSelectedIndex, selectFirstItem } = list

  const autoSelected = useRef(false)
  useEffect(() => {
    if (!autoSelected.current && autoSelectFirstItem) {
      setTimeout(() => {
        selectFirstItem()
        autoSelected.current = true
      })
      // TODO clear timeout just in case
    }
  }, [selectFirstItem])

  const select = useCallback(
    (index: number) => {
      const item = descendants.items[index]
      const { onSelect: itemOnSelect, value } = descendants.items[index].props
      scrollIntoView(item.element)
      if (itemOnSelect) itemOnSelect()
      if (onSelect && value !== undefined) onSelect(value)
      if (closeOnSelect) onClose()
    },
    [onSelect, descendants, closeOnSelect, onClose]
  )

  useListKeyboardEvents<Descendant<MenuDescendantProps>>(list, (item, index) =>
    select(index)
  )

  const context = {
    descendants,
    selectedIndex,
    setSelectedIndex,
    onSelect: select,
  }

  return (
    <div style={style} ref={ref}>
      <MenuContext.Provider value={context}>{children}</MenuContext.Provider>
    </div>
  )
})

const menuStyles = (...[_props, { isOpen: _isOpen }]: MenuStyleProps): StylesMap => ({
  list: {
    background: 'white',
    overflowY: 'auto',
  },
})

export type MenuStyleProps = [MenuProps & typeof menuDefaultProps, { isOpen: boolean }]

export interface MenuProps extends StyleProps<MenuStyleProps> {
  /** Content of the dropdown menu */
  menu: (props: MenuRenderProps) => React.ReactNode

  /** Trigger element that menu will be attached to */
  children: (ref: any, props: MenuRenderProps) => React.ReactNode

  /** Placement of the menu relative to the target */
  placement?: Partial<PopupPlacement>

  /** Function that is called when `<MenuItem>` is selected */
  onSelect?: (value?: string) => void

  /** Whether the menu should close when an item is selected */
  closeOnSelect?: boolean

  /** Function for the open and close animation */
  animation?: (side: Side) => AnimationFunction | AnimationFunction[]

  /** Maximum height of the list, in px. */
  maxHeight?: number

  /** Select first item on open */
  autoSelectFirstItem?: boolean

  /** If `true`, menu width will match the width of the button element. */
  matchWidth?: boolean

  /** Config for `react-spring` animation */
  springConfig?: SpringConfig

  /** Whether to lock focus inside the menu when it opens */
  focusLock?: boolean

  /** Whether to render overlay to cover page */
  overlay?: boolean

  /** Menu will close on click outside */
  closeOnClickOutside?: boolean
}

const menuDefaultProps = {
  closeOnSelect: true,
  placement: { ...defaultPlacement, constrain: true, padding: 16 },
  animation: (side: PopupSide) => [
    animationFunctions.fade(),
    animationFunctions.slide({ side: oppositeSides[side] }),
  ],
  springConfig: springConfigs.stiff,
  autoSelectFirstItem: true,
  focusLock: false,
  overlay: true,
  closeOnClickOutside: true,
}

export interface MenuRenderProps {
  isOpen: boolean
  open: () => void
  close: () => void
}

const Menu = (_props: MenuProps) => {
  const props = _props as MenuProps & typeof menuDefaultProps
  const {
    children,
    menu,
    placement,
    overlay,
    springConfig,
    matchWidth,
    closeOnClickOutside,
    autoSelectFirstItem,
    closeOnSelect,
    onSelect,
  } = props
  const [isOpen, setIsOpen] = useControlledState(props, 'isOpen', false)

  const styles = useStyles(menuStyles, [props, { isOpen }])

  const renderProps = {
    isOpen,
    open: () => setIsOpen(true),
    close: () => setIsOpen(false),
  }

  const popup = (
    ref: React.Ref<HTMLDivElement>,
    { style }: { style: React.CSSProperties }
  ) => (
    <MenuList
      style={{ ...style, ...styles.list }}
      autoSelectFirstItem={autoSelectFirstItem}
      closeOnSelect={closeOnSelect}
      onClose={() => setIsOpen(false)}
      onSelect={onSelect}
      ref={ref}
    >
      {menu(renderProps)}
    </MenuList>
  )

  return (
    <Dropdown
      popup={popup}
      // @ts-ignore
      isOpen={isOpen}
      onChangeIsOpen={setIsOpen}
      placement={placement}
      overlay={overlay}
      springConfig={springConfig}
      matchWidth={matchWidth}
      closeOnClickOutside={closeOnClickOutside}
    >
      {(ref) => children(ref, renderProps)}
    </Dropdown>
  )
}

Menu.defaultProps = menuDefaultProps

Menu.displayName = 'Menu'

export { Menu, MenuList, MenuItem }
