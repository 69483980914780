import React, { forwardRef } from 'react'
import { Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/react'

type UiMenuItemProps = React.ComponentProps<typeof MenuItem> & {
  kind?: 'primary' | 'danger'
}

const UiMenuItem = forwardRef((props: UiMenuItemProps, ref: any) => (
  <MenuItem
    ref={ref}
    {...props}
    style={{
      ...props.style,
      borderRadius: 6,
      minHeight: 36,
      padding: '.5rem 1rem',
      fontWeight: 500,
      cursor: props.isDisabled ? 'not-allowed' : 'pointer',
    }}
    _focus={{
      bg: props.kind === 'danger' ? 'var(--color-red-back)' : '#efebff',
      color: props.kind === 'danger' ? 'var(--color-red)' : 'var(--color-active)',
    }}
    _active={{
      bg: props.kind === 'danger' ? 'var(--color-red-back)' : '#efebff',
      color: props.kind === 'danger' ? 'var(--color-red)' : 'var(--color-active)',
    }}
  />
))

interface UiMenuListProps
  extends Omit<React.ComponentProps<typeof MenuList>, 'placement'> {
  // placement must be on <Menu>, not on list
  placement?: undefined
}

const UiMenuList = (props: UiMenuListProps) => (
  <MenuList
    {...props}
    style={{
      padding: '.5rem',
      borderRadius: 6,
      boxShadow: '0px 4px 20px #EEEEFA',
      ...props.style,
    }}
  />
)

const MenuLabel = ({ children }: { children: React.ReactNode }) => {
  return (
    <p
      style={{
        textTransform: 'uppercase',
        padding: '.75rem 1rem 0rem',
        fontSize: 10,
        color: 'var(--color-secondary)',
        fontWeight: 500,
      }}
    >
      {children}
    </p>
  )
}

export { Menu, MenuButton, UiMenuList as MenuList, UiMenuItem as MenuItem, MenuLabel }
