import React from 'react';

import { ReactComponent as LoaderIcon } from '../assets/loader.svg';

import classes from '../styles.module.css';

import clsx from 'clsx';

interface ILoaderProps {
    className?: string;
    appear?: boolean;
    margin?: boolean;
    onAnimationEnd?: () => void;
    style?: React.CSSProperties;
}

export const Loader = ({
    className,
    style,
    appear,
    margin,
    onAnimationEnd,
}: ILoaderProps) => (
    <div
        className={clsx(className, {
            [classes.margin]: margin,
        })}
        onAnimationEnd={onAnimationEnd}
    >
        <div
            className={classes.spinner}
            style={style}
        >
            <LoaderIcon
                className={clsx(className, {
                    [classes.appear]: appear,
                })}
            />
        </div>
    </div>
);

export default Loader;
