import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import cx from 'clsx'

import { UserRegistrationEntity } from 'entities'
import { Entity } from 'mobx/MobxApi'
import { Button, H1, H2, H3, Link, Modal, Notification, useTheme, Flex, Appear } from 'ui'

import { useStateToast } from 'components/ui'
import { useUserStore } from 'stores/context'
import DocumentsFormStore from 'pages/registration/store/DocumentsStore'
import ExtraFieldRenderer from 'pages/registration/components/ExtraFieldRenderer'
import RegistrationFooter from 'pages/registration/components/Footer'

import css from './styles.module.css'

const DocumentsReqiredStep = observer(
  ({ registration }: { registration: Entity<UserRegistrationEntity> }) => {
    const currentUser = useUserStore()
    const toast = useStateToast()
    const { config } = useTheme()
    const [isModalProtectionPersonalOpened, setIsModalProtectionPersonalOpened] =
      useState(false)
    const { documents } = registration.data.form_options

    const [store] = useState(
      () => new DocumentsFormStore(documents, registration, currentUser, toast)
    )

    // группируем документы по заголовкам
    let groups: any = {}
    if (documents) {
      documents.forEach((element: any) => {
        let caption = 'none'
        if (element.caption) caption = element.caption
        if (!groups[caption]) groups[caption] = []
        groups[caption].push(element)
      })
    }

    let size: 'm' = 'm'

    return (
      <>
        <Flex gap="20px" direction="col">
          <Flex gap="20px" direction="col" style={{ margin: '0 20px' }}>
            <Appear delay={0} animation={Appear.presets.slideDown}>
              <H1>Загрузка документов</H1>
            </Appear>
            <Appear delay={100} animation={Appear.presets.slideDown}>
              <p>
                Загрузите фото вашего паспорта и&nbsp;селфи с&nbsp;первым разворотом,
                чтобы мы&nbsp;вас идентифицировали и&nbsp;выпустили электронную подпись.
                Она нужна для подписания договоров и&nbsp;актов.
              </p>
            </Appear>
          </Flex>
          <Modal
            isOpen={isModalProtectionPersonalOpened}
            onClose={() => setIsModalProtectionPersonalOpened(false)}
            width={400}
            // lockFocusAcrossFrames={undefined}
          >
            <Flex gap="20px" direction="col">
              <H2>Restaff</H2>
              <ul className={css.protectionPersonalList}>
                <li>
                  <Link href="https://npd.nalog.ru/aggregators/" target="_blank">
                    Официальный налоговый агент ФНС
                  </Link>
                </li>
                <li>
                  Состоит в&nbsp;
                  <Link
                    href="https://pd.rkn.gov.ru/operators-registry/operators-list/?id=77-21-018964"
                    target="_blank"
                  >
                    реестре операторов персональных данных Роскомнадзора
                  </Link>
                </li>
                <li>Хранит данные пользователей только в&nbsp; России</li>
              </ul>
              <Button
                onTap={() => setIsModalProtectionPersonalOpened(false)}
                style={{ alignSelf: 'start' }}
              >
                Понятно
              </Button>
            </Flex>
          </Modal>
          {Object.keys(groups).map((caption, index: number) => {
            const withCaption: boolean = caption !== 'none'
            return (
              <Appear
                delay={400 + 100 * (index + 1)}
                animation={Appear.presets.slideDown}
              >
                <Flex
                  className={cx(css.group, withCaption && css.groupWithCaption)}
                  gap="20px"
                  direction="col"
                >
                  {withCaption && <H3 className={css.groupTitle}>{caption}</H3>}
                  <Flex gap="20px" direction="col">
                    {groups[caption].map((field: any) => (
                      <ExtraFieldRenderer
                        config={field}
                        field={store.form.fields[field.name]}
                        isLoading={store.form.fields[field.name].uploader?.isLoading}
                        size={size}
                      />
                    ))}
                  </Flex>
                </Flex>
              </Appear>
            )
          })}
          <Appear
            delay={Object.keys(groups).length + 500}
            animation={Appear.presets.slideDown}
          >
            <Flex justify="end" style={{ margin: '0 20px 50px' }}>
              <Button
                isDisabled={!store.isValid}
                isLoading={store.isLoading}
                onTap={() => store.submit()}
              >
                Далее
              </Button>
            </Flex>
          </Appear>
        </Flex>
        <RegistrationFooter />
      </>
    )
  }
)

export default DocumentsReqiredStep
