import React from 'react'

import { Flex, Col, LayoutHeading, Layout } from 'components/ui'

import { Modal, Button } from 'ui'

import { observer } from 'mobx-react-lite'

import { useDialogStore } from 'stores/DialogStore'
import { useMediaContext } from 'App/MediaContext'

import useScenarioListStore from './hooks/useScenarioListStore'
import { ScenarioModalStoreProvider } from './hooks/useScenarioModalStore'

import { OnboardingScenarioEntity } from 'entities/onboardingScenario'

import { ReactComponent as AddIcon } from 'assets/add-filled.svg'

import { Entity } from 'mobx/MobxApi'

import ScenarioModal, { IScenarioModalProps } from './components/ScenarioModal'
import ScenarioList from './components/ScenarioList'

export const ScenarioTemplates = observer((props: any) => {
  const scenarioStore = useScenarioListStore()

  const dialog = useDialogStore<IScenarioModalProps>({
    component: ScenarioModal,
  })

  const { isMobile } = useMediaContext()

  const handleRowEdit = (scenario: Entity<OnboardingScenarioEntity>) => {
    dialog.open({
      mode: 'update',
      payload: {
        id: scenario.data.id,
        // documentFields: scenarioStore.documentFields,
        // customFields: scenarioStore.customFields,
        // templates: scenarioStore.templates,
        // onboardingScenarios: scenarioStore.onboardingScenarios
      },
      onSave: () => {
        dialog.close()
      },
    })
  }

  return (
    <>
      <Layout smallPaddingTop>
        <Col gap="2rem">
          <Flex justify="space-between" align="center" gap="1rem">
            <Col gap=".5rem">
              <LayoutHeading>Сценарии</LayoutHeading>
            </Col>
            <Button
              icon={<AddIcon />}
              size={isMobile ? 's' : 'm'}
              onTap={() => {
                dialog.open({
                  mode: 'create',
                  onSave: () => {
                    dialog.close()
                    scenarioStore.loadData()
                  },
                })
              }}
            >
              Создать сценарий
            </Button>
          </Flex>
          <ScenarioList onEditRow={handleRowEdit} />
        </Col>
      </Layout>
      {dialog.isOpen && (
        <Modal isOpen={dialog.isOpen} onClose={() => dialog.close()}>
          <ScenarioModalStoreProvider
            pageProps={props}
            onUpdateRowData={scenarioStore.setItemData}
          >
            <Col gap="var(--gap-m)">{dialog.render()}</Col>
          </ScenarioModalStoreProvider>
        </Modal>
      )}
    </>
  )
})

export default ScenarioTemplates
