import React, { forwardRef, useState, useEffect, useRef } from 'react'

import { useStyles, StyleProps } from 'ui/styled'
import { Taply, TapState, initialTapState, TapEvent } from 'ui/taply'
import { OkButton, CancelButton } from 'ui/konsol/button'
import { HeadingLevel } from 'ui/konsol/heading'
import { ReactComponent as EditIcon } from 'ui/konsol/icons/edit.svg'

type EditInPlaceHeadingStyleProps = [
  EditInPlaceHeadingProps,
  { tapState: TapState; isEditing: boolean }
]

const defaultProps = {
  value: '',
  level: 1 as HeadingLevel,
}

interface EditInPlaceHeadingProps extends StyleProps<EditInPlaceHeadingStyleProps> {
  level?: HeadingLevel
  placeholder?: string
  value?: string
  onChange: (value: string) => void
}

const editInPlaceHeadingStyles = (
  ...[props, { isEditing }]: EditInPlaceHeadingStyleProps
) => {
  const { level } = props

  const root: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 6,
    background: isEditing ? 'var(--konsol-color-grey-16' : 'transparent',
    transition: 'background ease-out .15s',
    cursor: isEditing ? 'default' : 'pointer',
    padding: '4px 4px 4px 0',
    minHeight: 40,
    boxSizing: 'border-box',
  }

  const container: React.CSSProperties = {
    display: 'grid',
    gridTemplateColumns: 'auto',
    lineHeight: '120%',
    transition: 'padding ease-out .15s',
    flexShrink: 1,
    overflow: 'scroll',
  }

  const replica: React.CSSProperties = {
    gridArea: '1 / 1 / 2 / 2',
    font: 'inherit',
    visibility: 'hidden',
    whiteSpace: 'pre-wrap',
  }

  const placeholder: React.CSSProperties = {
    gridArea: '1 / 1 / 2 / 2',
    pointerEvents: 'none',
    font: 'inherit',
    color: 'var(--konsol-color-grey-64)',
    whiteSpace: 'pre-wrap',
  }

  const input: React.CSSProperties = {
    gridArea: '1 / 1 / 2 / 2',
    color: 'var(--konsol-color-black)',
    font: 'inherit',
    background: 'transparent',
    outline: 'none',
    border: 'none',
    resize: 'none',
    padding: 0,
    cursor: isEditing ? 'text' : 'pointer',
  }

  if (isEditing) container.paddingLeft = 8

  if (level === 1) {
    container.fontSize = '32px'
    container.fontWeight = 800
  } else if (level === 2) {
    container.fontSize = '24px'
    container.fontWeight = 800
  } else if (level === 3) {
    container.fontSize = '16px'
    container.fontWeight = 700
  } else if (level === 4) {
    container.fontSize = '14px'
    container.fontWeight = 700
  }

  const buttons: React.CSSProperties = {
    flexShrink: 0,
    marginLeft: 4,
  }

  return { root, container, replica, placeholder, input, buttons }
}

const EditInPlaceHeading = forwardRef<HTMLDivElement, EditInPlaceHeadingProps>(
  (_props: EditInPlaceHeadingProps, ref) => {
    const props = _props as EditInPlaceHeadingProps & typeof defaultProps
    const { value, onChange, placeholder } = props
    const [isEditing, setIsEditing] = useState(false)
    const [currentValue, setCurrentValue] = useState(value)
    const [tapState, setTapState] = useState(initialTapState)
    const inputRef = useRef<HTMLTextAreaElement>(null)
    const styles = useStyles(editInPlaceHeadingStyles, [props, { isEditing, tapState }])

    useEffect(() => {
      if (!isEditing) setCurrentValue(value)
    }, [value, isEditing])

    const onTap = () => {
      setIsEditing(true)
      inputRef.current?.focus()
    }

    const onInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setCurrentValue(e.target.value)
    }

    const onConfirm = (event: TapEvent) => {
      if (value !== currentValue) onChange(currentValue)
      setIsEditing(false)
      event.stopPropagation()
    }

    const onCancel = (event: TapEvent) => {
      setCurrentValue(value)
      setIsEditing(false)
      event.stopPropagation()
    }

    return (
      <Taply onTap={onTap} tapState={tapState} onChangeTapState={setTapState}>
        <div style={styles.root} ref={ref}>
          <div style={styles.container}>
            <textarea
              cols={1}
              ref={inputRef}
              disabled={!isEditing}
              style={styles.input}
              value={currentValue}
              onChange={onInputChange}
              rows={1}
            />
            <div style={styles.replica}>{currentValue} </div>
            {currentValue === '' && <div style={styles.placeholder}>{placeholder}</div>}
          </div>
          <div style={styles.buttons}>
            {isEditing ? (
              <>
                <OkButton size="s" onTap={onConfirm} />
                <CancelButton size="s" onTap={onCancel} />
              </>
            ) : tapState.isHovered ? (
              <EditIcon fill="var(--konsol-color-grey-64)" />
            ) : null}
          </div>
        </div>
      </Taply>
    )
  }
)

EditInPlaceHeading.defaultProps = defaultProps

export { EditInPlaceHeading }
