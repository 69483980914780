import React from 'react'

import { useStyles, StyleProps } from 'ui/styled'
import { Col } from 'ui/flex'

interface FieldProps extends StyleProps<[FieldProps]> {
  label?: React.ReactNode
  caption?: React.ReactNode
  error?: React.ReactNode
  children?: React.ReactNode
}

const defaultProps = {}

const fieldStyles = () => {
  const label = {
    fontSize: 12,
    lineHeight: '24px',
    color: 'var(--konsol-color-text-secondary)',
    fontWeight: 500,
  }
  const caption = { fontSize: 12, color: 'var(--konsol-color-text-secondary)' }
  const error = { fontSize: 12, color: 'var(--konsol-color-red)' }
  return { label, caption, error }
}

const Field = (_props: FieldProps) => {
  const props = _props as FieldProps & typeof defaultProps
  const { label, caption, error, children } = props
  const styles = useStyles(fieldStyles, [props])
  return (
    <Col gap="4px" style={styles.root}>
      {label && <div style={styles.label}>{label}</div>}
      {children && <div style={styles.input}>{children}</div>}
      {error ? (
        <div style={styles.error}>{error}</div>
      ) : (
        caption && <div style={styles.caption}>{caption}</div>
      )}
    </Col>
  )
}

export { Field }
