import React, { useState, useEffect } from 'react'
import { fromPromise } from 'mobx-utils'

import { fetchApi } from 'api'

import { Title, Input, FormGroup } from 'components/ui/LayoutSimple'

const savePassword = (uuid: string, password: string) =>
  fetchApi({
    method: 'PUT',
    url: 'authorizations/password',
    withPrefix: false,
    data: {
      uuid,
      password,
    },
  })

const SendPassword = ({ uuid, onComplete }: { uuid: string; onComplete: () => void }) => {
  const [password, setPassword] = useState('')
  const [repeatPassword, setRepeatPassword] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<any>()
  const [_uuid, _setUuid] = useState(uuid)
  const [checkState, setCheckState] = useState<any>(fromPromise.resolve())

  const onSubmit = () => {
    if (password && repeatPassword) {
      if (password && password !== repeatPassword) {
        setError(<>Пароли не совпадают</>)
        return
      }

      setIsLoading(true)
      setError('')
      const state = fromPromise(savePassword(_uuid, password))
      state.then(
        () => {
          setIsLoading(false)
          onComplete()
        },
        () => {
          setIsLoading(false)
        }
      )
      setCheckState(state)
    }
  }

  useEffect(() => {
    _setUuid(uuid)
  }, [uuid])

  useEffect(() => {
    if (checkState.state === 'rejected') {
      setError(checkState.value.message)
    }
  }, [checkState.state])

  useEffect(() => {
    if (error) {
      setError('')
    }
  }, [password, repeatPassword])

  return (
    <>
      <Title>
        Придумайте пароль
        <br />
        для&nbsp;входа
      </Title>
      <FormGroup
        isLoading={isLoading}
        isDisabled={!password || !repeatPassword || error}
        submitText="Продолжить"
        error={error}
        onSubmit={onSubmit}
      >
        <Input
          value={password}
          onChange={setPassword}
          placeholder="Введите пароль"
          type="password"
          onEnter={() => {
            if (password && repeatPassword) {
              onSubmit()
            }
          }}
          autoFocus
        />
        <Input
          value={repeatPassword}
          onChange={setRepeatPassword}
          type="password"
          placeholder="Введите пароль еще раз"
          onEnter={() => {
            if (password && repeatPassword) {
              onSubmit()
            }
          }}
        />
      </FormGroup>
    </>
  )
}

export default SendPassword
