import React from 'react'
import { observer } from 'mobx-react-lite'

import { Col, Button, H2, Modal } from 'components/ui'

interface ErrorDialogProps {
  title: React.ReactNode
  message: React.ReactNode
  errors?: string[]
  onClose: () => void
}

const ErrorDialog = observer(({ title, message, errors, onClose }: ErrorDialogProps) => {
  return (
    <Modal isOpen={true} onClose={onClose} size={800}>
      <Col gap="var(--gap-m)">
        <H2>{title}</H2>
        <div style={{ fontSize: '1.25rem', fontWeight: 500 }}>{message}</div>
        {errors && errors.length > 0 && (
          <Col gap="var(--gap-s)">
            {errors.map((item: string) => (
              <div dangerouslySetInnerHTML={{ __html: item }} />
            ))}
          </Col>
        )}
        <Button onTap={onClose} style={{ alignSelf: 'start' }}>
          Закрыть
        </Button>
      </Col>
    </Modal>
  )
})

export default ErrorDialog
