import React from 'react'

import { FormRow, Flex } from 'components/ui'

import { Chip, Chips, Button, Select } from 'ui'

import { ReactComponent as DeleteIcon } from 'assets/delete-red.svg'

import { OnboardingScenarioEntity } from 'entities/onboardingScenario'

import { useMediaContext } from 'App/MediaContext'

import useScenarioModalStore from '../../hooks/useScenarioModalStore'

import { observer } from 'mobx-react-lite'

import CustomFieldView from './CustomFieldView'

interface ICustomFieldProps {
  field: OnboardingScenarioEntity['contractor_custom_fields'][0]
  idx: number
}

export const CustomField = observer(({ field, idx }: ICustomFieldProps) => {
  const { isMobile } = useMediaContext()
  const scenarioModalStore = useScenarioModalStore()
  const size = isMobile ? 'm' : 'l'

  const fieldList = scenarioModalStore.customFields
    .filter(
      ({ id }) => !scenarioModalStore.usedFieldList.includes(id) || id === field.uuid
    )
    .map(({ id, name }) => ({
      label: name,
      value: id,
    }))

  const value = fieldList.find(({ value }) => value === field.uuid)

  return (
    <div
      style={{
        position: 'relative',
        paddingRight: 65,
        marginTop: 25,
        background: 'var(--konsol-color-grey-08)',
        borderRadius: 8,
      }}
    >
      <Button
        variant="text"
        icon={<DeleteIcon />}
        style={{
          position: 'absolute',
          top: 42.5,
          right: 10,
          color: 'red',
          userSelect: 'none',
          cursor: 'pointer',
        }}
        onClick={() => scenarioModalStore.removeField(idx)}
      />
      <Flex
        direction="column"
        style={{
          paddingTop: 25,
          paddingLeft: 25,
          paddingBottom: 25,
        }}
        gap="15px"
      >
        <FormRow label="Поле" size={size}>
          <Select
            value={value}
            onChange={(option: any) =>
              scenarioModalStore.updateFieldType(idx, option.value)
            }
            options={fieldList}
            isSearchable={false}
            placeholder="Выберите тип поля"
            noOptionsMessage={() => 'Нет доступных полей'}
          />
        </FormRow>
        <Flex align="center" gap="5px">
          <span
            style={{
              fontWeight: 500,
            }}
          >
            Заполняет
          </span>
          <Chips
            onChange={(value: string) =>
              scenarioModalStore.updateFieldFillTarget(idx, value)
            }
            value={field.fill_target}
          >
            <Chip value="company">Компания</Chip>
            <Chip value="contractor">Исполнитель</Chip>
          </Chips>
        </Flex>
        <CustomFieldView field={field} idx={idx} />
      </Flex>
    </div>
  )
})

export default CustomField
