import React from 'react'
import { observer } from 'mobx-react-lite'
import { Heading } from '@chakra-ui/react'

import { ActionState } from 'mobx/mobx'
import { useMediaContext } from 'App/MediaContext'
import { Flex, Modal } from 'components/ui'
import { FileThumb } from 'components'

import SignInput from './SignInput'

export interface SignDialogProps {
  isOpen: boolean
  title?: React.ReactNode
  onClose: () => void
  document?: { title: string; url: string }
  children?: React.ReactNode | (() => React.ReactNode)
  text: string
  onSign: (payload?: Object) => ActionState<any>
}

const SignDialog = ({
  children,
  title,
  document,
  text,
  onSign,
  isOpen,
  onClose,
}: SignDialogProps) => {
  let { isMobile } = useMediaContext()

  let content
  if (document) {
    content = isMobile ? (
      <FileThumb data={document} />
    ) : (
      <iframe
        title={document.title}
        src={document.url ? `${document.url}#zoom=90,-8,40&toolbar=0` : ''}
        style={{ flexGrow: 1, borderRadius: 6 }}
      />
    )
  } else {
    content = typeof children === 'function' ? children() : children
  }

  let height = isMobile ? 'auto' : document ? '90vh' : '250'

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={isMobile ? '97%' : '1000px'}
      styles={{ content: { height } }}
    >
      <Flex direction="column" align="stretch" gap="2rem" style={{ height: '100%' }}>
        {title && <Heading>{title}</Heading>}
        {content}
        <SignInput text={text} onSign={onSign} />
      </Flex>
    </Modal>
  )
}

export default observer(SignDialog)
