import Hotjar from '@hotjar/browser'

import { Entity } from 'mobx/mobx'
import { UserEntity } from 'entities'

export { yametrikaManager } from './yametrikaManager'

export const isProductionEnvironment = () =>
  process.env.NODE_ENV === 'production' &&
  !window.location.hostname.includes('netlify.app') &&
  !window.location.hostname.includes('staging.konsol.pro')

export const isDevelopment = () => process.env.NODE_ENV === 'development'

export const isStaging = () =>
  process.env.NODE_ENV === 'production' &&
  (window.location.hostname.includes('staging.konsol.pro') ||
    window.location.hostname.includes('netlify.app'))


export const getUserForBeamer = (user: Entity<UserEntity>) => {
  let { last_name = '', first_name = '', email = '', id, created_at = '' } = user.data
  const res = {
    user_firstname: first_name,
    user_lastname: last_name,
    user_email: email,
    user_id: id,
    user_created_at: created_at,
  }

  return res
}

export function beamerInitialize(
  id: number | undefined,
  params: {
    user_firstname: string
    user_email: string | undefined
    user_id: number
    user_created_at: string
  },
  cb: (c: number) => void,
  fn: () => void
) {
  if (!window) {
    return
  }

  if (!id) {
    throw Error('Must provide "id". https://app.getbeamer.com/embed')
  }

  ;(window as any).beamer_config = {
    product_id: 'AlQJwFIH40472',
    selector: '.beamerButton',
    button: false,
    counter: false,
    multi_user: true,
    callback: cb,
    onopen: fn,
    ...params,
  }

  const beamerURL = 'https://app.getbeamer.com/js/beamer-embed.js'

  const head = document.getElementsByTagName('head')[0]
  const script = document.createElement('script')

  script.defer = true
  script.src = beamerURL
  head.appendChild(script)
}

export const hotjarInitialize = (siteId: number, hotjarVersion: number) => {
  Hotjar.init(siteId, hotjarVersion)
}

export const hotjarIdentify = (user: Entity<UserEntity>) => {
  Hotjar.identify(user.data.id.toString(), {
    mobile_phone: user.data.mobile_phone!,
  })
}
