import React, { useEffect, useRef } from 'react'
import cl from 'clsx'

import css from './styles.module.css'

interface ToggleProps {
  isOpen?: boolean
  children: React.ReactNode
}

const Toggle = ({ isOpen = true, children }: ToggleProps) => {
  const wrapper = useRef<HTMLDivElement>(null)
  const content = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (wrapper.current && content.current) {
      if (!isOpen) {
        wrapper.current.style.height = '0'
        wrapper.current.classList.remove(css.open)
      } else {
        wrapper.current.style.height = `${content.current.offsetHeight}px`
        wrapper.current.classList.add(css.open)
      }
    }
  }, [isOpen])

  useEffect(() => {
    if (wrapper.current && content.current && !isOpen) {
      wrapper.current.style.height = '0'
    }
  }, [])

  return (
    <div ref={wrapper} className={cl(css.toggle, css.open)}>
      <div ref={content}>{children}</div>
    </div>
  )
}

export default Toggle
