import React, { useMemo, useState } from 'react'
import { makeAutoObservable } from 'mobx'
import { observer } from 'mobx-react-lite'
//@ts-ignore
import { FormStore } from 'shadowform'
import { repeat } from 'lodash'

import { Entity, Data, ActionState, initialActionState } from 'mobx/mobx'
import {
  ContractorEntity,
  ContractorPaymentMethod,
  ContractorPaymentDetails,
} from 'entities'
import { useMediaContext } from 'App/MediaContext'

import { FormRow, Field, useStateToast, Modal, H1, H3, Tabs } from 'components/ui'
import { Input, Flex, Button } from 'ui'
import { InfoListItem } from 'components/InfoList'
import { Notification } from 'components/Notification'
import {
  createDigitsMask,
  cardMask,
  cardPlaceholder,
  normalizeCardNumber,
  validateCardNumber,
} from 'utils/validations'

const requiredError = 'Необходимо заполнить это поле'

interface PaymentDetailsFormStoreProps {
  details?: ContractorPaymentDetails
  recipientName: string
  methods: ContractorPaymentMethod[]
  commission: { [key in ContractorPaymentMethod]: string }
}

class PaymentDetailsFormStore {
  constructor({ details, methods, commission }: PaymentDetailsFormStoreProps) {
    this.kind = details?.kind || methods[0]

    this.methods = methods
    this.commission = commission
    this.recipientName = ''

    this.accountForm = new FormStore({
      fields: {
        bank_id: {
          isRequired: true,
          requiredError,
          validations: {
            bank_id: {
              validate: (value: string) => /^\d{9}$/.test(value),
              error: 'Введите БИК до конца',
            },
          },
        },
        bank_account_id: {
          isRequired: true,
          requiredError,
          validations: {
            bank_account_id: {
              validate: (value: string) => /^\d{20}$/.test(value),
              error: 'Введите номер счета до конца',
            },
          },
        },
        bank_account_owner_name: {
          isRequired: true,
          requiredError,
          validations: {
            bank_account_owner_name: {
              validate: (value: string) => /^[?!,.\-а-яА-ЯёЁ\s]+$/.test(value),
              error: 'ФИО нужно указать на русском языке',
            },
          },
        },
      },
      initialValues: {
        ...details?.bank_account,
      },
    })

    this.cardForm = new FormStore({
      fields: {
        bank_card_number: {
          isRequired: true,
          requiredError,
          normalize: normalizeCardNumber,
          validations: {
            bank_card_number: {
              validate: validateCardNumber,
              error: 'Введите правильный номер карты',
            },
          },
        },
      },
      initialValues: {
        bank_card_number: '',
      },
    })

    makeAutoObservable(this)
  }

  methods: ContractorPaymentMethod[]
  commission: { [key in ContractorPaymentMethod]: string }
  kind: ContractorPaymentMethod
  recipientName: string
  accountForm: FormStore
  cardForm: FormStore

  get payload() {
    return {
      // kind: this.kind,
      ...(this.kind === 'card' ? this.cardForm.values : this.accountForm.values),
    }
  }

  get isValid() {
    return this.kind === 'card' ? this.cardForm.isValid : this.accountForm.isValid
  }
}

const PaymentDetailsForm = observer(({ store }: { store: PaymentDetailsFormStore }) => {
  const fields = {
    card: (
      <Field field={store.cardForm.fields.bank_card_number} key="bank_card_number">
        {({ inputProps, error }: any) => (
          <FormRow label="Номер карты" size="l" error={error}>
            <Input
              {...inputProps}
              numericKeyboard
              isWide
              size="l"
              mask={cardMask}
              placeholder={cardPlaceholder}
            />
          </FormRow>
        )}
      </Field>
    ),
    bank_account: (
      <>
        <Notification title="Счет обязательно должен принадлежать вам" type="warning">
          <div>Если указать реквизиты счета другого человека, деньги не дойдут</div>
        </Notification>
        <Field field={store.accountForm.fields.bank_id} key="bank_id">
          {({ inputProps, error }: any) => (
            <FormRow label="БИК" size="l" error={error}>
              <Input
                {...inputProps}
                numericKeyboard
                isWide
                size="l"
                mask={createDigitsMask(9)}
                placeholder={repeat('0', 9)}
              />
            </FormRow>
          )}
        </Field>
        <Field field={store.accountForm.fields.bank_account_id} key="bank_account_id">
          {({ inputProps, error }: any) => (
            <FormRow label="Номер счета" size="l" error={error}>
              <Input
                {...inputProps}
                numericKeyboard
                isWide
                size="l"
                mask={createDigitsMask(20)}
                placeholder={repeat('0', 20)}
              />
            </FormRow>
          )}
        </Field>
        <Field
          field={store.accountForm.fields.bank_account_owner_name}
          key="bank_account_owner_name"
        >
          {({ inputProps, error }: any) => (
            <FormRow
              label="ФИО как указано в реквизитах банка (получатель)"
              size="l"
              error={error}
            >
              <Input {...inputProps} isWide size="l" placeholder={''} />
            </FormRow>
          )}
        </Field>
      </>
    ),
  }

  if (!store.methods.includes(store.kind)) {
    if (store.kind === 'card') store.kind = 'bank_account'
    else if (store.kind === 'bank_account') store.kind = 'card'
  }

  const ChangeKind = ({ children }: any) => {
    if (store.methods.length > 1) {
      return (
        <Tabs
          title="Оплата по"
          options={[
            { value: 'bank_account', label: 'реквизитам счета' },
            { value: 'card', label: 'номеру карты' },
          ]}
          value={store.kind}
          onChange={(val) => (store.kind = val as ContractorPaymentMethod)}
        >
          {children}
        </Tabs>
      )
    }

    return children
  }

  return (
    <ChangeKind>
      <Flex gap="1rem" direction="col">
        <>
          {fields[store.kind]}
          {store.commission[store.kind] && (
            <div style={{ fontWeight: 500 }}>{store.commission[store.kind]}</div>
          )}
        </>
      </Flex>
    </ChangeKind>
  )
})

interface PaymentDetailsEditViewProps {
  contractor: Entity<ContractorEntity>
  onClose: () => void
}

const PaymentDetailsEditView = observer(
  ({ contractor, onClose }: PaymentDetailsEditViewProps) => {
    let { isMobile } = useMediaContext()
    let toast = useStateToast()

    let [submitState, setSubmitState] = useState(initialActionState)
    let submit = (): ActionState<Data> => {
      let state = contractor.action({ action: 'payment_details', payload: store.payload })
      state.then(
        (data) => {
          contractor.setData(data)
          toast.success({ title: 'Реквизиты изменены' })
          onClose()
        },
        (error) => {
          toast.error({
            title: 'Не удалось изменить реквизиты',
            description: error.message,
          })
        }
      )
      setSubmitState(state)
      return state
    }

    let { payment_details, payment_methods, payment_commission, full_name } =
      contractor.data
    let store = useMemo(
      () =>
        new PaymentDetailsFormStore({
          details: payment_details,
          methods: payment_methods,
          commission: payment_commission,
          recipientName: full_name,
        }),
      []
    )

    let buttons = (
      <Flex
        direction={isMobile ? 'col' : 'row'}
        gap={isMobile ? '1rem' : '2rem'}
        align={isMobile ? 'stretch' : 'center'}
      >
        <Button
          isDisabled={!store.isValid}
          isLoading={submitState.state === 'pending'}
          onTap={() => submit()}
        >
          Сохранить
        </Button>
        <Button variant="secondary" onTap={() => onClose()}>
          Отмена
        </Button>
      </Flex>
    )

    return (
      <Flex gap="2rem" direction="col">
        <H1>Укажите реквизиты для оплаты</H1>
        <div>Деньги будут выплачены по курсу ЦБ-1,5% на дату выплаты</div>
        <PaymentDetailsForm store={store} />
        {buttons}
      </Flex>
    )
  }
)

interface PaymentDetailsEditModalProps {
  contractor: Entity<ContractorEntity>
  onClose: () => void
}

const PaymentDetailsEditModal = observer(
  ({ contractor, onClose }: PaymentDetailsEditModalProps) => {
    let { isMobile } = useMediaContext()
    return (
      <Modal
        isOpen={true}
        onClose={onClose}
        size={isMobile ? '97%' : 600}
        closeOnOverlayClick={false}
      >
        <PaymentDetailsEditView contractor={contractor} onClose={onClose} />
      </Modal>
    )
  }
)

interface PaymentDetailsViewProps {
  contractor: Entity<ContractorEntity>
  details?: ContractorPaymentDetails // если нет, то берутся из контрактора
  canChange: boolean
  onChange?: () => void
}

const PaymentDetailsView = observer(
  ({ contractor, details, canChange, onChange }: PaymentDetailsViewProps) => {
    let { isMobile } = useMediaContext()
    let { kind, bank_account, card } = details || contractor.data.payment_details!

    let button = canChange && (
      <Button size="m" variant="secondary" onTap={onChange}>
        Изменить
      </Button>
    )

    return (
      <Flex direction="col" gap=".5rem">
        <Flex justify="space-between" align="center">
          <H3>Реквизиты</H3>
          {button}
        </Flex>
        <Flex direction={isMobile ? 'col' : 'row'} gap={isMobile ? '1rem' : '3rem'}>
          {kind === 'card' ? (
            <InfoListItem label="Номер карты">{card!.masked_card_number}</InfoListItem>
          ) : (
            <>
              <InfoListItem label="БИК">{bank_account!.rcbic}</InfoListItem>
              <InfoListItem label="Номер счёта">{bank_account!.account}</InfoListItem>
              <InfoListItem label="ФИО получателя">{bank_account!.name}</InfoListItem>
            </>
          )}
        </Flex>
      </Flex>
    )
  }
)

export {
  PaymentDetailsForm,
  PaymentDetailsFormStore,
  PaymentDetailsEditView,
  PaymentDetailsEditModal,
  PaymentDetailsView,
}
