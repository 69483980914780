import React, { forwardRef } from 'react'

import { StyleProps, useStyles } from 'ui/styled'
import { InputSize } from 'ui/konsol/input'

type InputAddonSide = 'left' | 'right'

interface InputAddonProps
  extends StyleProps<[InputAddonProps & typeof inputAddonDefaultProps]> {
  children?: React.ReactNode
  size?: InputSize
  side?: InputAddonSide
}

const inputAddonDefaultProps = {
  size: 'm' as InputSize,
  side: 'l' as InputAddonSide,
}

const heightMap = {
  s: 32,
  m: 40,
  l: 48,
}

const inputAddonStyles = (props: InputAddonProps & typeof inputAddonDefaultProps) => {
  const { size, side } = props

  const height = heightMap[size]
  const root: React.CSSProperties = {
    fontSize: 'var(--konsol-font-size)',
    color: 'var(--konsol-color-text-secondary)',
    background: 'var(--konsol-color-grey-24)',
    padding: '0 12px',
    height,
    display: 'flex',
    alignItems: 'center',
  }

  const radius = 6
  if (side === 'left') {
    root.borderTopLeftRadius = radius
    root.borderBottomLeftRadius = radius
  } else {
    root.borderTopRightRadius = radius
    root.borderBottomRightRadius = radius
  }

  return { root }
}

const InputAddon = (_props: InputAddonProps) => {
  const props = _props as InputAddonProps & typeof inputAddonDefaultProps
  const { children } = props
  const styles = useStyles(inputAddonStyles, [props])
  return <div style={styles.root}>{children}</div>
}

InputAddon.defaultProps = inputAddonDefaultProps

const InputLeftAddon = (props: Omit<InputAddonProps, 'side'>) => (
  <InputAddon side="left" {...props} />
)

const InputRightAddon = (props: Omit<InputAddonProps, 'side'>) => (
  <InputAddon side="right" {...props} />
)

interface InputGroupProps extends StyleProps<[InputGroupProps]> {
  children?: React.ReactNode
  className?: string
}

const inputGroupStyles = {
  root: {
    display: 'flex',
  },
}

const InputGroup = forwardRef((props: InputGroupProps) => {
  const { children, className } = props
  const styles = useStyles(inputGroupStyles, [props])
  return (
    <div style={styles.root} className={className}>
      {children}
    </div>
  )
})

export { InputGroup, InputAddon, InputLeftAddon, InputRightAddon }
