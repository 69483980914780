import React from 'react'

import { useMediaContext } from 'App/MediaContext'
import { TableDesktop } from '../TableDesktop'
import { TableMobile } from '../TableMobile'

export interface ITableProps {
  cols: ITableCol[]
  rows: ITableCell[][]
}

export interface ITableCol {
  text?: string
  isHiddenOnMobile?: boolean
  width?: string
}

export interface ITableCell {
  archived?: boolean
  text?: string
  url?: string
  date?: string
}

const Table = ({ cols, rows }: ITableProps) => {
  const { isMobile } = useMediaContext()

  if (isMobile) {
    return <TableMobile cols={cols} rows={rows} />
  }

  return <TableDesktop cols={cols} rows={rows} />
}

export default Table
