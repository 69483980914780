import React from 'react'
import { observer } from 'mobx-react-lite'

import { AbilityButton, AbilityMenuItem } from 'components/abilityButtons'

import SelectedItemsStore from './SelectedItemsStore'
import { useMediaContext } from 'App/MediaContext'

interface MultiActionButtonProps<T> extends React.ComponentProps<typeof AbilityButton> {
  action: string
  store: SelectedItemsStore<T>
  as?: 'button' | 'menuItem'
}

const MultiActionButton = observer(<T,>(props: MultiActionButtonProps<T>) => {
  let { children, store, action, as = 'button', ...rest } = props
  let component: any = as === 'button' ? AbilityButton : AbilityMenuItem
  let config = store.actions[action]
  let onTap = () => store.multiAction(action)

  let isDisabled = store.isFetching()
  let isLoading = store.isFetchingAction(action)
  let { isMobile } = useMediaContext()

  let elemProps = {
    as: 'div',
    children,
    ability: config.ability,
    isDisabled,
    ...rest,
  }
  if (as === 'button') {
    if (isMobile) {
      Object.assign(elemProps, { onTap, isLoading, design: 'text', size: 'xs' })
    } else {
      Object.assign(elemProps, { size: 's', design: 'normal', onTap, isLoading })
    }
  } else {
    Object.assign(elemProps, { onClick: onTap })
  }
  return React.createElement(component, elemProps)
})

export default MultiActionButton
