import React from 'react'
import cl from 'clsx'
import { InlineText, P1 } from 'ui-kit'
import { formatTime } from 'utils'

import css from './styles.module.css'

export enum TableCellType {
  Text = 'text',
  Date = 'date',
  Custom = 'custom',
}

export type TableCellProps = {
  type?: TableCellType | null
  content: string | React.ReactNode | null
}

const TableCell: React.FunctionComponent<TableCellProps> = ({
  type = TableCellType.Text,
  content,
}) => {
  if (type === null || content === null) {
    return <td className={cl(css.cell)}></td>
  } else if (type === TableCellType.Text) {
    return (
      <td className={cl(css.cell)}>
        <P1 className={css.text}>{content}</P1>
      </td>
    )
  } else if (type === TableCellType.Date) {
    return (
      <td className={cl(css.cell)}>
        <InlineText className={css.text}>
          {typeof content === 'string' && formatTime(content)}
        </InlineText>
      </td>
    )
  }

  return <td className={cl(css.cell)}>{content}</td>
}

export default TableCell
