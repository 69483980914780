import React, { useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { Link } from 'react-router-dom'

import { useMediaContext } from 'App/MediaContext'
import { useMobxApi, Collection, Entity } from 'mobx/mobx'
import { CompanyEntity } from 'entities'
import {
  Layout,
  LayoutHeading,
  ActionStateView,
  Placeholder,
  List,
  ListItem,
  ListCell,
  Flex,
  Button,
  useStateToast,
  Link as UiLink,
} from 'components/ui'
import { printDate } from 'utils/datetime'
import { useSignDialogStore, useUserStore } from 'stores/context'

import CompanyStore from './CompanyStore'

const CompanyListItem = observer(({ company }: { company: Entity<CompanyEntity> }) => {
  let { isMobile } = useMediaContext()
  let toast = useStateToast()
  let signDialog = useSignDialogStore()
  let currentUser = useUserStore()
  let store = useMemo(() => new CompanyStore(company, toast, currentUser, signDialog), [])

  let inviteElem = company.data.invite_status === 'not_accepted' && (
    <Flex gap="1rem" align="center">
      <div>Предлагает вам сотрудничество</div>
      <Button
        design="normal"
        size="s"
        onTap={(e: any) => {
          store.acceptInvite()
          e.preventDefault()
        }}
        isLoading={store.acceptInviteState.state === 'pending'}
      >
        Принять приглашение
      </Button>
    </Flex>
  )
  let contractElem
  if (store.unsignedContracts.length > 0) {
    let contract = store.unsignedContracts[0]
    let { date, title, file_url } = contract.data
    contractElem = (
      <Flex gap="1rem" align="center">
        <div>
          Подпишите оферту:{' '}
          <UiLink href={file_url} target="_blank" onTap={(e: any) => e.stopPropagation()}>
            {title}{' '}
            <span style={{ color: 'var(--color-secondary)' }}>от {printDate(date)}</span>
          </UiLink>
        </div>
        <Button
          design="normal"
          size="s"
          onTap={(e: any) => {
            store.openContractSignDialog(contract)
            e.preventDefault()
          }}
        >
          Подписать
        </Button>
      </Flex>
    )
  }
  let suspended = company.data.is_suspended && (
    <span style={{ color: 'var(--color-secondary)' }}>
      Компания приостановила работу с вами
    </span>
  )
  return (
    <ListItem
      as={Link}
      to={`/companies/${company.id}`}
      wrap
      style={{ alignItems: 'baseline' }}
    >
      <ListCell col="name" style={{ fontWeight: isMobile ? 600 : 'normal' }}>
        {company.data.name}
      </ListCell>
      <ListCell col="status">
        {inviteElem}
        {contractElem}
        {suspended}
      </ListCell>
    </ListItem>
  )
})

const CompanyListView = observer(
  ({ companies }: { companies: Collection<CompanyEntity> }) => {
    let { isMobile } = useMediaContext()
    return (
      <Layout>
        <Flex direction="column" gap="1.5rem">
          <LayoutHeading count={companies.items.length}>Компании</LayoutHeading>
          {companies.items.length === 0 ? (
            <Placeholder>Компаний нет</Placeholder>
          ) : (
            <List
              cols={{
                name: { width: 250, row: isMobile, style: { overflowWrap: 'anywhere' } },
                status: { row: isMobile },
              }}
            >
              {companies.items.map((company) => (
                <CompanyListItem company={company} />
              ))}
            </List>
          )}
        </Flex>
      </Layout>
    )
  }
)

const CompanyListPage = observer(() => {
  let api = useMobxApi()
  let fetchState = useMemo(() => api.fetch({ type: 'companies' }), [])
  return (
    <ActionStateView state={fetchState}>
      {(companies) => <CompanyListView companies={companies} />}
    </ActionStateView>
  )
})

export default CompanyListPage
