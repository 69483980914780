import Decimal from 'decimal.js'

import { nbsp } from './typo'

import { Amount, Currency } from 'entities'

export const currencySigns: { [key in Currency]: string } = {
  RUB: '₽',
  EUR: '€',
  USD: '$',
  PERCENT: '%',
}

interface NormalizedAmount {
  value: string
  currency: Currency
}

export const normalizeAmount = (amount: Amount): NormalizedAmount =>
  typeof amount === 'object'
    ? amount
    : {
        value: typeof amount === 'number' ? amount.toString() : amount,
        currency: 'EUR',
      }

export const formatAmount = (
  value: Amount,
  scale: number = 2,
  separator: string = ',',
  showCurrency: boolean = true
) => {
  const normalized = normalizeAmount(value)
  const str = String(Number(normalized.value).toFixed(scale))
  const formatted =
    Number(value) < 10000 ? str : str.replace(/(\d)(?=(\d{3})+(\.|$))/g, `$1${nbsp}`)
  const amount = formatted.replace(/.00$/, '').replace('.', separator)
  return `${amount}${nbsp}${showCurrency ? currencySigns[normalized.currency] : ''}`
}

const sumDecimalStrs = (strs: string[]) => {
  let sum = new Decimal(0)
  for (let i in strs) {
    if (strs[i] !== undefined) sum = sum.plus(new Decimal(strs[i]))
  }
  return sum.toFixed(2)
}

export const sumAmounts = (amounts: Amount[]): NormalizedAmount => ({
  value: sumDecimalStrs(amounts.map((a) => normalizeAmount(a).value)),
  currency: amounts.length > 0 ? normalizeAmount(amounts[0]).currency : 'RUB',
})
