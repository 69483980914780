import React from 'react'
import { makeAutoObservable } from 'mobx'
import { observer } from 'mobx-react-lite'

import { Entity, Collection, ActionState } from 'mobx/mobx'
import { PaginationEntity } from 'entities'
import { useMediaContext } from 'App/MediaContext'

import { Pagination, ErrorPlaceholder, LoadingPlaceholder, Flex } from 'components/ui'

// Это штука для загрузки постраничного контента с бэка

export type PageFetcher<T> = (options: {
  page: number
  abortSignal: any
}) => ActionState<Entity<PaginationEntity<T>>>

class PaginationStore<T> {
  constructor(fetcher: PageFetcher<T>, page: number = 1) {
    this.fetcher = fetcher
    this.page = page
    makeAutoObservable(this)
  }

  page: number = 1
  fetcher: PageFetcher<T>
  fetchState: ReturnType<PageFetcher<T>> = {} as ReturnType<PageFetcher<T>>
  fetchedValue?: Entity<PaginationEntity<T>>
  abortController?: AbortController

  fetch() {
    this.abortController?.abort()
    if (window.AbortController) this.abortController = new AbortController()
    this.fetchState = this.fetcher({
      page: this.page,
      abortSignal: this.abortController?.signal,
    })
    this.fetchState.then((value) => {
      this.fetchedValue = value
    })
  }

  setPage(page: number = 1) {
    this.page = page
    this.fetch()
  }
}

interface PaginationViewProps<T> {
  store: PaginationStore<T>
  render: (collection: Collection<T>, page: number) => React.ReactNode
  onChangePage: (page: number) => void
  style?: React.CSSProperties
}

const PaginationView = observer(
  <T,>({ store, render, onChangePage, style }: PaginationViewProps<T>) => {
    let { isMobile } = useMediaContext()
    if (store.fetchState.state === 'rejected') {
      return <ErrorPlaceholder>{String(store.fetchState.value)}</ErrorPlaceholder>
    }
    let isLoading = store.fetchState.state === 'pending'
    let value = store.fetchedValue
    return (
      <Flex direction="column" gap="1.5rem" style={{ position: 'relative', ...style }}>
        {value && render(value.data.items, value.data.page)}
        <div
          style={{
            position: 'absolute',
            left: 0,
            top: '-.5rem',
            right: 0,
            bottom: '-.5rem',
            background: 'radial-gradient(var(--color-background-secondary), transparent)',
            pointerEvents: isLoading ? 'auto' : 'none',
            opacity: isLoading ? 1 : 0,
          }}
        >
          <LoadingPlaceholder />
        </div>
        {value && value.data.total_pages > 1 && (
          <Pagination
            pageCount={value.data.total_pages}
            page={value.data.page}
            onChange={onChangePage}
            pagesRange={isMobile ? 1 : 2}
          />
        )}
      </Flex>
    )
  }
)

export { PaginationStore, PaginationView }
