import React from 'react'

import { Tag } from 'ui'

interface ISolidLabelsProps {
  items: string[]
  size?: 's' | 'm' | 'l'
  emptyLabel?: React.ReactNode
}

export const SolidLabels = ({ items, size, emptyLabel }: ISolidLabelsProps) => {
  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
      {items.map((item, idx) => (
        <Tag variant="grey" size={size} key={idx}>
          {item}
        </Tag>
      ))}
      {items.length === 0 && emptyLabel}
    </div>
  )
}

export default SolidLabels
