import React from 'react'

import { Stack } from 'ui/layers'
import { ToastContainer } from 'ui/toast'

const KonsolUIProvider = ({ children }: { children: React.ReactNode }) => (
  <Stack>
    <ToastContainer>{children}</ToastContainer>
  </Stack>
)

export { KonsolUIProvider }
