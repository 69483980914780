import React, { useState, forwardRef } from 'react'

import { Taply, TapState, initialTapState } from 'ui/taply'
import { useStyles, StyleProps } from 'ui/styled'

type SwitchStyleProps = [SwitchProps & typeof defaultProps, TapState]

interface SwitchProps extends StyleProps<SwitchStyleProps> {
  value?: boolean
  onChange?: (value: boolean) => void
  isDisabled?: boolean
  label?: React.ReactNode
}

const defaultProps = {
  value: false,
  isDisabled: false,
}

const switchStyles = (...[props, tapState]: SwitchStyleProps) => {
  const { value, isDisabled } = props

  let background
  if (value) {
    background = 'var(--konsol-color-primary)'
  } else {
    background = tapState.isHovered
      ? 'var(--konsol-color-grey-64)'
      : 'var(--konsol-color-grey-40)'
  }

  const root: React.CSSProperties = {
    cursor: 'pointer',
    WebkitTapHighlightColor: 'transparent',
    position: 'relative',
    borderRadius: 8,
    width: 32,
    height: 16,
    background,
    outline: 'none',
    boxShadow: tapState.isFocused ? 'var(--konsol-focus-shadow)' : 'none',
    transition: 'background .2s ease-in-out',
  }

  const thumb: React.CSSProperties = {
    width: 12,
    height: 12,
    borderRadius: '50%',
    background: 'white',
    position: 'absolute',
    left: value ? 18 : 2,
    top: 2,
    transition: 'left .2s ease-in-out',
  }

  if (isDisabled) {
    root.cursor = 'not-allowed'
    root.opacity = 0.4
  }

  return { root, thumb }
}

const Switch = forwardRef<HTMLDivElement, SwitchProps>((_props, ref) => {
  const props = _props as SwitchProps & typeof defaultProps
  const { value, onChange, isDisabled } = props
  const [tapState, setTapState] = useState(initialTapState)
  const styles = useStyles(switchStyles, [props, tapState])
  return (
    <Taply
      onChangeTapState={setTapState}
      onTap={() => onChange && onChange(!value)}
      isDisabled={isDisabled}
    >
      <div style={styles.root} ref={ref}>
        <div style={styles.thumb} />
      </div>
    </Taply>
  )
})

Switch.defaultProps = defaultProps

export { Switch }
