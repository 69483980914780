import React from 'react'

import { ListItem, ListCell, Flex, Link } from 'components/ui'
import { Badge, BadgeColor } from 'components/Badge'

import { useMediaContext } from 'App/MediaContext'

import { observer } from 'mobx-react-lite'
import { stringifyBase64Json } from 'utils/base64Json'

interface IIndicatorListItemProps {
  id: string;
  label: string;
  percent: number;
  quantity: number;
  comment: string;
  linkFilters?: {
    name: string;
    value: string;
  }[];
}

const percentColorMap: Record<number, string> = {
  0: 'green',
  20: 'orange',
  50: 'red',
};

const getPercentColor = (percent: number) => {

  const percentColorEntries = Object.entries(percentColorMap)
    .sort(([a], [b]) => Number(b) - Number(a));

  const percentColorIndex = percentColorEntries
    .findIndex(([value]) => percent >= Number(value));

  const percentColorEntry = percentColorEntries[percentColorIndex];

  return (percentColorEntry ? percentColorEntry[1] : 'gray') as BadgeColor;
};

export const IndicatorListItem = observer(({
  id,
  label,
  percent,
  quantity,
  comment,
  linkFilters = []
}: IIndicatorListItemProps) => {
  const { isMobile } = useMediaContext()

  const percentColor = getPercentColor(percent);

  const segmentFilter = linkFilters.find(({ name }) => name === 'segment');

  const viewLink = !!segmentFilter
    ? `/contractors/segment/${segmentFilter.value}/${stringifyBase64Json([
      {
        name: id,
        value: "1",
      },
    ])}`
    : `/contractors/${stringifyBase64Json([
      {
        name: id,
        value: "1",
      },
      ...linkFilters
    ])}`;

  if (!isMobile) {
    return (
      <ListItem>
        <ListCell col="label">
          <span>
            {label}
          </span>
        </ListCell>
        <ListCell col="percent">
          <Badge color={percentColor}>
            {`${percent}%`}
          </Badge>
        </ListCell>
        <ListCell col="quantity">
          <span>
            {quantity}
          </span>
        </ListCell>
        <ListCell col="comment">
          <span>
            {comment}
          </span>
        </ListCell>
        <ListCell col="contractor">
          <Link href={viewLink}>
            Просмотр
          </Link>
        </ListCell>
      </ListItem>
    )
  } else {
    return (
      <ListItem style={{ alignItems: 'stretch' }} padding={false} hover={false}>
        <ListCell
          style={{
            background: '#FFFFFF',
            boxShadow: '0px 8px 16px rgba(39, 46, 64, 0.04)',
            padding: '16px',
            borderRadius: '8px',
            position: 'relative',
          }}
        >
          <Flex direction="column" gap="5px">
            <Flex align='center'>
              <span style={{ fontWeight: 500 }}>
                {label}
              </span>
              <div style={{ flex: 1 }} />
              <span>
                <Badge color={percentColor}>
                  {`${percent}%`}
                </Badge>
              </span>
            </Flex>
            <Flex gap="1rem">
              <span style={{ color: 'gray', fontWeight: 500 }}>
                Кол-во:
              </span>
              <span>
                {quantity}
              </span>
            </Flex>
            <div style={{ display: 'inline-block' }}>
              <span style={{ color: 'gray', fontWeight: 500, marginRight: '5px' }}>
                Комментарий:
              </span>
              <span>
                {comment}
              </span>
            </div>
            <Flex gap="1rem">
              <span style={{ color: 'gray', fontWeight: 500 }}>
                Исполнители:
              </span>
              <span>
                <Link href={viewLink}>
                  Просмотр
                </Link>
              </span>
            </Flex>
          </Flex>
        </ListCell>
      </ListItem>
    );
  }
});

export default IndicatorListItem
