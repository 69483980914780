import React from 'react'

import { Flex, Button } from 'ui'

import s from './style.module.css'

interface IFormGroupProps {
  isLoading?: boolean
  isDisabled?: boolean
  additionalLink?: React.ReactNode
  additionalLinkAlign?: 'left' | 'center'
  error?: React.ReactNode
  onSubmit?: () => void
  submitText?: string
  children: React.ReactNode
}

const FormGroup = ({
  isLoading,
  isDisabled,
  additionalLink,

  error,
  onSubmit,
  submitText = 'Далее',
  children,
}: IFormGroupProps) => {
  return (
    <>
      <Flex className={s.formGroup} direction="col" gap="16px">
        {children}
      </Flex>
      {error && <div className={s.error}>{error}</div>}
      {onSubmit && (
        <Button
          isLoading={isLoading}
          isDisabled={isDisabled}
          onTap={onSubmit}
          size="l"
          style={{ width: '100%' }}
        >
          {!isLoading && submitText}
        </Button>
      )}
      {additionalLink && <div className={s.additionalLink}>{additionalLink}</div>}
    </>
  )
}

export default FormGroup
