export default [
  {
    title: 'Default',
    base_url: '/api',
  },
  {
    title: 'Alex',
    base_url: '/api-alex',
  },
  {
    title: 'Eric',
    base_url: '/api-eric',
  },
  {
    title: 'Mitya',
    base_url: '/api-mitchenko',
  },
  {
    title: 'Smurov',
    base_url: '/api-smurov',
  },
  {
    title: 'Sunra',
    base_url: '/api-sunra',
  },
  {
    title: 'Chook',
    base_url: '/api-chook',
  },
  {
    title: 'IvanE',
    base_url: '/api-iermakov',
  },
  {
    title: 'MikhailZ',
    base_url: '/api-mzadera'
  },
  {
    title: 'EB',
    base_url: '/api-eb'
  }
]
