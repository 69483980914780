import React, { useState } from 'react'
//@ts-ignore
import Taply from 'taply'
//@ts-ignore
import { useStyles } from 'floral'
import { without } from 'lodash'

import Flex from './Flex'

interface RadioInputOptionProps {
  isSelected: boolean
  onSelect: () => void
  children: React.ReactNode
  isLast?: boolean
}

const radioInputOptionStyles = (
  props: RadioInputOptionProps,
  { isHovered, isActive, isFocused }: any
) => ({
  root: {
    cursor: 'pointer',
    color: props.isSelected
      ? 'var(--color-active)'
      : isHovered || isActive
      ? '#666'
      : 'var(--color-secondary)',
    marginRight: props.isLast ? 0 : '1rem',
    boxShadow: isFocused ? '0 0 0px 3px rgb(83 23 254 / 0.4)' : '',
    borderRadius: 7,
    WebkitTapHighlightColor: 'transparent',
  },
})

const RadioInputOption = (props: RadioInputOptionProps) => {
  let { onSelect, children } = props
  let [tapState, setTapState] = useState({})
  let styles = useStyles(radioInputOptionStyles, [props, tapState])
  return (
    <Taply onChangeTapState={setTapState} onTap={onSelect}>
      <div style={styles.root}>{children}</div>
    </Taply>
  )
}

interface RadioInputProps<T = string>
  extends Omit<
    React.ComponentProps<typeof Flex>,
    'size' | 'onChange' | 'children' | 'value'
  > {
  options: { value: T; label: React.ReactNode }[]
  value: T | undefined
  onChange: (value: T) => void
  size: 'm' | 'l'
  components: {
    Option: React.ComponentType<any>
  }
}

const radioInputStyles = (props: RadioInputProps) => ({
  root: {
    fontSize: props.size === 'l' ? '1.25rem' : '1rem',
    padding: '4px 0',
  },
})

const RadioInput = <T,>(props: RadioInputProps<T>) => {
  let { options, value, onChange, components, size: _size, ...restProps } = props
  let styles = useStyles(radioInputStyles, [props])
  return (
    <Flex style={styles.root} {...restProps}>
      {options.map(({ value: optionValue, label }, index) =>
        React.createElement(components.Option, {
          key: String(optionValue),
          isSelected: value === optionValue,
          onSelect: () => onChange(optionValue),
          children: label,
          isLast: index + 1 === options.length,
        })
      )}
    </Flex>
  )
}

RadioInput.defaultProps = {
  size: 'm',
  components: { Option: RadioInputOption },
}

interface MultiRadioInputProps {
  options: { value: string; label: React.ReactNode }[]
  value: string[]
  onChange: (value: string[]) => void
  size: 'm' | 'l'
  components: {
    Option: React.ComponentType<any>
  }
}

const MultiRadioInput = (props: MultiRadioInputProps) => {
  let { options, value, onChange, components } = props
  let styles = useStyles(radioInputStyles, [props])
  let onSelect = (selectedValue: string) =>
    onChange(
      value.includes(selectedValue)
        ? without(value, selectedValue)
        : [...value, selectedValue]
    )
  return (
    <div style={styles.root}>
      {options.map(({ value: optionValue, label }, index) =>
        React.createElement(components.Option, {
          isSelected: value.includes(optionValue),
          onSelect: () => onSelect(optionValue),
          children: label,
          isLast: index + 1 === options.length,
        })
      )}
    </div>
  )
}

MultiRadioInput.defaultProps = {
  size: 'm',
  components: { Option: RadioInputOption },
}

export { RadioInput, MultiRadioInput, RadioInputOption }
