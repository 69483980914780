import React from 'react'
import { observer } from 'mobx-react-lite'

import { useUserStore } from 'stores/context'
import { useDialogStore } from 'stores/DialogStore'
import { Card, Button } from 'components/ui'
import { RejectedTransactionEntity } from 'entities'
import { Entity } from 'mobx/MobxApi'
import { ActCard } from './ActCard'
import { InvalidPaymentDetailsModal } from './InvalidPaymentDetailsModal'

import { ReactComponent as Pay } from 'ui/konsol/icons/pay.svg'

import css from './ActCard.module.css'

type TransactionRejectedCardProps = {
  transaction: Entity<RejectedTransactionEntity>
  onSuccess: () => void
}

const TransactionRejectedCard = observer(
  ({ transaction, onSuccess }: TransactionRejectedCardProps) => {
    let currentUser = useUserStore()
    let dialog = useDialogStore({
      component: InvalidPaymentDetailsModal,
    })
    let card = (
      <Card
        // icon={<Avatar size="36px" value={pay}></Avatar>}
        icon={
          <div className={css.iconPay}>
            <Pay width={36} height={36} />
          </div>
        }
        kind="error"
        title="Произошла ошибка при переводе"
        description="Из-за ошибки в реквизитах не удалось отправить перевод. Укажите корректные реквизиты, чтобы деньги ушли"
        actions={
          <Button
            size="s"
            onTap={() =>
              dialog.open({
                contractor: currentUser.user!.data.contractor!,
                transaction,
                onSuccess,
              })
            }
            style={{ width: '100%' }}
          >
            Изменить реквизиты
          </Button>
        }
      >
        <ActCard task={transaction.data.task} style={{ marginTop: '1rem' }} />
      </Card>
    )
    return (
      <>
        {card}
        {dialog.render()}
      </>
    )
  }
)

export default TransactionRejectedCard
