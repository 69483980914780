import * as React from 'react'
import browser from 'utils/browser'

type IDevice = {
  isMobile: boolean
  isIOS: boolean
  isAndroid: boolean
}

export function useDeviceDetect() {
  const [device, setDevice] = React.useState<IDevice>({
    isMobile: false,
    isIOS: false,
    isAndroid: false,
  })

  React.useEffect(() => {
    setDevice({
      isMobile: browser.platform.type === 'mobile',
      isIOS: browser.os.name === 'iOS',
      isAndroid: browser.os.name === 'android',
    })
  }, [])

  return device
}
