import React, {
  useState,
  useRef,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from 'react'
//@ts-ignore
import { useStyles } from 'floral'

import { FloralProps } from './floral.types'
import Input from './Input'

interface InplaceInputProps extends FloralProps {
  value?: string
  placeholder?: string
  onChange: (value: string) => void
  onCancel?: () => void
  isMultiline?: boolean
  inputComponent?: React.ComponentType | string
  inputProps?: any
  initialIsEditing?: boolean
  toggleOnClick?: boolean
  components?: { Input: React.ComponentType<any> }
}

let inplaceInputStyles = (props: InplaceInputProps) => ({
  text: {
    cursor: props.toggleOnClick ? 'pointer' : 'auto',
    borderBottom: '1px solid transparent',
    whiteSpace: props.isMultiline ? 'pre-wrap' : 'normal',
    padding: '4px 0',
  },
})

const InplaceInput = forwardRef((props: InplaceInputProps, ref) => {
  let {
    value,
    placeholder,
    onChange,
    onCancel,
    initialIsEditing,
    toggleOnClick,
    isMultiline,
    components,
  } = props
  let [isEditing, setIsEditing] = useState(initialIsEditing)
  let [inputValue, setInputValue] = useState(value || '')
  let inputRef = useRef<HTMLInputElement>(null)

  let styles = useStyles(inplaceInputStyles, [props, isEditing])

  useEffect(() => {
    if (isEditing) {
      setTimeout(() => inputRef.current?.focus())
    }
  }, [isEditing])

  useImperativeHandle(ref, () => ({
    toggle: toggleEdit,
  }))

  let toggleEdit = () => {
    setInputValue(value || '')
    setIsEditing(true)
  }

  let submitEdit = () => {
    if (inputValue !== value) onChange(inputValue)
    else if (onCancel) onCancel()
    setIsEditing(false)
  }

  let cancelEdit = () => {
    if (onCancel) onCancel()
    setIsEditing(false)
  }

  let onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!isMultiline && e.key === 'Enter') submitEdit()
    else if (e.key === 'Escape') cancelEdit()
  }

  let elem
  if (isEditing) {
    elem = React.createElement(components!.Input, {
      // @ts-ignore
      ref: inputRef,
      value: inputValue,
      placeholder,
      onKeyDown,
      onChange: setInputValue,
      onBlur: submitEdit,
      style: styles.input,
      isMultiline,
      isWide: true,
    })
  } else {
    elem = (
      <div onClick={toggleOnClick ? toggleEdit : undefined} style={styles.text}>
        {value && value.length > 0 ? (
          value
        ) : (
          <div style={{ color: 'var(--color-secondary)' }}>{placeholder}</div>
        )}
      </div>
    )
  }
  return <div style={styles.root}>{elem}</div>
})

InplaceInput.defaultProps = {
  initialIsEditing: false,
  toggleOnClick: true,
  inputComponent: 'input',
  isMultiline: false,
  components: { Input },
}

export default InplaceInput
