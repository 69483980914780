import React from 'react'
import cl from 'clsx'
import { InlineText, Typography, TypographyVariant } from 'ui-kit'

import css from './styles.module.css'
import { isNumber } from 'lodash'

export enum TabVariant {
  Title = 'title',
  Content = 'content',
  Page = 'page',
}

type TabProps = {
  variant: TabVariant
  titleTabVariant?:
    | TypographyVariant.H1
    | TypographyVariant.H2
    | TypographyVariant.H3
    | TypographyVariant.H4
  label: string
  active?: boolean
  count?: number
  className?: string
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

const Tab: React.FunctionComponent<TabProps> = ({
  variant = TabVariant.Page,
  titleTabVariant = TypographyVariant.H1,
  label,
  active = false,
  count,
  className,
  onClick,
}) => {
  if (variant === TabVariant.Title) {
    return (
      <button
        onClick={onClick}
        className={cl(css.tab, css.title, active && css.active, className && className)}
      >
        <Typography variant={titleTabVariant} className={css.titleLabel}>
          {label}
        </Typography>
      </button>
    )
  } else if (variant === TabVariant.Content) {
    return (
      <button
        onClick={onClick}
        className={cl(css.tab, css.content, active && css.active, className && className)}
      >
        <InlineText className={css.contentLabel}>{label}</InlineText>
        {count && (
          <>
            &nbsp;<InlineText className={css.contentCount}>{count}</InlineText>
          </>
        )}
      </button>
    )
  }

  return (
    <button
      onClick={onClick}
      className={cl(
        css.tab,
        css.page,
        active && css.active,
        isNumber(count) && css.withCount,
        className && className
      )}
    >
      <InlineText className={css.pageLabel}>{label}</InlineText>
      {isNumber(count) && <InlineText className={css.pageCount}>{count}</InlineText>}
    </button>
  )
}

export default Tab
