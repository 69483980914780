import React from 'react'
import { createContext } from 'react'
import { useContext } from 'react'

// @ts-ignore
import { FormStore } from 'shadowform'
import { PageProps } from 'app.types'

import {
  OnboardingScenarioDocumentItem,
  OnboardingScenario,
  OnboardingScenarioField,
} from 'entities/onboardingScenario'

import { makeAutoObservable, runInAction } from 'mobx'
import { useLocalObservable } from 'mobx-react-lite'

import { useMobxApi } from 'mobx/MobxApiContext'
import { useStateToast } from 'components/ui'
import { UserStore, useUserStore } from 'stores/context'

import { fetchApi } from 'api'
import { CustomField, Template } from 'entities'

type Toast = ReturnType<typeof useStateToast>
type Api = ReturnType<typeof useMobxApi>

const createCommonForm = (initialValues: any) => {
  const formStore = new FormStore({
    initialValues: {
      ...initialValues,
      name: initialValues.name || '',
    },
    fields: {
      name: {
        isRequired: true,
        requiredError: 'Укажите наименование сценария',
      },
      permalink: {
        isRequired: true,
        validations: {
          format: {
            validate: (value: string) => !!value.match(/^[a-z0-9_-]*$/i),
            error: 'Может содержать только латинские буквы и цифры',
          },
        },
        requiredError: 'Нужно заполнить ссылку',
      },
      contract_template_id: {},
      document_fields_ids: {},
      individual_entrepreneur: {},
      self_employed: {},
      gph: {},
      non_resident: {},
      check_contractor_spectrum_data: {},
      join_options: {},
    },
  })

  /**
   * Убирает ошибку отсутствия выбора налогооблажения
   */
  formStore.fields.individual_entrepreneur.on('change', () =>
    formStore.fields.join_options.change('')
  )
  formStore.fields.self_employed.on('change', () =>
    formStore.fields.join_options.change('')
  )
  formStore.fields.gph.on('change', () => formStore.fields.join_options.change(''))
  formStore.fields.non_resident.on('change', () =>
    formStore.fields.join_options.change('')
  )
  formStore.fields.check_contractor_spectrum_data.on('change', () =>
    formStore.fields.join_options.change('')
  )

  return formStore
}

class ScenarioModalStore {
  loading = false

  constructor(
    readonly api: Api,
    readonly pageProps: PageProps,
    readonly toast: Toast,
    readonly currentUser: UserStore,
    readonly onUpdateRowData?: (rawData: Partial<OnboardingScenario>) => void
  ) {
    makeAutoObservable(this)
  }

  commonForm?: FormStore

  private additionalFields = new Map<number, OnboardingScenarioField>()
  private usedFieldIds = new Set<string>()

  get additionalFieldList() {
    return [...this.additionalFields].map(([idx, field]) => ({
      field,
      idx,
    }))
  }

  get usedFieldList() {
    return [...this.usedFieldIds]
  }

  get canAddField() {
    return this.customFields.length && this.customFields.length > this.usedFieldIds.size
  }

  customFields: CustomField[] = []
  documentFields: OnboardingScenarioDocumentItem[] = []
  templates: Template[] = []

  private loadScenarioData = async (id: number) => {
    try {
      return (await fetchApi({
        url: `onboarding_scenarios/${id}`,
      })) as OnboardingScenario
    } catch (e: any) {
      this.toast.error({
        title: `Не удалось загрузить данные сценария`,
        description: e.message,
      })
      throw e
    }
  }

  private loadCustomFieldList = async () => {
    try {
      return (await fetchApi({ url: `contractors/custom_fields` })) as CustomField[]
    } catch (e: any) {
      this.toast.error({
        title: `Ошибка загрузки полей сценария`,
        description: e.message,
      })
      throw e
    }
  }

  private loadTemplatesList = async () => {
    try {
      return (await fetchApi({ url: `templates` })) as Template[]
    } catch (e: any) {
      this.toast.error({
        title: `Ошибка загрузки шаблонов договора`,
        description: e.message,
      })
      throw e
    }
  }

  private loadDocumentFieldList = async () => {
    try {
      return (await fetchApi({
        url: `onboarding_scenarios/document_fields`,
      })) as OnboardingScenarioDocumentItem[]
    } catch (e: any) {
      this.toast.error({
        title: `Ошибка загрузки списка документов`,
        description: e.message,
      })
      throw e
    }
  }

  updateFieldType = (idx: number, uuid: string) => {
    const field = this.additionalFields.get(idx)
    if (field) {
      this.usedFieldIds.delete(field.uuid)
      this.usedFieldIds.add(uuid)
      field.uuid = uuid
      field.default_value = ''
      field.required = false
    }
  }

  updateFieldFillTarget = (idx: number, fill_target: string) => {
    this.additionalFields.get(idx)!.fill_target = fill_target as 'contractor' | 'company'
  }

  updateFieldRequired = (idx: number, required: boolean) => {
    this.additionalFields.get(idx)!.required = required
  }

  updateFieldDefaultValue = (idx: number, default_value: string) => {
    this.additionalFields.get(idx)!.default_value = default_value
  }

  addField = () => {
    const idx =
      [...this.additionalFields.keys()].reduce((acm, cur) => Math.max(acm, cur), 0) + 1
    const uuid = this.customFields.find(({ id }) => !this.usedFieldIds.has(id))?.id || ''
    this.usedFieldIds.add(uuid)
    this.additionalFields.set(idx, {
      uuid,
      default_value: '',
      fill_target: 'contractor',
      required: false,
    })
  }

  removeField = (idx: number) => {
    if (this.additionalFields.has(idx)) {
      const { uuid } = this.additionalFields.get(idx)!
      this.usedFieldIds.delete(uuid)
    }
    this.additionalFields.delete(idx)
  }

  init = async (mode: 'create' | 'update', id?: number) => {
    this.loading = true
    try {
      const initialValues: any = {
        name: '',
        permalink: '',
        contract_template_id: undefined,
        individual_entrepreneur: false,
        document_fields_ids: '',
        self_employed: false,
        gph: false,
        non_resident: false,
      }
      const customFields = await this.loadCustomFieldList()
      const templates = await this.loadTemplatesList()
      const documents = await this.loadDocumentFieldList()
      runInAction(() => {
        this.customFields = customFields
        this.documentFields = documents
        this.templates = templates
      })
      if (mode === 'update') {
        const data = await this.loadScenarioData(id!)
        initialValues.name = data.title
        initialValues.permalink = data.permalink
        initialValues.contract_template_id = Number.isNaN(data.contract_template_id)
          ? undefined
          : data.contract_template_id
        initialValues.document_fields_ids = data.document_fields.join(',')

        initialValues.individual_entrepreneur = data.join_options.individual_entrepreneur
        initialValues.self_employed = data.join_options.self_employed
        initialValues.gph = data.join_options.gph
        initialValues.non_resident = data.join_options.non_resident
        initialValues.check_contractor_spectrum_data =
          data.join_options.check_contractor_spectrum_data

        if (data.contractor_custom_fields.length) {
          runInAction(() => {
            this.additionalFields.clear()
            this.usedFieldIds.clear()
            data.contractor_custom_fields.forEach((field, idx) => {
              this.additionalFields.set(idx, field)
              this.usedFieldIds.add(field.uuid)
            })
          })
        }
      }
      runInAction(() => {
        this.commonForm = createCommonForm(initialValues)
      })
      return true
    } catch (e) {
      console.log(e)
      this.clear()
      return false
    } finally {
      this.loading = false
    }
  }

  save = async (mode: 'create' | 'update', id?: number) => {
    try {
      const data = {
        name: this.commonForm?.values.name,
        permalink: this.commonForm?.values.permalink,
        contract_template_id: Number.isNaN(this.commonForm?.values.contract_template_id)
          ? null
          : this.commonForm?.values.contract_template_id,
        document_fields: this.commonForm?.values.document_fields_ids
          .split(',')
          .filter((v: string) => v.length),
        join_options: {
          individual_entrepreneur: this.commonForm?.values.individual_entrepreneur,
          self_employed: this.commonForm?.values.self_employed,
          gph: this.commonForm?.values.gph,
          non_resident: this.commonForm?.values.non_resident,
          check_contractor_spectrum_data:
            this.commonForm.values.check_contractor_spectrum_data,
        },
        contractor_custom_fields: this.additionalFieldList.map((item) => item.field),
      }

      if (!Object.values(data.join_options).reduce((acm, cur) => acm || cur)) {
        this.commonForm?.setErrors({
          join_options: 'Укажите статус налогооблажения',
        })
        return false
      }
      await fetchApi({
        method: mode === 'create' ? 'POST' : 'PATCH',
        url: mode === 'create' ? 'onboarding_scenarios' : `onboarding_scenarios/${id}`,
        data,
      })
      if (mode === 'update') {
        this.onUpdateRowData &&
          this.onUpdateRowData({
            contract_template_id: data.contract_template_id,
            contractor_custom_fields: data.contractor_custom_fields,
            document_fields: data.document_fields,
            permalink: data.permalink,
            title: data.name,
            id,
          })
      }
      this.toast.success({
        title: mode === 'create' ? 'Сценарий создан' : 'Изменения сохранены',
      })
      return true
    } catch (e: any) {
      this.toast.error({
        title: `Ошибка ${mode === 'create' ? 'создания' : 'сохранения'} сценария`,
        description: e.message,
      })
      const { validation } = e.data || {}
      validation && this.commonForm?.setErrors(validation)
      return false
    }
  }

  clear = () => {
    this.additionalFields.clear()
    this.usedFieldIds.clear()
    this.customFields = []
    this.documentFields = []
    this.commonForm = undefined
  }
}

const ScenarioContext = createContext<ScenarioModalStore>(null as never)

export const ScenarioModalStoreProvider = ({
  children,
  pageProps,
  onUpdateRowData,
}: React.PropsWithChildren<{
  pageProps: PageProps
  onUpdateRowData: (rawData: Partial<OnboardingScenario>) => void
}>) => {
  const currentUser = useUserStore()
  const toast = useStateToast()
  const api = useMobxApi()
  const store = useLocalObservable(() => {
    return new ScenarioModalStore(api, pageProps, toast, currentUser, onUpdateRowData)
  }, [pageProps, toast, api])
  return <ScenarioContext.Provider value={store}>{children}</ScenarioContext.Provider>
}

export const useScenarioModalStore = () => useContext(ScenarioContext)

export default useScenarioModalStore
