import React from 'react'
import { observer } from 'mobx-react-lite'
import { keyBy } from 'lodash'

import { Entity } from 'mobx/mobx'
import type { TaskEntity, Amount as AmountType } from 'entities'

import { Amount } from 'components'
import { List, ListItem, ListCell } from 'components/ui'
import { emdash } from 'utils/typo'
import { useMediaContext } from 'App/MediaContext'

interface ModalTaskListProps {
  tasks: Entity<TaskEntity>[]
  amounts?: { id: number; amount: AmountType }[]
  caption?: React.ReactNode
  banner?: React.ReactNode
}

const styles: { [key: string]: React.CSSProperties } = {
  caption: { fontSize: '1.15rem', fontWeight: 400 },
  list: {
    maxHeight: 400,
    overflowY: 'auto',
    paddingBottom: '1rem',
    borderBottom: '1px solid rgba(0,0,0,.15)',
  },
}

interface ModalTaskListItemProps {
  task: Entity<TaskEntity>
  amount?: AmountType
}

const ModalTaskListItem = observer(({ task, amount }: ModalTaskListItemProps) => {
  let docNumber = task.data.document_number?.number
  const { isMobile } = useMediaContext()
  return (
    <ListItem style={{ alignItems: 'start', padding: isMobile ? '.325rem' : '1rem' }}>
      <ListCell width={40} style={{ color: 'var(--color-secondary)' }}>
        {docNumber && (
          <div
            style={{
              fontSize: docNumber > 9999 ? '.85rem' : '1rem',
              lineHeight: '1.5rem',
            }}
          >
            {docNumber}
          </div>
        )}
      </ListCell>
      <ListCell grow={3}>{task.data.title || emdash}</ListCell>
      <ListCell grow={2}>
        {task.data.contractor ? task.data.contractor.data.name : emdash}
      </ListCell>
      <ListCell grow={2} style={{ textAlign: 'right', color: 'var(--color-secondary)' }}>
        <Amount value={amount !== undefined ? amount : task.data.act.amount} />
      </ListCell>
    </ListItem>
  )
})

const ModalTaskList = observer(({ caption, banner, tasks, amounts }: ModalTaskListProps) => {
  let amountsById = amounts && keyBy(amounts, 'id')
  return (
    <>
      {caption && <div style={styles.caption}>{caption}</div>}
      {banner}
      <div style={styles.list}>
        <List>
          {tasks.map((task: Entity<TaskEntity>) => (
            <ModalTaskListItem
              key={task.id}
              task={task}
              amount={amountsById ? amountsById[task.id].amount : undefined}
            />
          ))}
        </List>
      </div>
    </>
  )
})

export default ModalTaskList
