export const enum SpectrumDataReportStatus {
  created = 'created',
  in_progress = 'in_progress',
  completed = 'completed'
}

export interface ISpectrumDataReport {
  added_at: string;
  id: number;
  report_url: string;
  status: SpectrumDataReportStatus
}