import React from 'react'
import { Typography, TypographyProps, TypographyVariant } from 'ui-kit'

export type H4Props = Omit<TypographyProps, 'variant'>

const H4 = ({ children, ...restProps }: H4Props) => (
  <Typography variant={TypographyVariant.H4} {...restProps}>
    {children}
  </Typography>
)

export default H4
