import { makeAutoObservable } from 'mobx'
import { remove, cloneDeep } from 'lodash'

import { FiltersState } from 'entities'
import { useStateToast } from 'components/ui'

interface IKindFilterConfig {
  title: string
  kind: 'checkbox' | 'text' | 'number' | 'numberRange' | 'date'
  withNotFilled?: boolean
}

interface ISelectFilterConfig {
  title: string
  kind: 'multiselect' | 'select'
  options: Array<{ value: string; label: string }>
  withNotFilled?: boolean
}

export type FilterConfig = IKindFilterConfig | ISelectFilterConfig

export type FilterConfigs = { [key: string]: FilterConfig }

// Убирает удалённые поля и опции
const clearFilterState = (state: FiltersState, configs: FilterConfigs) =>
  state
    .filter((filter) => filter.name in configs)
    .map((filter) => {
      let config = configs[filter.name]
      if (config.kind === 'multiselect') {
        let options = config.options.map((option) => option.value)
        return {
          ...filter,
          value: filter.value
            ? filter.value.filter((option: string) => options.includes(option))
            : undefined,
        }
      } else {
        return filter
      }
    })

interface FiltersStoreProps {
  toast: ReturnType<typeof useStateToast>
  configs: { [key: string]: FilterConfig }
  initialState?: FiltersState
}

class FiltersStore {
  constructor({
    configs,
    toast,
    initialState,
  }: FiltersStoreProps) {
    this.configs = configs
    this.toast = toast

    if (initialState) this.setState(clearFilterState(initialState, configs))

    makeAutoObservable(this)
  }

  configs: { [key: string]: FilterConfig }
  toast: ReturnType<typeof useStateToast>
  currentState: FiltersState = []

  get state() {
    return this.currentState.filter((f) => f.value !== undefined)
  }

  hasFilter(name: string) {
    return this.currentState.some((f) => f.name === name)
  }

  addedFilter?: string

  addFilter(name: string) {
    this.currentState.push({ name, value: undefined })
    this.addedFilter = name
  }

  onRenderFilter(name: string, ref: any) {
    if (this.addedFilter === name) {
      this.addedFilter = undefined
      setTimeout(() => ref.open())
    }
  }

  removeFilter(name: string) {
    remove(this.currentState, (item) => item.name === name)
  }

  setFilter(name: string, value: any) {
    let filter = this.currentState.find((item) => item.name === name)
    filter!.value = value
  }

  setState(state: FiltersState) {
    this.currentState = cloneDeep(state)
  }
}


export default FiltersStore
