import React, { useState, createContext, useContext, forwardRef } from 'react'

import { Taply, TapState, initialTapState } from 'ui/taply'
import { useStyles, StyleProps } from 'ui/styled'
import { Flex } from 'ui/flex'

interface RadioGroupContextProps {
  onChange?: (value: string) => void
  value?: string
  isDisabled?: boolean
}

const RadioGroupContext = createContext<RadioGroupContextProps>({})

interface RadioGroupProps extends Omit<React.ComponentProps<typeof Flex>, 'onChange'> {
  onChange: (value: string) => void
  isDisabled?: boolean
  value?: string
}

const RadioGroup = (props: RadioGroupProps) => {
  const { value, onChange, isDisabled, ...flexProps } = props
  const context = { value, onChange, isDisabled }
  return (
    <RadioGroupContext.Provider value={context}>
      <Flex {...flexProps} />
    </RadioGroupContext.Provider>
  )
}

type RadioButtonStyleProps = [
  RadioButtonProps & typeof defaultProps,
  { isActive: boolean; isDisabled: boolean; tapState: TapState }
]

interface RadioButtonProps extends StyleProps<RadioButtonStyleProps> {
  value: string
  isDisabled?: boolean
  children?: React.ReactNode
}

const defaultProps = {
  isDisabled: false,
}

const radioButtonStyles = (
  ...[_props, { isActive, isDisabled, tapState }]: RadioButtonStyleProps
) => {
  const root: React.CSSProperties = {
    display: 'flex',
    alignItems: 'start',
    cursor: 'pointer',
    fontSize: 'var(--konsol-font-size)',
    WebkitTapHighlightColor: 'transparent',
    borderRadius: 6,
    width: 'fit-content',
    outline: 'none',
    lineHeight: '130%',
  }

  if (tapState.isFocused) root.boxShadow = 'var(--konsol-focus-shadow) inset'

  const control: React.CSSProperties = {
    width: 30,
    height: 30,
    borderRadius: '50%',
    flexShrink: 0,
    background: tapState.isHovered ? 'var(--konsol-color-grey-08)' : 'transparent',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }

  let background
  if (isActive) {
    background = 'var(--konsol-color-primary)'
  } else {
    background = tapState.isPressed
      ? 'var(--konsol-color-grey-64)'
      : 'var(--konsol-color-grey-40)'
  }

  const circle = {
    fill: 'white',
    borderRadius: '50%',
    width: 16,
    height: 16,
    background,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'background .1s ease-in-out',
  }

  const dot = {
    width: 8,
    height: 8,
    background: 'white',
    borderRadius: '50%',
  }

  const label: React.CSSProperties = {
    marginLeft: 2,
    marginRight: 7,
    marginTop: 7,
    marginBottom: 7,
  }

  if (isDisabled) {
    root.cursor = 'not-allowed'
    circle.background = 'var(--konsol-color-grey-16)'
    dot.background = 'var(--konsol-color-grey-40)'
    label.color = 'var(--konsol-color-text-disabled)'
  }

  return { root, control, circle, dot, label }
}

const RadioButton = forwardRef<HTMLDivElement, RadioButtonProps>(
  (_props: RadioButtonProps, ref) => {
    const props = _props as RadioButtonProps & typeof defaultProps
    const { value, children } = props
    const [tapState, setTapState] = useState(initialTapState)
    const context = useContext(RadioGroupContext)
    const isDisabled = Boolean(props.isDisabled || context.isDisabled)
    const isActive = context.value === value
    const styles = useStyles(radioButtonStyles, [
      props,
      { isActive, tapState, isDisabled },
    ])
    return (
      <Taply
        onChangeTapState={setTapState}
        onTap={() => context.onChange && context.onChange(value)}
        isDisabled={isDisabled}
      >
        <div style={styles.root} ref={ref}>
          <div style={styles.control}>
            <div style={styles.circle}>{isActive && <div style={styles.dot} />}</div>
          </div>
          {children && <div style={styles.label}>{children}</div>}
        </div>
      </Taply>
    )
  }
)

RadioButton.defaultProps = defaultProps

export { RadioButton, RadioGroup }
