import { createContext, useContext, useEffect, useState } from 'react'
import Pusher, { Channel } from 'pusher-js'
import Cookies from 'js-cookie'

import { baseUrl } from 'api/apiDefaults'

const pusherKey = process.env.REACT_APP_PUSHER_KEY
const pusherHost = process.env.REACT_APP_PUSHER_HOST

if (pusherKey) {
  // @ts-ignore
  Pusher.Runtime.createXHR = () => {
    let xhr = new XMLHttpRequest()
    xhr.withCredentials = true
    return xhr
  }
  let config = {
    authEndpoint: `${baseUrl}/authorizations/pusher`,
    auth: {
      headers: {
        ...(Cookies.get('x-api-token') && {
          'x-api-token': Cookies.get('x-api-token'),
        }),
      },
    },
  }
  if (pusherHost) {
    // @ts-ignore
    config['wsHost'] = pusherHost
  } else {
    // @ts-ignore
    config['cluster'] = 'eu'
  }
  // @ts-ignore
  window.pusher = new Pusher(pusherKey, config)
} else {
  // @ts-ignore
  window.pusher = new Pusher('4116e66406c2999a53d5', { cluster: 'eu' })
}

const usePusherChannel = (userId?: number) => {
  let [channel, setChannel] = useState<Channel | null>(null)
  useEffect(() => {
    setChannel((prevChannel: Channel | null) => {
      if (prevChannel) {
        //@ts-ignore
        window.pusher.unsubscribe(prevChannel.name)
      }
      if (userId) {
        let channelName = pusherKey ? `private-${userId}` : 'dev'
        //@ts-ignore
        return window.pusher.subscribe(channelName)
      }
      return null
    })
  }, [userId])
  return channel
}

const PusherChannelContext = createContext<Channel | undefined>(undefined)

const usePusherChannelContext = () => useContext(PusherChannelContext)

const usePusherSubscription = (event: string, callback: (event: any) => void) => {
  let callbackWithLog = (data: any) => {
    callback(data)
  }
  let channel = usePusherChannelContext()
  useEffect(() => {
    if (channel) {
      channel.bind(event, callbackWithLog)
    }
    return () => {
      if (channel) channel.unbind(event, callbackWithLog)
    }
  }, [channel])
}

export {
  PusherChannelContext,
  usePusherChannel,
  usePusherChannelContext,
  usePusherSubscription,
}
