import React from 'react'
import cl from 'clsx'
import { P1 } from 'ui-kit'

import css from './styles.module.css'

export type CheckboxProps = {
  name: string
  checked: boolean
  disabled?: boolean
  label?: string
  description?: string
  className?: string
  onChange: (checked: boolean) => void
}

const Checkbox: React.FunctionComponent<CheckboxProps> = ({
  name,
  checked,
  disabled = false,
  label,
  description,
  className,
  onChange,
}) => {
  const handleChange = () => onChange(!checked)
  const wideLabel = label || description

  return (
    <div
      className={cl(
        css.checkbox,
        wideLabel && css.wideLabel,
        disabled && css.disabled,
        className && className
      )}
    >
      <input
        type="checkbox"
        name={name}
        id={name}
        checked={checked}
        disabled={disabled}
        className={css.hiddenInput}
        onChange={handleChange}
      />
      <label className={css.label} htmlFor={name}>
        <div className={css.tickmark}></div>
        {wideLabel && (
          <div>
            <P1 className={css.labelText}>{label}</P1>
            {description && <P1 className={css.labelDescription}>{description}</P1>}
          </div>
        )}
      </label>
    </div>
  )
}

export default Checkbox
