import { fetchApi } from 'api'
import { useMediaContext } from 'App/MediaContext'
import { Button, Field, Flex, FormRow, Input } from 'components/ui'
import { Modal } from 'ui'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { useDialogStore } from 'stores/DialogStore'
import s from './styles.module.css'
import { Title } from 'components/ui/LayoutSimple'
// @ts-ignore
import { FormStore } from 'shadowform'
import { createDigitsMask, validateInn, validateInnLength } from 'utils/validations'

const styles = {
  description: {
    fontSize: '14px',
    lineHeight: '20px',
    marginBottom: '40px',
    textAlign: 'center' as const,
  },
  link: {
    color: 'var(--color-secondary)',
    textDecoration: 'none',
    textAlign: 'center' as const,
    marginTop: '20px',
  },
  infoLink: { color: 'var(--color-active)', alignSelf: 'start', marginTop: 8 },
}

const sberURL = 'https://www.sberbank.ru/ru/person/self_services_registration'

const createForm = () =>
  new FormStore({
    initialValues: {
      inn: '',
    },
    fields: {
      inn: {
        validations: {
          length: {
            validate: validateInnLength,
            error: 'Введите ИНН до конца',
          },
          valid: {
            validate: validateInn,
            error: 'Введите верный ИНН',
          },
        },
      },
    },
  })

type InnDialogProps = { onClose: () => void }

const InnDialog = observer(({ onClose }: InnDialogProps) => {
  const { isMobile } = useMediaContext()
  const [form] = React.useState(createForm)

  const isDisabled = !form.isValid || form.values.inn.length < 1

  const onSubmit = React.useCallback(() => {
    const { inn } = form.values

    if (inn && form.isValid) {
      fetchApi({
        url: 'users/events',
        method: 'PUT',
        data: { event: 'registration_sber_reward', inn },
      })
      fetchApi({
        url: 'registration/sber',
        method: 'POST',
        data: { inn },
      })
    } else {
      fetchApi({
        url: 'users/events',
        method: 'PUT',
        data: { event: 'registration_sber_without_inn' },
      })
    }
    onClose()
  }, [form.isValid, form.values, onClose])

  return (
    <Modal
      isOpen
      onClose={onClose}
      width={isMobile ? '97%' : 600}
      closeOnOverlayClick={false}
    >
      <Title>Укажите ИНН</Title>
      <p style={styles.description}>
        Для регистрации в качестве самозанятого <br /> нужно указать ИНН
      </p>

      <Flex justify="center" align="center" direction="column">
        <Field field={form.fields.inn}>
          {({ inputProps, error }) => (
            <FormRow error={error}>
              <Input
                {...inputProps}
                autoFocus
                placeholder="ИНН"
                mask={createDigitsMask(12)}
              />
            </FormRow>
          )}
        </Field>
        <a
          href="https://service.nalog.ru/inn.do"
          target="__blank"
          rel="noopener noreferrer"
          style={styles.infoLink}
        >
          Узнать ИНН по паспортным данным
        </a>

        <Button
          isDisabled={isDisabled}
          onTap={onSubmit}
          size="l"
          fluid
          as="a"
          href={isDisabled ? undefined : sberURL}
          target="_blank"
          rel="noopener noreferrer"
          style={{ marginTop: 40 }}
        >
          Перейти в Сбербанк Онлайн
        </Button>
        <a
          href={sberURL}
          target="_blank"
          rel="noopener noreferrer"
          onClick={onSubmit}
          style={styles.link}
        >
          Указать ИНН позже <br />и перейти в Сбербанк Онлайн
        </a>
      </Flex>
    </Modal>
  )
})

export const GoToSberbankButton = observer(() => {
  const dialog = useDialogStore({ component: InnDialog })
  return (
    <>
      <button
        className={s.sberLink}
        onClick={() => {
          fetchApi({
            url: 'users/events',
            method: 'PUT',
            data: { event: 'registration_sber' },
          })
          dialog.open({})
        }}
      >
        Перейти в Сбербанк Онлайн
      </button>
      {dialog.render()}
    </>
  )
})
