import React, { useEffect, useState } from 'react'
import cx from 'clsx'

// @ts-ignore
import css from './styles.module.css'

// @ts-ignore
import { useTheme } from 'ui/theme'

interface LogoProps {
  className?: string
  simple?: boolean
}

const Logo = (props: LogoProps) => {
  const { className, simple = false, ...restProps } = props
  const [logo, setLogo] = useState<any>()

  const { getLogo } = useTheme()

  useEffect(() => {
    getLogo(simple).then(setLogo)
  }, [])

  return (
    <div className={cx(css.root, className)} {...restProps}>
      {logo}
    </div>
  )
}

export { Logo }
