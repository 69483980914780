import React from 'react'

import { Flex } from 'components/ui'

import styles from './styles.module.css'

interface IButtonSelectAddWorkProps {
  onClick?: () => void
}

const ButtonSelectAddWork = ({ onClick }: IButtonSelectAddWorkProps) => {
  return (
    <Flex justify='space-between' align='center'>
      <div className={styles.notFoundText}>Ничего не найдено</div>
      <Flex className={styles.button} onClick={onClick} gap="5px">
        <span>Указать свою</span>
        <div className={styles.enter}>↩ Enter</div>
      </Flex>
    </Flex>
  )
}

export default ButtonSelectAddWork