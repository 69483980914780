import React, { useState, forwardRef } from 'react'
//@ts-ignore
import Taply from 'taply'
//@ts-ignore
import { useStyles } from 'floral'
import cx from 'clsx'
import { omit } from 'lodash'

import { ReactComponent as IosSpinnerIcon } from 'assets/ios-spinner.min.svg'

import Flex from './Flex'
import { FloralProps } from './floral.types'
import mergeRefs from './mergeRefs'

const omitFloralProps = (props: any) => omit(props, ['style', 'styles'])

export interface TapState {
  isPressed: boolean
  isHovered: boolean
  isFocused: boolean
}

export interface BaseButtonProps
  extends Omit<React.HTMLAttributes<HTMLElement>, 'style'>,
    FloralProps {
  as?: React.ComponentType<any> | string
  children: React.ReactNode
  isDisabled?: boolean
  onTap?: () => void
  className?: string
  fluid?: boolean
  [key: string]: any
}

export const focusShadow = '0 0 0px 2px rgb(83 23 254 / 0.3)'

const style = (props: BaseButtonProps, { isFocused }: TapState) => ({
  root: {
    WebkitTapHighlightColor: 'rgba(0,0,0,0)',
    outline: 'none',
    boxShadow: isFocused ? focusShadow : '',
  },
})

const BaseButton = forwardRef((props: BaseButtonProps, ref) => {
  const {
    children,
    onTap,
    className,
    classNames = {},
    isDisabled,
    ...restProps
  } = omitFloralProps(props)
  let [tapState, onChangeTapState] = useState({
    isPressed: false,
    isHovered: false,
    isFocused: false,
  })
  let styles = useStyles(style, [props, tapState])
  return (
    <Taply onTap={onTap} onChangeTapState={onChangeTapState} isDisabled={isDisabled}>
      {(tapState: TapState, taplyRef: React.RefObject<any>) =>
        React.createElement(
          props.as!,
          {
            className: cx(className, tapState.isHovered && classNames.hovered),
            ref: mergeRefs(ref, taplyRef),
            ...restProps,
            style: styles.root,
          },
          children
        )
      }
    </Taply>
  )
})

BaseButton.defaultProps = {
  as: 'button',
  size: 'm',
}

export interface ButtonProps extends BaseButtonProps {
  isLoading?: boolean
  size?: 'xs' | 's' | 'm' | 'l'
  design: 'normal' | 'accent' | 'text' | 'outline' | 'inline' | 'secondary'
}

const buttonStyles = (props: ButtonProps, tapState: TapState) => {
  let root: React.CSSProperties = {
    position: 'relative',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 25,
    cursor: props.isDisabled ? 'default' : 'pointer',
    textAlign: 'center',
    textDecoration: 'none',
    userSelect: 'none',
    whiteSpace: 'nowrap',
    transition: 'background, transform 0.2s ease-in-out',
    fontWeight: 500,
    willChange: 'transform',
    fontSize: '1rem',
    width: props.fluid ? '100%' : 'auto',
  }

  if (props.size === 'm') {
    Object.assign(root, {
      minHeight: '3rem',
      padding: '0 2rem',
      minWidth: 150,
    })
  } else if (props.size === 's') {
    Object.assign(root, {
      minHeight: 32,
      padding: '0 1.5rem',
    })
  } else if (props.size === 'xs') {
    Object.assign(root, {
      minHeight: 30,
      padding: '0 1rem',
      fontSize: '12px',
    })
  } else if (props.size === 'l') {
    Object.assign(root, {
      minHeight: '3.5rem',
      padding: '0 2rem',
      fontSize: '1.2rem',
    })
  }

  let shadow = ''
  if (props.design === 'normal') {
    shadow = `0 3px 10px 0 var(--konsol-color-primary-shadow), 0 0px 2px 0 var(--konsol-color-primary-shadow)`
    Object.assign(root, {
      color: '#272e40',
      background: 'white',
      boxShadow: shadow,
    })
  } else if (props.design === 'accent') {
    shadow = '0 4px 12px 0 var(--konsol-color-primary-shadow)'
    Object.assign(root, {
      color: 'white',
      // background: 'linear-gradient(to right, #8534fd, #3c0afe)',
      background: 'var(--konsol-gradient-primary)',
      boxShadow: shadow,
    })
  } else if (props.design === 'text') {
    Object.assign(root, {
      color: 'var(--color-active)',
      background: 'transparent',
      padding: 0,
      fontWeight: 600,
      fill: 'currentColor',
    })
  } else if (props.design === 'inline') {
    Object.assign(root, {
      color: 'var(--color-accent)',
    })
  } else if (props.design === 'secondary') {
    Object.assign(root, {
      color: 'var(--color-accent)',
      // background:
      //   'linear-gradient(90deg, rgba(133, 52, 253, 0.15) 12.06%, rgba(60, 10, 254, 0.15) 104.61%)',
      background: 'var(--konsol-color-light-background)',
      fontWeight: 600,
    })
  }

  if (props.isDisabled || props.isLoading) {
    Object.assign(root, {
      cursor: 'not-allowed',
      color: 'var(--color-secondary)',
      overflow: 'hidden',
      boxShadow: 'unset',
    })
    if (props.design !== 'text') {
      Object.assign(root, {
        background: 'var(--color-grey-1)',
      })
    }
  }

  let { isHovered, isPressed, isFocused } = tapState
  let scale = isPressed ? 0.97 : isHovered ? 1.02 : 1
  root.transform = `scale(${scale})`
  if (isHovered) root.opacity = 0.9
  if (isFocused) root.boxShadow = `${shadow}, ${focusShadow}`
  return { root }
}

const spinnerStyle: React.CSSProperties = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translateY(-50%) translateX(-50%)',
}

const Button = forwardRef((props: ButtonProps, ref) => {
  let { isDisabled, isLoading, children: _children, styles, ...rest } = props
  let buttonChildren = (
    <Flex gap=".5rem" align="center">
      {props.icon}
      <div>{props.children}</div>
      {isLoading && <IosSpinnerIcon style={spinnerStyle} />}
    </Flex>
  )
  return (
    <BaseButton
      {...rest}
      isDisabled={isDisabled || isLoading}
      children={buttonChildren}
      styles={[styles, buttonStyles]}
      ref={ref}
    />
  )
})

Button.defaultProps = {
  size: 'm',
  design: 'accent',
}

export { BaseButton, Button }
