import React from 'react'

import s from './style.module.css'

const Label = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className={s.label}>{children}</div>
  )
}

export default Label