import React, { CSSProperties } from 'react'
import cl from 'clsx'

import { Chip } from 'components'
import { Flex } from 'components/ui'

import s from './style.module.css'

type D<K extends string> = { id: K; title: string; [key: string]: any }
interface Props<T extends string> {
  // One prop is required: `items` or (`ids` and `map`)
  items?: readonly D<T>[]
  ids?: readonly T[]
  map?: { [key in T]: D<T> }
  value?: T
  onChange: (id: T) => void
  style?: CSSProperties
  className?: string
}

export default function Chips<K extends string>({
  items,
  ids,
  map = {} as { [key in K]: D<K> },
  value,
  onChange,
  style,
  className,
}: Props<K>) {
  return (
    <Flex className={cl(s.root, className)} style={style}>
      {(items || ids || ([] as D<K>[]))
        // @ts-ignore: TS-bug map: Each member of the union type <...> has signatures, but none of those signatures are compatible with each
        .map(renderChip)}
    </Flex>
  )

  function renderChip(_item: string | D<K>) {
    const { id, title }: D<K> = ['string', 'number'].includes(typeof _item)
      ? map[_item as K]
      : (_item as D<K>)

    const isActive = id === value
    return (
      <Chip
        key={id}
        onClick={isActive ? undefined : () => onChange(id)}
        active={isActive}
      >
        {title}
      </Chip>
    )
  }
}
