import React from 'react'

import { ReactComponent as RadioButtonUnchecked } from '@material-design-icons/svg/round/radio_button_unchecked.svg'
import { ReactComponent as RadioButtonChecked } from '@material-design-icons/svg/round/radio_button_checked.svg'

import { BaseButton, Flex } from 'components/ui'

interface OptionProps {
  children: React.ReactNode
  isSelected: boolean
  onSelect: () => void
}

export const Option = ({ children, isSelected, onSelect }: OptionProps) => (
  <BaseButton onTap={onSelect} style={{ borderRadius: '.5rem', textAlign: 'start' }}>
    <Flex gap=".5rem" align="center">
      {isSelected ? (
        <RadioButtonChecked fill="var(--color-active)" />
      ) : (
        <RadioButtonUnchecked fill="var(--color-secondary)" />
      )}
      <div style={{ flexGrow: 1 }}>{children}</div>
    </Flex>
  </BaseButton>
)