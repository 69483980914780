import React from 'react'

import { UserRegistrationEntity } from 'entities'
import { observer } from 'mobx-react-lite'
import { Entity } from 'mobx/MobxApi'
import { ConnectToPlatformStore } from 'pages/registration/store/ConnectToPlatformStore'

import PersonalDataStep from './components/PersonalDataStep'
import CyberityStep from './components/CyberityStep'
import DocumentsRequiredStep from './components/DocumentsRequiredStep'
import AdditionalDocumentsRequiredStep from './components/AdditionalDocumentsRequiredStep'

import CheckInProgressStep from './components/CheckInProgressStep'
import CheckErrorStep from './components/CheckErrorStep'

import {
  ADDITIONAL_DOCUMENTS_REQUIRED,
  CHECK_ERROR,
  CHECK_IN_PROGRESS,
  CYBERITY_VERIFICATION_REQUIRED,
  DOCUMENTS_REQUIRED,
  PERSONAL_DATA_REQUIRED,
} from '../../constants/statuses'

const FormStep = observer(
  ({
    registration,
    store,
  }: {
    registration: Entity<UserRegistrationEntity>
    store: ConnectToPlatformStore
  }) => {
    const { status } = registration.data
    const { company } = store.registration.data

    const steps: any = {
      [PERSONAL_DATA_REQUIRED]: (
        <PersonalDataStep registration={registration} store={store} />
      ),

      [CYBERITY_VERIFICATION_REQUIRED]: <CyberityStep registration={registration} />,
      [DOCUMENTS_REQUIRED]: <DocumentsRequiredStep registration={registration} />,

      [ADDITIONAL_DOCUMENTS_REQUIRED]: (
        <AdditionalDocumentsRequiredStep
          registration={registration}
          companyName={company?.name}
        />
      ),

      [CHECK_IN_PROGRESS]: <CheckInProgressStep registration={registration} />,
      [CHECK_ERROR]: <CheckErrorStep error={registration.data.error} />,
    }

    return steps[status] || steps['uncompleted']
  }
)

export default FormStep
