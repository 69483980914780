import React, { useRef } from 'react'
import cl from 'clsx'
import { DivProps } from 'app.types'

import { BaseButton, Flex } from 'components/ui'
import { ReactComponent as UploadPrimaryIcon } from 'assets/upload-primary.svg'

import s from './style.module.css'

interface Props extends Omit<DivProps, 'onChange' | 'ref'> {
  onChange: (files: File[]) => void
  files: File[]
  accept?: string
  title?: string
  multiple?: boolean
  disabled?: boolean
}

export default function DragnDropButton({
  onChange,
  accept,
  title = 'файлы',
  multiple,
  disabled,
  ...props
}: Props) {
  const fileInput = useRef<HTMLInputElement>(null)

  return (
    <>
      <BaseButton
        {...props}
        onTap={handleClick}
        isDisabled={disabled}
        className={cl(s.dragnDrop, { [s.disabled]: disabled })}
      >
        <Flex gap="2rem" align="center" justify="center">
          <UploadPrimaryIcon className={s.uploadIcon} />
          <span>
            Перетащите сюда <b>{title}</b> или{' '}
            <span className={s.dndBrowseLink}>выберите</span>
          </span>
        </Flex>
      </BaseButton>

      <input
        type="file"
        accept={accept}
        multiple={multiple}
        style={{ display: 'none' }}
        onChange={handleFilesSelect}
        ref={fileInput}
      />
    </>
  )

  function handleFilesSelect() {
    const files = fileInput.current?.files
    if (!files?.length) return
    onChange(Array.from(files))
    if (fileInput.current) fileInput.current.value = ''
  }

  function handleClick() {
    fileInput.current?.click()
  }
}
