// @ts-ignore
import { extendComponentStyles } from 'floral'

import { BaseButton } from 'components/ui'

const linkButtonStyles = () => ({
  root: {
    borderRadius: '.5rem',
    color: 'var(--color-active)',
    fill: 'var(--color-active)',
    fontWeight: 600,
    paddingRight: '0.5rem'
  },
})

export const LinkButton = extendComponentStyles(BaseButton, linkButtonStyles)