export const transformLinksInText = (text: string) =>
  text.replace(/(https?:\/\/[^\s]+)/gi, '<a class="link" target="_blank" href="$1">$1</a>')

function isDefined(value: any) {
  return value !== undefined && value !== null
}

// COPY: rocketbank/nasa-front/cc01c8a/src/utils/url.js
/**
 * Create url object.
 *
 * Multiple query keys compatible.
 *
 * @example Url('/api/make-awesome', { not_at_all: true })
 * @example Url() // catched the location
 *
 * @param url - absolute or related url
 * e.g. /foo, /bar?q=1, //ya.ru?q=bmw, /?really=yep, ?a[]=1&a[]=2
 *
 * @param queryObject - query parameters
 *
 * @returns window.URL instance
 */
export function Url(url?: string | Location, queryObject?: { [key: string]: any }): URL {
  const urlObject = url ? new window.URL(url as string, window.location.href) : new window.URL(window.location.href)

  if (!queryObject) return urlObject

  // Convert [['a[]', '1'], ['a[]', '2']] to URL#searchParams object
  Object.entries(queryObject).forEach(([key, val]) => {
    // remove query param from url string
    urlObject.searchParams.delete(key)

    if (Array.isArray(val)) {
      const key_ = /\[\]$/.test(key) ? key : `${key}[]`

      val.forEach((queryVal) => {
        // set query param from query object
        if (isDefined(queryVal)) urlObject.searchParams.append(key_, queryVal)
      })
    } else {
      if (isDefined(val)) urlObject.searchParams.append(key, val)
    }
  })

  return urlObject
}

/**
 * Get query object.
 *
 * Multiple query keys compatible (e.g. `?a[]=1&a[]=2`).
 *
 * @example queryObject(Url('/foo?q=test')) ==== { q: 'test' }
 *
 * @param {URL} urlObject window.URL instance
 *
 * @returns {Object}
 */
export function queryObject(urlObject: URL) {
  // ?a[]=1&a[]=2 --> [['a[]', '1'], ['a[]', '2']]
  const flatEntries = [...urlObject.searchParams.entries()]

  return flatEntries.reduce((res, [key, val]) => {
    // PERF: { ...res, [key]: val } was significantly slower
    // res[key] and val might be String or Array<String>
    res[key] = res[key] ? ([] as (number | string)[]).concat(res[key], val) : val

    return res
  }, {} as { [key: string]: string | number | (string | number)[] })
}

