import React from 'react'
import { useTransition } from 'react-spring'

interface AnimatedListProps<T> {
  items: Array<T>
  getId: (item: T) => any
  renderItem: (item: T, props: { open: any }, key?: string) => React.ReactNode
  isDisabled?: boolean
}

const AnimatedList = <T extends unknown>(props: AnimatedListProps<T>) => {
  let { items, renderItem, isDisabled } = props

  let transitions = useTransition(items, {
    initial: { open: 1 },
    from: { open: 0 },
    enter: { open: 1 },
    leave: { open: 0 },
    unique: true,
    immediate: isDisabled,
  })

  return <>{transitions((props, item) => renderItem(item, props))}</>
}

export default AnimatedList
