import { rest } from 'msw'
import { json } from './helpers'
import { user } from './user'

const join = {
  name: 'Компания с выплатой через Тиньков',
  avatar_url: 'https://via.placeholder.com/50x50',
}

const registration = {
  // disable_change_status: true, // показывает кнопку назад(изменить статус)
  join_options: {
    self_employed: true, // самозанятый (2 пункта) (я самозанятый, хочу стать самозанятым)
    individual_entrepreneur: true, // индивидуальный предприниматель
    non_resident: true, // не резиденты РФ с гпх
    gph: true, // физ лицо с гхп
  },
  start_url: '/join/test', // возврат на предыдущую страницу или /register или если пришло start_url то на start_url
  full_name: 'Борисов Еввгений Валерьевич',
  company: { name: 'Название компании' },
  payment_methods: ['card', 'bank_account'],
  payment_commission: {
    card: 'Комиссия 95%, сори',
  },
  allow_skip_payment_details: true,
  self_employed_data: {
    email: 'ilya@smurov.com',
    full_name: 'Смуров Илья Александрович',
  },
  form_options: {
    citizenship_options: [
      { value: 'kg', name: 'Киргизия' },
      { value: 'ru', name: 'Россия' },
      { value: null, name: null },
      { value: 'us', name: 'США' },
      { value: 'other', name: 'Другое' },
    ],
    personal_data: [
      {
        name: 'inn',
        type: 'inn',
        label: 'ИНН',
        is_required: false,
        placeholder: '',
      },
      {
        name: 'full_name',
        default_value: 'Райен Гослинг',
        type: 'text',
        label: 'ФИО',
        is_required: true,
        placeholder: 'Петров Алексей Михайлович',
      },
      {
        name: 'email',
        type: 'email',
        label: 'Email',
        is_required: false,
        placeholder: 'example@email.com',
        caption:
          'Укажите адрес почты, если хотите получать обновления по задачам и актам',
      },
      // {
      //   name: 'correspondence_address',
      //   type: 'text',
      //   label: 'Адрес для корреспонденции',
      //   is_required: true,
      //   placeholder: '',
      // },
      // {
      //   name: 'city',
      //   type: 'select',
      //   label: 'Город',
      //   is_required: true,
      //   options: [
      //     { value: 'Москва', name: 'Москва' },
      //     { value: 'Санкт-Петербург', name: 'Санкт-Петербург' },
      //     { value: 'Другой', name: 'Другой' },
      //   ],
      // },
      // {
      //   name: 'work_areas',
      //   type: 'multiselect',
      //   label: 'Специальность',
      //   is_required: true,
      //   options: [
      //     { value: 'design', name: 'Дизайн' },
      //     { value: 'front', name: 'Фронтенд' },
      //     { value: 'backend', name: 'Бэкенд' },
      //   ],
      // },
      // { is_required: true, name: 'text_field', type: 'text', label: 'Текстовое поле' },
      // { is_required: true, name: 'file_field', type: 'file', label: 'Загрузите файл' },
      // {
      //   is_required: true,
      //   name: 'checkbox_field',
      //   type: 'checkbox',
      //   label: 'Согласитесь с условиями',
      // },
    ],
    documents: [
      {
        is_required: true,
        name: 'checkbox_field',
        type: 'checkbox',
        label: 'Согласитесь с условиями',
      },
      {
        caption: 'Паспорт',
        is_required: true,
        name: 'passport_main',
        type: 'file',
        label: 'Основной разворот паспорта',
        icon: 'passport_main',
      },
      {
        // caption: "Паспорт",
        is_required: true,
        name: 'passport_registration',
        type: 'file',
        label: 'Разворот паспорта с пропиской',
        icon: 'passport_registration',
      },
      {
        caption: 'Паспорт',
        is_required: true,
        name: 'passport_selfie',
        type: 'file',
        label: 'Селфи с паспортом',
        icon: 'selfie',
      },
    ],
    additional_documents: [
      {
        is_required: true,
        name: 'passport_main',
        type: 'file',
        label: 'Основной разворот паспорта',
        icon: 'passport_main',
      },
      {
        caption: 'Паспорт',
        is_required: true,
        name: 'passport_selfie',
        type: 'file',
        label: 'Селфи с паспортом',
        icon: 'selfie',
      },
      {
        name: 'work_areas',
        type: 'multiselect',
        label: 'Специальность',
        is_required: true,
        options: [
          { value: 'design', name: 'Дизайн' },
          { value: 'front', name: 'Фронтенд' },
          { value: 'backend', name: 'Бэкенд' },
        ],
      },
      { is_required: true, name: 'text_field', type: 'text', label: 'Текстовое поле' },
      { is_required: true, name: 'file_field', type: 'file', label: 'Загрузите файл' },
      {
        is_required: true,
        name: 'checkbox_field',
        type: 'checkbox',
        label: 'Согласитесь с условиями',
      },
      {
        name: 'city',
        type: 'select',
        label: 'Город',
        is_required: true,
        options: [
          { value: 'Москва', name: 'Москва' },
          { value: 'Санкт-Петербург', name: 'Санкт-Петербург' },
          { value: 'Другой', name: 'Другой' },
        ],
      },
    ],
    citizenship_country_tax_residence: true,
  },
  new_self_employed_registration: true,
  payment_details_required: true, // показываем форму ввода банковских реквизитов или карты
  cyberity_verification: {
    token: '_act-sbx-f623b19e-c097-4425-91a4-f4bcbcebfc0c',
  },
}

const registration_uncompleted = {
  ...registration,
  form_options: {
    citizenship_options: [
      { value: 'kg', name: 'Киргизия' },
      { value: 'ru', name: 'Россия' },
      { value: null, name: null },
      { value: 'us', name: 'США' },
      { value: 'other', name: 'Другое' },
    ],
    personal_data: [
      {
        name: 'inn',
        type: 'inn',
        label: 'ИНН',
        is_required: false,
        placeholder: '',
      },
    ],
    documents: [
      {
        caption: 'Заголовок',
        is_required: true,
        name: 'passport_main',
        type: 'file',
        label: 'Основной разворот паспорта',
        icon: 'passport_main',
      },
      {
        caption: 'Заголовок ',
        is_required: true,
        name: 'passport_registration',
        type: 'file',
        label: 'Разворот паспорта с пропиской',
        icon: 'passport_registration',
      },
      {
        caption: 'Заголовок',
        is_required: true,
        name: 'passport_selfie',
        type: 'file',
        label: 'Селфи с паспортом',
        icon: 'selfie',
      },
      {
        caption: 'Заголовок dfsdfgdfg',
        is_required: true,
        name: 'passport_registration',
        type: 'file',
        label: 'Разворот паспорта с пропиской',
        icon: 'passport_registration',
      },
      {
        caption: 'Заголовок',
        is_required: true,
        name: 'passport_selfie',
        type: 'file',
        label: 'Селфи с паспортом',
        icon: 'selfie',
      },
    ],
    additional_documents: [
      {
        caption: 'Заголовок',
        is_required: true,
        name: 'passport_main',
        type: 'file',
        label: 'Основной разворот паспорта',
        icon: 'passport_main',
      },
      {
        caption: 'Заголовок',
        is_required: true,
        name: 'passport_selfie',
        type: 'file',
        label: 'Селфи с паспортом',
        icon: 'selfie',
      },
    ],
    citizenship_country_tax_residence: true,
  },
  status: 'uncompleted',
}

const registration_documents = { ...registration, status: 'personal_data_required' }

const registration_check_in_progress = { ...registration, status: 'check_in_progress' }

// шаг с загрузкой основных документов
const registration_documents_required = { ...registration, status: 'documents_required' }

const registration_completed = { ...registration, status: 'completed' }

const wrongCode = { error: { message: 'Неправильный код' } }

export default [
  rest.put('/api/registration/verify', (req: any, res: any, ctx: any) => {
    if (req.body.code !== '0000') {
      return res(ctx.delay(500), ctx.status(403), ctx.json(wrongCode))
    } else {
      return res(ctx.delay(500), ctx.json(user))
    }
  }),

  rest.get('/api/registration/info', (req: any, res: any, ctx: any) => {
    const permalink = req.url.searchParams.get('permalink')
    if (permalink === 'error') {
      return res(ctx.delay(500), ctx.status(404), ctx.json({}))
    } else {
      return res(ctx.delay(500), ctx.json(join))
    }
  }),

  rest.post('/api/registration', (req: any, res: any, ctx: any) => {
    if (req.body.mobile_phone === '+70000000000') {
      return res(
        ctx.delay(500),
        ctx.status(403),
        ctx.json({ error: { message: 'Ошибка сервера' } })
      )
    } else {
      return res(ctx.delay(500), ctx.json(join))
    }
  }),

  rest.get('/api/contractor/registration', json(registration_uncompleted)),
  // rest.get('/api/contractor/registration', json(registration_check_error)),
  // rest.get('/api/contractor/registration', json(registration_documents)),
  // rest.get('/api/contractor/registration', json(registration_bank_details)),
  // rest.get('/api/contractor/registration', json(registration_no_contract)),
  // rest.get('/api/contractor/registration', json(registration_cancelled)),
  // rest.get(
  //   '/api/contractor/registration',
  //   json(registration_registered_as_self_employed)
  // ),

  rest.put(
    '/api/registration/join_as_individual_entrepreneur',
    json({ ...registration, status: 'check_in_progress' })
  ),

  // rest.put(
  // '/api/registration/join_as_self_employed',
  // json({ result: 'fail', message: 'Сообщение об ошибке' })
  // ),
  rest.put('/api/registration/join_as_self_employed', json({ result: 'request_sent' })),
  rest.put(
    '/api/registration/join_as_self_employed',
    json({ result: 'success', registration: registration_documents })
  ),
  // rest.put(
  //   '/api/registration/join_as_self_employed',
  //   json({ result: 'not_self_employed' })
  // ),

  // rest.put(
  //   '/api/registration/register_self_employed',
  //   json({ result: 'already_self_employed' })
  // ),

  rest.put(
    '/api/registration/join_as_non_resident',
    json({ ...registration, status: 'personal_data_required' })
  ),

  rest.put(
    '/api/registration/join_as_gph',
    // json({ ...registration, status: 'personal_data_required' })
    json({ ...registration, status: 'documents_required' })
    // json({ ...registration, status: 'additional_documents_required' })
    // json({ ...registration, status: 'manual_verification_required' })
    // json({ ...registration, status: 'cyberity_verification_required' })
    // json({ ...registration_contract, status: 'check_error' })
  ),

  // rest.put(
  // '/api/registration/register_self_employed',
  // json({ result: 'success', registration: { ...registration, status: 'personal_data_required' } })
  // ),

  // rest.put('/api/registration/documents', json(registration_bank_details)),
  rest.put(
    '/api/registration/personal_data',
    json({
      status: 'success',
      result: registration_documents_required,
    })
  ),
  // rest.put(
  //   '/api/registration/documents',
  //   error(500, { error: { message: 'Сообщение' } })
  // ),

  rest.put(
    '/api/registration/submit_documents',
    json({
      ...registration_documents_required,
      status: 'additional_documents_required',
    })
  ),

  rest.put(
    '/api/registration/submit_additional_documents',
    json({
      ...registration_documents_required,
      status: 'manual_verification_required',
    })
  ),

  rest.patch('/api/registration/documents', (req: any, res: any, ctx: any) =>
    res(ctx.delay(2000), ctx.json({}))
  ),
  // rest.patch('/api/registration/documents', error(500, {})),

  rest.put('/api/registration/payment_details', json(registration_check_in_progress)),

  rest.put(
    '/api/registration/skip_payment_details',
    json({
      ...registration_completed,
      payment_details_required: false,
      status: 'manual_verification_required',
    })
  ),
]
