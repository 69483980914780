import React, { forwardRef, useState } from 'react'

import { useStyles, StyleProps } from 'ui/styled'
import { Taply, initialTapState, TapState } from 'ui/taply'
import { Tooltip } from 'ui/konsol/tooltip'
import { ReactComponent as CopyIcon } from 'ui/konsol/icons/copy.svg'
import { PopupPlacement } from 'ui/popup/PopupController'
import mergeRefs from 'ui/utils/mergeRefs'

interface CopyButtonProps extends StyleProps<[CopyButtonProps]> {
  onTap: () => void
  copyText: string
  placement?: Partial<PopupPlacement>
}

const copyButtonStyles = (
  props: CopyButtonProps,
  { isHovered, isPressed, isFocused }: TapState
) => ({
  root: {
    borderRadius: 6,
    width: 20,
    height: 20,
    opacity: isHovered || isPressed ? 1 : 0.4,
    background: isHovered || isPressed ? 'var(--konsol-color-blue-16)' : 'transparent',
    transition: 'opacity background .15s ease-in-out',
    fill: 'var(--konsol-color-primary)',
    cursor: 'pointer',
    boxShadow: isFocused ? 'var(--konsol-focus-shadow)' : 'none',
  },
})

const CopyButton = forwardRef<HTMLDivElement, CopyButtonProps>(
  (props: CopyButtonProps, ref) => {
    const { copyText, placement } = props
    const [tapState, setTapState] = useState(initialTapState)

    const [tooltipIsOpen, setTooltipIsOpen] = useState(false)
    const [justCopied, setJustCopied] = useState(false)

    const onCopy = () => {
      navigator.clipboard.writeText(copyText)
      setJustCopied(true)
      setTimeout(() => setJustCopied(false), 1000)
    }

    const styles = useStyles(copyButtonStyles, [props, tapState])

    return (
      <Tooltip
        tooltip={justCopied ? 'Скопировано' : 'Скопировать'}
        // @ts-ignore
        isOpen={justCopied || tooltipIsOpen}
        onChangeIsOpen={setTooltipIsOpen}
        placement={placement}
      >
        {(tooltipRef, renderProps) => (
          <Taply
            onTap={() => {
              onCopy()
              renderProps.onTap()
            }}
            tapState={tapState}
            onChangeTapState={(tapState) => {
              setTapState(tapState)
              renderProps.onChangeTapState(tapState)
            }}
          >
            <div style={styles.root} ref={mergeRefs(ref, tooltipRef as any)}>
              <CopyIcon />
            </div>
          </Taply>
        )}
      </Tooltip>
    )
  }
)

export { CopyButton }
