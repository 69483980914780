import React from 'react'
import clsx from 'clsx'

import { GlobalBanner } from 'entities'
import Flex from 'components/ui/Flex'
import { ReactComponent as DangerIcon } from 'assets/danger.svg'
import { ReactComponent as IconNotice } from 'assets/icon-notice.svg'

import classes from './styles.module.css'

const iconComponentMap: Record<string, React.ComponentType> = {
  danger: DangerIcon,
  notice: IconNotice,
}

interface IBannerProps extends GlobalBanner {
  className?: string
  children?: React.ReactNode
  style?: React.CSSProperties
}

export const Banner = ({
  icon = 'danger',
  title,
  className,
  children,
  color,
  background,
  style = {
    color,
    background,
  },
}: IBannerProps) => {
  const IconComponent = iconComponentMap[icon] || DangerIcon

  return (
    <Flex
      className={clsx(className, classes.wrapper)}
      align="normal"
      gap="10px"
      style={style}
    >
      <IconComponent className={classes.icon} />
      {title && <h6>{title}</h6>}
      {children && <div>{children}</div>}
    </Flex>
  )
}

export default Banner
