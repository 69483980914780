import React from 'react'
import { observer } from 'mobx-react-lite'
import { map } from 'lodash'

import { Chips } from 'components'

import SectionedStore from './SectionedStore'

interface SectionsChipsProps<T> {
  store: SectionedStore<T>
  sections: { [key: string]: string }
  onChange?: (section: string) => void
}

const SectionsChips = <T,>({ store, sections, onChange }: SectionsChipsProps<T>) => {
  let items = map(sections, (title, section) => ({
    id: section,
    title: `${title} ${store.counters[section].get()}`,
  }))
  return (
    <Chips
      value={store.section!}
      onChange={(section) => {
        if (onChange) onChange(section)
        else store.section = section
      }}
      items={items}
    />
  )
}

export default observer(SectionsChips)

