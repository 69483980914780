import React from 'react';
import { useReducer } from 'react';

import { ReactComponent as MarkIcon } from '../assets/mark.svg';

import classes from '../styles.module.css';

import clsx from 'clsx';

interface IMarkProps {
    className?: string;
    appear?: boolean;
    onAnimationEnd?: () => void;
    style?: React.CSSProperties;
}

interface IState {
    animation: AnimationState;
}

type AnimationState = 'first-animation' | 'second-animation';

const animationMap: Record<AnimationState, string> = {
    "first-animation": classes.markFirstAnimation,
    "second-animation": classes.markSecondAnimation,
};

export const Mark = ({
    className,
    style,
    appear,
    onAnimationEnd,
}: IMarkProps) => {

    const reducer = (state: IState): IState => {
        let { animation } = state;
        if (animation === 'first-animation') {
            animation = 'second-animation';
        } else if (animation === 'second-animation') {
            onAnimationEnd && onAnimationEnd();
        }
        return {
            animation,
        };
    };

    const [{ animation }, dispatch] = useReducer(reducer, {
        animation: 'first-animation',
    });

    return (
        <div className={clsx(className, classes.markRoot)} style={style}>
            <div
                className={clsx(classes.mark, {
                    [animationMap[animation]]: appear,
                })}
                onAnimationEnd={dispatch}
            >
                <MarkIcon
                    className={classes.markIcon}
                />
            </div>
        </div>
    );

};

export default Mark;
