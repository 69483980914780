import React, { useMemo } from 'react'
import { reaction, makeAutoObservable } from 'mobx'
import { Dayjs } from 'dayjs'
// @ts-ignore
import { FormStore } from 'shadowform'
import { observer } from 'mobx-react-lite'

import { Entity, Collection, ActionState, Data, initialActionState } from 'mobx/mobx'
import { DocumentTemplateEntity, DocumentTemplateField, DocumentEntity } from 'entities'
import {
  FormRow,
  Field,
  Input,
  Col,
  SelectInput,
  Checkbox,
  Flex,
  Button,
} from 'components/ui'
import { Chips } from 'components'
import dayjs from 'utils/dayjs'
import { createDecimalMask, dateMask } from 'utils/validations'

import NumberInput from 'components/NumberInput'
import { useUserStore, UserStore } from 'stores/context'
import FileInput from 'components/FileInput'

const makeTemplateFieldsForm = (
  fields: DocumentTemplateField[],
  values?: { [key: string]: number | string }
) => {
  let formFields: { [key: string]: any } = {}
  let initialValues: { [key: string]: number | string } = {}
  fields.forEach((field) => {
    let { key, type, is_required } = field
    let config: any = {
      isRequired: is_required,
      requiredError: 'Необходимо заполнить это поле',
    }
    if (type === 'date') {
      config.normalize = (value: string) =>
        value ? dayjs(value, 'DD.MM.YYYY', true) : null
      config.validations = {
        valid: {
          validate: (value: Dayjs) => value.isValid(),
          error: 'Введите правильную дату в формате ДД.ММ.ГГГГ',
        },
      }
    }
    if (values) {
      initialValues[key] = values[key]
    }
    formFields[key] = config
  })
  return new FormStore({ initialValues, fields: formFields })
}

const dateFormat = 'DD.MM.YYYY'

interface CreateFormProps {
  type: 'contracts' | 'documents'
  from: 'file' | 'template'
  contracts?: Entity<DocumentEntity>[]
  user: UserStore
}

const makeCreateForm = ({ type, from, contracts, user }: CreateFormProps) => {
  let fields: any = {}
  let initialValues: any = {}
  if (from === 'file') {
    fields.file = {
      isRequired: true,
      requiredError: 'Выберите файл',
    }
    fields.title = {
      isRequired: true,
      requiredError: 'Не заполнено название',
    }
    if (type === 'contracts' && user.company?.data.features.contract_en_title) {
      fields.title_en = {
        isRequired: false,
      }
    }
    fields.number = {
      isRequired: true,
      requiredError: 'Не указан номер',
    }
    fields.date = {
      isRequired: true,
      requiredError: 'Не указана дата',
      normalize: (value: string) => dayjs(value, dateFormat, true),
      validations: {
        isValid: {
          error: 'Неверная дата',
          validate: (date: dayjs.Dayjs) => date.isValid(),
        },
      },
    }
    fields.signed = {}
  }
  if (type === 'documents') {
    fields.require_sign = {}
    initialValues.require_sign = 'all'
    fields.contract_id = {}
    initialValues.contracts = contracts![0]?.id
  }
  return new FormStore({ fields, initialValues })
}

interface DocumentCreateStoreProps {
  type: 'contracts' | 'documents'
  templates: Collection<DocumentTemplateEntity>
  disabledTemplateIds: number[]
  contracts?: Entity<DocumentEntity>[]
  onSubmit: (values: any) => ActionState<Data>
  user: UserStore
}

class DocumentCreateStore {
  constructor({ user, ...props }: DocumentCreateStoreProps) {
    Object.assign(this, props)

    this.from = this.templates.items.length > 0 ? 'template' : 'file'
    this.template = this.from === 'template' ? this.templates.items[0] : undefined
    this.makeForm(user)
    this.makeTemplateForm()

    reaction(
      () => this.from,
      (from) => {
        this.makeForm(user)
        this.template = from === 'template' ? this.templates.items[0] : undefined
      }
    )
    reaction(
      () => this.template,
      () => this.makeTemplateForm()
    )

    makeAutoObservable(this)
  }

  makeForm(user: UserStore) {
    this.form = makeCreateForm({
      type: this.type,
      from: this.from,
      contracts: this.contracts,
      user,
    })
  }

  makeTemplateForm() {
    this.templateFieldsForm = this.template
      ? makeTemplateFieldsForm(this.template.data.template_fields)
      : undefined
  }

  type!: 'contracts' | 'documents'
  from!: 'file' | 'template'
  onSubmit!: (values: any) => ActionState<Data>
  templates!: Collection<DocumentTemplateEntity>
  contracts?: Entity<DocumentEntity>[]
  disabledTemplateIds!: number[]
  form: FormStore
  template?: Entity<DocumentTemplateEntity>
  templateFieldsForm?: FormStore

  get isTemplateDisabled() {
    return this.template && this.disabledTemplateIds.includes(this.template.id)
  }

  get isValid() {
    if (this.from === 'template') {
      return (
        this.form.isValid &&
        this.template &&
        !this.isTemplateDisabled &&
        this.templateFieldsForm.isValid
      )
    } else {
      return this.form.isValid
    }
  }

  get values() {
    let values: Data = { ...this.form.values }
    if (this.from === 'template') {
      Object.assign(values, {
        template_id: this.template!.id,
        template_fields: this.templateFieldsForm.values,
      })
    }
    return values
  }

  submitState = initialActionState

  submit() {
    this.submitState = this.onSubmit(this.values)
    return this.submitState
  }
}

const inputMasks = {
  date: dateMask,
  number: createDecimalMask(false),
  text: undefined,
  select: undefined,
  checkbox: undefined,
}

interface TemplateFieldViewProps {
  form: FormStore
  field: DocumentTemplateField
}

const TemplateFieldView = observer(({ form, field }: TemplateFieldViewProps) => (
  <Field field={form.fields[field.key]}>
    {({ inputProps, error }) => {
      let input
      if (field.type === 'select') {
        let options: any[] | undefined
        let value: any = null
        if (field.options) {
          if (typeof field.options[0] === 'string') {
            options = field.options!.map((option) => ({
              value: option,
              label: option,
            }))
          } else {
            options = field.options
          }
        }
        if (inputProps.value) {
          value = options?.find(({ value }) => value === inputProps.value) || null
        }
        input = (
          <SelectInput
            {...inputProps}
            onChange={(value) => inputProps.onChange(value ? value.value : null)}
            value={value}
            isWide
            size="l"
            placeholder="Выберите или введите значение"
            options={options}
            isCreatable
            formatCreateLabel={(inputValue: string) => <div>Ввести "{inputValue}"</div>}
            isClearable
          />
        )
      } else if (field.type === 'number') {
        let placeholder = 'Введите значение'
        input = (
          <NumberInput
            {...inputProps}
            isWide
            size="l"
            placeholder={placeholder}
            allowDecimal={true}
          />
        )
      } else if (field.type === 'checkbox') {
        input = (
          <Checkbox
            {...inputProps}
            isWide
            size="l"
            style={{
              marginTop: 5,
            }}
          />
        )
      } else {
        let placeholder =
          field.type === 'date' ? 'Введите дату в формате ДД.ММ.ГГГГ' : 'Введите значение'
        input = (
          <Input
            {...inputProps}
            isWide
            size="l"
            placeholder={placeholder}
            mask={inputMasks[field.type]}
          />
        )
      }
      return (
        <FormRow label={field.name} size="l" error={error} caption={field.caption}>
          {input}
        </FormRow>
      )
    }}
  </Field>
))

interface TemplateFieldsFormProps {
  form: FormStore
  fields: DocumentTemplateField[]
}

const TemplateFieldsForm = observer(({ form, fields }: TemplateFieldsFormProps) => (
  <>
    {fields.map((field) => (
      <TemplateFieldView key={field.key} field={field} form={form} />
    ))}
  </>
))

const CreateFromTemplateForm = observer(({ store }: { store: DocumentCreateStore }) => {
  let options = store.templates.items.map((item) => ({
    value: item.id,
    label: item.data.name,
  }))
  return (
    <>
      <FormRow
        label={`Шаблон ${store.type === 'contracts' ? 'договора' : 'документа'}`}
        size="l"
      >
        <SelectInput
          placeholder="Выберите шаблон"
          noOptionsMessage={() => 'Нет шаблонов'}
          isSearchable={false}
          size="l"
          value={
            store.template ? options.find((o) => o.value === store.template!.id) : null
          }
          onChange={(value) =>
            (store.template =
              value === null ? undefined : store.templates.map[value.value])
          }
          options={options}
        />
      </FormRow>
      {store.template &&
        (store.isTemplateDisabled ? (
          <div style={{ color: 'var(--color-red)' }}>
            У исполнителя уже есть активный договор по этому шаблону. Для создания нового
            договора нужно удалить или архивировать текущий.
          </div>
        ) : (
          <TemplateFieldsForm
            form={store.templateFieldsForm}
            fields={store.template!.data.template_fields}
          />
        ))}
    </>
  )
})

interface DocumentCreateFormProps {
  type: 'contracts' | 'documents'
  templates: Collection<DocumentTemplateEntity>
  onSubmit: (values: any) => ActionState<Data>
  contracts?: Entity<DocumentEntity>[]
  disabledTemplateIds: number[]
  onClose: () => void
}

const DocumentCreateForm = observer((props: DocumentCreateFormProps) => {
  let { templates, onSubmit, onClose, type, disabledTemplateIds, contracts } = props
  let user = useUserStore()

  let store = useMemo(
    () =>
      new DocumentCreateStore({
        templates,
        type,
        contracts,
        disabledTemplateIds,
        onSubmit,
        user,
      }),
    []
  )

  let chips = templates.items.length > 0 && (
    <Chips
      value={store.from}
      onChange={(value) => (store.from = value)}
      items={[
        { id: 'template', title: 'Создать из шаблона' },
        { id: 'file', title: 'Загрузить из файла' },
      ]}
    />
  )

  let contractOptions = store.contracts!.map((c) => ({
    value: c.id,
    label: c.data.title,
  }))

  let requireSignOptions = [
    { value: 'all', label: 'Подпись обеих сторон' },
    { value: 'contractor', label: 'Подпись только исполнителя' },
    { value: 'company', label: 'Подпись только компании' },
  ]

  let typeStr = store.type === 'contracts' ? 'договора' : 'документа'
  let form = (
    <>
      {store.form.fields.file && (
        <Field field={store.form.fields.file}>
          {({ inputProps, error }) => (
            <FormRow label="Файл" size="l" error={error}>
              <FileInput
                {...inputProps}
                label="Выберите файл в формате PDF"
                isWide
                accept=".pdf"
                style={{ marginTop: '.375rem' }}
              />
            </FormRow>
          )}
        </Field>
      )}
      {store.form.fields.title && (
        <Field field={store.form.fields.title}>
          {({ inputProps, error }) => (
            <FormRow label="Название" size="l" error={error}>
              <Input {...inputProps} isWide size="l" placeholder="Введите название" />
            </FormRow>
          )}
        </Field>
      )}
      {store.form.fields.title_en && (
        <Field field={store.form.fields.title_en}>
          {({ inputProps, error }) => (
            <FormRow label="Название договора (EN)" size="l" error={error}>
              <Input
                {...inputProps}
                isWide
                size="l"
                placeholder="Введите название (EN)"
              />
            </FormRow>
          )}
        </Field>
      )}
      {store.form.fields.number && (
        <Field field={store.form.fields.number}>
          {({ inputProps, error }) => (
            <FormRow label={`Номер ${typeStr}`} size="l" error={error}>
              <Input {...inputProps} isWide size="l" placeholder="Введите номер" />
            </FormRow>
          )}
        </Field>
      )}
      {store.form.fields.date && (
        <Field field={store.form.fields.date}>
          {({ inputProps, error }) => (
            <FormRow label={`Дата ${typeStr}`} size="l" error={error}>
              <Input {...inputProps} placeholder="ДД.ММ.ГГГГ" mask={dateMask} size="l" />
            </FormRow>
          )}
        </Field>
      )}
      {store.contracts && store.contracts.length > 1 && store.form.fields.contract_id && (
        <Field field={store.form.fields.contract_id}>
          {({ inputProps, error }) => (
            <FormRow label="К какому договору создать документ" size="l" error={error}>
              <SelectInput
                options={contractOptions}
                onChange={(value) =>
                  inputProps.onChange(value === null ? undefined : value.value)
                }
                value={
                  inputProps.value
                    ? contractOptions.find((o) => o.value === inputProps.value)
                    : null
                }
                isWide
                size="l"
                placeholder="Выберите договор"
                isClearable
              />
            </FormRow>
          )}
        </Field>
      )}
      {store.form.fields.require_sign && (
        <Field field={store.form.fields.require_sign}>
          {({ inputProps, error }) => (
            <FormRow label="Кем подписывается документ" size="l" error={error}>
              <SelectInput
                options={requireSignOptions}
                onChange={(value) =>
                  inputProps.onChange(value === null ? undefined : value.value)
                }
                value={
                  inputProps.value
                    ? requireSignOptions.find((o) => o.value === inputProps.value)
                    : null
                }
                isWide
                size="l"
                placeholder="Выберите подписывающие стороны"
              />
            </FormRow>
          )}
        </Field>
      )}
      {store.form.fields.signed && (
        <Field field={store.form.fields.signed}>
          {({ inputProps, error }) => (
            <FormRow size="l" error={error}>
              <Checkbox
                {...inputProps}
                size="m"
                label={`${
                  store.type === 'contracts' ? 'Договор' : 'Документ'
                } уже подписан`}
              />
            </FormRow>
          )}
        </Field>
      )}
    </>
  )

  let buttons = (
    <Flex gap="1rem" justify="end" style={{ marginTop: '1rem' }}>
      <Button design="normal" onTap={onClose}>
        Отменить
      </Button>
      <Button
        isDisabled={!store.isValid}
        isLoading={store.submitState.state === 'pending'}
        onTap={() => store.submit().then(onClose)}
      >
        Создать
      </Button>
    </Flex>
  )

  return (
    <Col gap="var(--gap-m)">
      {chips}
      {store.from === 'template' && <CreateFromTemplateForm store={store} />}
      {form}
      {buttons}
    </Col>
  )
})

export { DocumentCreateForm, TemplateFieldsForm, makeTemplateFieldsForm }
