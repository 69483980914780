import React from 'react'

import { IComputedValue, makeAutoObservable, reaction } from 'mobx'
import { difference } from 'lodash'
import { Entity, Collection, ActionState } from 'mobx/mobx'

import { Modal, Col, Flex, Button } from 'components/ui'
import DialogStore from 'stores/DialogStore'

export interface ActionConfig<T> {
  ability: boolean
  getItemsForAction: (selectedItems?: Entity<T>[]) => ActionState<Collection<T>>
  action: (items: Array<Entity<T>>) => void
}

export type ActionConfigs<T> = { [key: string]: ActionConfig<T> }

export interface Q<T> {
  items: Array<Entity<T>>
  count: number
  pages: number
}

interface ActionMessageProps {
  actionCount: number
  selectedCount: number
}

type ActionMessage = (props: ActionMessageProps) => string

interface ActionConfirmProps {
  actionCount: number
  selectedCount: number
  onClose: () => void
  onConfirm: () => void
  noItemsMessage: ActionMessage
  someItemsMessage: ActionMessage
}

const ActionConfirmDialog = ({
  onClose,
  onConfirm,
  actionCount,
  selectedCount,
  noItemsMessage,
  someItemsMessage,
}: ActionConfirmProps) => (
  <Modal
    styles={{ content: { padding: '2rem' } }}
    size={400}
    isOpen={true}
    onClose={onClose}
  >
    <Col gap="var(--gap-m)">
      <div style={{ fontSize: '1.25rem' }}>
        {actionCount > 0
          ? someItemsMessage({ actionCount, selectedCount })
          : noItemsMessage({ actionCount, selectedCount })}
      </div>
      <Flex gap="1rem" justify="end">
        {actionCount > 0 ? (
          <>
            <Button onTap={onClose} design="normal">
              Отмена
            </Button>
            <Button onTap={onConfirm}>Продолжить</Button>
          </>
        ) : (
          <Button onTap={onClose} design="normal">
            Закрыть
          </Button>
        )}
      </Flex>
    </Col>
  </Modal>
)

export interface SelectedItemsStoreProps<T> {
  list: IComputedValue<Q<T>>
  actions: ActionConfigs<T>
  noItemsMessage: ActionMessage
  someItemsMessage: ActionMessage
  isSelectableByDefault?: boolean
}

class SelectedItemsStore<T> {
  constructor({
    list,
    actions,
    noItemsMessage,
    someItemsMessage,
    isSelectableByDefault = true,
  }: SelectedItemsStoreProps<T>) {
    this.list = list
    this.actions = actions
    this.noItemsMessage = noItemsMessage
    this.someItemsMessage = someItemsMessage
    this.isSelectable = isSelectableByDefault

    this.dispose = reaction(
      () => this.list.get(),
      (value, prevValue) => {
        difference(prevValue.items, value.items).forEach((item) =>
          this.selectedItems.delete(item)
        )
      }
    )

    reaction(
      () => this.isPageSelected,
      (isPageSelected) => {
        if (this.isAllSelected && !isPageSelected) this.isAllSelected = false
      }
    )

    makeAutoObservable(this)
  }

  list: IComputedValue<Q<T>>
  actions: { [key: string]: ActionConfig<T> }
  selectedItems: Set<Entity<T>> = new Set()
  dispose: () => void
  noItemsMessage: ActionMessage
  someItemsMessage: ActionMessage
  isAllSelected = false
  isSelectable: boolean

  fetchingAction?: string
  fetchItemsForActionState?: ActionState<any>
  isFetching() {
    return this.fetchItemsForActionState?.state === 'pending'
  }
  isFetchingAction(action: string) {
    return this.isFetching() && this.fetchingAction === action
  }

  get selectedItemsList() {
    return Array.from(this.selectedItems)
  }

  get items() {
    return this.list.get().items
  }

  get count() {
    return this.list.get().count
  }

  get pages() {
    return this.list.get().pages
  }

  get isPageSelected() {
    return this.selectedItems.size === this.items.length
  }

  get selectedCount() {
    return this.isAllSelected ? this.count : this.selectedItems.size
  }

  get isEmpty() {
    return this.selectedCount === 0
  }

  setSelectable(selectable: boolean) {
    this.isSelectable = selectable
  }

  selectItem(item: Entity<T>) {
    this.selectedItems.add(item)
  }

  unselectItem(item: Entity<T>) {
    this.selectedItems.delete(item)
  }

  isItemSelected(item: Entity<T>) {
    return this.selectedItems.has(item)
  }

  toggleSelectItem(item: Entity<T>) {
    if (this.isItemSelected(item)) this.unselectItem(item)
    else this.selectItem(item)
  }

  selectPage() {
    this.items.forEach((item) => this.selectedItems.add(item))
  }

  toggleSelectPage() {
    if (this.selectedItems.size > 0) {
      this.unselectAll()
    } else {
      this.selectPage()
    }
  }

  selectAll() {
    this.selectPage()
    this.isAllSelected = true
  }

  unselectAll() {
    this.selectedItems.clear()
  }

  actionConfirmDialog = new DialogStore({ component: ActionConfirmDialog })

  async multiAction(name: string) {
    let config = this.actions[name]
    let items: Entity<T>[]
    let fetchState = config.getItemsForAction(
      this.isAllSelected ? undefined : Array.from(this.selectedItems)
    )
    try {
      this.fetchingAction = name
      this.fetchItemsForActionState = fetchState
      items = (await fetchState).items
    } catch (e) {
      // TODO handle error
      return
    }
    if (items.length < this.selectedCount) {
      this.actionConfirmDialog.open({
        actionCount: items.length,
        selectedCount: this.selectedCount,
        onConfirm: () => {
          this.actionConfirmDialog.close()
          config.action(items)
        },
        noItemsMessage: this.noItemsMessage,
        someItemsMessage: this.someItemsMessage,
      })
    } else {
      config.action(items)
    }
  }

  render() {
    return this.actionConfirmDialog.render()
  }
}

export default SelectedItemsStore
