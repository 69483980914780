import React from 'react'

import { useUserStore } from 'stores/context'

import UnsignedCounterpartyDocuments from './notifications/UnsignedCounterpartyDocuments'

const GlobalNotification = () => {
  const currentUser = useUserStore()

  const globalNotifications = currentUser.company?.data.global_notifications

  const UnsignedCounterpartyDocumentsData = globalNotifications?.find(
    (item: any) => item.kind === 'unsigned_counterparty_documents'
  )

  return (
    <>
      {/* <WorkingInNormalMode /> */}
      {UnsignedCounterpartyDocumentsData && (
        <UnsignedCounterpartyDocuments data={UnsignedCounterpartyDocumentsData} />
      )}
    </>
  )
}

export default GlobalNotification
