import React from 'react'
import { Link as ReactRouterDomLink } from 'react-router-dom'
import cl from 'clsx'
import { InlineText } from 'ui-kit'

import css from './styles.module.css'

export type LinkProps = {
  href?: string
  target?: '_self' | '_blank'
  variant?: 'normal' | 'dashed'
  icon?: React.ReactNode
  download?: boolean
  className?: string
  children?: string | React.ReactNode
  onClick?: (e: React.MouseEvent<Element, MouseEvent>) => void
}

const Link: React.FunctionComponent<LinkProps> = ({
  href,
  target = '_self',
  variant = 'normal',
  icon,
  download,
  className,
  children,
  onClick,
}) => {
  if (onClick) {
    return (
      <button
        onClick={onClick}
        className={cl(css.link, css[variant], className && className)}
      >
        {icon && <InlineText className={css.icon}>{icon}</InlineText>}
        <InlineText className={cl(css.content, icon && css.withIcon)}>
          {children}
        </InlineText>
      </button>
    )
  } else if (href) {
    const absoluteUrl = href.includes('http')

    return (
      <ReactRouterDomLink
        to={{ pathname: href }}
        target={absoluteUrl ? '_blank' : target}
        rel={absoluteUrl || target === '_blank' ? 'noopener noreferrer' : undefined}
        download={download}
        className={cl(css.link, css[variant], className && className)}
      >
        {icon && <InlineText className={css.icon}>{icon}</InlineText>}
        <InlineText className={cl(css.content, icon && css.withIcon)}>
          {children}
        </InlineText>
      </ReactRouterDomLink>
    )
  }

  return null
}

export default Link
