import * as React from 'react'
import { Col, List, ListItem, Row, useStateToast } from 'components/ui'
import { observer } from 'mobx-react-lite'

import { Amount, Avatar, FullscreenControl, Map, Marker } from 'components'

import { useMediaContext } from 'App/MediaContext'

import { Entity } from 'mobx/MobxApi'
import styles from './Tasks.module.css'
import clsx from 'clsx'

import { ReactComponent as LocationPinIcon } from './location-pin.svg'
import { LinkButton } from 'components/ui/Link'
import { ScenarioTaskActionsStore } from './TaskAction'
import { TaskActionButton } from './TaskAction/TaskAction'
import { formatDayPeriod } from './TaskList'
import { formatTime } from 'utils/datetime'
import { transformLinksInText } from 'utils/url'

type ITaskBlock = any;
type ScenarioTaskEntity = any;

export type BlockProps = {
  block: ITaskBlock
  task: Entity<ScenarioTaskEntity>
}

const BlockLabel = ({ children }: { children: React.ReactNode }) => (
  <label className={clsx(styles.smallText, styles.greyText)}>{children}</label>
)

const BlockCol = ({
  children,
  label,
}: {
  label: React.ReactNode
  children: React.ReactNode
}) => {
  const { isMobile } = useMediaContext()

  return (
    <Col gap={isMobile ? '12px' : '16px'}>
      <BlockLabel>{label}</BlockLabel>
      <Row className={styles.mediumText} align="center" gap=".625rem">
        {children}
      </Row>
    </Col>
  )
}

const HeaderBlock = observer(({ task }: BlockProps) => {
  const { isMobile } = useMediaContext()
  return (
    <Row gap={isMobile ? '12px' : '20px'}>
      <Col>
        <Avatar
          name={task.data.company.data.name}
          value={task.data.company.data.avatar_url}
          size={isMobile ? '40px' : '88px'}
        />
      </Col>
      <Col style={{ flex: 1 }} justify="center" align="start">
        <h6 className={clsx(styles.greyText, styles.mediumText)}>Заказчик:</h6>
        <Row justify="space-between" style={{ width: '100%' }}>
          <h1 className={styles.headerText}>{task.data.company.data.name}</h1>
          <Amount
            className={clsx(styles.headerText, styles.purpleText)}
            style={{ fontWeight: 600 }}
            value={task.data.amount}
          />
        </Row>
      </Col>
    </Row>
  )
})

const LocationBlock = observer(({ task }: BlockProps) => {
  const { isMobile } = useMediaContext()
  const [showMap, setShowMap] = React.useState(isMobile)

  React.useEffect(() => {
    setShowMap(isMobile)
  }, [isMobile])

  return (
    <>
      {task.data.location && (
        <BlockCol label="Местоположение:">
          <LocationPinIcon className={clsx(styles.blockIcon, styles.purpleText)} />{' '}
          <span className={clsx(styles.mediumText)}>{task.data.location?.address}</span>
          {!isMobile && (
            <LinkButton underline onClick={() => setShowMap((cur) => !cur)}>
              На карте
            </LinkButton>
          )}
        </BlockCol>
      )}
      {showMap && task.data.location?.lat && task.data.location?.lng && (
        <div className={styles.mapWrapper} style={{ height: 240 }}>
          <Map
            width="100%"
            height="240px"
            defaultState={{
              center: [task.data.location.lat, task.data.location.lng],
              zoom: 15,
            }}
            options={{
              suppressMapOpenBlock: true,
            }}
          >
            <Marker lat={task.data.location.lat} lng={task.data.location.lng} />
            <FullscreenControl />
          </Map>
        </div>
      )}
    </>
  )
})

const DateTimeBlock = observer(({ task }: BlockProps) => {
  if (!task.data.since_date) return null

  return (
    <BlockCol label="Время выполнения:">
      {formatDayPeriod(task.data.since_date, task.data.upto_date)}
      {task.data.since_time && (
        <>
          , с {formatTime(task.data.since_time)} по {formatTime(task.data.upto_time)}
        </>
      )}
    </BlockCol>
  )
})

const SubtasksBlock = observer(({ task }: BlockProps) => {
  const { isMobile } = useMediaContext()

  const { subtasks } = task.data

  return (
    <Col gap={isMobile ? '12px' : '16px'}>
      <BlockLabel>Задания:</BlockLabel>
      <List>
        {(!subtasks || subtasks?.length === 0) && (
          <div className={styles.mediumText}>Заданий нет</div>
        )}
        {subtasks?.map((subtask: any) => (
          <ListItem padding={false} hover={false} className={styles.subtask}>
            <div className={clsx(styles.cell, styles.mediumText, styles.nameCell)}>
              {subtask.title}
            </div>
            <div
              style={{ minWidth: '120px', maxWidth: '120px' }}
              className={clsx(
                styles.cell,
                styles.mediumText,
                styles.greyText,
                styles.unitCostCell
              )}
            >
              <Amount value={subtask.cost} />
            </div>
            <div style={{ minWidth: '120px', maxWidth: '120px' }} className={clsx(styles.cell, styles.mediumText, styles.quantityCell)}>
              <div className={styles.quantityBox}>x{subtask.quantity}</div>
            </div>
            <div style={{ minWidth: '120px', maxWidth: '120px' }} className={clsx(styles.cell, styles.mediumText, styles.amountCell)}>
              <Amount value={subtask.total_cost} />
            </div>
          </ListItem>
        ))}
      </List>
    </Col>
  )
})

const DescriptionBlock = observer(({ task }: BlockProps) => {
  const text = task.data.description
  const element = React.useRef<HTMLParagraphElement>(null)
  const [isHidden, setisHidden] = React.useState<boolean>(true)
  const toggleReadMore = () => {
    setisHidden(!isHidden)
  }

  const [isOverflow, setIsOverflow] = React.useState<boolean>(false)
  const hasOverflow = (element: React.RefObject<HTMLParagraphElement>): boolean => {
    if (!element.current) return false
    return element.current.scrollHeight > element.current.clientHeight
  }

  React.useLayoutEffect(() => {
    setIsOverflow(hasOverflow(element))
  }, [isHidden, text])

  if (!text) return null

  return (
    <BlockCol label="Комментарий:">
      <Col align="start" gap="1rem" style={{ width: '100%' }}>
        <div className={styles.readmoreWrapper}>
          <p
            className={styles.readmore}
            style={{ WebkitLineClamp: isHidden ? 6 : undefined }}
            ref={element}
            dangerouslySetInnerHTML={{ __html: transformLinksInText(text) }}
          />
        </div>
        {(isOverflow || !isHidden) && (
          <LinkButton underline onClick={toggleReadMore}>
            {isHidden ? 'Показать ещё' : 'Скрыть'}
          </LinkButton>
        )}
      </Col>
    </BlockCol>
  )
})

const ActionsBlock = observer(({ task }: BlockProps) => {
  const toast = useStateToast()
  const [store] = React.useState(() => new ScenarioTaskActionsStore({ task, toast }))

  return (
    <>
      {task.data.actions && task.data.actions.length > 0 && (
        <Row>
          {task.data.actions.map((action: any, idx: number) => (
            <TaskActionButton key={action + idx} store={store} action={action} />
          ))}
        </Row>
      )}
      {store.render()}
    </>
  )
})

export const TaskBlock = observer(({ block, task }: BlockProps) => {
  switch (block.type) {
    case 'header':
      return <HeaderBlock block={block} task={task} />
    case 'location':
      return <LocationBlock block={block} task={task} />
    case 'datetime':
      return <DateTimeBlock block={block} task={task} />
    case 'subtasks':
      return <SubtasksBlock block={block} task={task} />
    case 'description':
      return <DescriptionBlock block={block} task={task} />
    case 'actions':
      return <ActionsBlock block={block} task={task} />
    default:
      return null
  }
})
