import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'

import { useUserStore } from 'stores/context'

import { LayoutSimple } from 'components/ui'
import { Url, queryObject } from 'utils/url'

import PhoneStep from './components/PhoneStep'
import SmsStep from './components/SmsStep'
import Password from './components/Password'
import CompanySelectStep from './components/CompanySelectStep'

let LoginPage = observer(() => {
  const currentUser = useUserStore()

  const [step, setStep] = useState('phone')
  const [user] = useState<any>()
  const [phone, setPhone] = useState(localStorage['login_phone'] || '')
  const [password, setPassword] = useState('')
  const [status, setStatus] = useState('password')

  useEffect(() => {
    requestAnimationFrame(() => (window.document.body.style.opacity = '1'))
  }, [])

  let onComplete = (data: any) => {
    let { from } = queryObject(Url())
    let to = from ? decodeURIComponent(String(from)) : data.initialURl ?? '/'
    currentUser.login({ ...data, initialUrl: to })
  }

  let stepElem
  let onBack
  if (step === 'phone') {
    stepElem = (
      <PhoneStep
        initialPhone={phone}
        onComplete={(phone, status) => {
          setStatus(status)
          setStep(status)
          setPhone(phone)
          localStorage['login_phone'] = phone
        }}
      />
    )
  } else if (step === 'sms') {
    onBack = () => {
      setStep(status === 'password' ? 'password' : 'phone')
    }
    stepElem = (
      <SmsStep
        phone={phone!}
        password={password}
        onComplete={(user) => {
          const companyId =
            user.companies && user.companies.length > 0 ? user.companies[0].id : undefined
          onComplete({ user: user!, companyId })
        }}
      />
    )
  } else if (step === 'companySelect') {
    stepElem = (
      <CompanySelectStep
        user={user!}
        onComplete={(companyId) => onComplete({ user: user!, companyId })}
      />
    )
  } else if (step === 'password') {
    onBack = () => setStep('phone')
    stepElem = (
      <Password
        phone={phone}
        onComplete={(pwd) => {
          setPassword(pwd)
          setStep('sms')
        }}
      />
    )
  }

  return <LayoutSimple onBack={onBack}>{stepElem}</LayoutSimple>
})

export default LoginPage
