import { rest } from 'msw'

import { json } from './helpers'

const data = {
  // Массовая регистрация
  mass_registration: {
    count: 19,
    risk_percent: 5,
  },
  // Единственный источник дохода
  only_source_of_income: {
    count: 41,
    risk_percent: 55,
  },
  // Периодичность выплат
  two_payments_per_month: {
    count: 64,
    risk_percent: 20,
  },
  // Одинаковые платежи
  unique_payments: {
    count: 40,
    risk_percent: 0,
  },
  // Продолжительность сотрудничества
  working_duration: {
    count: 83,
    risk_percent: 38,
  },
}

export default [
  rest.get('/api/company/contractor_risk_profiles', json(data)),
  rest.get('/api/company/contractor_risk_profiles/1', json(data)),
]
