import React from 'react'
import { observer } from 'mobx-react-lite'

import { SimpleBackLink } from 'components/ui'
import { UserRegistrationEntity } from 'entities'
import { Entity } from 'mobx/MobxApi'
import RegistrationFooter from 'pages/registration/components/Footer'
import RegistrationForm from 'pages/registration/components/RegistrationForm'
import { ConnectToPlatformStore } from 'pages/registration/store/ConnectToPlatformStore'
import { Appear } from 'ui'

const PersonalDataStep = observer(
  ({
    registration,
    store,
  }: {
    registration: Entity<UserRegistrationEntity>
    store: ConnectToPlatformStore
  }) => {
    let { disable_change_status } = registration.data

    return (
      <>
        <RegistrationForm registration={registration} />
        <RegistrationFooter />
      </>
    )
  }
)

export default PersonalDataStep
