import React from 'react'
//@ts-ignore
import { useStyles } from 'floral'

import { IconButton } from 'components/ui'
import { Button, Flex } from 'ui'
import { ReactComponent as UploadIcon } from 'assets/upload-primary.svg'
import { ReactComponent as CheckIcon } from 'assets/icon-violet-check.svg'
import { ReactComponent as ClearIcon } from '@material-design-icons/svg/round/clear.svg'

import useFileDialog from 'utils/useFileDialog'

import css from './styles.module.css'

interface FileInputProps {
  value?: File
  onChange?: (file?: File) => void
  isLoading?: boolean
  label?: React.ReactNode
  accept?: string
  isWide?: boolean
}

const fileInputStyles = (props: FileInputProps) => ({
  value: {
    display: 'inline-flex',
    alignItems: 'center',
    fontSize: '1.15rem',
    borderBottom: '1px solid var(--color-underline)',
    width: props.isWide ? '100%' : 'auto',
    padding: '0 .5rem',
    lineHeight: '41px',
    minWidth: 150,
  },
  checkIcon: {
    width: 16,
    height: 16,
    marginRight: '.5rem',
  },
  file: {
    marginRight: '.5rem',
    fontWeight: 500,
    flexGrow: 1,
  },
})

const FileInput = (props: FileInputProps) => {
  let { label, value, onChange, isLoading, accept } = props
  let s = useStyles(fileInputStyles, [props])
  let fileDialog = useFileDialog((file) => onChange?.(file), { accept })
  return (
    <div>
      {value && !isLoading ? (
        <Flex className={css.value} align="center">
          <CheckIcon style={s.checkIcon} />
          <div>{value.name}</div>
          <IconButton onClick={() => onChange && onChange()} tooltip="Удалить">
            <ClearIcon />
          </IconButton>
        </Flex>
      ) : (
        <Button
          onTap={() => fileDialog.open()}
          variant="secondary"
          icon={<UploadIcon />}
          isLoading={isLoading}
          style={s.button}
        >
          {label}
        </Button>
      )}
      {fileDialog.render()}
    </div>
  )
}

FileInput.defaultProps = {
  label: 'Загрузить',
}

export default FileInput
