import * as React from 'react'
import { Button, Flex, H2, Modal, useStateToast } from 'components/ui'
import { observer } from 'mobx-react-lite'
import { useMediaContext } from 'App/MediaContext'
import { RateButton } from './RateButton'
import { TextArea } from 'components/ui/TextArea'
import { useDeviceDetect } from 'utils/useDeviceDetect'
import { fetchApi } from 'api'
import { initialActionState } from 'mobx/MobxApi'
import { sendFeedback } from './api'
import { usePusherSubscription } from 'App/pusher'
import { useUserStore } from 'stores/context'
import { useDialogStore } from 'stores/DialogStore'
import { useTheme } from 'ui'

const styles = {
  wrapper: { minHeight: 418 },
  logo: {
    width: 116,
    height: 28,
    position: 'absolute' as const,
    top: '28px',
    left: '50%',
    transform: 'translateX(-50%)',
  },
  title: {
    fontWeight: 700,
    fontSize: '28px',
    lineHeight: '33px',
    textAlign: 'center' as const,
    whiteSpace: 'pre-line' as const,
  },
  description: {
    fontSize: '14px',
    lineHeight: '20px',
    textAlign: 'center' as const,
    marginTop: '-6px',
  },
  mt: {
    marginTop: '50px',
  },
}

const titles: { [K in feedbackModalStep]: string } = {
  rate: 'Как вам наше приложение?',
  afterUpvote: 'Спасибо!\nМожет поставите нам оценку?',
  afterDownvote: 'Что вам не понравилось?',
  done: 'Спасибо, что помогаете нам становиться лучше',
}

const descriptions: { [K in feedbackModalStep]: string | null } = {
  rate: 'Мы будем рады, если вы поделитесь своими впечатлениями',
  afterUpvote: null,
  afterDownvote: 'Напишите пожалуйста что вам не понравилось, чтобы мы стали лучше!',
  done: null,
}

type FeedbackModalProps = {
  onClose: () => void
}

type feedbackModalStep = 'rate' | 'afterUpvote' | 'afterDownvote' | 'done'

export const FeedbackModal = observer(({ onClose }: FeedbackModalProps) => {
  const { config } = useTheme()
  const { isMobile: isMobileWidth } = useMediaContext()
  const { isIOS } = useDeviceDetect()
  const toast = useStateToast()

  const [step, setStep] = React.useState<feedbackModalStep>('rate')
  const title = titles[step]
  const description = descriptions[step]

  const [feedback, setFeedback] = React.useState<string>('')
  const [submitState, setSubmitState] = React.useState(initialActionState)
  const submitFeedback = React.useCallback(
    // @ts-ignore
    (e) => {
      e.preventDefault()
      const state = sendFeedback(feedback)
      state.then(
        () => {
          setStep('done')
        },
        (error: Error) => toast.error({ title: 'Ошибка', description: error.message })
      )
      setSubmitState(state)
    },
    [feedback, toast]
  )

  const handleGoToStore = React.useCallback(() => {
    fetchApi({
      url: 'users/events',
      method: 'PUT',
      data: { event: 'reviews_store' },
    })
    onClose()
  }, [onClose])

  return (
    <Modal onClose={onClose} isOpen={true} size={isMobileWidth ? '97%' : 344}>
      <Flex
        direction="column"
        justify="center"
        align="center"
        gap="20px"
        style={styles.wrapper}
      >
        <img src={require('./konsol.svg')} style={styles.logo} alt="konsol" />
        <H2 style={styles.title}>{title}</H2>
        {description && <p style={styles.description}>{description}</p>}
        {step === 'rate' && (
          <Flex gap="30px" style={{ marginTop: '10px' }}>
            <RateButton type="upvote" onClick={() => setStep('afterUpvote')} />
            <RateButton type="downvote" onClick={() => setStep('afterDownvote')} />
          </Flex>
        )}
        {step === 'afterUpvote' && (
          <>
            <Button
              fluid
              style={styles.mt}
              as="a"
              target="__blank"
              href={config.links.app.universal}
              onClick={handleGoToStore}
            >
              Перейти в {isIOS ? 'App Store' : 'Google Play'}
            </Button>
            <Button
              fluid
              design="text"
              onClick={() => {
                fetchApi({
                  url: 'users/events',
                  method: 'PUT',
                  data: { event: 'review_later' },
                })
                onClose()
              }}
            >
              Оценю позже
            </Button>
          </>
        )}
        {step === 'afterDownvote' && (
          <>
            <TextArea
              value={feedback}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                setFeedback(e.currentTarget.value)
              }
              resize="none"
              placeholder="Напишите, что не понравилось..."
            />
            <Button
              isLoading={submitState.state === 'pending'}
              isDisabled={feedback?.length < 2}
              fluid
              type="submit"
              onClick={submitFeedback}
            >
              Отправить отзыв
            </Button>
          </>
        )}
        {step === 'done' && (
          <Button fluid style={styles.mt} onClick={onClose}>
            Закрыть
          </Button>
        )}
      </Flex>
    </Modal>
  )
})

export const useFeedbackModal = () => {
  const currentUser = useUserStore()
  const dialog = useDialogStore({ component: FeedbackModal })

  usePusherSubscription('open-feedback-popup', () => {
    if (currentUser.platform === 'mobile_app') dialog.open({})
  })

  return dialog
}
