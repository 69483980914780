import React from 'react'
import { action } from 'mobx'
import { observer } from 'mobx-react-lite'

import { Input } from 'components/ui'
import { ReactComponent as SearchIcon } from '@material-design-icons/svg/round/search.svg'

import FilteredStore from './FilteredStore'

interface SearchInputProps<T> {
  store: FilteredStore<T, string, any>
  placeholder: string
}

const SearchInput = <T,>({ store, placeholder }: SearchInputProps<T>) => (
  <Input
    value={store.query || ''}
    onChange={action((value: string) => {
      store.query = value
    })}
    placeholder={placeholder}
    icon={<SearchIcon />}
  />
)

SearchInput.defaultProps = {
  placeholder: 'Поиск',
}

export default observer(SearchInput)
