import React from 'react'
import { Route, Redirect } from 'react-router'

interface ProtectedRouteProps extends React.ComponentProps<typeof Route> {
  guard: () => string | undefined
}

const ProtectedRoute = ({
  guard,
  component: Component,
  ...rest
}: ProtectedRouteProps) => (
  <Route
    {...rest}
    render={(props) => {
      let redirectUrl = guard()
      // @ts-ignore
      return redirectUrl ? <Redirect to={redirectUrl} /> : <Component {...props} />
    }}
  />
)

export default ProtectedRoute
