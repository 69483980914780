import { rest } from 'msw'
import { shuffle } from 'lodash'

import { json, error } from './helpers'

const documents = [
  {
    id: 1,
    title: 'Название документа',
    kind: 'document',
    status: 'created',
    file_url: 'https://google.com',
    created_date: '2021-10-11',
    contractor: { id: 999, name: 'Петров Петр' },
    template: {
      id: 3,
      name: 'Шаблон документa',
    },
  },
  {
    id: 2,
    title: 'Договор на оказание услуг',
    date: '2021-01-02',
    kind: 'contract',
    status: 'signed_by_contractor',
    file_url: 'https://google.com',
    created_date: '2021-10-12',
    contractor: { id: 999, name: 'Петров Петр' },
  },
  {
    id: 3,
    title: 'Ещё какой-то документ',
    kind: 'document',
    status: 'signed_by_all',
    file_url: 'https://google.com',
    created_date: '2021-10-10',
    contractor: { id: 1000, name: 'Константинов Константин' },
  },
  {
    id: 4,
    title: 'Договор на разработку программного обеспечения №1488',
    kind: 'document',
    status: 'signed_by_legal_entity',
    file_url: 'https://google.com',
    created_date: '2021-10-10',
    contractor: { id: 1000, name: 'Константинов Константин' },
  },
]

const getDocs = (req: any, res: any, ctx: any) =>
  res(
    ctx.delay(500),
    ctx.json(
      [
        {
          id: 1,
          title: `Документ 1`,
          status: 'created',
          file_url: 'https://google.com',
          contractor: { id: 1000, name: 'Константинов Константин' },
        },
      ]
      // req.body.ids.map((id: number) => ({
      //   id: id,
      //   title: `Документ ${id}`,
      //   status: 'created',
      //   file_url: 'https://google.com',
      //   contractor: { id: 1000, name: 'Константинов Константин' },
      // }))
    )
  )

export default [
  rest.post('/api/company/documents/for_multi_sign', getDocs),
  rest.post('/api/company/documents/for_multi_delete', getDocs),
  rest.post('/api/company/documents/for_multi_archive', getDocs),

  rest.delete('/api/company/documents/multi_delete', json({})),
  rest.post('/api/company/documents/multi_archive', (req, res, ctx) =>
    res(ctx.delay(5000), ctx.json({}))
  ),

  rest.post('/api/company/documents/filter', (req: any, res: any, ctx: any) =>
    res(
      ctx.delay(500),
      ctx.json({
        items: shuffle(documents),
        page: req.body.page,
        per_page: 5,
        total_pages: 10,
        total_count: 50,
      })
    )
  ),
  rest.post(
    '/api/company/documents/create_from_file',
    error(500, {
      error: {
        code: 'failed_validation',
        message: 'Неправильные данные',
        errors: [
          'Строка 25: Исполнитель с ИНН 78990123 не найден',
          'Cтрока 54: Шаблон документа 123 не найден',
        ],
      },
    })
  ),
]
