import { rest } from 'msw'

import { json, paginate } from './helpers'

const url =
  'https://allbeton.ru/upload/iblock/9ad/sto_87313302_13330_001_2012_konstrukcii_s_primeneniem_avtoklavnogo_gazobetona_v_stroitelstve_zdanii_i_soorujenii_pravila_....pdf'

const fns_receipts = [
  {
    id: 137,
    offline: false,
    preview_url: 'https://via.placeholder.com/200x200',
    url: 'http://google.com',
    number: 123,
    customer: 'ООО Нихера себе',
    amount: '1337.73',
  },
  {
    id: 138,
    offline: false,
    preview_url: 'https://via.placeholder.com/200x200',
    status: 'annulled',
    url: 'http://google.com',
  },
]

const tasks = [
  {
    id: 1407,
    document_number: { number: 1078 },
    since: '2022-02-01',
    upto: '2022-02-15',
    title: 'Название акта, длинное на две строчки, чтобы поместилось',
    status: 'accepted_by_company',
    kind: 'act',
    display_amount: '22927.45',
    task: {
      status: 'assigned',
    },

    act: {
      date: '2022-02-15',
      status: 'accepted_by_company',
      amount: '200.9',
      payment_amount: '100.45',
      exchange_rate: '77.44',
    },
    payment: { status: 'paid', error: null },
    actions: [
      'accept',
      'autopay',
      'cancel_autopay',
      'pay',
      'delete',
      'make_work_acceptance',
    ],
    contractor: { id: 2, name: 'Бабань Михаил', task_flags: [], is_suspended: true },
    company: { id: 22, name: 'Очень длинное очень длинное название компании' },
    problem_messages: ['not_signed_document'],
    fns_receipts,
  },
  {
    id: 1401,
    document_number: { number: 1072 },
    since: '2021-03-18',
    upto: '2021-03-21',
    title: 'шшшшш',
    status: 'accepted',
    kind: 'task',
    display_amount: { value: '605.0', currency: 'EUR' },
    task: {
      status: 'done',
    },
    act: {
      date: '2022-02-15',
      status: 'accepted',
      amount: { value: '605.0', currency: 'EUR' },
      payment_amount: { value: '605.0', currency: 'EUR' },
    },
    payment: { status: 'not_paid', error: null },
    actions: ['pay'],
    contractor: { id: 1, name: 'Бабань Михаил', task_flags: [], is_suspended: true },
    company: { id: 2, name: 'Restaff' },
    fns_receipts,
    multi_receipts: true,
  },
  {
    id: 1397,
    document_number: { number: 1068 },
    since: '2021-03-18',
    upto: '2021-03-19',
    title: 'создаём акт',
    status: 'accepted_by_company',
    kind: 'act',
    display_amount: '121.0',
    fns_receipt: null,
    act: {
      date: '2022-02-15',
      status: 'accepted_by_company',
      amount: '121.0',
      payment_amount: '121.0',
    },
    payment: { status: 'not_paid', error: null },
    actions: ['pay', 'delete'],
    contractor: { id: 1, name: 'Помящий Никита', task_flags: [] },
    company: { id: 2, name: 'Restaff' },
  },
  {
    id: 1395,
    description: 'Дополнительная информация к акту',
    document_number: { number: 1066 },
    since: '2021-03-24',
    upto: '2021-03-26',
    title: 'тестовый акт созднный мной',
    status: 'accepted_by_company',
    kind: 'act',
    display_amount: '13123.0',
    fns_receipt: 'http://google.com',
    act: {
      date: '2022-02-15',
      status: 'accepted_by_company',
      amount: '13123.0',
      payment_amount: '13123.0',
    },
    payment: { status: 'not_paid', error: null },
    actions: ['pay', 'delete'],
    contractor: { id: 1, name: 'Помящий Никита', task_flags: [] },
    company: { id: 2, name: 'Restaff' },
  },
  {
    id: 1394,
    description: null,
    document_number: { number: 1065 },
    since: '2022-02-01',
    upto: '2022-02-15',
    title: 'Доставка биоматериала под дверь заказчику',
    status: 'done',
    kind: 'act',
    display_amount: '8700.00',
    fns_receipt: null,
    act: {
      date: '2022-02-15',
      status: 'not_accepted',
      amount: '10000.0',
      payment_amount: '8700.00',
      work_acceptance_original_pdf_url: url,
      work_acceptance_blurb_pdf_url: null,
      attachments: [],
      description: null,
    },
    payment: { status: 'not_paid', error: null },
    actions: ['accept', 'delete'],
    contractor: { id: 38, name: 'Помящий Никита', task_flags: [] },
    company: { id: 2, name: 'Restaff' },
  },
  {
    id: 1393,
    description: null,
    document_number: { number: 1064 },
    since: '2022-02-01',
    upto: '2022-02-15',
    title: 'Доставка биоматериала под дверь заказчику',
    status: 'accepted',
    kind: 'act',
    display_amount: '100.45',
    fns_receipt: null,
    act: {
      date: '2022-02-15',
      status: 'accepted',
      amount: '200.9',
      payment_amount: '100.45',
      work_acceptance_original_pdf_url: url,
      work_acceptance_blurb_pdf_url: url,
      attachments: [],
      description: null,
    },
    payment: { status: 'in_progress', error: null },
    actions: [],
    contractor: { id: 2, name: 'Бабань Михаил', task_flags: [] },
    company: { id: 2, name: 'Restaff' },
  },
]

const reject = {
  comment: 'Тебе отказано по причине того что ты ничего не сделал и просишь денег',
}

const act = {
  id: 1408,
  description: null,
  document_number: { number: 1079 },
  since: '2022-02-01',
  upto: '2022-02-15',
  title: 'Доставка биоматериала под дверь заказчику',
  status: 'done_by_contractor',
  // status: 'accepted_by_company',
  kind: 'act',
  display_amount: '8700.0',
  contract: {
    id: 123,
    title: 'Договор на оказание услуг',
    date: '12-12-2012',
    file_url: 'https://google.com',
    events: [],
    // status: 'created',
    status: 'signed_by_all',
  },
  act: {
    status: 'rejected',
    amount: { value: '200.9', currency: 'EUR' },
    exchange_rate: '77.44',
    payment_amount: '100.45',
    tax_savings_amount: '123.40',
    payment_amount_after_savings: '8543.40',
    work_acceptance_original_pdf_url: url,
    work_acceptance_blurb_pdf_url: url,
    attachments: [],
    description: null,
  },

  reject,

  // annulment,

  fns_receipts,
  // fns_receipts: [],
  // payment: { status: 'not_paid', error: null },
  payment: { status: 'paid' },

  // actions: [],
  // actions: ['pay', 'delete', 'annul', 'accept', 'make_work_acceptance', 'accept_annul'],
  actions: ['reject', 'make_work_acceptance'],

  events: [
    {
      id: 2739,
      kind: 'accept',
      created_at: '2021-03-17T12:26:19Z',
      whodunnit: { id: 11, name: 'Михаил Провизион', avatar_url: null },
    },
    {
      id: 2739,
      kind: 'reject',
      created_at: '2021-03-17T12:26:19Z',
      whodunnit: { id: 11, name: 'Михаил Провизион', avatar_url: null },
    },
    /* {
      id: 2739,
      kind: 'annul',
      created_at: '2021-03-17T12:26:19Z',
      whodunnit: { id: 12, name: 'Заказчик', avatar_url: null },
    }, */
  ],
  contractor: {
    id: 200,
    name: 'Иванов Иван',
    moi_nalog: {
      // status: 'unbound',
      status: 'connected',
    },
  },
  company: { id: 2, name: 'Restaff' },
  projects: [],
}

export let task = {
  ...act,
  kind: 'task',
  task: {
    status: 'assigned',
    description: 'Описание',
    attachments: [],
    subtasks: [],
  },
}

let tax_box_withholdings = {
  // Удержания в копилку
  tax_savings: {
    amount: '1111.01',
    items: [
      {
        title: 'На оплату налога по акту',
        description:
          'С каждого акта часть суммы откладывается в копилку на оплату налога ',
        amount: '11.23',
      },
      {
        title:
          'На оплату налогов за доходы, задекларированные не через платформу Restaff',
        description:
          '“Название акта” от компании “ООО Restaff ПРО” от 23 июн. на сумму 4500,59 Р',
        amount: '11.23',
      },
      {
        title: 'На оплату налоговой задолженности',
        description:
          'У вас имеется неоплаченная налоговая задолженность, которую нужно оплатить',
        amount: '11.23',
      },
    ],
  },
  // Комиссия за перевод на карту
  transfer_commission_amount: '11.22',
  // Сумма выплаты
  payment_amount_after_withholdings: '7777.77',
}

const getCompanyPage = (req: any, res: any, ctx: any) =>
  res(ctx.delay(500), ctx.json(paginate(tasks, req.body.page)))

const getContractorPage = (req: any, res: any, ctx: any) =>
  res(ctx.delay(500), ctx.json(paginate(tasks, req.params.page)))

export default [
  rest.get('/api/contractor/tasks/page/:page', getContractorPage),
  rest.get('/api/contractor/acts/page/:page', getContractorPage),

  rest.post('/api/company/tasks/filter', getCompanyPage),
  rest.post('/api/company/acts/filter', getCompanyPage),

  rest.post('/api/company/acts/for_multi_accept', json(tasks)),
  // rest.post('/api/company/acts/for_multi_pay', json([tasks[0]])), // RUB
  rest.post('/api/company/acts/for_multi_pay', json([tasks[1]])), // EUR

  rest.post('/api/company/acts/for_multi_mark_paid', json(tasks)),

  // rest.get('/api/company/tasks/:id', json(act)),
  rest.get('/api/company/tasks/:id', json(task)),

  rest.get('/api/contractor/tasks/:id', json(act)),

  rest.delete('/api/company/tasks/:id', json({})),
  rest.patch('/api/company/tasks/:id', json({})),

  rest.post(
    '/api/company/tasks/code_for_multi_:action',
    json({
      action_info: { payment_amount: 1337 },
      sign_id: 42,
    })
  ),
  rest.put('/api/company/tasks/multi_annul', json({})),

  rest.get(
    '/api/contractor/tasks/:id/withholdings',
    json(tax_box_withholdings)
    // json(tax_box_no_withholdings)
    // json(tax_account_withholdings)
  ),

  rest.post('/api/company/tasks/:id/code_for_sign', json({})),
  rest.post('/api/contractor/tasks/:id/code_for_sign', json({})),
  rest.put('/api/contractor/tasks/:id/make_work_acceptance', json({})),
  rest.post('/api/company/tasks/:id/code_for_accept_annul', json({})),
  rest.put('/api/company/tasks/:id/accept_annul', json({})),

  rest.post(
    '/api/company/tasks/code_for_multi_pay',
    json({
      sign_id: 1337,
      action_info: {
        commission: '123.12',
        payment_amount: '123123.12',
        payment_amounts: [{ id: 9, amount: '123123.12' }],
      },
    })
  ),

  rest.post(
    '/api/company/tasks/multi_pay',
    json({
      sign_id: 1337,
      completed: 5,
      // completed: 40,
      total: 40,
      tasks: [],
    })
  ),

  rest.post(
    '/api/company/tasks/multi_mark_paid',
    json({
      sign_id: 1337,
      completed: 2,
      // completed: 40,
      total: 2,
      tasks: [],
    })
  ),

  rest.get('/api/company/exchange_rates', json({ value: '88.44' })),
]
