import React, { ReactNode } from 'react'
import { flatten } from 'lodash'

import { Flex } from 'components/ui'

type Propses = { [key in 'p' | 'b']?: any }
interface Props {
  value?: string
  propses?: Propses
}

const interpose = <T,>(arr: T[], glue: T) =>
  flatten(arr.map((item, index) => (index === arr.length - 1 ? [item] : [item, glue])))

export function FormattedText({ value, propses = {}, ...props }: Props) {
  if (!value) return null
  const text = value.split('\n\n').map((t, i) => (
    <p {...propses.p} key={`${t}-${i}`}>
      <FormattedString value={t} {...{ propses }} />
    </p>
  ))
  return (
    <Flex direction="column" gap="1rem" {...props}>
      {text}
    </Flex>
  )
}

function FormattedString({ value }: Props) {
  if (!value) return null
  return (
    <>
      {interpose<ReactNode>(value.split('\n').filter(Boolean), <br />).map((t, i) => (
        <span key={`${t}-${i}`}>{t}</span>
      ))}
    </>
  )
}
