import { ComponentProps } from 'app.types'
import * as React from 'react'
import { Flex } from '..'

export const CardRow = ({ children, ...rest }: ComponentProps) => (
  <Flex
    style={{ width: '100%' }}
    wrap
    gap="16px"
    align="center"
    justify="start"
    {...rest}
  >
    {children}
  </Flex>
)
