import React, { CSSProperties } from 'react'
//@ts-ignore
import Select from 'react-select'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react-lite'

import Avatar from '../Avatar'
import { useUserStore } from 'stores/context'
import { Entity } from 'mobx/mobx'
import { CompanyEntity } from 'entities'
import { Amount } from 'components'

import s from './styles.module.css'

const customStyles = {
  //@ts-ignore
  container: (styles) => ({
    ...styles,
    position: 'absolute',
    top: 7.5,
    right: 10,
    width: 35,
    height: 35,
  }),
  //@ts-ignore
  valueContainer: (styles) => ({ ...styles, padding: 0 }),
  //@ts-ignore
  indicatorSeparator: (styles) => ({ ...styles, display: 'none' }),
  //@ts-ignore
  control: (styles, state) => {
    return {
      ...styles,
      padding: 0,
      border: 'none',
      background: state.isFocused ? '#f1ecff' : 'transparent',
      boxShadow: 'none',
      cursor: 'pointer',
      flexWrap: 'no-wrap',
      minHeight: 0,
      '&:hover': {
        background: '#D7D3E5',
      },
    }
  },
  //@ts-ignore
  option: (provided, state) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    color: '#272E40',
    backgroundColor: state.isFocused ? '#EFEBFF' : '#fff',
    cursor: 'pointer',
    borderRadius: '6px',
    padding: '10px 15px',
    '&:hover': {
      backgroundColor: '#EFEBFF',
    },
  }),
  //@ts-ignore
  menu: (provided) => ({
    ...provided,
    width: '300px',
    left: '-200px',
    padding: 0,
    borderRadius: '4px',
    boxShadow: '0px 4px 20px #EEEEFA, 0px 0px 3px rgba(0,0,0,0.35)',
  }),
  //@ts-ignore
  menuList: (provided) => ({
    ...provided,
    padding: '10px 10px',
  }),
  //@ts-ignore
  singleValue: (provided) => ({
    ...provided,
    maxWidth: 'calc(100% - 35px)',
  }),
}

interface CompanySelectorProps {
  style: CSSProperties
}

let CompanySelector = observer(({ style }: CompanySelectorProps) => {
  let currentUser = useUserStore()
  let user = currentUser.user!
  let company = currentUser.company!
  let select
  if (user.data.companies!.items.length > 1) {
    select = (
      <Select
        options={user.data.companies!.items}
        value={company}
        isDisabled={user.data.companies!.items.length === 1}
        onChange={(option: Entity<CompanyEntity>) => currentUser.changeCompany(option.id)}
        getOptionLabel={(option: Entity<CompanyEntity>) => (
          <>
            <Avatar
              size="24px"
              value={option.data.avatar_url}
              name={option.data.name}
              className={s.avatar}
            />
            <div className={s.option}>{option.data.name}</div>
          </>
        )}
        getOptionValue={({ id }: Entity<CompanyEntity>) => id}
        styles={customStyles}
        isSearchable={false}
        controlShouldRenderValue={false}
      />
    )
  }
  return (
    <div style={style} className={s.root}>
      <Link
        to="/company"
        className={s.companyLink}
        style={{ paddingRight: select ? 50 : 25 }}
      >
        <Avatar
          size="22px"
          value={company.data.avatar_url}
          name={company.data.name}
          className={s.avatar}
        />
        <div className={s.companyName}>
          {company.data.name}
          {company.data.nominal_account && (
            <div className={s.balance}>
              Баланс: <Amount value={company.data.nominal_account.data.balance} />
            </div>
          )}
        </div>
      </Link>
      {select}
    </div>
  )
})

export default CompanySelector
