import { computed, IComputedValue, makeAutoObservable } from 'mobx'
import { mapValues } from 'lodash'

export type Sections<T> = { [key: string]: (item: T) => boolean }

export const makeSectionsCounters = <T>(
  items: IComputedValue<Array<T>>,
  sections: Sections<T>
) =>
  mapValues(sections, (filter) =>
    computed(() => {
      let count = 0
      items.get().forEach((item) => {
        if (filter(item)) count++
      })
      return count
    })
  )

class SectionedStore<T> {
  constructor({
    items,
    sections,
    section,
  }: {
    items: IComputedValue<Array<T>>
    sections: Sections<T>
    section?: string
  }) {
    this.allItems = items
    this.sections = sections
    this.section = section
    this.counters = makeSectionsCounters(items, sections)
    makeAutoObservable(this)
  }

  allItems: IComputedValue<Array<T>>
  sections: Sections<T>
  section?: string
  counters: { [key: string]: IComputedValue<number> }

  get items() {
    let items = this.allItems.get()
    return this.section === undefined ? items : items.filter(this.sections[this.section])
  }
}

export default SectionedStore
