import React from 'react';

interface IErrorProps {
    className?: string;
    style?: React.CSSProperties;
}

export const Error = ({
    className,
    style = {},
}: IErrorProps) => (
    <div
        className={className}
        style={{
            ...style,
            background: '#f24822',
            height: 70,
            width: 70,
            borderRadius: '50%',
        }}
    />
);

export default Error;
