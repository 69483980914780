import React, { forwardRef } from 'react'
import { animated, SpringValue } from 'react-spring'
import useMeasure from 'react-use-measure'
import { ResizeObserver } from '@juggle/resize-observer'

export interface CollapseAnimationProps extends React.HTMLAttributes<HTMLDivElement> {
  openValue: SpringValue
  children?: React.ReactNode
}

const CollapseAnimation = forwardRef<HTMLDivElement, CollapseAnimationProps>(
  (props: CollapseAnimationProps, ref) => {
    const { openValue, children, ...restProps } = props
    const [measureRef, { height }] = useMeasure({
      polyfill: ResizeObserver,
    })

    const style: any = { overflowY: 'hidden', ...restProps.style }

    style.height = openValue.to({ output: [0, height] })

    return (
      <animated.div ref={ref} {...restProps} style={style}>
        {/* use display flex, so it measures margins of the children */}
        <div ref={measureRef as any} style={{ display: 'flex' }}>
          {children}
        </div>
      </animated.div>
    )
  }
)

CollapseAnimation.displayName = 'Collapse'

export default CollapseAnimation
