import { useMediaContext } from 'App/MediaContext'
import { observer } from 'mobx-react-lite'
import React, { useMemo } from 'react'
import { Collection, Entity } from 'mobx/mobx'
import { SubtaskTemplateEntity, UnitEntity } from 'entities'
import { uniqBy } from 'lodash'

// @ts-ignore
import { FormStore } from 'shadowform'

import {
  Button,
  FormRow,
  Field,
  Input,
  SelectInput,
  Flex,
} from 'components/ui'

const requiredError = 'Необходимо заполнить поле'

const createForm = (title: string | undefined) =>
  new FormStore({
    fields: {
      title: {
        isRequired: true,
        requiredError,
      },
      description: {
        isRequired: true,
        requiredError,
      },
      unit: {
        isRequired: true,
        requiredError,
      },
      unit_cost: {
        isRequired: true,
        requiredError,
      },
    },
    initialValues: {
      title: title ? {
        value: title,
        label: title
      } : ''
    }
  })

interface SubtaskTemplateCreateData {
  title: string
  description: string
  unit_id: number
  unit_cost: number
}

interface SubtaskTemplateCreateFormProps {
  templates: Collection<SubtaskTemplateEntity>
  units: Collection<UnitEntity>
  onSubmit: (data: SubtaskTemplateCreateData) => void
  title?: string
}

const getOptions = (templates: Collection<SubtaskTemplateEntity>) =>
  uniqBy(templates.items, 'data.title').map((t: Entity<SubtaskTemplateEntity>) => ({
    value: t.data.title,
    label: t.data.title,
  }))

const getUnitOptions = (units: Collection<UnitEntity>) =>
  units.items.map((unit) => ({ value: unit.id, label: unit.data.title }))

const SubtaskTemplateCreateForm = observer(
  ({ templates, units, onSubmit, title }: SubtaskTemplateCreateFormProps) => {
    let { isMobile } = useMediaContext()
    let form = useMemo(() => createForm(title), [])
    let onClickSubmit = () => {
      let { title, description, unit, unit_cost } = form.values
      onSubmit({ title: title.value, description, unit_id: unit.value, unit_cost })
    }
    let size: 'l' | 'm' = isMobile ? 'm' : 'l'

    let options = getOptions(templates);

    if (title) {
      options = [...options, { value: title, label: title }]
    }

    return (
      <Flex gap="3rem" direction="column">
        <Field field={form.fields.title}>
          {({ inputProps, error }) => (
            <FormRow label="Вид задания" size={size} error={error}>
              <SelectInput
                {...inputProps}
                autoFocus={!title}
                isWide
                size={size}
                placeholder="Выберите или создайте вид задания"
                options={options}
                isCreatable
              />
            </FormRow>
          )}
        </Field>

        <Field field={form.fields.description}>
          {({ inputProps, error }) => (
            <FormRow label="Описание" size={size} error={error}>
              <Input
                {...inputProps}
                autoFocus={!!title}
                placeholder="Опишите все подробности"
                isWide
                size={size}
              />
            </FormRow>
          )}
        </Field>

        <Field field={form.fields.unit}>
          {({ inputProps, error }) => (
            <FormRow label="Единица" size={size} error={error}>
              <SelectInput
                {...inputProps}
                size={size}
                placeholder="Выберите единицу измерения"
                isClearable
                options={getUnitOptions(units)}
              />
            </FormRow>
          )}
        </Field>

        <Field field={form.fields.unit_cost}>
          {({ inputProps, error }) => (
            <FormRow label="Стоимость 1 ед." size={size} error={error}>
              <Input
                {...inputProps}
                type="number"
                placeholder="Введите стоимость задания"
                isWide
                size={size}
              />
            </FormRow>
          )}
        </Field>

        <Button isDisabled={!form.isValid} onTap={onClickSubmit}>
          Добавить работу
        </Button>
      </Flex>
    )
  }
)

export default SubtaskTemplateCreateForm