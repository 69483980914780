import React from 'react'
import { observer, Observer } from 'mobx-react-lite'

import { ReactComponent as ArrowDropDownIcon } from '@material-design-icons/svg/round/arrow_drop_down.svg'

import { useUserStore } from 'stores/context'

import { Flex } from 'components/ui'

import { FilterButton } from './components'
import { Menu, MenuItem, MenuSeparator } from './ui'
import SegmentsStore from './SegmentsStore'

const SegmentSelector = observer(({ store }: { store: SegmentsStore }) => {
  let currentUser = useUserStore()
  let menu = () => (
    <>
      {store.segments.items.map((item) => (
        <MenuItem key={item.id} value={item.id}>
          <Flex gap="1rem" justify="space-between" align="center">
            <div>{item.data.title}</div>
            <div style={{ color: 'var(--konsol-color-primary)' }}>{item.data.count}</div>
          </Flex>
        </MenuItem>
      ))}
      {currentUser.hasAbility('segments.update') && (
        <>
          <MenuSeparator />
          <MenuItem onSelect={() => store.openSegmentListDialog()}>
            Управление сегментами
          </MenuItem>
        </>
      )}
    </>
  )
  return (
    <Menu
      matchWidth
      menu={menu}
      onSelect={(id: string) => store.selectSegment(store.segments.map[id])}
      placement={{ align: 'end', offset: 5 }}
    >
      {(ref: any, { open }: any) => (
        <Observer>
          {() => (
            <div ref={ref}>
              <FilterButton isDisabled={store.segments.isEmpty} onTap={open}>
                <Flex gap=".25rem" align="center" justify="space-between">
                  <div>Сегменты</div>
                  <ArrowDropDownIcon style={{ fill: 'var(--color-secondary)' }} />
                </Flex>
              </FilterButton>
            </div>
          )}
        </Observer>
      )}
    </Menu>
  )
})

export default SegmentSelector
