import React, { forwardRef } from 'react'

import { useTaply, TapState, TapEvent } from './taply'
import { Row } from './flex'
import { useStyles, StyleProps, StylesMap, omitStyleProps } from './styled'
import mergeRefs from './utils/mergeRefs'

export interface ButtonSpinnerProps {
  style: React.CSSProperties
  buttonProps: ButtonPropsWithDefault
}

export interface ButtonProps
  extends Omit<React.HTMLAttributes<HTMLElement>, 'style'>,
    StyleProps<[ButtonProps, TapState]> {
  children?: React.ReactNode
  icon?: React.ReactNode
  onTap?: (event: TapEvent) => void
  isDisabled?: boolean
  isLoading?: boolean
  as?: React.ElementType<any>
  className?: string
  Spinner?: React.ComponentType<ButtonSpinnerProps>
}

const defaultProps = {
  as: 'button' as React.ElementType<any>,
  isDisabled: false,
  isLoading: false,
  Spinner: () => <>...</>,
}

export type ButtonPropsWithDefault = ButtonProps & typeof defaultProps

const style = (props: ButtonPropsWithDefault, _tapState: TapState): StylesMap => {
  const { isDisabled, isLoading } = props

  const root: React.CSSProperties = {
    WebkitTapHighlightColor: 'rgba(0,0,0,0)',
    outline: 'none',
    position: 'relative',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: isDisabled || isLoading ? 'default' : 'pointer',
    textAlign: 'center',
    textDecoration: 'none',
    userSelect: 'none',
    whiteSpace: 'nowrap',
    border: 'none',
  }

  const spinner: React.CSSProperties = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translateX(-50%) translateY(-50%)',
  }

  const content = {
    opacity: isLoading ? 0 : 1,
  }

  return { root, spinner, content }
}

const Button = forwardRef((_props: ButtonProps, ref) => {
  const props = _props as ButtonPropsWithDefault
  const {
    children,
    onTap,
    className,
    isDisabled,
    isLoading,
    icon,
    as: _Component,
    Spinner,
    ...restProps
  } = omitStyleProps(props)

  const { tapState, ref: taplyRef } = useTaply({
    isDisabled: isDisabled || isLoading,
    onTap,
  })
  const styles = useStyles(style, [props, tapState])
  const buttonChildren = (
    <>
      <Row gap="6px" align="center" style={styles.content}>
        {icon}
        {children && <div>{children}</div>}
      </Row>
      {isLoading && <Spinner style={styles.spinner} buttonProps={props} />}
    </>
  )
  return React.createElement(
    props.as,
    {
      className,
      ...restProps,
      ref: mergeRefs(ref, taplyRef),
      style: styles.root,
    },
    buttonChildren
  )
})

Button.defaultProps = defaultProps

Button.displayName = 'Button'

export { Button }
