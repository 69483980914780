import React from 'react'

import { H1, Link, Notification } from 'ui'


import RegistrationFooter from 'pages/registration/components/Footer'

import css from './styles.module.css'

const CheckErrorStep = ({ error }: { error: any }) => {
  return (
    <>
      <H1 style={{ marginBottom: 30 }}>Ошибка проверки документов</H1>
      <Notification kind="error" style={{ padding: '20px' }}>
        <div>
          <b>Нужно предоставить документы</b>
        </div>
        <div className={css.errorDescription}>{error}</div>

      <div style={{ marginTop: '1rem' }}>
        Please contact us at <Link href='mailto:hello@restaff.pro' target='_blank'>hello@restaff.pro</Link> if you have any issues
      </div>

      </Notification>
      <div className={css.errorImage}></div>
      <RegistrationFooter />
    </>
  )
}

export default CheckErrorStep
