import React from 'react'
//@ts-ignore
import { useStyles } from 'floral'
import { omit } from 'lodash'

import { useMediaContext } from 'App/MediaContext'

import { FloralProps } from './floral.types'

const omitFloralProps = (props: any) => omit(props, ['style', 'styles'])

const headingStyles = ({ level }: HeadingProps, isMobile: boolean) => {
  let root: React.CSSProperties = { lineHeight: '1.25', letterSpacing: '0.01em' }
  if (level === '1') {
    Object.assign(root, {
      fontSize: isMobile ? 26 : 30,
      fontWeight: 'bold',
    })
  }
  if (level === '2') {
    Object.assign(root, {
      fontSize: isMobile ? 22 : 24,
      fontWeight: 'bold',
    })
  }
  if (level === '3') {
    Object.assign(root, { fontSize: 15, fontWeight: '600' })
  }
  return { root }
}

type HeadingLevel = '1' | '2' | '3'

interface HeadingProps
  extends Omit<React.HTMLAttributes<HTMLElement>, 'style'>,
  FloralProps {
  as?: any
  level?: HeadingLevel
  children?: React.ReactNode
  [key: string]: any
}

let components = {
  '1': 'h1',
  '2': 'h2',
  '3': 'h3',
}

const Heading = (props: HeadingProps) => {
  let { level, as, ...restProps } = omitFloralProps(props)
  let { isMobile } = useMediaContext()
  let styles = useStyles(headingStyles, [props, isMobile])
  let component = as || components[level as HeadingLevel]
  return React.createElement(component, { ...restProps, style: styles.root })
}

Heading.defaultProps = {
  level: 1,
}

const H1 = (props: Omit<HeadingProps, 'level'>) => <Heading {...props} level="1" />
const H2 = (props: Omit<HeadingProps, 'level'>) => <Heading {...props} level="2" />
const H3 = (props: Omit<HeadingProps, 'level'>) => <Heading {...props} level="3" />

export default Heading
export { H1, H2, H3 }
