import { rest } from 'msw'
import { json, error } from './helpers'

const onboardingScenarios = [
  {
    id: 1,
    title: 'Водитель москва',
    invite_url: 'join/driver_moscow',
    permalink: 'driver_moscow',
    enabled: true,
    archived: false,
    register_count: 42,
    contractor_custom_fields: [
      {
        uuid: "1",
        fill_target: "company",
        required: true,
        default_value: "12.04.2022"
      }
    ],
    document_fields: [
      "driver_license",
    ],
    contract_template_id: 42,
    template_fields: [
      {
        caption: "Обятазельное поле",
        is_required: true,
        key: "contract_end_date",
        name: "Дата окончания договора",
        type: "date"
      }
    ]
  },
  {
    id: 2,
    title: 'Водитель москва',
    invite_url: 'join/driver_moscow',
    permalink: 'driver_moscow',
    enabled: true,
    archived: false,
    register_count: 42,
    contractor_custom_fields: [
      {
        uuid: "1",
        fill_target: "company",
        required: true,
        default_value: "12.04.2022"
      }
    ],
    document_fields: [
      "driver_license",
    ],
    contract_template_id: 42,
    template_fields: []
  },
  {
    id: 3,
    title: 'Водитель москва',
    invite_url: 'join/driver_moscow',
    permalink: 'driver_moscow',
    enabled: true,
    archived: true,
    register_count: 42,
    contractor_custom_fields: [
      {
        uuid: "1",
        fill_target: "company",
        required: true,
        default_value: "12.04.2022"
      }
    ],
    document_fields: [
      "driver_license",
    ],
    contract_template_id: 42,
    template_fields: []
  },
];

const onboardingScenarioDocumentItems = [
  {
    key: "driver_license",
    name: "Водительское удостоверение"
  }
]

const onboardingScenario = {
  id: 1,
  enabled: true,
  archived: false,
  invite_url: 'join/driver_moscow',
  contractor_custom_fields: [
    {
      uuid: "1",
      fill_target: "company",
      required: true,
      default_value: "12.04.2022"
    }
  ],
  document_fields: [
    "driver_license",
  ],
  title: "Водитель москва",
  contract_template_id: 42,
  permalink: "driver_moscow",
  join_options: {
    individual_entrepreneur: true,
    self_employed: true,
    gph: true,
    non_resident: true,
    check_contractor_spectrum_data: true
  }
};

const onboardingScenarioValidation = {
  error: {
    message: "Не указан статус налогообложения",
    validation: {
      permalink: "Этот пермалинк уже занят"
    }
  }
};

export default [
  rest.get(
    '/api/company/onboarding_scenarios',
    json(onboardingScenarios)
  ),
  rest.get(
    '/api/company/onboarding_scenarios/1',
    json(onboardingScenario)
  ),
  rest.post(
    '/api/company/onboarding_scenarios',
    error(422, onboardingScenarioValidation)
  ),
  rest.patch(
    '/api/company/onboarding_scenarios/1',
    error(422, onboardingScenarioValidation)
  ),
  rest.get(
    '/api/company/onboarding_scenarios/document_fields',
    json(onboardingScenarioDocumentItems)
  ),
];
