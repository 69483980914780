import React from 'react'
import cl from 'clsx'
import styles from './styles.module.css'

interface KebabMenuProps {
  items: KebabMenuItem[]
}

export interface KebabMenuItem {
  condition: boolean
  icon: () => JSX.Element
  title: string
  onClick: (e: React.SyntheticEvent<Element, Event>) => void
  className?: string
}

const KebabMenu = (props: KebabMenuProps) => {
  const { items } = props

  return (
    <div className={styles.menu}>
      {items.map((item) => {
        return (
          item.condition && (
            <div
              className={cl(styles.menuItem, item.className)}
              onClick={item.onClick}
              key={item.title}
            >
              <div className={styles.iconWrapper}>
                <item.icon />
              </div>
              <span>{item.title}</span>
            </div>
          )
        )
      })}
    </div>
  )
}

export default KebabMenu
