import React from 'react'
import { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react-lite'

import { useMediaContext } from 'App/MediaContext'
import { useUserStore } from 'stores/context'
import { Banner } from 'components/Banner'
import { Flex } from 'components/ui'
import { fromNow, printDate } from 'utils/datetime'
import { localStorageValue } from 'utils/localStorage'

import styles from './styles.module.css'

const poaValue = localStorageValue('poaExpired')

export const AttorneyExpiration = observer(() => {
  let { isMobile } = useMediaContext()
  const currentUser = useUserStore()

  const id = currentUser?.company?.id
  const currentUserId = currentUser?.user?.data.id

  const [isRead, setIsRead] = useState<boolean>(
    poaValue.get()?.some((item: Number) => item === id)
  )

  const [overdue, expiredDays, expiredDate] = useMemo(() => {
    const overdue = currentUser?.company?.data.members.items.find((item) => {
      const { user_id, power_of_attorney } = item.data
      return (
        power_of_attorney?.show_overdue_notification && Number(user_id) === currentUserId
      )
    })
    const expiredAt = overdue?.data?.power_of_attorney?.expired_at
    return [
      overdue,
      !!expiredAt && fromNow(expiredAt),
      !!expiredAt && printDate(new Date(expiredAt), { mode: 'full' }),
    ] as const
  }, [currentUser?.company?.data.members.items, currentUserId])

  const handleClose = () => {
    if (id) {
      const poa = poaValue.get() || []
      poa.push(Number(id))
      poaValue.set(poa)
    }
    setIsRead(true)
  }

  if (overdue && !isRead) {
    return (
      <Banner icon="notice">
        <Flex gap="10px" direction={isMobile ? 'column' : 'row'}>
          <div>
            Срок действия доверенности {expiredDays <= 0 ? 'закончится' : 'закончился'}{' '}
            {expiredDate}
          </div>
          <Flex gap="10px" direction={isMobile ? 'column' : 'row'}>
            <Link
              className={styles.link}
              to={{
                pathname: 'https://support.konsol.pro/new-power-of-attorney',
              }}
              target="_blank"
              rel="noopener noreferrer"
            >
              Получить новую доверенность
            </Link>
            <button type="button" className={styles.link} onClick={handleClose}>
              Скрыть
            </button>
          </Flex>
        </Flex>
      </Banner>
    )
  } else {
    return null
  }
})

export default AttorneyExpiration
