import React, {
  forwardRef,
  useState,
  useEffect,
  useImperativeHandle,
  useRef,
  useCallback,
} from 'react'
import MaskedInput from 'react-text-mask'

import { useStyles, StyleProps } from 'ui/styled'
import { Taply, TapState, initialTapState } from 'ui/taply'
import { Tooltip } from 'ui/konsol/tooltip'
import useControlledState from 'ui/utils/useControlledState'
import { ReactComponent as CloseIcon } from 'ui/konsol/icons/close-filled.svg'

interface InputClearButtonProps extends StyleProps<[InputClearButtonProps, TapState]> {
  onTap: () => void
}

const clearButtonStyles = (
  props: InputClearButtonProps,
  { isHovered, isPressed, isFocused }: TapState
) => ({
  root: {
    borderRadius: 6,
    cursor: 'pointer',
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: isFocused ? 'var(--konsol-focus-shadow)' : 'none',
    outline: 'none',
    background: isPressed
      ? 'var(--konsol-color-grey-24)'
      : isHovered
      ? 'var(--konsol-color-grey-16)'
      : 'transparent',
  },
})

const InputClearButton = forwardRef<HTMLDivElement, InputClearButtonProps>(
  (props, ref) => {
    const { onTap } = props
    const [tapState, setTapState] = useState(initialTapState)
    const styles = useStyles(clearButtonStyles, [props, tapState])
    return (
      <Taply tapState={tapState} onChangeTapState={setTapState} onTap={onTap}>
        <div style={styles.root} ref={ref}>
          <CloseIcon />
        </div>
      </Taply>
    )
  }
)

export type InputSize = 's' | 'm' | 'l'

export interface InputProps
  extends Omit<
      React.HTMLAttributes<HTMLInputElement>,
      'onChange' | 'value' | 'style' | 'size'
    >,
    StyleProps<[InputProps]> {
  value?: string
  children?: React.ReactNode
  onChange?: (value: string) => void
  autofocus?: boolean
  isInvalid?: boolean
  isDisabled?: boolean
  onFocus?: () => void
  onBlur?: () => void
  isMultiline?: boolean
  rows?: number
  maxRows?: number
  size?: InputSize
  icon?: React.ReactNode
  rightIcon?: React.ReactNode
  isWide?: boolean
  numericKeyboard?: boolean
  isClearable?: boolean
  type?: string
  mask?: any
  persistentPlaceholder?: boolean
  withLeftAddon?: boolean
  withRightAddon?: boolean
}

const defaultProps = {
  value: '',
  size: 'm' as InputSize,
  isInvalid: false,
  isDisabled: false,
  autofocus: false,
  isMultiline: false,
  rows: 1,
  numericKeyboard: false,
  isClearable: false,
  type: 'text',
  persistentPlaceholder: false,
  withLeftAddon: false,
  withRightAddon: false,
}

export type InputPropsWithDefault = InputProps & typeof defaultProps

const inputStyles = (
  props: InputPropsWithDefault,
  isFocused: boolean,
  isHovered: boolean
) => {
  const { size, isDisabled, isInvalid, withLeftAddon, withRightAddon, isClearable } =
    props

  const root: React.CSSProperties = {
    display: 'block',
    cursor: isDisabled ? 'default' : 'text',
    position: 'relative',
    fontSize: 13,
    transition: 'background 0.15s ease-out, box-shadow 0.15s ease-out',
    background: isDisabled
      ? 'var(--konsol-color-grey-08)'
      : isHovered
      ? 'var(--konsol-color-grey-24)'
      : 'var(--konsol-color-grey-16)',
  }

  const radius = 6
  root.borderTopLeftRadius = withLeftAddon ? 0 : radius
  root.borderBottomLeftRadius = withLeftAddon ? 0 : radius

  root.borderTopRightRadius = withRightAddon ? 0 : radius
  root.borderBottomRightRadius = withRightAddon ? 0 : radius

  const wrapper: React.CSSProperties = {
    display: 'grid',
    flexGrow: 1,
    // width: '100%',
  }

  const color = props.isDisabled
    ? 'var(--konsol-color-text-disabled)'
    : 'var(--konsol-color-text)'

  const input: React.CSSProperties = {
    margin: 0,
    background: 'transparent',
    border: 'none',
    outline: 'none',
    boxSizing: 'border-box',
    color,
    opacity: 1,
    font: 'inherit',
    gridArea: '1 / 1 / 2 / 2',
    width: '100%',
    resize: 'none',
    padding: 0,
  }

  if (isFocused) {
    root.boxShadow = 'var(--konsol-focus-shadow)'
    root.background = 'white'
  }

  if (isInvalid) {
    root.boxShadow = '0 0 0 1px var(--konsol-color-red)'
    root.background = 'var(--konsol-color-red-08)'
  }

  if (props.value.length > 0) {
    input.WebkitTextFillColor = color // Override iOS font color change
  }

  const lineHeight = 1.5
  const { rows, maxRows } = props
  const replica: React.CSSProperties = {
    font: 'inherit',
    whiteSpace: 'pre-wrap',
    visibility: 'hidden',
    gridArea: '1 / 1 / 2 / 2',
  }
  if (rows) replica.minHeight = `calc(1em * ${rows * lineHeight})`
  if (maxRows) replica.maxHeight = `calc(1em * ${maxRows * lineHeight})`

  const placeholder: React.CSSProperties = {
    gridArea: '1 / 1 / 2 / 2',
    fontWeight: 400,
    color: 'var(--konsol-color-grey-64)',
    // opacity: 0.1,
    pointerEvents: 'none',
  }

  if (size === 'l') {
    root.padding = '14px 12px'
  } else if (size === 'm') {
    root.padding = '10.25px 12px'
  } else if (size === 's') {
    root.padding = '7.5px 12px'
  }

  if (props.isWide) {
    root.width = '100%'
  }

  const elem: React.CSSProperties = {
    position: 'absolute',
    height: '100%',
    top: 0,
    display: 'flex',
    alignItems: 'center',
    fill: 'var(--konsol-color-black)',
  }
  const leftIcon: React.CSSProperties = { ...elem, left: 12, pointerEvents: 'none' }
  const rightIcon: React.CSSProperties = { ...elem, right: 12, pointerEvents: 'none' }
  const clearButton: React.CSSProperties = { ...elem, right: 4 }

  if (props.icon) root.paddingLeft = 36
  if (isClearable || props.rightIcon) root.paddingRight = 36

  return { root, wrapper, input, replica, leftIcon, rightIcon, clearButton, placeholder }
}

interface InputImperativeHandle {
  focus: () => void
  ref: React.MutableRefObject<any>
}

const Input = forwardRef<InputImperativeHandle, InputProps>((_props: InputProps, ref) => {
  const props = _props as InputPropsWithDefault
  const {
    value,
    placeholder,
    onChange,
    isDisabled,
    autofocus,
    onFocus,
    onBlur,
    isMultiline,
    icon,
    rightIcon,

    numericKeyboard,
    isClearable,
    type,
    mask,

    ...restProps
  } = props
  const [isFocused, setIsFocused] = useControlledState(props, 'isFocused', false)
  const [isHovered, setIsHovered] = useState(false)
  const styles = useStyles(inputStyles, [props, isFocused, isHovered])

  const inputRef = useRef<HTMLInputElement | any>()
  const rootRef = useRef<HTMLInputElement | any>()
  const focus = useCallback(() => {
    if (mask) inputRef.current?.inputElement?.focus?.()
    else inputRef.current?.focus?.()
  }, [mask, inputRef])
  useImperativeHandle(ref, () => ({ focus, ref: rootRef }), [rootRef, focus])
  useEffect(() => {
    if (autofocus) focus()
  }, [])

  const elem: any = isMultiline ? 'textarea' : mask ? MaskedInput : 'input'
  const elemProps: any = {
    ref: inputRef,
    value,
    disabled: isDisabled,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
      if (onChange) onChange(e.target.value)
    },
    onFocus: () => {
      setIsFocused(true)
      onFocus && onFocus()
    },
    onBlur: () => {
      setIsFocused(false)
      onBlur && onBlur()
    },
    ...restProps,
    rows: 1,
    style: styles.input,
    type,
  }

  if (mask) {
    Object.assign(elemProps, { mask, guide: false })
  }

  if (numericKeyboard) {
    Object.assign(elemProps, { inputmode: 'numeric', pattern: '[0-9]*' })
  }

  const showPlaceholder =
    placeholder !== undefined && (value === '' || props.persistentPlaceholder)

  const onTapClearButton = () => {
    onChange?.('')
    setTimeout(() => inputRef.current?.focus())
  }

  let rightElem
  if (isClearable && value !== '') {
    rightElem = (
      <div style={styles.clearButton}>
        <Tooltip tooltip="Удалить">
          <InputClearButton onTap={onTapClearButton} />
        </Tooltip>
      </div>
    )
  } else if (rightIcon) {
    rightElem = <div style={styles.rightIcon}>{rightIcon}</div>
  }

  return (
    <label
      style={styles.root}
      ref={rootRef}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div style={styles.wrapper}>
        {showPlaceholder && <div style={styles.placeholder}>{placeholder}</div>}
        {React.createElement(elem, elemProps)}
        {isMultiline && <div style={styles.replica}>{value} </div>}
      </div>
      {icon && <div style={styles.leftIcon}>{icon}</div>}
      {rightElem}
    </label>
  )
})

Input.defaultProps = defaultProps

export { Input }
