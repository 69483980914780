import { makeAutoObservable } from 'mobx'
import { initialActionState } from 'mobx/MobxApi'
import { ActionState } from 'mobx/types'

export default class FileUploader {
  constructor(uploadFn: (file: File) => ActionState<any>) {
    this.uploadFn = uploadFn
    makeAutoObservable(this)
  }

  upload(file?: File) {
    // TODO abort, delete
    this.uploadState = file ? this.uploadFn(file) : initialActionState
    return this.uploadState
  }

  uploadFn: (file: File) => ActionState<any>
  abortController?: AbortController
  uploadState = initialActionState

  get isLoading() {
    return this.uploadState.state === 'pending'
  }
}
