import { DocumentAction } from 'entities'

export interface DocumentMultiActionConfig {
  action: DocumentAction
}

export const documentActionMethods: { [key in DocumentAction]?: string } = {
  sign: 'sign',
  delete: 'delete',
  archive: 'archive',
}

export const actionAbilities: { [key in DocumentAction]?: string } = {
  sign: 'sign',
  delete: 'destroy',
  archive: 'archive',
}

export const documentWordFormsWith = {
  one: 'документов',
  two: 'документами',
  many: 'документами',
}

export const actionConfigs: DocumentMultiActionConfig[] = [
  { action: 'sign' },
  { action: 'delete' },
  { action: 'archive' },
]
