import React from 'react'

import { useTheme, Link } from 'ui'
import { Flex } from 'components/ui'

import css from './styles.module.css'

const FnsPartnerBlock = () => {
  const { config } = useTheme()

  return (
    <>
      <Flex gap="2rem" align="center">
        <div className={css.fnsLogo} />
        <div>
          Restaff –{' '}
          <Link href="https://npd.nalog.ru/aggregators/" target="_blank">
            официальный партнер ФНС
          </Link>
        </div>
      </Flex>
      <div
        dangerouslySetInnerHTML={{ __html: config.text.pages.registration.moi_nalog }}
      />
    </>
  )
}

export default FnsPartnerBlock
