import { isEqual } from 'lodash'

import { NotFilledFilterValue } from 'entities'

export const NOT_FILLED_VALUE: NotFilledFilterValue = { not_filled: true }
export const NOT_FILLED_TEXT = 'Не заполнено'

export const isNotFilledValue = <T>(
  v: T | NotFilledFilterValue
): v is NotFilledFilterValue => isEqual(v, NOT_FILLED_VALUE)
