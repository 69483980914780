import React from 'react'

import { NotFilledFilterValue } from 'entities'
import { Col, Flex, Input, Checkbox } from 'components/ui'
import NumberInput from 'components/NumberInput'

import { DropdownButton } from '../DropdownButton'
import { NOT_FILLED_VALUE, NOT_FILLED_TEXT, isNotFilledValue } from '../../constants'

interface FilterProps<T> {
  initialFocusRef?: any
  value?: T | NotFilledFilterValue
  withNotFilled?: boolean
  onChange: (value: any) => void
  onClose: () => void
}

interface TextFilterProps extends FilterProps<string> {
  kind: any
}

const TextFilter = ({
  value,
  withNotFilled,
  onChange,
  onClose,
  kind,
}: TextFilterProps) => {
  const isNotFilledValueSelected = isNotFilledValue(value)

  return (
    <Col gap="var(--gap-s)">
      {kind === 'text' ? (
        <Input
          placeholder="Введите значение"
          value={isNotFilledValueSelected ? '' : (value as string) || ''}
          onChange={onChange}
          autofocus={true}
          isClearable={true}
          type={kind}
        />
      ) : (
        <NumberInput
          value={isNotFilledValueSelected ? '' : value}
          onChange={(value) => onChange(String(value!))}
          allowDecimal={true}
        />
      )}
      {withNotFilled && (
        <Flex align="center" justify="start" gap="12px">
          <Checkbox
            styles={{ label: { color: 'grey', fontWeight: 'normal' } }}
            onChange={(value) => onChange(value ? NOT_FILLED_VALUE : '')}
            value={isNotFilledValueSelected}
            label={NOT_FILLED_TEXT}
          />
        </Flex>
      )}
      <DropdownButton onTap={onClose}>Готово</DropdownButton>
    </Col>
  )
}

export default TextFilter
