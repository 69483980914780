import React, { useMemo, useState, useEffect } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { toJS } from 'mobx'
import { fromPromise } from 'mobx-utils'
import { pick, reverse, clone, without, compact, sum } from 'lodash'
import qs from 'query-string'

import { PageProps } from 'app.types'
import { useMediaContext } from 'App/MediaContext'
import { usePusherSubscription } from 'App/pusher'
import { useUserStore, useSignDialogStore } from 'stores/context'
import { fetchApi } from 'api'
import { useMobxApi, Entity } from 'mobx/mobx'
import { TaskEntity, TaskFnsReceipt, ContractorEntity, DocumentStatus } from 'entities'

import { printPeriod } from 'utils/datetime'
import { formatAmount } from 'utils/amount'
import { thinsp } from 'utils/typo'
import useDropZone from 'utils/useDropZone'
import useHashState from 'utils/useHashState'
import { ConnectMoiNalogCard } from 'pages/taxes/TaxBoxPage'
import NoPaymentDetailsCard from 'components/paymentDetails/NoPaymentDetailsCard'

import {
  ActionStateView,
  StatusBadge,
  Input,
  Flex,
  Col,
  Modal,
  useStateToast,
  RadioInput,
  Layout,
  BackLink,
  Button,
  Menu,
  MenuButton,
  MenuList,
  Card,
  H1,
  H2,
  H3,
  ConfirmDialog,
  useConfirmDialog,
} from 'components/ui'
import { TooltipButton, AbilityButton, AbilityMenuItem } from 'components/abilityButtons'
import { Amount, Avatar, FileThumb } from 'components'
import { FormattedText } from 'components/Text'
import {
  AttachmentsStore,
  AttachmentsZone,
  DropShade,
} from 'components/ui/AttachmentsZone'
import {
  PaymentDetailsView,
  PaymentDetailsEditModal,
} from 'pages/contractors/ContractorPaymentDetails'

import type { Subtask } from 'components/SubtaskList/types'
import SubtaskList from 'components/SubtaskList'
import TaskEventList, { sectionsEvents } from 'components/tasks/TaskEventList'
import TaskCreateForm from 'components/tasks/TaskCreateForm'
import TaskActionsStore, { TaskReportData } from 'components/tasks/TaskActionsStore'
import TaskProjectSelector from 'components/tasks/TaskProjectSelector'
import { oldTaskStatusMap } from 'components/tasks/statuses'
import { TaskProblemList } from 'components/tasks/taskProblems'
import { Link as LinkUiKit } from 'ui-kit'

import { ReactComponent as MoreIcon } from '@material-design-icons/svg/round/more_horiz.svg'
import defaultCompanyAvatar from 'assets/avatar-company.png'

import s from './styles.module.css'

const Section = (props: any) => (
  <Flex className={s.section} gap="var(--gap-m)" direction="column" {...props} />
)

interface WhoBlockProps {
  who: { avatar_url?: string; name: string }
  title: string
  to: any
}

const WhoBlock = ({ who, title, to }: WhoBlockProps) => (
  <Link to={to} className={s.whoBlock}>
    <Avatar
      value={who.avatar_url}
      size="3.5rem"
      name={who.name}
      className={s.whoAvatar}
    />
    <div className={s.whoTitle}>{title}</div>
    <div className={s.whoName}>{who.name}</div>
  </Link>
)

interface CheckPaymentDetailsProps {
  contractor: Entity<ContractorEntity>
}

const CheckPaymentDetails = observer(({ contractor }: CheckPaymentDetailsProps) => {
  let [showDetailsEditModal, setShowDetailsEditModal] = useState(false)
  return (
    <>
      <div>
        Проверьте реквизиты. Если в реквизитах ошибка, то деньги не придут на карту.
      </div>
      <PaymentDetailsView
        contractor={contractor}
        onChange={() => setShowDetailsEditModal(true)}
        canChange={true}
      />
      {showDetailsEditModal && (
        <PaymentDetailsEditModal
          contractor={contractor}
          onClose={() => setShowDetailsEditModal(false)}
        />
      )}
    </>
  )
})

const TaskInfoSection = observer(({ store }: { store: TaskActionsStore }) => {
  let { isMobile } = useMediaContext()
  let currentUser = useUserStore()

  let buttons = []
  if (store.hasAction('sign_terms')) {
    buttons.push(
      <AbilityButton
        ability={currentUser.hasAbility('tasks.sign_terms')}
        isDisabled={store.isActionDisabled('sign_terms')}
        onTap={() => store.action('sign_terms')}
        isLoading={store.isLoading}
      >
        Подписать ТЗ
      </AbilityButton>
    )
  }
  if (store.hasAction('cancel')) {
    buttons.push(
      <AbilityButton
        isDisabled={store.isActionDisabled('cancel')}
        ability={currentUser.hasAbility('tasks.cancel')}
        onTap={() => store.action('cancel')}
        design="normal"
        isLoading={store.isLoading}
      >
        Отменить
      </AbilityButton>
    )
  }
  if (store.hasAction('start')) {
    buttons.push(
      <Button
        onTap={() => store.action('start')}
        isLoading={store.isLoading}
        isDisabled={store.isActionDisabled('start')}
      >
        Принять
      </Button>
    )
  }
  if (store.hasAction('decline')) {
    buttons.push(
      <Button
        onTap={() => store.action('decline')}
        isDisabled={store.isActionDisabled('decline')}
        isLoading={store.isLoading}
        design="normal"
      >
        Отказаться
      </Button>
    )
  }

  let task = store.task
  let taskData = store.task.data.task!
  // let terms = store.termsDocument
  return (
    <Section>
      <H2>Постановка задания</H2>
      <SubtaskList mode="view" subtasks={taskData.subtasks} />
      {taskData.description && (
        <div>
          <div className={s.caption}>
            Описание {task.data.kind === 'task' ? 'задания' : 'акта'}
          </div>
          <FormattedText value={taskData.description} />
        </div>
      )}
      {/* {(taskData.attachments.items.length > 0 || terms?.url) && ( */}
      {(taskData.attachments.items.length > 0) && (
        <div className={s.thumbsStack}>
          {/* {terms && (
            <FileThumb
              data={{ title: 'Техническое задание.pdf', url: terms.url }}
              status={terms.status}
            />
          )} */}
          {taskData.attachments.items.map((file) => (
            <FileThumb
              key={file.id}
              data={{ title: file.data.title, url: file.data.file_url }}
            />
          ))}
        </div>
      )}
      <TaskEventList task={store.task} section="start" />
      {buttons.length > 0 && (
        <Flex direction={isMobile ? 'column' : 'row'} gap={isMobile ? '1rem' : '2rem'}>
          {buttons}
        </Flex>
      )}
    </Section>
  )
})

interface TaskReportFormProps {
  store: TaskActionsStore
  subtasks: Array<Subtask>
  onSubmit: (data: TaskReportData) => void
  isLoading?: boolean
}

const TaskReportForm = observer(
  ({ subtasks, onSubmit, isLoading }: TaskReportFormProps) => {
    let { isMobile } = useMediaContext()
    let currentUser = useUserStore()
    let api = useMobxApi()
    let attachments = useMemo(() => new AttachmentsStore(api), [])
    let isDragOver = useDropZone(
      (files: File[] | null) => files && attachments.upload(files),
      window.$root
    )
    let [comment, setComment] = useState('')
    let [reportSubtasks, setReportSubtasks] = useState(() =>
      subtasks.map((t) => ({ ...t, quantity: 0 }))
    )
    let submit = () => {
      let totalAmount = sum(
        reportSubtasks.map((s) => s.quantity * parseFloat(s.unit_cost))
      )
      //if (totalAmount === 0) {
      //  alert('Заполните объём и стоимость выполненных работ перед завершением')
      //  return
      //}
      // if (reportSubtasks.some((s) => s.quantity === 0 || parseFloat(s.unit_cost) === 0)) {
      // alert('Заполните объём и стоимость выполненных работ перед завершением')
      // return
      // }
      onSubmit({
        comment,
        subtasks: reportSubtasks.map((s) => pick(s, ['id', 'quantity', 'unit_cost'])),
        attachment_ids: attachments.ids,
      })
    }

    let contractor = currentUser.user!.data.contractor!
    return (
      <Section>
        <H2>Отчет о выполнении</H2>
        <SubtaskList
          mode="report"
          canChangeCost={false}
          origSubtasks={subtasks}
          subtasks={subtasks}
          onChangeSubtasks={setReportSubtasks}
          noTasksText="Заполните объёмы выполненных работ"
        />
        <Input
          isMultiline
          isWide={true}
          size="l"
          placeholder={
            isMobile
              ? 'Приложите ссылку / отчет'
              : 'Укажите ссылку на результат выполнения работы или приложите отчет'
          }
          value={comment}
          onChange={setComment}
        />
        <AttachmentsZone title="файлы для отчёта" store={attachments} />
        <DropShade isDragOver={isDragOver} />
        {contractor.data.payment_details && (
          <CheckPaymentDetails contractor={contractor} />
        )}
        <Button onTap={submit} isLoading={isLoading || attachments.isLoading}>
          Завершить и подписать инвойс
        </Button>
      </Section>
    )
  }
)

const TaskReportSection = observer(({ store }: { store: TaskActionsStore }) => {
  let task = store.task

  let reportSubtaskList
  if (task.data.task!.subtasks) {
    reportSubtaskList = (
      <SubtaskList
        mode="view"
        subtasks={task.data.task!.subtasks}
        origSubtasks={task.data.task && task.data.task.subtasks}
      />
    )
  }

  return (
    <Section>
      <H2>Отчет о выполнении</H2>
      {task.data.report!.comment && (
        <div>
          <div className={s.caption}>Комментарий исполнителя</div>
          <FormattedText value={task.data.report!.comment} />
        </div>
      )}
      {task.data.report!.attachments.length > 0 && (
        <div className={s.thumbsStack}>
          {task.data.report!.attachments.map((file) => (
            <FileThumb key={file.id} data={{ title: file.title, url: file.file_url }} />
          ))}
        </div>
      )}
      {reportSubtaskList}
      <TaskEventList task={store.task} section="report" />
    </Section>
  )
})

interface ActSectionProps {
  store: TaskActionsStore
  initialShowRejectForm: boolean
}

const ActSection = observer(({ store, initialShowRejectForm }: ActSectionProps) => {
  let currentUser = useUserStore()
  let { isMobile } = useMediaContext()
  let task = store.task
  let [showRejectForm, setShowRejectForm] = useState(initialShowRejectForm)
  let [rejectComment, setRejectComment] = useState('')

  if (task.data.act.status === 'creating') {
    return (
      <Section>
        <H2>Инвойс на оплату</H2>
        <div style={{ color: 'var(--color-secondary)' }}>Подождите, акт формируется</div>
      </Section>
    )
  }

  let fileThumbs
  if (store.actDocument || task.data.act.attachments.items.length > 0) {
    fileThumbs = (
      <div className={s.thumbsStack}>
        {store.actDocument && (
          <FileThumb
            data={{ title: 'Инвойс на оплату', url: store.actDocument.url }}
            status={store.actDocument.status}
            error={['annulled', 'rejected', 'rejected_by_contractor'].includes(
              task.data.act.status
            )}
          />
        )}
        {task.data.act.attachments.items.map((file) => (
          <FileThumb
            key={file.id}
            data={{ title: file.data.title, url: file.data.file_url }}
          />
        ))}
      </div>
    )
  }

  // let contractSection
  // if (task.data.contract) {
    // let { title, status, file_url } = task.data.contract.data
    // contractSection = (
    //   <Col gap="var(--gap-s)" style={{ maxWidth: 400 }}>
    //     <H3>Договор</H3>
    //     <FileThumb data={{ title, url: file_url }} status={contractStatusMap[status]} />
    //   </Col>
    // )
  // }

  let {
    amount,
    payment_amount,
    tax_savings_amount,
    payment_amount_after_savings,
    exchange_rate,
  } = task.data.act
  let amounts = (
    <Col gap="var(--gap-s)" style={{ maxWidth: 400 }}>
      <H3>Сумма инвойса</H3>
      <Flex justify="space-between">
        <div>Сумма инвойса</div>
        <Amount style={{ fontWeight: 500 }} value={amount} />
      </Flex>
      {exchange_rate && (
        <Flex justify="space-between">
          <div>Курс конвертации валюты</div>
          <div style={{ fontWeight: 500 }}>{exchange_rate}</div>
        </Flex>
      )}
      {payment_amount !== amount && (
        <Flex justify="space-between">
          <div>Сумма выплаты исполнителю</div>
          <Amount style={{ fontWeight: 500 }} value={payment_amount} />
        </Flex>
      )}
      {tax_savings_amount && (
        <Flex justify="space-between">
          <div>Отложим в копилку на налоги</div>
          <Amount style={{ fontWeight: 500 }} value={tax_savings_amount} />
        </Flex>
      )}
      {payment_amount_after_savings && (
        <Flex justify="space-between">
          <div>Отправим по реквизитам</div>
          <Amount style={{ fontWeight: 500 }} value={payment_amount_after_savings} />
        </Flex>
      )}
    </Col>
  )

  let contractor = task.data.contractor!
  let paymentDetails = task.data.act.payment_details && (
    <PaymentDetailsView
      contractor={contractor}
      canChange={false}
      details={task.data.act.payment_details}
    />
  )

  let checkPaymentDetails = store.hasAction('make_work_acceptance') &&
    contractor.data.payment_details && <CheckPaymentDetails contractor={contractor} />

  let rejectForm
  if (showRejectForm) {
    rejectForm = (
      <Col gap="var(--gap-m)">
        <div>
          {currentUser.kind === 'company'
            ? 'Мы уведомим исполнителя о причине отказа и предложим подготовить новый акт'
            : 'Мы уведомим заказчика о причине отказа'}
        </div>
        <Input
          isWide={true}
          size="l"
          isMultiline={true}
          rows={isMobile ? 2 : 1}
          value={rejectComment}
          onChange={setRejectComment}
          placeholder="Причина отказа"
          autoFocus={true}
        />
        <Flex direction={isMobile ? 'column' : 'row'} gap={isMobile ? '1rem' : '2rem'}>
          <Button
            isLoading={store.isLoading}
            onTap={() => {
              if (rejectComment.length === 0) return alert('Опишите причину отказа')
              store
                .action('reject', { comment: rejectComment })
                .then(() => setShowRejectForm(false))
            }}
          >
            Отправить отказ
          </Button>
          <Button onTap={() => setShowRejectForm(false)} design="normal">
            Отменить
          </Button>
        </Flex>
      </Col>
    )
  }

  let reject
  if (task.data.reject) {
    let { rejected_by, comment } = task.data.reject
    let title = `${
      rejected_by === 'contractor' ? 'Исполнитель' : 'Заказчик'
    } отказал в принятии акта`
    reject = (
      <Card title={title} kind="error">
        <div>
          <div style={{ color: 'var(--color-secondary)' }}>
            Комментарий {rejected_by === 'contractor' ? 'исполнителя' : 'заказчика'}
          </div>
          <FormattedText value={comment} />
        </div>
      </Card>
    )
  }

  let buttons = []
  if (store.hasAction('make_work_acceptance')) {
    let { isDisabled, problems } = store.getActionState('make_work_acceptance')
    buttons.push(
      <TooltipButton
        onTap={() => store.openContractorAcceptDialog()}
        isDisabled={isDisabled}
        tooltip={problems && <TaskProblemList problems={problems} />}
      >
        Принять задание
      </TooltipButton>
    )
  }
  if (store.hasAction('accept')) {
    let { isDisabled, problems } = store.getActionState('accept')
    buttons.push(
      <AbilityButton
        isDisabled={isDisabled}
        ability={currentUser.hasAbility('tasks.accept')}
        onTap={() => store.openMultiAcceptDialog()}
        tooltip={problems && <TaskProblemList problems={problems} />}
      >
        Принять задание
      </AbilityButton>
    )
  }
  if (store.hasAction('reject')) {
    let { isDisabled, problems } = store.getActionState('reject')
    buttons.push(
      <AbilityButton
        isDisabled={isDisabled}
        ability={
          currentUser.kind === 'contractor'
            ? true
            : currentUser.hasAbility('tasks.reject')
        }
        onTap={() => setShowRejectForm(true)}
        design="normal"
        tooltip={problems && <TaskProblemList problems={problems} />}
      >
        Отклонить
      </AbilityButton>
    )
  }

  let actions
  if (!showRejectForm && buttons.length > 0) {
    actions = (
      <Flex direction={isMobile ? 'column' : 'row'} gap={isMobile ? '1rem' : '2rem'}>
        {buttons}
      </Flex>
    )
  }

  return (
    <Section>
      <H2>Инвойс на оплату</H2>
      {reject}
      {task.data.act.description && (
        <div>
          <div className={s.caption}>Описание</div>
          <FormattedText value={task.data.act.description} />
        </div>
      )}
      {fileThumbs}
      <TaskEventList task={store.task} section="act" />
      {amounts}
      {/* {contractSection} */}
      {paymentDetails}
      {rejectForm}
      {checkPaymentDetails}
      {actions}
    </Section>
  )
})

const TaskReceiptForm = observer(({ store }: { store: TaskActionsStore }) => {
  let api = useMobxApi()
  let attachments = useMemo(() => new AttachmentsStore(api), [])
  let isDragOver = useDropZone(
    (files: File[] | null) => files && attachments.upload(files),
    window.$root
  )

  let sendReceipt = () =>
    store.action('complete_with_receipt', {
      receipt: { attachment_ids: attachments.ids },
    })

  return (
    <>
      <AttachmentsZone title="чек для налоговой" store={attachments} />
      <Button
        onTap={sendReceipt}
        isLoading={store.isLoading || attachments.isLoading}
        isDisabled={attachments.isEmpty}
      >
        Подтвердить оплату и приложить чек
      </Button>
      <DropShade isDragOver={isDragOver} />
    </>
  )
})

const fnsReceiptStatuses = {
  created: 'сформирован',
  annulled: 'аннулирован',
}

const TaskFnsReceiptAnnul = observer(
  ({ store, receipt }: { store: TaskActionsStore; receipt: TaskFnsReceipt }) => {
    let [isOpen, setIsOpen] = useState(false)
    let fetchReasonsState = useMemo(
      () =>
        fromPromise(
          fetchApi({
            method: 'GET',
            url: 'tasks/cancel_receipt_reasons',
          })
        ),
      []
    )
    let [reason, setReason] = useState<string | undefined>()
    if (isOpen) {
      return (
        <ActionStateView
          state={fetchReasonsState}
          styles={{ loader: { height: 'auto' } }}
        >
          {(reasons: any) => (
            <Flex direction="column">
              <div className={s.caption}>Выберите причину аннулирования</div>
              <RadioInput
                options={reasons.map((item: any) => ({
                  value: item.code,
                  label: item.description,
                }))}
                value={reason}
                onChange={setReason}
                direction="column"
                gap=".5rem"
              />
              <Button
                style={{ marginTop: '2rem' }}
                design="normal"
                isLoading={store.isLoading}
                onTap={() =>
                  store.action('annul_fns_receipt', { reason, receipt_id: receipt.id })
                }
                isDisabled={reason === undefined}
              >
                Аннулировать
              </Button>
            </Flex>
          )}
        </ActionStateView>
      )
    } else {
      return (
        <Button
          design="normal"
          onTap={() => setIsOpen(true)}
          isDisabled={store.isActionDisabled('annul_fns_receipt')}
        >
          Аннулировать
        </Button>
      )
    }
  }
)

const TaskFnsReceiptModal = observer(
  ({ store, receipt }: { store: TaskActionsStore; receipt: TaskFnsReceipt }) => {
    let toast = useStateToast()
    let { url, preview_url, offline } = receipt
    let copyUrl = () => {
      navigator.clipboard.writeText(url)
      toast.success({ title: 'Ссылка на чек скопирована в буфер обмена' })
    }

    const receiptOriginalLink = receipt?.attachments
      ? receipt.attachments[0].file_url
      : false

    return (
      <Flex direction="column" gap="2rem">
        <div style={{ fontSize: '1.5rem', fontWeight: 500 }}>Чек</div>
        <img
          src={offline ? preview_url : `${preview_url}?${new Date().getTime()}`}
          alt="Чек об оплате"
          style={{ width: '100%', maxWidth: 330, alignSelf: 'center' }}
        />
        <Button
          as={'a'}
          href={preview_url}
          download
          target="_blank"
          rel="noopener noreferrer"
        >
          Скачать
        </Button>
        {receipt.status === 'annulled' && receiptOriginalLink && (
          <a
            style={{
              cursor: 'pointer',
              display: 'block',
              alignSelf: 'center',
              color: 'var(--color-active)',
            }}
            target="_blank"
            rel="noopener noreferrer"
            href={receiptOriginalLink}
          >
            Скачать сохраненную версию
          </a>
        )}
        <LinkUiKit className={s.copyLink} onClick={copyUrl}>
          Скопировать ссылку в буфер
        </LinkUiKit>
        {store.hasAction('annul_fns_receipt') && (
          <TaskFnsReceiptAnnul store={store} receipt={receipt} />
        )}
      </Flex>
    )
  }
)

const makeReceiptTitle = (receipt: TaskFnsReceipt) => {
  let { number, customer, amount } = receipt
  return compact([
    'Чек',
    number && `№${thinsp}${number}`,
    customer && `для ${customer}`,
    amount && `на ${formatAmount(amount)}`,
  ]).join(' ')
}

const TaskFnsReceipts = observer(({ store }: { store: TaskActionsStore }) => {
  let { isMobile } = useMediaContext()
  let [selectedReceipt, setSelectedReceipt] = useState<TaskFnsReceipt | undefined>(
    undefined
  )
  return (
    <>
      <div className={s.thumbsStack}>
        {reverse(clone(store.task.data.fns_receipts)).map((receipt) => (
          <FileThumb
            data={{ title: makeReceiptTitle(receipt) }}
            status={fnsReceiptStatuses[receipt.status] || receipt.status}
            style={{ cursor: 'pointer' }}
            onClick={() => setSelectedReceipt(receipt)}
            error={receipt.status === 'annulled'}
          />
        ))}
      </div>
      <Modal
        isOpen={selectedReceipt !== undefined}
        onClose={() => setSelectedReceipt(undefined)}
        size={isMobile ? '97%' : 500}
      >
        <TaskFnsReceiptModal store={store} receipt={selectedReceipt!} />
      </Modal>
    </>
  )
})

const TaskPaymentSection = observer(({ store }: { store: TaskActionsStore }) => {
  let { isMobile } = useMediaContext()
  let currentUser = useUserStore()

  let buttons = []
  if (store.hasAction('autopay') && store.task.data.act.status !== 'accepted') {
    let { isDisabled, problems } = store.getActionState('autopay')
    buttons.push(
      <AbilityButton
        ability={currentUser.hasAbility('tasks.autopay')}
        onTap={() => store.openAutoPayDialog()}
        isDisabled={isDisabled}
        isLoading={store.isLoading}
        tooltip={problems && <TaskProblemList problems={problems} />}
      >
        Автооплатить
      </AbilityButton>
    )
  }
  if (currentUser.kind === 'company') {
    let payAction = currentUser.companyPayAction
    if (store.hasAction(payAction)) {
      let { isDisabled, problems } = store.getActionState(payAction)
      let payActionNames = {
        pay: 'Оплатить',
        mark_paid: 'Отметить оплаченным',
        mark_paid_with_receipt: 'Сформировать чек и отметить оплаченным',
      }
      buttons.push(
        <AbilityButton
          ability={currentUser.hasAbility(`tasks.${payAction}`)}
          onTap={() => store.openPayDialog(payAction)}
          isDisabled={isDisabled}
          isLoading={store.isLoading}
          tooltip={problems && <TaskProblemList problems={problems} />}
        >
          {payActionNames[currentUser.companyPayAction]}
        </AbilityButton>
      )
    }
  }
  if (store.hasAction('complete')) {
    buttons.push(
      <Button
        onTap={() => store.action('complete')}
        isLoading={store.isLoading}
        isDisabled={store.isActionDisabled('complete')}
      >
        Подтвердить оплату
      </Button>
    )
  }
  if (store.hasAction('complete_with_receipt')) {
    buttons.push(<TaskReceiptForm store={store} />)
  }
  if (store.hasAction('make_fns_receipt')) {
    buttons.push(
      <Button
        onTap={() => store.action('make_fns_receipt')}
        isLoading={store.isLoading}
        isDisabled={store.isActionDisabled('make_fns_receipt')}
      >
        Задекларировать доход
      </Button>
    )
  }

  return (
    <>
      <Section>
        <H2 id="receipts">Оплата</H2>
        {store.task.data.fns_receipts.length > 0 && <TaskFnsReceipts store={store} />}
        {store.task.data.tinkoff_receipt_link && (
          <div className={s.thumbsStack}>
            <FileThumb
              key={store.task.data.tinkoff_receipt_link}
              data={{
                title: 'Чек, сформированный в Тиньков банке',
                url: store.task.data.tinkoff_receipt_link,
              }}
            />
          </div>
        )}
        {store.task.data.receipt && (
          <div className={s.thumbsStack}>
            {store.task.data.receipt.attachments.map((file: any) => (
              <FileThumb
                key={file.id}
                data={{ title: 'Чек, приложенный исполнителем', url: file.file_url }}
              />
            ))}
          </div>
        )}
        <TaskEventList task={store.task} section="payment" />
      </Section>
      {buttons.length > 0 && (
        <Flex
          direction={isMobile ? 'column' : 'row'}
          gap={isMobile ? '1rem' : '2rem'}
          className={s.section}
        >
          {buttons}
        </Flex>
      )}
    </>
  )
})

const annulStatusMap = {
  accepted: 'Подписано',
  accepted_by_company: 'Подписано заказчиком',
}

const AnnulSection = observer(({ store }: { store: TaskActionsStore }) => {
  let { comment, agreement_url, status } = store.task.data.annulment!

  let buttons = []
  if (store.hasAction('accept_annul')) {
    buttons.push(
      <Button onTap={() => store.openAcceptAnnulDialog()} isLoading={store.isLoading}>
        Подписать соглашение
      </Button>
    )
  }

  return (
    <Section>
      <H2>Соглашение об аннулировании</H2>
      <FileThumb
        data={{ title: 'Соглашение об аннулировании', url: agreement_url }}
        status={annulStatusMap[status]}
      />
      {comment && (
        <div>
          <div className={s.caption}>Комментарий заказчика</div>
          <FormattedText value={comment} />
        </div>
      )}
      <TaskEventList task={store.task} section="annul" />
      <Flex gap="2rem">{buttons}</Flex>
    </Section>
  )
})

const contractStatusMap: { [key in DocumentStatus]: string } = {
  created: 'Не подписан',
  signed_by_contractor: 'Подписан исполнителем',
  signed_by_legal_entity: 'Подписан компанией',
  signed_by_all: 'Подписан',
}

const ProblemsSection = observer(({ store }: { store: TaskActionsStore }) => {
  let currentUser = useUserStore()

  let problems = []

  if (store.problems.includes('is_suspended')) {
    let title =
      currentUser.kind === 'contractor'
        ? 'Компания приостановила работу с вами'
        : 'Вы приостановили работу с этим исполнителем'
    problems.push(
      <Card kind="error" title={title}>
        {currentUser.kind === 'company' && (
          <div>Это не блокирует работу с исполнителем, просто напоминаем ;)</div>
        )}
      </Card>
    )
  }

  if (store.problems.includes('not_connected_moi_nalog')) {
    problems.push(
      currentUser.kind === 'contractor' ? (
        <ConnectMoiNalogCard />
      ) : (
        <Card kind="error" title="Исполнитель не подключен к «Мой налог»">
          <div>
            Исполнитель отключил нашу платформу от ФНС, поэтому мы не сможем
            задекларировать доход и отправить оплату.
          </div>
        </Card>
      )
    )
  }

  if (store.problems.includes('not_registered_moi_nalog')) {
    problems.push(
      currentUser.kind === 'contractor' ? (
        <ConnectMoiNalogCard />
      ) : (
        <Card kind="error" title="Исполнитель не является самозанятым">
          <div>
            Исполнитель перестал быть самозанятым, поэтому мы не сможем задекларировать
            доход и отправить оплату.
          </div>
        </Card>
      )
    )
  }

  if (store.problems.includes('no_payment_details')) {
    problems.push(
      currentUser.kind === 'contractor' ? (
        <NoPaymentDetailsCard />
      ) : (
        <Card kind="error" title="Не указаны реквизиты">
          <div>Исполнитель не указал свои платёжные реквизиты.</div>
        </Card>
      )
    )
  }

  if (
    store.problems.includes('not_signed_contract_by_company') ||
    store.problems.includes('not_signed_contract_by_contractor')
  ) {
    let contractStore = store.contractStore!
    let contract = store.task.data.contract!
    let signedBy = contractStore.signedBy
    let { title, file_url, status } = contract.data
    let button = !signedBy.currentUser && (
      <Button size="s" onTap={() => contractStore.openSignDialog()}>
        Подписать договор
      </Button>
    )
    problems.push(
      <Card kind="error" title="Не подписан договор" actions={button}>
        {!signedBy.contractor && currentUser.kind === 'contractor' && (
          <div>Подпишите договор с компанией, чтобы получать деньги за работу.</div>
        )}
        <FileThumb data={{ title, url: file_url }} status={contractStatusMap[status]} />
      </Card>
    )
  }

  return problems.length > 0 ? <Section>{problems}</Section> : null
})

interface TaskViewProps {
  backLink: { to: string; title: string }
  task: Entity<TaskEntity>
  fetchProjectsState: any
  initialShowRejectForm: boolean
  initialOpenDialog: boolean
}

const TaskView = observer((props: TaskViewProps) => {
  let { backLink, task, fetchProjectsState, initialShowRejectForm, initialOpenDialog } =
    props
  let currentUser = useUserStore()
  let history = useHistory()
  let location: any = useLocation()
  let toast = useStateToast()
  let { isMobile } = useMediaContext()
  let signDialog = useSignDialogStore()
  let store = useLocalObservable(
    () => new TaskActionsStore({ currentUser, task, toast, signDialog })
  )

  useMemo(() => {
    if (initialOpenDialog) store.openDialog()
  }, [])

  usePusherSubscription('update-task', (event: any) => {
    if (event.id === task.id) task.fetch()
  })

  let [hash] = useHashState(history, '')
  useEffect(() => {
    if (hash === 'receipts') {
      setTimeout(() => document.querySelector('#receipts')?.scrollIntoView(), 0)
    }
  }, [])

  let [reportFormIsOpen, setReportFormIsOpen] = useState(false)

  // let backUrl: string, backText
  // if (backLink) {
  //   backUrl = backLink.to
  //   backText = backLink.title
  // } else {
  //   backUrl = task.data.kind === 'task' ? '/tasks' : '/acts'
  //   backText = task.data.kind === 'task' ? 'К заданиям' : 'К актам'
  // }

  const backUrl = useMemo(() => {
    const url = task.data.kind === 'task' ? '/tasks' : '/acts'
    const text = task.data.kind === 'task' ? 'К заданиям' : 'К актам'

    const from = location?.state?.from || false

    if (from) {
      const path = from.split('/')

      if (path[1] === 'acts' && path[2]) {
        return {
          url: from,
          text: backLink?.title || text,
        }
      }
    }

    if (backLink) {
      return {
        url: backLink.to,
        text: backLink.title,
      }
    }

    return {
      url,
      text,
    }
  }, [backLink, task.data.kind, location?.state])

  let backLinkElem = (
    <BackLink to={backUrl.url} style={{ marginBottom: '2rem' }}>
      {backUrl.text}
    </BackLink>
  )

  let statusLine = (
    <div className={s.statusLine}>
      {task.data.document_number && (
        <span className={s.taskNumber}>№&nbsp;{task.data.document_number.number}</span>
      )}
      {task.data.status && (
        <StatusBadge
          status={task.data.status}
          map={oldTaskStatusMap}
          className={s.taskStatus}
        />
      )}
      {task.data.since && (
        <span className={s.taskSince}>
          {printPeriod(task.data.since, task.data.upto, false)}
        </span>
      )}
    </div>
  )

  let deleteConfirmDialog = useConfirmDialog({
    render: ({ cancel, confirm }) => (
      <ConfirmDialog
        header={`Удаление ${task.data.kind === 'task' ? 'задания' : 'акт'}`}
        buttons={
          <>
            <Button onTap={cancel} design="normal">
              Отмена
            </Button>
            <Button onTap={confirm}>Удалить</Button>
          </>
        }
        onCancel={cancel}
      >
        Вы уверены что хотите удалить {task.data.kind === 'task' ? 'задание' : 'акт'}?
      </ConfirmDialog>
    ),
    onConfirm: () => store.delete().then(() => history.push(backUrl.url)),
  })

  let actions = []
  if (store.hasAction('annul')) {
    actions.push(
      <AbilityMenuItem
        ability={currentUser.hasAbility('tasks.annul')}
        onClick={() => {
          store.openAnnulDialog()
        }}
      >
        Аннулировать
      </AbilityMenuItem>
    )
  }
  if (store.hasAction('delete')) {
    actions.push(
      <AbilityMenuItem
        ability={currentUser.hasAbility('tasks.destroy')}
        onClick={deleteConfirmDialog.open}
        style={{ color: 'var(--color-red)' }}
      >
        Удалить
      </AbilityMenuItem>
    )
  }

  let menu = actions.length > 0 && (
    <div style={{ position: 'relative' }}>
      <Menu placement="bottom-end">
        <MenuButton as={Button} style={{ minWidth: '3rem', padding: 0 }} design="normal">
          <MoreIcon style={{ fill: 'var(--color-active)' }} />
        </MenuButton>
        <MenuList style={{ minWidth: 275 }}>{actions}</MenuList>
      </Menu>
    </div>
  )

  let header = (
    <Section>
      <Flex gap="1rem" justify="space-between">
        <H1>{task.data.title}</H1>
        {menu}
        {deleteConfirmDialog.render()}
      </Flex>
      {fetchProjectsState && task.data.kind === 'task' && (
        <TaskProjectSelector
          task={task}
          fetchProjectsState={fetchProjectsState}
          canUpdate={currentUser.hasAbility('projects.update')}
        />
      )}
      <Flex direction={isMobile ? 'column' : 'row'} gap={'2rem'}>
        <WhoBlock
          title="Заказчик"
          who={{ name: task.data.company.data.name, avatar_url: defaultCompanyAvatar }}
          to={
            currentUser.kind === 'company'
              ? `/company`
              : `/companies/${task.data.company.id}`
          }
        />
        {task.data.contractor && (
          <WhoBlock
            title="Исполнитель"
            who={task.data.contractor.data}
            to={`/contractors/${task.data.contractor.id}`}
          />
        )}
      </Flex>
    </Section>
  )

  let problemsSection = <ProblemsSection store={store} />

  let taskSection
  if (task.data.task) taskSection = <TaskInfoSection store={store} />

  let reportSection = task.data.report && <TaskReportSection store={store} />

  let reportForm
  if (store.hasAction('done')) {
    reportForm = reportFormIsOpen ? (
      <TaskReportForm
        store={store}
        subtasks={toJS(task.data.task!.subtasks)}
        isLoading={store.isLoading}
        onSubmit={(data) => store.submitReport(data)}
      />
    ) : (
      <div className={s.section}>
        <Button
          onTap={() => setReportFormIsOpen(true)}
          isDisabled={store.isActionDisabled('done')}
        >
          Подготовить отчёт
        </Button>
      </div>
    )
  }

  let actSection
  if (task.data.act.status !== 'not_created') {
    actSection = (
      <ActSection
        store={store}
        initialShowRejectForm={store.hasAction('reject') && initialShowRejectForm}
      />
    )
  }

  let annulSection = task.data.annulment && <AnnulSection store={store} />

  let showPaymentSection =
    task.data.fns_receipts.length > 0 ||
    task.data.actions?.some((action) => sectionsEvents.payment.includes(action)) ||
    task.data.events?.some((event) => sectionsEvents.payment.includes(event.kind))
  let paymentSection = showPaymentSection && <TaskPaymentSection store={store} />

  let menuActions = ['delete', 'annul']
  let otherTasksButton
  if (without(task.data.actions, ...menuActions).length === 0) {
    otherTasksButton = (
      <div className={s.section} style={{ marginTop: '2rem' }}>
        <Button design="normal" onTap={() => history.push(backLink.to)}>
          {backLink.title}
        </Button>
      </div>
    )
  }

  return (
    <Layout smallPaddingTop style={{ maxWidth: 800 }}>
      {backLinkElem}
      {statusLine}
      {header}
      {problemsSection}
      {taskSection}
      {reportSection}
      {reportForm}
      {actSection}
      {paymentSection}
      {annulSection}
      {otherTasksButton}
      {store.render()}
      {store.multiActions.render()}
    </Layout>
  )
})

const DraftView = observer(
  ({
    task,
    backLink,
  }: {
    task: Entity<TaskEntity>
    backLink: { to: string; title: string }
  }) => {
    let mobxApi = useMobxApi()
    let currentUser = useUserStore()
    let contractorsFetchState = useMemo(() => mobxApi.fetch({ type: 'contractors' }), [])
    let templatesFetchState = useMemo(
      () => mobxApi.fetch({ type: 'subtasks/templates' }),
      []
    )
    let projectsFetchState = useMemo(
      () => currentUser.hasFeature('projects') && mobxApi.fetch({ type: 'projects' }),
      []
    )
    return (
      <ActionStateView
        state={[templatesFetchState, contractorsFetchState, projectsFetchState]}
      >
        {([templates, contractors, projects]) => (
          <TaskCreateForm
            options={{ kind: 'task', contractors, projects, templates, draft: task }}
            backLink={backLink}
          />
        )}
      </ActionStateView>
    )
  }
)

interface TaskPageProps extends PageProps {
  backLink?: { to: string; title: string }
  location: any
}

const TaskPage = observer((props: TaskPageProps) => {
  let mobxApi = useMobxApi()
  let currentUser = useUserStore()

  let { match, location } = props
  let id = Number(match.params.id)

  let query = qs.parse(location.search)
  let initialShowRejectForm = query.open_reject_form === '1'
  let initialOpenDialog = query.open_sign_dialog === '1'

  // let backLink: any
  // if (props.backLink) {
  //   backLink = props.backLink
  // } else if (location.pathname.match('/acts')) {
  //   backLink = { to: '/acts', title: 'К актам' }
  // } else {
  //   backLink = { to: '/tasks', title: 'К заданиям' }
  // }

  const backLink = React.useMemo(() => {
    const isActs = location.pathname.match('/acts')

    const to = isActs ? '/acts' : '/tasks'
    const title = isActs ? 'К актам' : 'К заданиям'

    const from = location?.state?.from || false

    if (from) {
      const path = from.split('/')

      if (['acts', 'tasks'].includes(path[1]) && path[2]) {
        return {
          to: from,
          title: title,
        }
      }
    }

    if (props.backLink) return props.backLink

    return {
      to,
      title,
    }
  }, [location.pathname, location.state])

  let fetchTaskState = useMemo(() => mobxApi.fetch({ type: 'tasks', id }), [])
  let fetchProjectsState = useMemo(
    () => currentUser.hasFeature('projects') && mobxApi.fetch({ type: 'projects' }),
    []
  )
  let isDraft =
    fetchTaskState.state === 'fulfilled' && fetchTaskState.value.data.status === 'draft'
  return (
    <ActionStateView state={fetchTaskState} animate={!isDraft}>
      {(task: Entity<TaskEntity>) =>
        task.data.status === 'draft' ? (
          <DraftView backLink={backLink} task={task} />
        ) : (
          <TaskView
            backLink={backLink}
            task={task}
            fetchProjectsState={fetchProjectsState}
            initialShowRejectForm={initialShowRejectForm}
            initialOpenDialog={initialOpenDialog}
          />
        )
      }
    </ActionStateView>
  )
})

export default TaskPage
