import React, { ReactNode } from 'react'

import Card from './components/Card'
import { LinkBack, Notice } from 'components/ui/LayoutSimple'

import { Logo } from 'ui'

import s from './style.module.css'

const LayoutSimple = ({
  onBack,
  notice,
  children,
}: {
  onBack?: () => void
  notice?: React.ReactNode
  children: ReactNode
}) => {
  return (
    <div className={s.root}>
      <Logo className={s.logo} />
      {onBack && <LinkBack onClick={onBack}>Вернуться назад</LinkBack>}
      {notice && <Notice>{notice}</Notice>}
      <Card>{children}</Card>
    </div>
  )
}

export default LayoutSimple

export { default as Title } from './components/Title'
export { default as Label } from './components/Label'
export { default as Input } from './components/Input'
export { default as FormGroup } from './components/FormGroup'
export { default as LinkBack } from './components/LinkBack'
export { default as Notice } from './components/Notice'
