import { useLayoutEffect, useMemo, useRef, useState } from 'react'

import mergeRefs from './utils/mergeRefs'

const compareNodesPosition = (a: Node, b: Node) => {
  const res = a.compareDocumentPosition(b)

  if (
    res & Node.DOCUMENT_POSITION_FOLLOWING ||
    res & Node.DOCUMENT_POSITION_CONTAINED_BY
  ) {
    // a < b
    return -1
  }

  if (res & Node.DOCUMENT_POSITION_PRECEDING || res & Node.DOCUMENT_POSITION_CONTAINS) {
    // a > b
    return 1
  }

  return 0
}

export interface Descendant<T> {
  element: HTMLElement
  props: T
}

class DescendantManager<T> {
  items: Descendant<T>[] = []

  sortItems() {
    this.items.sort((a: Descendant<T>, b: Descendant<T>) =>
      compareNodesPosition(a.element, b.element)
    )
  }

  register({ element, ...props }: Descendant<T>) {
    if (!element) return

    // already registered
    let item = this.items.find((item) => item.element === element)
    if (item) {
      Object.assign(item, props)
    } else {
      const newItem = { element, ...props }
      this.items.push(newItem)
    }

    this.sortItems()
  }

  unregister(element: HTMLElement) {
    const index = this.items.findIndex((item) => item.element === element)
    if (index !== -1) this.items.splice(index, 1)
  }
}

const useDescendants = <T>() => useMemo(() => new DescendantManager<T>(), [])

const useDescendant = <T>(descendants: DescendantManager<T>, props: T) => {
  const [index, setIndex] = useState(-1)
  const ref = useRef<HTMLElement>()

  useLayoutEffect(() => {
    return () => {
      if (ref.current) descendants.unregister(ref.current)
    }
  }, [])

  useLayoutEffect(() => {
    if (!ref.current) return
    const theIndex = descendants.items.findIndex((item) => ref.current === item.element)
    if (index !== theIndex) setIndex(theIndex)
  })

  const callback = (element: any) => descendants.register({ element, props })
  return { ref: mergeRefs(ref, callback), index }
}

export { useDescendants, useDescendant, DescendantManager }
