import { makeAutoObservable } from 'mobx'

import { Entity, ActionState } from 'mobx/mobx'
import { PaginationEntity } from 'entities'

export type PageFetcher<T> = (options: {
  page: number
  abortSignal: any
}) => ActionState<Entity<PaginationEntity<T>>>

class PaginatedStore<T> {
  constructor(fetcher: PageFetcher<T>, page: number = 1) {
    this.fetcher = fetcher
    this.page = page
    makeAutoObservable(this)
  }

  page: number = 1
  fetcher: PageFetcher<T>
  fetchState: ReturnType<PageFetcher<T>> = {} as ReturnType<PageFetcher<T>>
  fetchedValue?: Entity<PaginationEntity<T>>
  abortController?: AbortController

  fetch() {
    this.abortController?.abort()
    if (window.AbortController) this.abortController = new AbortController()
    this.fetchState = this.fetcher({
      page: this.page,
      abortSignal: this.abortController?.signal,
    })
    this.fetchState.then((value) => {
      this.fetchedValue = value
    })
  }

  setPage(page: number = 1) {
    this.page = page
    this.fetch()
  }
}

export default PaginatedStore
