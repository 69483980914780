import { useStateToast } from 'components/ui'
import { makeAutoObservable } from 'mobx'
import { Entity, makeInitialActionState } from 'mobx/MobxApi'
import { ActionState } from 'mobx/types'
import DialogStore from 'stores/DialogStore'
import { ConfirmActionDialog } from './ConfirmActionDialog'

type ScenarioTask = any
type ScenarioTaskAction = any
type ScenarioTaskEntity = any

type TaskActionsStoreProps = {
  // currentUser: UserStore
  task: Entity<ScenarioTaskEntity>
  toast: ReturnType<typeof useStateToast>
}

const successMessages: { [K in ScenarioTaskAction]: string } = {
  accept: 'Вы откликнулись на задание',
  decline: 'Вы отказались от задания',
}

export class ScenarioTaskActionsStore {
  task
  toast
  actionState = makeInitialActionState<ScenarioTask>()
  actionDialog = new DialogStore({ component: ConfirmActionDialog })
  lastAction?: ScenarioTaskAction

  constructor({ task, toast }: TaskActionsStoreProps) {
    makeAutoObservable(this)
    this.task = task
    this.toast = toast
  }

  runTaskAction(action: ScenarioTaskAction, payload?: Object) {
    this.lastAction = action
    switch (action) {
      case 'decline':
        return this.handleDeclineAction(action, payload)
      default:
        return this.handleGenericAction(action, payload)
    }
  }

  handleDeclineAction(action: ScenarioTaskAction, payload?: Object) {
    this.actionDialog.open({
      title: 'Отказаться от задания?',
      description: 'Вы уверены, что хотите отказаться от задания?',
      store: this,
      onConfirm: () => {
        this.handleGenericAction(action, payload).then(() => this.actionDialog.close())
      },
      confirmText: 'Да, отказаться',
    })
  }

  handleGenericAction(
    action: ScenarioTaskAction,
    _payload?: Object
  ): PromiseLike<ScenarioTask> {
    this.actionState = this.task.update({
      url: `scenario_tasks/${this.task.id}`,
      payload: { scenario_action: action },
    }) as ActionState<ScenarioTask>
    this.actionState.then(
      this.handleActionResult.bind(this),
      this.handleActionError.bind(this)
    )
    return this.actionState
  }

  handleActionResult() {
    if (this.lastAction) this.toast.success({ title: successMessages[this.lastAction] })
  }

  handleActionError(error: any) {
    this.toast.error({ title: 'Ошибка', description: error.message })
  }

  render() {
    return this.actionDialog.render()
  }
}
