import { rest } from 'msw'
import { json } from './helpers'

import { ScenarioProjectTaskDocument, ScenarioProjectTaskEntityRead } from 'entities';

const taskDocuments: ScenarioProjectTaskDocument[] = [
  {
    id: 1,
    project: { title: 'Поект 1', id: 1 },
    units: [{ title: 'РАБота' }],
    since_date: '2022-01-02',
    upto_date: '2022-05-02',
    since_time: '12:00',
    upto_time: '12:00',
    status: 'completed',
    actions: ['delete_draft'],
    act: {
      id: 42,
    },
    amount: 5000,
    contractor: { name: 'Фамилия3 Имя3 Отчество3', mobile_phone: '88005553535' },
    allowed_company_actions: ['complete', 'not_complete'],
    company: {
      id: 1,
      name: 'МакДак'
    }
  },
  {
    id: 2,
    project: { title: 'Поект 1', id: 1 },
    units: [{ title: 'РАБота' }, { title: 'РАБота' }, { title: 'РАБота' }],
    since_date: '2022-01-02',
    upto_date: '2022-05-02',
    since_time: '12:00',
    upto_time: '12:00',
    status: 'completed',
    actions: ['delete_draft'],
    act: {
      id: 42,
    },
    amount: 5000,
    contractor: { name: 'Фамилия2 Имя2 Отчество2', mobile_phone: '88005553535' },
    allowed_company_actions: ['complete', 'not_complete'],
    company: {
      id: 1,
      name: 'МакДак'
    }
  },
  {
    id: 3,
    project: { title: 'Поект 1', id: 1 },
    units: [{ title: 'РАБота' }],
    since_date: '2022-01-02',
    upto_date: '2022-05-02',
    since_time: '12:00',
    upto_time: '12:00',
    status: 'completed',
    actions: ['delete_draft'],
    act: {
      id: 42,
    },
    amount: 5000,
    contractor: { name: 'Фамилия1 Имя1 Отчество1', mobile_phone: '88005553535' },
    allowed_company_actions: ['complete', 'not_complete'],
    company: {
      id: 1,
      name: 'МакДак'
    }
  }
];

export const taskEntity: ScenarioProjectTaskEntityRead = {
  id: 1,
  title: 'Задание 1',
  project: { title: 'Поект 1', id: 1 },
  location: { address: 'г Москва, Малый Песчаный пер', lat: 12.123, lng: 12.123 },
  units: [{ title: 'РАБота', quantity: 10, cost: 100, total_cost: 1000, unit_type: 'week' }],
  since_date: '2022-01-02',
  upto_date: '2022-05-02',
  since_time: '12:00',
  upto_time: '12:00',
  status: 'completed',
  act: {
    id: 42,
  },
  actions: ['delete_draft'],
  amount: 5000,
  description: 'qweasd',
  contractor: { name: 'Фамилия Имя Отчество', mobile_phone: '88005553535' },
  allowed_company_actions: ['complete', 'not_complete'],
  company: {
    id: 1,
    name: 'МакДак'
  }
};

export default [
  rest.post('/api/company/scenario_tasks/filter', json({
    items: taskDocuments,
    page: 1,
    per_page: 10,
    total_count: 4,
    total_pages: 1
  })),
  rest.get('/api/company/scenario_tasks', json(taskDocuments)),
  rest.get('/api/company/scenario_tasks/:id', json(taskEntity)),
  rest.post('/api/company/scenario_tasks', json(taskEntity)),
  rest.put('/api/company/scenario_tasks', json({})),
];
