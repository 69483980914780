import clsx from 'clsx'
import React from 'react'

import { BaseButton, TapState } from './Button'

import classes from './Link.module.css'

const linkButtonStyles = (props: { underline?: boolean }, { isHovered }: TapState) => ({
  root: {
    color: 'var(--color-active)',
    textDecoration: !props.underline && isHovered ? 'underline' : 'none',
    borderRadius: 0,
    cursor: 'pointer',
    // paddingBottom: props.underline ? 1 : 0,
    // borderBottom: props.underline ? '1px solid var(--color-active)' : 0,
    lineHeight: 1.05,
  },
})

const LinkButton = ({ styles, ...restProps }: any) => (
  <BaseButton
    {...restProps}
    styles={[linkButtonStyles, styles]}
    className={clsx({
      [classes.underline]: restProps.underline,
    })}
  />
)

export { LinkButton }

const Link = (props: any) => <LinkButton as="a" {...props} />

export default Link
