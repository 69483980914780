import { rest } from 'msw'

import { json } from './helpers'

const notifications = [
  {
    id: 1,
    from: 'ФНС',
    title: 'Restaff предоставлены полномочия',
    content:
      'Restaff предоставлены полномочия на реализацию прав и обязанностей налогоплательщика налога на профессиональный доход.',
    date: '2020-11-26T21:00:00Z',
    read: false,
  },

  {
    id: 2,
    from: 'ФНС',
    title:
      'Restaff запрашивает разрешение на осуществление определенных действий от Вашего имени',
    content:
      'Restaff запросил у Вас разрешение на осуществление определенных действий от Вашего имени. Вы можете ознакомиться с перечнем запрошенных разрешений и дать свое согласие (уполномочить Restaff), нажав кнопку "Разрешить", или отказать ему, нажав кнопку "Отказать".',
    date: '2020-11-26T21:00:00Z',
    read: false,
  },
]

export default [
  rest.get('/api/notifications/unread_count', json({ count: 0 })),
  rest.get('/api/company/notifications/unread_count', json({ count: 0 })),
  rest.get('/api/contractor/notifications/unread_count', json({ count: 1 })),
  rest.get('/api/contractor/notifications', json(notifications)),
  rest.put('/api/contractor/notifications/:id/mark_as_read', json({})),
]
