import React from 'react'

import { ReactComponent as CheckIcon } from '@material-design-icons/svg/round/check.svg'

import { Flex } from 'components/ui'
import { MenuItem } from '../../ui'

import { NOT_FILLED_TEXT } from '../../constants'

interface FilterProps<T> {
  initialFocusRef?: any
  value?: T
  withNotFilled?: boolean
  onChange: (value: T) => void
  onClose: () => void
}

export type MultiSelectValue = string[]

type SelectOptions = Array<{ value: string; label: string }>

interface MultiSelectFilterProps extends FilterProps<MultiSelectValue> {
  options: SelectOptions
}

const MultiSelectFilter = ({
  value,

  options,
  withNotFilled,
}: MultiSelectFilterProps) => {
  const optionsWithNotFilled = withNotFilled
    ? [...options, { value: 'not_filled', label: NOT_FILLED_TEXT }]
    : options
  return (
    <>
      {optionsWithNotFilled.map((option) => {
        let isSelected = value && value.includes(option.value)
        return (
          <MenuItem key={option.value} value={option.value}>
            <Flex align="center" gap=".25rem">
              <CheckIcon style={{ visibility: isSelected ? 'visible' : 'hidden' }} />
              <div>{option.label}</div>
            </Flex>
          </MenuItem>
        )
      })}
    </>
  )
}

export default MultiSelectFilter
