import React, { useMemo } from 'react'
import { makeAutoObservable, observable } from 'mobx'
import { Observer } from 'mobx-react-lite'

interface DialogComponentProps {
  onClose: () => void
}

interface DialogStoreProps<T extends DialogComponentProps> {
  component: React.ComponentType<T>
}

class DialogStore<T extends DialogComponentProps> {
  constructor({ component }: DialogStoreProps<T>) {
    this.component = component
    makeAutoObservable(this, {
      render: false,
      props: observable.ref,
      component: observable.ref,
    })
  }

  component: React.ComponentType<T>
  isOpen = false
  props?: Omit<T, 'onClose'>

  open(props: Omit<T, 'onClose'>) {
    this.isOpen = true
    this.props = props
  }

  close() {
    this.isOpen = false
  }

  render() {
    return (
      <Observer>
        {() =>
          this.isOpen
            ? React.createElement(this.component, {
              ...this.props!,
              onClose: () => this.close(),
            } as T)
            : null
        }
      </Observer>
    )
  }
}

const useDialogStore = <T extends DialogComponentProps>(props: DialogStoreProps<T>) =>
  useMemo(() => new DialogStore(props), [])

export { useDialogStore }

export default DialogStore
