import React from 'react'

import { ReactComponent as IconArrowLeft } from 'assets/icon-arrow-left.svg'

import s from './style.module.css'

const LinkBack = ({
  onClick,
  children,
}: {
  onClick: () => void
  children: React.ReactNode
}) => {
  return (
    <div className={s.linkBackContainer}>
      <button onClick={onClick} className={s.linkBack}>
        <div className={s.icon}>
          <IconArrowLeft />
        </div>
        {children}
      </button>
    </div>
  )
}

export default LinkBack
