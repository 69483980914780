import React, { useEffect } from 'react'

import { useMediaContext } from 'App/MediaContext'
import { observer } from 'mobx-react-lite'

import { ReactComponent as SearchIcon } from '@material-design-icons/svg/round/search.svg'

import { ActionStateView, Placeholder } from 'components/ui'
import { Input } from 'ui'

import useScenarioListStore from '../../hooks/useScenarioListStore'

import { OnboardingScenarioEntity } from 'entities/onboardingScenario'

import { Entity } from 'mobx/MobxApi'

import ScenarioListInner from './ScenarioListInner'

interface IScenarioListProps {
  onEditRow: (scenario: Entity<OnboardingScenarioEntity>) => void
}

export const ScenarioList = observer(({ onEditRow }: IScenarioListProps) => {
  const { isMobile } = useMediaContext()
  const scenarioStore = useScenarioListStore()

  useEffect(() => {
    scenarioStore.loadData()
    return () => scenarioStore.clearData()
  }, [])
  ;(window as any).scenarioStore = scenarioStore

  return (
    <>
      <Input
        icon={<SearchIcon />}
        value={scenarioStore.searchText}
        onChange={(value) => scenarioStore.setSearchText(value)}
        style={{ ...(isMobile && { marginBottom: 0 }) }}
        placeholder="Можно искать по названию сценария"
      />
      {scenarioStore.fetchState && (
        <ActionStateView state={scenarioStore.fetchState}>
          {() =>
            scenarioStore.fetchState!.value?.items ? (
              <ScenarioListInner
                scenarios={scenarioStore.fetchState!.value}
                onEditRow={onEditRow}
              />
            ) : (
              <Placeholder>Загрузка</Placeholder>
            )
          }
        </ActionStateView>
      )}
    </>
  )
})

export default ScenarioList
