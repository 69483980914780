import React from 'react'
import { Link } from 'react-router-dom'
//@ts-ignore
import { useStyles } from 'floral'

import { useMediaContext, IMediaContext } from 'App/MediaContext'

import { ReactComponent as ChevronLeftIcon } from '@material-design-icons/svg/round/chevron_left.svg'

import { H1 } from '../Heading'

const layoutStyles = (props: LayoutProps, { isMobile, isUltraWide }: IMediaContext) => ({
  root: {
    '--space-layout-horizontal': isMobile ? '20px' : `${isUltraWide ? 3 : 2}rem`,
    '--space-layout-top': isMobile ? '32px' : `${props.smallPaddingTop ? 2 : 4}rem`,
    '--space-layout-bottom': isMobile ? '64px' : '5rem',
    padding:
      'var(--space-layout-top) var(--space-layout-horizontal) var(--space-layout-bottom)',
    boxSizing: 'content-box',
    maxWidth: 1250,
  },
})

interface LayoutProps extends React.HTMLProps<HTMLDivElement> {
  smallPaddingTop?: boolean
}

const Layout = (props: LayoutProps) => {
  let ctx = useMediaContext()
  let styles = useStyles(layoutStyles, [props, ctx])
  let { smallPaddingTop: _smallPaddingTop, ...restProps } = props
  return <div {...restProps} className={props.className} style={styles.root} />
}

const backLinkStyles = () => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    fontStyle: 'normal',
    fontWeight: 500,
    color: 'var(--color-secondary)',
    fill: '#98A1A9',
    marginBottom: '1.5rem',
    cursor: 'pointer',
  },
  icon: {
    height: 24,
    width: 24,
    marginLeft: -4,
  },
})

const BackLink = (props: React.ComponentProps<typeof Link>) => {
  let { isMobile } = useMediaContext()
  let styles = useStyles(backLinkStyles, [props, isMobile])
  return (
    <Link {...props} className={props.className} style={styles.root}>
      <ChevronLeftIcon style={styles.icon} />
      <div>{props.children}</div>
    </Link>
  )
}

const SimpleBackLink = (props: React.HTMLProps<HTMLAnchorElement>) => {
  let { isMobile } = useMediaContext()
  let styles = useStyles(backLinkStyles, [props, isMobile])
  return (
    <a {...props} className={props.className} style={styles.root}>
      <ChevronLeftIcon style={styles.icon} />
      <div>{props.children}</div>
    </a>
  )
}

interface LayoutHeadingProps extends React.ComponentProps<typeof H1> {
  count?: number
}

const headingStyles = () => ({
  counter: {
    color: 'var(--color-secondary)',
    marginLeft: '1rem',
  },
})

const LayoutHeading = (props: LayoutHeadingProps) => {
  let { count, children, ...restProps } = props
  let { isMobile } = useMediaContext()
  let styles = useStyles(headingStyles, [props, isMobile])
  return (
    <H1 {...restProps} style={styles.root}>
      {children}
      {count !== undefined && count > 0 && <span style={styles.counter}>{count}</span>}
    </H1>
  )
}

export { Layout, LayoutHeading, BackLink, SimpleBackLink }
