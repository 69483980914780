import React from 'react'

import { Flex } from 'components/ui'

import s from './style.module.css'

const Notice = ({ children }: { children: React.ReactNode }) => {
  return (
    <Flex className={s.notice} gap="12px">
      <div className={s.icon}>
        <img
          alt=""
          src={require('assets/icon-lock.png')}
          width="20"
          height="20"
        />
      </div>
      <div>{children}</div>
    </Flex>
  )
}

export default Notice