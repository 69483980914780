import { ActStatus, TaskStatus, PaymentStatus, OldTaskStatus } from 'entities'

export const taskStatusMap: { [key in TaskStatus]: any } = {
  draft: { text: 'Черновик', color: 'grey' },
  assigned: { text: 'Предложено исполнителю' },
  cancelled: { text: 'Отменено', color: 'grey' },
  signed_by_company: { text: 'Предложено исполнителю' },
  signed_by_contractor: { text: 'Принято исполнителем' },
  in_progress: { text: 'В работе' },
  declined: { text: 'Исполнитель отказался', color: 'red' },
  done: { text: 'Выполнено', color: 'green' },
}

export const actStatusMap: { [key in ActStatus]: any } = {
  annulled: { text: 'Аннулирован', color: 'red' },
  not_created: { text: 'Не сформирован', color: 'grey' },
  creating: { text: 'Формируется', color: 'grey' },
  not_accepted: { text: 'Не подписан', color: 'grey' },
  accepted_by_company: { text: 'Подписан компанией' },
  accepted_by_contractor: { text: 'Подписан исполнителем' },
  accepted: { text: 'Подписан', color: 'green' },
  rejected: { text: 'Отклонён', color: 'red' },
  rejected_by_contractor: { text: 'Отклонён исполнителем', color: 'red' },
}

export const paymentStatusMap: { [key in PaymentStatus]?: any } = {
  not_available: { text: 'Нельзя оплатить', color: 'grey' },
  not_paid: { text: 'Не оплачен' },
  autopay: { text: 'Одобрен' },
  in_progress: { text: 'Отправлен', color: 'green' },
  paid: { text: 'Оплачен', color: 'green' },
  error: { text: 'Ошибка', color: 'red' },
}

const enumerate = <T>(arr: Array<T>) => {
  let res: { [key: string]: number } = {}
  arr.forEach((elem, i) => {
    res[String(elem)] = i
  })
  return res
}

export const taskStatusOrder = enumerate(Object.keys(taskStatusMap))
export const actStatusOrder = enumerate(Object.keys(actStatusMap))
export const paymentStatusOrder = enumerate(Object.keys(paymentStatusMap))

export const oldTaskStatusMap: { [key in OldTaskStatus]?: any } = {
  draft: { text: 'Черновик', color: 'grey' },
  created: { text: 'Предложено исполнителю', color: 'grey' },
  cancelled: { text: 'Отменено', color: 'grey' },
  assigned: { text: 'Предложено исполнителю' },
  started_by_contractor: { text: 'Принято исполнителем' },
  declined: { text: 'Исполнитель отказался', color: 'red' },
  in_progress: { text: 'В работе' },
  act_in_progress: { text: 'Акт формируется' },
  done: { text: 'Инвойс сформирован', color: 'green' },
  done_by_contractor: { text: 'Инвойс сформирован исполнителем', color: 'green' },
  acceptance_ready: { text: 'Инвойс подписан исполнителем' },
  accepted_by_company: { text: 'Инвойс подписан заказчиком' },
  accepted_by_contractor: { text: 'Инвойс подписан исполнителем' },
  rejected: { text: 'Акт отклонён', color: 'red' },
  rejected_by_contractor: { text: 'Акт отклонён исполнителем', color: 'red' },
  accepted: { text: 'Инвойс подписан', color: 'green' },
  paid: { text: 'Акт оплачен', color: 'green' },
  paid_without_contractor_accept: {
    text: 'Акт оплачен без подписи исполнителя',
    color: 'green',
  },
  completed: { text: 'Оплата подтверждена', color: 'green' },
  completed_without_contractor_accept: {
    text: 'Чек приложен, осталась подпись исполнителя',
    color: 'green',
  },
  annulled: { text: 'Акт аннулирован', color: 'grey' },
}
