import { rest } from 'msw'

import { json } from './helpers'

// const templates = []
const templates = [
  {
    id: 1,
    unit_cost: '12000.00',
    unit: {
      id: 1,
      title: 'Час',
    },
    description: 'Кратно увеличить продажи премиум сегмента',
    title: 'Увеличение продаж кратно увеличить продажи премиум сегмента',
    currency: 'EUR',
  },
  {
    id: 2,
    unit_cost: '22.54',
    unit: {
      id: 2,
      title: 'День',
    },
    description: 'Обзвонить холодных клиентов по базе из мейлгана',
    title: 'Обзвон холодных',
    currency: 'EUR',
  },
  {
    id: 3,
    unit_cost: 11111,
    unit: {
      id: 2,
      title: 'День',
    },
    description: 'Ещё один обзвон холодных но другой',
    title: 'Обзвон холодных',
    currency: 'EUR',
  },
  {
    id: 4,
    unit_cost: 12000,
    unit: {
      id: 3,
      title: 'Год',
    },
    description: 'Обзвонить тёплых клиентов. См админку в разделе...',
    title: 'Обзвон тёплых',
    currency: 'EUR',
  },
]

const units = [
  {
    id: 1,
    title: 'Час',
  },
  {
    id: 2,
    title: 'День',
  },
  {
    id: 3,
    title: 'Год',
  },
  {
    id: 4,
    title: 'Метр квадратный',
  },
]

export default [
  rest.get('/api/company/subtasks/templates', json(templates)),
  rest.delete('/api/company/subtasks/templates/:id', json({})),
  rest.get('/api/company/subtasks/units', json(units)),

  rest.get('/api/contractor/companies/:id/subtasks/templates', json(templates)),
]
