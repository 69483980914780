import React, { forwardRef } from 'react'
import cx from 'clsx'
import { Tooltip } from '@chakra-ui/react'

import { ReactComponent as IosSpinnerIcon } from 'assets/ios-spinner.min.svg'

import { BaseButton } from '../Button'
import type { BaseButtonProps, TapState } from '../Button'
import s from './styles.module.css'

interface IconButtonProps extends BaseButtonProps {
  tooltip?: string
  isLoading?: boolean
  placement?: any
  variant?: 'simple' | 'scale' | 'solid'
}

const iconButtonStyles = (props: IconButtonProps, { isHovered, isFocused }: TapState) => {
  let root = {
    borderRadius: 6,
    '--fill-color': isHovered || isFocused ? 'var(--color-primary)' : 'var(--color-icon)',
    opacity: props.isDisabled ? 0.4 : 1,
    cursor: props.isDisabled ? 'default' : 'pointer',
    transition: 'transform, background .15s ease-in',
  }
  if (props.variant === 'scale') {
    Object.assign(root, {
      transform: isHovered || isFocused ? 'scale(1.1)' : 'none',
    })
  } else if (props.variant === 'solid') {
    Object.assign(root, {
      padding: '.5rem',
      background: isHovered || isFocused ? 'rgba(0,0,0,.1)' : 'transparent',
    })
  }
  return { root }
}

const IconButton = forwardRef((props: IconButtonProps, ref) => {
  let { isLoading, tooltip, styles, children, placement, ...buttonProps } = props
  let buttonChildren = isLoading ? <IosSpinnerIcon /> : children
  let button = (
    <BaseButton
      {...buttonProps}
      ref={ref}
      isDisabled={buttonProps.isDisabled || isLoading}
      className={cx(s.iconButton, props.className)}
      styles={styles ? [iconButtonStyles, styles] : iconButtonStyles}
    >
      {buttonChildren}
    </BaseButton>
  )
  if (!tooltip || props.isLoading) return button
  return (
    <Tooltip
      label={tooltip}
      aria-label="button"
      style={{ zIndex: 100 }}
      placement={placement}
    >
      {button}
    </Tooltip>
  )
})

IconButton.defaultProps = {
  ...BaseButton.defaultProps,
  as: 'div',
  variant: 'scale',
  placement: 'top',
}

export default IconButton
