import React from 'react'
import { intersection } from 'lodash'

import { Entity } from 'mobx/mobx'
import { Col } from 'components/ui'

import { TaskEntity, TaskAction, TaskProblem } from 'entities'

const getTaskProblems = (task: Entity<TaskEntity>) => {
  return task.data.problem_messages ?? []
}

const contractorActionRequirements: { [key in TaskAction]?: TaskProblem[] } = {
  // задание:
  // принять задание
  start: [
    'no_payment_details',
    'not_signed_contract_by_company',
    'not_signed_contract_by_contractor',
  ],
  // отказаться от задания
  decline: [],
  // сформировать отчёт о выполнении
  done: ['not_signed_contract_by_company', 'not_signed_contract_by_contractor'],

  // акт
  // подписать акт
  make_work_acceptance: [
    'not_signed_contract_by_company',
    'not_signed_contract_by_contractor',
    'no_payment_details',
    'not_connected_moi_nalog',
  ],
  // подтвердить оплату
  complete: [],
  // подтвердить оплату и приложить чек
  complete_with_receipt: [],
  // аннулировать чек
  annul_fns_receipt: ['not_connected_moi_nalog'],
  // задекларировать доход
  make_fns_receipt: ['not_connected_moi_nalog'],
  // подписать соглашение об аннулировании
  accept_annul: [],
}

const companyActionRequirements: { [key in TaskAction]?: TaskProblem[] } = {
  // черновик
  // предложить задание исполнителю
  assign_contractor: [],
  // удалить
  delete: [],

  // задание
  // подписать ТЗ
  sign_terms: ['not_signed_contract_by_company'],
  // отменить задание
  cancel: [],

  // акт
  // подписать акт
  accept: ['not_signed_contract_by_company'],
  // отклонить акт
  reject: [],
  // оплатить
  pay: ['no_payment_details', 'not_connected_moi_nalog'],
  // отметить оплаченным
  mark_paid: [],
  // сформировать чек и отметить оплаченным
  mark_paid_with_receipt: ['not_connected_moi_nalog'],
  // отметить оплаченным без регистрации и смс
  mark_paid_without_sms: ['not_signed_contract_by_company'],
  // автооплатить
  autopay: ['no_payment_details'],
  // отменить оплату
  cancel_autopay: [],
  // запросить аннулирование
  annul: [],
}

const actionRequirements: { [key in TaskAction]?: TaskProblem[] } = {
  ...companyActionRequirements,
  ...contractorActionRequirements,
}

const getTaskActionState = (action: TaskAction, problems: TaskProblem[]) => {
  let actionProblems = intersection(problems, actionRequirements[action])
  return actionProblems.length > 0
    ? { isDisabled: true, problems: actionProblems }
    : { isDisabled: false }
}

const taskProblemMessages = {
  is_suspended: 'Работа приостановлена',
  not_signed_contract_by_company: 'Договор не подписан компанией',
  not_signed_contract_by_contractor: 'Договор не подписан исполнителем',
  no_payment_details: 'Не указаны реквизиты',
  not_connected_moi_nalog: 'Платформа Restaff не подключена в «Мой Налог»',
  not_registered_moi_nalog: 'Исполнитель не является самозанятым',
  not_signed_document: 'Есть неподписанные документы',
  contractor_is_in_blacklist: 'Бывший работник',
}

const TaskProblemList = ({ problems }: { problems?: TaskProblem[] }) =>
  problems && problems.length > 0 ? (
    <Col gap=".25rem">
      {problems.map((p) => (
        <div>{taskProblemMessages[p]}</div>
      ))}
    </Col>
  ) : (
    <></>
  )

export { getTaskActionState, getTaskProblems, taskProblemMessages, TaskProblemList }
