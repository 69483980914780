// TODO: Удалить все закомментированное и все с ним связанное
import React, { useEffect, useMemo, useState } from 'react'
import {
  observer,
  // useLocalObservable
} from 'mobx-react-lite'
import { fetchData } from 'api'
// import { Heading } from '@chakra-ui/react'
//@ts-ignore
// import { FormStore } from 'shadowform'

import { PageProps } from 'app.types'
import { useUserStore } from 'stores/context'
import { useMediaContext } from 'App/MediaContext'
import {
  // internationalPhoneMask,
  // normalizePhone,
  // validatePhoneInternational,
} from 'utils/validations'
// import dayjs from 'utils/dayjs'

import {
  Entity,
  useMobxApi,
  // initialActionState
} from 'mobx/mobx'
import {
  Amount as AmountType,
  CompanyEntity,
  // CompanyMemberEntity
} from 'entities'
import {
  ActionStateView,
  BackLink,
  Button,
  Card,
  // DatePicker,
  // Field,
  Flex,
  FormRow,
  H1,
  H2,
  // Input,
  Layout,
  // List,
  // ListCell,
  // ListHeader,
  // ListItem,
  LoadingIndicator,
  Modal,
  Placeholder,
  SectionNavigator,
  // SelectInput,
  useStateToast,
} from 'components/ui'
// import { ReactComponent as DocumentIcon } from 'assets/document.svg'
// import { Modal as OrienteModal } from 'components/FilteredList2/ui'
// import { AbilityButton } from 'components/abilityButtons'
import type { SectionNavigatorSection } from 'components/ui/SectionNavigator'
// import type {
  // StatementFormat,
  // StatementKind
// } from 'entities/nominalAccount'
import { getBaseUrl, getDefaultHeaders } from 'api/apiDefaults'
import downloadFile from 'utils/downloadFile'
// import { printIsoDate } from 'utils/datetime'

import { Avatar, Amount } from 'components'
import {
  InfoList,
  InfoListItem,
  // InfoListSubtitle
} from 'components/InfoList'
import { ContractAndDocumentsForContractor } from 'pages/contractors/ContractAndDocuments'

// import CompanyMembersList, { RoleSelect } from './CompanyMembersList'
// import { RoleSelect } from './CompanyMembersList'
import s from './style.module.css'
// import { ReactComponent as AddIcon } from './add.svg'
// import { HelpfulNotice } from 'components/HelpfulNotice'
import NumberInput from 'components/NumberInput'
import { formatAmount, normalizeAmount } from 'utils/amount'
import { CompanyRisksPage } from './CompanyRisksPage'

const CompanyInfo = observer(({ company }: { company: Entity<CompanyEntity> }) => (
  <Flex align="center" className={s.companyInfo} gap="2rem">
    <Avatar
      size="84px"
      value={company.data.avatar_url}
      name={company.data.name}
      className={s.avatar}
    />
    <H1 style={{ width: 430 }}>{company.data.name}</H1>
  </Flex>
))

const CompanyDataSection = observer(({ company }: { company: Entity<CompanyEntity> }) => {
  // let currentUser = useUserStore()
  let { isMobile } = useMediaContext()

  if (company.data.legal_entities.isEmpty) {
    return <Placeholder>Данные компании ещё заполняются</Placeholder>
  }

  let legalEntity = company.data.legal_entities.items[0]
  // let { inn, ogrn, kpp } = legalEntity.data

  const {
    business_name,
    legal_address,
    directing_body,
    director_first_name,
    director_patronymic,
    director_last_name
  } = legalEntity.data

  const businessNameParts = []

  if (director_first_name) {
    businessNameParts.push(director_first_name)
  }

  if (director_patronymic) {
    businessNameParts.push(director_patronymic)
  }

  if (director_last_name) {
    businessNameParts.push(director_last_name)
  }

  const businessName = businessNameParts.join(' ')

  let mainInfo = (
    <InfoList title="Основная информация">
      <InfoListItem label="Полное наименование">
        {/* {legalEntity.data.business_name} */}
        {business_name}
      </InfoListItem>

      <InfoListItem label="Юридический адрес">
        {/* {legalEntity.data.legal_address} */}
        {legal_address}
      </InfoListItem>

      {directing_body && businessName && (
        <InfoListItem label={directing_body}>
          {businessName}
        </InfoListItem>
      )}
      {/* {inn && <InfoListItem label="ИНН">{legalEntity.data.inn}</InfoListItem>}
      {ogrn && (
        <InfoListItem label={company.data.kind === 'ip' ? 'ОГРНИП' : 'ОГРН'}>
          {legalEntity.data.ogrn}
        </InfoListItem>
      )} */}
      {/* {kpp && <InfoListItem label="КПП">{legalEntity.data.kpp}</InfoListItem>} */}
    </InfoList>
  )

  // let bankDetails
  // if (currentUser.kind === 'company') {
  //   if (company.data.bank_details.length > 0) {
  //     bankDetails = (
  //       <InfoList title="Реквизиты">
  //         {company.data.bank_details.map((details, index) => (
  //           <InfoList key={details.id}>
  //             {company.data.bank_details.length > 1 && (
  //               <InfoListSubtitle>Расчётный счёт №{index + 1}</InfoListSubtitle>
  //             )}
  //             <InfoListItem label="Номер счёта">{details.account}</InfoListItem>
  //             <InfoListItem label="Банк">{details.bank_name}</InfoListItem>
  //             <InfoListItem label="БИК">{details.rcbic}</InfoListItem>
  //           </InfoList>
  //         ))}
  //       </InfoList>
  //     )
  //   } else {
  //     bankDetails = (
  //       <InfoList title="Реквизиты">
  //         <InfoListItem label="Реквизиты не заполнены" />
  //       </InfoList>
  //     )
  //   }
  // }

  if (isMobile) {
    return (
      <>
        {mainInfo}
        {/* {bankDetails} */}
      </>
    )
  }

  return (
    <Flex gap="5rem">
      <div className={s.column}>{mainInfo}</div>
      {/* <div className={s.column}>{bankDetails}</div> */}
    </Flex>
  )
})

// const createAddMemberForm = () =>
//   new FormStore({
//     initialValues: {
//       name: '',
//       mobile_phone: '+7',
//     },
//     fields: {
//       full_name: {
//         isRequired: true,
//         requiredError: 'Заполните ФИО',
//       },
//       mobile_phone: {
//         isRequired: true,
//         requiredError: 'Заполните номер телефона',
//         normalize: normalizePhone,
//         validations: {
//           format: {
//             validate: validatePhoneInternational,
//             error: 'Введите номер до конца',
//           },
//         },
//       },
//       role: {
//         isRequired: true,
//         requiredError: 'Выберите роль',
//       },
//     },
//   })

// interface AddMemberModalProps {
//   company: Entity<CompanyEntity>
//   onClose: () => void
// }

// const AddMemberModal = observer(({ company, onClose }: AddMemberModalProps) => {
//   let toast = useStateToast()
//   let { isMobile } = useMediaContext()
//   let size: 'm' | 'l' = isMobile ? 'm' : 'l'
//   let form = useLocalObservable(createAddMemberForm)
//   let [submitState, setSubmitState] = useState(initialActionState)
//   let onSubmit = () => {
//     let state = company.api.create<CompanyMemberEntity>({
//       type: 'companies/members',
//       payload: { company_id: company.id, ...form.values },
//     })
//     state.then(
//       (member) => {
//         toast.success({ title: 'Приглашение отправлено' })
//         company.data.members.append(member)
//         onClose()
//       },
//       (error) =>
//         toast.error({ title: 'Не удалось отправить', description: error.message })
//     )
//     setSubmitState(state)
//   }
//   return (
//     <Modal isOpen={true} onClose={onClose} size={isMobile ? '97%' : 600}>
//       <Flex gap="2rem" direction="column">
//         <Heading>Добавление сотрудника</Heading>
//         <Field field={form.fields.full_name}>
//           {({ inputProps, error }) => (
//             <FormRow label="ФИО" size={size} error={error}>
//               <Input
//                 {...inputProps}
//                 isWide
//                 size={size}
//                 placeholder="Петров Алексей Михайлович"
//               />
//             </FormRow>
//           )}
//         </Field>
//         <Field field={form.fields.mobile_phone}>
//           {({ inputProps, error }) => (
//             <FormRow label="Мобильный" size={size} error={error}>
//               <Input
//                 {...inputProps}
//                 isWide
//                 numericKeyboard
//                 size={size}
//                 placeholder="+7 (000) 000-00-00"
//                 mask={internationalPhoneMask}
//               />
//             </FormRow>
//           )}
//         </Field>
//         <Field field={form.fields.role}>
//           {({ inputProps, error }) => (
//             <FormRow label="Роль" size={size} error={error}>
//               <RoleSelect {...inputProps} size={size} />
//             </FormRow>
//           )}
//         </Field>
//         {form.values.role && !['manager', 'readonly'].includes(form.values.role) && (
//           <Flex direction="column" gap=".5rem">
//             <div style={{ fontWeight: 500 }}>
//               Роль с правом подписи или оплаты требует подписания доверенности
//             </div>
//             <div>
//               После идентификации сотрудника мы подготовим доверенность на подпись и
//               отправим вам уведомление.
//             </div>
//           </Flex>
//         )}
//         <Button
//           isDisabled={!form.isValid}
//           isLoading={submitState.state === 'pending'}
//           onTap={onSubmit}
//         >
//           Добавить сотрудника
//         </Button>
//       </Flex>
//     </Modal>
//   )
// })

// const mailtoAddMember = (company: Entity<CompanyEntity>) => {
//   window.open(
//     `mailto:clients@konsol.pro?subject=Добавить%20сотрудника%20в%20компанию%20${company.data.name}&body=Привет,хочу%20добавить сотрудника%0D%0AИмя сотрудника:%0D%0AТелефон:%0D%0AПочта:%0D%0AРоль:`
//   )
// }

// const MembersSection = observer(({ company }: { company: Entity<CompanyEntity> }) => {
//   let currentUser = useUserStore()
//   let [modalIsOpen, setModalIsOpen] = useState(false)
//   let addMember = () =>
//     currentUser.hasFeature('member_invites')
//       ? setModalIsOpen(true)
//       : mailtoAddMember(company)

//   return (
//     <>
//       <Flex gap="2rem" direction="column">
//         <HelpfulNotice
//           links={[
//             {
//               href: 'https://support.konsol.pro/users-roles',
//               title: 'Роли сотрудников и права доступа',
//             },
//           ]}
//           icon={<DocumentIcon />}
//         />
//         <div>
//           <CompanyMembersList
//             members={company.data.members}
//             style={{ marginBottom: '3rem' }}
//           />
//           <AbilityButton
//             ability={currentUser.hasAbility('company_members.create')}
//             style={{ alignSelf: 'start' }}
//             size="m"
//             icon={<AddIcon />}
//             onTap={addMember}
//           >
//             Добавить сотрудника
//           </AbilityButton>
//         </div>
//       </Flex>
//       {modalIsOpen && (
//         <AddMemberModal company={company} onClose={() => setModalIsOpen(false)} />
//       )}
//     </>
//   )
// })

export const downloadDocument = async (url: string, name: string, toast: any) => {
  try {
    let { blob } = await fetchData({
      url: getBaseUrl() + url,
      method: 'GET',
      as: 'file',
      headers: getDefaultHeaders(),
    })

    downloadFile(blob, name)
  } catch (e) {
    toast.error({ title: 'Не удалось скачать файл' })
    throw e
  }
}

// type StatementSelectOption = {
//   value: StatementKind
//   label: string
// }

// interface StatementModalProps {
//   download: (
//     format: StatementFormat,
//     kind: StatementKind,
//     range: [Date, Date]
//   ) => Promise<any>
//   onClose: () => void
//   statementFormats: StatementSelectOption[]
// }

// const StatementModal = ({ download, onClose, statementFormats }: StatementModalProps) => {
//   let { isMobile } = useMediaContext()
//   let [format, setFormat] = useState<StatementSelectOption | null>()
//   let [range, setRange] = useState<any>()
//   let isValid = format && range && range[0] && range[1]
//   let [isLoading, setIsLoading] = useState(false)
//   let submit = () => {
//     setIsLoading(true)
//     let downloadFormat: StatementFormat =
//       format!.value === 'txt-konsol'
//         ? 'txt'
//         : format!.value === 'pdf-konsol'
//         ? 'pdf'
//         : format!.value
//     download(downloadFormat, format!.value, range).then(onClose, () =>
//       setIsLoading(false)
//     )
//   }

//   return (
//     <OrienteModal
//       isOpen={true}
//       width={isMobile ? '97%' : '500px'}
//       onClose={onClose}
//       closeOnOverlayClick={false}
//     >
//       <Flex direction="column" gap="var(--gap-m)">
//         <H2>Скачать выписку</H2>
//         <FormRow label="Формат">
//           <SelectInput
//             value={format}
//             onChange={setFormat}
//             options={statementFormats}
//             placeholder="Выберите формат"
//             size="l"
//           />
//         </FormRow>
//         <FormRow label="Период">
//           <DatePicker
//             value={range}
//             selectsRange={true}
//             onChange={setRange}
//             size="l"
//             minDate={undefined}
//           />
//         </FormRow>
//         <Button isDisabled={!isValid} isLoading={isLoading} onTap={submit}>
//           Скачать
//         </Button>
//       </Flex>
//     </OrienteModal>
//   )
// }

interface DepositModalProps {
  download: (amount: string) => Promise<any>
  onClose: () => void
  minAmount?: AmountType
}

export const DepositModal = ({ download, onClose, minAmount }: DepositModalProps) => {
  let { isMobile } = useMediaContext()
  let [amount, setAmount] = useState('')
  let [isLoading, setIsLoading] = useState(false)
  let submit = () => {
    setIsLoading(true)
    download(amount).then(onClose, () => setIsLoading(false))
  }
  const minAmountError =
    minAmount &&
    Number(amount) < Number(normalizeAmount(minAmount).value) &&
    `Минимальная сумма пополнения ${formatAmount(minAmount)}`
  return (
    <Modal isOpen={true} onClose={onClose} size={isMobile ? '97%' : '500px'}>
      <Flex direction="column" gap="2rem">
        <H2>Пополнить баланс</H2>
        <FormRow label="Сумма пополнения" error={minAmountError}>
          <NumberInput
            value={amount}
            onChange={(value) => setAmount(String(value))}
            autoFocus={true}
            placeholder="Введите сумму пополнения"
            size="l"
            isWide
            allowDecimal={true}
          />
        </FormRow>
        <div>
          Согласно правилам пользования номинальным счётом пополнить можно только со счета
          вашей организации, иначе банк не сможет зачислить деньги
        </div>
        <Button
          size="s"
          onTap={submit}
          isDisabled={amount.length === 0 || parseFloat(amount) === 0}
          isLoading={isLoading}
        >
          Скачать счёт на оплату
        </Button>
      </Flex>
    </Modal>
  )
}

// let statementFormats: StatementSelectOption[] = [
//   { value: 'pdf-konsol', label: 'PDF (по расчётам Restaff)' },
//   { value: 'txt-konsol', label: '1С (по расчётам Restaff)' },
//   { value: 'pdf', label: 'PDF' },
//   { value: 'csv', label: 'CSV' },
//   { value: 'txt', label: '1C(TXT)' },
//   { value: 'xlsx', label: 'XLSX' },
// ]

const NominalAccountSection = observer(
  ({ company }: { company: Entity<CompanyEntity> }) => {
    let { isMobile } = useMediaContext()
    let toast = useStateToast()
    useEffect(() => {
      company.data.nominal_account.fetch()
    }, [company.data.nominal_account])

    // let [statementModalIsOpen, setStatementModalIsOpen] = useState(false)
    let [depositModalIsOpen, setDepositModalIsOpen] = useState(false)
    let account = company.data.nominal_account!

    // let downloadDetails = () =>
    //   downloadDocument(
    //     `/nominal_accounts/${account.id}/details.pdf`,
    //     `konsol_account_details_${account.id}.pdf`,
    //     toast
    //   )
    let downloadDepositForm = (amount: string) =>
      downloadDocument(
        `/nominal_accounts/${account.id}/deposit.pdf?amount=${amount}`,
        `konsol_account_deposit_form_${account.id}.pdf`,
        toast
      )
    // let downloadStatement = (
    //   format: StatementFormat,
    //   kind: StatementKind,
    //   range: [Date, Date]
    // ) => {
    //   let from = dayjs(range[0]).format('YYYY-MM-DD')
    //   let _to = dayjs(range[1]).format('YYYY-MM-DD')
    //   return downloadDocument(
    //     `/nominal_accounts/${account.id}/statement.${format}?from=${from}&to=${_to}&kind=${kind}`,
    //     `konsol_account_statement_${account.data.account}.${format}`,
    //     toast
    //   )
    // }

    // let operations
    // if (account.data.operations.items.length > 0) {
    //   let title = (
    //     <Flex justify="space-between" align="center">
    //       <div>Операции</div>
    //       {account.data.actions.includes('statement') && (
    //         <Button design="normal" size="xs" onTap={() => setStatementModalIsOpen(true)}>
    //           Скачать выписку
    //         </Button>
    //       )}
    //     </Flex>
    //   )
    //   operations = (
    //     <InfoList title={title}>
    //       <List style={{ marginTop: '1.5rem' }}>
    //         {!isMobile && (
    //           <ListHeader>
    //             <ListCell width={120}>Дата</ListCell>
    //             <ListCell grow={2}>Контрагент</ListCell>
    //             <ListCell grow={3}>Назначение платежа</ListCell>
    //             <ListCell width={130} style={{ textAlign: 'right' }}>
    //               Cумма
    //             </ListCell>
    //           </ListHeader>
    //         )}
    //         {account.data.operations.items.map((operation) => {
    //           let date = printIsoDate(operation.data.date, false)
    //           let amount =
    //             operation.data.kind === 'deposit' ? (
    //               <div style={{ color: 'var(--color-green)' }}>
    //                 +<Amount value={operation.data.amount} />
    //               </div>
    //             ) : (
    //               <Amount value={operation.data.amount} />
    //             )
    //           let who =
    //             operation.data.kind === 'deposit'
    //               ? operation.data.sender
    //               : operation.data.recipient
    //           return isMobile ? (
    //             <ListItem wrap key={operation.id}>
    //               <ListCell row>
    //                 <ListCell style={{ color: 'var(--color-secondary)' }}>
    //                   {date}
    //                 </ListCell>
    //                 <ListCell style={{ textAlign: 'right' }}>{amount}</ListCell>
    //               </ListCell>
    //               <ListCell row style={{ fontWeight: 600 }}>
    //                 {who}
    //               </ListCell>
    //               <ListCell>{operation.data.purpose}</ListCell>
    //             </ListItem>
    //           ) : (
    //             <ListItem key={operation.id}>
    //               <ListCell width={120} style={{ color: 'var(--color-secondary)' }}>
    //                 {date}
    //               </ListCell>
    //               <ListCell grow={2}>{who}</ListCell>
    //               <ListCell grow={3}>{operation.data.purpose}</ListCell>
    //               <ListCell width={130} style={{ textAlign: 'right' }}>
    //                 {amount}
    //               </ListCell>
    //             </ListItem>
    //           )
    //         })}
    //       </List>
    //     </InfoList>
    //   )
    // }

    return (
      <>
        <InfoList title="Баланс:">
          {/* <InfoListItem label="Счёт">
            <Flex
              gap="1.5rem"
              align="center"
              justify={isMobile ? 'space-between' : 'start'}
            >
              <div>
                {account.data.account} в {account.data.bank_name}
              </div>
              {account.data.actions.includes('download_details') && (
                <Button design="normal" size="xs" onTap={downloadDetails}>
                  Скачать реквизиты
                </Button>
              )}
            </Flex>
          </InfoListItem> */}
          <InfoListItem>
            <Flex
              gap="1.5rem"
              align="center"
              justify={isMobile ? 'space-between' : 'start'}
            >
              <Amount value={account.data.balance} />
              {company.data.nominal_account.fetchState.state === 'pending' && (
                <LoadingIndicator delay={400} />
              )}
              {account.data.actions.includes('deposit') && (
                <Button
                  design="normal"
                  size="xs"
                  onTap={() => setDepositModalIsOpen(true)}
                >
                  Пополнить баланс
                </Button>
              )}
            </Flex>
          </InfoListItem>
          {/* <InfoListItem label="Тариф">{account.data.tariff}</InfoListItem> */}
        </InfoList>
        {/* <HelpfulNotice
          style={{ marginBottom: '2.5rem' }}
          links={[
            {
              title: 'Бухгалтерский учет взаиморасчетов с Restaff',
              href: 'https://konsol.pro/infobase/buhgalterskij-uchet-vzaimoraschetov',
            },
          ]}
        /> */}
        {/* {operations}
        {statementModalIsOpen && (
          <StatementModal
            statementFormats={statementFormats.filter((item) =>
              account.data.statement_formats.includes(item.value)
            )}
            download={downloadStatement}
            onClose={() => setStatementModalIsOpen(false)}
          />
        )} */}
        {depositModalIsOpen && (
          <DepositModal
            download={downloadDepositForm}
            onClose={() => setDepositModalIsOpen(false)}
          />
        )}
      </>
    )
  }
)

const ContractorCompanyPage = observer(({ match }: PageProps) => {
  let id = Number(match.params.id)
  let mobxApi = useMobxApi()
  let [fetchState] = useState(() => mobxApi.fetch({ type: 'companies', id }))
  return (
    <ActionStateView state={fetchState}>
      {(company: Entity<CompanyEntity>) => {
        let sections: { [key: string]: SectionNavigatorSection } = {
          data: {
            title: 'Данные заказчика',
            render: () => <CompanyDataSection company={company} />,
          },
          contract: {
            title: 'Документы',
            render: () => <ContractAndDocumentsForContractor company={company} />,
          },
        }
        let suspendedWarning = company.data.is_suspended && (
          <Card
            kind="error"
            title="Компания приостановила работу с вами"
            style={{ marginBottom: '1rem' }}
          />
        )
        return (
          <Layout smallPaddingTop>
            <BackLink to="/companies" style={{ marginBottom: '3rem' }}>
              К компаниям
            </BackLink>
            <div className={s.contentRow}>
              <div className={s.main}>
                {suspendedWarning}
                <CompanyInfo company={company} />
                <SectionNavigator
                  basePath={match.url}
                  sections={sections}
                  defaultSection="data"
                />
              </div>
            </div>
          </Layout>
        )
      }}
    </ActionStateView>
  )
})

const MemberCompanyPage = observer(({ match }: PageProps) => {
  let currentUser = useUserStore()
  let company = useMemo(() => currentUser.company!, [currentUser.company])
  let [fetchState] = useState(() => currentUser.company!.fetch())
  return (
    <ActionStateView state={fetchState}>
      {() => {
        let sections: { [key: string]: SectionNavigatorSection } = {
          data: {
            title: 'Данные компании',
            render: () => <CompanyDataSection company={company} />,
          },
          // members: {
          //   title: 'Сотрудники и доступы',
          //   render: () => <MembersSection company={company} />,
          // },
        }
        if (
          company.data.nominal_account &&
          currentUser.hasAbility('nominal_accounts.show')
        ) {
          sections['nominal-account'] = {
            title: company.data.nominal_account.data.kind,
            render: () => <NominalAccountSection company={company} />,
          }
        }

        // sections['integrations'] = {
        //   title: 'Интеграции',
        //   render: () => <IntegrationsSection basePath={`${match.path}/integrations`} />,
        // }

        // if (currentUser.hasFeature('tariffs')) {
        //   sections['tariff'] = {
        //     title: 'Тариф и документы',
        //     render: () => <TariffsSection />,
        //   }
        // }

        if (currentUser.company?.data.features.contractors_blacklist) {
          sections['risks'] = {
            title: 'Риски',
            render: () => <CompanyRisksPage />,
          }
        }

        return (
          <Layout>
            <div className={s.contentRow}>
              <div className={s.main}>
                <CompanyInfo company={company} />
                <SectionNavigator
                  basePath={match.url}
                  defaultSection="data"
                  sections={sections}
                />
              </div>
            </div>
          </Layout>
        )
      }}
    </ActionStateView>
  )
})

export { ContractorCompanyPage, MemberCompanyPage }
