import { rest } from 'msw'

export default [
  rest.post('/api/contractor/withdrawals', (req: any, res: any, ctx: any) =>
    res(ctx.delay(2000), ctx.json({ id: req.body.id }))
  ),
  // rest.post(
  //   '/api/contractor/withdrawals',
  //   error(422, {
  //     error: { code: '500', message: 'Не можем отправить деньги' },
  //   })
  // ),
]
