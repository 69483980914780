import React, { forwardRef } from 'react'

const extendProps = <C extends React.ComponentType<any>, E>(
  Component: C,
  defaultProps: Partial<React.ComponentProps<C> & E> = {}
) => {
  const newComponent: React.ForwardRefExoticComponent<
    React.PropsWithoutRef<React.ComponentProps<C>> & E
  > = forwardRef((props: React.ComponentProps<C> & E, ref) => (
    <Component {...(props as any)} ref={ref} />
  ))
  newComponent.defaultProps = defaultProps
  return newComponent
}

export default extendProps
