import Template from 'entities/templates'
import { rest } from 'msw'

import { json } from './helpers'

const templateList: Template[] = [
    {
        id: 42,
        kind: "contract",
        enabled: true,
        name: "Водительское удостоверение",
        variables: [
            {
                key: "shop",
                name: "Магазин",
                is_required: true,
                caption: "Выбери магазин",
                options: [
                    "Дикси",
                    "Пятерочка"
                ]
            }
        ]
    }
];

export default [
    rest.get('/api/company/templates', json(templateList)),
];
