import { Token } from 'entities/integrations'
import { TariffDocumentGroup, TariffDocument, Tariffs } from 'entities/tariffs'
import { rest } from 'msw'

import { json } from './helpers'

const companies = [
  {
    id: 1,
    name: 'A1 решения очень длинное название компании',
    contracts: [],
    documents: [],
    features: {
      is_defaulter: true,
    },
  },
  {
    id: 2,
    name: 'Х7 решения оченьоченьоченьдлинноеназваниеводнустроку',
    contracts: [],
    documents: [],
    is_suspended: true,
  },
  {
    id: 777,
    invite_status: 'not_accepted',
    name: 'ООО Приглашалово',
    contracts: [],
    documents: [],
  },
]

const acceptedInvite = {
  id: 777,
  invite_status: 'accepted',
  name: 'ООО Приглашалово',
  contracts: [
    {
      id: 12345,
      title: 'Договор на оказание услуг',
      status: 'created',
      date: '2021-05-21',
      file_url: 'http://localhost:5000/document.html',
    },
  ],
}

const members = [
  {
    id: 1,
    invited_at: '2021-02-17T12:26:19Z',
    email: 'user@email.com',
    full_name: 'Иванов Иван Иванович',
    mobile_phone: '+70002223344',
    role: 'owner',
    user_id: 1,
    status: 'active',
    power_of_attorney: {
      id: 1,
      url: 'http://google.com',
      status: 'accepted',
      // status: 'cancelled',
      date: '2022-04-09', // дата выдачи просрочки
      issued: 'Клавой Кокой', // просрочка выдана
      expired_at: '2022-03-29',
      show_overdue_notification: true,
    },
  },
  {
    id: 2,
    invited_at: '2021-02-17T12:26:19Z',
    email: 'user@email.com',
    full_name: 'Иванов Иван Иванович',
    mobile_phone: '+70002223344',
    role: 'owner',
    user_id: 2,
    status: 'active',
    power_of_attorney: {
      id: 1,
      url: 'http://google.com',
      status: 'accepted',
      date: '2022-03-31', // дата выдачи
      issued: 'Бэтмен', // просрочка выдана
      expired_at: '2022-03-28',
    },
  },
  {
    id: 3,
    invited_at: '2021-02-17T12:26:19Z',
    email: 'user@email.com',
    full_name: 'Ридонли Иван Петрович',
    mobile_phone: '+70002223344',
    role: 'readonly',
    user_id: 3,
    status: 'active',
    power_of_attorney: {
      id: 1,
      url: 'http://google.com',
      status: 'accepted',
      date: '2022-03-02', // дата просрочки
      issued: 'Бэтмен', // просрочка выдана
      expired_at: '2022-03-31',
      issued_user_id: 1,
    },
  },
  {
    id: 4,
    invited_at: '2021-03-17T12:26:19Z',
    email: 'pomnikita+staginggerman@gmail.com',
    full_name: 'Копылов Герман Инвайтович',
    mobile_phone: '+70002223344',
    role: 'manager',
    status: 'approved',
    power_of_attorney: { id: 2, url: 'http://google.com', status: 'not_accepted' },
  },
  {
    id: 5,
    invited_at: '2021-02-17T12:26:19Z',
    email: 'user@email.com',
    full_name: 'Неидентифицирован Иван Петрович',
    mobile_phone: '+70002223344',
    role: 'manager',
    user_id: 5,
    status: 'identification',
  },
]

const invited = {
  ...members[2],
  status: 'active',
}

let newMember = {
  email: 'member@gmail.com',
  full_name: 'Ещё один',
  mobile_phone: '+70002223344',
  role: 'manager',
  user_id: 2,
  status: 'identification',
}

const nominalAccount = {
  id: 51,
  balance: '174378.74',
  account: '40702810910000532583',
  rcbic: '044525974',
  bank_name: 'АО "ТИНЬКОФФ БАНК"',
  bank_account: '30101810145250000974',
  bank_code: 'Tinkoff',
  kind: 'Номинальный счёт',
  tariff: '1,5%, минимум 49₽',
  actions: ['download_details', 'deposit', 'statement'],
  statement_formats: ['pdf-konsol', 'txt-konsol', 'pdf', 'csv', 'txt', 'xlsx'],
  operations: [
    {
      id: 1,
      date: '2020-08-15T13:35:02.081Z',
      recipient: 'Получатель',
      sender: 'Отправитель',
      purpose: 'Назначение',
      amount: '4378.74',
      kind: 'deposit',
    },
    {
      id: 2,
      date: '2020-08-15T13:35:02.081Z',
      recipient: 'Получатель',
      sender: 'Отправитель',
      purpose: 'Назначение',
      amount: '4378.74',
      kind: 'withdrawal',
    },
  ],
}

const company = {
  id: 3,
  kind: 'ooo',
  name: 'A1 решения очень длинное название компании',
  avatar_url: null,
  tinkoff: { token_valid: true },
  is_suspended: true,
  legal_entities: [
    {
      directing_body: 'Генеральный директор',
      director_first_name:	'Олег',
      director_last_name:	'Олегов',
      director_patronymic:	'Олегович',
      id: 75445,
      inn: '3123011520',
      ogrn: '1027739328440',
      kpp: '772601001',
      business_name: 'Общество с ограниченной ответственностью “Клади-забивай”',
      legal_address: '117648,г. Москва, Чертаново Северное МКР, дом 1а, корпус 1',
      registration_date: '2010-01-01',
      title: 'Клади-забивай',
    },
  ],
  contracts: [
    {
      id: 75448,
      title: 'Название договора',
      file_url:
        'https://storage.yandexcloud.net/frontbucket/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBIdz09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--7a4b84319a0af7cae4a92d880db88f51956e52b6/TaskCreate%20%D0%92%D0%B5%D1%80%D1%82%20%D0%BC%D0%B5%D0%BD%D1%8E%20(alex).png',
      signed_by_contractor: false,
      signed_by_legal_entity: false,
      events: [
        {
          id: 1,
          kind: 'upload_by_legal_entity',
          whodunnit: {
            avatar: null,
            name: 'Вальдемир Заказчиков',
          },
          created_at: '2020-08-15T13:35:02.081Z',
        },
        {
          id: 2,
          kind: 'signed_by_contractor',
          whodunnit: {
            avatar: null,
            name: 'Alex',
          },
          created_at: '2020-08-15T14:35:02.081Z',
        },
      ],
    },
  ],
  documents: [],
  members,
  bank_details: [
    {
      id: 51,
      account: '40702810910000532583',
      rcbic: '044525974',
      bank_name: 'АО "ТИНЬКОФФ БАНК"',
      bank_account: '30101810145250000974',
      bank_code: 'Tinkoff',
    },
    {
      id: 52,
      account: '40702810910000532583',
      rcbic: '044525974',
      bank_name: 'АО "ВОЛГО-ВЯТСКИЙ БАНК СБЕРБАНКА РФ"',
      bank_account: '30101810145250000974',
      bank_code: 'Tinkoff',
    },
  ],
  nominal_account: nominalAccount,
}

const join = (req: any, res: any, ctx: any) => {
  if (req.body.permalink === 'already_invited') {
    return res(
      ctx.delay(500),
      ctx.status(500),
      ctx.json({
        error: { code: 'already_invited', message: 'Вы уже работаете с компанией X' },
      })
    )
  }
  if (req.body.permalink === 'not_found') {
    return res(
      ctx.delay(500),
      ctx.status(500),
      ctx.json({
        error: {
          code: 'not_found',
          message: 'Приглашение не найдено. Проверьте правильность ссылки.',
        },
      })
    )
  } else {
    return res(
      ctx.delay(500),
      ctx.json({ message: 'Вы получили приглашение в компанию' })
    )
  }
}

const tokens: Token[] = [
  {
    name: 'Длинное название ключа',
    created: '2021-03-02T12:17:22Z',
    last_used: '2021-03-02T12:17:22Z',
    token: 'Ss0httpExZ2XNGqjO5kXpb-n4oHy3M2J5hoFbmXxq2dwAWaRYKxs4-rQH84kzK1X',
    whitelist_ips: ['192.168.0.1'],
    id: 1,
  },
  {
    name: 'Длинное название ключа',
    created: '2020-08-15T13:35:02.081Z',
    last_used: null,
    token: 'Ss0httpExZ2XNGqjO5kXpb-n4oHy3M2J5hoFbmXxq2dwAWaRYKxs4-rQH84kzK12',
    whitelist_ips: ['192.168.0.1', '192.168.0.2'],
    id: 2,
  },
  {
    name: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.',
    created: '2020-08-15T13:35:02.081Z',
    last_used: null,
    token: 'Ss0httpExZ2XNGqjO5kXpb-n4oHy3M2J5hoFbmXxq2dwAWaRYKxs4-rQH84kzK12',
    whitelist_ips: ['192.168.0.1', '192.168.0.2', '192.168.0.3', '192.168.0.4'],
    id: 3,
  },
]

const tariffs: Tariffs = {
  problems: [
    {
      title: 'У вас есть неоплаченный счет',
      kind: 'unpaid',
      options: {
        document_file_url: 'https://google.com',
      },
    },
    { title: 'Не получилось списать деньги', kind: 'payment_failure', options: {} },
  ],
  main: [
    {
      title: 'Услуги (Лицензия)',
      description: 'за активного пользователя',
      amount: '123 ₽',
    },
    {
      title: 'Агентский',
      description: 'с каждой оплаты исполнителю',
      amount: '1,15 %',
      commission: {
        text: 'Минимальная комиссия с оплаты',
        amount: '35 ₽',
      },
    },
  ],
  rules: [
    {
      title: 'Услуги (Лицензия)',
      headers: ['hello', 'Цена за 1 активного пользователя'],
      values: [
        {
          range: 'До 50',
          value: '350 ₽',
          is_current: true,
        },
        {
          range: 'От 51 до 150',
          value: '300 ₽',
        },
        {
          range: 'Свыше 151',
          value: '250 ₽',
        },
      ],
    },
    {
      title: 'Услуги (Лицензия)',
      headers: ['Кол-во активных пользователей*', 'Цена за 1 активного пользователя'],
      values: [
        {
          range: 'От 100 до 300',
          value: '1 000 ₽',
          is_current: true,
        },
        {
          range: 'Свыше 4',
          value: '350 ₽',
        },
      ],
    },
  ],
  stats: {
    month: 'Апрель',
    values: [
      {
        description: 'sdfgdfgdfg',
        amount: '500 ₽',
      },
    ],
    result: {
      description: 'всего',
      amount: '1000 ₽',
    },
  },
  other: [
    'Оплата за лицензию будет списываться с номинального счета',
    'Стоимость отчета SpectrumData — 300 ₽',
  ],
}

const tariffDocuments: TariffDocument[] = [
  {
    id: 1,
    title: 'Счёт-акт 123123',
    kind: 'act',
    payment_status: 'not_paid',
    status: 'created',
    file_url: 'https://google.com',
    date: 'Март 2022',
    description: 'После подписания спишем бабки',
    amount: { value: '10000', currency: 'RUB' },
  },
  {
    id: 2,
    title: 'Отчёт агента',
    kind: 'agent_invoice',
    payment_status: 'paid',
    status: 'signed_by_legal_entity',
    file_url: 'https://yandex.ru',
    date: 'Март 2022',
    description: null,
    amount: { value: '10000', currency: 'EUR' },
  },
]

const tariffDocumentGroups: TariffDocumentGroup[] = [
  {
    month: 'Март 2022',
    documents: tariffDocuments,
  },
]

export { companies, company, members }

export default [
  rest.get('/api/company/companies/:id', (req, res, ctx) =>
    res(ctx.delay(500), ctx.json({ ...company, id: req.params.id }))
  ),
  rest.get('/api/company/nominal_accounts/:id', json(nominalAccount)),

  rest.post(
    '/api/company/companies/members',
    json({ id: Math.round(Math.random() * 1e7), ...newMember })
  ),

  rest.post(
    '/api/company/companies/members/:id/code_for_sign_power_of_attorney',
    json({
      date: '2022-03-28',
      expired_at: '2022-03-28T00:00:00Z',
      id: 74,
      issued: 'Иван Иваныч',
      status: 'not_accepted',
      url: 'http://google.com',
    })
  ),
  rest.patch('/api/company/companies/members/:id', json({})),
  rest.put('/api/company/companies/members/:id/sign_power_of_attorney', json(invited)),
  rest.delete(
    '/api/company/companies/members/:id',
    json({
      success: true,
    })
  ),

  rest.get('/api/contractor/companies', json(companies)),
  rest.get('/api/contractor/companies/:id', json(company)),

  rest.put('/api/contractor/companies/join', join),
  rest.put('/api/contractor/companies/:id/accept_invite', json(acceptedInvite)),

  rest.post('/api/company/members/registration', json({})),

  rest.get('/api/company/tokens', json(tokens)),
  rest.post<{ name: string; whitelist_ips: string }>(
    '/api/company/tokens',
    (req, res, ctx) =>
      res(
        ctx.delay(500),
        ctx.json({
          ...tokens[0],
          whitelist_ips: req.body.whitelist_ips,
          id: tokens.length + 1,
          name: req.body.name,
        })
      )
  ),
  rest.delete<Token>('/api/company/tokens/:id', (req, res, ctx) => {
    const token = tokens.find((token: any) => token.id === req.params.id)
    return res(ctx.delay(500), ctx.json(token))
  }),
  rest.patch<{ name: string; whitelist_ips: string }>(
    '/api/company/tokens/:id',
    (req, res, ctx) => {
      const token = tokens.find((token: any) => token.id === req.params.id)
      return res(
        ctx.delay(500),
        ctx.json({
          ...token,
          whitelist_ips: req.body.whitelist_ips,
          id: tokens.length + 1,
          name: req.body.name,
        })
      )
    }
  ),
  rest.post('/api/company/subtasks/templates', (req, res, ctx) =>
    res(
      ctx.delay(500),
      ctx.json({
        currency: 'EUR',
        description: 'новая работа, которая тока что добавлена',
        id: 1,
        title: 'Тока что добавлено',
        unit: { id: 1, title: 'Час' },
        unit_cost: '12000.00',
      })
    )
  ),

  rest.put('/api/company/subtasks/templates/:id', (req, res, ctx) =>
    res(ctx.delay(500), ctx.json({}))
  ),

  rest.get<Tariffs>('/api/company/tariffs', json(tariffs)),
  rest.get<TariffDocumentGroup[]>(
    '/api/company/tariffs/documents',
    json(tariffDocumentGroups)
  ),
  rest.get<TariffDocument[]>(
    '/api/company/tariffs/documents/for_sign',
    json(tariffDocuments)
  ),
  // @ts-ignore
  rest.post<{ sign_id: string }, { ids: number[] }>(
    '/api/company/tariffs/documents/code_multi_sign',
    json({ sign_id: 'a9a9504e-5994-4319-80a0-489dc9f93118' })
  ),
  rest.post<TariffDocument[]>(
    '/api/company/tariffs/documents/multi_sign',
    json(tariffDocuments)
  ),

  // rest.get('/api/company/scenario_tasks/:id', json({
  //   "id": 1407,
  //   "description": "сделай всё красиво, Никола",
  //   "document_number": {
  //     "number": 1407
  //   },
  //   "status": "annulled",
  //   "since": "2021-11-01",
  //   "upto": "2021-11-03",
  //   "title": "Проверяем работу заданий",
  //   "kind": "task",
  //   "display_amount": "5000.0",
  //   "fns_receipts": [
  //     {
  //       "id": 1108,
  //       "offline": false,
  //       "preview_url": "https://himself-ktr.nalog.ru/api/v1/receipt/041104807660/20cn4uerl9/print",
  //       "url": "https://himself-ktr.nalog.ru/api/v1/receipt/041104807660/20cn4uerl9/print",
  //       "status": "created",
  //       "number": "20cn4uerl9",
  //       "customer": null,
  //       "amount": null,
  //       "attachments": [
  //         {
  //           "id": 588,
  //           "title": null,
  //           "file_url": "https://storage.yandexcloud.net/yadroprivatebucketstaging/u4vedrc2ala3808ajvg9959fs2p2?response-content-disposition=inline%3B%20filename%3D%22receipt.png%22%3B%20filename%2A%3DUTF-8%27%27receipt.png&response-content-type=image%2Fjpeg&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=-Q_n2PEzOqw23uQdxDXl%2F20220513%2Fru-central1%2Fs3%2Faws4_request&X-Amz-Date=20220513T072501Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=30425facb4f8e5dea1a53b8b9d9dd03561c3aacf69080ed048684fff25b12829"
  //         }]
  //     }
  //   ],
  //   "task": {
  //     "status": "annulled",
  //     "description": "сделай всё красиво, Никола",
  //     "subtasks": [
  //       {
  //         "id": 1047,
  //         "description": "Чем-то вкусным",
  //         "quantity": 10,
  //         "title": "Покормить кота",
  //         "unit_cost": "100.0",
  //         "unit_id": 3,
  //         "currency": "RUB"
  //       }
  //     ],
  //     "attachments": [
  //       {
  //         "id": 587, "title": "task_bundle.csv", "file_url": "https://storage.yandexcloud.net/yadroprivatebucketstaging/avtyx0ed18bol5s78r1y8kp86wze?response-content-disposition=attachment%3B%20filename%3D%22task_bundle.csv%22%3B%20filename%2A%3DUTF-8%27%27task_bundle.csv&response-content-type=application%2Fvnd.ms-excel&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=-Q_n2PEzOqw23uQdxDXl%2F20220513%2Fru-central1%2Fs3%2Faws4_request&X-Amz-Date=20220513T072501Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=d94c06d684d9b601fb785a5dd75deed49932b4a2f7e4689ad9cce5c2f597dd34"
  //       }
  //     ],
  //     "terms_of_reference_original_pdf_url": "https://yadro-sunra.stg.konsol.pro/company/documents/file_url?token=eyJhbGciOiJIUzI1NiJ9.eyJ0b3NfaWQiOjM4MSwiZXhwIjoxNjU1MDE4NzAxfQ.2_7DDNJ6GcMmWiUS_iSeYrUrtfpl1jmrSnZoiJ5Shbg",
  //     "terms_of_reference_blurb_pdf_url": "https://yadro-sunra.stg.konsol.pro/company/documents/file_url?token=eyJhbGciOiJIUzI1NiJ9.eyJ0b3NfaWQiOjM4MSwiZXhwIjoxNjU1MDE4NzAxfQ.2_7DDNJ6GcMmWiUS_iSeYrUrtfpl1jmrSnZoiJ5Shbg"
  //   },
  //   "act": {
  //     "status": "annulled", "amount": "5000.0", "prepaid_amount": "0.0", "payment_amount": "5000.0", "exchange_rate": null, "date": "2021-11-03", "work_acceptance_original_pdf_url": "https://yadro-sunra.stg.konsol.pro/company/documents/file_url?token=eyJhbGciOiJIUzI1NiJ9.eyJhY3RfaWQiOjI4NDA3LCJleHAiOjE2NTUwMTg3MDF9.wJ7cwEmL7sLdPjkeLUvRWBWBeEdcHDHD_7bjxXgggic", "work_acceptance_blurb_pdf_url": null, "attachments": [], "payment_amount_after_savings": "4700.0", "tax_savings_amount": "300.0"
  //   },
  //   "payment": {
  //     "status": "paid", "error": null
  //   },
  //   "actions": [],
  //   "events": [
  //     {
  //       "id": 49835, "kind": "create", "created_at": "2021-11-03T10:55:01Z", "whodunnit": { "name": "Директор Кухоньки" }
  //     },
  //     {
  //       "id": 49836, "kind": "sign_terms", "created_at": "2021-11-03T10:55:11Z", "whodunnit": { "name": "Директор Кухоньки" }
  //     },
  //     {
  //       "id": 49837, "kind": "start", "created_at": "2021-11-03T10:56:13Z", "whodunnit": { "name": "Николай Гоголь" }
  //     },
  //     {
  //       "id": 49838, "kind": "done", "created_at": "2021-11-03T10:56:56Z", "whodunnit": { "name": "Николай Гоголь" }
  //     },
  //     {
  //       "id": 49839, "kind": "make_work_acceptance", "created_at": "2021-11-03T10:57:16Z", "whodunnit": { "name": "Николай Гоголь" }
  //     },
  //     {
  //       "id": 49840, "kind": "accept", "created_at": "2021-11-03T11:01:37Z", "whodunnit": { "name": "Директор Кухоньки" }
  //     },
  //     {
  //       "id": 49841, "kind": "autopay", "created_at": "2021-11-03T11:01:57Z", "whodunnit": { "name": "Директор Кухоньки" }
  //     },
  //     {
  //       "id": 49842, "kind": "complete_with_auto_receipt", "created_at": "2021-11-03T11:02:06Z", "whodunnit": { "name": "Николай Гоголь" }
  //     },
  //     {
  //       "id": 62843, "kind": "annul", "created_at": "2022-05-13T07:25:00Z", "whodunnit": { "name": "Эрик Фарукшин" }
  //     }
  //   ],
  //   "annulment": {
  //     "comment": null,
  //     "agreement_url": "https://yadro-sunra.stg.konsol.pro/company/documents/file_url?token=eyJhbGciOiJIUzI1NiJ9.eyJhbm51bG1lbnRfaWQiOjI0NDEsImV4cCI6MTY1NTAxODcwMX0.DO60K-SeUYSq4iIFtrWaX1O0EwDyEfLu4TEikCQctUQ",
  //     "status": "accepted_by_company"
  //   },
  //   "contractor": {
  //     "id": 1,
  //     "name": "Гоголь Николай",
  //     "moi_nalog": {
  //       "status": "connected",
  //       "message": null
  //     },
  //     "payment_details": {
  //       "kind": "bank_account",
  //       "bank_account": {
  //         "account": "40817810100000000001",
  //         "rcbic": "044525999",
  //         "bank_account": "30101810845250000999",
  //         "bank_name": "ТОЧКА ПАО БАНКА \"ФК ОТКРЫТИЕ\"",
  //         "bank_code": "Otkritie",
  //         "name": "Гоголь Николай Васильевич"
  //       }
  //     },
  //     "show_no_payment_details": false
  //   },
  //   "contract": {
  //     "id": 147,
  //     "title": "Договор на оказание услуг №154",
  //     "date": "2021-03-04",
  //     "status": "signed_by_all",
  //     "created_date": "2021-03-04",
  //     "file_url": "https://api-pomnikita.stg.konsol.pro/company/documents/file_url?token=eyJhbGciOiJIUzI1NiJ9.eyJkb2N1bWVudF9pZCI6MTQ3LCJleHAiOjE2NTI0Mjg1MDF9.PxHYeip7AkUpFt_9LbkXu-RJL91mZ_x70RmllQuyxvs"
  //   },
  //   "company": {
  //     "id": 1,
  //     "name": "ООО Кухонька"
  //   },
  //   "report": {
  //     "id": 28423,
  //     "comment": "Купил самый крутой корм",
  //     "amount": 5000.0,
  //     "subtasks": [
  //       {
  //         "id": 1047,
  //         "unit_cost": "100.0",
  //         "amount": "5000.0",
  //         "quantity": 50,
  //         "title": "Покормить кота",
  //         "description": "Чем-то вкусным",
  //         "currency": "RUB"
  //       }],
  //     "attachments": []
  //   },
  //   "projects": [
  //     {
  //       "id": 62, "title": "Проект из говна и палок"
  //     }
  //   ],
  //   "reject": null,
  //   "multi_receipts": true,
  //   "show_no_payment_details": false
  // }))
]
