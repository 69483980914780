import React from 'react'
import { makeAutoObservable } from 'mobx'
import { Observer } from 'mobx-react-lite'

import { initialActionState } from 'mobx/mobx'

import SignDialog, { SignDialogProps } from 'components/SignDialog'

export type OpenSignDialogProps = Omit<SignDialogProps, 'isOpen' | 'onClose'>

class SignDialogStore {
  constructor() {
    makeAutoObservable(this, { render: false })
  }

  isOpen = false
  props?: OpenSignDialogProps
  actionState = initialActionState

  open(props: OpenSignDialogProps) {
    this.actionState = initialActionState
    this.isOpen = true
    this.props = props
  }

  onSign(payload: Object | undefined) {
    this.actionState = this.props!.onSign(payload)
    this.actionState.then(() => {
      this.isOpen = false
    })
    return this.actionState
  }

  render() {
    return (
      <Observer>
        {() =>
          this.isOpen ? (
            <SignDialog
              {...this.props!}
              children={this.props!.children}
              onSign={this.onSign.bind(this)}
              isOpen={true}
              onClose={() => {
                this.isOpen = false
              }}
            />
          ) : null
        }
      </Observer>
    )
  }
}

export default SignDialogStore
