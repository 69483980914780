import * as React from 'react'
import { ActionStateView, BackLink, Col, Layout, Row } from 'components/ui'
import { observer } from 'mobx-react-lite'

import { PageProps } from 'app.types'

import { useMobxApi } from 'mobx/MobxApiContext'
import { useUserStore } from 'stores/context'

import { TaskBlock } from './TaskBlock'
import { Entity } from 'mobx/MobxApi'
import { TaskStatusBadge } from './TaskStatusBadge'

type TaskPageProps = PageProps & {
  backLink?: { to: string; title: string }
  location?: any
}

type ScenarioTaskEntity = any
type TaskBlockType = any

export type TaskViewProps = {
  backLink: { to: string; title: string }
  task: Entity<ScenarioTaskEntity>
}

const defaultContractorBlocks: TaskBlockType[] = [
  {
    type: 'header',
  },
  {
    type: 'location',
  },
  {
    type: 'datetime',
  },
  {
    type: 'subtasks',
  },
  {
    type: 'description',
  },
  {
    type: 'actions',
  },
]

const TaskView = observer(({ task, backLink }: TaskViewProps) => {
  const currentUser = useUserStore()
  const gap = 'var(--gap-l)'

  return (
    <Layout>
      <Row style={{ marginBottom: gap }} align="center" justify="space-between">
        <BackLink style={{ margin: 0 }} to={backLink.to}>
          {backLink.title}
        </BackLink>
        <TaskStatusBadge status={task.data.status} />
      </Row>
      <Col gap={gap}>
        {(
          task.data.blocks ??
          (currentUser.kind === 'contractor' ? defaultContractorBlocks : [])
        ).map((block: any, idx: number) => (
          <TaskBlock task={task} block={block} key={block.type + idx} />
        ))}
      </Col>
    </Layout>
  )
})

export const TaskPage = observer(({ match, location, backLink }: TaskPageProps) => {
  let mobxApi = useMobxApi()

  let id = Number(match.params.id)

  const back = React.useMemo(() => {
    const isActs = location.pathname.match('/acts')

    const to = isActs ? '/acts' : '/tasks'
    const title = isActs ? 'К актам' : 'Назад к списку заданий'

    const from = location?.state?.from || false

    if (from) {
      const path = from.split('/')

      if (['acts', 'tasks'].includes(path[1]) && path[2]) {
        return {
          to: from,
          title: backLink?.title || title,
        }
      }
    }

    if (backLink) {
      return backLink
    }

    return {
      to,
      title,
    }
  }, [backLink, location.pathname, location.state])

  let [fetchTaskState] = React.useState(() =>
    mobxApi.fetch({ type: 'scenario_tasks', id })
  )

  let isDraft =
    fetchTaskState.state === 'fulfilled' && fetchTaskState.value.data.status === 'draft'
  return (
    <ActionStateView state={fetchTaskState} animate={!isDraft}>
      {(task: Entity<ScenarioTaskEntity>) => <TaskView backLink={back} task={task} />}
    </ActionStateView>
  )
})
