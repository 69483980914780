import React from 'react'
import { useEffect } from 'react'

import { observer } from 'mobx-react-lite'

import { Divider } from 'ui'

import useProjectItemStore from '../../hooks/useProjectItemStore'

import HeadRow from './components/HeadRow'
import BodyRow from './components/BodyRow'

import TaskList from '../TaskList'

export const ProjectItem = observer(() => {
  const modalStore = useProjectItemStore()

  useEffect(() => {
    modalStore.init()
  }, [])

  return modalStore.staticForm ? (
    <>
      <HeadRow />
      <Divider
        style={{
          marginTop: 25,
          marginBottom: 15,
        }}
      />
      <BodyRow />
      <TaskList items={modalStore.tasks} />
    </>
  ) : null
})

export default ProjectItem
