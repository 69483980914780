import React from 'react'
// @ts-ignore
import { useStyles } from 'floral'
import { map } from 'lodash'

import { useMediaContext } from 'App/MediaContext'

import { ListHeader, ListCell, BaseButton } from 'components/ui'
import { ReactComponent as ArrowIcon } from '@material-design-icons/svg/round/expand_more.svg'

interface Sorting {
  col: string
  direction: 'asc' | 'desc'
}

const sortableListHeaderCellStyles = (props: SortableListHeaderCellProps) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  arrow: {
    fill: 'var(--color-icon)',
    transform: `rotate(${props.direction === 'asc' ? 0 : 180}deg)`,
    flexShrink: 0,
    width: 20,
    height: 20,
  },
  button: {
    borderRadius: 6,
    fontWeight: props.direction !== undefined ? '600' : 'normal',
    display: 'flex',
    alignItems: 'center',
    textTransform: 'uppercase',
    textAlign: 'start',
  },
})

interface SortableListHeaderCellProps {
  name: string
  col: SortableListCol
  direction: 'asc' | 'desc' | undefined
  onTap: () => void
}

const SortableListHeaderCell = (props: SortableListHeaderCellProps) => {
  let { col, direction, onTap } = props
  let styles = useStyles(sortableListHeaderCellStyles, [props])
  return (
    <ListCell col={props.name} style={{ ...styles.root, ...col.props?.style }}>
      {col.sorting ? (
        <BaseButton onTap={onTap} style={styles.button}>
          {col.title}
          {direction !== undefined && <ArrowIcon style={styles.arrow} />}
        </BaseButton>
      ) : (
        col.title
      )}
    </ListCell>
  )
}

interface SortableListCol {
  title: React.ReactNode
  sorting?: string
  props?: React.ComponentProps<typeof ListCell>
}

interface SortableListHeaderProps
  extends Omit<React.ComponentProps<typeof ListHeader>, 'cols' | 'onChange'> {
  cols: { [key: string]: SortableListCol }
  sorting?: Sorting
  showMobile?: boolean
  onChange: (sorting: Sorting) => void
}

const SortableListHeader = (props: SortableListHeaderProps) => {
  let { cols, sorting, onChange, ...rest } = props
  let { isMobile } = useMediaContext()
  if (isMobile && !props.showMobile) return null
  return (
    <ListHeader {...rest}>
      {map(cols, (col, name) => (
        <SortableListHeaderCell
          name={name}
          col={col}
          direction={
            sorting && sorting.col === col.sorting ? sorting.direction : undefined
          }
          onTap={() => {
            if (sorting && sorting.col === col.sorting) {
              onChange({
                ...sorting,
                direction: sorting.direction === 'asc' ? 'desc' : 'asc',
              })
            } else {
              onChange({ col: col.sorting!, direction: 'asc' })
            }
          }}
        />
      ))}
    </ListHeader>
  )
}

export default SortableListHeader