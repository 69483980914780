import React, { forwardRef } from 'react'
//@ts-ignore
import { useStyles } from 'floral'
import cx from 'clsx'

import { FloralProps } from '../floral.types'
import c from './styles.module.css'

interface FlexProps extends FloralProps, Omit<React.HTMLProps<HTMLDivElement>, 'wrap'> {
  direction?: 'row' | 'column' | 'row-reverse' | 'column-reverse'
  wrap?: boolean
  align?: 'normal' | 'start' | 'end' | 'center' | 'stretch' | 'baseline'
  justify?:
    | 'normal'
    | 'start'
    | 'end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
    | 'stretch'
  gap?: string | number
  // rowGap?: string | number
  className?: string
  children: React.ReactNode
}

const justifyMap: any = {
  end: 'flex-end',
  start: 'flex-start',
}

let flexStyles = ({ direction, wrap, align, justify, gap }: FlexProps) => ({
  root: {
    display: 'flex',
    flexDirection: direction,
    flexWrap: wrap ? 'wrap' : 'no-wrap',
    alignItems: align,
    justifyContent: justifyMap[justify!] || justify,
    // marginTop: typeof rowGap === 'number' ? -rowGap : `-${rowGap}`,
  },
  /*
  Flex uses wrapper, so when flex nested in flex, it does not override gap:
  
  Wrong gap:
    .flex { --gap: <value> }
      <child> { margin-top: var(--gap }
      .flex { --gap: <new-value>; margin-top: var(--gap) } <- has invalid margin
      
  Correct:
    .flex
      .wrapper { --gap: <value> }
      <child> { margin-top: var(--gap }
      .flex { margin-top: var(--gap) }
        .wrapper { --gap: <new-value>; }
  */
  wrapper: {
    display: 'contents',
    '--flex-gap': gap,
    // '--flex-row-gap': rowGap
  },
})

const Flex = forwardRef((props: FlexProps, ref) => {
  let {
    direction: _direction,
    wrap: _wrap,
    align: _align,
    justify: _justify,
    gap: _gap,
    style: _style,
    ...restProps
  } = props
  let { styles: _styles, className, children, ...elemProps } = restProps
  let s = useStyles(flexStyles, [props])
  return (
    <div
      // @ts-ignore
      ref={ref}
      style={s.root}
      className={cx(props.direction === 'row' ? c.flexRow : c.flexCol, className)}
      {...elemProps}
    >
      <div className={c.wrapper} style={s.wrapper}>
        {children}
      </div>
    </div>
  )
})

Flex.defaultProps = {
  direction: 'row',
  wrap: false,
  align: 'normal',
  justify: 'normal',
  gap: 0,
  // rowGap: 0
}

const Col = forwardRef((props: Omit<FlexProps, 'direction'>, ref) => (
  <Flex {...props} ref={ref} direction="column" />
))

const Row = forwardRef((props: Omit<FlexProps, 'direction'>, ref) => (
  <Flex {...props} ref={ref} direction="row" />
))

export default Flex
export { Col, Row }
