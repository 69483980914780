import React from 'react'
import { observer } from 'mobx-react-lite'

import { Entity } from 'mobx/mobx'
import { DocumentEntity } from 'entities'
import { Status } from 'components/ui'
import { StatusMap } from 'components/ui/StatusBadge'
import { ComponentProps } from 'app.types'

export const documentStatusMap: StatusMap = {
  created: { text: 'Не подписан' },
  signed_by_legal_entity: { text: 'Подписан компанией' },
  signed_by_contractor: { text: 'Подписан исполнителем' },
  signed_by_all: { text: 'Подписан', color: 'green' },
}

export const documentKinds = {
  document: 'Документ',
  contract: 'Договор',
}

export const documentWordForms = { one: 'документ', two: 'документа', many: 'документов' }

export const documentEvents: { [key: string]: string } = {
  signed_by_contractor: 'Подписан исполнителем',
  signed_by_legal_entity: 'Подписан заказчиком',
  upload_by_legal_entity: 'Загружен заказчиком',
  created: 'Создан',
}

export const DocumentStatusBadge = observer(
  ({ document, className }: { document: Entity<DocumentEntity> } & ComponentProps) => {
    let { status, require_sign } = document.data
    let statusMapItem = { ...documentStatusMap[document.data.status] }
    let requireSignText
    if (status !== 'signed_by_all') {
      if (require_sign === 'contractor') requireSignText = ' (только подпись исполнителя)'
      if (require_sign === 'company') requireSignText = ' (только подпись компании)'
    }
    if (requireSignText) statusMapItem.text += requireSignText
    return (
      <Status
        className={className}
        color={statusMapItem.color}
        children={statusMapItem.text}
      />
    )
  }
)
