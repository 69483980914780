import React, { useState, useMemo } from 'react'
import { useLocation, Redirect } from 'react-router-dom'
import { fetchApi } from 'api'

import { Placeholder } from 'components/ui'

const TinkoffComplete = () => {
  let query = new URLSearchParams(useLocation().search)
  let [isCompleted, setIsCompleted] = useState(false)
  useMemo(() => {
    let data = {
      state: query.get('state'),
      code: query.get('code'),
    }
    fetchApi({ url: '/banks/tinkoff/complete', method: 'POST', data })
      .then(console.log)
      .then(() => setIsCompleted(true))
  }, [])
  return isCompleted ? (
    <Redirect to="/company#tinkoff" />
  ) : (
    <Placeholder>Ждите...</Placeholder>
  )
}

export default TinkoffComplete
