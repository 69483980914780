import React, { useMemo, createContext, useRef } from 'react'
import FocusLock from 'react-focus-lock'
import { useKey, useClickAway } from 'react-use'
import { RemoveScroll } from 'react-remove-scroll'
import { SpringConfig } from 'react-spring'

import { useStyles, StyleProps, StylesMap } from 'ui/styled'
import { Layer } from 'ui/layers'
import {
  OpenAnimation,
  useAnimatedValue,
  springConfigs,
  animationFunctions,
} from 'ui/animation'
import type { AnimationFunction } from 'ui/animation/functions'

type ModalStyleProps = [ModalProps, boolean]

export interface ModalProps extends StyleProps<ModalStyleProps> {
  /** Content of the modal */
  children: (close: () => void) => React.ReactNode | React.ReactNode

  /** Whether the modal is open */
  isOpen?: boolean

  /** Function that is called when the modal requests to close */
  onClose: () => void

  /** Ref to the element that will get focus on opening the modal */
  initialFocusRef?: any

  /** Whether the modal should close on click on overlay */
  closeOnOverlayClick?: boolean

  /** Whether the modal should close on pressing the Esc key */
  closeOnEsc?: boolean

  /** Vertically centers the modal */
  isCentered?: boolean

  /** CSS value for the width of the modal window */
  width: string | number

  /** Function for hide and show animation */
  animation?: AnimationFunction

  /** Config for `react-spring` animation */
  springConfig?: SpringConfig
}

interface ModalContextProps {
  close: () => void
}

const ModalContext = createContext<ModalContextProps | undefined>(undefined)

const modalStyles = (props: ModalProps, isOpen: boolean): StylesMap => {
  const container: React.CSSProperties = {
    position: 'fixed',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: props.isCentered ? 'center' : 'flex-start',
    boxSizing: 'border-box',
    overflowY: 'auto',
    pointerEvents: isOpen ? 'all' : 'none',
  }
  const window: React.CSSProperties = {
    position: 'relative',
    background: 'white',
    maxWidth: '100%',
    width: props.width,
    pointerEvents: 'all',
  }
  const overlay: React.CSSProperties = {
    background: 'rgba(0,0,0,.5)',
    userSelect: 'none',
    pointerEvents: isOpen ? 'all' : 'none',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  }
  return { container, window, overlay }
}

const fade = animationFunctions.fade()

const modalDefaultProps = {
  isOpen: false,
  isCentered: false,
  closeOnEsc: true,
  width: 800,
  animation: fade,
  springConfig: springConfigs.normal,
}

const Modal = (_props: ModalProps) => {
  const props = _props as ModalProps & typeof modalDefaultProps
  const {
    isOpen,
    children,
    closeOnOverlayClick,
    closeOnEsc,
    onClose,
    animation,
    springConfig,
  } = props
  const styles = useStyles(modalStyles, [props, isOpen])
  const [openValue, isRest] = useAnimatedValue(isOpen ? 1 : 0, { config: springConfig })
  const context = useMemo(() => ({ close: onClose }), [])
  const isActive = isOpen || !isRest
  useKey('Escape', () => {
    if (closeOnEsc) onClose()
  })
  const windowRef = useRef(null)
  useClickAway(windowRef, () => {
    if (closeOnOverlayClick) onClose()
  })
  const modalChildren = useMemo(() => {
    if (isActive) {
      return typeof children === 'function' ? children(onClose) : children
    } else {
      return null
    }
  }, [children, isActive])

  return (
    <>
      <Layer type="modal" isActive={isActive}>
        <OpenAnimation animation={fade} openValue={openValue} style={styles.overlay} />
        <RemoveScroll>
          <div style={styles.container}>
            <OpenAnimation
              animation={animation}
              openValue={openValue}
              style={styles.window}
            >
              <div ref={windowRef}>
                <ModalContext.Provider value={context}>
                  <FocusLock>{modalChildren}</FocusLock>
                </ModalContext.Provider>
              </div>
            </OpenAnimation>
          </div>
        </RemoveScroll>
      </Layer>
    </>
  )
}

Modal.defaultProps = modalDefaultProps

export { Modal }
