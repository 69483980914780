import React, { useEffect } from 'react'

import { Flex } from 'ui'

import RiskLabel from './RiskLabel'
import IndicatorList from '../../components/IndicatorList'

import useAnalytics from '../../context/AnalyticsProvider'
import { ActionStateView, Placeholder } from 'components/ui'
import { observer } from 'mobx-react-lite'

export const CompanyView = observer(() => {
  const analyticsManager = useAnalytics()

  useEffect(() => {
    analyticsManager.fetchData('company')
    return () => analyticsManager.clearData()
  }, [])

  return (
    <Flex direction="col" gap="25px">
      <RiskLabel />
      {!!analyticsManager.fetchDataTask && (
        <ActionStateView state={analyticsManager.fetchDataTask}>
          {(data) => (
            <>
              {data ? (
                <IndicatorList data={data} />
              ) : (
                <Placeholder>Произошла ошибка</Placeholder>
              )}
            </>
          )}
        </ActionStateView>
      )}
    </Flex>
  )
})

export default CompanyView
