import React, { createElement } from 'react'

interface ProviderProps {
  provider: any
  props?: any
}

const renderProviders = (
  providers: ProviderProps[],
  children: React.ReactNode
): React.ReactElement => {
  let [first, ...rest] = providers
  return createElement(
    first.provider,
    first.props,
    rest.length > 0 ? renderProviders(rest, children) : children
  )
}

interface MultiProviderProps {
  providers: ProviderProps[]
  children: React.ReactNode
}

const MultiProvider = ({ providers, children }: MultiProviderProps) =>
  renderProviders(providers, children)

export default MultiProvider
