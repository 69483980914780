// @ts-ignore
import ContentDisposition from 'content-disposition'

import Cookies from 'js-cookie'

import type { Data } from 'mobx/types'

export type HttpMethod = 'GET' | 'POST' | 'PATCH' | 'PUT' | 'DELETE'

export interface FetchParams {
  url: string
  method?: HttpMethod
  headers?: { [key: string]: any }
  data?: { [key: string]: any }
  signal?: AbortSignal
  as?: 'json' | 'file'
}

export type FetchErrorKind = 'fetch' | 'abort' | 'http' | 'json' | 'application'

const fetchErrorMessages: { [key: string]: string } = {
  fetch: 'Проверьте соединение с интернетом и попробуйте снова.',
  abort: 'Запрос был отменён',
  http: 'Ошибка на сервере.',
  json: 'Ошибка на сервере.',
}

class FetchError extends Error {
  originalError: any
  kind: FetchErrorKind
  data: any
  response?: Response

  constructor({
    kind,
    originalError,
    data,
    response,
    message,
  }: {
    kind: FetchErrorKind
    originalError?: any
    data?: any
    response?: Response
    message?: string
  }) {
    super(message || fetchErrorMessages[kind])
    this.name = 'FetchError'
    this.kind = kind
    this.originalError = originalError
    this.data = data
    this.response = response
  }

  toString() {
    return this.message
  }
}

const isAbortError = (e: Error) => e instanceof DOMException && e.name === 'AbortError'

const fetchData = async (params: FetchParams) => {
  let { url, method = 'GET', headers, data, signal, as = 'json' } = params
  let body
  let reqHeaders: { [key: string]: string } = {
    Accept: 'application/json, text/plain, */*',
    ...(Cookies.get('x-api-token') && {
      'x-api-token': Cookies.get('x-api-token'),
    }),
    ...headers,
  }
  let reqUrl = url

  if (method !== 'GET' && data) {
    if (data instanceof FormData) {
      body = data
      // content-type is set automatically by browser
    } else {
      body = JSON.stringify(data)
      reqHeaders['Content-type'] = 'application/json;charset=UTF-8'
    }
  }

  if (method === 'GET' && data) reqUrl += '?' + new URLSearchParams(data).toString()

  let req = fetch(reqUrl, {
    method,
    headers: reqHeaders,
    body,
    credentials: 'include',
    signal,
  })

  let response
  try {
    response = await req
  } catch (e) {
    if (isAbortError(e as Error)) {
      throw new FetchError({ kind: 'abort', originalError: e })
    }
    throw new FetchError({ kind: 'fetch', originalError: e, response })
  }

  if (response.headers.has('x-api-token')) {
    const token = response.headers.get('x-api-token')!
    Cookies.set('x-api-token', token, {
      expires: 365,
      secure: true,
      sameSite: 'strict',
    })
  }

  if (as === 'json') {
    let json: Data | undefined
    let contentType = response.headers.get('content-type')
    if (contentType && contentType.indexOf('application/json') !== -1) {
      try {
        json = await response.json()
      } catch (e) {
        throw new FetchError({ kind: 'json', originalError: e, response })
      }
    }
    if (!response.ok) throw new FetchError({ kind: 'http', data: json, response })
    return json || {}
  } else {
    // as === 'file'
    if (!response.ok) throw new FetchError({ kind: 'http', data: {}, response })
    let blob = await response.blob()
    let disposition = response.headers.get('Content-Disposition')
    let filename = 'unknown'
    if (disposition) {
      try {
        let parsed = ContentDisposition.parse(disposition)
        if (parsed.parameters.filename) filename = parsed.parameters.filename
      } catch (e) {
        console.log('[fetchData] cannot parse content disposition:')
        console.log(e)
      }
    }
    return { blob, filename }
  }
}

const fetchDataWithLog = (params: FetchParams) => {
  let req = fetchData(params)
  req.catch((error) => {
    if (error.kind === 'application') return
    let _message = 'FetchError'
    if (error.kind === 'http') {
      _message = `FetchError: status ${error.response?.status} at ${error.response?.url}`
    } else if (error.kind === 'fetch') {
      _message = `FetchError: couldn't fetch`
    } else if (error.kind === 'json') {
      _message = `FetchError: invalid json at ${error.response?.url}`
    }
  })
  return req
}

const fetchJson = (params: FetchParams) => fetchDataWithLog({ ...params, as: 'json' })

const fetchFile = (params: FetchParams) => fetchDataWithLog({ ...params, as: 'file' })

export { fetchJson, fetchFile, FetchError }
export default fetchDataWithLog
