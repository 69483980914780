import React, { useState, useMemo, useEffect } from 'react'
import { without, map, sum } from 'lodash'
import { observer } from 'mobx-react-lite'
import { Heading } from '@chakra-ui/react'

import { Collection, useMobxApi } from 'mobx/mobx'
import { SubtaskTemplateEntity } from 'entities'

import { Button, Flex } from 'components/ui'
import { Amount } from 'components'

import type { Subtask } from './types'
import SubtaskRow from './SubTaskRow'
import { ReactComponent as AddSubTaskIcon } from './add-subtask.svg'
import { useUserStore } from 'stores/context'

import css from './styles.module.css'

let randomId = () => Math.floor(Math.random() * Math.floor(Number.MAX_SAFE_INTEGER))

interface SubtaskListProps {
  subtasks: Subtask[]
  noTasksText?: React.ReactNode
  mode: 'create' | 'report' | 'view'
  canChangeCost?: boolean // create | report
  origSubtasks?: Subtask[] // report | view
  onChangeSubtasks?: (subtasks: Subtask[]) => void // create | report
  templates?: Collection<SubtaskTemplateEntity> // create
  onAmountChanged?: (totalAmount: number) => void
}

const SubtaskList = (props: SubtaskListProps) => {
  const {
    subtasks,
    mode,
    origSubtasks,
    onChangeSubtasks,
    templates,
    canChangeCost,
    onAmountChanged = () => { }
  } = props

  const currentUser = useUserStore()

  const [$subtasks, $setSubtasks] = useState<Subtask[]>([])
  const [unitOptions, setUnitOptions] = useState<any>([])

  useEffect(() => {
    $setSubtasks(subtasks)
  }, [subtasks])

  let mobxApi = useMobxApi()

  const isCompany = currentUser.kind === 'company'

  useEffect(() => {
    const getUnits = async () => await mobxApi.fetch({ type: 'subtasks/units' })
    if (mode === 'create' && isCompany) {
      getUnits().then(({ items }) => {
        setUnitOptions(items.map((unit: any) => ({ value: unit.id, label: unit.data.title })))
      })
    }
  }, [])

  const [showBlankRow, setShowBlankRow] = useState(Object.keys($subtasks).length === 0)
  useEffect(() => {
    if ($subtasks.length === 0) setShowBlankRow(true)
  }, [$subtasks.length])

  const totalAmount = useMemo(
    () => {
      const result = sum(map($subtasks, (t) => t.quantity * parseFloat(t.unit_cost)))
      onAmountChanged(result)
      return result
    },
    [$subtasks]
  )
  const currency: any = currentUser.company?.data.currency || $subtasks[0]?.currency

  const onDelete = (subtask: Subtask) =>
    onChangeSubtasks && onChangeSubtasks(without($subtasks, subtask))

  const onSelectTemplate = (templateId: number) => {
    setShowBlankRow(false)
    let id = randomId()
    let { unit, ...rest } = templates!.map[templateId].data
    onChangeSubtasks &&
      onChangeSubtasks([
        ...$subtasks,
        { ...rest, id, quantity: 1, unit, unit_id: unit.id },
      ])
  }

  const rows = $subtasks.map((subtask, i) => {
    let origSubtask = subtask.id ? origSubtasks?.find((s) => s.id === subtask.id) : null
    if (mode === 'view' && subtask.quantity === 0) return null
    return (
      <SubtaskRow
        key={subtask.id}
        origQuantity={origSubtask?.quantity}
        origUnitCost={origSubtask?.unit_cost}
        subtask={subtask}
        canDelete={mode === 'create'}
        onDelete={() => onDelete(subtask)}
        canChangeCost={canChangeCost}
        canChangeQuantity={mode === 'create'}
        onChange={(changed: Subtask) => {
          let updated = [...$subtasks]
          updated[i] = changed
          onChangeSubtasks && onChangeSubtasks(updated)
        }}
        isNew={subtask.isNew}
        units={unitOptions}
      />
    )
  })

  let addButton
  if (mode === 'create') {
    if (showBlankRow) {
      rows.push(
        <SubtaskRow
          key="blank"
          isBlank={true}
          templates={templates}
          onSelectTemplate={onSelectTemplate}
          onEnter={(value) => {
            const subtasksUpdate = [...$subtasks, {
              quantity: 1,
              title: value,
              id: randomId(),
              description: value,
              unit_id: unitOptions[0]?.value,
              unit: {
                id: unitOptions[0]?.value,
                title: unitOptions[0]?.label,
              },
              unit_cost: '1000',
              currency,
              isNew: true
            }]
            $setSubtasks(subtasksUpdate)
            // @ts-ignore
            onChangeSubtasks(subtasksUpdate)
            setShowBlankRow(false)
          }}
        />
      )
    }
    addButton = (
      <div>
        <Button
          design="normal"
          icon={(
            <div className={css.subTaskIcon}>
              <AddSubTaskIcon />
            </div>
          )}
          onTap={() => setShowBlankRow(true)}
          isDisabled={showBlankRow}
        >
          Добавить работу
        </Button>
      </div>
    )
  }

  return (
    <Flex direction="column" gap="2rem">
      <Heading as="h3" style={{ fontWeight: 'normal' }} fontSize="1.5rem">
        {totalAmount > 0 ? (
          <>
            Общая стоимость{' '}
            <Amount
              value={{ value: String(totalAmount), currency }}
              style={{ fontWeight: 600 }}
            />
          </>
        ) : (
          'Работы не заполнены'
        )}
      </Heading>
      <div>{rows}</div>
      {addButton}
    </Flex>
  )
}

SubtaskList.defaultProps = {
  canEdit: false,
  canChangeQuantity: false,
}

export default observer(SubtaskList)
