import React from 'react'
import { observer } from 'mobx-react-lite'

import { BaseButton, Flex, Col, Row, Button, Checkbox } from 'components/ui'

import numForm from 'utils/numForm'

import SelectedItemsStore from './SelectedItemsStore'
import { useMediaContext } from 'App/MediaContext'
import { ComponentProps } from 'app.types'
import clsx from 'clsx'
import styles from './SortFilterPanel.module.css'

export type SelectedItemsViewProps<T> = ComponentProps & {
  store: SelectedItemsStore<T>
  wordForms: any
  children?: React.ReactNode
}

export const SelectedItemsCount = observer(<T,>(props: SelectedItemsViewProps<T>) => {
  const { isMobile } = useMediaContext()
  let { store, wordForms, children, className, ...rest } = props
  let { selectedCount, count, isPageSelected, isAllSelected, pages } = store

  if (isMobile) {
    return (
      <Row
        align="center"
        gap="10px"
        className={clsx(className, styles.countRow)}
        {...rest}
      >
        {!store.isSelectable ? (
          <p>
            Всего: <strong>{numForm(count, wordForms)}</strong>
          </p>
        ) : (
          <>
            {isPageSelected && !isAllSelected && pages > 1 ? (
              <>
                <div>
                  Выбраны все
                  <wbr /> {numForm(selectedCount, wordForms)}
                  <wbr /> на странице.
                </div>
                <Button
                  onClick={() => {
                    store.selectAll()
                  }}
                  design="text"
                  size="xs"
                >
                  Выбрать все {numForm(count, wordForms)}
                </Button>
              </>
            ) : (
              <>
                <p>
                  Выбрано: <strong>{selectedCount}</strong> из {numForm(count, wordForms)}
                </p>
              </>
            )}
            {store.selectedCount < 1 && (
              <Button
                onClick={() => {
                  store.unselectAll()
                  store.setSelectable(false)
                }}
                design="text"
                size="xs"
              >
                Отменить
              </Button>
            )}
          </>
        )}
      </Row>
    )
  }

  return (
    <Col gap="var(--gap-s)" {...rest}>
      <Flex gap="var(--gap-m)" align="center" style={{ height: '2.5rem' }}>
        <div style={{ fontSize: '1.5rem', fontWeight: 600 }}>
          {selectedCount > 0 ? (
            <>
              {numForm(selectedCount, wordForms)}
              <span style={{ color: 'var(--color-secondary)' }}> из {count}</span>
            </>
          ) : count > 0 ? (
            numForm(count, wordForms)
          ) : null}
        </div>
        {children}
      </Flex>
    </Col>
  )
})

export const SelectAllItems = observer(<T,>(props: SelectedItemsViewProps<T>) => {
  const { isMobile } = useMediaContext()
  let { store, wordForms, ...rest } = props
  let { selectedCount, count, pages, isAllSelected, isPageSelected } = store

  if (isMobile) {
    return store.isSelectable ? (
      <Row align="start" {...rest}>
        <Button
          design="text"
          size="xs"
          onTap={() => {
            store.toggleSelectPage()
          }}
          icon={
            <Checkbox
              value={store.isPageSelected}
              isIndeterminate={!store.isPageSelected && store.selectedItems.size > 0}
            />
          }
        >
          {store.selectedItems.size > 0 ? 'Cнять выделение' : 'Выбрать все'}
        </Button>
      </Row>
    ) : (
      <Row align="start" gap="10px" {...rest}>
        <Button
          onClick={() => {
            store.setSelectable(true)
          }}
          design="text"
          size="xs"
        >
          Выбрать
        </Button>
      </Row>
    )
  }

  return (
    <Flex gap=".5rem" style={{ height: '1.5rem' }} {...rest}>
      {isPageSelected && !isAllSelected && pages > 1 && (
        <>
          <div>Выбраны все {numForm(selectedCount, wordForms)} на странице.</div>
          <BaseButton
            style={{ color: 'var(--color-active)', cursor: 'pointer' }}
            onTap={() => store.selectAll()}
          >
            Выбрать все {numForm(count, wordForms)}
          </BaseButton>
        </>
      )}
    </Flex>
  )
})

type SelectedItemsActionsPanelProps<T> = ComponentProps & {
  store: SelectedItemsStore<T>
  actions?: React.ReactNode
}

export const SelectedItemsActionsPanel = observer(
  <T,>({ store, actions, className }: SelectedItemsActionsPanelProps<T>) => {
    const { isMobile } = useMediaContext()
    const { selectedCount } = store

    if (selectedCount < 1 || !actions) return null

    return (
      <Flex
        className={clsx(styles.actionsPanel, className)}
        gap={isMobile ? '.325rem' : '1rem'}
        justify={isMobile ? 'space-between' : 'start'}
        align="center"
      >
        {actions}
      </Flex>
    )
  }
)

const SelectedItemsView = observer(<T,>(props: SelectedItemsViewProps<T>) => {
  return (
    <>
      <SelectedItemsCount {...props} />
      <SelectAllItems {...props} />
    </>
  )
})

export default SelectedItemsView
