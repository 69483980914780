import React from 'react'
import { observer } from 'mobx-react-lite'
import { useMediaContext } from 'App/MediaContext'

import { Flex, Modal } from 'ui'
import ImageFnsQr from 'assets/fns-qr.svg'

import css from './styles.module.css'

const DownloadMoiNalogModal = observer(({ onClose }: { onClose: () => void }) => {
  let { isMobile } = useMediaContext()

  let content = (
    <Flex gap="2rem" direction="col">
      <b>Мой налог — приложение налоговой службы для самозанятых граждан.</b>
      <div>
        Если вы еще не установили приложение, скачайте его и авторизуйтесь с номером
        телефона, который указывали при регистрации самозанятого.
      </div>
      <div className={css.fnsContent}>
        {!isMobile ? (
          <Flex gap="20px">
            <div className={css.fnsQr}>
              <img src={ImageFnsQr} alt="" />
            </div>
            <div>
              Считайте QR-код на&nbsp;вашем мобильном устройсте для установки приложения.
            </div>
          </Flex>
        ) : (
          <Flex direction="col" align="center">
            <a
              className={css.link}
              href="https://onelink.to/r5k49k"
              target="_blank"
              rel="noopener noreferrer"
            >
              Скачать приложение
            </a>
          </Flex>
        )}
      </div>
    </Flex>
  )

  return (
    <Modal isOpen={true} onClose={onClose} width={isMobile ? '92%' : 600}>
      {content}
    </Modal>
  )
})

export default DownloadMoiNalogModal
