import React from 'react'
import cl from 'clsx'
import { Button, ButtonVariant, Divider, H1 } from 'ui-kit'
import { ReactComponent as BackArrowIcon } from 'assets/back-arrow.svg'

import css from './styles.module.css'
import { useHistory } from 'react-router-dom'

export type PageHeaderProps = {
  title: string
  backLinkUrl?: string
  action?: React.ReactNode
  className?: string
}

const PageHeader: React.FunctionComponent<PageHeaderProps> = ({
  title,
  backLinkUrl,
  action,
  className,
}) => {
  const history = useHistory()

  const handleClick = () => {
    if (backLinkUrl) {
      history.push(backLinkUrl)
    }
  }

  return (
    <>
      <div className={cl(css.header, className && className)}>
        <div className={css.linkAndTitleWrapper}>
          {backLinkUrl && (
            <Button
              variant={ButtonVariant.Quaternary}
              onClick={handleClick}
              className={css.backLinkButton}
            >
              <BackArrowIcon className={css.backLinkIcon} />
            </Button>
          )}
          <H1>{title}</H1>
        </div>
        {action && action}
      </div>
      <Divider className={css.divider} />
    </>
  )
}

export default PageHeader
