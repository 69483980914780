import React from 'react'
//@ts-ignore
import { useStyles } from 'floral'

import { useMediaContext } from 'App/MediaContext'
import Flex from './Flex'
import { FloralProps } from './floral.types'

interface BannerProps extends FloralProps {
  children: React.ReactNode
  icon?: React.ReactNode
  buttons?: React.ReactNode
  kind?: 'info' | 'error'
}

const colors = {
  info: '#efebff',
  error: '#fecccc',
}

const bannerStyles = (props: BannerProps) => {
  let root = {
    padding: '1.5rem 1rem',
    background: colors[props.kind!],
  }
  return { root }
}

const Banner = (props: BannerProps) => {
  let { icon, children, buttons } = props
  let styles = useStyles(bannerStyles, [props])
  let { isMobile } = useMediaContext()
  let content = isMobile ? (
    <Flex direction="column" gap="1rem">
      <div>{children}</div>
      <div>{buttons}</div>
    </Flex>
  ) : (
    <Flex gap="1.5rem" align="center">
      <div>{children}</div>
      <div>{buttons}</div>
    </Flex>
  )
  return <div style={styles.root}>
    <Flex gap="1rem">
        {icon && <div>{icon}</div>}
        <div>{content}</div>
    </Flex>
  </div>
}

Banner.defaultProps = {
  kind: 'info',
}

export default Banner
