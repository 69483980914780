import React, { useEffect, useMemo, useState } from 'react'
// import clsx from 'clsx'

import { useMediaContext } from 'App/MediaContext'

import { Appear, Text, Link, useTheme, Button, Flex, Modal, H2 } from 'ui'

import PhoneStep from './components/PhoneStep'

// import Tabs from './components/Tabs'
import TabsContent, { TOption } from './components/TabsContent'

import PhoneIcon from './assets/phone-icon.svg'
// import TinkoffLogo from './assets/tinkoff-logo.svg'
// import SberLogo from './assets/sber-logo.svg'
import PopupImage from './assets/popup-img.png'

// import { useMixpanel } from 'components/MixPanel'

import css from './styles.module.css'

interface IAuthSwitcherProps {
  permalink: string
  query: Object
  onComplete: (phone: string) => void
}

const AuthSwitcher = ({ permalink, query, onComplete }: IAuthSwitcherProps) => {
  const { isMobile } = useMediaContext()
  const { config } = useTheme()
  // const mixpanel = useMixpanel()

  const options: TOption[] = [
    {
      id: 'phone',
      tab: (
        <Flex gap="8px">
          <img src={PhoneIcon} alt="Телефон" />
          <div className={css.tabPhone}>
            По&nbsp;номеру
            <br />
            телефона
          </div>
        </Flex>
      ),
      // title: 'Войти по номеру телефона',
      list: [
        'Займет 10-30 минут',
        'Нужно заполнить контактные данные',
        <>
          Потребуется загрузка паспорта и&nbsp;селфи с&nbsp;паспортом для идентификации
        </>,
      ],
      controls: <PhoneStep permalink={permalink} query={query} onComplete={onComplete} />,
    },
    // {
    //   id: 'tiknoff',
    //   tab: <img src={TinkoffLogo} alt="tinkoff" />,
    //   title: 'Войти через Тинькофф',
    //   list: [
    //     <>Займет 1&nbsp;минуту</>,
    //     <>Не&nbsp;нужно загружать фото паспорта</>,
    //     <>Не&nbsp;нужно загружать селфи с&nbsp;паспортом</>,
    //   ],
    //   controls: (
    //     <Button
    //       className={clsx(css.tinkoffSubmit, isMobile && css.mobileSubmitButton)}
    //       onClick={() => {
    //         setIsModalOpen(true)
    //         // @ts-ignore
    //         mixpanel.track('register_via_tinkoff_id')
    //       }}
    //     >
    //       {isMobile ? <img src={TinkoffLogo} alt="tinkoff" /> : 'Продолжить'}
    //     </Button>
    //   ),
    //   className: css.tinkoff,
    // },
    // {
    //   id: 'sber',
    //   tab: <img src={SberLogo} alt="Сбер ID" />,
    //   title: 'Войти через Сбер ID',
    //   list: [
    //     <>Займет 1&nbsp;минуту</>,
    //     <>Не&nbsp;нужно загружать фото паспорта</>,
    //     <>Не&nbsp;нужно загружать селфи с&nbsp;паспортом</>,
    //   ],
    //   controls: (
    //     <Button
    //       className={clsx(css.sberSubmit, isMobile && css.mobileSubmitButton)}
    //       onClick={() => {
    //         setIsModalOpen(true)
    //         // @ts-ignore
    //         mixpanel.track('register_via_sber_id')
    //       }}
    //     >
    //       {isMobile ? <img src={SberLogo} alt="Сбер ID" /> : 'Продолжить'}
    //     </Button>
    //   ),
    //   className: css.sber,
    // },
  ]

  const [currentTabId, setCurrentTabId] = useState(options[0].id)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const tabContentData: any = useMemo(
    () => options.find((item: TOption) => item.id === currentTabId),
    [currentTabId]
  )

  // при ресайзе, сбрасываем вкладку на первую,
  // чтобы если выбрана не первая вкладка, при возврате контент был корректный
  useEffect(() => {
    if (isMobile) setCurrentTabId(options[0].id)
  }, [isMobile])

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        isCentered
        width="340px"
      >
        <Appear delay={100} animation={Appear.presets.slideUp}>
          <H2>Скоро все будет</H2>
        </Appear>
        <Appear delay={200} animation={Appear.presets.slideUp}>
          <div style={{ margin: '22px 0 16px' }}>
            <img src={PopupImage} alt="" />
          </div>
        </Appear>
        <Appear delay={300} animation={Appear.presets.slideUp}>
          <Text size="m">
            Сейчас мы&nbsp;проверяем востребованность функционала, но&nbsp;совсем скоро
            все будет готово. Благодарим за&nbsp;понимание.
          </Text>
        </Appear>
        <Appear delay={400} animation={Appear.presets.slideUp}>
          <Button
            style={{ width: '100%', marginTop: '24px' }}
            onClick={() => setIsModalOpen(false)}
          >
            Хорошо
          </Button>
        </Appear>
      </Modal>
      <Appear delay={100} animation={Appear.presets.slideUp}>
        <Flex direction="col" className={css.wrapper}>
          {/* {!isMobile && (
            <>
              <Tabs items={options} onChange={setCurrentTabId} />
            </>
          )} */}
          <TabsContent {...tabContentData} />
        </Flex>
        {/* {isMobile && (
          <Flex direction="col" className={css.wrapper} style={{ marginTop: '16px' }}>
            <TabsContent
              title="Войти через банк"
              list={options[1].list}
              controls={
                <>
                  {options[1].controls}
                  {options[2].controls}
                </>
              }
            />
          </Flex>
        )} */}
      </Appear>
      <div className={css.policy}>
        <Appear delay={200} animation={Appear.presets.slideUp}>
          <Text color="secondary" size={isMobile ? 'm' : 's'}>
            By&nbsp;clicking "Get Started", I&nbsp;confirm that I&nbsp;read and agree with{' '}
            <Link target="_blank" href={config.links.agreement}>
              Restaff Terms of&nbsp;Service
            </Link>{' '}
            and&nbsp;
            <Link target="_blank" href={config.links.policy}>
              Privacy Policy
            </Link>
          </Text>
        </Appear>
      </div>
    </>
  )
}

export default AuthSwitcher
