import React from 'react'
import { observer } from 'mobx-react-lite'
import { Tooltip } from '@chakra-ui/react'

import { Checkbox as UiCheckbox } from 'components/ui'

import SelectableStore from './SelectableStore'

const SelectAllCheckbox = observer(({ store, Checkbox = UiCheckbox }: {
  store: SelectableStore<any>,
  Checkbox?: React.ComponentType<any>,
}) => (
  <Tooltip
    label={store.selectedItems.size > 0 ? 'Снять выделение' : 'Выделить все'}
    aria-label="button"
    placement="top"
    style={{ zIndex: 100 }}
  >
    <div>
      <Checkbox
        value={store.isAllSelected}
        isIndeterminate={!store.isAllSelected && store.selectedItems.size > 0}
        onChange={() => store.toggleSelectAll()}
      />
    </div>
  </Tooltip>
))

export default SelectAllCheckbox
