import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { makeAutoObservable } from 'mobx'

import { ReactComponent as DeleteIcon } from '@material-design-icons/svg/round/delete.svg'
import { ReactComponent as EditIcon } from '@material-design-icons/svg/round/edit.svg'

import { Entity, Collection, initialActionState } from 'mobx/mobx'
import { SegmentEntity, FiltersState } from 'entities'

import DialogStore from 'stores/DialogStore'
import {
  Flex,
  Col,
  Input,
  IconButton,
  Modal,
  H2,
  Button,
  useStateToast,
  List,
  ListItem,
} from 'components/ui'

import { Option } from './components'

interface SaveSegmentModalProps {
  state: FiltersState
  store: SegmentsStore
  onClose: () => void
}

const SaveSegmentModal = observer(({ state, store, onClose }: SaveSegmentModalProps) => {
  let [action, setAction] = useState<'update' | 'create'>(
    store.segment ? 'update' : 'create'
  )
  let [title, setTitle] = useState('')

  let newSegmentOption = (
    <Flex gap="1rem" align="center">
      <div style={{ flexShrink: 0 }}>Создать новый сегмент</div>
      <Input
        value={title}
        onChange={setTitle}
        placeholder="Введите название сегмента"
        isWide={true}
      />
    </Flex>
  )
  let options = store.segment ? (
    <>
      <Option isSelected={action === 'update'} onSelect={() => setAction('update')}>
        Сохранить изменения в сегмент{' '}
        <span style={{ fontWeight: 600 }}>"{store.segment.data.title}"</span>
      </Option>
      <Option isSelected={action === 'create'} onSelect={() => setAction('create')}>
        {newSegmentOption}
      </Option>
    </>
  ) : (
    newSegmentOption
  )

  let isValid = action === 'update' || title.trim().length > 0

  return (
    <Modal
      isOpen={true}
      size={550}
      onClose={onClose}
      styles={{
        content: { padding: '1.5rem' },
      }}
    >
      <Col gap="var(--gap-m)">
        <H2>Сохранить сегмент</H2>
        {options}
        <Flex gap="1rem" align="center" justify="end">
          <Button design="normal" onTap={onClose}>
            Отменить
          </Button>
          <Button
            isDisabled={!isValid}
            isLoading={store.saveSegmentState.state === 'pending'}
            onTap={() => store.saveSegment({ action, title, state })}
          >
            Сохранить
          </Button>
        </Flex>
      </Col>
    </Modal>
  )
})

interface SegmentListItemProps {
  segment: Entity<SegmentEntity>
  onDelete: () => void
}

const SegmentListItem = observer(({ segment, onDelete }: SegmentListItemProps) => {
  let toast = useStateToast()
  let [isEditing, setIsEditing] = useState(false)
  let [title, setTitle] = useState('')

  let rename = () => {
    let updateState = segment.update({ payload: { title }, optimistic: true })
    updateState.then(null, (err) =>
      toast.error({ title: 'Не удалось сохранить', description: err.message })
    )
    setIsEditing(false)
  }

  let content
  if (isEditing) {
    let isValid = title.trim().length > 0
    content = (
      <>
        <Input value={title} onChange={setTitle} isWide={true} autoFocus={true} />
        <Button onTap={rename} size="xs" design="normal" isDisabled={!isValid}>
          Сохранить
        </Button>
        <Button onTap={() => setIsEditing(false)} size="xs" design="outline">
          Отменить
        </Button>
      </>
    )
  } else {
    content = (
      <>
        <div style={{ flexGrow: 1 }}>{segment.data.title}</div>
        <IconButton
          tooltip="Переименовaть"
          onTap={() => {
            setTitle(segment.data.title)
            setIsEditing(true)
          }}
        >
          <EditIcon style={{ width: 20, height: 20 }} />
        </IconButton>
        <IconButton tooltip="Удалить" onTap={onDelete}>
          <DeleteIcon style={{ width: 20, height: 20 }} />
        </IconButton>
      </>
    )
  }
  return (
    <ListItem style={{ height: '3rem' }} hover={false}>
      <Flex gap=".5rem" align="center" style={{ flexGrow: 1 }}>
        {content}
      </Flex>
    </ListItem>
  )
})

interface SegmentListModalProps {
  store: SegmentsStore
  onClose: () => void
}

const SegmentListModal = observer(({ store, onClose }: SegmentListModalProps) => {
  return (
    <Modal
      isOpen={true}
      size={550}
      onClose={onClose}
      styles={{
        content: { padding: '1.5rem' },
      }}
    >
      <Col gap="var(--gap-m)">
        <H2>Управление сегментами</H2>
        <List>
          {store.segments.items.map((segment) => (
            <SegmentListItem
              key={segment.id}
              segment={segment}
              onDelete={() => store.deleteSegment(segment)}
            />
          ))}
        </List>
      </Col>
    </Modal>
  )
})

interface SegmentsStoreProps {
  toast: ReturnType<typeof useStateToast>
  segmentCreateUrl: string
  segments: Collection<SegmentEntity>
  initialSegment?: Entity<SegmentEntity>
  onSelect: (segment?: Entity<SegmentEntity>) => void
}

class SegmentsStore {
  constructor({
    segments,
    segmentCreateUrl,
    toast,
    initialSegment,
    onSelect,
  }: SegmentsStoreProps) {
    this.segments = segments
    this.segmentCreateUrl = segmentCreateUrl
    this.toast = toast
    this.onSelect = onSelect
    if (initialSegment) this.segment = initialSegment

    makeAutoObservable(this)
  }

  segments: Collection<SegmentEntity>
  segmentCreateUrl: string
  toast: ReturnType<typeof useStateToast>
  onSelect: (segment?: Entity<SegmentEntity>) => void
  segment?: Entity<SegmentEntity>

  selectSegment(segment: Entity<SegmentEntity>) {
    this.segment = segment
    this.onSelect(segment)
  }

  resetSegment() {
    this.segment = undefined
    this.onSelect(undefined)
  }

  saveDialog = new DialogStore({ component: SaveSegmentModal })
  segmentListDialog = new DialogStore({ component: SegmentListModal })
  saveSegmentState = initialActionState

  openSaveSegmentDialog(state: FiltersState) {
    this.saveDialog.open({ store: this, state })
  }

  saveSegment({
    state,
    action,
    title,
  }: {
    state: FiltersState
    action: 'update' | 'create'
    title: string
  }) {
    if (action === 'update') {
      this.saveSegmentState = this.segment!.update({
        payload: { filters: state, optimistic: true },
      })
    } else {
      this.saveSegmentState = this.segments.create({
        payload: { title, filters: state },
        url: this.segmentCreateUrl,
      })
    }
    this.saveSegmentState.then(
      (data) => {
        this.selectSegment(this.segments.map[data.id])
        this.saveDialog.close()
      },
      (error) => {
        this.toast.error({ title: 'Не удалось сохранить', description: error.message })
      }
    )
  }

  openSegmentListDialog() {
    this.segmentListDialog.open({ store: this })
  }

  deleteSegment(segment: Entity<SegmentEntity>) {
    let deleteState = this.segments.delete({ id: segment.id })
    deleteState.then(
      () => {
        if (this.segment === segment) this.resetSegment()
        if (this.segments.isEmpty) this.segmentListDialog.close()
      },
      (err) => this.toast.error({ title: 'Не удалось удалить', description: err.message })
    )
  }

  render() {
    return <>
      {this.saveDialog.render()}
      {this.segmentListDialog.render()}
    </>
  }
}

export default SegmentsStore
