import React from 'react'
import clsx from 'clsx'

import LayoutEntry from 'components/ui/LayoutEntry'
import { useTheme } from 'ui'
import { ReactComponent as Info } from 'ui/konsol/icons/info.svg'

import { H1, Text, Appear } from 'ui'

import css from './styles.module.css'

const InviteNotFound = () => {
  const { config } = useTheme()
  const classes = clsx('ui', css.container)
  return (
    <LayoutEntry className={classes}>
      <Appear animation={Appear.presets.slideDown}>
        <Info fill="var(--konsol-color-red)" width={100} height={100} />
      </Appear>
      <Appear animation={Appear.presets.slideDown} delay={100}>
        <H1>
          <span
            dangerouslySetInnerHTML={{
              __html: config.text.pages.registration.invite_not_found,
            }}
          />
        </H1>
      </Appear>
      <Appear animation={Appear.presets.slideDown} delay={150}>
        <Text size="l" className={css.invitationText}>
          Приглашение нe&nbsp;найдено. Проверьте правильность ссылки.
        </Text>
      </Appear>
    </LayoutEntry>
  )
}

export default InviteNotFound
