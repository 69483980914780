import { rest } from 'msw'

import { json } from './helpers'
import { user } from './user'

const unregistered = { error: { message: 'Такой пользователь не зарегистрирован' } }
const wrongCode = { error: { message: 'Неправильный код' } }

const passwordRegxp = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])([a-zA-Z0-9]{8,})$/

export default [
  rest.post('/api/authorizations', (req, res, ctx) => {
    if (req.body.mobile_phone === '+1234') {
      return res(ctx.delay(500), ctx.status(403), ctx.json(unregistered))
    } else {
      return res(ctx.delay(500), ctx.json({ status: 'password' }))
    }
  }),
  rest.put('/api/authorizations/verify', (req, res, ctx) => {
    if (req.body.code !== '0000') {
      return res(ctx.delay(500), ctx.status(403), ctx.json(wrongCode))
    } else {
      return res(ctx.delay(500), ctx.json(user))
    }
  }),
  // auth by phone and password
  rest.post('/api/authorizations/password_login', (req, res, ctx) => {
    if (req.body.password === '12345678aA') {
      return res(ctx.delay(500), ctx.status(403), ctx.json(wrongCode))
    } else {
      return res(ctx.delay(500), ctx.json(user))
    }
  }),
  // password recovery { email: 'email' }
  rest.post('/api/authorizations/recovery', (req, res, ctx) => {
    return res(
      ctx.delay(500),
      ctx.json({
        uuid: '1a',
      })
    )
  }),
  // password recovery code { "uuid": "uuid", "code": "123456" }
  rest.post('/api/authorizations/recovery_confirmation', (req, res, ctx) => {
    if (req.body.code !== '000000') {
      return res(
        ctx.delay(500),
        ctx.status(403),
        ctx.json({ error: { message: 'Неверный логин или пароль' } })
      )
    } else {
      return res(
        ctx.delay(500),
        ctx.json({
          uuid: '1a',
        })
      )
    }
  }),
  // password recovery set new password{ "uuid": "uuid", "password": "qwerty" }
  rest.put('/api/authorizations/password', (req, res, ctx) => {
    if (!req.body.uuid || !req.body.password) {
      return res(
        ctx.delay(500),
        ctx.status(403),
        ctx.json({ error: { message: 'Ошибка' } })
      )
    } else if (!passwordRegxp.test(req.body.password)) {
      return res(
        ctx.delay(500),
        ctx.status(403),
        ctx.json({
          error: {
            message:
              'Пароль должен быть не короче 8 символов, содержать хотя бы одну заглавную букву и цифру',
          },
        })
      )
    } else {
      return res(
        ctx.delay(500),
        ctx.json({
          twofactor: {
            status: 'active',
          },
        })
      )
    }
  }),
  // create auth with password
  rest.post('/api/company/user/profile/password', (req, res, ctx) => {
    if (req.body.email === 'test@gmail.com') {
      return res(
        ctx.delay(500),
        ctx.status(400),
        ctx.json({ error: { message: 'Такой email уже есть' } })
      )
    } else if (!passwordRegxp.test(req.body.password)) {
      return res(
        ctx.delay(500),
        ctx.status(403),
        ctx.json({
          error: {
            message:
              'Пароль должен быть не короче 8 символов, содержать хотя бы одну заглавную букву и цифру',
          },
        })
      )
    } else {
      return res(
        ctx.delay(500),
        ctx.json({ email: req.body.email, need_password: false })
      )
    }
  }),

  rest.post('/api/authorizations/admin_login', json(user)),

  rest.post('/api/authorizations/token_login', json(user)),

  // rest.post('/api/authorizations/app_login', json(user)),

  rest.post('/api/authorizations/app_login', (req: any, res: any, ctx: any) =>
    res(ctx.delay(1500), ctx.json(user))
  ),

  rest.delete('/api/authorizations/destroy', json({})),
]
