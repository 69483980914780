import { sample } from 'lodash'
import { rest } from 'msw'

import { json } from './helpers'

const invites: any = [
  {
    id: 1,
    name: 'Алексеев Алексей',
    phone: '+79811852760',
    email: '',
    status: 'waiting_for_contract_sign',
    invited_time: '2020-08-15T23:10:02.081Z',
    updated_time: '2020-08-15T23:10:02.081Z',
    contract: {
      id: 1234567,
      contractor: { id: 1111, name: 'Алексеев Алексей' },
      status: 'signed_by_contractor',
      title: 'Договорной договор',
      file_url: 'http://google.com',
    },
    onboarding_scenario: null,
    comment: `
      Неправильное фото паспорта
      Приложено селфи кота
      Неправильное фото паспорта
      Приложено селфи кота
      Неправильное фото паспорта
      Приложено селфи кота
    `,
    contract_settings: {
      'Дата договора': '01.11.2020',
      'Номер договора': 'ПР-20-11-01',
      'Виды услуг в п. 1.2. договора':
        'Проведение видеосъемки мероприятия и техническая поддержка - Проведение онлайн-трансляции мероприятия и техническая поддержка',
      'Заполните информацию для п. 4.1. договора':
        'Стоимость услуг согласовывается Сторонами применительно к каждой заявке и зависит от сложности и срока выполнения Задания. Если нужно заполнить п. 4.5. (Вознаграждение за передачу исключительных прав на Произведения...), укажите информацию',
      'Начните предложение с 4.5.':
        'Вознаграждение за передачу исключительных прав на Произведения включено в стоимость услуг и составляет 10% от указанной в Акте об оказанных услугах стоимости услуг.',
    },
    spectrum_url: 'https://google.com',
    contract_data: {
      document_date: '01.11.2020',
    },
  },
  {
    id: 2,
    name: 'Петров Александр',
    phone: '+79001112233',
    email: 'email@email.com',
    status: 'waiting_for_documents',
    invited_time: '2020-08-15T09:04:02.081Z',
    updated_time: '2020-08-15T09:04:02.081Z',
    onboarding_scenario: { id: 2, title: 'Сценарий 2' },
  },
  {
    id: 3,
    name: 'Григорий Михаил',
    phone: '+7 (981) 185-27-60',
    email: 'email@email.com',
    status: 'incorrect_documents',
    invited_time: '2020-08-15T09:04:02.081Z',
    updated_time: '2020-08-15T09:04:02.081Z',
    onboarding_scenario: { id: 1, title: 'Сценарий 1' },
    contract_settings: {
      'Дата договора': '01.11.2020',
      'Номер договора': 'ПР-20-11-01',
      'Виды услуг в п. 1.2. договора':
        'Проведение видеосъемки мероприятия и техническая поддержка - Проведение онлайн-трансляции мероприятия и техническая поддержка',
      'Заполните информацию для п. 4.1. договора':
        'Стоимость услуг согласовывается Сторонами применительно к каждой заявке и зависит от сложности и срока выполнения Задания. Если нужно заполнить п. 4.5. (Вознаграждение за передачу исключительных прав на Произведения...), укажите информацию',
      'Начните предложение с 4.5.':
        'Вознаграждение за передачу исключительных прав на Произведения включено в стоимость услуг и составляет 10% от указанной в Акте об оказанных услугах стоимости услуг.',
    },
  },
  {
    id: 4,
    name: 'Геворкян Геворг Петерлеевич',
    phone: '+7 (981) 185-27-61',
    email: 'gevorg@email.com',
    status: 'declined',
    comment: 'Остроумный комментарий',
    invited_time: '2020-08-16T09:04:02.081Z',
    updated_time: '2020-08-16T09:04:02.081Z',
  },
]

let statuses = [
  'invited',
  'waiting_for_documents',
  'checking_documents',
  'incorrect_documents',
  'waiting_for_contract_sign',
  'declined',
]

let count = 5
let randomInvite = () => ({
  id: count++,
  name: 'Геворкян Геворг Петерлеевич',
  phone: '+7 (981) 185-27-61',
  email: 'gevorg@email.com',
  status: sample(statuses),
  comment: 'Остроумный комментарий',
  invited_time: '2020-08-16T09:04:02.081Z',
  updated_time: '2020-08-16T09:04:02.081Z',
})

for (let i = 0; i < 600; i++) invites.push(randomInvite())

export default [
  rest.get('/api/company/contractors/invites', json(invites)),
  rest.get('/api/company/contractors/invites/unread_count', json({ count: 0 })),
  // rest.put(
  // '/api/company/contractors/invites/:id/cancel',
  // error(422, { error: { message: 'Сообщение' } })
  // ),
  rest.put('/api/company/contractors/invites/:id/cancel', json({ cancelled: true })),
  rest.put('/api/company/contractors/invites/:id/resume', json({ cancelled: false })),
]
