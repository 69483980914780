import React from 'react'
import { runInAction } from 'mobx'
import { observer } from 'mobx-react-lite'
import { Entity, Collection } from 'mobx/mobx'
import { useMediaContext } from 'App/MediaContext'
import { DocumentEntity } from 'entities'
import { DocumentStatusBadge, documentKinds } from 'components/documents/statuses'
import {
  List,
  ListItem,
  ListCell,
  Flex,
  Checkbox,
  Link,
  Card,
  CardRow,
  CardValue,
} from 'components/ui'
import { SortableListHeader } from 'components/FilteredList'
import { printDate } from 'utils/datetime'
import { SelectAllCheckbox } from 'components/FilteredList2'
import styles from './Documents.module.css'
import { DocumentsStore } from './DocumentsStore'
import { ListItemSelectionCol } from 'components/ui/List'

interface DocumentListItemProps {
  document: Entity<DocumentEntity>
  isSelected: boolean
  onToggleSelected: () => void
  isSelectable: boolean
}

export const DocumentListItem = observer(
  ({ document, isSelected, onToggleSelected, isSelectable }: DocumentListItemProps) => {
    let {
      title,
      kind,
      created_date,
      contractor,
      date,
      file_url,
      template,
    } = document.data

    const { isMobile } = useMediaContext()

    if (isMobile)
      return (
        <ListItem hover={false} padding={false} className={styles.document}>
          {isSelectable && (
            <ListItemSelectionCol value={isSelected!} onClick={onToggleSelected!} />
          )}
          <Card kind="secondary" withShadow style={{ flex: 1 }}>
            <Flex direction="column" gap="6px">
              <CardRow>
                <CardRow>
                  <CardValue
                    variant="active"
                    value={
                      <Link style={{ width: '100%' }} href={file_url} target="_blank">
                        {title}
                      </Link>
                    }
                  />
                </CardRow>
                {date && (
                  <CardRow>
                    <CardValue
                      variant="active"
                      label={<p>от {printDate(date, { mode: 'full' })}</p>}
                    />
                  </CardRow>
                )}
              </CardRow>
              {template?.name && (
                <CardRow>
                  <CardValue label={template.name} />
                </CardRow>
              )}
              {contractor.data.name && (
                <CardRow>
                  <CardValue
                    label="Исполнитель:"
                    variant="active"
                    value={
                      <Link target="_blank" href={`/contractors/${contractor.data.id}`}>
                        {contractor.data.name}
                      </Link>
                    }
                  />
                </CardRow>
              )}
              {created_date && (
                <CardRow>
                  <CardValue
                    label="Дата создания:"
                    value={<p>{printDate(created_date, { mode: 'full' })}</p>}
                  />
                </CardRow>
              )}
              <CardRow>
                <CardValue label="Тип:" value={<p>{documentKinds[kind]}</p>} />
                <CardValue
                  label="Статус:"
                  value={<DocumentStatusBadge document={document} />}
                />
              </CardRow>
            </Flex>
          </Card>
        </ListItem>
      )

    return (
      <ListItem padding={false} hover={false}>
        <ListCell col="selection">
          <Checkbox value={isSelected} onChange={onToggleSelected} />
        </ListCell>
        <ListCell col="contractor">
          <Link href={`/contractors/${contractor.data.id}`} target="_blank">
            {contractor.data.name}
          </Link>
        </ListCell>
        <ListCell col="title">
          <div>
            <Link href={file_url} target="_blank">
              {title}
              {date && (
                <span style={{ color: 'var(--color-secondary)' }}>
                  {' '}
                  от {printDate(date, { mode: 'full' })}
                </span>
              )}
            </Link>
          </div>
          {template && (
            <span style={{ color: 'var(--color-secondary)' }}>
              по шаблону "{template.name}"
            </span>
          )}
        </ListCell>
        <ListCell col="kind">{documentKinds[kind]}</ListCell>
        <ListCell col="status">
          <DocumentStatusBadge document={document} />
        </ListCell>
        <ListCell col="date">{printDate(created_date, { mode: 'full' })}</ListCell>
      </ListItem>
    )
  }
)

interface DocumentListProps {
  documents: Collection<DocumentEntity>
  store: DocumentsStore
}

export const DocumentList = observer(({ documents, store }: DocumentListProps) => {
  let cols = {
    selection: { width: 20 },
    contractor: { grow: 2 },
    title: { grow: 3 },
    kind: { width: 80 },
    status: { width: 180 },
    template: { width: 130 },
    date: { width: 130 },
  }

  const { isMobile } = useMediaContext()

  return (
    <List cols={cols} gap={isMobile ? '1rem' : 0}>
      {!isMobile && (
        <SortableListHeader
          padding={false}
          cols={{
            selection: { title: <SelectAllCheckbox store={store.selected} /> },
            contractor: { title: 'Исполнитель', sorting: 'contractor' },
            title: { title: 'Название', sorting: 'title' },
            kind: { title: 'Тип', sorting: 'kind' },
            status: { title: 'Статус', sorting: 'status' },
            date: { title: 'Дата создания', sorting: 'date' },
          }}
          sorting={store.sorting}
          onChange={(sorting) => {
            runInAction(() => {
              store.sorting = sorting
            })
          }}
        />
      )}

      {documents.items.map((item) => (
        <DocumentListItem
          document={item}
          isSelected={store.selected.isItemSelected(item)}
          onToggleSelected={() => store.selected.toggleSelectItem(item)}
          isSelectable={store.selected.isSelectable}
        />
      ))}
    </List>
  )
})
