//@ts-ignore
import { FormStore } from 'shadowform'
import {
  normalizePhone,
  validateEmail,
  validatePhoneInternational,
} from 'utils/validations'

export const createJoinCompanyForm = () =>
  new FormStore({
    initialValues: {
      full_name: '',
      mobile_phone: '+7',
      email: '',
    },
    fields: {
      full_name: {
        isRequired: true,
        requiredError: 'Заполните ФИО',
      },
      mobile_phone: {
        isRequired: true,
        requiredError: 'Заполните номер телефона',
        normalize: normalizePhone,
        validations: {
          format: {
            validate: validatePhoneInternational,
            error: 'Введите номер до конца',
          },
        },
      },
      email: {
        isRequired: true,
        requiredError: 'Заполните e-mail',
        validations: {
          format: {
            validate: validateEmail,
            error: 'Введите правильный адрес',
          },
        },
      },
      passport_main: {
        isRequired: true,
        requiredError: 'Загрузите основной разворот паспорта',
      },
      passport_registration: {
        isRequired: true,
        requiredError: 'Загрузите разворот паспорта с пропиской',
      },
      passport_selfie: {
        isRequired: true,
        requiredError: 'Загрузите селфи с паспортом',
      },
    },
  })
