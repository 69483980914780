import React from 'react'
import { observer } from 'mobx-react-lite'
import { repeat, without } from 'lodash'
// @ts-ignore
import { FieldStore } from 'shadowform'
import cx from 'clsx'

import { createDigitsMask } from 'utils/validations'
import { RegistrationExtraField } from 'entities'

import { FormRow, Field, Col } from 'components/ui'

import { Input, Select, Checkbox } from 'ui'
import FileInput from 'components/FileInput'

import NumberInput from 'components/NumberInput'
import DatePicker from 'components/ui/DatePicker'
import { parseDate, serializeDate } from 'utils/datetime'

import css from './styles.module.css'

interface ExtraFieldRendererProps {
  config: RegistrationExtraField
  field: FieldStore
  size: 'm' | 'l'
  isLoading?: boolean
  onChange?: (value: string) => void
}

const textFields = ['text', 'inn', 'email']

const ExtraFieldRenderer = observer(
  ({ config, field, size, isLoading, onChange }: ExtraFieldRendererProps) => {
    let { type, icon, label, placeholder, caption } = config

    if (textFields.includes(type)) {
      let props: any = {}
      if (type === 'inn') {
        Object.assign(props, {
          mask: createDigitsMask(12),
          placeholder: repeat('0', 12),
          numericKeyboard: true,
        })
      } else {
        props.placeholder = placeholder
      }

      return (
        <Field field={field}>
          {({ inputProps, error }) => {
            const { value, ..._inputProps } = inputProps
            return (
              <FormRow label={label} size={size} error={error} caption={caption}>
                <Input
                  {..._inputProps}
                  {...props}
                  onChange={(val) => {
                    _inputProps.onChange(val)

                    if (onChange) {
                      onChange(val)
                    }
                  }}
                  value={value}
                  isWide
                  size="m"
                />
              </FormRow>
            )
          }}
        </Field>
      )
    }

    if (type === 'number') {
      return (
        <Field field={field}>
          {({ inputProps, error }) => (
            <FormRow label={label} size={size} error={error} caption={caption}>
              <NumberInput
                {...inputProps}
                placeholder={config.placeholder}
                isWide
                size={size}
              />
            </FormRow>
          )}
        </Field>
      )
    }
    if (type === 'date') {
      return (
        <Field field={field}>
          {({ inputProps, error }) => (
            <FormRow label={label} size={size} error={error} caption={caption}>
              <DatePicker
                {...inputProps}
                value={
                  inputProps.value ? parseDate(inputProps.value).toDate() : undefined
                }
                onChange={(value) =>
                  inputProps.onChange(value ? serializeDate(value as Date) : undefined)
                }
                placeholder={config.placeholder}
                isWide
                size={size}
              />
            </FormRow>
          )}
        </Field>
      )
    }
    if (type === 'inn') {
      return (
        <Field field={field}>
          {({ inputProps, error }) => (
            <FormRow label={label} size={size} error={error} caption={caption}>
              <Input {...inputProps} isWide size={size} />
            </FormRow>
          )}
        </Field>
      )
    }
    if (type === 'checkbox') {
      return (
        <Field field={field}>
          {({ inputProps, error }) => (
            <FormRow size={size} error={error} caption={caption}>
              <Checkbox {...inputProps} size={size} label={label} />
            </FormRow>
          )}
        </Field>
      )
    }
    if (type === 'file') {
      return (
        <Field field={field}>
          {({ inputProps }) => (
            <div className={cx(css.docWrapper, css[`docWrapper${icon}`])}>
              <div className={css.fileInputLabel}>{label}</div>
              <FileInput isLoading={isLoading} {...inputProps} />
            </div>
          )}
        </Field>
      )
    }
    if (type === 'select') {
      let options = config.options!.map((o) => ({ value: o.value, label: o.name }))
      return (
        <Field field={field} showRequiredError="onChange">
          {({ inputProps, error }) => {
            return (
              <FormRow label={label} size={size} error={error} caption={caption}>
                <Select
                  {...inputProps}
                  value={
                    inputProps.value === null
                      ? undefined
                      : options.find((o) => o.value === inputProps.value)
                  }
                  onChange={(value: any) => {
                    inputProps.onChange(value === null ? undefined : value.value)
                    if (onChange) {
                      onChange(value?.value || '')
                    }
                  }}
                  size={size}
                  isClearable={true}
                  isSearchable={false}
                  placeholder={'Выберите значение'}
                  options={options}
                />
              </FormRow>
            )
          }}
        </Field>
      )
    }
    if (type === 'multiselect') {
      return (
        <Field field={field} showRequiredError="onChange">
          {({ inputProps, error }) => (
            <FormRow label={label} size={size} error={error}>
              <Col gap="var(--gap-s)" style={{ marginTop: '1rem' }}>
                {config.options!.map((o) => (
                  <Checkbox
                    key={o.value}
                    value={inputProps.value.includes(o.value)}
                    onChange={(value) =>
                      inputProps.onChange(
                        value
                          ? [...inputProps.value, o.value]
                          : without(inputProps.value, o.value)
                      )
                    }
                    label={o.name}
                  />
                ))}
              </Col>
            </FormRow>
          )}
        </Field>
      )
    }
    return null
  }
)

export default ExtraFieldRenderer
