import React from 'react'

import { Link } from 'ui'

export const RiskLabel = () => {
  return (
    <div style={{ display: 'inline-block' }}>
      <span style={{ marginRight: '2pt' }}>
        Аналитика рисков — инструмент для мониторинга уровня риска в работе с
        самозанятыми. Чем меньше исполнителей компании попадает под критерий, тем меньше
        процент риска.
      </span>
      <Link href="https://support.konsol.pro/risk-analytics">
        Подробнее про аналитику рисков
      </Link>
    </div>
  )
}

export default RiskLabel
