import React from 'react';

import { ReactComponent as RuIcon } from '../../../assets/icon-ru.svg';
import { ReactComponent as KgIcon } from '../../../assets/icon-kg.svg';
import { ReactComponent as AmIcon } from '../../../assets/icon-am.svg';
import { ReactComponent as KzIcon } from '../../../assets/icon-kz.svg';
import { ReactComponent as ByIcon } from '../../../assets/icon-by.svg';

import cx from 'clsx';

import s from './styles.module.css'

interface ICountries {
  countries: {
    [key: string]: boolean
  };
  className?: string;
}

export const CountriesIcons: React.FC<ICountries> = ({ countries, className }) => {

  return (
    <div className={cx(s.countriesRow, className)}>
      {countries.ru && <span className={s.countriesIcon}><RuIcon /></span>}
      {countries.kg && <span className={s.countriesIcon}><KgIcon /></span>}
      {countries.kz && <span className={s.countriesIcon}><KzIcon /></span>}
      {countries.am && <span className={s.countriesIcon}><AmIcon /></span>}
      {countries.by && <span className={s.countriesIcon}><ByIcon /></span>}
    </div>
  );
};
