import React, { useState } from 'react'

import { isStaging } from 'thirdParty'

import apiList from '../apiList'

import styles from './styles.module.css'

const BASE_URL = 'base_url'

const clearCookies = () => {
  var cookies = document.cookie.split(';')

  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i]
    var eqPos = cookie.indexOf('=')
    var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT'
  }
}

interface ApiListItem {
  title: string
  base_url: string
}

const DevPanel = () => {
  const [isShow, setIsShow] = useState(false)
  const [currentApi, setCurrentApi] = useState(() => {
    const baseUrl: string | null = localStorage.getItem(BASE_URL)
    const defaultItem: ApiListItem | undefined = apiList.find(
      (i: ApiListItem) => i.title.toLowerCase() === 'default'
    )
    const initialValue = baseUrl

    if (isStaging() && !initialValue && defaultItem?.base_url) {
      localStorage.setItem(BASE_URL, defaultItem?.base_url)
      window.location.reload()
    }

    return initialValue || null
  })

  const onSwitcherClick = () => setIsShow(true)

  const onBaseUrlChange = (e: any) => {
    const { value } = e.target
    setCurrentApi(value)
    if (value) {
      localStorage.setItem(BASE_URL, value)
    } else {
      localStorage.removeItem(BASE_URL)
    }
  }

  const onCloseButton = () => setIsShow(false)

  const onRefreshButton = () => window.location.reload()

  const onClearDataButton = () => {
    clearCookies()
    localStorage.clear()
  }

  return (
    <>
      {isShow ? (
        <div className={styles.devpanel}>
          Base_url:
          <select onChange={onBaseUrlChange}>
            <optgroup label="Доступные API">
              {apiList.map((item) => {
                return (
                  <option value={item.base_url} selected={item.base_url === currentApi}>
                    {item.base_url} ({item.title})
                  </option>
                )
              })}
            </optgroup>
            <optgroup label="Сбросить API">
              <option value="" selected={!currentApi}>
                Local mocks
              </option>
            </optgroup>
          </select>
          <button
            type="button"
            className={styles.refreshPageButton}
            onClick={onRefreshButton}
            title="Обновить страницу"
          >
            ↺
          </button>
          <button
            type="button"
            className={styles.refreshPageButton}
            onClick={onClearDataButton}
            title="Очистить cookie и localstorage"
          >
            <span role="img" aria-label="">
              💥
            </span>
          </button>
          <button
            type="button"
            className={styles.closeButton}
            onClick={onCloseButton}
            title="Закрыть панель"
          >
            ✕
          </button>
        </div>
      ) : (
        <div className={styles.devpanelSwitcher}>
          <button type="button" onClick={onSwitcherClick}>
            API {currentApi}
          </button>
        </div>
      )}
    </>
  )
}

export default DevPanel
