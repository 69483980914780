import React, { useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import cx from 'clsx'
import { compact } from 'lodash'

import type { PageProps } from 'app.types'
import { useUserStore } from 'stores/context'
import { useMediaContext } from 'App/MediaContext'
import { useMobxApi, Entity, Collection } from 'mobx/mobx'
import { Data } from 'mobx/types'
import {
  ContractorIdCard,
  ContractorIdCardField,
  ContractorEntity,
  CustomFieldEntity,
  CustomFieldValue,
  TaskEntity,
} from 'entities'

import * as bankIcons from 'components/BankIcon'

import {
  Layout,
  BackLink,
  ActionStateView,
  Placeholder,
  Flex,
  Col,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  useStateToast,
  Button,
  Card,
  H3,
  Input,
  Checkbox,
  DatePicker,
  SelectInput,
} from 'components/ui'
import { AbilityButton, AbilityMenuItem } from 'components/abilityButtons'
import TaskList from 'components/tasks/TaskList'
import UserInfoBlock from 'components/UserInfoBlock'
import NumberInput from 'components/NumberInput'

import { printIsoDate, serializeDate, parseDate, printDate } from 'utils/datetime'
import useHashState from 'utils/useHashState'
import AutoSaveStore from 'utils/AutoSaveStore'
import { endash } from 'utils/typo'

import { ReactComponent as CheckIcon } from '@material-design-icons/svg/round/check_circle.svg'
import { ReactComponent as CancelIcon } from '@material-design-icons/svg/round/cancel.svg'
import { ReactComponent as EditIcon } from '@material-design-icons/svg/round/create.svg'
import { ReactComponent as MoreIcon } from '@material-design-icons/svg/round/more_horiz.svg'
import { ReactComponent as SettingsIcon } from '@material-design-icons/svg/round/settings.svg'

import { PaymentDetailsEditModal } from './ContractorPaymentDetails'
import { ContractAndDocumentsForMember } from './ContractAndDocuments'
import { CustomFieldsStore } from './CustomFields'
import { getContractorProblems, ContractorProblem } from './problems'
import { contractorKindsMap } from './maps'
import s from './style.module.css'
import ContractorReports from './ContractorReports'
import ContractorRisks from './ContractorRisks'
import { Divider, Tab, Tabs } from 'ui'

interface InfoListProps {
  title?: React.ReactNode
  children: React.ReactNode
}

const InfoList = ({ title, children }: InfoListProps) => (
  <Col gap="var(--gap-m)">
    {title && <H3>{title}</H3>}
    {children}
  </Col>
)

interface InfoListItemProps {
  label?: string
  children?: React.ReactNode
}

const InfoListItem = ({ label, children }: InfoListItemProps) => (
  <div>
    <div style={{ color: 'var(--color-secondary)', marginBottom: '.5rem' }}>{label}</div>
    {children && <div>{children}</div>}
  </div>
)

const BankIcon = ({ bankCode }: { bankCode: string }) => {
  // @ts-ignore
  const Icon = bankIcons[bankCode]
  return Icon ? <Icon colored size="l" /> : null
}

interface ContractorTasksSectionProps {
  tasks: Collection<TaskEntity>
  contractor: Entity<ContractorEntity>
}

const ContractorTasksSection = observer(
  ({ tasks, contractor }: ContractorTasksSectionProps) => {
    if (tasks.items.length === 0) return <Placeholder>Нет заданий</Placeholder>
    return (
      <TaskList
        options={{
          kind: 'tasks',
          who: false,
          isSelectable: false,
          isEditable: false,
          actionButtons: false,
        }}
        tasks={tasks}
        baseUrl={`/contractors/${contractor.id}`}
        appendUrl={'?back=acts'}
      />
    )
  }
)

const ContractorMoiNalogSection = observer(
  ({ contractor }: { contractor: Entity<ContractorEntity> }) => {
    let [actionState, setActionState] = useState({ state: 'fulfilled' })
    let { status, message } = contractor.data.moi_nalog!

    let action = () => {
      let action = status === 'connected' ? 'disconnect_moi_nalog' : 'connect_moi_nalog'
      let state = contractor.action({ action })
      setActionState(state)
      state.then((data: Data) => contractor.setData(data))
    }

    return (
      <InfoList title="Подключение в приложении «Мой налог»">
        <InfoListItem label="Статус">
          <Flex gap=".5rem" align="center">
            {status === 'connected' ? (
              <>
                <CheckIcon fill="var(--color-green)" />
                <div>Подключено</div>
              </>
            ) : (
              <>
                <CancelIcon fill="var(--color-icon)" />
                <div>Не подключено</div>
              </>
            )}
          </Flex>
        </InfoListItem>
        <InfoListItem label="Действия">
          <Flex gap="1rem" align="center">
            <Button
              design="normal"
              isLoading={actionState.state === 'pending'}
              onTap={action}
            >
              {status === 'connected' ? 'Отключить' : 'Подключить'}
            </Button>
            {message && (
              <div
                className={cx(s.message, status === 'failed' && s.error)}
                dangerouslySetInnerHTML={{ __html: message }}
              />
            )}
          </Flex>
        </InfoListItem>
      </InfoList>
    )
  }
)

interface ContractorPersonalDataSectionProps {
  fields?: Collection<CustomFieldEntity>
  contractor: Entity<ContractorEntity>
}

interface CustomFieldControlProps {
  contractor: Entity<ContractorEntity>
  field: Entity<CustomFieldEntity>
}

const CustomFieldControl = observer(({ contractor, field }: CustomFieldControlProps) => {
  let { name, kind, options } = field.data
  let currentUser = useUserStore()
  let record = contractor.data.custom_fields.find((f) => f.id === field.id)

  let store = useMemo(
    () =>
      new AutoSaveStore<CustomFieldValue | undefined>({
        initialValue: record?.value,
        saveFn: (value, { abortSignal }) => {
          return contractor.action({
            action: `custom_fields/${field.id}`,
            payload: { value: value === undefined ? null : value },
            options: { method: 'PATCH', signal: abortSignal },
          })
        },
        delay: kind === 'text' || kind === 'number' ? 1337 : 0,
      }),
    []
  )

  let onChange = (value: CustomFieldValue | undefined) => {
    if (!record) {
      contractor.data.custom_fields.push({ id: field.id, value })
    } else {
      record.value = value
    }
    store.value = value
  }

  let canUpdate = !currentUser.hasAbility('contractors.update_custom_fields')
  let value = record?.value

  let control
  if (canUpdate) {
    if (kind === 'checkbox') {
      control = <Checkbox value={value as boolean} isDisabled={true} />
    } else if (value === undefined) {
      control = <div style={{ color: 'var(--color-secondary)' }}>{endash}</div>
    } else if (kind === 'text') {
      control = value
    } else if (kind === 'number') {
      control = String(value as number)
    } else if (kind === 'date') {
      control = printDate(value as string)
    } else if (kind === 'dropdown') {
      control = options!.items.find((o) => o.id === value)?.data.name
    }
  } else {
    if (kind === 'text') {
      control = (
        <Input
          value={value === undefined ? '' : (value as string)}
          onChange={onChange}
          isMultiline={true}
          isClearable={true}
        />
      )
    } else if (kind === 'checkbox') {
      control = <Checkbox value={value as boolean} onChange={onChange} />
    } else if (kind === 'number') {
      control = (
        <NumberInput
          value={value === undefined ? undefined : String(value)}
          onChange={(value) => onChange(value as CustomFieldValue)}
          allowDecimal={true}
          // decimalLimit={false}
          isClearable={true}
        />
      )
    } else if (kind === 'date') {
      control = (
        <DatePicker
          selectsRange={false}
          value={value === undefined ? undefined : parseDate(value as string).toDate()}
          onChange={(value) => onChange(value ? serializeDate(value as Date) : undefined)}
          isClearable={true}
          printMode="full"
        />
      )
    } else if (kind === 'dropdown') {
      let selectOptions = options!.items.map((o) => ({
        value: o.data.id,
        label: o.data.name,
      }))
      control = (
        <SelectInput
          value={
            value === undefined ? null : selectOptions.find((o) => o.value === value)
          }
          onChange={(option) => onChange(option ? option.value : null)}
          options={selectOptions}
          placeholder="Выберите значение"
          isClearable={true}
          isSearchable={false}
        />
      )
    }
  }

  return <InfoListItem label={name}>{control}</InfoListItem>
})

interface CustomFieldsSectionProps {
  fields: Collection<CustomFieldEntity>
  contractor: Entity<ContractorEntity>
}

const CustomFieldsSection = observer((props: CustomFieldsSectionProps) => {
  let { contractor, fields } = props
  let currentUser = useUserStore()
  let toast = useStateToast()
  let customFieldsStore = useMemo(() => new CustomFieldsStore({ fields, toast }), [])
  let title = (
    <Flex gap="1rem" align="center" justify="space-between">
      <div>Дополнительные поля</div>
      {currentUser.hasAbility('contractors/custom_fields.update') && (
        <IconButton onTap={() => customFieldsStore!.openList()}>
          <SettingsIcon />
        </IconButton>
      )}
    </Flex>
  )
  return (
    <InfoList title={title}>
      {fields.isEmpty ? (
        <div style={{ color: 'var(--color-secondary)' }}>
          Дополнительных полей не создано
        </div>
      ) : (
        fields.items.map((field) => (
          <CustomFieldControl
            key={field.id}
            contractor={contractor}
            field={fields.map[field.id]}
          />
        ))
      )}
      {customFieldsStore!.render()}
    </InfoList>
  )
})

const ContractorPersonalDataSection = observer(
  ({ contractor, fields }: ContractorPersonalDataSectionProps) => {
    let currentUser = useUserStore()
    let { isMobile } = useMediaContext()
    let history = useHistory()
    let params = new URLSearchParams(history.location.search)
    let [showDetailsEditModal, setShowDetailsEditModal] = useState(
      () => params.get('edit_payment_details') === '1'
    )

    let { payment_details } = contractor.data
    let personalData = contractor.data.full_name && (
      <>
        <InfoList title="Персональные данные">
          <InfoListItem label="ФИО">{contractor.data.full_name}</InfoListItem>
          <InfoListItem label="Тип занятости">
            {contractorKindsMap[contractor.data.kind] || contractor.data.kind}
          </InfoListItem>
          {contractor.data.hasOwnProperty('is_new') && (
            <InfoListItem label="Статус НПД">
              {contractor.data.is_new === true ? 'Новый' : 'Не новый'}
            </InfoListItem>
          )}
          {/* <InfoListItem label="ИНН">{contractor.data.inn}</InfoListItem> */}
          {contractor.data.citizenship && (
            <InfoListItem label="Гражданство">{contractor.data.citizenship}</InfoListItem>
          )}
          {/* {contractor.data.tax_residence_country && (
            <InfoListItem label="Страна налогового резидентства">
              {contractor.data.tax_residence_country}
            </InfoListItem>
          )} */}
          {contractor.data.address && (
            <InfoListItem label="Адрес для корреспонденции">
              {contractor.data.address}
            </InfoListItem>
          )}
          {contractor.data.arrival_date && (
            <InfoListItem label="Дата въезда">
              {printIsoDate(new Date(contractor.data.arrival_date), false)}
            </InfoListItem>
          )}
        </InfoList>
        {contractor.data.id_cards?.map((item: ContractorIdCard) => {
          return (
            <InfoList title={item.title}>
              {item.fields.map((field: ContractorIdCardField) => {
                return (
                  <InfoListItem label={field.title}>{String(field.value)}</InfoListItem>
                )
              })}
            </InfoList>
          )
        })}
      </>
    )

    let editPaymentDetailsButton = currentUser.kind === 'contractor' && (
      <IconButton
        tooltip="Изменить реквизиты"
        onTap={() => setShowDetailsEditModal(true)}
      >
        <EditIcon style={{ width: 20, height: 20 }} />
      </IconButton>
    )

    let paymentDetails = payment_details ? (
      <InfoList
        title={
          <Flex gap="1rem" align="center">
            <div>Реквизиты для оплаты</div>
            {editPaymentDetailsButton}
          </Flex>
        }
      >
        {payment_details.kind === 'bank_account' ? (
          <>
            <InfoListItem label="БИК">{payment_details.bank_account!.rcbic}</InfoListItem>
            <InfoListItem label="Банк">
              <Flex gap="1rem" align="center">
                <span>{payment_details.bank_account!.bank_name}</span>
                <BankIcon bankCode={payment_details.bank_account!.bank_code} />
              </Flex>
            </InfoListItem>
            <InfoListItem label="Номер счёта">
              {payment_details.bank_account!.account}
            </InfoListItem>
            <InfoListItem label="Получатель">
              {payment_details.bank_account!.name}
            </InfoListItem>
          </>
        ) : (
          <InfoListItem label="Номер карты">
            {payment_details.card!.masked_card_number}
          </InfoListItem>
        )}
      </InfoList>
    ) : (
      <InfoList
        title={
          <Flex gap="1rem" align="center">
            <div>Реквизиты для оплаты</div>
            {editPaymentDetailsButton}
          </Flex>
        }
      >
        <InfoListItem
          label={
            contractor.data.is_deleted
              ? 'Пользователь удалил персональные данные'
              : 'Реквизиты ещё не заполнены'
          }
        />
      </InfoList>
    )

    let editPaymentDetailsModal = showDetailsEditModal && (
      <PaymentDetailsEditModal
        contractor={contractor}
        onClose={() => setShowDetailsEditModal(false)}
      />
    )

    let customFields = fields ? (
      <CustomFieldsSection contractor={contractor} fields={fields} />
    ) : null

    // const buttonDeleteAccount =
    //   currentUser.kind !== 'company' ? (
    //     <Button
    //       onClick={onDeleteAccount}
    //       design="outline"
    //       size="s"
    //       style={{ maxWidth: '200px', border: '1px solid red', color: 'red' }}
    //     >
    //       Удалить аккаунт
    //     </Button>
    //   ) : null

    if (isMobile) {
      return (
        <Col gap="var(--gap-l)">
          {personalData}
          {paymentDetails}
          {editPaymentDetailsModal}
          {customFields}
          {/* {buttonDeleteAccount} */}
        </Col>
      )
    }

    return (
      <Flex gap="1rem">
        <Col gap="var(--gap-l)" style={{ flexBasis: 0, flexGrow: 1 }}>
          {personalData}
          {paymentDetails}
          {editPaymentDetailsModal}
          {/* {buttonDeleteAccount} */}
        </Col>
        <Col gap="var(--gap-l)" style={{ flexBasis: 350, flexShrink: 0, flexGrow: 0 }}>
          {customFields}
        </Col>
      </Flex>
    )
  }
)

const ContractorProblems = observer(
  ({ problems, section }: { problems: ContractorProblem[]; section: string }) => {
    let cards = []

    if (problems.includes('is_suspended')) {
      cards.push(
        <Card kind="error" title="Вы приостановили работу с этим исполнителем">
          <div>Это не блокирует работу с исполнителем, просто напоминаем ;)</div>
        </Card>
      )
    }

    if (problems.includes('not_connected_moi_nalog')) {
      cards.push(
        <Card kind="error" title="Исполнитель не подключен к «Мой налог»">
          <div>
            Исполнитель отключил нашу платформу от ФНС, поэтому мы не сможем
            задекларировать доход и отправить оплату.
          </div>
        </Card>
      )
    }

    if (problems.includes('not_registered_moi_nalog')) {
      cards.push(
        <Card kind="error" title="Исполнитель не является самозанятым">
          <div>
            Исполнитель перестал быть самозанятым, поэтому мы не сможем задекларировать
            доход и отправить оплату.
          </div>
        </Card>
      )
    }

    if (section !== 'contract') {
      if (problems.includes('no_contract')) {
        cards.push(
          <Card kind="error" title="Не загружен договор">
            У исполнителя не загружено ни одного договора.
          </Card>
        )
      }
      if (problems.includes('not_signed_contract')) {
        cards.push(
          <Card kind="error" title="Не подписан договор">
            У исполнителя есть договор не подписанный одной из сторон.
          </Card>
        )
      }
    }

    if (problems.includes('no_payment_details')) {
      cards.push(
        <Card kind="error" title="Не указаны реквизиты">
          Исполнитель не указал свои платёжные реквизиты.
        </Card>
      )
    }

    return cards.length > 0 ? <Col gap="1rem">{cards}</Col> : null
  }
)

interface ContractorViewProps {
  fields?: Collection<CustomFieldEntity>
  contractor: Entity<ContractorEntity>
}

const ContractorView = observer(({ contractor, fields }: ContractorViewProps) => {
  let history = useHistory()
  let { isMobile } = useMediaContext()
  let currentUser = useUserStore()
  let toast = useStateToast()

  const isSelfEmployed = contractor.data.kind === 'self_employed'

  let suspend = () =>
    contractor.action({ action: 'suspend' }).then(
      (data) => contractor.setData(data),
      (err) => toast.error({ title: 'Ошибка', description: err.message })
    )

  let unsuspend = () =>
    contractor.action({ action: 'unsuspend' }).then(
      (data) => contractor.setData(data),
      (err) => toast.error({ title: 'Ошибка', description: err.message })
    )

  let [section, setSection] = useHashState(history, 'passport')

  let companySections = [
    { id: 'passport', title: 'Персональные данные' },
    { id: 'contract', title: 'Документы' },
    { id: 'acts', title: 'Акты и задания' },
  ]

  if (contractor.data.attachments && contractor.data.attachments.length > 0) {
    companySections.push({ id: 'reports', title: 'Файлы и отчеты' })
  }

  if (
    currentUser.company?.data.features.check_contractor_spectrum_data ||
    (isSelfEmployed && currentUser.company?.data.features.contractors_blacklist) ||
    (isSelfEmployed && currentUser.company?.data.features.contractors_risk_profiles) ||
    (isSelfEmployed && currentUser.company?.data.features.contractors_risk_placeholder)
  ) {
    companySections.push({ id: 'risks', title: 'Риски' })
  }

  let contractorSections = compact([
    { id: 'passport', title: 'Персональные данные' },
    contractor.data.moi_nalog && { id: 'moi-nalog', title: 'Мой налог' },
  ])
  let sections = currentUser.kind === 'contractor' ? contractorSections : companySections

  let problems = currentUser.kind === 'company' && (
    <ContractorProblems problems={getContractorProblems(contractor)} section={section} />
  )

  let actions
  if (currentUser.kind === 'company') {
    let createTaskButton
    if (currentUser.hasFeature('projects')) {
      let ability = true
      let noAbilityMessage
      if (!currentUser.hasAbility('tasks.create')) {
        ability = false
      } else if (contractor.data.is_suspended) {
        ability = false
        noAbilityMessage = 'Работа с исполнителем приостановлена'
      } else if (contractor.data.contracts.isEmpty) {
        ability = false
        noAbilityMessage = 'У исполнителя нет договора'
      }
      createTaskButton = (
        <AbilityButton
          ability={ability}
          noAbilityMessage={noAbilityMessage}
          onTap={() => history.push(`/tasks/new?contractor_id=${contractor.id}`)}
          size={isMobile ? 's' : 'm'}
        >
          Создать задание
        </AbilityButton>
      )
    }

    let canSuspend = currentUser.hasAbility('contractors.suspend')
    let menu = (
      <div style={{ position: 'relative' }}>
        <Menu placement="bottom-end">
          <MenuButton
            style={{ outline: 'none', minWidth: '3rem', padding: 0 }}
            as={Button}
            design="normal"
          >
            <MoreIcon style={{ fill: 'var(--color-active)' }} />
          </MenuButton>
          <MenuList style={{ minWidth: 275 }}>
            {contractor.data.is_suspended ? (
              <AbilityMenuItem ability={canSuspend} onClick={unsuspend}>
                Возобновить работу с исполнителем
              </AbilityMenuItem>
            ) : (
              <AbilityMenuItem
                ability={canSuspend}
                style={{ color: 'var(--color-red)' }}
                onClick={suspend}
              >
                Приостановить работу с исполнителем
              </AbilityMenuItem>
            )}
          </MenuList>
        </Menu>
      </div>
    )
    actions = (
      <Flex
        style={
          isMobile
            ? { gridRow: 3, gridColumn: 1 }
            : { gridRow: 1, gridColumn: 3, alignSelf: 'self-end' }
        }
        gap="1rem"
      >
        {createTaskButton}
        {menu}
      </Flex>
    )
  }

  return (
    <Col gap="3rem">
      {problems}
      <Col gap="1rem">
        <UserInfoBlock user={contractor.data as any}>{actions}</UserInfoBlock>
      </Col>
      {/*sections.length > 1 && (
        <Chips items={sections} value={section} onChange={setSection} />
      )*/}
      {sections.length > 1 && (
        <div>
          <Tabs noUnderline value={section} onChange={setSection}>
            {sections.map((section, idx) => (
              <Tab key={idx} value={section.id}>
                {section.title}
              </Tab>
            ))}
          </Tabs>
          <Divider
            style={{
              ...(!isMobile && {
                margin: '0',
                marginLeft: '-2em',
                marginRight: '-2em',
                width: 'calc(100% + 4em)',
              }),
            }}
          />
        </div>
      )}
      {currentUser.kind === 'company' ? (
        <>
          {section === 'acts' && (
            <ContractorTasksSection
              tasks={contractor.data.tasks}
              contractor={contractor}
            />
          )}
          {section === 'passport' && (
            <ContractorPersonalDataSection contractor={contractor} fields={fields} />
          )}
          {section === 'contract' && (
            <ContractAndDocumentsForMember contractor={contractor} />
          )}
          {section === 'reports' && <ContractorReports contractor={contractor} />}
          {section === 'risks' && (
            <ContractorRisks
              id={contractor.id}
              name={contractor.data.full_name}
              blacklisted={contractor.data.blacklisted}
              contractor={contractor}
              self_employed={isSelfEmployed}
            />
          )}
        </>
      ) : (
        <>
          {section === 'passport' && (
            <ContractorPersonalDataSection contractor={contractor} fields={fields} />
          )}
          {section === 'moi-nalog' && (
            <ContractorMoiNalogSection contractor={contractor} />
          )}
        </>
      )}
    </Col>
  )
})

const ContractorPage = observer(({ match }: PageProps) => {
  let mobxApi = useMobxApi()
  const location: any = useLocation()
  let id = Number(match.params.id)
  let currentUser = useUserStore()
  let fetchState = useMemo(() => mobxApi.fetch({ type: 'contractors', id }), [])
  let fetchCustomFieldsState = useMemo(
    () =>
      currentUser.kind === 'company'
        ? mobxApi.fetch({ type: 'contractors/custom_fields' })
        : undefined,
    []
  )

  const backLinkUrl = React.useMemo(() => {
    const from = location?.state?.from || false

    if (from) {
      const path = from.split('/')
      const pathWithoutHash = from.split('#')

      if (
        path[1] === 'contractors' &&
        path[2] &&
        location.pathname !== pathWithoutHash[0]
      ) {
        return from
      }
    }

    return '/contractors'
  }, [location.pathname, location.state])

  return (
    <Layout smallPaddingTop={currentUser.kind === 'company'}>
      {currentUser.kind === 'company' && (
        <BackLink to={backLinkUrl}>К исполнителям</BackLink>
      )}
      <ActionStateView state={[fetchState, fetchCustomFieldsState]} animate={false}>
        {([contractor, fields]) => (
          <ContractorView contractor={contractor} fields={fields} />
        )}
      </ActionStateView>
    </Layout>
  )
})

export default ContractorPage
