import React, { useMemo, useState } from 'react'
import { observer } from 'mobx-react-lite'

import { useUserStore, useSignDialogStore } from 'stores/context'
import { Entity, useMobxApi } from 'mobx/mobx'
import {
  DashboardEntity,
  CompanyEntity,
  DocumentEntity,
  RejectedTransactionEntity,
} from 'entities'
import { printDate } from 'utils/datetime'
import CompanyStore from 'pages/companies/CompanyStore'
import {
  ConnectMoiNalogCard,
  TaxPaymentPermissionCard,
  UnpaidTaxesCard,
} from 'pages/taxes/TaxBoxPage'
import { usePusherSubscription } from 'App/pusher'
import { fetchApi } from 'api'

import TaskList from 'components/tasks/TaskList'
import { documentKinds } from 'components/documents/statuses'
import { Avatar } from 'components'
import {
  Layout,
  Flex,
  Card,
  Button,
  ActionStateView,
  Link as UiLink,
  H2,
  useStateToast,
} from 'components/ui'
import TransactionRejectedCard from 'components/paymentDetails/TransactionRejectedCard'
import NoPaymentDetailsCard from 'components/paymentDetails/NoPaymentDetailsCard'
import { BalanceBlock } from './BalanceBlock'
import { TaskList as ScenarioTaskList } from 'pages/scenario_tasks'

const CompanyInviteCard = observer(({ company }: { company: Entity<CompanyEntity> }) => {
  let { name, avatar_url } = company.data
  let toast = useStateToast()
  let signDialog = useSignDialogStore()
  let currentUser = useUserStore()
  // eslint-disable-next-line
  let store = useMemo(() => new CompanyStore(company, toast, currentUser, signDialog), [])
  return (
    <Card
      icon={<Avatar name={name} value={avatar_url} size="36px" />}
      title={
        <>
          Компания <span style={{ fontWeight: 500 }}>"{name}"</span> предлагает вам
          сотрудничество
        </>
      }
      actions={
        <Button
          design="normal"
          size="s"
          onTap={() => store.acceptInvite()}
          isLoading={store.acceptInviteState.state === 'pending'}
          style={{ width: '100%' }}
        >
          Принять приглашение
        </Button>
      }
    ></Card>
  )
})

const DocumentCard = observer(
  ({
    company,
    document,
  }: {
    company: Entity<CompanyEntity>
    document: Entity<DocumentEntity>
  }) => {
    let { name, avatar_url } = company.data
    let toast = useStateToast()
    let signDialog = useSignDialogStore()
    let currentUser = useUserStore()
    let [store] = useState(
      () => new CompanyStore(company, toast, currentUser, signDialog)
    )
    let { date, title, file_url, kind } = document.data
    let documentKind = documentKinds[kind]?.toLowerCase()
    return (
      <Card
        icon={<Avatar name={name} value={avatar_url} size="36px" />}
        title={
          <>
            Подпишите оферту для работы на платформе
          </>
        }
        actions={
          <Button
            design="normal"
            size="s"
            onTap={() => store.openContractSignDialog(document)}
          >
            Подписать
          </Button>
        }
      >
        <UiLink href={file_url} target="_blank">
          {title}{' '}
          <span style={{ color: 'var(--color-secondary)' }}>от {printDate(date)}</span>
        </UiLink>
      </Card>
    )
  }
)

const DashboardView = observer(
  ({ dashboard }: { dashboard: Entity<DashboardEntity> }) => {
    let currentUser = useUserStore()
    let {
      income,
      notifications,
      tax_box,
      companies,
      new_tasks,
      scenario_tasks,
      acts_for_sign,
      recent_acts,
      rejected_transactions,
    } = dashboard.data

    usePusherSubscription('update-dashboard', () => {
      dashboard.fetch()
    })

    let importantBlocks: React.ReactNode[] = []
    let blocks: React.ReactNode[] = []

    let hideNotification = (key: string) => {
      fetchApi({
        url: 'users/events',
        method: 'PUT',
        data: { event: 'hide_dashboard_notification', key },
      })
      dashboard.data.notifications = notifications.filter((item) => item.key !== key)
    }

    notifications.forEach(({ title, content, key }) => {
      blocks.push(
        <Card
          title={title}
          actions={
            <Button onTap={() => hideNotification(key)} size="s" design="normal">
              Понятно
            </Button>
          }
        >
          {content && <div dangerouslySetInnerHTML={{ __html: content }} />}
        </Card>
      )
    })

    let contractor = currentUser.user!.data.contractor!

    let showNoPaymentDetailsCard = contractor.data.show_no_payment_details
    if (showNoPaymentDetailsCard) blocks.push(<NoPaymentDetailsCard />)
    let showRejectedTransactions =
      Boolean(rejected_transactions) && !rejected_transactions?.isEmpty
    let hideRejectedTransaction =
      (transaction: Entity<RejectedTransactionEntity>) => () => {
        dashboard.data.rejected_transactions!.remove(transaction)
      }

    if (showRejectedTransactions)
      rejected_transactions?.items.forEach((transaction) =>
        importantBlocks.push(
          <TransactionRejectedCard
            transaction={transaction}
            onSuccess={hideRejectedTransaction(transaction)}
          />
        )
      )

    let showConnectMoiNalogCard =
      contractor.data.moi_nalog && contractor.data.moi_nalog.status !== 'connected'
    if (showConnectMoiNalogCard) importantBlocks.push(<ConnectMoiNalogCard />)

    let showUnpaidTaxesCard =
      tax_box &&
      tax_box.data.unpaid_taxes &&
      !tax_box.data.unpaid_taxes.enabled_withholding
    let [keepShowingUnpaidTaxesCard, setKeepShowingUnpaidTaxesCard] = useState(false)
    if (tax_box) {
      if (!tax_box.data.tax_payment_permission) {
        importantBlocks.push(<TaxPaymentPermissionCard account={tax_box} />)
      }
      if (showUnpaidTaxesCard || keepShowingUnpaidTaxesCard) {
        importantBlocks.push(
          <UnpaidTaxesCard
            account={tax_box}
            onEnableWitholding={() => setKeepShowingUnpaidTaxesCard(true)}
          />
        )
      }
    }

    companies.items.forEach((company) => {
      if (company.data.invite_status === 'not_accepted') {
        blocks.push(<CompanyInviteCard company={company} />)
      }
    })
    companies.items.forEach((company) => {
      let isUnsigned = (item: Entity<DocumentEntity>) =>
        ['created', 'signed_by_legal_entity'].includes(item.data.status)
      let unsignedContracts = company.data.contracts.items.filter(isUnsigned)
      let unsignedDocuments = company.data.documents.items.filter(isUnsigned)
      ;[...unsignedContracts, ...unsignedDocuments].forEach((document) =>
        blocks.push(<DocumentCard company={company} document={document} />)
      )
    })

    let tasks
    if (!currentUser.user!.data.flags.scenario_tasks && !new_tasks.isEmpty) {
      tasks = (
        <Flex direction="column" gap="1.5rem">
          <H2>Новые задания</H2>
          <TaskList
            tasks={new_tasks}
            baseUrl="/dashboard"
            options={{
              kind: 'tasks',
              who: true,
              isSelectable: false,
              isEditable: false,
              actionButtons: true,
            }}
          />
        </Flex>
      )
    } else if (
      currentUser.user!.data.flags.scenario_tasks &&
      (!scenario_tasks?.isEmpty || !new_tasks.isEmpty)
    ) {
      tasks = (
        <Flex direction="column" gap="1.5rem">
          <H2>Задания</H2>
          {scenario_tasks && !scenario_tasks?.isEmpty && (
            <ScenarioTaskList baseUrl="/dashboard" tasks={scenario_tasks} />
          )}
          {!new_tasks.isEmpty && (
            <TaskList
              tasks={new_tasks}
              baseUrl="/dashboard"
              options={{
                kind: 'tasks',
                who: true,
                isSelectable: false,
                isEditable: false,
                actionButtons: true,
              }}
            />
          )}
        </Flex>
      )
    }

    let actsForSign
    if (!acts_for_sign.isEmpty) {
      actsForSign = (
        <Flex direction="column" gap="1.5rem">
          <H2>Инвойсы на подпись</H2>
          <TaskList
            baseUrl="/dashboard"
            tasks={acts_for_sign}
            options={{
              kind: 'acts',
              who: true,
              isSelectable: false,
              isEditable: false,
              actionButtons: true,
            }}
          />
        </Flex>
      )
    }

    let recentActs
    if (!recent_acts.isEmpty) {
      recentActs = (
        <Flex direction="column" gap="1.5rem">
          <H2>Последние инвойсы</H2>
          <TaskList
            baseUrl="/dashboard"
            tasks={recent_acts}
            options={{
              kind: 'acts',
              who: true,
              isSelectable: false,
              isEditable: false,
              actionButtons: true,
            }}
          />
        </Flex>
      )
    }

    return (
      <Layout>
        <Flex direction="column" gap="3rem">
          {importantBlocks}
          <BalanceBlock income={income} taxBox={tax_box} />
          {blocks.length > 0 && (
            <Flex direction="column" gap="1.5rem" style={{ maxWidth: 750 }}>
              {blocks}
            </Flex>
          )}
          {tasks}
          {actsForSign}
          {recentActs}
        </Flex>
      </Layout>
    )
  }
)

const DashboardPage = observer(() => {
  let api = useMobxApi()
  let [fetchState] = useState(() => api.fetch({ type: 'dashboard' }))
  return (
    <ActionStateView state={fetchState}>
      {(dashboard) => <DashboardView dashboard={dashboard} />}
    </ActionStateView>
  )
})

export default DashboardPage
