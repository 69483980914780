import fetchJson, { FetchParams, FetchError } from './fetchData'
import { getBaseUrl, getDefaultHeaders } from './apiDefaults'

interface ApiFetchParams extends FetchParams {
  withPrefix?: boolean
}

const fetchApi = ({ url, withPrefix = true, headers, ...rest }: ApiFetchParams) => {
  let params = {
    url: getBaseUrl({ withPrefix }) + '/' + url,
    headers: {
      ...getDefaultHeaders(),
      ...headers,
    },
    ...rest,
  }
  return fetchJson(params).catch((error: any) => {
    console.log('error: ', error)
    if (error.kind === 'http' && error.response.status === 401) {
      setTimeout(() => {
        // @ts-ignore
        window.userStore.logout(false)
      }, 500)
      throw new FetchError({ kind: 'application', message: 'Ошибка авторизации' })
    }
    if (error.kind === 'http' && error.data?.error?.message) {
      throw new FetchError({
        kind: 'application',
        message: error.data.error.message,
        data: error.data?.error,
      })
    }
    throw error
  })
}

export default fetchApi
