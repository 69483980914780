import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useTitle } from 'react-use'

import { useMobxApi } from 'mobx/mobx'
import { useUserStore } from 'stores/context'
import { fetchJson, baseUrl } from 'api'

import { ActionStateView, useStateToast } from 'components/ui'

import { ConnectToPlatformStore } from './store/ConnectToPlatformStore'
import RegistrationView from './components/RegistrationView'

//@ts-ignore
window.checkSuccess = () =>
  fetchJson({
    url: `${baseUrl}/registration/check_success`,
    method: 'PUT',
  })

const RegistrationPage = observer(() => {
  useTitle('Restaff - Регистрация')

  const api = useMobxApi()
  const toast = useStateToast()
  const currentUser = useUserStore()
  const [store] = useState(() => new ConnectToPlatformStore(currentUser, toast, api))

  useEffect(() => {
    return () => store.deconstruct()
  }, [])

  return (
    <ActionStateView state={store.registrationActionState}>
      {() => <RegistrationView registration={store.registration} store={store} />}
    </ActionStateView>
  )
})

export default RegistrationPage
