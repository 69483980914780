import React from 'react'
import { Button, P1 } from 'ui-kit'
import { Modal } from 'ui-kit'

import css from './styles.module.css'

type HowItWorksModalProps = {
  open: boolean
  onClose: () => void
}

const HowItWorksModal: React.FunctionComponent<HowItWorksModalProps> = ({
  open,
  onClose,
}) => (
  <Modal title="Как работает копилка" open={open} onClose={onClose}>
    <P1 className={css.paragraph}>
      Копилка автоматически откладывает часть суммы с ваших доходов, чтобы хватило на
      оплату налогов. Когда придёт налог — он будет оплачен из накопленной суммы
      автоматически.
    </P1>
    <P1 className={css.paragraph}>
      – Откладывает 6% с доходов от юрлиц и 4% с доходов от физлиц.
    </P1>
    <P1 className={css.paragraph}>
      – Учитывает налоговый бонус и откладывает чуть меньше пока бонус не израсходуется.
    </P1>
    <P1 className={css.paragraph}>
      – Учитывает доходы, задекларированные не в Консоли.{'\n'}Например: вы работаете
      одновременно в двух компаниях. С одной из них — через Restaff. После декларации
      дохода в других местах Копилка автоматически рассчитает сумму налога и отложит со
      следующей выплаты.
    </P1>
    <Button onClick={onClose} label="Понятно" fullWidth />
  </Modal>
)

export default HowItWorksModal
