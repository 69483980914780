import React from 'react'
import cl from 'clsx'
import { Typography, TypographyVariant } from 'ui-kit'
import { formatAmount } from 'utils/amount'
import css from './styles.module.css'

export enum AmountDifference {
  Positive = '+',
  Negative = '-',
}

export type AmountProps = {
  value?: number | string
  scale?: number
  separator?: string
  typographyVariant?: TypographyVariant
  className?: string
  difference?: AmountDifference
}

const Amount: React.FunctionComponent<AmountProps> = ({
  value,
  scale,
  separator,
  difference,
  typographyVariant = TypographyVariant.Span,
  className,
}) => (
  <Typography
    variant={typographyVariant}
    className={cl(css.amount, className && className)}
  >
    {difference && difference}
    {value || value === 0 ? formatAmount(value, scale, separator) : ''}
  </Typography>
)

export default Amount
