import * as React from 'react'
import { observer } from 'mobx-react-lite'
import { Collection } from 'mobx/MobxApi'
import { Col, Placeholder } from 'components/ui'

import { useMediaContext } from 'App/MediaContext'

import { TaskListItem } from './TaskListItem'

type ScenarioTaskEntity = any;

export const TaskList = observer(
  ({ tasks, baseUrl }: { tasks: Collection<ScenarioTaskEntity>; baseUrl?: string }) => {
    const { isMobile } = useMediaContext()

    if (tasks.isEmpty) return <Placeholder>Заданий нет</Placeholder>

    return (
      <Col gap={isMobile ? '16px' : '20px'}>
        {tasks.items.map((task) => (
          <TaskListItem task={task} key={task.id} baseUrl={baseUrl} />
        ))}
      </Col>
    )
  }
)
