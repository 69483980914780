import React from 'react'
import { observer } from 'mobx-react-lite'
import { Link } from 'react-router-dom'

import { Avatar } from 'components'
import { useUserStore } from 'stores/context'
import { useSize } from 'react-use'

import { ReactComponent as Hamburger } from './hamburger.svg'
import s from './styles.module.css'

import { Logo } from 'ui'

interface MobileHeaderProps {
  banners?: React.ReactNode
  onOpenSidebar: () => void
}

let MobileHeader = observer(({ banners, onOpenSidebar }: MobileHeaderProps) => {
  let currentUser = useUserStore()
  let user = currentUser.user
  let [sized, { height }] = useSize(
    () => (
      <div className={s.root}>
        {banners}
        <div className={s.header}>
          <div style={{ position: 'relative' }}>
            <Hamburger fill="var(--color-icon)" onClick={onOpenSidebar} />
          </div>
          <Link to="/" className={s.logo}>
            <Logo />
          </Link>
          <Link
            to={
              currentUser.isContractor
                ? `/contractors/${user?.data.contractor?.id}`
                : '/profile'
            }
          >
            <Avatar value={user?.data.avatar_url} size="40px" name={user?.data.name} />
          </Link>
        </div>
      </div>
    ),
    { height: 0 }
  )
  return (
    <>
      {sized}
      <div style={{ height }} />
    </>
  )
})

export default MobileHeader
