import 'core-js/modules/es.object.entries'
import 'core-js/modules/es.object.values'

import React from 'react'
import ReactDOM from 'react-dom'

import { isProductionEnvironment, isDevelopment } from 'thirdParty'
import localStorageObservable from 'utils/localStorageObservable'

import './index.css'
import App from './App'

if (isDevelopment()) {
  const worker = require('./mocks/index').default
  worker.start()
}

if (isProductionEnvironment()) {

  localStorageObservable('user_data').dispose()
}

ReactDOM.render(<App />, document.getElementById('root'))
