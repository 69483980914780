import * as React from 'react'
import { observer } from 'mobx-react-lite'
import { Placemark } from 'react-yandex-maps'
import locationIcon from './location.svg'

type MarkerProps = {
  lat: number
  lng: number
  hintContent?: string
  baloonContent?: string
  icon?: string
}

export const Marker = observer(
  ({ lat, lng, icon = locationIcon, hintContent, baloonContent }: MarkerProps) => (
    //@ts-ignore
    <Placemark
      geometry={[lat, lng]}
      properties={{
        hintContent: hintContent,
        balloonContent: baloonContent,
      }}
      options={{
        iconLayout: 'default#image',
        // Custom image for the placemark icon.
        iconImageHref: icon,
        // The size of the placemark.
        // iconImageSize: [24, 24],
        // The offset of the upper left corner of the icon relative
        // to its "tail" (the anchor point).
        // iconImageOffset: [-3, -42],
      }}
    ></Placemark>
  )
)
