import { rest } from 'msw'

import { json } from './helpers'

const custom_fields = [
  { id: '1', kind: 'text', name: 'Комментарий' },
  { id: '2', kind: 'date', name: 'Дата' },
  {
    id: '3',
    kind: 'dropdown',
    name: 'Специальность',
    options: [
      { id: '4', name: 'Работяга' },
      { id: '5', name: 'Большой молодец' },
      { id: '6', name: 'Велокурьер' },
      { id: '7', name: 'Тест' },
      { id: '8', name: 'Тест' },
      { id: '9', name: 'Тест' },
      { id: '10', name: 'Тест' },
      { id: '11', name: 'Тест' },
      { id: '12', name: 'Тест' },
      { id: '13', name: 'Тест' },
      { id: '14', name: 'Тест' },
      { id: '15', name: 'Тест' },
    ],
  },
  { id: '20', kind: 'checkbox', name: 'Флажок' },
  { id: '21', kind: 'number', name: 'Номер' },
  { id: '22', kind: 'numberRange', name: 'numberRange' },
  { id: '24', kind: 'text', name: 'hello' },
]

export default [
  // rest.get('/api/company/contractors/custom_fields', json([])),
  rest.get('/api/company/contractors/custom_fields', json(custom_fields)),
  // rest.get('/api/company/contractors/custom_fields/:id/counter', json({ count: 0 })),
  rest.get('/api/company/contractors/custom_fields/:id/counter', json({ count: 10 })),
  rest.delete('/api/company/contractors/custom_fields/:id', json({})),

  rest.patch('/api/company/contractors/:id1/custom_fields/:id2', json({})),
]
