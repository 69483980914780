import React, { useState, useMemo, useLayoutEffect, useRef, useCallback } from 'react'
import { useClickAway, useKey } from 'react-use'
import FocusLock from 'react-focus-lock'
import { SpringConfig } from 'react-spring'

// import { useStyles, StyleProps, StylesMap } from './styled'
import type { AnimationFunction, Side } from './animation/functions'
import {
  OpenAnimation,
  springConfigs,
  useAnimatedValue,
  animationFunctions,
} from './animation'
import { Layer } from './layers'
import { Popup } from './popup'
import {
  defaultPlacement,
  oppositeSides,
  PopupPlacement,
  PopupSide,
} from './popup/PopupController'
import { useViewport } from './viewport'
import mergeRefs from './utils/mergeRefs'
import useControlledState from './utils/useControlledState'
import useMeasure from './utils/useMeasure'

interface DropdownPopupRenderProps {
  style: React.CSSProperties
}

interface DropdownRenderProps {
  isOpen: boolean
  open: () => void
  close: () => void
}

interface DropdownProps {
  /** Content of the dropdown menu */
  popup: (ref: any, props: DropdownPopupRenderProps) => React.ReactNode

  /** Trigger element that dropdown will be attached to */
  children: (ref: any, props: DropdownRenderProps) => React.ReactNode

  /** Placement of the popup relative to the target */
  placement?: Partial<PopupPlacement>

  /** Function for the open and close animation */
  animation: (side: Side) => AnimationFunction | AnimationFunction[]

  /** Maximum height of the popup, in px. */
  maxHeight?: number

  /** Maximum width of the popup, in px. */
  maxWidth?: number

  /** If `true`, popup width will match the width of the button element. */
  matchWidth?: boolean

  /** Config for `react-spring` animation */
  springConfig?: SpringConfig

  /** Locks focus inside the popup when it opens */
  focusLock?: boolean

  /** Renders transparent overlay to cover page */
  overlay?: boolean

  /** Popup will close on click outside */
  closeOnClickOutside?: boolean

  /** Popup will close on presssing Esc key */
  closeOnEsc?: boolean
}

const defaultProps = {
  placement: { ...defaultPlacement, constrain: true, padding: 16 },
  animation: (side: PopupSide) => [
    animationFunctions.fade(),
    animationFunctions.slide({ side: oppositeSides[side] }),
  ],
  springConfig: springConfigs.stiff,
  overlay: false,
  focusLock: false,
  closeOnClickOutside: true,
}

const Dropdown = (_props: DropdownProps) => {
  const props = _props as DropdownProps & typeof defaultProps
  const {
    popup,
    children,
    maxHeight,
    maxWidth,
    placement,
    matchWidth,
    animation,
    focusLock,
    springConfig,
    overlay,
    closeOnClickOutside,
  } = props

  const [isOpen, setIsOpen] = useControlledState(props, 'isOpen', false)
  const [openValue, isRest] = useAnimatedValue(isOpen ? 1 : 0, { config: springConfig })
  const [side, setSide] = useState<PopupSide>('top')
  const isActive = isOpen || !isRest

  const triggerRef = useRef<HTMLElement>(null)
  const open = useCallback(() => setIsOpen(true), [])
  const close = useCallback(() => {
    setIsOpen(false)
    setTimeout(() => {
      if (focusLock) triggerRef.current?.focus?.()
    })
  }, [focusLock])
  const [measureRef, { width: triggerWidth }] = useMeasure(Boolean(matchWidth))
  const viewport = useViewport()
  const [contrainedMaxHeight, setConstrainedMaxHeight] = useState(0)
  useLayoutEffect(() => {
    if (!isActive) return
    let availableHeight = viewport.height - 2 * (placement?.padding || 0)
    if (maxHeight !== undefined) {
      availableHeight = Math.min(availableHeight, maxHeight)
    }
    setConstrainedMaxHeight(availableHeight)
  }, [isActive, maxHeight, placement, viewport.height])
  const animationFunction = useMemo(() => animation(side), [side, animation])
  const clickAwayRef = useRef(null)
  useClickAway(clickAwayRef, () => {
    if (closeOnClickOutside) close()
  })
  useKey('Escape', close)

  const animatedPopup = (ref: any) => (
    <OpenAnimation animation={animationFunction as any} openValue={openValue}>
      <FocusLock disabled={!focusLock || !isOpen}>
        {popup(mergeRefs(ref, clickAwayRef), {
          style: {
            maxHeight: contrainedMaxHeight,
            minWidth: matchWidth ? triggerWidth : 'auto',
            maxWidth,
          },
        })}
      </FocusLock>
    </OpenAnimation>
  )

  const renderProps: DropdownRenderProps = { isOpen, open, close }
  return (
    <>
      {isActive && overlay && (
        <Layer type="popup" isActive={true}>
          <div
            onClick={close}
            onDragStart={(e) => e.preventDefault()}
            style={{
              background: 'transparent',
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              userSelect: 'none',
              pointerEvents: isOpen ? 'all' : 'none',
            }}
          />
        </Layer>
      )}
      <Popup
        placement={placement}
        isActive={isActive}
        onChangeSide={setSide}
        popup={animatedPopup}
      >
        {(ref) => children(mergeRefs(ref, triggerRef, measureRef), renderProps)}
      </Popup>
    </>
  )
}

Dropdown.defaultProps = defaultProps

Dropdown.displayName = 'Dropdown'

export { Dropdown }
