import React, { useState, useEffect, useCallback } from 'react'
import ReactDatepicker, { registerLocale } from 'react-datepicker'
import ru from 'date-fns/locale/ru'
import 'react-datepicker/dist/react-datepicker.css'

import { StylesMap } from 'ui/styled'
import { PopupPlacement } from 'ui/popup/PopupController'
import { Button } from 'ui/konsol/button'
import { Dropdown } from 'ui/dropdown'
import { Input, InputProps } from 'ui/konsol/input'

import { ReactComponent as DatepickerIcon } from 'ui/konsol/icons/datepicker.svg'
import { ReactComponent as ArrowLeftIcon } from 'ui/konsol/icons/arrow-left.svg'
import { ReactComponent as ArrowRightIcon } from 'ui/konsol/icons/arrow-right.svg'

import css from './styles.module.css'
import dayjs from './dayjs'

registerLocale('ru', ru)

const customHeaderStyles: StylesMap = {
  root: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 6px',
  },
  text: {
    textAlign: 'center',
    flexGrow: 1,
    fontWeight: 600,
  },
}

interface CustomHeaderProps {
  date: Date
  decreaseMonth: () => void
  increaseMonth: () => void
  prevMonthButtonDisabled: boolean
  nextMonthButtonDisabled: boolean
}

const CustomHeader = (props: CustomHeaderProps) => {
  const {
    date,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
  } = props
  const styles = customHeaderStyles
  const text = dayjs(date).format('MMMM YYYY')
  return (
    <div style={styles.root}>
      <Button
        onTap={decreaseMonth}
        isDisabled={prevMonthButtonDisabled}
        icon={<ArrowLeftIcon />}
        size="s"
        variant="text"
      />
      <div style={styles.text}>{text}</div>
      <Button
        onTap={increaseMonth}
        isDisabled={nextMonthButtonDisabled}
        icon={<ArrowRightIcon />}
        size="s"
        variant="text"
      />
    </div>
  )
}

export type DateRange = [Date | null, Date | null]

interface DatePickerCalendarProps {
  excludeDates?: Date[]
  value?: Date | DateRange
  onChange: (date: Date | DateRange | undefined) => void
  minDate?: Date
  selectsRange?: boolean
}

const DatePickerCalendar = (props: DatePickerCalendarProps) => {
  const { value, minDate, onChange, selectsRange, excludeDates } = props

  const onSelect = (value: Date | DateRange | null) => onChange(value || undefined)

  let pickerProps
  if (selectsRange) {
    const range = value as DateRange | undefined
    pickerProps = { selected: range?.[0], startDate: range?.[0], endDate: range?.[1] }
  } else {
    pickerProps = { selected: value as Date | undefined }
  }
  return (
    <ReactDatepicker
      excludeDates={excludeDates}
      inline
      locale="ru"
      selectsRange={selectsRange}
      minDate={minDate}
      onChange={onSelect}
      disabledKeyboardNavigation
      renderCustomHeader={(props) => <CustomHeader {...props} />}
      calendarClassName={css.calendar}
      {...pickerProps}
    />
  )
}

type DatePickerInputProps = Pick<
  InputProps,
  | 'autofocus'
  | 'isInvalid'
  | 'isDisabled'
  | 'onFocus'
  | 'onBlur'
  | 'size'
  | 'mask'
  | 'withLeftAddon'
  | 'withRightAddon'
>

interface DatePickerProps extends Partial<DatePickerInputProps> {
  excludeDates?: Date[]
  value?: Date | DateRange
  onChange: (date: Date | DateRange | undefined) => void
  minDate?: Date
  format?: string
  mask?: (string | RegExp)[]
  rangeMask?: (string | RegExp)[]
  selectsRange?: boolean
  style?: React.CSSProperties
}

const defaultPlacement: PopupPlacement = {
  side: 'bottom',
  align: 'start',
  offset: 4,
  flip: true,
  constrain: false,
  padding: 16,
}

const defaultProps = {
  format: 'DD.MM.YYYY',
  placeholder: 'дд.мм.гггг',
  mask: [/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/],
  rangeMask: [
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    '-',
    ' ',
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ],
  placement: defaultPlacement,
}

const printDate = (date: Date | DateRange | undefined, format: string) => {
  if (Array.isArray(date)) {
    const [from = null, to = null] = date
    const since = from ? dayjs(from).format(format) : '...'
    const upto = to ? dayjs(to).format(format) : '...'
    return `${since}-${upto}`
  } else if (date) {
    return dayjs(date).format(format)
  } else {
    return ''
  }
}

const parseDate = (
  value: string,
  format: string,
  selectsRange?: boolean
): Date | DateRange | undefined => {
  if (value.includes('-') && selectsRange) {
    const [from = null, to = null] = value.split('-').map((subdate) => subdate.trim())
    const sinceDay = dayjs(from, format, true)
    const uptoDay = dayjs(to, format, true)
    if (sinceDay.isValid() && uptoDay.isValid()) {
      return [sinceDay.toDate(), uptoDay.toDate()]
    }
  } else if (!selectsRange) {
    const day = dayjs(value, format, true)
    if (day.isValid()) {
      return day.toDate()
    }
  }
  return
}

const DatePicker = (_props: DatePickerProps) => {
  const props = _props as DatePickerProps & typeof defaultProps

  const {
    value,
    onChange,
    format,
    minDate,
    onFocus,
    onBlur,
    placement,
    selectsRange,
    ...restInputProps
  } = props

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [inputValue, setInputValue] = useState(printDate(value, format))

  useEffect(() => {
    setInputValue(printDate(value, format))
  }, [value, format])

  const onSelect = useCallback(
    (value: Date | DateRange | undefined) => {
      onChange(value as Date | undefined)
      if (Array.isArray(value)) {
        const [from, to] = value
        if (from && to) {
          setIsOpen(false)
        }
      } else {
        setIsOpen(false)
      }
    },
    [onChange]
  )

  const onTap = useCallback(() => setIsOpen(!isOpen), [isOpen, setIsOpen])

  const onInputChange = (value: string) => {
    const parsed = parseDate(value, format, selectsRange)
    if (parsed) {
      onChange(parsed)
    } else {
      setInputValue(value)
    }
  }

  const onInputFocus = () => {
    setIsOpen(true)
    onFocus?.()
  }

  const onInputBlur = () => {
    const parsed = parseDate(inputValue, format, selectsRange)
    if (parsed) {
      setInputValue(printDate(parsed, format))
    }
    setIsOpen(false)
    onBlur?.()
  }

  const onTapDatePicker = (e: any) => {
    // prevent input blur
    e.preventDefault()
  }

  const popup = (ref: any) => (
    <div ref={ref} onMouseDown={onTapDatePicker} className={css.popup}>
      <DatePickerCalendar
        value={value}
        minDate={minDate}
        onChange={onSelect}
        selectsRange={selectsRange}
      />
    </div>
  )

  const hasTwoDays = [
    /**
     * Как значение получен массив
     */
    Array.isArray(value),
    /**
     * Указана первая дата
     */
    Array.isArray(value) && !!(value as any)[0],
    /**
     * Указана вторая дата
     */
    Array.isArray(value) && !!(value as any)[1],
    /**
     * Даты не одинаковые
     */
    Array.isArray(value) && !((value as any)[0]?.getTime() - (value as any)[1]?.getTime() === 0),
  ].reduce((acm, cur) => acm && cur)

  const isRangeFullDay = selectsRange && !hasTwoDays

  const managedValue = isRangeFullDay ? printDate((value as any)[0], format) : inputValue

  const managedMask = props.selectsRange
    ? (hasTwoDays || isOpen)
      ? props.rangeMask
      : props.mask
    : props.mask

  return (
    <Dropdown
      // @ts-ignore
      isOpen={isOpen}
      onChangeIsOpen={setIsOpen}
      popup={popup}
      matchWidth={false}
      overlay={false}
      focusLock={false}
      closeOnClickOutside={false}
      placement={placement}
    >
      {(ref) => (
        <Input
          ref={(handle) => ref(handle?.ref.current)}
          value={managedValue}
          rightIcon={<DatepickerIcon />}
          onChange={onInputChange}
          onFocus={onInputFocus}
          onBlur={onInputBlur}
          onMouseDown={onTap}
          styles={{ input: { fontVariant: 'tabular-nums' } }}
          {...restInputProps}
          mask={managedMask}
        />
      )}
    </Dropdown>
  )
}

DatePicker.defaultProps = defaultProps

export { DatePicker, DatePickerCalendar }
