export const useTheme = () => {
  const config = require(`themes/${process.env.REACT_APP_THEME}/config.json`)
  const getStyles = () => require(`themes/${process.env.REACT_APP_THEME}/index.css`)

  const getLogo = async (simple = false) => {
    return (
      await import(
        /* webpackMode: "eager" */ `!@svgr/webpack!themes/${
          process.env.REACT_APP_THEME
        }/logo${simple ? '.simple' : ''}.svg`
      )
    ).default
  }

  const getSidebarIcon = async (name: string) => {
    return (
      await import(
        /* webpackMode: "eager" */ `!@svgr/webpack!themes/${process.env.REACT_APP_THEME}/assets/icons/sidebar/${name}.svg`
      )
    ).default
  }

  const getJoinIcon = async (name: string) => {
    return (
      await import(
        /* webpackMode: "eager" */ `!@svgr/webpack!themes/${process.env.REACT_APP_THEME}/assets/icons/join/${name}.svg`
      )
    ).default
  }

  const getQRcode = async () => {
    return (
      await import(
        /* webpackMode: "eager" */ `!@svgr/webpack!themes/${process.env.REACT_APP_THEME}/assets/download-app-qr.svg`
      )
    ).default
  }

  return {
    config,
    getStyles,
    getLogo,
    getSidebarIcon,
    getJoinIcon,
    getQRcode,
  }
}
