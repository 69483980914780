import MobxApi from 'mobx/MobxApi'
import createBackendAdapter from 'utils/createBackendAdapter'

const entityTypes = {
  attachments: {
    schema: {},
  },
  projects: {
    schema: {
      tasks: ['tasks'],
    },
  },
  'projects/page': {
    schema: {
      items: ['projects'],
    },
  },
  tasks: {
    schema: {
      projects: ['projects'],
      contractor: 'contractors',
      contract: 'documents',
      company: 'companies',
      task: {
        attachments: ['attachments'],
      },
      act: {
        attachments: ['attachments'],
      },
    },
  },
  scenario_tasks: {
    schema: {
      company: 'companies',
    },
  },
  'contractor_risk_profiles': {
    schema: {
    },
  },
  'scenario_tasks/page': {
    schema: {
      items: ['scenario_tasks'],
    },
  },
  'scenario_projects/page': {
    schema: {
      items: ['scenario_tasks'],
    },
  },
  'onboarding_scenarios': {
    schema: {
      items: ['onboarding_scenarios']
    },
  },
  'scenario_projects': {
    schema: {
      items: ['scenario_projects']
    },
  },
  'templates': {
    schema: {},
  },
  'tasks/page': {
    schema: {
      items: ['tasks'],
    },
  },
  tokens: {
    schema: {},
  },
  bundles: {
    schema: {
      acts: ['tasks'],
    },
  },
  'bundles/page': {
    schema: {
      items: ['bundles'],
    },
  },
  users: {
    schema: {
      companies: ['companies'],
      contractor: 'contractors',
    },
  },
  'user/profile': {
    schema: {},
  },
  registration: {
    schema: {
      contract: 'documents',
    },
  },
  document_templates: {
    schema: {},
  },
  'documents/page': {
    schema: {
      items: ['documents'],
    },
  },
  documents: {
    schema: {
      contractor: 'contractors',
    },
  },
  'tax_boxes/operations': { schema: {} },
  tax_boxes: {
    schema: {
      operations: ['tax_boxes/operations'],
    },
  },
  'tax_savings_accounts/operations': { schema: {} },
  tax_savings_accounts: {
    schema: {
      operations: ['tax_savings_accounts/operations'],
    },
  },
  contractors: {
    schema: {
      tasks: ['tasks'],
      contracts: ['documents'],
      documents: ['documents'],
      companies: ['companies'],
      tax_savings_account: 'tax_savings_accounts',
      tax_box: 'tax_boxes',
    },
  },
  'contractors/page': {
    schema: {
      items: ['contractors'],
    },
  },
  'contractors/invites': {
    schema: {
      contract: 'documents',
    },
  },
  'contractors/custom_fields': {
    schema: {
      options: 'contractors/custom_fields',
    },
  },
  companies: {
    schema: {
      nominal_account: 'nominal_accounts',
      contracts: ['documents'],
      documents: ['documents'],
      legal_entities: ['legal_entities'],
      members: ['companies/members'],
      contract_templates: ['document_templates'],
      document_templates: ['document_templates'],
    },
  },
  'companies/member_invites': {
    schema: {},
  },
  'companies/members': {
    schema: {},
  },
  legal_entities: {
    schema: {
      contract: 'documents',
    },
  },
  nominal_accounts: {
    schema: {
      operations: ['operations'],
    },
  },
  operations: {
    schema: {},
  },
  'tinkoff-registers': {
    schema: {
      tasks: ['tasks'],
    },
  },
  'qiwi-registers': {
    schema: {
      tasks: ['tasks'],
    },
  },
  'external-registers': {
    schema: {
      tasks: ['tasks'],
    },
  },
  'payment-registers': {
    schema: {
      tasks: ['tasks'],
    },
  },
  'subtasks/templates': {
    schema: {},
  },
  'subtasks/units': {
    schema: {},
  },
  notifications: {
    schema: {},
  },
  dashboard: {
    schema: {
      new_tasks: ['tasks'],
      acts_for_sign: ['tasks'],
      recent_acts: ['tasks'],
      tax_box: 'tax_boxes',
      companies: ['companies'],
      rejected_transactions: ['rejected_transactions'],
      scenario_tasks: ['scenario_tasks'],
    },
  },
  rejected_transactions: {
    schema: {
      task: 'tasks',
    },
  },
  segments: {
    schema: {},
  },
  offers: {
    schema: {
      company: 'companies',
      response: 'offers/responses',
      responses: ['offers/responses'],
    },
  },
  'offers/for_date': {
    schema: {
      offer: 'offers',
      response: 'offers/responses',
    },
  },
  'offers/responses': {
    schema: {
      offer: 'offers',
      contractor: 'contractors',
    },
  },
  'offers/workplaces': {
    schema: {
      offers: ['offers'],
    },
  },
  tariffs: {
    schema: {},
  },
  'tariffs/documents': {
    schema: {},
  },
  'tariffs/documents/for_sign': {
    schema: {},
  },
}

const createMobxApi = () => {
  let backend = createBackendAdapter()
  return new MobxApi(entityTypes, backend)
}

export default createMobxApi
