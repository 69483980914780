import { rest } from 'msw'
import { range } from 'lodash'

import { json } from './helpers'

import { members } from './companies'
import { contract } from './contracts'

// @ts-ignore
// window.IS_MOBILE_APP = true

let abilities = {
  companies: {
    add_member: true,
  },

  company_members: {
    create: true,
    update: true,
    destroy: true,
    allow_invite_roles: ['accountant', 'manager', 'manager_with_sign', 'readonly'],
  },

  // Исполнители
  contractors: {
    suspend: true,
    update_custom_fields: true,
  },

  'contractors/custom_fields': {
    update: true,
  },

  // Договоры исполнителей
  contracts: {
    create: true,
    destroy: true,
    update: true,
    sign: true,
  },

  // Документы исполнителей
  documents: {
    create: true,
    destroy: true,
    update: true,
    sign: true,
    archive: true,
  },

  // Приглашения
  contractor_invites: {
    create: true,
    cancel: true,
    resume: true,
  },

  // Шаблоны заданий
  subtask_templates: {
    create: true,
    destroy: true,
  },

  // Задания и акты
  tasks: {
    create: true, // создать задачу
    assign: true, // назначить черновик
    update: true, // изменить задачу или черновик
    sign_terms: true, // подписать ТЗ
    cancel: true, // отменить задание
    reject: true, // отклонить отчет
    accept: true, // подписать акт
    pay: true, // оплатить
    mark_paid: true,
    mark_paid_with_receipt: true,
    autopay: true, // одобрить автооплату
    cancel_autopay: true, // отменить автооплату
    delete: true,
    annul: true, // аннулировать акт
    destroy: true, // удалить (черновик)
  },

  // Проекты
  projects: {
    create: true,
    update: true,
    destroy: true,
  },

  // Пачки
  bundles: {
    create: true,
    update: true,
    destroy: true,
  },

  // Номинальный счёт компании
  nominal_accounts: {
    show: true,
  },

  // Сохранённые сегменты
  segments: {
    create: true,
    update: true,
    destroy: true,
  },

  // Отклики на задания
  'offers/responses': {
    invite: true,
    assign: true,
    decline: true,
  },

  tokens: {
    index: true,
    update: true,
    destroy: true,
    create: true,
  },
}

const manyCompanies = range(1, 20).map((i) => ({
  id: 100 + i,
  name: `Компания ${i}`,
  avatar_url: null,
  currency: 'RUB',
  legal_entities: [
    {
      id: 75445,
      inn: '3123011520',
      ogrn: '1027739328440',
      kpp: '772601001',
      business_name: 'Общество с ограниченной ответственностью “Клади-забивай”',
      legal_address: '117648,г. Москва, Чертаново Северное МКР, дом 1а, корпус 1',
      registration_date: '2010-01-01',
      title: 'Клади-забивай',
    },
  ],
  members,
  onboarding_scenarios: [
    { id: 1, title: 'Cценарий', invite_url: 'join/invite_url' },
    { id: 2, title: 'Cценарий 2', invite_url: 'join/some_invite_url' },
  ],
  features: {},
}))

const template_fields = [
  {
    key: 'document_date',
    name: 'Дата договора',
    type: 'date',
    caption: 'Подпись',
  },
  {
    key: 'document_number',
    name: 'Номер договора',
    type: 'text',
    caption: 'Подпись',
  },
  {
    key: 'text_field',
    name: 'Текстовое поле',
    type: 'text',
    is_required: true,
  },
  { key: 'number_field', name: 'Число', type: 'number', is_required: false },
  {
    key: 'select_field',
    name: 'Селект',
    type: 'select',
    is_required: true,
    options: ['Один', 'Два'],
  },
]

const payment_details = {
  kind: 'bank_account',
  // kind: 'card',
  bank_account: {
    rcbic: '044525999',
    bank_name: 'ПАО Сбергрин',
    account: '43564435466347283446',
    bank_code: 'Tinkoff',
    name: 'Константин Константинопольский',
  },
  card: {
    masked_card_number: '1234 12xx xxxx 5555',
  },
}

const user = {
  // platform: 'web',
  platform: 'mobile_app',

  id: 1,
  avatar_url: null,
  name: 'Иванов Иван',
  mobile_phone: '+70002223344',
  email: 'user@email.com',
  need_password: false,
  // need_password: true,
  contractor: {
    id: 200,
    full_name: 'Иванов Иван Иванович',
    moi_nalog: { status: 'not_registered', message: 'Сообщение' },
    // payment_methods: ['bank_account', 'card'],
    payment_methods: ['bank_account'],
    payment_commission: {
      card: 'Текст про комиссию',
    },

    payment_details,
    // payment_details: null,

    companies: [
      {
        id: 777,
        invite_status: 'not_accepted',
        name: 'ООО Приглашалово',
        contracts: [contract],
        documents: [],
        features: {
          acts_create: true,
        },
      },
      {
        id: 888,
        invite_status: 'not_accepted',
        name: 'ООО Ещё одна',
        contracts: [],
        documents: [],
        features: {
          acts_create: true,
        },
      },
    ],
    tax_box: { id: '1' },
    // tax_savings_account: { id: '1', is_enabled: true },
  },
  companies: [
    {
      id: 1,
      name: 'A1 решения очень длинное название компании',
      avatar_url: null,
      currency: 'RUB',
      legal_entities: [
        {
          id: 75445,
          inn: '3123011520',
          ogrn: '1027739328440',
          kpp: '772601001',
          business_name: 'Общество с ограниченной ответственностью “Клади-забивай”',
          legal_address: '117648,г. Москва, Чертаново Северное МКР, дом 1а, корпус 1',
          registration_date: '2010-01-01',
          title: 'Клади-забивай',
        },
      ],
      members,
      onboarding_scenarios: [
        {
          id: 1,
          title: 'Cценарий с полями',
          invite_url: 'join/invite_url',
          template_fields,
        },
        {
          id: 2,
          title: 'Cценарий без полей',
          invite_url: 'join/some_invite_url',
          template_fields: [],
        },
      ],
      global_notifications: [
        {
          kind: 'unsigned_counterparty_documents',
          data: {
            list: [
              'Документ о работорговле',
              'Документ на жрачку',
              'Документ о ядерном разоружении',
            ],
            invoice_act: true,
          },
        },
      ],
      // #deprecated
      global_banners: [
        {
          title: 'Кастомный баннер',
          color: 'rgb(83, 23, 254)',
          background: '#d4cbfd',
          icon: 'notice',
        },
        {
          title: 'sdfsdf',
          description: 'sdfsdf',
          icon: 'notice',
        },
      ],
      nominal_account: { id: 1234, balance: '12345678.90' },
      contract_templates: [
        { id: 2, name: 'Необычный договор', template_fields },
        { id: 1, name: 'Обычный договор', template_fields: [] },
      ],
      document_templates: [{ id: 3, name: 'Шаблон документa', template_fields: [] }],
      features: {
        templates: true,
        projects: true,
        bundles: true,
        bundles_autopay: true,
        external_bank: true,
        member_invites: true,
        documents: true,
        additional_contracts: true,
        offers: true,
        medical_examination_filter_show: true,
        tariffs: true,
        contract_en_title: true,
        is_defaulter: true,
        citizenship_filter_show: true,
        citizenship_col_show: true,
        npd_new_filter_show: true,
        mark_as_paid: true,
        manual_payment_amount: true,
        scenario_tasks: true,
      },
      citizenship_filter_items: [
        {
          label: 'Россия',
          value: 'ru',
        },
        {
          label: 'Италия',
          value: 'it',
        },
        {
          label: 'США',
          value: 'en',
        },
      ],
    },
    {
      id: 2,
      name: 'Х7 решения оченьоченьоченьдлинноеназваниеводнустроку',
      avatar_url: null,
      currency: 'EUR',
      legal_entities: [
        {
          id: 75445,
          inn: '3123011520',
          ogrn: '1027739328440',
          kpp: '772601001',
          business_name: 'Общество с ограниченной ответственностью “Клади-забивай”',
          legal_address: '117648,г. Москва, Чертаново Северное МКР, дом 1а, корпус 1',
          registration_date: '2010-01-01',
          title: 'Клади-забивай',
        },
      ],
      members,
      onboarding_scenarios: [
        { id: 1, title: 'Cценарий', invite_url: 'http://google.com' },
        { id: 2, title: 'Cценарий 2', invite_url: 'http://google2.com' },
      ],
      features: {},
    },
    ...manyCompanies,
  ],

  abilities,
  // abilities: no_abilities,

  flags: {
    show_install_app_banner: true,

    // completed_registration: true,
    completed_registration: false,

    dashboard: true,

    offers: true,

    scenario_tasks: true,

    is_admin_user_login: true,

    onboarding_scenarios: true,
  },
}

const profile = {
  email: 'user@email.com',
  notification_settings: {},
}

const usedesk_token = {
  token: null,
}

export { user }

export default [
  rest.get('/api/company/users/current', json(user)),
  rest.get('/api/contractor/users/current', json(user)),

  rest.put('/api/contractor/users/events', json({})),

  rest.get('/api/company/user/profile', json(profile)),
  rest.put('/api/company/user/profile', json({})),

  rest.post('/api/company/user/profile/usedesk_token', json({})),
  rest.get('/api/company/user/profile/usedesk_token', json(usedesk_token)),
]
