import React from 'react'
import { Flex, Input, Select } from 'ui'

import css from './styles.module.css'
import NumberInput from 'components/NumberInput'
import { toFixed } from 'utils/validations'
import QuantityInput from '../QuantityInput'
import { observer } from 'mobx-react-lite'
import { ListCell, ListItem } from 'components/ui/List'
import { ReactComponent as Garbage } from 'ui/konsol/icons/garbage.svg'

interface IEditUnitForm {
  data: any
  unitOptions: any[]
  onChange: (data: any) => void
  onRemove: () => void
}

const EditUnitForm = observer(
  ({ data, unitOptions, onChange, onRemove }: IEditUnitForm) => {
    const getUnitType = (value: string) =>
      unitOptions.find((item: any) => item.value.toString() === value)

    const createHandleChange = (key: string) => (value: string | number) =>
      onChange({
        ...data,
        [key]: value,
      })

    const handleSelect = createHandleChange('unit_type')

    const handleCost = createHandleChange('cost')

    const handleEnterType = (e: any) => {
      if (e.key === 'Enter') {
        e.target.blur()
      }
    }

    return (
      <ListItem
        className={css.container}
        style={{ cursor: 'pointer', paddingLeft: 0 }}
        hover={false}
      >
        <ListCell col="title">
          <div className={css.title}>
            <Input
              placeholder="Название"
              value={data.title}
              onChange={createHandleChange('title')}
              onKeyDown={handleEnterType}
            />
          </div>
        </ListCell>
        <ListCell col="cost" style={{ display: 'flex' }}>
          <Flex
            className={css.cost}
            style={{ flex: 1, width: '100%' }}
            gap="4px"
            align="center"
          >
            <NumberInput
              placeholder="Цена"
              value={String(toFixed(Number(data.cost), 2))}
              onChange={(cost) => handleCost(cost || 0)}
              allowDecimal={true}
              allowMinus={true}
              onKeyDown={handleEnterType}
              styles={{ root: { flex: 1 }, input: { textAlign: 'right' } }}
            />
            <span>₽</span>
          </Flex>
        </ListCell>
        <ListCell col="unit" style={{ display: 'flex' }}>
          <div className={css.unitType} style={{ flex: 1 }}>
            <Select
              placeholder="Тип"
              value={getUnitType(data.unit_type)}
              onChange={(value: any) => handleSelect(value.value.toString())}
              options={unitOptions}
            />
          </div>
        </ListCell>
        <ListCell col="quantity" style={{ display: 'flex' }}>
          <div className={css.quantity} style={{ flex: 1 }}>
            <QuantityInput
              allowDecimal={true}
              min={0}
              style={{ flex: 1 }}
              value={data.quantity}
              onChange={createHandleChange('quantity')}
            />
          </div>
        </ListCell>
        <ListCell
          col="remove"
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
          <div className={css.remove} onClick={onRemove}>
            <Garbage fill="#fff" />
          </div>
        </ListCell>
      </ListItem>
    )
  }
)

export default EditUnitForm
