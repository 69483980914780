import React from 'react'

import { useUserStore } from 'stores/context'

import { Divider } from '@chakra-ui/react'
import { BaseButton, Flex } from 'components/ui'
import { Link } from 'ui'

import { ReactComponent as LogoutIcon } from 'assets/logout.svg'

import s from '../../styles.module.css'

interface IRegistrationFooterProps {
  kind?: string
}

const RegistrationFooter = ({ kind }: IRegistrationFooterProps) => {
  const currentUser = useUserStore()

  if (kind === 'initial') {
    return null
  }

  return (
    <div style={{ marginTop: '2rem' }}>
      <div style={{ marginBottom: '2rem' }}>
        Please contact us at <Link href='mailto:hello@restaff.pro' target='_blank'>hello@restaff.pro</Link> if you have any issues
      </div>

      <Divider />
      <BaseButton onTap={() => currentUser.logout()} className={s.logoutButton}>
        <Flex gap=".25rem" align="center">
          <LogoutIcon />
          <div>Войти с другим номером</div>
        </Flex>
      </BaseButton>
    </div>
  )
}

export default RegistrationFooter
