import React from 'react'
import { Modal as KonsolUiModal, H3 } from 'ui'
import cl from 'clsx'
import { SpectrumDataReportStatus } from 'entities/spectrum'

import c from './styles.module.css'
import { fetchApi } from 'api'
import { useUserStore } from 'stores/context'
import { getSpectrumDataPrice } from 'utils/company'

interface ISpectrumDataReporstModalProps {
  isVisibleModal: boolean
  setVisibleModal: (isVisibleModal: boolean) => void
  setSpectrumDataReportStatus: (status: SpectrumDataReportStatus) => void
  phone?: string
  contractorId?: number
  isInvitesPage?: boolean
}

const SpectrumDataReportModal = ({
  isVisibleModal,
  setVisibleModal,
  setSpectrumDataReportStatus,
  phone,
  contractorId,
  isInvitesPage = false,
}: ISpectrumDataReporstModalProps) => {
  const currentUser = useUserStore()
  const spectrumDataReportPrice = getSpectrumDataPrice(currentUser)

  const handleSpectrumDataSubmit = () => {
    let data = {}

    if (isInvitesPage) {
      data = {
        mobile_phone: phone,
      }
    } else {
      data = {
        contractor_id: contractorId,
      }
    }

    return fetchApi({
      method: 'POST',
      data,
      url: 'spectrum_data_reports',
    })
      .then((response) => {
        setSpectrumDataReportStatus(response.status)
        setVisibleModal(false)
      })
      .catch((error) => {
        console.log(error)
        setVisibleModal(false)
      })
  }

  return (
    <KonsolUiModal
      isOpen={isVisibleModal}
      onClose={() => setVisibleModal(false)}
      width={343}
    >
      <H3 className={c.modalHeading}>
        Проверка
        <br />
        исполнителя
      </H3>
      <div className={c.modalImg} />
      <div className={c.modalContent}>
        <ul>
          <li>Стоимость одного отчета — {spectrumDataReportPrice} руб.</li>
          <li>Примерное время ожидания — 10 минут в рабочее время</li>
        </ul>
        <p>
          Мы работаем:
          <br />
          с понельника по пятницу с 9:00 до 22:00
          <br />в субботу и воскресенье с 9:00 до 19:00
        </p>
      </div>
      <div className={c.buttonsWrapper}>
        <button
          className={cl(c.modalButton, c.modalButtonCancel)}
          onClick={() => setVisibleModal(false)}
        >
          Отмена
        </button>
        <button className={c.modalButton} onClick={handleSpectrumDataSubmit}>
          Заказать
        </button>
      </div>
    </KonsolUiModal>
  )
}

export default SpectrumDataReportModal
