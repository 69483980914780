import * as React from 'react'
import { Button } from 'components/ui'
import { ButtonProps } from 'components/ui/Button'
import { ScenarioTaskActionsStore } from '.'
import { observer } from 'mobx-react-lite'

type ScenarioTaskAction = any;

type TaskActionButtonProps = {
  action: ScenarioTaskAction
  store: ScenarioTaskActionsStore
}

const mapActionToTitle: { [K in ScenarioTaskAction]: string } = {
  accept: 'Принять',
  decline: 'Отказаться',
}

export const TaskActionButton = observer(
  ({
    store,
    action,
    size,
    design,
  }: TaskActionButtonProps & Omit<ButtonProps, 'children'>) => (
    <Button
      design={design ?? (action === 'accept' ? 'accent' : 'inline')}
      style={{ alignSelf: 'flex-start' }}
      size={size}
      onClick={(e: Event) => {
        e.preventDefault()
        store.runTaskAction(action)
      }}
      isLoading={store.lastAction === action && store.actionState.state === 'pending'}
      isDisabled={(store.lastAction !== action && store.actionState.state) === 'pending'}
    >
      {mapActionToTitle[action]}
    </Button>
  )
)
