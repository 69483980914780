import { makeAutoObservable, toJS, computed } from 'mobx'
import { fromPromise } from 'mobx-utils'
import {
  UserEntity,
  CompanyFeatures,
  TaskPayAction,
  Contractor,
  TaxBox,
  TaxSavingsAccount,
} from 'entities'
import { get } from 'lodash'
import { matchPath } from 'react-router'
import type { History } from 'history'

import { fetchApi, FetchError } from 'api'
import { MobxApi, Entity, ActionState } from 'mobx/mobx'
import localStorageObservable from 'utils/localStorageObservable'
import {
  isProductionEnvironment,
  beamerInitialize,
  getUserForBeamer,
  hotjarInitialize,
  hotjarIdentify,
} from 'thirdParty'

interface UserData {
  id?: number
  kind?: 'company' | 'contractor'
  companyId?: number
  beamerCount?: number
}

type LoginSource = 'web' | 'admin' | 'app'

const detectPlatform = () =>
  //@ts-ignore
  window.IS_MOBILE_APP ? 'mobile_app' : 'web'

// (Cookies.get('platform') === 'mobile' ? 'mobile_app' : 'web')

class UserStore {
  constructor(api: MobxApi) {
    this.api = api
    this.userData = localStorageObservable<UserData>('user_data', () => ({})).res
    makeAutoObservable(this)
  }

  history!: History
  api: MobxApi
  userData: UserData
  user?: Entity<UserEntity>
  beamerCount?: number

  init(history: History): ActionState<any> {
    this.history = history

    let match = matchPath(this.history.location.pathname, {
      path: '/app_login/:token',
      exact: true,
      strict: true,
    })
    // @ts-ignore
    if (match) return this.mobileAppLogin(match.params.token)

    const matchTokenLoginUrl = matchPath(this.history.location.pathname, {
      path: '/token_login/:token',
      exact: true,
      strict: true,
    })
    const matchAdminLoginUrl = matchPath(this.history.location.pathname, {
      path: '/admin_login/:admin_token/:user_id',
      exact: true,
      strict: true,
    })
    if (this.userData.id && !matchTokenLoginUrl && !matchAdminLoginUrl) {
      return this.fetchUser()
    } else {
      return fromPromise.resolve()
    }
  }

  fetchUser() {
    return fromPromise(
      this.api.fetch<UserEntity>({ type: 'users', url: 'users/current' }).then(
        (user) => {
          this.user = user as Entity<UserEntity>
          this.setUserData()
        },
        (error: any) => {
          console.log(error.message)
          this.logout()
        }
      )
    )
  }

  mobileAppLogin(token: string) {
    return fromPromise(
      fetchApi({
        method: 'POST',
        url: 'authorizations/app_login',
        data: { token },
        withPrefix: false,
      }).then(
        (response) => {
          let companyId =
            response.contractor === null ? response.companies[0].id : undefined
          let params = new URLSearchParams(this.history.location.search)
          let url = params.get('url')
          this.login({
            source: 'app',
            user: response,
            companyId,
            initialUrl: url ? `/${url}` : undefined,
          })
        },
        (error: FetchError) => {
          if (error.kind !== 'fetch' && error.kind !== 'abort') {
            this.logout()
          }
        }
      )
    )
  }

  login({
    user,
    companyId,
    initialUrl,
  }: {
    source?: LoginSource
    user: any
    companyId?: number
    initialUrl?: string
  }) {
    this.user = this.api.createOrUpdateEntity('users', user)
    this.userData.kind = companyId ? 'company' : 'contractor'
    this.userData.companyId = companyId
    this.setUserData()

    // нужно обновить юзера после логина чтобы получить abilities и company members
    if (this.isCompanyMember) this.fetchUser()

    const to = initialUrl ?? this.defaultUrl
    this.history.replace(to)
  }

  setUserData() {
    if (!this.user) return

    let data = this.userData
    data.id = this.user.id
    if (this.isCompanyMember && this.isContractor) {
      if (!data.kind) data.kind = 'company'
    } else if (this.isCompanyMember) {
      data.kind = 'company'
      data.beamerCount = this.beamerCount
    } else if (this.isContractor) {
      data.kind = 'contractor'
    }
    if (this.isCompanyMember) {
      if (!data.companyId || !this.user.data.companies!.map[data.companyId]) {
        data.companyId = this.user.data.companies!.items[0].id
      }
    }

    this.platform = detectPlatform()

    const { is_admin_user_login } = this.user!.data.flags

    // Restaff: disable beamer
    // if (isProductionEnvironment() && data.kind === 'company' && !is_admin_user_login) {

    //   beamerInitialize(
    //     this.userData.id,
    //     getUserForBeamer(this.user),
    //     (count) => {
    //       this.beamerCount = count
    //     },
    //     () => (this.beamerCount = 0)
    //   )
    // }

    if (isProductionEnvironment() && data.kind === 'company') {
      hotjarInitialize(3076413, 6)
      hotjarIdentify(this.user)
    }
  }

  platform = detectPlatform()

  logout(sendRequest = true) {
    if (this.user && sendRequest) {
      fetchApi({ url: 'authorizations', method: 'DELETE', withPrefix: false })
    }
    if (this.platform === 'web') {
      this.webLogout()
    } else if (this.platform === 'mobile_app') {
      this.mobileLogout()
    }
  }

  webLogout() {
    delete this.userData.id
    delete this.userData.kind
    delete this.userData.companyId
    this.user = undefined
  }

  mobileLogout() {
    // @ts-ignore
    window.ReactNativeWebView?.postMessage('logout')
    window.postMessage('logout', '*')
  }

  changeCompany(id: number) {
    let json = JSON.stringify({ ...toJS(this.userData), companyId: id })
    localStorage.setItem('user_data', json)
    // this.userData.companyId = id

    let redirects = [
      { path: '/contractors/:any+', redirect: '/contractors' },
      { path: '/acts/:any+', redirect: '/acts' },
      { path: '/tasks/:any+', redirect: '/tasks' },
      { path: '/projects/:any+', redirect: '/projects' },
      { path: '/bundles/:any+', redirect: '/bundles' },
    ]
    for (let i in redirects) {
      let { path, redirect } = redirects[i]
      if (
        matchPath(window.location.pathname, {
          path,
          exact: true,
          strict: true,
        })
      ) {
        window.location.href = redirect
        return
      }
    }
    window.location.reload()
  }

  changeKind(kind: 'contractor' | 'company') {
    if (this.userData.kind === kind) return

    let json = JSON.stringify({ ...toJS(this.userData), kind })
    localStorage.setItem('user_data', json)
    // this.userData.kind = kind

    window.document.body.style.transform = 'rotate3d(1, 1, 1, 360deg)'
    window.location.href = '/'
  }

  get company() {
    if (this.user && this.userData.kind === 'company') {
      return this.user.data.companies!.map[this.userData.companyId!]
    }
    return undefined
  }

  get kind() {
    return this.userData.kind
  }

  get defaultUrl() {
    // if (this.kind === 'contractor' && this.user?.data) {
    if (this.kind === 'company') {
      return '/company'
    } else if (this.kind === 'contractor' && this.user?.data) {
      return this.user!.data.flags.dashboard ? '/dashboard' : '/tasks'
    } else {
      return '/tasks'
    }
  }

  get isCompanyMember() {
    return (
      this.user && this.user.data.companies && this.user.data.companies.items.length > 0
    )
  }

  get role() {
    if (!this.user || this.userData.kind === 'contractor') return null
    let member = this.company?.data.members?.items.find(
      (m) => m.data.user_id === this.user!.id
    )
    return member?.data.role
  }

  hasAbility(
    ability: string,
    { kind = 'any' }: { kind?: 'company' | 'contractor' | 'any' } = {}
  ) {
    if (!this.user) return false
    if (kind !== 'any' && kind !== this.kind) return false
    return this.user.data.abilities && get(this.user.data.abilities, ability)
  }

  hasFeature(feature: keyof CompanyFeatures) {
    if (!this.user) return false
    if (this.kind === 'contractor') return false
    return this.company!.data.features?.[feature]
  }

  get companyPayAction() {
    if (this.company!.data.nominal_account) return 'pay' as TaskPayAction
    if (this.hasFeature('external_bank')) return 'mark_paid_with_receipt' as TaskPayAction
    return 'mark_paid' as TaskPayAction
  }

  @computed
  get isContractor(): boolean {
    return this.kind === 'contractor'
  }

  @computed
  get contractor(): Contractor | null {
    return get(this.user, 'data.contractor.data', null)
  }

  @computed
  get contractorId(): number | null {
    return get(this.contractor, 'id', null)
  }

  @computed
  get contractorTaxBox(): TaxBox | null {
    return get(this.contractor, 'tax_box.data', null)
  }

  @computed
  get contractorTaxSavingsAccount(): TaxSavingsAccount | null {
    return get(this.contractor, 'tax_savings_account', null)
  }

  @computed
  get taxBoxId(): string | null {
    return get(this.contractorTaxBox, 'id', null)
  }

  @computed
  get taxBoxIsFlexible(): boolean | null {
    return get(this.contractorTaxBox, 'is_flexible', null)
  }
}

export default UserStore
