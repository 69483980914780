import React from 'react'

import { Flex } from 'components/ui'

const styles: { [key: string]: React.CSSProperties } = {
  root: {
    whiteSpace: 'nowrap',
  },
  dot: {
    width: 5,
    height: 5,
    borderRadius: '50%',
    background: '#c4c4c4',
  },
}

const Dots = ({ children }: { children: React.ReactNode[] }) => {
  let dot = <div style={styles.dot} />
  return (
    <Flex gap=".75rem" align="center" wrap={true} style={styles.root}>
      {children.map((child, index) =>
        index === children.length - 1 ? child : [child, dot]
      )}
    </Flex>
  )
}

export default Dots
