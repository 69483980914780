import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import cx from 'clsx'

import { UserRegistrationEntity } from 'entities'
import { Entity } from 'mobx/MobxApi'
import { Appear, Button, H1, H3, Flex } from 'ui'

import { useStateToast } from 'components/ui'
import { useMediaContext } from 'App/MediaContext'
import DocumentsFormStore from 'pages/registration/store/DocumentsStore'
import { useUserStore } from 'stores/context'
import ExtraFieldRenderer from 'pages/registration/components/ExtraFieldRenderer'

import RegistrationFooter from 'pages/registration/components/Footer'

import css from './styles.module.css'

const DocumentsReqiredStep = observer(
  ({
    registration,
    companyName,
  }: {
    registration: Entity<UserRegistrationEntity>
    companyName: string | undefined
  }) => {
    const { isMobile } = useMediaContext()
    const currentUser = useUserStore()
    const toast = useStateToast()
    const { additional_documents } = registration.data.form_options

    const [store] = useState(
      () => new DocumentsFormStore(additional_documents, registration, currentUser, toast)
    )

    // группируем документы по заголовкам
    let groups: any = {}
    if (additional_documents) {
      additional_documents.forEach((element: any) => {
        let caption = 'none'
        if (element.caption) caption = element.caption
        if (!groups[caption]) groups[caption] = []
        groups[caption].push(element)
      })
    }

    let size: any = isMobile ? 'm' : 'l'

    return (
      <>
        <Flex gap="20px" direction="col">
          <Flex gap="20px" direction="col" style={{ margin: '0 20px' }}>
            <Appear delay={0} animation={Appear.presets.slideDown}>
              <H1>Загрузка дополнительных документов</H1>
            </Appear>
            <Appear delay={100} animation={Appear.presets.slideDown}>
              <div>
                Для сотрудничества{' '}
                {companyName ? <>с&nbsp;компанией {companyName}</> : ''} потребуется
                предоставить дополнительные документы или информацию
              </div>
            </Appear>
          </Flex>
          {Object.keys(groups).map((caption, index: number) => {
            const withCaption = caption !== 'none'
            return (
              <Appear
                delay={100 + 100 * (index + 1)}
                animation={Appear.presets.slideDown}
              >
                <Flex
                  className={cx(css.group, withCaption && css.groupWithCaption)}
                  gap="20px"
                  direction="col"
                >
                  {withCaption && <H3 className={css.groupTitle}>{caption}</H3>}
                  <Flex gap="20px" direction="col">
                    {groups[caption].map((field: any) => (
                      <ExtraFieldRenderer
                        config={field}
                        field={store.form.fields[field.name]}
                        isLoading={store.form.fields[field.name].uploader?.isLoading}
                        size={size}
                      />
                    ))}
                  </Flex>
                </Flex>
              </Appear>
            )
          })}
          <Appear
            delay={Object.keys(groups).length + 500}
            animation={Appear.presets.slideDown}
          >
            <Flex justify="end" style={{ margin: '0 20px 50px' }}>
              <Button
                isDisabled={!store.isValid}
                isLoading={store.isLoading}
                onTap={() => {
                  const additional = true
                  store.submit(additional)
                }}
              >
                Далее
              </Button>
            </Flex>
          </Appear>
        </Flex>
        <RegistrationFooter />
      </>
    )
  }
)

export default DocumentsReqiredStep
