import React from 'react'
import cl from 'clsx'
import { InlineText } from 'ui-kit'

import css from './styles.module.css'

export enum StatusBadgeState {
  Default = 'default',
  Success = 'success',
  Fail = 'fail',
  Info = 'info',
  Progress = 'progress',
  Warning = 'warning',
}

type StatusBadgeProps = {
  state: StatusBadgeState
  label: string
  className?: string
}

const StatusBadge: React.FunctionComponent<StatusBadgeProps> = ({
  state,
  label,
  className,
}) => (
  <div className={cl(css.statusBadge, css[state], className && className)}>
    <InlineText className={cl(css.label)}>{label}</InlineText>
  </div>
)

export default StatusBadge
