// @ts-ignore
import { extendComponentStyles } from 'floral'

import { BaseButton } from 'components/ui'

const dropdownButtonStyles = (
  props: React.ComponentProps<typeof BaseButton>,
  { isHovered }: any
) => ({
  root: {
    borderRadius: '.5rem',
    height: '2rem',
    color: 'var(--color-active)',
    align: 'center',
    background: isHovered ? 'var(--color-grey-1)' : 'transparent',
  },
})

export const DropdownButton = extendComponentStyles(BaseButton, dropdownButtonStyles)