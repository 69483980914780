import React, { useEffect } from 'react'
import { Switch, Route, Redirect, useLocation } from 'react-router-dom'
import { observer } from 'mobx-react-lite'

import ProtectedRoute from 'utils/ProtectedRoute'
import { useUserStore } from 'stores/context'
import NotificationsPopup, {
  notificationsPopupState,
} from 'components/mobile/NotificationsPopup'

import { usePusherSubscription } from './pusher'
import UserRoutes from './UserRoutes'
import { useFeedbackModal } from 'components/mobile/FeedbackModal'

// Main app routes
const MainRoutes = observer(({ browserHistory }: any) => {
  let currentUser = useUserStore()
  const location = useLocation()

  useEffect(() => {
    let prevPath = ''
    let currentPath = location.pathname

    const unlisten = browserHistory.listen((location: any, action: string) => {
      if (action !== 'POP') window.scrollTo(0, 0)

      const pathname = `${location.pathname}${location.search}${location.hash}`

      if (pathname !== currentPath) {
        prevPath = currentPath
        currentPath = pathname
      }

      location.state = {
        from: prevPath,
      }
    })
    return unlisten
  }, [])

  let loggedIn = currentUser.user !== undefined
  let completedRegistration =
    currentUser.kind === 'company' || currentUser.user?.data.flags.completed_registration

  usePusherSubscription('open-notifications-popup', () => {
    if (currentUser.platform === 'mobile_app') notificationsPopupState.isOpen = true
  })

  const feedbackModal = useFeedbackModal()

  let routes = (
    <Switch>
      <Route
        path="/legal"
        exact
        component={() => {
          window.location.href = 'https://konsol.pro/legal'
          return null
        }}
      />
      <Route
        path="/admin_login/:admin_token/:user_id"
        component={require('pages/admin_login').default}
      />
      <Route
        path="/token_login/:token"
        component={require('pages/token_login').default}
      />
      <Route path="/not-found" component={require('pages/not-found').default} />
      <Redirect path="/" exact to={currentUser.defaultUrl} />
      <ProtectedRoute
        path="/login"
        component={require('pages/login').default}
        guard={() => {
          if (loggedIn) return '/'
          return undefined
        }}
      />
      <Route path="/register" exact component={require('pages/join/index').default} />
      <Redirect from="/register/:offer" to="/register?offer=:offer" />
      <Route path="/join/:permalink" exact component={require('pages/join').default} />
      <Route
        path="/join_company"
        exact
        component={require('pages/join_company/index').default}
      />
      <ProtectedRoute
        path="/registration"
        exact
        component={require('pages/registration/RegistrationPage').default}
        guard={() => {
          if (!loggedIn) return '/login'
          if (completedRegistration) return '/registration/completed'
          return undefined
        }}
      />

      <Route
        path="/password-forgot"
        exact
        component={require('pages/password_forgot/index').default}
      />

      <ProtectedRoute
        path="*"
        component={UserRoutes}
        guard={() => {
          if (!loggedIn) return '/login'
          if (!completedRegistration) return '/registration'
          return undefined
        }}
      />

      <Redirect exact to="/not-found" />
    </Switch>
  )

  return (
    <>
      <NotificationsPopup />
      {routes}
      {feedbackModal.render()}
    </>
  )
})

export default MainRoutes
