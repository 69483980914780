const getLocalStorageValue = (key: string) => {
  const value = localStorage.getItem(key)
  if (value === null) return
  try {
    return JSON.parse(value)
  } catch (e) {}
}

const setLocalStorageValue = <Data>(key: string, value?: Data) => {
  if (value) {
    const serialized = JSON.stringify(value, null, 2)
    localStorage.setItem(key, serialized)
  } else {
    localStorage.removeItem(key)
  }
}

const localStorageValue = <Data>(key: string) => ({
  get: () => getLocalStorageValue(key),
  set: (value?: Data) => setLocalStorageValue(key, value),
})

export { getLocalStorageValue, setLocalStorageValue, localStorageValue }
