import React, { useState, useEffect } from 'react'
import { fromPromise } from 'mobx-utils'

import { Title, Input, FormGroup } from 'components/ui/LayoutSimple'

import { Link } from 'ui'

// import { Link } from 'react-router-dom'

import { loginAuth } from '../../requests'

import s from './style.module.css'

interface PasswordProps {
  phone: string
  onComplete: (password: string) => void
}

const Password = ({ phone, onComplete }: PasswordProps) => {
  const [password, setPassword] = useState('')
  const [error, setError] = useState<any>()
  const [checkState, setCheckState] = useState<any>(fromPromise.resolve())
  const [isLoading, setIsLoading] = useState(false)

  const onSubmit = () => {
    if (password) {
      setIsLoading(true)
      let state = fromPromise(loginAuth(phone, password))
      state.then(
        () => {
          onComplete(password)
          setPassword('')
          setIsLoading(false)
        },
        () => {
          setPassword('')
          setIsLoading(false)
        }
      )
      setCheckState(state)
    }
  }

  useEffect(() => {
    if (checkState.state === 'rejected') {
      setError(<span className={s.errorText}>{checkState.value.message}</span>)
    }
  }, [checkState.state])

  return (
    <>
      <Title>Вход</Title>
      <FormGroup
        additionalLink={
          <>
            {/* переделать на Link из react-router-dom */}
            <Link href="/password-forgot">Не помню пароль</Link>
          </>
        }
        error={error}
        isDisabled={!password}
        isLoading={isLoading}
        onSubmit={onSubmit}
      >
        <Input
          value={password}
          onChange={setPassword}
          onEnter={() => {
            if (password) onSubmit()
          }}
          type="password"
          placeholder="Введите пароль"
          autoFocus
        />
      </FormGroup>
    </>
  )
}

export default Password
