import { FiltersState } from 'entities'
import { fetchFile } from 'api'
import { getBaseUrl, getDefaultHeaders } from 'api/apiDefaults'
import downloadFile from 'utils/downloadFile'

import { useStateToast } from 'components/ui'

export interface ExportProps {
  url: string
  filename?: string
  format: string
  filters?: FiltersState
  ids?: number[]
  toast: ReturnType<typeof useStateToast>
  data?: any
}

const exportToFile = async (props: ExportProps) => {
  let { url, filename, format, filters, ids, toast, data } = props
  try {
    let { blob, filename: origFilename } = await fetchFile({
      url: `${getBaseUrl()}/${url}/export_to_${format}`,
      method: 'POST',
      headers: getDefaultHeaders(),
      data: { filters, ids, ...data },
    })
    downloadFile(blob, origFilename ?? filename)
  } catch (e) {
    toast.error({ title: 'Не удалось экспортировать' })
  }
}

export default exportToFile
