import React from 'react'

import { observer } from 'mobx-react-lite'

import { PageProps } from 'app.types'

import { ScenarioTemplates as ScenarioTemplatesInner } from './ScenarioTemplates'

import { ScenarioListStoreProvider } from './hooks/useScenarioListStore'

export const ScenarioTemplates = observer((props: PageProps) => {
  return (
    <ScenarioListStoreProvider pageProps={props}>
      <ScenarioTemplatesInner {...props} />
    </ScenarioListStoreProvider>
  )
})

export default ScenarioTemplates
