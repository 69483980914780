import React, { useEffect } from 'react'
import { useState } from 'react'

import { AnalyticsProvider } from './context/AnalyticsProvider'

import { Divider, H1, Tab, Tabs } from 'ui'

import { Layout, PageHeader } from 'components/ui'

import { PageProps } from 'app.types'

import CompanyView from './view/CompanyView'
import OnboardingScenarioView from './view/OnboardingScenarioView'
import SegmentView from './view/SegmentView'

import classes from './styles.module.css'

const allowed_location_hash = ['#company', '#scenario', '#segment']

const getInitialTab = () => {
  const { hash } = window.location
  if (allowed_location_hash.includes(hash)) {
    return hash.slice(1, hash.length)
  }
  return 'company'
}

export const AnalyticsPage = (props: PageProps) => {
  const [tabValue, setTabValue] = useState<string>(getInitialTab())

  useEffect(() => {
    window.location.hash = tabValue
  }, [tabValue])

  return (
    <AnalyticsProvider pageProps={props}>
      <Layout smallPaddingTop style={{ paddingBottom: '0' }}>
        <PageHeader
          title={<H1>Аналитика рисков</H1>}
          controls={
            <>
              {/*
                <Button
                  icon={<SettingsIcon fill="#3C0AFE" />}
                  variant="secondary"
                />
              */}
            </>
          }
        >
          <Tabs noUnderline value={tabValue} onChange={(v) => setTabValue(v)}>
            <Tab value="company">По компании</Tab>
            <Tab value="scenario">По сценариям</Tab>
            <Tab value="segment">По сегментам</Tab>
          </Tabs>
        </PageHeader>
      </Layout>
      <Divider className={classes.divider} />
      <Layout smallPaddingTop style={{ paddingTop: '0' }}>
        {tabValue === 'company' && <CompanyView />}
        {tabValue === 'scenario' && <OnboardingScenarioView />}
        {tabValue === 'segment' && <SegmentView />}
      </Layout>
    </AnalyticsProvider>
  )
}

export default AnalyticsPage
