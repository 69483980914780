import type { Action } from 'mobx/types'

import { HttpMethod } from 'api'
import { fetchApi } from 'api'

const crudActions = ['fetch', 'delete', 'create', 'update']

const actionMethods: { [key: string]: HttpMethod } = {
  fetch: 'GET',
  delete: 'DELETE',
  create: 'POST',
  update: 'PATCH',
}

const createBackendAdapter = () => {
  return ({ type, id, action, payload, url: customUrl, options }: Action) => {
    let url
    if (customUrl) {
      url = customUrl
    } else {
      url = type
      if (id) url += `/${id}`
      if (!crudActions.includes(action)) url += `/${action}`
    }

    let method: HttpMethod
    if (options?.method) {
      method = options?.method
    } else if (crudActions.includes(action)) {
      method = actionMethods[action]
    } else {
      method = 'PUT'
    }

    let signal = options?.signal

    return fetchApi({ url, method, data: payload, signal })
  }
}

export default createBackendAdapter
