import { fetchApi } from 'api'
import { fromPromise } from 'mobx-utils'
import toFormData from 'utils/toFormData'

const joinCompanyApi = {
  registerMember: (data: {
    full_name: string
    email: string
    phone: string
    passport_main: string
    passport_selfie: string
    passport_registration: string
  }) =>
    fromPromise(
      fetchApi({
        withPrefix: false,
        url: 'company/members/registration',
        method: 'POST',
        data: toFormData(data),
      })
    ),
}

export default joinCompanyApi
