import React from 'react'
// @ts-ignore
import { extendComponentStyles } from 'floral'
// @ts-ignore
import { Menu, MenuItem, Modal, ModalCloseButton } from 'oriente'

import { useMediaContext } from 'App/MediaContext'

const menuStyles = {
  list: {
    padding: '.5rem',
    border: '1px solid #e9eaec',
    boxShadow: 'rgb(238 238 250) 0px 4px 20px',
    borderRadius: '.5rem',
    background: 'white',
  },
}

const UiMenu = extendComponentStyles(Menu, menuStyles)

const menuItemStyles = (props: any, { isSelected }: any) => ({
  root: {
    borderRadius: 6,
    minHeight: '2rem',
    padding: '.5rem 1rem',
    background: isSelected ? 'var(--konsol-color-light-background)' : 'transparent',
    color: props.isDisabled ? 'var(--color-secondary)' : 'auto',
    cursor: props.isDisabled ? 'not-allowed' : 'pointer',
  },
})

const UiMenuItem = extendComponentStyles(MenuItem, menuItemStyles)

const headerStyles: { [key: string]: React.CSSProperties } = {
  root: {
    fontWeight: 600,
    textTransform: 'uppercase',
    color: 'var(--color-secondary)',
    marginTop: '.75rem',
    fontSize: '.9rem',
    padding: '0.5rem 1rem',
    letterSpacing: '0.01rem',
  },
}

const MenuHeader = ({ children }: { children: React.ReactNode }) => (
  <div style={headerStyles.root}>{children}</div>
)

const MenuGroup = ({
  children,
  header,
}: {
  children: React.ReactNode
  header: React.ReactNode
}) => (
  <>
    {header && <MenuHeader>{header}</MenuHeader>}
    {children}
  </>
)

const separatorStyles: { [key: string]: React.CSSProperties } = {
  root: {
    borderBottom: '1px solid var(--color-grey-1)',
    margin: '.25rem',
  },
}
const MenuSeparator = () => <div style={separatorStyles.root} />

export { UiMenu as Menu, UiMenuItem as MenuItem, MenuHeader, MenuGroup, MenuSeparator }

const modalStyles = ({ isMobile }: { isMobile: boolean }) => ({
  container: {
    paddingTop: '4rem',
    paddingBottom: '4rem'
  },
  window: {
    paddingLeft: isMobile ? 20 : 35,
    paddingRight: isMobile ? 20 : 35,
    paddingTop: 28,
    paddingBottom: 42,
    borderRadius: 20,
  },
  overlay: {
    background: 'rgba(0,0,0,.5)',
  },
})

interface ModalProps extends React.ComponentProps<Modal> {
  children: React.ReactNode
}

const UiModal = (props: ModalProps) => {
  let { children, styles, ...restProps } = props
  let { isMobile } = useMediaContext()
  return (
    <Modal isMobile={isMobile} styles={[modalStyles, styles]} {...restProps}>
      {() => (
        <>
          <ModalCloseButton />
          {children}
        </>
      )}
    </Modal>
  )
}

UiModal.defaultProps = {
  closeOnOverlayClick: true
}

export { UiModal as Modal }
