import {
  Layout,
  List,
  ListHeader,
  Menu as MenuUi,
  Button as ButtonUi,
  MenuButton,
  MenuList,
  PageHeader,
  Placeholder,
} from 'components/ui'
import { Button, Flex, H1, Input, Checkbox } from 'ui'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { comparer, computed, makeAutoObservable, reaction } from 'mobx'
import { PaginationEntity } from 'entities'
import {
  MultiActionButton,
  SelectAllCheckbox,
  SortFiltersPanel,
} from 'components/FilteredList2'
import MobxApi, { Entity } from 'mobx/MobxApi'
import { useStateToast } from 'components/ui'
import { formatTime, parseDate } from 'utils/datetime'
import { useMobxApi } from 'mobx/MobxApiContext'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { ListItem, ListCell } from 'components/ui'
import { Link, Menu, MenuItem } from 'ui'
import { ReactComponent as MoreIcon } from 'assets/more-vert.svg'
import { ReactComponent as SearchIcon } from '@material-design-icons/svg/round/search.svg'
import { ReactComponent as DropdownIcon } from '@material-design-icons/svg/round/arrow_drop_down.svg'
import { fetchApi } from 'api'
import {
  PaginatedStore,
  PaginatedView,
  SelectedItemsStore,
} from 'components/FilteredList2'
import { ActionState } from 'mobx/types'
import numForm from 'utils/numForm'

import filterCollection from 'utils/filterCollection'
import UnitPopover from 'pages/scenario_projects/components/UnitPopover'

import classes from './Tasks.module.css'
import { useMediaContext } from 'App/MediaContext'
import { Badge } from 'components/Badge'
import { formatAmount } from 'utils/amount'

import clsx from 'clsx'
import { SortableStore } from 'components/FilteredList'

const taskWordFormsWith = {
  one: 'задание',
  two: 'задания',
  many: 'заданий',
}

const actionConfigs = [
  { action: 'create_act' }, // Создать акты
  { action: 'complete' }, // Завершить
  { action: 'not_complete' }, // Не выполнено
  { action: 'cancel' }, // Отменить
  { action: 'delete_draft' }, // Удалить черновики
  { action: 'suggest' }, // Отправить
  { action: 'duplicate' }, // Создать копию
]

const actionSuccessMap: Record<string, any> = {
  create_act: 'Акты созданы',
  complete: 'Задания завершены',
  not_complete: 'Задания отмечены не выполненными',
  cancel: 'Задания отменены',
  delete_draft: 'Черновики удалены',
  suggest: 'Задания отправлены',
}

const actionErrorMap: Record<string, any> = {
  create_act: 'Не удалось создать акты',
  complete: 'Не удалось завершить задания',
  not_complete: 'Не удалось отметить задания не выполненными',
  cancel: 'Не удалось отменить задания',
  delete_draft: 'Не удалось удалить черновики',
  suggest: 'Не удалось отправить задания',
}

class TaskListStore {
  sorted: SortableStore<any>
  selected: SelectedItemsStore<any>
  paginated: PaginatedStore<any>

  public searchQuery = ''
  private searchQueryDelayed = ''

  setSearchQuery = (searchQuery: string) => {
    this.searchQuery = searchQuery
  }

  get query() {
    return {
      filters: {},
      sorting: {},
      search: this.searchQueryDelayed,
    }
  }

  constructor(
    readonly mobxApi: MobxApi,
    readonly toast: ReturnType<typeof useStateToast>
  ) {
    makeAutoObservable(this)
    const actions: Record<string, any> = {}
    actionConfigs.forEach(({ action }) => {
      actions[action] = {
        ability: true,
        getItemsForAction: (selectedItems: any) =>
          mobxApi.fetch({
            type: 'scenario_tasks',
            url: `scenario_tasks/for_multi_action`,
            options: { method: 'POST' },
            payload: selectedItems
              ? { ids: selectedItems.map((item: any) => item.id), action_type: action }
              : { action_type: action, search: this.searchQuery },
          }),
        action: (tasks: any) => {
          const ids = tasks.map((task: any) => task.id)

          fetchApi({
            url: `scenario_tasks/multi_action`,
            method: 'POST',
            data: {
              action_type: action,
              ids,
            },
          }).then(
            (data: any) => {
              if (action === 'delete_draft') {
                filterCollection(this.paginated.fetchedValue!.data.items, (item) => {
                  return !ids.includes(item.data.id)
                })
              }

              if (action === 'duplicate') {
                this.paginated.fetch()
              }

              if (action === 'delete_draft') {
                this.paginated.fetch()
              }

              data.map((d: any) => {
                return mobxApi.entities.scenario_tasks.get(d.id)?.setData(d)
              })

              this.toast.success({
                title: actionSuccessMap[action] || 'Действие выполнено успешно',
              })
            },
            (error: any) =>
              this.toast.error({
                title: actionErrorMap[action] || 'Не удалось выполнить действие',
                description: error.message,
              })
          )
        },
      }
    })
    this.paginated = new PaginatedStore<any>(
      ({ page, abortSignal }) =>
        this.mobxApi.fetch({
          type: 'scenario_tasks/page',
          url: `scenario_tasks/filter`,
          payload: { ...this.query, page },
          options: { signal: abortSignal, method: 'POST' },
        }) as ActionState<Entity<PaginationEntity<any>>>
    )
    this.selected = new SelectedItemsStore({
      list: computed(() => {
        if (this.paginated.fetchedValue) {
          let { items, total_pages, total_count } = this.paginated.fetchedValue.data
          return { items: items.items, pages: total_pages, count: total_count }
        } else {
          return { items: [], pages: 0, count: 0 }
        }
      }),
      actions,
      noItemsMessage: () => 'Не выбрано задач, доступных для этого действия.',
      someItemsMessage: ({ actionCount, selectedCount }) =>
        'Действие можно выполнить только с ' +
        numForm(actionCount, taskWordFormsWith) +
        ` из ${selectedCount}.`,
    })
    reaction(
      () => this.query,
      () => this.paginated.setPage(1),
      { fireImmediately: true, equals: comparer.structural }
    )
    reaction(
      () => this.searchQuery,
      (search) => {
        this.searchQueryDelayed = search
      },
      { delay: 555 }
    )
    this.sorted = new SortableStore<any>({
      items: computed(() => this.paginated.fetchedValue?.data?.items.items || []),
      sortFunc: (item) => {
        return item.id
      },
      sorting: { col: 'id', direction: 'desc' },
    })
  }

  removeItemById = async (id: number) => {
    if (this.paginated.fetchedValue) {
      try {
        await fetchApi({
          url: `scenario_tasks/multi_action`,
          method: 'POST',
          data: {
            action_type: 'delete_draft',
            ids: [id],
          },
        })
        filterCollection(this.paginated.fetchedValue.data.items, (item) => {
          return item.data.id !== id
        })
        this.paginated.fetch()
        this.toast.success({ title: 'Черновики удалены' })
      } catch (e: any) {
        this.toast.error({
          title: 'Не удалось удалить черновик',
          description: e.message,
        })
      }
    }
  }

  duplicateItemById = async (id: number) => {
    if (this.paginated.fetchedValue) {
      try {
        await fetchApi({
          url: `scenario_tasks/multi_action`,
          method: 'POST',
          data: {
            action_type: 'duplicate',
            ids: [id],
          },
        })
        this.paginated.fetch()
        this.toast.success({ title: 'Создана копия задания' })
      } catch (e: any) {
        this.toast.error({
          title: 'Не удалось создать копию',
          description: e.message,
        })
      }
    }
  }
}

const ListHeaderCheckbox = observer(
  ({
    value,
    isIndeterminate,
    onChange,
  }: {
    value: boolean
    isIndeterminate: boolean
    onChange: () => void
  }) => (
    <Checkbox
      style={{
        marginLeft: 5,
      }}
      isIndeterminate={isIndeterminate}
      value={value}
      onChange={onChange}
    />
  )
)

export const TaskListRow = observer(
  ({
    item,
    isSelected,
    onSelectChange,
    onRemove,
    onDuplicate,
  }: React.PropsWithChildren<{
    item: any
    isSelected: boolean
    onSelectChange: () => void
    onRemove: (id: number) => void
    onDuplicate: (id: number) => void
  }>) => {
    const history = useHistory()

    const isRemoveActionDisabled = !item.actions?.includes('delete_draft')

    let menu = () => (
      <>
        <MenuItem value="duplicate">Создать копию</MenuItem>
        <MenuItem
          isDisabled={isRemoveActionDisabled}
          value="delete_draft"
          style={{
            opacity: isRemoveActionDisabled ? 0.5 : 1.0,
            color: 'var(--color-red)',
          }}
        >
          Удалить
        </MenuItem>
      </>
    )

    const statusColorMap: Record<string, any> = {
      draft: 'gray',
      created: 'gray',
      suggested: 'purple',
      declined: 'red',
      cancelled: 'red',
      in_progress: 'blue',
      not_completed: 'red',
      completed: 'green',
    }

    const statusLabelMap: Record<string, any> = {
      draft: 'Черновик',
      created: 'Черновик',
      suggested: 'Предложено',
      declined: 'Отказался',
      cancelled: 'Отменено',
      in_progress: 'В работе',
      not_completed: 'Не выполнено',
      completed: 'Выполнено',
    }

    const dateFormat = `DD.MM.YYYY`

    const dateLabel = `${
      item.since_date ? parseDate(item.since_date).format(dateFormat) : '...'
    } - ${item.upto_date ? parseDate(item.upto_date).format(dateFormat) : '...'}`
    const timeLabel = `с ${formatTime(item.since_time)} по ${formatTime(item.upto_time)}`

    const handleClick = () => {
      history.push(`/tasks/${item.id}`)
    }

    const createHandleActClick = (id: number) => () => {
      history.push(`/acts/${id}`)
    }

    const handleMenuSelect = (action?: string) => {
      if (action === 'delete_draft') {
        onRemove(item.id)
      } else if (action === 'duplicate') {
        onDuplicate(item.id)
      }
    }

    return (
      <ListItem style={{ alignItems: 'stretch', cursor: 'pointer' }} padding={false}>
        <ListCell style={{ maxWidth: 35 }} col="checkbox">
          <Checkbox
            style={{ marginLeft: 5, marginTop: -5 }}
            onChange={onSelectChange}
            value={isSelected}
          />
        </ListCell>
        <ListCell onClick={handleClick} col="contractor">
          <Flex direction="col" gap="1px">
            <span>{item.contractor.name}</span>
            {item.contractor.mobile_phone && (
              <span style={{ color: 'gray' }}>{item.contractor.mobile_phone}</span>
            )}
          </Flex>
        </ListCell>
        <ListCell onClick={handleClick} col="project">
          <span style={{ color: 'gray' }}>{item.project?.title || 'Не указан'}</span>
        </ListCell>
        <ListCell col="units">
          <UnitPopover item={item} />
        </ListCell>
        <ListCell onClick={handleClick} col="dateRange">
          <Flex direction="col" gap="1px">
            <span>{dateLabel}</span>
            <span style={{ color: 'gray' }}>{timeLabel}</span>
          </Flex>
        </ListCell>
        <ListCell onClick={handleClick} col="status">
          <Badge color={statusColorMap[item.status] || 'gray'}>
            {statusLabelMap[item.status] || item.status}
          </Badge>
        </ListCell>
        <ListCell col="act">
          {item.act?.id ? (
            <Link onClick={createHandleActClick(item.act?.id)}>Акт</Link>
          ) : (
            <span style={{ opacity: 0.5 }}>Не сформирован</span>
          )}
        </ListCell>
        <ListCell
          style={{
            display: 'flex',
            justifyContent: 'stretch',
          }}
          col="amount"
        >
          <span style={{ flex: 1 }}>{formatAmount(item.amount, 2, ',')}</span>
          <Flex align="start" justify="center">
            <Menu onSelect={handleMenuSelect} matchWidth menu={menu}>
              {(ref: any, { open }: any) => (
                <Button
                  ref={ref}
                  size="s"
                  variant="text"
                  onClick={(e: any) => {
                    e.stopPropagation()
                    open(e)
                  }}
                  style={{
                    width: 30,
                    marginRight: 5,
                    marginTop: -5,
                  }}
                >
                  <MoreIcon
                    fill="black"
                    style={{
                      transform: 'scale(0.5)',
                    }}
                  />
                </Button>
              )}
            </Menu>
          </Flex>
        </ListCell>
      </ListItem>
    )
  }
)

const PageHeaderLabel = () => {
  const history = useHistory()
  return (
    <Flex gap="24px">
      <div>
        <H1 className={clsx(classes.pageHeaderLabel, classes.active)}>Задания</H1>
      </div>
      <div onClick={() => history.push('/projects')}>
        <H1 className={classes.pageHeaderLabel}>Проекты</H1>
      </div>
    </Flex>
  )
}

const TaskPage = observer(() => {
  const { isMobile } = useMediaContext()

  const history = useHistory()
  const mobxApi = useMobxApi()
  const toast = useStateToast()

  const listStore = useLocalObservable(() => new TaskListStore(mobxApi, toast))

  const handleCreate = async () => {
    history.push('/tasks/create')
    // try {
    //   const { id } = await fetchApi({
    //     method: 'POST',
    //     url: 'scenario_tasks',
    //   });
    //   history.push(join(history.location.pathname, id.toString()));
    // } catch (e: any) {
    //   toast.error({
    //     title: 'Произошла ошибка при создании задания',
    //     description: e.message,
    //   });
    // }
  }

  const cols = {
    checkbox: {
      width: () => 35,
    },
    contractor: {
      width: (fullWidth: number) => (fullWidth - 35 - 175) / 7,
    },
    project: {
      width: (fullWidth: number) => (fullWidth - 35 - 175) / 7,
    },
    units: {
      width: (fullWidth: number) => (fullWidth - 35 - 175) / 7,
    },
    dateRange: {
      width: (fullWidth: number) => (fullWidth - 35 - 175) / 7,
    },
    status: {
      width: (fullWidth: number) => (fullWidth - 35 - 175) / 7,
    },
    act: {
      width: (fullWidth: number) => (fullWidth - 35 - 175) / 7,
    },
    amount: {
      width: () => 175,
    },
  }

  return (
    <Layout smallPaddingTop>
      <PageHeader
        title={<PageHeaderLabel />}
        controls={
          <>
            <Button onClick={handleCreate}>Создать задания</Button>
          </>
        }
      />
      <Flex direction="col" style={{ minHeight: '130px' }}>
        <Input
          icon={<SearchIcon />}
          value={listStore.searchQuery}
          onChange={(value: any) => listStore.setSearchQuery(value)}
          placeholder="Найти задание по исполнителю"
          style={{ marginBottom: 20 }}
        />
        <div className={classes.taskListFilterWrapper}>
          <SortFiltersPanel
            wordForms={taskWordFormsWith}
            sticky
            selectedStore={listStore.selected}
            actions={
              <>
                <MultiActionButton store={listStore.selected} action="suggest">
                  Отправить
                </MultiActionButton>
                <MultiActionButton store={listStore.selected} action="cancel">
                  Отменить
                </MultiActionButton>
                <MultiActionButton store={listStore.selected} action="complete">
                  Завершить
                </MultiActionButton>
                <MultiActionButton store={listStore.selected} action="not_complete">
                  Не выполнено
                </MultiActionButton>
                <MultiActionButton store={listStore.selected} action="create_act">
                  Создать акты
                </MultiActionButton>
                <MenuUi>
                  <MenuButton
                    isDisabled={listStore.selected.isFetching()}
                    as={ButtonUi}
                    design="normal"
                    size={isMobile ? 'xs' : 's'}
                    style={{ transform: 'none' }}
                  >
                    <Flex gap=".5rem" align="center">
                      Ещё
                      <DropdownIcon
                        style={{ marginRight: '-1rem', fill: 'var(--color-icon)' }}
                      />
                    </Flex>
                  </MenuButton>
                  <MenuList>
                    <MultiActionButton
                      store={listStore.selected}
                      action="duplicate"
                      as="menuItem"
                    >
                      Создать копии
                    </MultiActionButton>
                    <MultiActionButton
                      store={listStore.selected}
                      action="delete_draft"
                      as="menuItem"
                      style={{ color: 'var(--color-red)' }}
                    >
                      Удалить черновики
                    </MultiActionButton>
                  </MenuList>
                </MenuUi>
              </>
            }
          />
        </div>
        {listStore.selected.render()}
      </Flex>
      <PaginatedView
        store={listStore.paginated}
        render={(collection) => (
          <List cols={cols as any}>
            <>
              {collection.isEmpty ? (
                <Placeholder>Заданий не найдено</Placeholder>
              ) : (
                <ListHeader style={{ alignItems: 'center' }} padding={false}>
                  <ListCell style={{ maxWidth: 35 }} col="checkbox">
                    <SelectAllCheckbox
                      Checkbox={ListHeaderCheckbox}
                      store={listStore.selected}
                    />
                  </ListCell>
                  <ListCell col="contractor">Исполнитель</ListCell>
                  <ListCell col="project">Проект</ListCell>
                  <ListCell col="units">Работа</ListCell>
                  <ListCell col="dateRange">Период</ListCell>
                  <ListCell col="status">Статус</ListCell>
                  <ListCell col="act">Акт</ListCell>
                  <ListCell col="amount">Стоимость</ListCell>
                </ListHeader>
              )}
              {listStore.sorted.items.map((item, idx) => (
                <TaskListRow
                  item={item.data}
                  key={idx}
                  isSelected={listStore.selected.isItemSelected(item)}
                  onSelectChange={() => listStore.selected.toggleSelectItem(item)}
                  onRemove={listStore.removeItemById}
                  onDuplicate={listStore.duplicateItemById}
                />
              ))}
            </>
          </List>
        )}
        onChangePage={(page) => {
          if (listStore.paginated.fetchedValue) {
            listStore.paginated.setPage(page)
          }
        }}
      />
    </Layout>
  )
})

export default TaskPage
