import { ReactComponent as ArrowLeft } from './arrow-left.svg'
import { ReactComponent as Info } from './info.svg'
import { ReactComponent as Phone } from './phone.svg'
import { ReactComponent as Spinner } from './spinner.svg'
import { ReactComponent as TaxBox } from './tax-box.svg'
import { ReactComponent as Pay } from './pay.svg'
import { ReactComponent as Location } from './location.svg'
import { ReactComponent as Garbage } from './garbage.svg'
import { ReactComponent as Checkbox } from './checkbox.svg'

const icons = { ArrowLeft, Info, Phone, Spinner, TaxBox, Pay, Location, Garbage, Checkbox }

export default icons
