import { ComponentProps } from 'app.types'
import clsx from 'clsx'
import * as React from 'react'
import { Flex } from '..'
import styles from './styles.module.css'

export const CardValue = ({
  label,
  value,
  variant = 'primary',
  ...props
}: {
  label?: React.ReactNode
  value?: React.ReactElement
  variant?: 'primary' | 'active'
} & ComponentProps) => (
  <Flex gap=".325rem" align="center" className={styles.row} {...props}>
    {label && <label className={styles.label}>{label}</label>}
    {value &&
      React.cloneElement(value, {
        className: clsx(styles.cardValue, {
          [styles.cardValueActive]: variant === 'active',
        }),
      })}
  </Flex>
)
