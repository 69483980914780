import React from 'react'
import { observer } from 'mobx-react-lite'
import { Tooltip } from '@chakra-ui/react'

import { Checkbox as UiCheckbox } from 'components/ui'

import SelectedItemsStore from './SelectedItemsStore'

const SelectAllCheckbox = observer(
  ({
    store,
    label,
    readOnly = false,
    Checkbox = UiCheckbox,
  }: {
    Checkbox?: React.ComponentType<any>
    store: SelectedItemsStore<any>
    label?: React.ReactNode
    readOnly?: boolean
  }) => (
    <Tooltip
      label={store.selectedItems.size > 0 ? 'Снять выделение' : 'Выделить все'}
      aria-label="button"
      placement="top"
      style={{ zIndex: 100 }}
    >
      <div>
        <Checkbox
          value={store.isPageSelected}
          isIndeterminate={!store.isPageSelected && store.selectedItems.size > 0}
          onChange={readOnly ? undefined : () => store.toggleSelectPage()}
          label={label}
        />
      </div>
    </Tooltip>
  )
)

export default SelectAllCheckbox
