import React from 'react'
import { useMemo, useEffect } from 'react'

import { Field, FormRow } from 'components/ui'

import { Checkbox, Select, DatePicker, Input, Flex } from 'ui'

// @ts-ignore
import { FormStore } from 'shadowform'

import { OnboardingScenarioEntity } from 'entities/onboardingScenario'

import { useMediaContext } from 'App/MediaContext'

import useScenarioModalStore from '../../hooks/useScenarioModalStore'

import { observer, useLocalObservable } from 'mobx-react-lite'

import { parseDate, serializeDate } from 'utils/datetime'

import NumberInput from 'components/NumberInput'

interface ICustomFieldProps {
  field: OnboardingScenarioEntity['contractor_custom_fields'][0]
  idx: number
}

export const CustomFieldView = observer(({ field, idx }: ICustomFieldProps) => {
  const scenarioModalStore = useScenarioModalStore()

  const joinedField = useMemo(() => {
    const fieldData = scenarioModalStore.customFields.find(({ id }) => id === field.uuid)!
    const joinedField = {
      ...fieldData,
      ...field,
    } as const
    return joinedField
  }, [idx, field.uuid])

  const formStore = useLocalObservable(
    () =>
      new FormStore({
        initialValues: {
          defaultValue: joinedField.default_value,
        },
        fields: {
          defaultValue: {},
        },
      })
  )

  useEffect(() => {
    formStore.fields.defaultValue.change(joinedField.default_value)
  }, [joinedField])

  useEffect(() => {
    const unsubscribe = formStore.fields.defaultValue.on('change', () => {
      const newValue = formStore.values.defaultValue
      scenarioModalStore.updateFieldDefaultValue(idx, newValue)
    })
    return () => unsubscribe()
  }, [idx])

  const { isMobile } = useMediaContext()
  const size = isMobile ? 'm' : 'l'

  const renderInner = () => (
    <Field field={formStore.fields.defaultValue}>
      {({ inputProps }) => {
        if (joinedField.kind === 'dropdown') {
          const options = joinedField.options?.map(({ id, name }) => ({
            label: name,
            value: id,
          }))
          return (
            <Select
              {...inputProps}
              value={
                inputProps.value
                  ? options?.find((o) => o.value === inputProps.value)
                  : undefined
              }
              onChange={(option: any) =>
                formStore.fields.defaultValue.change(option ? option.value : null)
              }
              size={size}
              options={options}
              isSearchable={false}
              placeholder={joinedField.name}
              noOptionsMessage={() => 'Нет значений'}
            />
          )
        } else if (joinedField.kind === 'date') {
          return (
            <DatePicker
              {...inputProps}
              value={inputProps.value ? parseDate(inputProps.value).toDate() : undefined}
              onChange={(value) => {
                formStore.fields.defaultValue.change(
                  value ? serializeDate(value as Date) : ''
                )
              }}
              placeholder={joinedField.name}
            />
          )
        } else if (joinedField.kind === 'number') {
          return (
            <NumberInput
              {...inputProps}
              placeholder={joinedField.name}
              allowDecimal={true}
              Input={Input}
            />
          )
        } else if (joinedField.kind === 'checkbox') {
          return (
            <Checkbox
              {...inputProps}
              style={{
                marginLeft: -5,
                marginTop: -5,
              }}
              label={
                <span style={{ color: 'black', fontWeight: 500 }}>
                  По умолчанию отмечено
                </span>
              }
            />
          )
        } else if (joinedField.kind === 'text') {
          return <Input {...inputProps} placeholder={joinedField.name} size={size} />
        } else {
          return <Input {...inputProps} placeholder={joinedField.name} size={size} />
        }
      }}
    </Field>
  )

  if (joinedField.kind === 'checkbox') {
    return renderInner()
  } else {
    return (
      <FormRow label="Значение по умолчанию" size={size}>
        <Flex direction="col" gap="10px">
          {renderInner()}
          {field.fill_target === 'contractor' ? (
            <Checkbox
              style={{
                marginLeft: -5,
              }}
              label={
                <span style={{ color: 'black', fontWeight: 500 }}>
                  Обязательно для заполнения
                </span>
              }
              onChange={(value: any) =>
                scenarioModalStore.updateFieldRequired(idx, value)
              }
              value={field.required}
            />
          ) : (
            <div />
          )}
        </Flex>
      </FormRow>
    )
  }
})

export default CustomFieldView
