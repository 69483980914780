import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useHistory } from 'react-router'
import jwtDecode from 'jwt-decode'

import type { PageProps } from 'app.types'
import { fetchApi } from 'api'
import { useUserStore } from 'stores/context'

const TokenLoginPage = observer(({ match }: PageProps) => {
  let history = useHistory()
  let currentUser = useUserStore()
  useEffect(() => {
    currentUser.logout()
    fetchApi({
      method: 'POST',
      url: 'authorizations/token_login',
      data: match.params,
    }).then(
      (response) => {
        let payload
        try {
          payload = jwtDecode(match.params.token!) as any
        } catch (e) {}
        let { redirect_to } = payload
        let url = redirect_to ? `/${redirect_to}` : '/'
        currentUser.login({
          source: 'web',
          user: response,
          companyId: response.companies[0]?.id,
          initialUrl: url,
        })
        history.replace(url)
      },
      () => {
        history.replace('/')
      }
    )
  }, [])
  return <div />
})

export default TokenLoginPage
