import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import cl from 'clsx'
import { fetchApi } from 'api'
import { TaxBox, TaxBoxOperation, TaxBoxOperationItem } from 'entities'
import {
  Amount,
  AmountDifference,
  Button,
  ButtonVariant,
  Card,
  H4,
  InlineText,
  Link,
  Notification,
  NotificationType,
  P1,
  PageBox,
  PageHeader,
  Table,
  TableCellType,
  TableProps,
  TypographyVariant,
} from 'ui-kit'
import { useUserStore } from 'stores/context'
import {
  EstimatedTaxModal,
  SelfPayModal,
  TaxPaymentPermissionModal,
} from 'pages/taxes/components'
import { ReactComponent as SettingsIcon } from 'assets/settings.svg'
import { formatTime } from 'utils'
import { useStateToast } from 'components/ui'
import { isNull } from 'lodash'
import { getErrorMessage } from 'utils/error'

import css from './styles.module.css'

type FlexibleTaxBoxProps = {}

const prepareTableProps = (
  prevState: TableProps,
  operations: TaxBoxOperation[]
): TableProps => {
  return {
    desktop: {
      colNames: prevState.desktop.colNames,
      thClassNames: prevState.desktop.thClassNames,
      rows: operations.map((operation: TaxBoxOperation) => {
        const { kind, date, title, amount } = operation
        return [
          {
            type: TableCellType.Date,
            content: date,
          },
          {
            type: TableCellType.Custom,
            content: (
              <>
                <P1 className={css.operationTitle}>{title}</P1>
                {operation.items.map((item: TaxBoxOperationItem, i: number) => (
                  <P1 key={i} className={css.operationItemTitle}>
                    {item.title}
                  </P1>
                ))}
              </>
            ),
          },
          {
            type: TableCellType.Custom,
            content: (
              <>
                <Amount
                  className={cl(
                    css.amount,
                    kind === 'withdrawal' ? css.negativeAmount : css.positiveAmount
                  )}
                  value={amount}
                  difference={
                    kind === 'withdrawal'
                      ? AmountDifference.Negative
                      : AmountDifference.Positive
                  }
                  typographyVariant={TypographyVariant.P1}
                ></Amount>
                {operation.items.map((item: TaxBoxOperationItem, i: number) => (
                  <Amount
                    className={css.amount}
                    key={i}
                    value={item.amount}
                    difference={
                      kind === 'withdrawal'
                        ? AmountDifference.Negative
                        : AmountDifference.Positive
                    }
                    typographyVariant={TypographyVariant.P1}
                  ></Amount>
                ))}
              </>
            ),
          },
        ]
      }),
    },
    mobile: operations.map((operation: TaxBoxOperation) => {
      const { date, title, amount, kind } = operation
      return (
        <>
          <InlineText className={css.tableCardTime}>{formatTime(date)}</InlineText>
          <div className={css.tableCardOperations}>
            <div className={css.tableCardColumn}>
              <P1 className={css.operationTitle}>{title}</P1>
            </div>
            <div className={css.tableCardColumn}>
              <Amount
                className={cl(
                  css.amount,
                  kind === 'withdrawal' ? css.negativeAmount : css.positiveAmount
                )}
                difference={
                  kind === 'withdrawal'
                    ? AmountDifference.Negative
                    : AmountDifference.Positive
                }
                value={amount}
                typographyVariant={TypographyVariant.P1}
              ></Amount>
            </div>
          </div>
          {operation.items.map((item: TaxBoxOperationItem, i: number) => (
            <div className={css.tableCardOperations} key={i}>
              <div className={css.tableCardColumn}>
                <P1 className={css.operationItemTitle}>{item.title}</P1>
              </div>
              <div className={css.tableCardColumn}>
                <P1 className={css.amount}>
                  {kind === 'withdrawal' ? '-' : '+'}
                  {item.amount} ₽
                </P1>
              </div>
            </div>
          ))}
        </>
      )
    }),
  }
}

const FlexibleTaxBox: React.FunctionComponent<FlexibleTaxBoxProps> = () => {
  const history = useHistory()
  const toast = useStateToast()

  const currentUser = useUserStore()
  const taxBoxId = currentUser.taxBoxId

  const [taxBox, setTaxBox] = useState<null | TaxBox>(null)
  const [openSelfPayModal, setOpenSelfPayModal] = useState(false)
  const [openEstimatedTaxModal, setOpenEstimatedTaxModal] = useState(false)
  const [openTaxPaymentPermissionModal, setOpenTaxPaymentPermissionModal] =
    useState(false)
  const [tableProps, setTableProps] = useState<TableProps>({
    desktop: {
      colNames: ['Дата', 'Операции', 'Сумма'],
      thClassNames: [css.dateCol, null, css.amountCol],
      rows: [],
    },
    mobile: [],
  })

  const getTaxBoxData = useCallback(async () => {
    try {
      const result = (await fetchApi({
        url: `tax_boxes/${taxBoxId}`,
        method: 'GET',
      })) as TaxBox

      setTaxBox(result)
      setTableProps((prevState: TableProps) => {
        return prepareTableProps(prevState, result.operations)
      })
    } catch (err) {
      console.error(err)
    }
  }, [taxBoxId])

  const handleTaxOfficeRequest = async () => {
    try {
      const result = await fetchApi({
        url: `/tax_boxes/${taxBoxId}/send_request`,
        method: 'PUT',
      })
      if (result.status === 'request_sent') {
        setOpenTaxPaymentPermissionModal(true)
      } else if (result.status === 'success') {
        toast.success({ title: 'Разрешение подтверждено' })
        setTaxPaymentPermission(true)
        setOpenTaxPaymentPermissionModal(false)
      }
    } catch (err: unknown) {
      toast.error({ title: 'Ошибка', description: getErrorMessage(err) })
    }
  }

  const handleSettingsButtonClick = () => {
    history.push('/taxes/settings')
  }

  const toggleSelfPayModalVisibility = () => {
    setOpenSelfPayModal((prevState) => !prevState)
  }

  const toggleEstimatedTaxModalVisibility = () => {
    setOpenEstimatedTaxModal((prevState) => !prevState)
  }

  const toggleTaxPaymentPermissionModalVisibility = () => {
    setOpenTaxPaymentPermissionModal((prevState) => !prevState)
  }

  const setTaxPaymentPermission = (status: boolean) => {
    setTaxBox((prevState) => {
      if (!isNull(prevState)) {
        return {
          ...prevState,
          tax_payment_permission: status,
        }
      }
      return null
    })
  }

  useEffect(() => {
    getTaxBoxData()
  }, [])

  return (
    <PageBox>
      <PageHeader
        title="Налоговая копилка"
        action={
          <Button
            variant={ButtonVariant.Secondary}
            onClick={handleSettingsButtonClick}
            className={css.settingsButton}
          >
            <SettingsIcon className={css.settingsIcon} />
          </Button>
        }
      />
      <Notification
        type={NotificationType.Question}
        linkText="Как работает копилка?"
        linkHref="https://help.konsol.pro/tax-money-box-2"
      ></Notification>
      {taxBox && (
        <>
          {!taxBox.tax_payment_permission && (
            <Notification
              type={NotificationType.Fail}
              title="Подтвердите разрешение на оплату налогов от вашего имени"
              description="Без этого мы не сможем получать ваши налоговые начисления и оплачивать их за вас"
              buttonLabel="Дать разрешение"
              onClick={handleTaxOfficeRequest}
              className={css.failNotification}
            ></Notification>
          )}
          <div className={css.generalInfo}>
            <Card className={css.generalInfoCard}>
              <P1 className={css.generalInfoHeading}>Отложено на оплату</P1>
              <Amount
                className={css.generalInfoAmount}
                value={taxBox.savings.amount}
                typographyVariant={TypographyVariant.H2}
              ></Amount>
            </Card>
            <Card className={css.generalInfoCard}>
              <P1 className={css.generalInfoHeading}>
                Налоги {taxBox.taxes.items[0].title.toLowerCase()}
              </P1>
              <Amount
                className={css.generalInfoAmount}
                value={taxBox.taxes.items[0].tax_amount}
                typographyVariant={TypographyVariant.H2}
              ></Amount>
              <Link onClick={toggleEstimatedTaxModalVisibility}>Показать расчет</Link>
            </Card>
            <Card className={css.generalInfoCard}>
              <P1 className={css.generalInfoHeading}>Задолженность</P1>
              <Amount
                className={css.generalInfoAmount}
                value={taxBox.unpaid_taxes?.amount}
                typographyVariant={TypographyVariant.H2}
              ></Amount>
              <Link onClick={toggleSelfPayModalVisibility}>Как погасить долг?</Link>
            </Card>
          </div>
          <H4 className={css.operationsHeading}>Операции</H4>
          <Table {...tableProps} />
          <EstimatedTaxModal
            open={openEstimatedTaxModal}
            taxesItems={taxBox.taxes.items}
            onClose={toggleEstimatedTaxModalVisibility}
          />
          <SelfPayModal open={openSelfPayModal} onClose={toggleSelfPayModalVisibility} />
          {!taxBox.tax_payment_permission && taxBoxId && (
            <TaxPaymentPermissionModal
              id={taxBoxId}
              open={openTaxPaymentPermissionModal}
              onClose={toggleTaxPaymentPermissionModalVisibility}
              setTaxPaymentPermission={setTaxPaymentPermission}
            />
          )}
        </>
      )}
    </PageBox>
  )
}

export default FlexibleTaxBox
