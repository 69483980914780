import { transaction } from "mobx";
import { Collection, Entity } from "mobx/MobxApi";

export const filterCollection = <T>(data: Collection<T>, fn: (item: Entity<T>) => boolean) => {
  const result: Entity<T>[] = [];
  transaction(() => {
    while (data.allItems.length) {
      const lastItem = data.allItems.pop()!;
      if (fn(lastItem)) {
        result.push(lastItem);
      }
    }
    for (const item of result) {
      data.allItems.push(item);
    }
  });
};

export default filterCollection;
