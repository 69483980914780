import React from 'react'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { Menu, MenuButton, MenuList, MenuItem, Tooltip } from '@chakra-ui/react'

import { Collection, Entity, ActionState } from 'mobx/mobx'
import type { ProjectEntity, TaskEntity } from 'entities'
import { Reveal, BaseButton, Flex } from 'components/ui'

import s from './styles.module.css'
import { ReactComponent as AddIcon } from './add.svg'

interface ProjectSelectorProps {
  projects: Array<Entity<ProjectEntity>>
  onSelect: (project: Entity<ProjectEntity>) => void
  smallButton: boolean
}

const ProjectsMenu = ({ projects, onSelect, smallButton }: ProjectSelectorProps) => (
  <Menu>
    {smallButton ? (
      <MenuButton
        className={smallButton ? s.smallAddButton : s.fullAddButton}
        as={BaseButton}
        key="small_button"
      >
        <Tooltip
          label="Добавить в проект"
          placement="top"
          aria-label="button"
          className={s.tooltip}
        >
          <AddIcon className={s.addIcon} />
        </Tooltip>
      </MenuButton>
    ) : (
      <MenuButton className={s.fullAddButton} as={BaseButton} key="big_button">
        <Flex align="center">
          <AddIcon className={s.addIcon} />
          <div>Добавить в проект</div>
        </Flex>
      </MenuButton>
    )}
    <MenuList
      // @ts-ignore
      placement="bottom-start"
      style={{ zIndex: 1000, maxWidth: 300, maxHeight: '50vh', overflow: 'auto' }}
    >
      {projects &&
        projects.map((project) => (
          <MenuItem
            key={project.id}
            onClick={() => onSelect(project)}
            style={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              lineHeight: '1rem',
              display: 'block',
            }}
          >
            {project.data.title}
          </MenuItem>
        ))}
    </MenuList>
  </Menu>
)

interface TaskProjectSelectorProps {
  task: Entity<TaskEntity>
  fetchProjectsState: ActionState<Collection<ProjectEntity>>
  canUpdate: boolean
}

const TaskProjectSelector = observer((props: TaskProjectSelectorProps) => {
  let { task, fetchProjectsState, canUpdate } = props
  let local = useLocalObservable(() => ({
    get filteredProjects() {
      if (fetchProjectsState.state !== 'fulfilled') return []
      let projects = fetchProjectsState.value
      let ids = task.data.projects ? task.data.projects.items.map((item) => item.id) : []
      return projects.items.filter((project) => !ids.includes(project.id))
    },
  }))

  let projects: any = fetchProjectsState.value
  let addToProject = (id: number) => {
    let project = projects.map[id]
    task.data.projects.append(project)
    task
      .action({ action: 'add_to_project', payload: { project_id: id } })
      // @ts-ignore
      .catch(() => task.data.projects.remove(project))
  }
  let removeFromProject = (id: number) => {
    let project = projects.map[id]
    task.data.projects.remove(project)
    task
      .action({ action: 'remove_from_project', payload: { project_id: id } })
      // @ts-ignore
      .catch(() => task.data.projects.add(project))
  }

  return (
    <div className={s.root}>
      {task.data.projects?.items.map((project) => (
        <div key={project.id} className={s.badge}>
          {project.data.title}
          {canUpdate && (
            <Tooltip
              label="Убрать из проекта"
              placement="top"
              aria-label="button"
              className={s.tooltip}
            >
              <BaseButton
                className={s.removeButton}
                classNames={{ hovered: s.removeButtonHovered }}
                onClick={() => removeFromProject(project.id!)}
              >
                ✕
              </BaseButton>
            </Tooltip>
          )}
        </div>
      ))}
      {canUpdate && local.filteredProjects.length > 0 && (
        <Reveal animation="fadeIn" style={{ lineHeight: 0 }}>
          <ProjectsMenu
            projects={local.filteredProjects}
            onSelect={(project) => addToProject(project.id!)}
            smallButton={task.data.projects?.items.length > 0}
          />
        </Reveal>
      )}
    </div>
  )
})

export default TaskProjectSelector
