import React from 'react'
import { observer } from 'mobx-react-lite'
//@ts-ignore
import { useShowError } from 'shadowform'

interface FieldProps {
  field: any
  showRequiredError?: any
  showValidationErrors?: any
  children: ({
    inputProps,
    error,
  }: {
    inputProps: any
    error?: string
  }) => React.ReactNode
}

const Field = observer((props: FieldProps) => {
  let {
    field,
    showRequiredError = 'onBlurTouched',
    showValidationErrors = 'onBlurTouched',
    children,
  } = props
  let { showError, onFocus, onBlur } = useShowError({
    field,
    showRequiredError,
    showValidationErrors,
  })
  let inputProps = {
    value: field.value,
    onChange: (value: string) => field.change(value),
    onFocus,
    onBlur,
    isInvalid: showError,
  }
  return <>{children({ inputProps, error: showError ? field.error.value : undefined })}</>
})

export default Field
