import React from 'react'
import { observer } from 'mobx-react-lite'
// @ts-ignore
import { FormStore } from 'shadowform'
import { repeat } from 'lodash'

import { phoneMask, createDigitsMask } from 'utils/validations'
import { Flex, FormRow, RadioInput, Field, Input } from 'components/ui'

const PhoneOrInnForm = observer(({ form }: { form: FormStore }) => (
  <Flex direction="column" gap="1rem">
    <Field field={form.fields.kind}>
      {({ inputProps }) => (
        <RadioInput
          {...inputProps}
          options={[
            { value: 'phone', label: 'По номеру телефона' },
            { value: 'inn', label: 'По ИНН' },
          ]}
        />
      )}
    </Field>
    {form.fields.kind.value === 'phone' ? (
      <Field field={form.fields.phone} key="phone">
        {({ inputProps, error }) => (
          <FormRow size="l" error={error} style={{ maxWidth: 400 }}>
            <Input
              {...inputProps}
              numericKeyboard
              mask={phoneMask}
              isWide
              size="l"
              placeholder="+7 (000) 000-00-00"
            />
          </FormRow>
        )}
      </Field>
    ) : (
      <Field field={form.fields.inn}>
        {({ inputProps, error }) => (
          <FormRow size="l" error={error} style={{ maxWidth: 400 }} key="inn">
            <Input
              {...inputProps}
              numericKeyboard
              mask={createDigitsMask(12)}
              isWide
              size="l"
              placeholder={repeat('0', 12)}
            />
          </FormRow>
        )}
      </Field>
    )}
  </Flex>
))

export default PhoneOrInnForm
