import { rest } from 'msw'

import { json } from './helpers'

import { companies } from './companies'
import { tax_box } from './taxes'
import { task } from './tasks'

const url =
  'https://allbeton.ru/upload/iblock/9ad/sto_87313302_13330_001_2012_konstrukcii_s_primeneniem_avtoklavnogo_gazobetona_v_stroitelstve_zdanii_i_soorujenii_pravila_....pdf'

const new_tasks = [
  {
    id: 13371,
    document_number: { number: 1078 },
    title: 'Доставка биоматериала под дверь заказчику',
    kind: 'act',
    since: '2022-02-22',
    upto: '2022-02-23',
    display_amount: '100.45',
    task: {
      status: 'assigned',
    },
    act: {
      status: 'not_accepted',
      amount: '200.9',
      payment_amount: '100.45',
      work_acceptance_original_pdf_url: url,
      work_acceptance_blurb_pdf_url: null,
    },
    payment: { status: 'not_paid', error: null },
    actions: ['make_work_acceptance'],
    contractor: { id: 200, name: 'Бабань Михаил', task_flags: [] },
    company: { id: 22, name: 'Очень длинное очень длинное название компании' },
  },
]

const acts_for_sign = [
  {
    id: 13372,
    document_number: { number: 1078 },
    title: 'Доставка биоматериала под дверь заказчику',
    check_payment_details: true,
    kind: 'act',
    since: '2022-02-22',
    upto: '2022-02-23',
    display_amount: '100.45',
    task: {
      status: 'assigned',
    },
    act: {
      status: 'accepted_by_company',
      amount: '200.9',
      payment_amount: '100.45',
      work_acceptance_original_pdf_url: url,
      work_acceptance_blurb_pdf_url: null,
    },
    payment: { status: 'not_paid', error: null },
    actions: ['make_work_acceptance', 'reject'],
    contractor: {
      id: 200,
      name: 'Бабань Михаил',
      task_flags: [],
      moi_nalog: { status: 'unbound' },
    },
    company: { id: 22, name: 'Очень длинное очень длинное название компании' },
  },
  {
    id: 13373,
    document_number: { number: 1078 },
    title: 'Доставка биоматериала под дверь заказчику',
    kind: 'act',
    since: '2022-02-22',
    upto: '2022-02-23',
    display_amount: '100.45',
    task: {
      status: 'assigned',
    },
    act: {
      status: 'accepted_by_company',
      amount: '200.9',
      payment_amount: '100.45',
      work_acceptance_original_pdf_url: url,
      work_acceptance_blurb_pdf_url: null,
    },
    payment: { status: 'not_paid', error: null },
    actions: ['make_work_acceptance', 'reject'],
    contractor: {
      id: 201,
      name: 'Бабань Михаил',
      task_flags: [],
      moi_nalog: { status: 'connected' },
      payment_details: { kind: 'card', card: '123' },
    },
    company: { id: 22, name: 'Очень длинное очень длинное название компании' },
  },
  {
    id: 13374,
    document_number: { number: 1078 },
    title: 'Акт в евро',
    kind: 'act',
    since: '2022-02-22',
    upto: '2022-02-23',
    display_amount: { value: '200.9', currency: 'EUR' },
    task: {
      status: 'assigned',
    },
    act: {
      status: 'accepted_by_company',
      amount: { value: '200.9', currency: 'EUR' },
      work_acceptance_original_pdf_url: url,
      work_acceptance_blurb_pdf_url: null,
    },
    payment: { status: 'not_paid', error: null },
    actions: ['make_work_acceptance', 'reject'],
    contractor: {
      id: 201,
      name: 'Бабань Михаил',
      task_flags: [],
      moi_nalog: { status: 'connected' },
      payment_details: { kind: 'card', card: '123' },
    },
    company: { id: 22, name: 'Очень длинное очень длинное название компании' },
  },
]

const recent_acts = [
  {
    id: 13375,
    document_number: { number: 1078 },
    title: 'Доставка биоматериала под дверь заказчику',
    kind: 'act',
    display_amount: '100.45',
    task: {
      status: 'assigned',
    },
    act: {
      status: 'accepted_by_company',
      amount: '200.9',
      payment_amount: '100.45',
    },
    payment: { status: 'paid', error: null },
    actions: ['make_work_acceptance'],
    contractor: { id: 200, name: 'Бабань Михаил', task_flags: [] },
    company: { id: 22, name: 'Очень длинное очень длинное название компании' },
  },
]

const dashboard = {
  income: {
    amount: 0,
    month: 'март',
  },
  notifications: [
    { key: 'test', title: 'Заголовок', content: 'Содержимое<br/>В несколько<br/>Строк' },
  ],
  new_tasks,
  acts_for_sign,
  recent_acts,
  tax_box,
  companies,
  rejected_transactions: [
    {
      id: 123,
      task,
    },
    {
      id: 455,
      task: { ...task, title: 'Другая таска' },
    },
  ],
  scenario_tasks: [],
}

export default [
  rest.get('/api/contractor/dashboard', json(dashboard)),
  // rest.get('/api/contractor/dashboard', json(empty_dashboard)),
]
