import { Base64 } from 'js-base64'

export const parseBase64Json = (state: string) => {
  try {
    return JSON.parse(Base64.decode(state))
  } catch (e) {
    return undefined
  }
};

export const stringifyBase64Json = (state: Record<string, any>) => {
  return Base64.encode(JSON.stringify(state));
};
