import React from 'react'

import { fetchApi } from '../../../../api'
import { Flex } from 'components/ui'

import css from './styles.module.css'

const StatusOptionButton = ({ event, title, description, onClick }: any) => (
  <Flex
    align="center"
    gap=".75rem"
    className={css.button}
    onClick={() => {
      fetchApi({
        url: 'users/events',
        method: 'PUT',
        data: { event },
      })
      onClick()
    }}
  >
    <div className={css.content}>
      <b>{title}</b>
      {description && <div>{description}</div>}
    </div>
    <div className={css.icon}>
      <svg
        width="6"
        height="10"
        viewBox="0 0 6 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.125 8.75L4.875 5L1.125 1.25"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M1.125 8.75L4.875 5L1.125 1.25"
          stroke="#858DA2"
          stroke-opacity="0.64"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  </Flex>
)

export default StatusOptionButton
