import React from 'react'
import cx from 'clsx'

import { ReactComponent as SpinnerIcon } from './spinner.svg'
import css from './styles.module.css'

type SpinnerSize = 's' | 'm' | 'l'

export interface SpinnerProps {
  size?: SpinnerSize
  fill?: string
  style?: React.CSSProperties
  className?: string
}

const defaultProps = {
  size: 'm' as SpinnerSize,
}

type SpinnerPropsWithDefault = SpinnerProps & typeof defaultProps

const sizes = {
  s: 12,
  m: 16,
  l: 20,
}

const Spinner = (props: SpinnerProps) => {
  const { size, fill, className, style } = props as SpinnerPropsWithDefault
  const s = sizes[size]
  return (
    <div className={cx(css.root, className)} style={style}>
      <SpinnerIcon style={{ width: s, height: s }} fill={fill} />
    </div>
  )
}

Spinner.defaultProps = defaultProps

export { Spinner }
