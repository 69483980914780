import React from 'react'
// import { DownloadAppCard } from 'pages/registration/DownloadAppCard'
import RegistrationFooter from 'pages/registration/components/Footer'
import PaymentDetailsStep from 'pages/registration/steps/PaymentDetailsStep'

import { observer } from 'mobx-react-lite'
import { Entity } from 'mobx/MobxApi'
import { UserRegistrationEntity } from 'entities'
import { Flex, H1 } from 'ui'

import css from './styles.module.css'

const CheckInProgressStep = observer(
  ({ registration }: { registration: Entity<UserRegistrationEntity> }) => {
    const { payment_details_required } = registration.data

    // показываем форму заполнения банковских реквизитов или карты
    if (payment_details_required) {
      return <PaymentDetailsStep registration={registration} />
    }

    return (
      <>
        <Flex gap="2rem" direction="col">
          <H1>Получили и проверяем документы</H1>
          <div>
            В рабочее время это занимает до часа. Пришлём уведомление с результатом
            проверки.
          </div>
          <div>
            Работаем с понедельника по пятницу, с 9:00 до 21:00 по Москве. Если завершили
            заполнение в нерабочее время — не переживайте, доберёмся до вашей анкеты и
            проверим так быстро, как только сможем, когда начнём работать.
          </div>
          {/* <DownloadAppCard /> */}
          <div className={css.checkImage} />
        </Flex>
        <RegistrationFooter />
      </>
    )
  }
)

export default CheckInProgressStep
