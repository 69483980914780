import React from 'react'

import { computed, makeAutoObservable, runInAction } from 'mobx'

import { createContext } from 'react'
import { useContext } from 'react'

import { useLocalObservable } from 'mobx-react-lite'
import { useStateToast } from 'components/ui'

import MobxApi, { Entity } from 'mobx/MobxApi'

import { SortableStore } from 'components/FilteredList'
import { SelectedItemsStore } from 'components/FilteredList2'

import { ScenarioProjectTaskDocument } from 'entities'
import { useMobxApi } from 'mobx/MobxApiContext'
import { parseDate } from 'utils/datetime'
import { PageProps } from 'app.types'
import { fetchApi } from 'api'

import numForm from 'utils/numForm'

export const taskWordFormsWith = {
  one: 'задание',
  two: 'заданий',
  many: 'заданий',
}

export const actionConfigs = [
  { action: 'create_act' }, // Создать акты
  { action: 'complete' }, // Завершить
  { action: 'not_complete' }, // Не выполнено
  { action: 'cancel' }, // Отменить
  { action: 'delete_draft' }, // Удалить черновики
  { action: 'suggest' }, // Отправить
  { action: 'duplicate' }, // Создать копию
]

const actionSuccessMap: Record<string, any> = {
  create_act: 'Акты созданы',
  complete: 'Задания завершены',
  not_complete: 'Задания отмечены не выполненными',
  cancel: 'Задания отменены',
  delete_draft: 'Черновики удалены',
  suggest: 'Задания отправлены',
  duplicate: 'Копии созданы',
}

const actionErrorMap: Record<string, any> = {
  create_act: 'Не удалось создать акты',
  complete: 'Не удалось завершить задания',
  not_complete: 'Не удалось отметить задания не выполненными',
  cancel: 'Не удалось отменить задания',
  delete_draft: 'Не удалось удалить черновики',
  suggest: 'Не удалось отправить задания',
  duplicate: 'Не удалось создать копии',
}

class TaskListStore {
  private _sorted: SortableStore<ScenarioProjectTaskDocument>
  public _selected: SelectedItemsStore<ScenarioProjectTaskDocument>

  private _items: ScenarioProjectTaskDocument[] = []

  setItems = (items: ScenarioProjectTaskDocument[]) => (this._items = items)

  get items() {
    return this._sorted.items
  }

  get sortModel() {
    return this._sorted
  }

  get selectedStore() {
    return this._selected
  }

  constructor(
    readonly mobxApi: MobxApi,
    readonly toast: ReturnType<typeof useStateToast>,
    readonly pageProps: PageProps
  ) {
    makeAutoObservable(this)
    this._sorted = new SortableStore<ScenarioProjectTaskDocument>({
      items: computed(() => this._items),
      sortFunc: (item, sorting) => {
        if (sorting === 'contractor') return item.contractor?.name || ''
        if (sorting === 'project') return item.project?.title || ''
        if (sorting === 'dateRange')
          return item.since_date ? parseDate(item.since_date!).toDate().getTime() : 0
        if (sorting === 'status') return item.status
        if (sorting === 'amount') return item.amount
        if (sorting === 'id') return item.id
        return undefined
      },
      sorting: { col: 'id', direction: 'desc' },
    })
    const actions: Record<string, any> = {}
    actionConfigs.forEach(({ action }) => {
      actions[action] = {
        ability: true,
        getItemsForAction: (selectedItems: any) =>
          mobxApi.fetch({
            type: 'scenario_tasks',
            url: `scenario_tasks/for_multi_action`,
            options: { method: 'POST' },
            payload: selectedItems
              ? { ids: selectedItems.map((item: any) => item.id), action_type: action }
              : { action_type: action },
          }),
        /*getItemsForAction: async (selectedItems: any) => {
          const data = await mobxApi.fetch({
            type: 'scenario_tasks',
            url: `scenario_tasks/for_multi_action`,
            options: { method: 'GET' },
            payload: selectedItems
              ? { ids: selectedItems.map((item: any) => item.id), action_type: action }
              : { action_type: action },
          }) as Collection<any>
          const collection = new Collection({
            api: this.mobxApi,
            type: 'scenario_tasks',
          })
          const selectedIds = new Set(selectedItems.map((item: any) => item.id));
          data.items.forEach((item: any) => {
            if (selectedIds.has(item.id)) {
              collection.append(item);
            }
          });
          return collection;
        },*/
        action: (tasks: any) => {
          const ids = tasks.map((task: any) => task.id)

          fetchApi({
            url: `scenario_tasks/multi_action`,
            method: 'POST',
            data: {
              action_type: action,
              ids,
            },
          }).then(
            (data: any) => {
              runInAction(() => {
                if (action === 'delete_draft') {
                  this._items = this._items.filter(({ id }) => {
                    return !ids.includes(id)
                  })
                }
              })

              runInAction(() => {
                data.forEach((data: any) => {
                  this._items = this._items.map((item) => {
                    if (data.id === item.id) {
                      return data
                    } else {
                      return item
                    }
                  })
                })
              })

              runInAction(() => {
                if (action === 'duplicate') {
                  this._items = [...data, ...this._items]
                }
              })

              this.toast.success({
                title: actionSuccessMap[action] || 'Действие выполнено успешно',
              })
            },
            (error: any) =>
              this.toast.error({
                title: actionErrorMap[action] || 'Не удалось выполнить действие',
                description: error.message,
              })
          )
        },
      }
    })
    this._selected = new SelectedItemsStore({
      list: computed(() => {
        return {
          items: this._items.map<any>((data) => {
            const entity = new Entity({
              api: mobxApi,
              type: 'scenario_tasks',
            })
            entity.setData(data)
            return entity
          }),
          pages: 1,
          count: this._items.length,
        }
      }),
      actions,
      noItemsMessage: () => 'Не выбрано задач, доступных для этого действия.',
      someItemsMessage: ({ actionCount, selectedCount }) =>
        'Действие можно выполнить только с ' +
        numForm(actionCount, taskWordFormsWith) +
        ` из ${selectedCount}.`,
    })
  }

  toggleSelect = (item: ScenarioProjectTaskDocument) => {
    const entity = this._selected.items.find(({ id }) => id === item.id)
    if (entity) {
      this._selected.toggleSelectItem(entity)
    }
  }

  isSelected = (item: ScenarioProjectTaskDocument) => {
    const entity = this._selected.items.find(({ id }) => id === item.id)
    if (entity) {
      return this._selected.selectedItems.has(entity)
    }
    return false
  }

  toggleSelectAll = () => {
    if (this._selected.isAllSelected) {
      this._selected.unselectAll()
    } else {
      this._selected.selectAll()
    }
  }

  get isAllSelected() {
    return this._selected.isAllSelected
  }

  get isSomeSelected() {
    return !!this._selected.selectedCount
  }

  removeById = async (id: number) => {
    try {
      await fetchApi({
        url: `scenario_tasks/multi_action`,
        method: 'POST',
        data: {
          action_type: 'delete_draft',
          ids: [id],
        },
      })
      this.setItems(this._items.filter((item) => item.id !== id))
      this.toast.success({ title: 'Черновик удален' })
    } catch (e: any) {
      this.toast.error({
        title: 'Не удалось удалить черновик',
        description: e.message,
      })
    }
  }

  duplicateById = async (id: number) => {
    try {
      const items = (await fetchApi({
        url: `scenario_tasks/multi_action`,
        method: 'POST',
        data: {
          action_type: 'duplicate',
          ids: [id],
        },
      })) as any[]
      this.setItems([...items, ...this._items])
      this.toast.success({ title: 'Создана копия задания' })
    } catch (e: any) {
      this.toast.error({
        title: 'Не удалось создать копию',
        description: e.message,
      })
    }
  }
}

const TaskListContext = createContext<TaskListStore>(null as never)

interface ITaskListStoreProviderProps {
  children: React.ReactNode
  pageProps: PageProps
}

export const TaskListStoreProvider = ({
  children,
  pageProps,
}: ITaskListStoreProviderProps) => {
  const mobxApi = useMobxApi()

  const toast = useStateToast()
  const store = useLocalObservable(() => new TaskListStore(mobxApi, toast, pageProps))

  return <TaskListContext.Provider value={store}>{children}</TaskListContext.Provider>
}

export const useTaskListStore = () => useContext(TaskListContext)

export default useTaskListStore
