import React, { useEffect, useState, useContext, createContext, forwardRef } from 'react'
//@ts-ignore
import { useStyles } from 'floral'
import cx from 'clsx'

import { Amount } from 'components'

import c from './styles.module.css'
import { debounce } from 'lodash'
import { ResizeObserver } from '@juggle/resize-observer'

const ListContext = createContext<
  { [key: string]: ListCellProps | undefined } | undefined
>({})

const ListFullWidthContext = createContext(0)

interface ListProps extends Omit<React.HTMLProps<HTMLDivElement>, 'cols'> {
  lines?: boolean
  cols?: { [key: string]: ListCellProps | undefined }
  gap?: string | number
}

const List = forwardRef<HTMLDivElement, ListProps>((props: ListProps, ref) => {
  const [listRef, setListRef] = useState<HTMLDivElement | null>(null)
  const [fullWidth, setFullWidth] = useState(0)

  let { cols, lines, gap, ...restProps } = props
  let styles = useStyles(null, [props])

  const handleRef = (element: HTMLDivElement | null) => {
    if (typeof ref === 'function') {
      ref(element)
    } else if (ref) {
      ref.current = element
    }
    setListRef(element)
  }

  const getContainer = (element: HTMLElement): HTMLElement | null => {
    if (element.clientWidth) {
      return element
    } else if (element.parentElement) {
      return getContainer(element.parentElement)
    } else {
      return null
    }
  }

  useEffect(() => {
    const target = listRef && getContainer(listRef)
    if (target) {
      const handleSize = debounce(() => {
        const width = target.clientWidth
        setFullWidth(width)
      })
      const observer = new ResizeObserver(handleSize)
      handleSize()
      observer.observe(target)
      return () => {
        observer.unobserve(target)
        handleSize.cancel()
      }
    } else {
      return () => null
    }
  }, [listRef])

  return (
    <ListFullWidthContext.Provider value={fullWidth}>
      <ListContext.Provider value={cols}>
        <div
          {...restProps}
          className={cx(c.root, lines && c.lines, props.className)}
          style={{ '--list-gap': gap, ...styles.root }}
          ref={handleRef}
        >
          {!!fullWidth && restProps.children}
        </div>
      </ListContext.Provider>
    </ListFullWidthContext.Provider>
  )
})

List.defaultProps = { lines: true, gap: 0 }

interface ListHeaderProps extends React.HTMLProps<HTMLDivElement> {
  padding?: boolean
}

const ListHeader = ({ padding = true, ...props }: ListHeaderProps) => {
  const styles = useStyles(null, [props])
  return (
    <div
      {...props}
      className={cx(c.header, padding && c.padding, props.className)}
      style={styles.root}
    />
  )
}

export interface ListItemProps
  extends Omit<React.HTMLProps<HTMLDivElement>, 'as' | 'wrap'> {
  wrap?: boolean
  hover?: boolean
  padding?: boolean
  pointer?: boolean
  as?: React.ComponentType<any>
  onClick?: (e: React.MouseEvent) => void
  [key: string]: any
}

const listItemStyles = (props: ListItemProps) => ({
  root: props.wrap ? { flexWrap: 'wrap' } : {},
})

const ListItem = (props: ListItemProps) => {
  let { as, wrap: _wrap, hover, padding, onClick, pointer, ...restProps } = props
  let styles = useStyles(listItemStyles, [props])

  const handleClick = (e: React.MouseEvent) => {
    onClick && onClick(e)
  }
  return React.createElement(as!, {
    ...restProps,
    onClick: handleClick,
    className: cx(
      c.item,
      hover && c.hover,
      padding && c.padding,
      props.className,
      pointer && c.pointer
    ),
    style: styles.root,
  })
}

ListItem.defaultProps = {
  hover: true,
  padding: true,
  as: 'div',
}

export interface ListCellProps extends React.HTMLProps<HTMLDivElement> {
  grow?: number
  width?: any
  row?: boolean
  ellipsis?: boolean
}

const listCellStyles = (props: ListCellProps) => {
  let root: React.CSSProperties = {}
  if (props.row) {
    root.flexBasis = '100%'
  } else if (props.width) {
    root.flexBasis = props.width
    root.width = props.width
    root.flexGrow = 0
  } else {
    // flexible cells in different rows should take same size
    root.flexBasis = 0
    root.flexGrow = props.grow
  }
  return { root }
}

const ListCell = (props: ListCellProps) => {
  let { grow: _grow, width, row, ...restProps } = props
  let styles = useStyles(listCellStyles, [props])
  let fullWidth = useContext(ListFullWidthContext)
  return (
    <div
      {...restProps}
      className={cx(row ? c.row : c.cell, props.className, {
        [c['cell_text-ellipsis']]: props.ellipsis,
      })}
      style={{
        ...styles.root,
        ...(typeof width === 'function' && {
          minWidth: width(fullWidth),
          maxWidth: width(fullWidth),
        }),
      }}
    />
  )
}

ListCell.defaultProps = { grow: 1 }

interface ListCellWithContextProps extends ListCellProps {
  col?: string
}

const ListCellWithContext = (props: ListCellWithContextProps) => {
  let { col, ...restProps } = props
  let cols = useContext(ListContext)
  let colProps = cols && col ? cols[col] : {}
  return (
    <ListCell
      {...colProps}
      {...restProps}
      data-col={col}
      style={{ ...colProps?.style, ...restProps.style }}
    />
  )
}

const AmountListCell = (props: ListCellProps) => (
  <ListCell {...props} className={cx(c.amountCell, props.className)}>
    <Amount value={props.value as any} />
  </ListCell>
)

AmountListCell.defaultProps = ListCell.defaultProps

let labeledListCellStyles = {
  root: { alignItems: 'start' },
  label: { width: 75, color: 'var(--color-secondary)', flexGrow: 0, flexShrink: 0 },
  value: { flexGrow: 1 },
}

interface LabeledListCellProps extends Omit<ListCellProps, 'label'> {
  label: React.ReactNode
}

const LabeledListCell = (props: LabeledListCellProps) => {
  let s = useStyles(labeledListCellStyles, [props])
  let { label, children } = props
  return (
    <ListCell style={s.root} row>
      <div style={s.label}>{label}</div>
      <div style={s.value}>{children}</div>
    </ListCell>
  )
}

export { ListItemSelectionCol } from './ListItemSelectionCol'

export {
  List,
  ListHeader,
  ListItem,
  ListCellWithContext as ListCell,
  AmountListCell,
  LabeledListCell,
}
