import * as React from 'react'
import { Col, Flex, Layout, LayoutHeading, Placeholder } from 'components/ui'
import { observer } from 'mobx-react-lite'
import { useTitle } from 'react-use'
import { Chips } from 'components'
import { PageProps } from 'app.types'
import { useMobxApi } from 'mobx/MobxApiContext'
import { useLocation } from 'react-router-dom'
import { getHashState } from 'utils/useHashState'
import { PaginationStore, PaginationView } from 'components/PaginationView'
import { usePusherSubscription } from 'App/pusher'
import { TaskList } from './TaskList'

type sectionKey = 'all' | 'new' | 'active' | 'completed'

type ScenarioTaskEntity = any

const sections: {
  [K in sectionKey]: {
    id: K
    title: string
    emptyText: string
    statuses?: string[]
  }
} = {
  all: { id: 'all', title: 'Все', emptyText: 'Нет заданий' },
  new: {
    id: 'new',
    title: 'Новые',
    emptyText: 'Нет новых заданий',
    statuses: ['created', 'assigned'],
  },
  active: {
    id: 'active',
    title: 'Открытые',
    emptyText: 'Нет активных заданий',
    statuses: ['in_progress', 'done', 'started_by_contractor'],
  },
  completed: {
    id: 'completed',
    title: 'Завершенные',
    emptyText: 'Нет завершенных заданий',
    statuses: ['paid'],
  },
}

export const TasksPage = observer(({ history, match }: PageProps) => {
  useTitle('Restaff - Задания')

  let page = Number(match.params.page) || 1
  let api = useMobxApi()

  let location = useLocation()
  let section = getHashState<sectionKey>(location, 'all')
  let [paginationStore] = React.useState(
    () =>
      new PaginationStore<ScenarioTaskEntity>(
        ({ page, abortSignal }) =>
          api.fetch({
            type: 'scenario_tasks/page',
            url: `scenario_tasks/page/${page}`,
            payload: { status: getHashState<string>(history.location, 'all') },
            options: { signal: abortSignal },
          }),
        page
      )
  )
  React.useEffect(() => paginationStore.setPage(page), [page, paginationStore, section])

  usePusherSubscription('update-task', (event: any) => {
    let task = api.entities.tasks.get(event.id)
    if (task) {
      task.setData(event)
    }
  })

  let onChangePage = (page: number) => history.push(`/tasks/page/${page}#${section}`)
  let onSectionChange = (section: string) => history.push(`/tasks/page/1#${section}`)

  return (
    <Layout>
      <Col gap="2rem">
        <Flex justify="space-between" align="center" gap="1rem">
          <LayoutHeading>Задания</LayoutHeading>
        </Flex>

        <Chips
          items={Object.values(sections)}
          value={section}
          onChange={onSectionChange}
        />

        <PaginationView
          store={paginationStore}
          render={(tasks) =>
            tasks.isEmpty ? (
              <Placeholder>
                {section ? sections[section].emptyText : `Нет заданий`}
              </Placeholder>
            ) : (
              <TaskList tasks={tasks} />
            )
          }
          onChangePage={onChangePage}
        />
      </Col>
    </Layout>
  )
})
