import { makeAutoObservable, action } from 'mobx'
import localStorageObservable from 'utils/localStorageObservable'
import { initialActionState, Entity, ActionState } from 'mobx/mobx'
import { UserStore } from 'stores/context'
import { UserRegistrationEntity } from 'entities'
import { useStateToast } from 'components/ui'
import { validatePhone, validateInn } from 'utils/validations'
import registrationApi from '../api'
import MobxApi from 'mobx/MobxApi'

// @ts-ignore
import { FormStore } from 'shadowform'
import { handleRegistrationResponse } from '../forms/utils'

const requiredError = 'Необходимо заполнить это поле'

type EmploymentKind =
  | 'initial'
  | 'individual_entrepreneur'
  | 'self_employed'
  | 'gph'
  | 'register_self_employed'
  | 'register_non_resident'

type JoinAsSelfEmployedSteps =
  | 'initial'
  | 'enter_phone_or_inn'
  | 'not_self_employed'
  | 'request_sent'

type RegisterSelfEmployedSteps = 'initial' | 'enter_inn' | 'already_self_employed'

interface ConnectToPlatformState {
  kind: EmploymentKind
  step: JoinAsSelfEmployedSteps | RegisterSelfEmployedSteps
  values?: { inn?: string; phone?: string }
}

export class ConnectToPlatformStore {
  constructor(
    currentUser: UserStore,
    toast: ReturnType<typeof useStateToast>,
    api: MobxApi
  ) {
    this.user = currentUser
    this.toast = toast
    this.registration = new Entity({ api, type: 'registration' })
    this.registrationActionState = this.registration.fetch() as ActionState<
      Entity<UserRegistrationEntity>
    >
    document.addEventListener('visibilitychange', this.handleVisibilityChange.bind(this))
    let { res, dispose } = localStorageObservable<ConnectToPlatformState>(
      'registration_connect_to_platform_state',
      () => ({
        kind: 'initial',
        step: 'initial',
        values: { phone: currentUser.user!.data.mobile_phone },
      })
    )
    this.state = res
    this.dispose = dispose
    this.form = new FormStore({
      fields: {
        kind: {},
        phone: {
          isRequired: true,
          requiredError,
          validations: {
            phone: { validate: validatePhone, error: 'Введите телефон' },
          },
        },
        inn: {
          isRequired: true,
          requiredError,
          validations: {
            inn: { validate: validateInn, error: 'Введите ИНН до конца' },
          },
        },
        innNonResident: {},
      },
      initialValues: {
        kind: 'phone',
        phone: '',
        inn: '',
        innNonResident: '',
        ...this.state.values,
      },
    })

    makeAutoObservable(this)
  }

  registration: Entity<UserRegistrationEntity>
  toast: ReturnType<typeof useStateToast>
  state: ConnectToPlatformState
  dispose: () => void
  form: FormStore
  registrationActionState: ActionState<Entity<UserRegistrationEntity>>
  actionState = initialActionState
  user

  handleVisibilityChange() {
    const element = window.document
    const isHidden = element.visibilityState === 'hidden'

    if (!isHidden) {
      this.registration
        .fetch()
        .then((data: any) => handleRegistrationResponse(data, this.user))
    }
  }

  deconstruct() {
    window.removeEventListener('visibilitychange', this.handleVisibilityChange.bind(this))
  }

  cancelJoin() {
    this.actionState = registrationApi.cancelJoin()
    this.actionState.then(
      (data: any) => this.registration.setData(data),
      (error: any) => this.toast.error({ title: 'Ошибка', description: error.message })
    )
  }

  joinAsIp() {
    this.actionState = registrationApi.joinAsIp({ inn: this.form.values.inn })
    this.actionState.then(
      (data: any) => this.registration.setData(data),
      (error: any) => this.toast.error({ title: 'Ошибка', description: error.message })
    )
  }

  joinAsNonResident() {
    return new Promise((resolve, reject) => {
      this.actionState = registrationApi.joinAsNonResident()
      this.actionState.then(
        (data: any) => {
          this.registration.setData(data)
          resolve(data)
        },
        (error: any) => {
          this.toast.error({ title: 'Ошибка', description: error.message })
          reject()
        }
      )
    })
  }

  joinAsGPH() {
    return new Promise((resolve, reject) => {
      this.actionState = registrationApi.joinAsGPH()
      this.actionState.then(
        (data: any) => {
          this.registration.setData(data)
          resolve(data)
        },
        (error: any) => {
          this.toast.error({ title: 'Ошибка', description: error.message })
          reject()
        }
      )
    })
  }

  joinAsSelfEmployed(values: Object) {
    this.actionState = registrationApi.joinAsSelfEmployed(values)
    this.actionState.then(
      action((data: any) => {
        if (data.result === 'not_self_employed') {
          this.state.step = 'not_self_employed'
        }
        if (data.result === 'fail') {
          this.state.step = 'enter_phone_or_inn'
        } else if (data.result === 'request_sent') {
          if (this.state.step === 'request_sent') {
            this.toast.error({ title: 'Подключение не подтверждено' })
          }
          this.state.step = 'request_sent'
          this.state.values = values
        } else if (data.result === 'success') {
          this.registration.setData(data.registration)
        }
      }),
      (error: any) => this.toast.error({ title: 'Ошибка', description: error.message })
    )
  }

  sendJoinAsSelfEmployedRequest() {
    let { kind, phone, inn } = this.form.values
    this.joinAsSelfEmployed(kind === 'phone' ? { phone } : { inn })
  }

  checkJoinAsSelfEmployedRequest() {
    this.joinAsSelfEmployed(this.state.values!)
  }

  registerSelfEmployed() {
    let values = { inn: this.form.values.inn }
    this.actionState = registrationApi.registerSelfEmployed(values)
    this.actionState.then(
      action((data: any) => {
        if (data.result === 'already_self_employed') {
          this.state.step = 'already_self_employed'
        } else if (data.result === 'success') {
          this.registration.setData(data.registration)
        }
      }),
      (error: any) => this.toast.error({ title: 'Ошибка', description: error.message })
    )
  }

  selectKind(kind: EmploymentKind) {
    this.actionState = initialActionState
    this.state.kind = kind
    this.state.step = 'initial'
    if (kind === 'initial') {
      this.registration.setData({ status: '' })
    } else {
      this.registration.setData({ form_options: '' })
    }
  }

  goToJoinAsSelfEmployed() {
    this.actionState = initialActionState
    this.state.kind = 'self_employed'
    this.state.step = 'enter_phone_or_inn'
    this.form.fields.kind.setValue('inn')
  }

  goToRegisterSelfEmployed() {
    this.actionState = initialActionState
    this.state.kind = 'register_self_employed'
    this.state.step = 'enter_inn'
  }
}
