import React from 'react'
import cl from 'clsx'

import css from './styles.module.css'

export type CardProps = {
  className?: string
  children: React.ReactNode
}

const Card: React.FunctionComponent<CardProps> = ({ className, children }) => (
  <div className={cl(css.card, className && className)}>{children}</div>
)

export default Card
