import React from 'react'
import { observer } from 'mobx-react-lite'

import { DocumentEntity, TariffDocument } from 'entities'
import { Entity } from 'mobx/mobx'

import { documentStatusMap } from 'components/documents/statuses'
import {
  List,
  ListItem,
  ListCell,
  StatusBadge,
  Checkbox,
  ListHeader,
} from 'components/ui'

import { Link } from 'ui'

import { SelectableStore, SelectAllCheckbox } from 'components/FilteredList'
import { useMediaContext } from 'App/MediaContext'
import Amount from 'components/Amount'

interface DocumentForSignListProps {
  documents: Array<Entity<DocumentEntity> | Entity<TariffDocument>>
  style?: React.CSSProperties
  selected?: SelectableStore<Entity<DocumentEntity>>
}

const isTariffDocument = (
  document: Entity<TariffDocument> | Entity<DocumentEntity>
): document is Entity<TariffDocument> =>
  (document as Entity<DocumentEntity>).data.contractor === undefined

const DocumentForSignList = observer(
  ({ documents, style, selected }: DocumentForSignListProps) => {
    let { isMobile } = useMediaContext()

    const header = selected && (
      <ListHeader>
        <ListCell grow={0}>
          <SelectAllCheckbox store={selected} />
        </ListCell>
        {isMobile ? (
          <ListCell grow={3}>
            <div onClick={() => selected.toggleSelectAll()}>
              {selected.selectedCount ? 'Снять выделение' : 'Выделить все'}
            </div>
          </ListCell>
        ) : (
          <ListCell />
        )}
        <ListCell />
        <ListCell />
      </ListHeader>
    )

    return isMobile ? (
      <List style={{ ...style, maxHeight: 400, overflowX: 'auto' }}>
        {header}
        {documents.map((doc) => {
          const { file_url, title, status } = doc.data
          const contractor = isTariffDocument(doc) ? undefined : doc.data.contractor

          return (
            <ListItem key={doc.id}>
              {!isTariffDocument(doc) && selected && (
                <ListCell grow={0}>
                  <Checkbox
                    value={selected.isSelected(doc)!}
                    onChange={() => selected.toggleSelect(doc)}
                  />
                </ListCell>
              )}
              <ListCell grow={2}>
                {contractor && <div>{contractor.data.name}</div>}
                <Link href={file_url} target="_blank">
                  {title} {isTariffDocument(doc) && <> за {doc.data.date}</>}
                </Link>
                {isTariffDocument(doc) && (
                  <div>
                    <Amount value={doc.data.amount} />
                  </div>
                )}
              </ListCell>

              <ListCell style={{ alignSelf: 'flex-start' }}>
                <StatusBadge status={status} map={documentStatusMap} />
              </ListCell>
            </ListItem>
          )
        })}
      </List>
    ) : (
      <List style={{ ...style, maxHeight: 400, overflowX: 'auto' }}>
        {header}
        {documents.map((doc) => {
          const { file_url, title, status } = doc.data
          const contractor = isTariffDocument(doc) ? undefined : doc.data.contractor

          return (
            <ListItem key={doc.id}>
              {!isTariffDocument(doc) && selected && (
                <ListCell grow={0}>
                  <Checkbox
                    value={selected.isSelected(doc)!}
                    onChange={() => selected.toggleSelect(doc)}
                  />
                </ListCell>
              )}
              {contractor && <ListCell grow={2}>{contractor.data.name}</ListCell>}
              <ListCell grow={3}>
                <Link href={file_url} target="_blank">
                  {title}
                </Link>
              </ListCell>
              {isTariffDocument(doc) && (
                <>
                  <ListCell grow={1}>{doc.data.date}</ListCell>
                  <ListCell grow={1}>
                    <Amount value={doc.data.amount} />
                  </ListCell>
                </>
              )}
              <ListCell width={200}>
                <StatusBadge status={status} map={documentStatusMap} />
              </ListCell>
            </ListItem>
          )
        })}
      </List>
    )
  }
)

export default DocumentForSignList
