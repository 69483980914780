import React, { useEffect, useState } from 'react'

import { Col } from 'components/ui'
import { H3 } from 'ui'
import { SpectrumDataReportStatus } from 'entities/spectrum'
import { FileThumb } from 'components'

import { observer } from 'mobx-react-lite'
import { fetchApi } from 'api'
import s from './style.module.css'
import { SpectrumDataReportModal } from 'components/SpectrumDataReportModal'
import { getSpectrumDataPrice } from 'utils/company'
import { useUserStore } from 'stores/context'
interface ContractorRisksProps {
  id: number
  name: string
  blacklisted: boolean
}

const formatDate = (utc: string) => {
  const date = new Date(utc)

  return `${date.toLocaleDateString()}_${date.toLocaleTimeString()}`
}

const ContractorTrustRisks = observer((props: ContractorRisksProps) => {
  const { id, name } = props

  const currentUser = useUserStore()
  const spectrumDataReportPrice = getSpectrumDataPrice(currentUser)
  const [isFetched, setFetched] = useState(false)
  const [spectrumDataReport, setSpectrumDataReport] = useState<any>({
    status: null,
  })
  const [isVisibleModal, setVisibleModal] = useState(false)

  useEffect(() => {
    fetchApi({
      method: 'GET',
      url: `spectrum_data_reports?contractor_id=${id}`,
    })
      .then((response) => {
        if (response && response[0]?.status) {
          setSpectrumDataReport(response[0])
        }
        setFetched(true)
      })
      .catch((error) => {
        setFetched(true)
        console.log(error)
      })
  }, [])

  const setSpectrumDataReportStatus = (status: any) => {
    setSpectrumDataReport((prevState: any) => ({
      ...prevState,
      status,
    }))
  }

  return (
    <>
      <Col gap="var(--gap-m)">
        <H3>Отчет по рискам</H3>
        <Col style={{ marginTop: '1rem' }} align="start">
          {
            <>
              <div className={s.fieldAdditionalInfo}>
                <p>По исполнителю будет сформирован отчет о благонадежности.</p>
                <p>Стоимость одного отчета — {spectrumDataReportPrice} руб.</p>
                <p>
                  <a
                    href="https://support.konsol.pro/verification-of-trustworthiness"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Подробнее о проверке исполнителей
                  </a>
                </p>
              </div>
              {isFetched && spectrumDataReport.status === null && (
                <button className={s.riskButton} onClick={() => setVisibleModal(true)}>
                  Проверить исполнителя
                </button>
              )}
              {isFetched &&
                (spectrumDataReport.status === SpectrumDataReportStatus.in_progress ||
                  spectrumDataReport.status === SpectrumDataReportStatus.created) && (
                  <FileThumb
                    data={{ title: `Отчет_${name}`, url: '' }}
                    status="В процессе подготовки..."
                    isRiskReport={true}
                    isDisabled
                    classes={{
                      root: s.fileThumbRoot,
                      img: s.fileThumbImg,
                      title: s.fileThumbTitle,
                      statusBadge: s.statusBadge,
                    }}
                  />
                )}
              {isFetched &&
                spectrumDataReport.status === SpectrumDataReportStatus.completed && (
                  <FileThumb
                    data={{
                      title: `Отчет_${name}_${formatDate(spectrumDataReport.added_at)}`,
                      url: spectrumDataReport.report_url,
                    }}
                    isRiskReport={true}
                    classes={{
                      root: s.fileThumbRoot,
                      img: s.fileThumbImg,
                      title: s.fileThumbTitle,
                      statusBadge: s.statusBadge,
                    }}
                  />
                )}
            </>
          }
        </Col>
      </Col>
      <SpectrumDataReportModal
        isVisibleModal={isVisibleModal}
        setVisibleModal={setVisibleModal}
        setSpectrumDataReportStatus={setSpectrumDataReportStatus}
        contractorId={id}
      />
    </>
  )
})

export default ContractorTrustRisks
