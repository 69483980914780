import React from 'react'

import { useUserStore } from 'stores/context'
import { observer } from 'mobx-react-lite'

import Banner from 'components/Banner'

import AdminLoginBanner from './AdminLoginBanner'
import AttorneyExpirationBanner from './AttorneyExpirationBanner'
/**
 * @TODO remove
 * @description ожидает backend
 */
import AccountBlockBannerLegacy from './AccountBlockBannerLegacy'

const GlobalBanners = () => {
  const currentUser = useUserStore()
  const banners = currentUser?.company?.data.global_banners || []
  return (
    <>
      <AdminLoginBanner />
      <AttorneyExpirationBanner />
      <AccountBlockBannerLegacy />
      {banners.map((banner, idx) => (
        <Banner key={idx} {...banner} />
      ))}
    </>
  )
}

export default observer(GlobalBanners)
