import React, { useMemo, useState, useEffect, useCallback, useRef } from 'react'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { makeAutoObservable, reaction, computed } from 'mobx'
//@ts-ignore
import { FormStore } from 'shadowform'
import { useHistory } from 'react-router-dom'
import { pick, omit } from 'lodash'

import DocumentsMultiActionsStore from 'stores/DocumentsMultiActionsStore'
import { useUserStore, useSignDialogStore } from 'stores/context'
import SignDialogStore from 'stores/SignDialogStore'
import DialogStore, { useDialogStore } from 'stores/DialogStore'
import { PageProps } from 'app.types'
import {
  MobxApi,
  useMobxApi,
  Collection,
  Entity,
  initialActionState,
  Data,
} from 'mobx/mobx'
import {
  InviteEntity,
  DocumentEntity,
  OnboardingScenarioEntity,
  CustomField,
} from 'entities'
import { SpectrumDataReportStatus } from 'entities/spectrum'
import { useMediaContext } from 'App/MediaContext'
import { usePusherSubscription } from 'App/pusher'
import { badges } from 'App/badges'

import useHashState from 'utils/useHashState'
import { printIsoDateTime, serializeDate } from 'utils/datetime'
import numForm from 'utils/numForm'
import {
  internationalPhoneMask,
  normalizePhone,
  validatePhoneInternational,
  validateDigitsAndLatin,
  validateNotDigits,
} from 'utils/validations'
import {
  splitQueryToTerms,
  stripPhoneSymbols,
  searchStringsWithTerms,
} from 'utils/searchStrings'
import { formatPhone } from 'utils/formatting'
import { fetchApi, getBaseUrl, getDefaultHeaders } from 'api'
import { Tooltip, Box } from '@chakra-ui/react'
import {
  ActionStateView,
  Input,
  Layout,
  LayoutHeading,
  List,
  ListItem,
  ListCell,
  Modal,
  FormRow,
  Field,
  Flex,
  useStateToast,
  SelectInput,
  Col,
  Placeholder,
  Button,
  H1,
  H2,
} from 'components/ui'
import { Badge, BadgeColor } from 'components/Badge'
import Chips from 'components/Chips'
import {
  SectionedStore,
  FilteredStore,
  SortableStore,
  SectionsChips,
  SortableListHeader,
  SelectableStore,
} from 'components/FilteredList'
import { AbilityButton, TooltipButton } from 'components/abilityButtons'
import { Toggle } from 'components/ui/animation'
import DocumentsForSignList from 'components/documents/DocumentsForSignList'
import {
  makeTemplateFieldsForm,
  TemplateFieldsForm,
} from 'components/documents/DocumentCreateForm'

import FilterDropdown from 'components/FilterDropdown'

import { ReactComponent as ArrowIcon } from '@material-design-icons/svg/round/expand_more.svg'

import { ReactComponent as EditIcon } from '@material-design-icons/svg/round/edit.svg'
import { ReactComponent as ClearIcon } from '@material-design-icons/svg/round/clear.svg'
import { ReactComponent as RestoreIcon } from '@material-design-icons/svg/round/restore.svg'
import { ReactComponent as ExportIcon } from 'assets/export.svg'
import { ReactComponent as SearchIcon } from '@material-design-icons/svg/round/search.svg'
import { ReactComponent as LightningIcon } from 'assets/lightning.svg'

import exportToFile from 'utils/exportToFile'
import { ExportInvitesModal } from './ExportInvitesModal'
import useFileDialog from 'utils/useFileDialog'
import downloadFile from 'utils/downloadFile'

import c from './styles.module.css'
import { makeSectionsCounters } from 'components/FilteredList/SectionedStore'
import cl from 'clsx'
import { SpectrumDataReportModal } from 'components/SpectrumDataReportModal'
import { Kebab } from 'ui'
import dayjs from 'utils/dayjs'

interface Props {
  children: React.ReactNode
}
function OverflownText({ children, ...props }: Props) {
  const ref = useRef(null)
  const [isOverflown, setIsOverflown] = useState(false)
  useEffect(() => {
    const element = ref.current!
    setIsOverflown(element['scrollWidth'] > element['clientWidth'])
  }, [])

  return (
    <Tooltip label={children} isDisabled={!isOverflown}>
      <Box position="relative" isTruncated ref={ref} {...props}>
        {children}
      </Box>
    </Tooltip>
  )
}

const buildCustomFields = (
  onboardingScenario: OnboardingScenarioEntity,
  customFields: CustomField[]
) => {
  const { contractor_custom_fields = [] } = onboardingScenario
  const result = contractor_custom_fields
    .filter(({ fill_target }) => fill_target === 'company')
    .map(({ required, uuid, default_value }) => {
      const customField: any = customFields.find(({ id }) => id === uuid) || {}
      const { kind, name, options } = customField
      return {
        key: uuid,
        name: name!,
        type: kind!,
        ...(options && {
          options: (options.items?.map((item: any) => item.data) || options).map(
            (option: any) => ({
              label: option.name,
              value: option.id,
            })
          ),
        }),
        is_required: required,
        default_value,
      }
    })
  return result.map((field: any) => {
    if (field.type === 'dropdown') {
      field.type = 'select'
    }
    return field
  })
}

const invitesStatuses: { [key: string]: { text: string; color: BadgeColor } } = {
  invited: {
    text: 'Приглашён',
    color: 'blue',
  },
  waiting_for_documents: {
    text: 'Заполняет анкету',
    color: 'purple',
  },
  checking_documents: {
    text: 'Проверка документов',
    color: 'purple',
  },
  incorrect_documents: {
    text: 'Ошибка проверки',
    color: 'red',
  },
  declined: {
    text: 'Отказался',
    color: 'red',
  },
  completed: {
    text: 'Договор подписан',
    color: 'green',
  },
  waiting_for_contract_sign: {
    text: 'Подпишите договор',
    color: 'green',
  },
}

const getInviteStatus = (item: Entity<InviteEntity>) => {
  let { status, contract } = item.data

  if (status === 'waiting_for_contract_sign') {
    return {
      text:
        contract?.data.status === 'signed_by_contractor'
          ? 'Подпишите договор'
          : 'Подписание договора',
      color: 'green' as BadgeColor,
    }
  }

  return invitesStatuses[status]
}

const statusesOrder = {
  invited: 1,
  waiting_for_documents: 2,
  checking_documents: 3,
  incorrect_documents: 4,
  waiting_for_contract_sign: 5,
  declined: 6,
}

interface InvitesStoreProps {
  api: MobxApi
  invites: Collection<InviteEntity>
  toast: any
  signDialog: SignDialogStore
  onInviteSubmit: () => void
}

type DisplayType = 'normal' | 'loading' | 'error'
type InviteType = 'single_invite' | 'multiple_invite'

interface FilterParsedQuery {
  terms?: string[]
  onboardingScenarioId: string
}

interface FilterRawQuery {
  search: string
  onboardingScenarioId: string
}

class InvitesStore {
  constructor({ api, invites, toast, signDialog, onInviteSubmit }: InvitesStoreProps) {
    this.api = api
    this.invites = invites
    this.onInviteSubmit = onInviteSubmit
    const sections = {
      all: (item: any) => !item.data.cancelled,
      form: (item: any) =>
        !item.data.cancelled && item.data.status === 'waiting_for_documents',
      check: (item: any) =>
        !item.data.cancelled && item.data.status === 'checking_documents',
      error: (item: any) =>
        !item.data.cancelled && item.data.status === 'incorrect_documents',
      contract: (item: any) =>
        !item.data.cancelled &&
        item.data.status === 'waiting_for_contract_sign' &&
        item.data.contract?.data.status !== 'signed_by_contractor',
      signed_by_contractor: (item: any) =>
        !item.data.cancelled &&
        item.data.status === 'waiting_for_contract_sign' &&
        item.data.contract?.data.status === 'signed_by_contractor',
      declined: (item: any) => !item.data.cancelled && item.data.status === 'declined',
      cancelled: (item: any) => item.data.cancelled,
      blacklisted: (item: any) => item.data.blacklisted,
    }
    const filterFunc = (
      item: Entity<InviteEntity>,
      query: FilterParsedQuery
    ): boolean => {
      const terms = query.terms
      const onboardingScenarioId = query.onboardingScenarioId
      let isOk = true
      if (terms) {
        isOk =
          isOk &&
          searchStringsWithTerms([...item.data.name.split(' '), item.data.phone], terms)
      }
      if (onboardingScenarioId) {
        isOk = isOk && item.data.onboarding_scenario?.id === Number(onboardingScenarioId)
      }
      return isOk
    }
    const parseQuery = (query: FilterRawQuery): FilterParsedQuery => {
      const search = query.search
      const onboardingScenarioId = query.onboardingScenarioId
      return {
        terms: splitQueryToTerms(stripPhoneSymbols(search)),
        onboardingScenarioId,
      }
    }
    this.sections = new SectionedStore({
      items: computed(() => invites.items),
      sections,
      section: 'all',
    })
    this.filtered = new FilteredStore({
      items: computed(() => this.sections.items),
      filterFunc: filterFunc,
      parseQuery: parseQuery,
    })
    this.totalFiltered = new FilteredStore({
      items: computed(() => invites.items),
      filterFunc: filterFunc,
      parseQuery: parseQuery,
    })
    this.sections.counters = makeSectionsCounters(
      computed(() => this.totalFiltered.items),
      sections
    )

    this.filtered.query = {
      search: '',
      onboardingScenarioId: '',
    }

    this.totalFiltered.query = {
      search: '',
      onboardingScenarioId: '',
    }

    this.sorted = new SortableStore({
      items: computed(() => this.filtered.items),
      sortFunc: (item, sorting) => {
        if (sorting === 'name') return item.data.name
        if (sorting === 'scenario') return item.data.onboarding_scenario?.title
        if (sorting === 'status') return statusesOrder[item.data.status]
        if (sorting === 'invited_time') return new Date(item.data.invited_time).getTime()
        if (sorting === 'updated_time') return new Date(item.data.updated_time).getTime()
        return undefined
      },
      sorting: { col: 'invited_time', direction: 'desc' },
    })

    this.toast = toast

    makeAutoObservable(this)

    this.dispose = reaction(
      () => this.contractsForSign.length,
      (value) => {
        badges.unread_invites = value
      },
      { fireImmediately: true }
    )

    this.documentsMultiActions = new DocumentsMultiActionsStore({
      api,
      signDialog,
      toast,
    })

    this.selected = new SelectableStore({
      items: computed(() => this.contractsForSign || []),
      isDefaultSelectedAll: true,
    })

    reaction(
      () => this.selected.isAllSelected,
      (isAllPageSelected) => {
        if (!isAllPageSelected) this.isAllSelected = false
      }
    )
  }

  setSearchFilter = (search: string) => {
    Object.assign(this.filtered.query as any, { search })
    Object.assign(this.totalFiltered.query as any, { search })
  }

  setOnboardingScenarioFilter = (onboardingScenarioId: string) => {
    Object.assign(this.filtered.query as any, { onboardingScenarioId })
    Object.assign(this.totalFiltered.query as any, { onboardingScenarioId })
  }

  api: MobxApi
  toast: any
  onInviteSubmit: () => void
  invites: Collection<InviteEntity>

  sections: SectionedStore<Entity<InviteEntity>>
  filtered: FilteredStore<Entity<InviteEntity>, FilterRawQuery, FilterParsedQuery>
  totalFiltered: FilteredStore<Entity<InviteEntity>, FilterRawQuery, FilterParsedQuery>
  sorted: SortableStore<Entity<InviteEntity>>
  selected: SelectableStore<Entity<DocumentEntity>>

  documentsMultiActions: DocumentsMultiActionsStore
  dispose: () => void
  exportModal = new DialogStore({ component: ExportInvitesModal })

  removeSignedContracts() {
    let signed = this.invites.items.filter(
      (item) => item.data.contract?.data.status === 'signed_by_all'
    )
    this.invites.remove(...signed)
  }

  get contractsForSign() {
    return this.invites.items
      .map((invite) => invite.data.contract)
      .filter(
        (contract) =>
          contract && ['created', 'signed_by_contractor'].includes(contract.data.status)
      ) as Array<Entity<DocumentEntity>>
  }

  get isAllPageSelected() {
    return this.selected.isAllSelected
  }
  isAllSelected = true

  openSignDialog() {
    const selectedIds = Array.from(this.selected.selectedItems).map(
      (item) => item.data.id
    )
    const selectedContractsForSign = this.contractsForSign.filter((contract) =>
      selectedIds.includes(contract.id)
    )

    this.documentsMultiActions.openSignDialog(selectedContractsForSign)
  }

  actionState = initialActionState

  createInvite(data: InviteFormData) {
    this.actionState = this.invites.create({ payload: data, mode: 'prepend' })
    this.actionState.then(
      () => {
        this.toast.success({
          title: 'Пригласили',
          description: 'Мы отправим приглашение исполнителю',
        })
        this.onInviteSubmit()
      },
      (error) => {
        this.toast.error({
          title: 'Не удалось пригласить исполнителя',
          description: error.message,
        })
      }
    )
    return this.actionState
  }

  resumeInvite(invite: Entity<InviteEntity>, payload: Data) {
    this.actionState = invite.action({ action: 'resume', payload })
    this.actionState.then(
      (data) => {
        invite.setData(data)
        this.toast.success({ title: 'Приглашение возобновлено' })
      },
      (error) =>
        this.toast.error({
          title: 'Не удалось возобновить приглашение',
          description: error.message,
        })
    )
    return this.actionState
  }

  updateInvite(invite: Entity<InviteEntity>, payload: Data) {
    this.actionState = invite.update({ payload })
    this.actionState.then(
      () => {
        this.toast.success({ title: 'Приглашение изменено' })
      },
      (error) =>
        this.toast.error({
          title: 'Не удалось изменить приглашение',
          description: error.message,
        })
    )
    return this.actionState
  }

  cancelInvite(invite: Entity<InviteEntity>) {
    let state = invite.action({ action: 'cancel' })
    state.then(
      (data) => {
        invite.setData(data)
        this.toast.success({ title: 'Приглашение отменено' })
      },
      (error) =>
        this.toast.error({
          title: 'Не удалось отменить приглашение',
          description: error.message,
        })
    )
    return state
  }

  multipleInvite(data: InviteFormData, file: File) {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('company_onboarding_scenario_id', data.company_onboarding_scenario_id)
    return fetchApi({
      method: 'POST',
      data: formData,
      url: 'contractors/invites/create_from_file',
    }).then(() => {
      this.toast.success({ title: 'Реестр исполнителей загружен' })
      this.onInviteSubmit()
    })
  }

  exportToFile(format: string) {
    this.exportModal.open({
      onSubmit: (range) =>
        exportToFile({
          url: 'contractors/invites',
          format,
          toast: this.toast,
          filename: 'konsol_export_invites.xslx',
          data: {
            since: serializeDate(range[0]!),
            upto: serializeDate(range[1]!),
          },
        }),
    })
  }

  render() {
    return <>{this.exportModal.render()}</>
  }
}

interface InviteFormStoreProps {
  scenarios: Entity<OnboardingScenarioEntity>[]
  customFields: Entity<CustomField>[]
  invite?: Entity<InviteEntity>
  mode: InviteFormModalMode
}

class InviteFormStore {
  mode: InviteFormModalMode

  get customFieldsTemplate() {
    const onboardingScenarioId = this.form.fields.company_onboarding_scenario_id.value

    const customFieldList = this.customFields.map((item) => item.data)

    const onboardingScenario =
      this.scenarios
        .map((item) => item.data)
        .find((item) => item.id === onboardingScenarioId) || {}

    const template_fields = buildCustomFields(
      onboardingScenario as OnboardingScenarioEntity,
      customFieldList
    )

    return template_fields
  }

  constructor({ scenarios, customFields, invite, mode }: InviteFormStoreProps) {
    this.scenarios = scenarios
    this.customFields = customFields
    this.mode = mode
    let initialValues = {
      name: '',
      phone: '+7',
      company_onboarding_scenario_id: scenarios[0]?.id,
    }
    if (invite) {
      Object.assign(initialValues, pick(invite.data, 'name', 'phone'))
      if (invite.data.onboarding_scenario) {
        initialValues.company_onboarding_scenario_id = invite.data.onboarding_scenario.id
      }
    }

    this.form = new FormStore({
      initialValues,
      fields: {
        name: {
          isRequired: true,
          validations:
            mode === 'create'
              ? {
                  format: {
                    validate: validateNotDigits,
                    error: 'Может содержать только буквы',
                  },
                }
              : {},
          requiredError: 'Нужно заполнить ФИО',
        },
        phone: {
          isRequired: true,
          requiredError: 'Нужно заполнить номер телефона',
          normalize: normalizePhone,
          validations: {
            format: {
              validate: validatePhoneInternational,
              error: 'Введите номер до конца',
            },
          },
        },
        company_onboarding_scenario_id: {},
      },
    })

    reaction(
      () => this.scenario,
      (scenario) => {
        if (scenario && scenario.data.template_fields) {
          const totalFields = [
            ...scenario.data.template_fields,
            ...this.customFieldsTemplate,
          ]

          totalFields.forEach((field: any) => {
            const { type, default_value } = field
            if (type === 'date' && default_value) {
              if (default_value.match(/(?:\d\d\d\d-\d\d-\d\d)/g)) {
                field.default_value = dayjs(default_value, 'YYYY-MM-DD', true).format(
                  'DD.MM.YYYY'
                )
              }
            }
          })

          const inviteContractData = invite?.data.contract_data || {}
          const inviteCustomData = invite?.data.custom_fields || {}
          const initialValues = totalFields.reduce(
            (acm, { key, default_value }) => ({
              ...acm,
              [key]:
                inviteContractData[key] || inviteCustomData[key] || default_value || null,
            }),
            {}
          )
          this.templateFieldsForm = makeTemplateFieldsForm(totalFields, initialValues)
        } else {
          this.templateFieldsForm = undefined
        }
      },
      { fireImmediately: true }
    )

    makeAutoObservable(this)
  }

  displayMode: DisplayType = 'normal'
  setDisplayMode = (displayMode: DisplayType) => (this.displayMode = displayMode)

  inviteMode: InviteType = 'single_invite'
  setInviteMode = (inviteMode: InviteType) => (this.inviteMode = inviteMode)

  errorItems: string[] = []
  setErrorItems = (errorItems: string[]) => (this.errorItems = errorItems)

  form: FormStore
  templateFieldsForm?: FormStore
  scenarios: Entity<OnboardingScenarioEntity>[]
  customFields: Entity<CustomField>[]

  get scenario() {
    if (this.form.values.company_onboarding_scenario_id) {
      return this.scenarios.find(
        (s) => s.id === this.form.values.company_onboarding_scenario_id
      )!
    }
    return undefined
  }

  get isValid() {
    return (
      this.form.isValid &&
      (this.templateFieldsForm ? this.templateFieldsForm.isValid : true)
    )
  }

  get values() {
    let values = { ...this.form.normalizedValues }
    if (this.templateFieldsForm) values.template_fields = this.templateFieldsForm.values
    return values
  }
}

interface InviteFormData {
  name: string
  phone: string
  email: string
  [key: string]: any
}

type InviteFormModalMode = 'create' | 'resume' | 'update'

interface InviteFormModalProps {
  mode: InviteFormModalMode
  invite?: Entity<InviteEntity>
  store: InvitesStore
  onboardingScenarios: Collection<OnboardingScenarioEntity>
  customFields: Collection<CustomField>
  onClose: () => void
}

const inviteModeChips = [
  {
    id: 'single_invite',
    title: 'Одного',
  },
  {
    id: 'multiple_invite',
    title: 'Нескольких',
  },
] as const

const InviteFormModal = observer(
  ({
    mode,
    invite,
    onClose,
    store: invitesStore,
    onboardingScenarios,
    customFields,
  }: InviteFormModalProps) => {
    const { isMobile } = useMediaContext()

    const scenarios = onboardingScenarios.items.filter(
      (item: Entity<OnboardingScenarioEntity>) => !item.data.archived && item.data.enabled
    )

    const scenariosOptions = scenarios.map((item) => ({
      value: item.data.id,
      label: item.data.title,
    }))

    const store = useLocalObservable(
      () =>
        new InviteFormStore({
          scenarios,
          customFields: customFields.items,
          invite,
          mode,
        })
    )

    const handleMultipleTemplateDownload = async () => {
      const url = `/contractors/invites/create_from_file_template?company_onboarding_scenario_id=${store.values.company_onboarding_scenario_id}`
      const name = 'template.xlsx'
      try {
        let res = await fetch(getBaseUrl() + url, {
          method: 'GET',
          credentials: 'include',
          headers: getDefaultHeaders(),
        })
        if (!res.ok) throw new Error()
        let blob = await res.blob()
        downloadFile(blob, name)
      } catch (e) {
        invitesStore.toast.error({ title: 'Не удалось скачать файл' })
      }
    }

    const inviteFileDialog = useFileDialog(
      (file) => {
        store.setDisplayMode('loading')
        invitesStore
          .multipleInvite(store.values, file)
          .then(() => {
            store.setInviteMode('single_invite')
            store.setDisplayMode('normal')
            onClose()
          })
          .catch((error: any) => {
            store.setDisplayMode('error')
            if (error.data?.code === 'failed_validation') {
              store.setErrorItems(error.data.errors)
            }
          })
      },
      {
        accept: '.csv,.xls,.xlsx',
      }
    )

    const handleSubmit = () => {
      if (store.inviteMode === 'single_invite') {
        const templateFieldsSet = new Set(
          store.scenario?.data.template_fields.map(({ key }) => key)
        )

        const inviteData = store.values
        const totalFields = Object.assign({}, inviteData.template_fields)

        inviteData.template_fields = Object.entries(totalFields).reduce(
          (acm, [key, value]) => {
            if (templateFieldsSet.has(key)) {
              return {
                ...acm,
                [key]: value,
              }
            } else {
              return acm
            }
          },
          {}
        )

        inviteData.custom_fields = Object.entries(totalFields).reduce(
          (acm, [key, value]) => {
            if (!templateFieldsSet.has(key)) {
              return {
                ...acm,
                [key]: value,
              }
            } else {
              return acm
            }
          },
          {}
        )

        if (mode === 'create') {
          invitesStore.createInvite(inviteData).then(onClose)
        } else if (mode === 'resume') {
          invitesStore.resumeInvite(invite!, omit(inviteData, 'phone')).then(onClose)
        } else if (mode === 'update') {
          invitesStore.updateInvite(invite!, omit(inviteData, 'phone')).then(onClose)
        }
      } else {
        inviteFileDialog.open()
      }
    }

    const size: 'm' | 'l' = isMobile ? 'm' : 'l'

    const onboardingScenarioId = store.form.fields.company_onboarding_scenario_id.value

    const customFieldList = customFields.items.map((item) => item.data)

    const onboardingScenario: any =
      onboardingScenarios.items
        .map((item) => item.data)
        .find((item) => item.id === onboardingScenarioId) || {}

    const customFieldsTemplate = buildCustomFields(
      onboardingScenario as OnboardingScenarioEntity,
      customFieldList
    )

    const totalFields = [...onboardingScenario?.template_fields, ...customFieldsTemplate]

    const templateFieldsForm = store.templateFieldsForm && (
      <TemplateFieldsForm form={store.templateFieldsForm} fields={totalFields} />
    )

    let singleInviteHint: any
    if (store.scenario) {
      if (mode === 'create') {
        const url = `https://${window.location.hostname}/${store.scenario.data.invite_url}`
        singleInviteHint = (
          <div className={c.hint}>
            Мы отправим СМС со ссылкой на приглашение{' '}
            <a
              className={c.inviteLink}
              href={url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {url}
            </a>
          </div>
        )
      }
    }

    const multipleInviteHint = (
      <span className={c.label}>
        Пригласите сразу несколько исполнителей. Для этого нужно заполнить реестр
        исполнителей в excel и загрузить его.
      </span>
    )

    const actions = {
      create: 'Пригласить исполнителя',
      resume: 'Возобновить приглашение',
      update: 'Изменить приглашение',
    }

    const formSubmit =
      store.inviteMode === 'single_invite' ? (
        <Button
          isDisabled={!store.isValid}
          onTap={handleSubmit}
          isLoading={invitesStore.actionState.state === 'pending'}
        >
          {actions[mode]}
        </Button>
      ) : (
        <Button
          isDisabled={store.values.company_onboarding_scenario_id === undefined}
          onTap={handleSubmit}
          isLoading={store.displayMode === 'loading'}
        >
          Загрузить реестр исполнителей
        </Button>
      )

    const disableFields = mode !== 'create'

    const singleInviteForm = (
      <Col gap="var(--gap-m)">
        <Field field={store.form.fields.name}>
          {({ inputProps, error }) => (
            <FormRow label="ФИО" size={size} error={error}>
              <Input
                {...inputProps}
                isWide
                size={size}
                placeholder="Петров Алексей Михайлович"
              />
            </FormRow>
          )}
        </Field>
        <Field field={store.form.fields.phone}>
          {({ inputProps, error }) => (
            <FormRow label="Мобильный" size={size} error={error}>
              <Input
                {...inputProps}
                isWide
                numericKeyboard
                size={size}
                placeholder="+7 (000) 000-00-00"
                mask={internationalPhoneMask}
                isDisabled={disableFields}
              />
            </FormRow>
          )}
        </Field>
        {scenariosOptions.length > 1 && (
          <Field field={store.form.fields.company_onboarding_scenario_id}>
            {({ inputProps, error }) => (
              <FormRow label="Сценарий онбординга" size={size} error={error}>
                <SelectInput
                  {...inputProps}
                  value={
                    inputProps.value
                      ? scenariosOptions.find((o: any) => o.value === inputProps.value)
                      : null
                  }
                  onChange={(option) =>
                    store.form.fields.company_onboarding_scenario_id.change(
                      option ? option.value : null
                    )
                  }
                  size={size}
                  options={scenariosOptions}
                  isSearchable={false}
                  placeholder="Выберите сценарий"
                />
              </FormRow>
            )}
          </Field>
        )}
        {templateFieldsForm}
      </Col>
    )

    const multipleInviteForm = (
      <>
        <Col gap="var(--gap-m)">
          <Field field={store.form.fields.company_onboarding_scenario_id}>
            {({ inputProps, error }) => (
              <FormRow label="Сценарий онбординга" size={size} error={error}>
                <SelectInput
                  placeholder="Выберите"
                  noOptionsMessage={() => 'Нет Сценариев'}
                  {...inputProps}
                  value={
                    inputProps.value
                      ? scenariosOptions.find((o: any) => o.value === inputProps.value)
                      : null
                  }
                  onChange={(option) =>
                    store.form.fields.company_onboarding_scenario_id.change(
                      option ? option.value : null
                    )
                  }
                  size={size}
                  options={scenariosOptions}
                  isSearchable={false}
                />
              </FormRow>
            )}
          </Field>
        </Col>
        <span onClick={handleMultipleTemplateDownload} className={c.link}>
          Скачать шаблон для заполнения
        </span>
        {inviteFileDialog.render()}
      </>
    )

    const renderForm = () => {
      return (
        <>
          <H1>Приглашение исполнителей</H1>
          {store.mode === 'create' && (
            <Chips
              value={store.inviteMode}
              items={inviteModeChips}
              onChange={store.setInviteMode}
            />
          )}
          {store.inviteMode === 'single_invite' ? (
            <>{singleInviteForm}</>
          ) : (
            <>
              {multipleInviteHint}
              {multipleInviteForm}
            </>
          )}
          {singleInviteHint}
          {formSubmit}
        </>
      )
    }

    const renderError = () => (
      <>
        <H2>Ошибка приглашения исполнителей</H2>
        <span style={{ fontWeight: 500, fontSize: 16 }}>Проверьте данные в реестре</span>
        <List>
          {store.errorItems.map((__html, idx) => (
            <ListItem key={idx} style={{ padding: 0 }} hover={false}>
              <ListCell dangerouslySetInnerHTML={{ __html }} />
            </ListItem>
          ))}
        </List>
        <Button
          style={{ maxWidth: 250 }}
          onTap={() => {
            store.setDisplayMode('normal')
            store.setErrorItems([])
          }}
        >
          Назад
        </Button>
      </>
    )

    return (
      <Modal isOpen={true} onClose={onClose} size={isMobile ? '97%' : 600}>
        <Col gap="var(--gap-m)">
          {(store.displayMode === 'normal' || store.displayMode === 'loading') &&
            renderForm()}
          {store.displayMode === 'error' && renderError()}
        </Col>
      </Modal>
    )
  }
)

interface InviteListItemProps {
  invite: Entity<InviteEntity>
  store: InvitesStore
  onResume: (e: React.SyntheticEvent) => void
  onUpdate: (e: React.SyntheticEvent) => void
}

const InviteListItem = observer(
  ({ invite, store, onResume, onUpdate }: InviteListItemProps) => {
    let { isMobile } = useMediaContext()
    let currentUser = useUserStore()
    let {
      name,
      phone,
      invited_time,
      updated_time,
      onboarding_scenario,
      comment,
      spectrum_url,
      spectrum_data_report,
    } = invite.data

    let initialSpectrumDataReportStatus =
      spectrum_data_report === null ? null : spectrum_data_report?.status

    if (spectrum_url) {
      initialSpectrumDataReportStatus = SpectrumDataReportStatus.completed
    }

    const spectrumUrl = spectrum_data_report?.report_url
      ? spectrum_data_report?.report_url
      : spectrum_url

    const [spectrumDataReportStatus, setSpectrumDataReportStatus] = useState(
      initialSpectrumDataReportStatus
    )

    let [_cancelState, setCancelState] = useState(initialActionState)
    const [isVisibleModal, setVisibleModal] = useState(false)
    let cancel = useCallback((e: React.SyntheticEvent) => {
      if (window.confirm('Вы уверены что хотите отменить приглашение?')) {
        e.stopPropagation()
        setCancelState(store.cancelInvite(invite))
      }
    }, [])

    let phoneNode = phone && (
      <div style={{ color: 'var(--color-secondary)' }}>{formatPhone(phone)}</div>
    )

    let status = getInviteStatus(invite)

    const menuItems = []

    if (invite.data.cancelled) {
      menuItems.push({
        condition: currentUser.hasAbility('contractor_invites.resume'),
        icon: () => <RestoreIcon color="red" />,
        title: 'Возобновить приглашение',
        onClick: onResume,
      })
    } else {
      menuItems.push(
        {
          condition: currentUser.hasAbility('contractor_invites.resume'),
          icon: () => <EditIcon />,
          title: 'Изменить приглашение',
          onClick: onUpdate,
        },
        {
          condition: currentUser.hasAbility('contractor_invites.cancel'),
          icon: () => <ClearIcon />,
          title: 'Отменить приглашение',
          onClick: cancel,
          className: c.cancelButton,
        }
      )
    }

    if (
      currentUser.company?.data.features.check_contractor_spectrum_data &&
      spectrumDataReportStatus === null
    ) {
      menuItems.splice(menuItems.length - 1, 0, {
        condition: spectrumDataReportStatus === null,
        icon: () => <LightningIcon />,
        title: 'Проверка исполнителя',
        onClick: () => setVisibleModal(true),
      })
    }

    const isSpectrumReportInProgress =
      spectrumDataReportStatus === 'in_progress' || spectrumDataReportStatus === 'created'
    const isSpectrumReportDone = spectrumDataReportStatus === 'completed'

    return (
      <>
        <ListItem style={{ flexWrap: isMobile ? 'wrap' : 'nowrap' }}>
          {isMobile ? (
            <>
              <ListCell grow={1}>
                <div style={{ fontWeight: 'bold' }}> {name} </div>
                {phoneNode}
                {`${status.text} с ${printIsoDateTime(updated_time)}`}
              </ListCell>
              <ListCell width={48} style={{ alignSelf: 'start' }}>
                {isSpectrumReportInProgress && (
                  <div
                    className={cl(
                      c.reportStatus,
                      isSpectrumReportInProgress && c.inProgress
                    )}
                  >
                    <LightningIcon />
                    <div className={c.reportTooltip}>Отчет по исполнителю запрошен</div>
                  </div>
                )}
                {isSpectrumReportDone && (
                  <a
                    className={cl(c.reportStatus, isSpectrumReportDone && c.done)}
                    href={spectrumUrl}
                  >
                    <LightningIcon />
                    <div className={c.reportTooltip}>Отчет по исполнителю готов</div>
                  </a>
                )}
                <Kebab className={c.kebabInvite} menuItems={menuItems} />
              </ListCell>
            </>
          ) : (
            <>
              <ListCell col="name">
                {name}
                {phoneNode}
              </ListCell>
              <ListCell col="scenario">{onboarding_scenario?.title}</ListCell>
              <ListCell col="invited">{printIsoDateTime(invited_time)}</ListCell>
              <ListCell col="status">
                <Badge color={status.color}>{status.text}</Badge>
              </ListCell>
              <ListCell col="updated">{printIsoDateTime(updated_time)}</ListCell>
              <ListCell col="comment" ellipsis>
                {comment && (
                  <OverflownText>
                    <span dangerouslySetInnerHTML={{ __html: comment }} />
                  </OverflownText>
                )}
              </ListCell>
              <ListCell col="actions" style={{ justifyContent: 'flex-end' }}>
                {isSpectrumReportInProgress && (
                  <div
                    className={cl(
                      c.reportStatus,
                      isSpectrumReportInProgress && c.inProgress
                    )}
                  >
                    <LightningIcon />
                    <div className={c.reportTooltip}>Отчет по исполнителю запрошен</div>
                  </div>
                )}
                {isSpectrumReportDone && (
                  <a
                    className={cl(c.reportStatus, isSpectrumReportDone && c.done)}
                    href={spectrumUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <LightningIcon />
                    <div className={c.reportTooltip}>Отчет по исполнителю готов</div>
                  </a>
                )}
                <Kebab className={c.kebabInvite} menuItems={menuItems} />
              </ListCell>
            </>
          )}
          <SpectrumDataReportModal
            isVisibleModal={isVisibleModal}
            setVisibleModal={setVisibleModal}
            setSpectrumDataReportStatus={setSpectrumDataReportStatus}
            phone={phone}
            isInvitesPage={true}
          />
        </ListItem>
      </>
    )
  }
)

const ContractsForSign = observer(({ store }: { store: InvitesStore }) => {
  let { isMobile } = useMediaContext()
  let [isOpen, setIsOpen] = useState(false)
  let count = store.contractsForSign.length
  const { selected } = store
  let title = numForm(count, {
    one: 'договор ждёт вашей подписи',
    two: 'договора ждут вашей подписи',
    many: 'договоров ждут вашей подписи',
  })
  let list = (
    <Toggle isOpen={isOpen}>
      <div>
        <DocumentsForSignList
          documents={store.contractsForSign}
          style={{ paddingTop: '1rem' }}
          selected={store.selected}
        />
      </div>
    </Toggle>
  )
  return (
    <div className={c.contracts}>
      <Flex align="center" justify="space-between">
        <Flex
          align="center"
          gap=".25rem"
          className={c.contractsTitle}
          onClick={() => setIsOpen((v) => !v)}
        >
          <ArrowIcon style={{ transform: `rotate(${isOpen ? 180 : 0}deg)` }} />
          <span>{title}</span>
        </Flex>
        <Button
          design="normal"
          size="s"
          onTap={() => store.openSignDialog()}
          isDisabled={!selected.selectedCount}
        >
          {!selected.selectedCount || isMobile
            ? 'Подписать'
            : selected.isAllSelected
            ? 'Подписать все'
            : 'Подписать выбранные'}
        </Button>
      </Flex>
      {list}
    </div>
  )
})

interface InvitesViewProps {
  basePath: string
  onInviteSubmit: () => void
  invites: Collection<InviteEntity>
  onboardingScenarios: Collection<OnboardingScenarioEntity>
  customFields: Collection<CustomField>
}

const InvitesView = observer(
  ({ invites, onboardingScenarios, customFields, onInviteSubmit }: InvitesViewProps) => {
    let history = useHistory()
    let { isMobile } = useMediaContext()
    let currentUser = useUserStore()
    let toast = useStateToast()
    let api = useMobxApi()
    let signDialog = useSignDialogStore()

    let store = useMemo(
      () => new InvitesStore({ api, invites, toast, signDialog, onInviteSubmit }),
      []
    )
    useEffect(() => store.dispose, [])

    usePusherSubscription('update-invite', (event: any) => {
      invites.map[event.id]?.setData(event)
    })

    let [section, setSection] = useHashState(history, 'all')
    useEffect(() => {
      store.sections.section = section
    }, [section])

    let dialog = useDialogStore({ component: InviteFormModal })

    // let isOnboardingScenariosAvailable = currentUser.user!.data.flags.onboarding_scenarios

    let inviteButton = (
      <>
        {/* {isOnboardingScenariosAvailable && (
          <AbilityButton
            ability
            onTap={() => history.push('/onboarding_scenarios')}
            design="secondary"
            size={isMobile ? 's' : 'm'}
          >
            {isMobile ? 'Настроить' : 'Настроить сценарии'}
          </AbilityButton>
        )} */}
        <AbilityButton
          ability={currentUser.hasAbility('contractor_invites.create')}
          onTap={() =>
            dialog.open({ mode: 'create', store, onboardingScenarios, customFields })
          }
          size={isMobile ? 's' : 'm'}
          isLoading={store.actionState.state === 'pending'}
        >
          {isMobile ? 'Пригласить' : 'Пригласить исполнителей'}
        </AbilityButton>
      </>
    )

    let contracts = currentUser.hasAbility('contracts.sign') &&
      store.contractsForSign.length > 0 && <ContractsForSign store={store} />

    let dateColProps = { width: 115, style: { color: 'var(--color-secondary)' } }
    let content

    const scenarios = onboardingScenarios.items.filter(
      (item: Entity<OnboardingScenarioEntity>) => !item.data.archived && item.data.enabled
    )

    const scenariosOptions = scenarios.map((item: any) => ({
      value: item.data.id,
      label: item.data.title,
    }))

    let filters = (
      <FilterDropdown
        label="Сценарий онбординга"
        value={store.filtered.query?.onboardingScenarioId}
        onChange={(value) => {
          store.setOnboardingScenarioFilter(value || '')
        }}
        options={scenariosOptions}
      />
    )

    if (invites.items.length === 0) {
      content = <Placeholder>Пригласите исполнителей</Placeholder>
    } else {
      let list
      if (store.filtered.items.length === 0) {
        list = (
          <Placeholder>
            {store.filtered.query === undefined || store.filtered.query.search === ''
              ? 'Нет исполнителей с таким статусом'
              : 'Исполнителей не найдено'}
          </Placeholder>
        )
      } else {
        let cols = {
          name: { title: 'Имя', sorting: 'name' },
          scenario: { title: 'Сценарий онбординга', sorting: 'scenario' },
          invited: {
            title: 'Приглашён',
            sorting: 'invited_time',
          },
          status: { title: 'Статус', sorting: 'status' },
          updated: {
            title: 'Последнее обновление',
            sorting: 'updated_time',
          },
          comment: { title: 'Комментарий' },
          actions: { title: '' },
        }
        list = (
          <List
            cols={{
              name: {},
              icon: { width: 24, style: { display: 'flex', alignItems: 'center' } },
              scenario: { width: 150 },
              invited: dateColProps,
              status: { width: 160 },
              updated: dateColProps,
              comment: { width: 180 },
              actions: { width: 72, style: { display: 'flex', alignItems: 'center' } },
            }}
          >
            <SortableListHeader
              sorting={store.sorted.sorting}
              onChange={store.sorted.setSorting}
              cols={cols}
            />
            {store.sorted.items.map((item) => (
              <InviteListItem
                key={item.id}
                invite={item}
                store={store}
                onResume={(e: React.SyntheticEvent) => {
                  e.stopPropagation()
                  dialog.open({
                    mode: 'resume',
                    invite: item,
                    store,
                    onboardingScenarios,
                    customFields,
                  })
                }}
                onUpdate={(e: React.SyntheticEvent) => {
                  e.stopPropagation()
                  dialog.open({
                    mode: 'update',
                    invite: item,
                    store,
                    onboardingScenarios,
                    customFields,
                  })
                }}
              />
            ))}
          </List>
        )
      }
      content = (
        <Flex direction="column" gap="2rem">
          <SectionsChips
            store={store.sections}
            sections={{
              all: 'Все',
              form: 'Заполняют анкету',
              check: 'На проверке',
              error: 'Ошибка проверки',
              contract: 'Ждём подписания',
              signed_by_contractor: 'Осталась подпись компании',
              declined: 'Отказались',
              cancelled: 'Отменены',
              blacklisted: 'Заблокированы',
            }}
            onChange={setSection}
          />
          <Input
            value={store.filtered.query?.search || ''}
            onChange={(value: string) => store.setSearchFilter(value)}
            placeholder="Найти исполнителя"
            icon={<SearchIcon />}
          />
          {list}
        </Flex>
      )
    }

    return (
      <Layout>
        <Flex direction="column" gap="2rem">
          <Flex justify="space-between">
            <LayoutHeading count={store.filtered.items.length}>Приглашения</LayoutHeading>
            <Flex gap=".5rem">
              {inviteButton}

              <TooltipButton
                tooltip="Экспортировать в xlsx"
                size="s"
                design="normal"
                style={{
                  padding: '0',
                  width: '40px',
                  height: '40px',
                  transform: 'none',
                }}
                onTap={() => store.exportToFile('xlsx')}
              >
                <div className={c.exportIcon}>
                  <ExportIcon />
                </div>
              </TooltipButton>
            </Flex>
          </Flex>
          {filters}
          {contracts}
          {content}
          {dialog.render()}
          {store.render()}
        </Flex>
      </Layout>
    )
  }
)

const InvitesPage = observer(({ match }: PageProps) => {
  const mobxApi = useMobxApi()

  const handleLoadData = () => mobxApi.fetch({ type: 'contractors/invites' })

  let fetchState = useMemo(
    () => [
      mobxApi.fetch({ type: 'contractors/invites' }),
      mobxApi.fetch({ type: 'onboarding_scenarios' }),
      mobxApi.fetch({ type: 'contractors/custom_fields' }),
    ],
    []
  )

  return (
    <ActionStateView state={fetchState}>
      {([invites, onboardingScenarios, customFields]) =>
        invites.items ? (
          <InvitesView
            basePath={match.url}
            invites={invites}
            onInviteSubmit={handleLoadData}
            customFields={customFields}
            onboardingScenarios={onboardingScenarios}
          />
        ) : null
      }
    </ActionStateView>
  )
})

export default InvitesPage
