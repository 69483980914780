import { makeAutoObservable, action } from 'mobx'
import { fromPromise } from 'mobx-utils'
import { mapValues, mergeWith, cloneDeepWith, random } from 'lodash'

import type { Data, BackendAdapter, Action, ActionState } from './types'

const randomId = () => random(0, Number.MAX_SAFE_INTEGER)

export class ArraySchema {
  constructor(schema: Schema) {
    this.schema = schema
  }
  schema: Schema
}

export type Schema = string | ArraySchema | { [key: string]: Schema } | Schema[]

export interface TypeConfig {
  schema: Schema
}

export type TypesConfig = { [key: string]: TypeConfig }

const normalize = (
  data: Data,
  schema: Schema,
  cb: (type: string, data: Data | Array<Data>) => any
): any => {
  if (typeof data === 'object' && data !== null) {
    if (typeof schema === 'string') {
      return cb(schema, data)
    } else if (typeof schema === 'object') {
      if (Array.isArray(schema) && Array.isArray(data) && typeof schema[0] === 'string') {
        // ['entity'] => Collection<Entity>
        return cb(schema[0], data)
      }
      // ArraySchema(schema) => [ ... ]
      if (schema instanceof ArraySchema && Array.isArray(data)) {
        return data.map((item) => normalize(item, schema.schema, cb))
      }
      // { [key]: Schema }
      return mapValues(data, (value, key) =>
        key in schema
          ? normalize(value, (schema as { [key: string]: Schema })[key], cb)
          : value
      )
    }
  }
  return data
}

export const makeInitialActionState = <T>() => fromPromise.resolve({}) as ActionState<T>

export const initialActionState = makeInitialActionState<Data>()

class MobxApi {
  types: TypesConfig
  dispatch: BackendAdapter
  entities: { [key: string]: Map<any, Entity<any>> }

  constructor(types: TypesConfig, dispatch: BackendAdapter) {
    this.types = types
    this.dispatch = dispatch
    this.entities = mapValues(types, () => new Map())
  }

  fetch<T>({
    type,
    id,
    payload,
    optimistic = false,
    url,
    options,
  }: {
    type: string
    id?: any
    payload?: any
    optimistic?: boolean
    url?: string
    options?: Object
  }): ActionState<Entity<T> | Collection<T>> {
    this.checkEntityType(type)
    if (optimistic && id !== undefined && this.entities[type].has(id)) {
      let entity = this.entities[type].get(id) as Entity<T>
      entity!.fetch({ payload, options })
      return fromPromise.resolve(entity) as ActionState<Entity<T>>
    }
    return fromPromise(this.doFetch<T>(type, id, payload, url, options))
  }

  createOptimistic({
    type,
    payload,
    data,
    url,
    options,
  }: {
    type: string
    payload: any
    data?: any
    url?: string
    options?: Object
  }) {
    this.checkEntityType(type)
    let id = randomId()
    let entity = new Entity({ api: this, type })
    entity.setData({ id, ...(data || payload) })
    entity.isCreated = false
    entity.create({ url, options, payload })
    return entity
  }

  create<T>({
    type,
    payload,

    url,
    options,
  }: {
    type: string
    payload: any
    data?: any
    url?: string
    options?: Object
  }) {
    this.checkEntityType(type)
    return fromPromise(this.doCreate<T>(type, payload, url, options))
  }

  async doFetch<T>(type: string, id: any, payload: any, url?: string, options?: any) {
    let action: Action = { action: 'fetch', type, payload, url, options }
    if (id) action.id = id
    let data = await this.dispatch(action)
    if (Array.isArray(data)) return this.createCollection<T>(type, data)
    return this.createOrUpdateEntity<T>(type, data)
  }

  async doCreate<T>(type: string, payload: any, url?: string, options?: Object) {
    let data = await this.dispatch({ action: 'create', type, payload, url, options })
    return this.createOrUpdateEntity<T>(type, data)
  }

  checkEntityType(type: string) {
    if (!this.types[type]) throw new Error(`Unknown Entity type: ${type}`)
  }

  createCollection<T>(type: string, data: any): Collection<T> {
    let col = new Collection<T>({ api: this, type })
    // @ts-ignore
    col.allItems = data.map((itemData) => this.createOrUpdateEntity<T>(type, itemData))
    return col
  }

  createOrUpdateEntity<T>(type: string, data: any): Entity<T> {
    this.checkEntityType(type)
    let entity
    // if (!data.id) throw new Error(`Entity must have id: ${JSON.stringify(data)}`)
    if (data.id && this.entities[type].has(data.id)) {
      entity = this.entities[type].get(data.id)
    } else {
      entity = new Entity<T>({ api: this, type })
      // TODO
      // let entityClass = this.types[type].enitityClass || Entity
      // entity = new entityClass({ api: this, type })
      if (data.id !== undefined) this.entities[type].set(data.id, entity)
    }
    entity!.setData(data)
    return entity!
  }

  normalizeData(type: string, data: Data) {
    let schema = this.types[type].schema
    return normalize(data, schema, (type, data) => {
      if (Array.isArray(data)) return this.createCollection(type, data)
      return this.createOrUpdateEntity(type, data)
    })
  }
}

class Collection<T> {
  api: MobxApi
  type: string
  fetchState: ActionState<Data> = initialActionState
  createState: ActionState<Data> = initialActionState
  deleteState: ActionState<Data> = initialActionState
  allItems: Array<Entity<T>> = []
  deletedItems = new Set()

  constructor({ api, type }: { api: MobxApi; type: string }) {
    makeAutoObservable(this)
    this.api = api
    this.type = type
  }

  get items() {
    return this.allItems.filter((item) => !this.deletedItems.has(item))
  }

  get isEmpty() {
    return this.items.length === 0
  }

  get map() {
    let map: { [key: string]: Entity<T> } = {}
    this.items.forEach((item) => {
      if (item.id !== undefined) map[String(item.id)] = item
    })
    return map
  }

  // TODO mode = append|prepend|replace
  fetch({
    payload,
    append = false,
    url,
    options,
  }: { payload?: any; append?: boolean; url?: string; options?: Object } = {}) {
    this.fetchState = fromPromise(
      this.api
        .dispatch({ action: 'fetch', type: this.type, payload, url, options })
        .then((data) => {
          let fetchedEntities = data.map((itemData: Data) =>
            this.api.createOrUpdateEntity(this.type, itemData)
          )
          if (append) this.items.push(...fetchedEntities)
          else this.allItems = fetchedEntities
          return data
        })
    )
    return this.fetchState
  }

  create({
    payload,
    data,
    mode = 'append',
    optimistic = false,
    url,
    options,
  }: {
    payload?: any
    data?: any
    mode?: 'append' | 'prepend'
    optimistic?: boolean
    url?: string
    options?: Object
  } = {}) {
    if (optimistic) {
      let entity = this.api.createOptimistic({
        payload,
        data,
        options,
        type: this.type,
      }) as any as Entity<T>
      mode === 'append' ? this.append(entity) : this.prepend(entity)
      // @ts-ignore
      entity.createState.catch(() => this.remove(entity))
      return entity.createState
    }
    this.createState = fromPromise(
      this.api.dispatch({ action: 'create', type: this.type, payload, url, options })
    )
    this.createState.then((data) => {
      let created: Entity<T> = this.api.createOrUpdateEntity<T>(this.type, data)
      if (mode === 'append') this.allItems.push(created)
      else this.allItems.unshift(created)
    })
    return this.createState
  }

  delete({
    id,
    payload,
    optimistic = false,
    url,
    options,
  }: {
    id: any
    payload?: any
    optimistic?: boolean
    url?: string
    options?: Object
  }) {
    let entity = this.map[id]
    if (!entity) return initialActionState
    this.deleteState = entity.delete({ payload, url, options })
    if (optimistic) this.deletedItems.add(entity)
    this.deleteState.then(
      () => {
        this.deletedItems.delete(entity)
        this.allItems.splice(this.allItems.indexOf(entity), 1)
      },
      () => {
        this.deletedItems.delete(entity)
      }
    )
    return this.deleteState
  }

  append(...entities: Entity<T>[]) {
    this.allItems.push(...entities)
  }

  prepend(...entities: Entity<T>[]) {
    this.allItems.unshift(...entities)
  }

  remove(...entities: Entity<T>[]) {
    entities.forEach((entity) => {
      let index = this.allItems.indexOf(entity)
      if (index !== -1) this.allItems.splice(index, 1)
    })
  }
}

export interface ObjectWithId {
  id?: any
}

class Entity<T extends ObjectWithId> {
  api: MobxApi
  type: string
  data: T = {} as T
  createState: ActionState<Data> = initialActionState
  fetchState: ActionState<Data> = initialActionState
  updateState: ActionState<Data> = initialActionState
  deleteState: ActionState<Data> = initialActionState
  isCreated = true
  isDeleted = false

  constructor({ api, type }: { api: MobxApi; type: string }) {
    makeAutoObservable(this)
    this.api = api
    this.type = type
  }

  get id(): T['id'] {
    return this.data.id!
  }

  create({
    payload,
    url,
    options,
  }: { payload?: any; url?: string; options?: Object } = {}) {
    this.createState = fromPromise(
      this.api.dispatch({
        action: 'create',
        type: this.type,
        payload,
        url,
        options,
      })
    )
    this.createState.then(
      action((data: Data) => {
        this.api.entities[this.type].delete(this.id)
        this.setData(data)
        this.api.entities[this.type].set(this.id, this)
        this.isCreated = true
      })
    )
    return this.createState
  }

  fetch({
    payload,
    url,
    options,
  }: { payload?: any; url?: string; options?: Object } = {}) {
    this.fetchState = fromPromise(
      this.api
        .dispatch({
          action: 'fetch',
          id: this.id,
          type: this.type,
          payload,
          url,
          options,
        })
        .then((data) => {
          this.setData(data)
          return data
        })
    )
    return this.fetchState
  }

  update({
    payload,
    clientPayload,
    optimistic = false,
    url,
    options,
  }: {
    payload?: any
    clientPayload?: any
    optimistic?: boolean
    url?: string
    options?: Object
  } = {}) {
    let oldData: T
    if (optimistic) {
      // TODO should not allow update nested entities
      oldData = cloneDeepWith(this.data, (value) => {
        if (value instanceof Entity) return value
        return undefined
      })
      if (clientPayload) this.setData(clientPayload, { normalize: false })
      else this.setData(payload)
    }
    this.updateState = fromPromise(
      this.api
        .dispatch({
          action: 'update',
          type: this.type,
          id: this.id,
          payload,
          url,
          options,
        })
        .then(
          (data) => {
            this.setData(data)
            return data
          },
          (error) => {
            if (optimistic) this.setData(oldData, { normalize: false })
            throw error
          }
        )
    )
    return this.updateState
  }

  delete({
    payload,
    url,
    options,
  }: { payload?: any; url?: string; options?: Object } = {}) {
    this.deleteState = fromPromise(
      this.api.dispatch({
        type: this.type,
        id: this.id,
        action: 'delete',
        payload,
        url,
        options,
      })
    )
    return this.deleteState
  }

  action<T extends Data = Data>({
    action,
    payload,
    url,
    options,
  }: {
    action: string
    payload?: any
    url?: string
    options?: any
  }): ActionState<T> {
    return fromPromise(
      this.api.dispatch({ type: this.type, id: this.id, action, payload, url, options })
    ) as ActionState<T>
  }

  setData(newData: Data, { normalize = true }: { normalize?: boolean } = {}) {
    let normalizedData = normalize ? this.api.normalizeData(this.type, newData) : newData
    mergeWith(this.data, normalizedData, (value, srcValue) => {
      if (Array.isArray(srcValue)) return srcValue
      if (value instanceof Entity) return srcValue
      return undefined
    })
  }
}

export default MobxApi
export { Entity, Collection }
