import React from 'react'

import styles from './styles.module.css'

type ITabsOption = {
  value: string,
  label: string
}

interface ITabsProps {
  title: string
  options: ITabsOption[]
  value: string
  onChange: (value: string) => void
  children?: React.ReactNode
}

const Tabs = ({ title, options, value, onChange, children }: ITabsProps) => {
  return (
    <div className={styles.tabs}>
      <div className={styles.wrapper}>
        {title && <div className={styles.title}>{title}</div>}
        <div className={styles.items}>
          {options.map((item: ITabsOption) => (
            <div
              className={item.value === value ? styles.active : ''}
              onClick={() => onChange(item.value)}
            >
              {item.label}
            </div>
          ))}
        </div>
      </div>
      <div className={styles.content}>
        {children}
      </div>
    </div>
  )
}

export default Tabs