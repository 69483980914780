import React from 'react'

import { CompanyBankDetails } from 'entities'
import { thinsp } from 'utils/typo'

import { Modal, BaseButton, Col, H2 } from 'components/ui'

interface SelectCompanyAccountDialogProps {
  accounts: CompanyBankDetails[]
  onClose: () => void
  onSelect: (id: number) => void
}

const accountButtonStyles = (props: any, { isHovered }: any) => ({
  root: {
    fontSize: '1.25rem',
    textAlign: 'left',
    padding: '1rem',
    borderRadius: '.5rem',
    cursor: 'pointer',
    background: isHovered ? '#efebff' : 'white',
  },
})

const SelectCompanyAccountDialog = ({
  onClose,
  onSelect,
  accounts,
}: SelectCompanyAccountDialogProps) => (
  <Modal isOpen={true} onClose={onClose} size={750}>
    <Col gap="1.5rem">
      <H2>Выберите счет для экспорта</H2>
      <Col gap=".5rem">
        {accounts.map(({ id, account, bank_name }) => (
          <BaseButton key={id} onTap={() => onSelect(id)} styles={accountButtonStyles}>
            Счёт №{thinsp}
            {account} в {bank_name}
          </BaseButton>
        ))}
      </Col>
    </Col>
  </Modal>
)

export default SelectCompanyAccountDialog
