import { H4 } from 'ui'
import React from 'react'

import s from './styles.module.css'

interface ITableTitleProps {
  title: string
  note?: string
}

const TableTitle = ({ title, note = '' }: ITableTitleProps) => (
  <H4 className={s.tableTitle}>
    {title}
    {note.length > 0 && (
      <>
        &nbsp;<span>{note}</span>
      </>
    )}
  </H4>
)

export default TableTitle
