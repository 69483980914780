import React, { ReactNode } from 'react'
import cn from 'clsx'

import s from './style.module.css'

interface Props {
  children: ReactNode
  active?: boolean
  onClick?: () => void
  [key: string]: any
}

export default function Chip({ children, active, onClick, ...props }: Props) {
  return (
    <div
      {...props}
      className={cn(s.root, props.className, { [s.active]: active })}
      style={{ ...props.style, cursor: onClick ? 'pointer' : 'default' }}
      onClick={onClick}
    >
      {children}
    </div>
  )
}
