import React, { forwardRef } from 'react'
import { Tooltip, Placement } from '@chakra-ui/react'

import { Button, IconButton, MenuItem } from 'components/ui'

interface TooltipButtonProps extends React.ComponentProps<typeof Button> {
  tooltip?: React.ReactNode
  placement?: Placement
}

const TooltipButton = forwardRef(
  ({ tooltip, placement, ...rest }: TooltipButtonProps, ref: any) => (
    <Tooltip label={tooltip} placement={placement!}>
      <Button ref={ref} {...rest} as="div" />
    </Tooltip>
  )
)

TooltipButton.defaultProps = {
  placement: 'top',
}

interface TooltipMenuItemProps extends React.ComponentProps<typeof MenuItem> {
  tooltip?: React.ReactNode
  placement?: Placement
}

const TooltipMenuItem = ({ tooltip, placement, ...rest }: TooltipMenuItemProps) => (
  <Tooltip label={tooltip} placement={placement}>
    <MenuItem {...rest} as="div" />
  </Tooltip>
)

TooltipMenuItem.defaultProps = {
  placement: 'top',
}

const defaultMessage = 'Ваша роль в компании не обладает правом на это действие'

interface AbilityButtonProps extends React.ComponentProps<typeof TooltipButton> {
  ability: boolean
  noAbilityMessage?: React.ReactNode
}

const AbilityButton = forwardRef(
  (
    { ability, noAbilityMessage, isDisabled, tooltip, ...rest }: AbilityButtonProps,
    ref: any
  ) => (
    <TooltipButton
      ref={ref}
      {...rest}
      tooltip={!ability ? noAbilityMessage : tooltip}
      isDisabled={isDisabled || !ability}
    />
  )
)

AbilityButton.defaultProps = {
  noAbilityMessage: defaultMessage,
}

interface AbilityIconButtonProps extends React.ComponentProps<typeof IconButton> {
  ability: boolean
}

const AbilityIconButton = ({
  ability,
  isDisabled,
  tooltip,
  ...rest
}: AbilityIconButtonProps) => (
  <IconButton
    {...rest}
    tooltip={ability ? tooltip : `${tooltip} (Нет прав)`}
    isDisabled={isDisabled || !ability}
  />
)

interface AbilityMenuItemProps extends React.ComponentProps<typeof TooltipMenuItem> {
  ability: boolean
  noAbilityMessage?: React.ReactNode
}

const AbilityMenuItem = ({
  ability,
  noAbilityMessage,
  isDisabled,
  tooltip,
  ...rest
}: AbilityMenuItemProps) => (
  <TooltipMenuItem
    {...rest}
    tooltip={!ability ? noAbilityMessage : tooltip}
    isDisabled={isDisabled || !ability}
  />
)

AbilityMenuItem.defaultProps = {
  noAbilityMessage: defaultMessage,
}

export {
  TooltipButton,
  TooltipMenuItem,
  AbilityButton,
  AbilityIconButton,
  AbilityMenuItem,
}
