import React from 'react'
import cx from 'clsx'

import css from '../styles.module.css'

interface IGlobalNotificationsWrapper {
  isHidden: boolean
  controls?: React.ReactNode
  children: React.ReactNode
}

const GlobalNotificationWrapper = ({ isHidden, children, controls }: IGlobalNotificationsWrapper) => {
  return (
    <div className={cx(css.container, isHidden && css.hide)}>
      <div className={css.wrapper}>
        <div className={css.content}>
          <div className={css.text}>
            {children}
          </div>
          {controls && (
            <div className={css.button}>
              {controls}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default GlobalNotificationWrapper