import { validateEmail, validateInn } from 'utils/validations'

const requiredError = 'Необходимо заполнить это поле'

const fileFieldConfig = {
  isRequired: true,
  requiredError,
}

const extraFieldsConfig: any = {
  text: {
    config: { requiredError },
    initialValue: '',
  },
  email: {
    config: {
      requiredError,
      validations: {
        email: { validate: validateEmail, error: 'Введите правильный email-адрес' },
      },
    },
    initialValue: '',
  },
  inn: {
    config: {
      requiredError,
      validations: {
        inn: { validate: validateInn, error: 'Некорректный ИНН' },
      },
    },
    initialValue: '',
  },
  checkbox: {
    config: {
      requiredError: 'Необходимо дать согласие',
      isEmpty: (value: boolean) => value === false,
    },
    initialValue: true,
  },
  file: {
    config: fileFieldConfig,
    initialValue: undefined,
  },
  select: {
    config: { requiredError: 'Необходимо выбрать значение' },
    initialValue: null,
  },
  multiselect: {
    config: {
      requiredError: 'Выберите одно или несколько значений',
      isEmpty: (value: string[]) => value.length === 0,
    },
    initialValue: [],
  },
  number: {
    config: {
      requiredError,
    },
    initialValue: 0,
  },
  date: {
    config: {
      requiredError,
      isEmpty: (value: any) => !value,
    },
    initialValue: null,
  },
}

export default extraFieldsConfig
