import React from 'react'
import { makeAutoObservable } from 'mobx'
import { Observer } from 'mobx-react-lite'
// @ts-ignore
import ContentDisposition from 'content-disposition'

import { getBaseUrl, getDefaultHeaders } from 'api/apiDefaults'
import { Entity } from 'mobx/mobx'
import UserStore from 'stores/UserStore'
import downloadFile from 'utils/downloadFile'
import { useStateToast } from 'components/ui'
import SelectCompanyAccountDialog from 'components/SelectCompanyAccountDialog'

const fetchFile = async (url: string) => {
  let res = await fetch(url, {
    method: 'GET',
    credentials: 'include',
    headers: getDefaultHeaders(),
  })
  if (!res.ok) throw new Error()
  let blob = await res.blob()
  let disposition = res.headers.get('Content-Disposition')
  let filename = 'unknown'
  if (disposition) {
    let parsed = ContentDisposition.parse(disposition)
    if (parsed.parameters.filename) filename = parsed.parameters.filename
  }
  return { blob, filename }
}

const fetchAndDownloadFile = async (url: string, filename?: string) => {
  let { blob, filename: origFilename } = await fetchFile(url)
  downloadFile(blob, filename ?? origFilename)
}

class ExportActionsStore {
  constructor(currentUser: UserStore, toast: ReturnType<typeof useStateToast>) {
    this.currentUser = currentUser
    this.toast = toast
    makeAutoObservable(this, { render: false })
  }

  currentUser: UserStore
  toast: ReturnType<typeof useStateToast>

  modalIsOpen = false
  modalProps?: { entity: Entity<any> }

  openModal(entity: Entity<any>) {
    this.modalProps = { entity }
    this.modalIsOpen = true
  }

  async exportTo1c(entity: Entity<any>) {
    try {
      let company = this.currentUser.company!
      await company.fetch()
      if (company.data.bank_details.length === 0) {
        this.toast.error({ title: 'Не удалось экспортировать акты' })
      } else if (company.data.bank_details.length === 1) {
        this.download1cFile(entity, company.data.bank_details[0].id)
      } else {
        this.openModal(entity)
      }
    } catch (e) {
      this.toast.error({ title: 'Не удалось экспортировать акты' })
    }
  }

  async download1cFile(entity: Entity<any>, account_id: number) {
    let url =
      getBaseUrl() + `/${entity.type}/${entity.id}/export_to_1c?account_id=${account_id}`
    try {
      fetchAndDownloadFile(url, `konsol_${entity.type}_${entity.id}.txt`)
    } catch (e) {
      this.toast.error({ title: 'Не удалось экспортировать акты' })
    }
  }

  render() {
    return (
      <Observer>
        {() => {
          return this.modalIsOpen ? (
            <SelectCompanyAccountDialog
              accounts={this.currentUser.company!.data.bank_details}
              onClose={() => {
                this.modalIsOpen = false
              }}
              onSelect={(id) => {
                this.modalIsOpen = false
                this.download1cFile(this.modalProps!.entity, id)
              }}
            />
          ) : null
        }}
      </Observer>
    )
  }

  async exportToXlsx(entity: Entity<any>) {
    let url = getBaseUrl() + `/${entity.type}/${entity.id}/export_to_xlsx`
    try {
      fetchAndDownloadFile(url, `konsol_${entity.type}_${entity.id}.xlsx`)
    } catch (e) {
      this.toast.error({ title: 'Не удалось экспортировать акты' })
    }
  }

  async exportToJson(entity: Entity<any>) {
    let url = getBaseUrl() + `/${entity.type}/${entity.id}/export_to_json`
    try {
      fetchAndDownloadFile(url, `konsol_${entity.type}_${entity.id}.json`)
    } catch (e) {
      this.toast.error({ title: 'Не удалось экспортировать акты' })
    }
  }

  async downloadOriginalFile(entity: Entity<any>) {
    let url = getBaseUrl() + `/${entity.type}/${entity.id}/download_original_file`
    try {
      fetchAndDownloadFile(url)
    } catch (e) {
      this.toast.error({ title: 'Не удалось скачать файл' })
    }
  }
}

export default ExportActionsStore
