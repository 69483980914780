import React, { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Time } from '@internationalized/date'

import { Layout, PageHeader, useStateToast } from 'components/ui'
import { Button, Flex, H1, icons } from 'ui'
// @ts-ignore
import { FormStore } from 'shadowform'

import TaskEditForm from './components/TaskEditForm'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { makeAutoObservable, runInAction } from 'mobx'
import { fetchApi } from 'api'

import { formatTime, parseDate, serializeDate } from 'utils/datetime'
import numForm from 'utils/numForm'
import { Badge } from 'components/Badge'
import { Tooltip } from '@chakra-ui/react'

const taskWordFormsWith = {
  one: 'задание',
  two: 'задания',
  many: 'заданий',
}

const createTaskForm = (
  initialValues: Record<string, any> = {},
  params: Record<string, any> = {},
  onError?: (e: Error) => void
) => {
  const formStore = new FormStore({
    initialValues: {
      id: initialValues.id || null,
      project_id: initialValues.project_id || null,
      location: initialValues.location || '',
      periodFrom: initialValues.periodFrom || null,
      periodTo: initialValues.periodTo || null,
      timeFrom: initialValues.timeFrom || null,
      timeTo: initialValues.timeTo || null,
      description: initialValues.description || '',
      units: initialValues.units || [],
      constractorsIds: initialValues.constractorsIds || [],
      status: initialValues.status || '',
      contractor: initialValues.contractor || '',
      company: initialValues.company || '',
      actions: initialValues.actions || '',
      project_location: null,
      project_periodFrom: null,
      project_periodTo: null,
      project_timeFrom: null,
      project_timeTo: null,
      project_description: null,
    },
    fields: {
      id: {},
      project_id: {},
      location: {},
      periodFrom: {},
      periodTo: {},
      timeFrom: {},
      timeTo: {},
      description: {},
      units: {},
      constractorsIds: {},
      status: {},
      contractor: {},
      company: {},
      project_location: {},
      project_periodFrom: {},
      project_periodTo: {},
      project_timeFrom: {},
      project_timeTo: {},
      project_description: {},
      actions: {},
    },
  })

  const handleProjectChange = async () => {
    const projectId = formStore.fields.project_id.value
    if (!projectId) {
      return
    }
    try {
      let {
        upto_date = null,
        upto_time = null,
        since_date = null,
        since_time = null,
        location = null,
        comment = null,
      } = await fetchApi({
        url: `scenario_projects/${projectId}`,
        method: 'GET',
      })
      since_date = since_date ? parseDate(since_date).toDate() : null
      upto_date = upto_date ? parseDate(upto_date).toDate() : null
      since_time = since_time
        ? new Time(parseInt(since_time.split(':')[0]), parseInt(since_time.split(':')[1]))
        : null
      upto_time = upto_time
        ? new Time(parseInt(upto_time.split(':')[0]), parseInt(upto_time.split(':')[1]))
        : null

      formStore.fields.project_location.setValue(location || null)

      if (!params.id) {
        formStore.fields.location.setValue(location?.address || '')
        formStore.fields.periodFrom.setValue(since_date)
        formStore.fields.periodTo.setValue(upto_date)
        formStore.fields.timeFrom.setValue(since_time)
        formStore.fields.timeTo.setValue(upto_time)
        formStore.fields.description.setValue(comment)
      }
    } catch (e) {
      onError && onError(e as Error)
    }
  }

  formStore.fields.project_id.on('change', handleProjectChange)

  return handleProjectChange().then(() => formStore)
}

const EditAddPage = observer(() => {
  const params = useParams<any>()

  const history = useHistory()
  const toast = useStateToast()

  const store = useLocalObservable(
    () =>
      new (class {
        projectList: any[] = []

        unitOptions: any[] = []

        form?: FormStore

        constructor() {
          makeAutoObservable(this)
        }

        _handleError = (e: Error) => {
          toast.error({
            title: 'Произошла ошибка',
            description: e.message,
          })
        }

        _loadUnits = async () => {
          try {
            const items = await fetchApi({ url: 'subtasks/units' })
            runInAction(() => {
              this.unitOptions = items.map((unit: any) => ({
                value: unit.id,
                label: unit.title,
              }))
            })
          } catch (e: any) {
            toast.error({
              title: 'Не удалось загрузить список единиц измерения',
              description: e.message,
            })
          }
        }

        _loadProjectList = async () => {
          try {
            await fetchApi({
              url: 'scenario_projects/filter',
              method: 'POST',
              data: {
                minimal: true,
                filters: {},
                no_paginate: true,
              },
            }).then((projectsData: any) => {
              const projectsList = projectsData.map((item: any) => {
                return {
                  label: item.title,
                  value: item.id,
                }
              })
              runInAction(() => {
                this.projectList = projectsList
              })
            })
          } catch (e: any) {
            toast.error({
              title: 'Не удалось загрузить список проектов',
              description: e.message,
            })
          }
        }

        _validateData = (data: Record<string, any>) => {
          data.units.forEach((unit: any, idx: number) => {
            const { title, cost, unit_type, quantity } = unit

            if (!title) {
              throw new Error(`Пропущено наименование задания ${idx + 1}`)
            }

            if (!cost) {
              throw new Error(`Укажите стоимость задания «${title}»`)
            }

            if (!unit_type) {
              throw new Error(`Укажите единицу измерения для задания «${title}»`)
            }

            if (!quantity) {
              throw new Error(`Укажите количество единиц для задания «${title}»`)
            }
          })
        }

        beginEditTask = async (id: number) => {
          await this._loadProjectList()
          await this._loadUnits()
          try {
            const initialData = await fetchApi({
              method: 'GET',
              url: `scenario_tasks/${id}`,
            })
            createTaskForm(
              {
                project_id: initialData.project?.id,
                description: initialData.description,
                location: initialData.location?.address || '',
                status: initialData.status,
                actions: initialData.actions.join(','),
                contractor: initialData.contractor?.name,
                periodFrom: initialData.since_date
                  ? parseDate(initialData.since_date).toDate()
                  : null,
                periodTo: initialData.upto_date
                  ? parseDate(initialData.upto_date).toDate()
                  : null,
                timeFrom: initialData.since_time
                  ? new Time(
                      parseInt(initialData.since_time.split(':')[0]),
                      parseInt(initialData.since_time.split(':')[1])
                    )
                  : null,
                timeTo: initialData.upto_time
                  ? new Time(
                      parseInt(initialData.upto_time.split(':')[0]),
                      parseInt(initialData.upto_time.split(':')[1])
                    )
                  : null,
                units: initialData.units,
                company: initialData.company.name,
              },
              params,
              this._handleError
            ).then((formStore: any) => {
              runInAction(() => {
                this.form = formStore
              })
            })
          } catch (e: any) {
            toast.error({
              title: 'Ошибка загрузки задания',
              description: e.message,
            })
          }
        }

        beginCreateTask = async () => {
          await this._loadProjectList()
          await this._loadUnits()
          createTaskForm(
            {
              project_id: parseInt(params.projectId),
              actions: 'delete_draft',
              status: 'draft',
            },
            params,
            this._handleError
          ).then((formStore: any) => {
            runInAction(() => {
              this.form = formStore
            })
          })
        }

        handleSaveTask = async (): Promise<true | undefined> => {
          try {
            let timeFrom = this.form.fields.timeFrom.value
            let timeTo = this.form.fields.timeTo.value
            const periodFrom = this.form.fields.periodFrom.value
            const periodTo = this.form.fields.periodTo.value
            const since_date = periodFrom ? serializeDate(periodFrom) : null
            const upto_date = (periodTo ? serializeDate(periodTo) : null) || since_date

            const data = {
              project_id: this.form.fields.project_id.value,
              since_date,
              upto_date,
              since_time: timeFrom
                ? formatTime(`${timeFrom.hour}:${timeFrom.minute}`)
                : null,
              upto_time: timeTo ? formatTime(`${timeTo.hour}:${timeTo.minute}`) : null,
              description: this.form.fields.description.value,
              units: this.form.fields.units.value.map((unit: any) => ({
                ...unit,
                unit_type: unit.unit_type?.toString() || null,
              })),
              ...(!params.id && {
                contractor_ids: this.form.fields.constractorsIds.value,
              }),
              location:
                typeof this.form.fields.location.value === 'string'
                  ? {
                      address: this.form.fields.location.value,
                      lng: 0,
                      lat: 0,
                    }
                  : {
                      address: this.form.fields.location.value.value,
                      lat: this.form.fields.location.value.data?.geo_lat || 0,
                      lng: this.form.fields.location.value.data?.geo_lon || 0,
                    },
            }
            this._validateData(data)
            await fetchApi({
              method: !params.id ? 'POST' : 'PATCH',
              url: !params.id ? 'scenario_tasks' : `scenario_tasks/${params.id}`,
              data,
            })
            return true
          } catch (e: any) {
            toast.error({
              title: 'Ошибка сохранения задания',
              description: e.message,
            })
          }
          return
        }
      })()
  )

  useEffect(() => {
    if (params.id) {
      store.beginEditTask(params.id)
    } else {
      store.beginCreateTask()
    }
  }, [])

  const constractorsCount = store.form?.fields.constractorsIds.value.length || 0

  const onTaskCreate = async () => {
    const isOk = await store.handleSaveTask()
    if (isOk) {
      history.push(params.projectId ? `/projects/${params.projectId}` : '/tasks')
    }
  }

  const onTaskEdit = async () => {
    const isOk = await store.handleSaveTask()
    isOk &&
      toast.success({
        title: 'Изменения сохранены',
      })
  }

  const onRemoveTask = async () => {
    if (params.id) {
      await fetchApi({
        url: `scenario_tasks/multi_action`,
        method: 'POST',
        data: {
          action_type: 'delete_draft',
          ids: [params.id],
        },
      })
      toast.success({ title: 'Черновики удалены' })
    }
    if (params.projectId) {
      history.push(`/projects/${params.projectId}`)
    } else {
      history.push('/tasks')
    }
  }

  const statusColorMap: Record<string, any> = {
    draft: 'gray',
    created: 'gray',
    suggested: 'purple',
    declined: 'red',
    cancelled: 'red',
    in_progress: 'blue',
    not_completed: 'red',
    completed: 'green',
  }

  const statusLabelMap: Record<string, any> = {
    draft: 'Черновик',
    created: 'Черновик',
    suggested: 'Предложено',
    declined: 'Отказался',
    cancelled: 'Отменено',
    in_progress: 'В работе',
    not_completed: 'Не выполнено',
    completed: 'Выполнено',
  }

  const Controls = () => {
    if (params.id) {
      return (
        <Flex direction="row" align="center" gap="15px">
          {!!store.form && (
            <>
              <span style={{ color: 'gray' }}>Статус</span>
              <div style={{ marginRight: 15 }}>
                <Badge color={statusColorMap[store.form.fields.status.value] || 'gray'}>
                  {statusLabelMap[store.form.fields.status.value] ||
                    store.form.fields.status.value}
                </Badge>
              </div>
            </>
          )}
          <Button onClick={onTaskEdit} isDisabled={!store.form}>
            Сохранить изменения
          </Button>
          {!!store.form && (
            <>
              {store.form.fields.actions.value.includes('delete_draft') && (
                <Button
                  variant="simple"
                  style={{ padding: '12px' }}
                  onClick={onRemoveTask}
                >
                  <icons.Garbage />
                </Button>
              )}
            </>
          )}
        </Flex>
      )
    } else if (constractorsCount === 0) {
      return (
        <Tooltip label="Не выбран ни один исполнитель">
          <Button isDisabled>Создать задание</Button>
        </Tooltip>
      )
    } else {
      return (
        <Button onClick={onTaskCreate} isDisabled={!store.form}>
          {`Создать ${numForm(constractorsCount, taskWordFormsWith)}`}
        </Button>
      )
    }
  }

  return (
    <Layout smallPaddingTop>
      <PageHeader
        onBack={() =>
          params.projectId
            ? history.push(`/projects/${params.projectId}`)
            : history.push('/tasks')
        }
        title={
          <Flex justify="start" align="center" gap="15px">
            <H1>{!params.id ? 'Создать задания' : 'Редактировать задание'}</H1>
          </Flex>
        }
        controls={
          <Flex gap="8px">
            <Controls />
          </Flex>
        }
      />
      {!!store.form && <TaskEditForm id={params.id} store={store} />}
    </Layout>
  )
})

export default EditAddPage
