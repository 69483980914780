import React, { forwardRef } from 'react'

import {
  Flex,
  BaseButton,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
} from 'components/ui'

import { ReactComponent as DropdownIcon } from '@material-design-icons/svg/round/arrow_drop_down.svg'
import { ReactComponent as CancelIcon } from '@material-design-icons/svg/round/cancel.svg'

const filterButtonStyles = (
  props: React.ComponentProps<typeof BaseButton>,
  tapState: any
) => {
  let root: React.CSSProperties = {
    height: 36,
    padding: '0 1rem',
    borderRadius: 7,
    minWidth: 200,
    maxWidth: 350,
  }
  root.border = '1px solid var(--color-grey-1)'
  let shadows = []
  // if (tapState.isHovered) shadows.push('rgb(0 0 0 / 8%) 0px 3px 10px 0px')
  if (props.isActive) {
    // root.border = '2px solid var(--color-active)'
    // root.marginLeft = -2
  }
  if (tapState.isFocused) shadows.push('var(--konsol-focus-shadow)')
  // root.boxShadow = shadows.join(',')
  root.boxShadow = shadows.join(',')
  return { root }
}

const FilterButton = forwardRef((props: React.ComponentProps<typeof Button>, ref) => (
  <BaseButton ref={ref} styles={filterButtonStyles} {...props} />
))

interface FilterDropdownProps<T> {
  label: React.ReactNode
  options: Array<{ value: T; label: React.ReactNode }>
  value?: T
  onChange: (value?: T) => void
  width?: number
  style?: any
}

const filterDropdownStyles: { [key: string]: React.CSSProperties } = {
  label: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  reset: { marginRight: '-.5rem', pointerEvents: 'all' },
  arrow: { marginRight: '-.5rem', fill: 'var(--color-icon)' },
}

const FilterDropdown = <T,>({
  label,
  options,
  value,
  onChange,
  width = 250,
  style,
}: FilterDropdownProps<T>) => {
  let s = filterDropdownStyles
  return (
    <div style={{ position: 'relative' }}>
      <Menu placement="bottom-start">
        {({ onClose }) => (
          <>
            <MenuButton
              style={{ outline: 'none', ...style }}
              as={FilterButton}
              isActive={value}
            >
              <Flex gap=".5rem" justify="space-between" align="center">
                <div style={s.label}>
                  {label}
                  {value && `: ${options.find((o) => o.value === value)!.label}`}
                </div>
                {value ? (
                  <IconButton
                    tooltip="Сбросить"
                    onTap={() => {
                      onClose && onClose()
                      onChange(undefined)
                    }}
                    style={s.reset}
                  >
                    <CancelIcon />
                  </IconButton>
                ) : (
                  <DropdownIcon style={s.arrow} />
                )}
              </Flex>
            </MenuButton>
            <MenuList width={width} style={{ maxHeight: '50vh', overflowY: 'auto' }}>
              {options.map((option) => {
                let isSelected = value === option.value
                let onSelect = () => onChange(isSelected ? undefined : option.value)
                return (
                  <MenuItem
                    onClick={onSelect}
                    key={String(option.value)}
                    style={{ fontWeight: isSelected ? 'bold' : 'normal' }}
                  >
                    {option.label}
                  </MenuItem>
                )
              })}
            </MenuList>
          </>
        )}
      </Menu>
    </div>
  )
}

export default FilterDropdown
