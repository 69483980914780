import React from 'react'

import { Entity } from 'mobx/MobxApi'
import { ScenarioProjectEntityRead } from 'entities'

import { formatTime, parseDate } from 'utils/datetime'
import { formatAmount } from 'utils/amount'

import { ListItem, ListCell } from 'components/ui'
import { Flex, icons } from 'ui'

import { PageProps } from 'app.types'

import { join } from 'path'

import css from './styles.module.css'

interface IProjectsListRowProps {
  project: Entity<ScenarioProjectEntityRead>
  pageProps: PageProps
}

const ProjectsListRow = ({ project, pageProps }: IProjectsListRowProps) => {
  const {
    id,
    title: name,
    location,
    since_date,
    upto_date,
    since_time,
    upto_time,
    tasks_count,
    total_amount: coast,
  } = project.data

  const handleClick = () => {
    pageProps.history.push(join(pageProps.location.pathname, id.toString()))
  }

  const dateFormat = `DD.MM.YYYY`

  const dateLabel = `${since_date ? parseDate(since_date).format(dateFormat) : '...'} - ${
    upto_date ? parseDate(upto_date).format(dateFormat) : '...'
  }`
  const timeLabel = `с ${formatTime(since_time)} по ${formatTime(upto_time)}`

  return (
    <ListItem style={{ alignItems: 'stretch', cursor: 'pointer' }} onClick={handleClick}>
      <ListCell>{name}</ListCell>
      <ListCell style={{ maxWidth: 70 }} className={css.secondaryField}>
        {tasks_count}
      </ListCell>
      <ListCell className={css.secondaryField}>
        <Flex gap="2px">
          <icons.Location />
          <span>{location?.address || 'Не указано'}</span>
        </Flex>
      </ListCell>
      <ListCell style={{ maxWidth: 160 }} className={css.secondaryField}>
        <Flex direction="col" gap="1px">
          <span>{dateLabel}</span>
          <span style={{ color: 'gray' }}>{timeLabel}</span>
        </Flex>
      </ListCell>
      <ListCell style={{ maxWidth: 100 }}>{formatAmount(coast)}</ListCell>
    </ListItem>
  )
}

export default ProjectsListRow
