import React from 'react'
//@ts-ignore
import { useStyles } from 'floral'

import { useMediaContext } from 'App/MediaContext'
import Flex from '../Flex'
import { FloralProps } from '../floral.types'
import { H3 } from '../Heading'

interface CardProps extends FloralProps {
  icon?: React.ReactNode
  title?: React.ReactNode
  description?: React.ReactNode
  children?: React.ReactNode
  actions?: React.ReactNode
  kind?: 'info' | 'error' | 'success' | 'secondary' | 'outline'
  withShadow?: boolean
  className?: string
}

const colors = {
  info: 'var(--color-accent-back)',
  secondary: 'white',
  error: 'var(--color-red-back)',
  success: 'var(--color-green-back)',
  primary: 'white',
  outline: 'transparent',
}

const cardStyles = (props: CardProps) => {
  let padding = '1.325rem'
  let root: React.CSSProperties = {
    padding: padding,
    background: colors[props.kind!],
    // maxWidth: 600,
    borderRadius: '.75rem',
  }
  let title: React.CSSProperties = { flexGrow: 1 }

  let description: React.CSSProperties = { color: 'var(--color-secondary)' }
  // if (props.kind === 'error') {
  //   title.color = '#f24070' // contrast ratio 3:1
  // }

  if (props.kind === 'outline') {
    root.border = '1px solid #D3DEE8'
  }

  if (props.withShadow) {
    root.boxShadow = 'var(--shadow-card)'
  }

  return { root, title, description }
}

export const Card = (props: CardProps) => {
  let { icon, title, description, children, actions } = props
  let styles = useStyles(cardStyles, [props])
  let { isMobile } = useMediaContext()
  return (
    <Flex
      style={styles.root}
      className={props.className}
      direction="column"
      gap="var(--gap-s)"
    >
      {(title || icon || description) && (
        <Flex gap="1rem" align={isMobile ? 'start' : 'center'} justify="start">
          {icon && <div>{icon}</div>}
          <Flex direction="column" style={{ width: '100%' }}>
            {title && <H3 style={styles.title}>{title}</H3>}
            {description && <div style={styles.description}>{description}</div>}
          </Flex>
        </Flex>
      )}
      {children && <div>{children}</div>}
      {actions &&
        (isMobile ? (
          <Flex direction="column" gap="var(--gap-s)" align="stretch">
            {actions}
          </Flex>
        ) : (
          <Flex
            gap="2rem"
            align="center"
            style={{ alignSelf: 'start', marginTop: '1rem' }}
          >
            {actions}
          </Flex>
        ))}
    </Flex>
  )
}

Card.defaultProps = {
  kind: 'info',
  withShadow: false,
}

export default Card
