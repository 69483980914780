import React from 'react'

import { ConnectToPlatformStore } from '../../store/ConnectToPlatformStore'


import StatusOptionButton from '../StatusOptionButton'

import css from './styles.module.css'
import { useUserStore } from 'stores/context'
import { useHistory } from 'react-router-dom'
import { H1, Flex, Button, icons, useTheme, Link } from 'ui'

const JOIN_AS_SELF_EMPLOYED = 'self_employed'
const JOIN_AS_INDIVIDUAL_ENTREPRENEUR = 'individual_entrepreneur'
const JOIN_AS_NON_RESIDENT = 'non_resident'
const JOIN_AS_INDIVIDUAL = 'gph'

const statusOrder = [
  JOIN_AS_SELF_EMPLOYED,
  JOIN_AS_INDIVIDUAL_ENTREPRENEUR,
  JOIN_AS_INDIVIDUAL,
  JOIN_AS_NON_RESIDENT,
]

type TCustomersStatusOption = {
  title: React.ReactNode
  description?: React.ReactNode
  event: string
  kind?: any
}
type TCustomersStatus = {
  [key: string]: Array<TCustomersStatusOption> | TCustomersStatusOption
}

const customersStatus: TCustomersStatus = {
  [JOIN_AS_SELF_EMPLOYED]: [
    {
      title: <>Я&nbsp;самозанятый</>,
      description: <>У&nbsp;вас есть действующий статус самозанятого</>,
      event: 'registration_is_self_employed',
      kind: JOIN_AS_SELF_EMPLOYED,
    },
    {
      title: 'Хочу стать самозанятым',
      description: (
        <>
          Будет пройдена регистрация в&nbsp;качестве самозанятого. Займет 10&nbsp;минут.
        </>
      ),
      event: 'registration_wanna_be_self_employed',
      kind: 'register_self_employed',
    },
  ],
  [JOIN_AS_INDIVIDUAL_ENTREPRENEUR]: {
    title: <>Я&nbsp;индивидуальный предприниматель</>,
    description: <>У&nbsp;вас есть действующий статус индивидуального предпринимателя</>,
    event: 'registration_is_ip',
    kind: JOIN_AS_INDIVIDUAL_ENTREPRENEUR,
  },
  [JOIN_AS_INDIVIDUAL]: {
    title: <>Я&nbsp;физическое лицо (договор ГПХ)</>,
    description: (
      <>
        Вы&nbsp;будете работать в&nbsp;качестве физ.лица по&nbsp;договору
        гражданско-правового характера
      </>
    ),
    event: 'registration_gph',
    kind: JOIN_AS_INDIVIDUAL,
  },
  [JOIN_AS_NON_RESIDENT]: {
    title: <>Я&nbsp;нерезидент&nbsp;РФ (договор ГПХ)</>,
    description: (
      <>
        Вы&nbsp;живете в&nbsp;другой стране и&nbsp;будете работать в&nbsp;качестве
        физ.лица по&nbsp;договору гражданско-правового характера
      </>
    ),
    event: 'registration_non_resident',
    kind: 'register_non_resident',
  },
}

const SelectKindStep = ({ store }: { store: ConnectToPlatformStore }) => {
  const { config } = useTheme()
  const history = useHistory()
  const currentUser = useUserStore()

  const company = store.registration.data.company
  const joinOptions: any = store.registration.data.join_options
  const start_url = store.registration.data.start_url || '/login'

  const availableOptions: Array<string> = Object.keys(joinOptions).filter(
    (item) => joinOptions[item] === true
  )

  return (
    <div className="konsol-ui">
      <Flex direction="col" gap="24px" align="start">
        <div>
          <H1>Выберите ваш текущий статус</H1>
          <div
            className={css.description}
            dangerouslySetInnerHTML={{
              __html: config.text.pages.registration.description.replace(
                '%COMPANY%',
                company ? `с&nbsp;<b>${company.name}</b> ` : ''
              ),
            }}
          />
        </div>
        <Flex direction="col" style={{ width: '100%' }} align="stretch">
          {statusOrder.map((status) => {
            if (!availableOptions.includes(status)) return null

            const option = customersStatus[status]
            const optionIsArray = Array.isArray(option)

            if (!option) return null

            if (optionIsArray) {
              return option.map(({ title, description, event, kind }) => {
                return (
                  <StatusOptionButton
                    title={title}
                    description={description}
                    event={event}
                    onClick={() => {
                      store.selectKind(kind)
                    }}
                  />
                )
              })
            }

            return (
              <StatusOptionButton
                title={option.title}
                description={option.description}
                event={option.event}
                onClick={() => {
                  store.selectKind(option.kind)
                }}
              />
            )
          })}
        </Flex>
        <Flex gap="10px" justify="space-between" wrap style={{ width: '100%' }}>
          <Button
            variant="simple"
            onClick={() => {
              currentUser.logout(false)
              history.push(start_url)
            }}
            style={{ padding: '0 20px 0 14px' }}
          >
            <Flex direction="row" gap="8px" align="center">
              <icons.ArrowLeft />
              <div>Войти с&nbsp;другим номером телефона</div>
            </Flex>
          </Button>
          
          <div style={{ marginTop: '1rem' }}>
            Please contact us at <Link href='mailto:hello@restaff.pro' target='_blank'>hello@restaff.pro</Link> if you have any issues
          </div>
        </Flex>
      </Flex>
    </div>
  )
}

export default SelectKindStep
