import React, { forwardRef } from 'react'

import { useStyles, omitStyleProps, StyleProps, StylesMap } from './styled'

type FlexDirection = 'row' | 'col'

type FlexAlign = 'normal' | 'start' | 'end' | 'center' | 'stretch' | 'baseline'

type FlexJustify =
  | 'normal'
  | 'start'
  | 'end'
  | 'center'
  | 'space-between'
  | 'space-around'
  | 'space-evenly'

interface FlexProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'wrap'>,
    StyleProps<[FlexProps]> {
  children: React.ReactNode
  direction?: FlexDirection
  wrap?: boolean
  align?: FlexAlign
  justify?: FlexJustify
  gap?: string | number
  className?: string
}

const defaultProps = {
  direction: 'row' as FlexDirection,
  wrap: false,
  align: 'normal' as FlexAlign,
  justify: 'normal' as FlexJustify,
  gap: 0,
}

type FlexPropsWithDefault = FlexProps & typeof defaultProps

const justifyMap: any = {
  end: 'flex-end',
  start: 'flex-start',
}

const directionMap: any = {
  row: 'row',
  col: 'column',
}

const flexStyles = ({
  direction,
  wrap,
  align,
  justify,
  gap,
}: FlexPropsWithDefault): StylesMap => {
  const root: React.CSSProperties = {
    display: 'flex',
    flexDirection: directionMap[direction],
    flexWrap: wrap ? 'wrap' : 'nowrap',
    alignItems: align,
    justifyContent: justifyMap[justify!] || justify,
    gap,
  }
  return { root }
}

const Flex = forwardRef<HTMLDivElement, FlexProps>((_props: FlexProps, ref) => {
  const props = _props as FlexPropsWithDefault
  const {
    direction: _direction,
    wrap: _wrap,
    align: _align,
    justify: _justify,
    gap: _gap,
    className,
    children,
    ...elemProps
  } = omitStyleProps(props)
  const computedStyles = useStyles(flexStyles, [props])
  return (
    <div ref={ref} style={computedStyles.root} className={className} {...elemProps}>
      {children}
    </div>
  )
})

Flex.defaultProps = defaultProps

const Col = forwardRef<HTMLDivElement, FlexProps>((props: FlexProps, ref) => (
  <Flex {...props} ref={ref} direction="col" />
))

const Row = forwardRef<HTMLDivElement, FlexProps>((props: FlexProps, ref) => (
  <Flex {...props} ref={ref} direction="row" />
))

export { Flex, Col, Row }
