import React, { useMemo } from 'react'
import { observer } from 'mobx-react-lite'

import type { PageProps } from 'app.types'
import { fetchApi } from 'api'
import { useUserStore } from 'stores/context'

const AdminLoginPage = observer(({ match }: PageProps) => {
  let currentUser = useUserStore()
  useMemo(() => {
    currentUser.logout()
    fetchApi({
      method: 'POST',
      url: 'authorizations/admin_login',
      data: match.params,
    }).then((response) => {
      currentUser.login({
        source: 'admin',
        user: response,
        companyId: response.companies[0]?.id,
      })
    })
  }, [])
  return <div />
})

export default AdminLoginPage
