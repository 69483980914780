import { rest } from 'msw'

import { json } from './helpers'

const contract = {
  id: 75448,
  file_url: 'https://via.placeholder.com/350x150',
  signed_by_contractor: false,
  signed_by_legal_entity: false,
  status: 'created',
  // status: 'signed_by_contractor',
  require_sign: 'contractor',
  actions: [],
  date: '2021-03-24',
  title: 'Название договора',
  events: [
    {
      id: 1,
      kind: 'upload_by_legal_entity',
      whodunnit: {
        avatar: null,
        name: 'Вальдемир Заказчиков',
      },
      created_at: '2020-08-15T13:35:02.081Z',
    },
  ],
  kind: 'contract',
  created_date: '2021-10-20',
}

const contract_from_template = {
  ...contract,
  template: { id: 1, name: 'Обычный договор' },
}

export default [
  rest.get('/api/company/contracts/:id', json(contract)),
  rest.patch('/api/company/contracts/:id', json({})),

  rest.post('/api/company/documents/from_template', json(contract_from_template)),
  rest.post('/api/company/documents/', json(contract)),
  // rest.post('/api/company/documents/', json(document)),
]

export { contract }
