import React from 'react'

import { DivProps } from 'app.types'
import { formatAmount } from 'utils/amount'
import { Amount as AmountType } from 'entities'

interface AmountProps extends DivProps {
  value: AmountType
  separator?: string
  scale?: number
}

const Amount = ({ value, separator = ',', scale = 2, ...props }: AmountProps) => (
  <span
    {...props}
    style={{ fontVariantNumeric: 'tabular-nums', whiteSpace: 'nowrap', ...props.style }}
  >
    {value || value === 0 ? formatAmount(value, scale, separator) : ''}
  </span>
)

export default Amount
