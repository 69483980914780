import React from 'react'
import { makeAutoObservable } from 'mobx'
import { fromPromise } from 'mobx-utils'

import { DocumentEntity } from 'entities'
import { MobxApi, initialActionState, Data, Entity } from 'mobx/mobx'
import { fetchApi } from 'api'
import numForm from 'utils/numForm'
import SignDialogStore from 'stores/SignDialogStore'

import { useStateToast } from 'components/ui'
import { documentWordForms } from 'components/documents/statuses'
import DocumentsForSignList from 'components/documents/DocumentsForSignList'

interface DocumentsMultiActionsStoreProps {
  api: MobxApi
  signDialog: SignDialogStore
  toast: ReturnType<typeof useStateToast>
}

class DocumentsMultiActionsStore {
  constructor(props: DocumentsMultiActionsStoreProps) {
    Object.assign(this, props)

    makeAutoObservable(this)
  }

  api!: MobxApi
  signDialog!: SignDialogStore
  toast!: ReturnType<typeof useStateToast>
  codeForSignState: any = initialActionState

  openSignDialog(documents: Array<Entity<DocumentEntity>>) {
    this.codeForSignState = fromPromise(
      fetchApi({
        method: 'POST',
        url: 'documents/code_for_multi_sign',
        data: {
          ids: documents.map((doc) => doc.id),
        },
      })
    )
    this.signDialog.open({
      title: 'Подписание документов',
      text: `подписать ${numForm(documents.length, documentWordForms)}`,
      onSign: this.sign.bind(this),
      children: () => <DocumentsForSignList documents={documents} />,
    })
  }

  sign(payload: Object | undefined) {
    let action = fromPromise(
      fetchApi({
        method: 'PUT',
        url: 'documents/multi_sign',
        data: {
          sign_id: this.codeForSignState.value.sign_id,
          ...payload,
        },
      })
    )
    action.then(
      (data: Data) => {
        data.map((d: Data) => this.api.entities.documents.get(d.id)?.setData(d))
        this.toast.success({ title: 'Документы подписаны' })
      },
      (error: any) =>
        this.toast.error({
          title: 'Не удалось подписать',
          description: error.message,
        })
    )
    return action
  }

  openConfirmDeleteDialog() {}

  delete(payload: Object) {
    fromPromise(fetchApi({ method: 'PUT', url: 'documents/multi_delete', data: payload }))
  }
}

export default DocumentsMultiActionsStore
