import { setupWorker } from 'msw'

import authHandlers from './auth'
import userHandlers from './user'
import badgesHandlers from './badges'
import notificationsHandlers from './notifications'
import registrationHandlers from './registration'
import companiesHandlers from './companies'
import documentsHandlers from './documents'
import invitesHandlers from './invites'
import customFieldsHandlers from './customFields'
import contractorsHandlers from './contractors'
import contractsHandlers from './contracts'
import taxesHandlers from './taxes'
import subtasksHandlers from './subtasks'
import tasksHandlers from './tasks'
import bundlesHandlers from './bundles'
import dashboardHandlers from './dashboard'
import segmentsHandlers from './segments'
import offersHandlers from './offers'
import withdrawalsHandlers from './withdrawals'
import onboardingScenariosHandlers from './onboardingScenarios'
import templatesHandlers from './templates'
import scenarioProjectsHandlers from './scenario_projects'
import scenarioTasksHandlers from './scenario_tasks'
import contractorRiskProfilesHandlers from './contractorRiskProfiles'

const worker = setupWorker(
  ...authHandlers,
  ...userHandlers,
  ...badgesHandlers,
  ...notificationsHandlers,
  ...registrationHandlers,
  ...companiesHandlers,
  ...documentsHandlers,
  ...invitesHandlers,
  ...customFieldsHandlers,
  ...contractorsHandlers,
  ...contractsHandlers,
  ...taxesHandlers,
  ...subtasksHandlers,
  ...tasksHandlers,
  ...bundlesHandlers,
  ...dashboardHandlers,
  ...segmentsHandlers,
  ...offersHandlers,
  ...withdrawalsHandlers,
  ...scenarioTasksHandlers,
  ...onboardingScenariosHandlers,
  ...templatesHandlers,
  ...scenarioProjectsHandlers,
  ...contractorRiskProfilesHandlers,
)

export default worker
