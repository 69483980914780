import React, { useState } from 'react'
import {
  makeAutoObservable,
  action as mobxAction
} from 'mobx'
import { observer, Observer } from 'mobx-react-lite'
import { fromPromise } from 'mobx-utils'
import Decimal from 'decimal.js'
import { Channel } from 'pusher-js'

import { fetchApi } from 'api'
import { MobxApi, Entity, Data, makeInitialActionState, ActionState } from 'mobx/mobx'
import { Task, TaskEntity, TaskAction, Currency, Amount as AmountType } from 'entities'
import { useMediaContext } from 'App/MediaContext'

import UserStore from 'stores/UserStore'
import SignDialogStore from 'stores/SignDialogStore'

import numForm from 'utils/numForm'
import {
  // normalizeAmount,
  sumAmounts,
  currencySigns
} from 'utils/amount'
import { endash, thinsp } from 'utils/typo'

import { Amount } from 'components'
import { useStateToast, Input, Flex, Col, Modal, Button, H2 } from 'components/ui'
import NumberInput from 'components/NumberInput'
import SignInput from 'components/SignDialog/SignInput'
import ProgressBar from 'components/ui/ProgressBar'
import ToggleBanner from 'components/ToggleBanner'

import ModalTaskList from './ModalTaskList'

const taskWordForms = { one: 'задание', two: 'задания', many: 'заданий' }
const actWordForms = { one: 'задание', two: 'задания', many: 'заданий' }
const actWordFormsGenitive = { one: 'задания', two: 'задания', many: 'заданий' }

interface TaskMultiActionResult {
  sign_id: number
  completed: number
  total: number
  tasks?: Task[]
}

interface ExchangeRateDialogProps {
  action: TaskAction
  tasks: Entity<TaskEntity>[]
  currency: Currency
  exchangeRate: string
}

interface ActionInfo {
  commission?: string
  payment_amount?: string
  cbr_exchange_rate?: number
  payment_amounts?: { id: number; amount: AmountType }[]
}

interface ActionDialogProps {
  action: TaskAction
  tasks: Entity<TaskEntity>[]
  signId?: number
  payload?: any
  info: ActionInfo
  result?: TaskMultiActionResult
}

// const payActions = ['pay', 'autopay', 'mark_paid', 'mark_paid_with_receipt']
const suspendedActions = ['accept', 'pay', 'autopay']

interface Props {
  store: TasksMultiActionsStore
}

const ExchangeRateDialog = observer(({ store }: Props) => {
  let {
    tasks,
    currency,
    exchangeRate: initialExchangeRate,
  } = store.exchangeRateDialogProps!
  let [exchangeRate, setExchangeRate] = useState(parseFloat(initialExchangeRate))
  let foreignCurrencyAmount = sumAmounts(tasks.map((t) => t.data.act.amount))
  let convertedAmount = new Decimal(foreignCurrencyAmount.value)
    .mul(exchangeRate)
    .toFixed(2)
  const { isMobile } = useMediaContext()
  return (
    <Modal
      isOpen={true}
      onClose={() => {
        store.exchangeRateDialogIsOpen = false
      }}
      size={isMobile ? '97%' : 600}
    >
      <Col gap="var(--gap-m)">
        <H2>Курс валюты</H2>
        <div>Для оплаты актов в иностранной валюте нужно подтвердить курс валюты</div>
        <Col gap="var(--gap-s)" style={{ maxWidth: 400 }}>
          <Flex justify="space-between" align="baseline">
            <div>Курс валюты</div>
            <NumberInput
              value={String(exchangeRate)}
              onChange={(value) => setExchangeRate(Number(value!))}
              styles={{
                root: { width: 75 },
                input: { textAlign: 'right', paddingTop: 0, paddingBottom: 0 },
              }}
              allowDecimal={true}
            />
          </Flex>
          <Flex justify="space-between">
            <div>Сумма в иностранной валюте</div>
            <Amount value={foreignCurrencyAmount} />
          </Flex>
          <Flex justify="space-between">
            <div>Сумма в рублях</div>
            <Amount value={{ value: convertedAmount, currency: 'RUB' }} />
          </Flex>
        </Col>
        <div style={{ color: 'var(--color-secondary)' }}>
          Текущий курс ММВБ {endash} {initialExchangeRate}
          {thinsp}
          {currencySigns.RUB}/{currencySigns[currency]}
        </div>
        <Button
          isDisabled={exchangeRate === 0}
          onTap={() => store.confirmExchangeRate(exchangeRate.toFixed(2))}
        >
          Подтвердить
        </Button>
      </Col>
    </Modal>
  )
})

const AssignContractorModal = observer(({ store }: Props) => {
  let { tasks } = store.actionDialogProps!
  return (
    <ModalTaskList
      caption={<>Вы собираетесь принять {numForm(tasks.length, taskWordForms)}</>}
      tasks={tasks}
    />
  )
})

const SignTermsModal = observer(({ store }: Props) => {
  let { tasks } = store.actionDialogProps!
  return (
    <ModalTaskList
      caption={<>Вы собираетесь подписать {tasks.length} ТЗ</>}
      tasks={tasks}
    />
  )
})

const AcceptModal = observer(({ store }: Props) => {
  let { tasks, info } = store.actionDialogProps!

  const handleToggle = () => {
    store.actionPayload.forceTaskPayments = !store.actionPayload.forceTaskPayments
  }

  const banner = null
  //  store.currentUser.hasFeature('bundles_autopay') &&
  //  store.currentUser.hasAbility('tasks.autopay') ? (
  //    <ToggleBanner
  //      value={store.actionPayload.forceTaskPayments!}
  //      isDisabled={!!signId}
  //      primaryLabel="Оплатить автоматически после подписания исполнителем"
  //      secondaryLabel={`
  //      Автооплата произойдет после подписания акта с обеих сторон.
  //      Вы получите закрывающие документы и вам не нужно следить
  //      за подписанием акта
  //    `}
  //      onToggle={handleToggle}
  //      onPrimaryLabelClick={handleToggle}
  //      onSecondaryLabelClick={handleToggle}
  //    />
  //  ) : null

  return (
    <ModalTaskList
      caption={
        <>
          Вы собираетесь принять и оплатить {numForm(tasks.length, actWordForms)} на сумму{' '}
          <Amount value={info.payment_amount!} />
          {info!.cbr_exchange_rate && (
            <>
              . Исполнитель получит рубли по курсу {(info.cbr_exchange_rate*0.985).toFixed(2)} руб./евро.
              <br />
              *Оплата произойдёт автоматически после принятия задания и подтверждения оплаты через смс-код
            </>
          )}
        </>
      }
      banner={banner}
      tasks={tasks}
    />
  )
})

const PayModal = observer(({ store }: Props) => {
  let { tasks, info } = store.actionDialogProps!
  return (
    <ModalTaskList
      caption={
        <>
          Вы собираетесь оплатить {numForm(tasks.length, actWordForms)} на сумму{' '}
          <Amount value={info.payment_amount!} />
          {info!.commission && (
            <>
              . Комиссия составит <Amount value={info.commission} />.
            </>
          )}
        </>
      }
      tasks={tasks}
      amounts={info.payment_amounts}
    />
  )
})

const MarkPaidModal = observer(({ store }: Props) => {
  let { tasks, info } = store.actionDialogProps!
  return (
    <ModalTaskList
      caption={
        <>
          Вы собираетесь отметить оплаченными {numForm(tasks.length, actWordForms)} на
          сумму <Amount value={info.payment_amount!} />
        </>
      }
      tasks={tasks}
      amounts={info.payment_amounts}
    />
  )
})

const AutopayModal = observer(({ store }: Props) => {
  let { tasks, info } = store.actionDialogProps!
  return (
    <ModalTaskList
      caption={
        <>
          Вы собираетесь подтвердить автооплату{' '}
          {numForm(tasks.length, actWordFormsGenitive)} на сумму{' '}
          <Amount value={info!.payment_amount!} />.
          <br />
          Автооплата произойдет после подписания акта с обеих сторон. Вы получите
          закрывающие документы и вам не нужно следить за подписанием акта.
          {info!.commission && (
            <>
              <br />
              Комиссия составит <Amount value={info.commission} />.
            </>
          )}
        </>
      }
      tasks={tasks}
      amounts={info.payment_amounts}
    />
  )
})

const MarkPaidWithReceiptModal = observer(({ store }: Props) => {
  let { tasks, info } = store.actionDialogProps!
  return (
    <ModalTaskList
      caption={
        <>
          Вы собираетесь сформировать чеки и отметить оплаченными{' '}
          {numForm(tasks.length, actWordForms)} на сумму{' '}
          <Amount value={info.payment_amount!} />
        </>
      }
      tasks={tasks}
      amounts={info.payment_amounts}
    />
  )
})

const AnnulModal = observer(({ store }: Props) => {
  let { tasks } = store.actionDialogProps!
  return (
    <Col gap="var(--gap-m)">
      <Col gap="var(--gap-s)" style={{ maxWidth: 700 }}>
        <div style={{ fontSize: '1.25rem', fontWeight: 500 }}>
          Вы собираетесь аннулировать {numForm(tasks.length, actWordForms)}
        </div>
        <div>
          Если акты уже подписаны, то по ним будут сформированы соглашения об
          аннулировании и исполнители получат уведомления с предложением подписать
          соглашение. Неподписанные акты будут аннулированы в одностороннем порядке.
        </div>
      </Col>
      <div style={{ maxWidth: 700 }}>
        <div style={{ color: 'var(--color-secondary)' }}>Комментарий</div>
        <Input
          isMultiline={true}
          placeholder="Опишите причину аннулирования"
          value={store.actionPayload.annulComment!}
          onChange={(annulComment) => {
            store.actionPayload.annulComment = annulComment
          }}
        />
      </div>
      <ModalTaskList tasks={tasks} />
    </Col>
  )
})

const IsSuspendedModal = observer(({ store }: Props) => {
  const { tasks } = store.actionDialogProps!
  return (
    <ModalTaskList
      caption={
        <>
          Но вы можете подписать акты и провести оплату. Чтобы исключить их из списка на
          оплату вернитесь в раздел "Акты"
        </>
      }
      tasks={tasks.filter((t) => t.data.contractor?.data.is_suspended)}
    />
  )
})

interface DialogProps {
  title: string
  signText?: string
  render: (store: TasksMultiActionsStore) => React.ReactNode
}

const dialogProps: { [key in TaskAction]?: DialogProps } = {
  assign_contractor: {
    title: 'Создание заданий',
    signText: 'создать задания',
    render: (store) => <AssignContractorModal store={store} />,
  },
  sign_terms: {
    title: 'Подписание ТЗ',
    signText: 'подписать ТЗ',
    render: (store) => <SignTermsModal store={store} />,
  },
  accept: {
    title: 'Принять и оплатить задание',
    signText: 'принять задания',
    render: (store) => <AcceptModal store={store} />,
  },
  is_suspended: {
    title: 'Работа приостановлена с этими исполнителями',
    render: (store) => <IsSuspendedModal store={store} />,
  },
  pay: {
    title: 'Оплата актов',
    signText: 'оплатить акты',
    render: (store) => <PayModal store={store} />,
  },
  mark_paid: {
    title: 'Оплата актов',
    signText: 'отметить акты оплаченными',
    render: (store) => <MarkPaidModal store={store} />,
  },
  autopay: {
    title: 'Автооплатить',
    render: (store) => <AutopayModal store={store} />,
  },
  mark_paid_with_receipt: {
    title: 'Оплата актов',
    signText: 'сформировать чеки и отметить оплаченными',
    render: (store) => <MarkPaidWithReceiptModal store={store} />,
  },
  annul: {
    title: 'Аннулирование актов',
    signText: 'аннулировать акты',
    render: (store) => <AnnulModal store={store} />,
  },
}

const successMessages: { [key in TaskAction]?: string } = {
  assign_contractor: 'Черновики переведены в статус "Создано"',
  sign_terms: 'ТЗ подписаны',
  accept: 'Акты отправлены на оплату',
  pay: 'Акты отправлены на оплату',
  mark_paid: 'Акты отмечены оплаченными',
  autopay: 'Оплата актов одобрена',
  cancel_autopay: 'Одобрение оплаты отменено',
  mark_paid_with_receipt: 'Чеки сформированы и акты отмечены оплаченными',
  annul: 'Акты аннулированы',
  delete: 'Удаление выполнено',
  mark_paid_without_sms: 'Акты отмечены оплаченными',
}

const errorMessages: { [key in TaskAction]?: string } = {
  assign_contractor: 'Не удалось создать задания',
  sign_terms: 'Не удалось подписать ТЗ',
  accept: 'Не удалось принять задания',
  pay: 'Не удалось оплатить акты',
  mark_paid: 'Не удалось отметить акты оплаченными',
  autopay: 'Не удалось автооплатить',
  cancel_autopay: 'Не удалось отменить',
  mark_paid_with_receipt: 'Не удалось сформировать чеки и отметить акты оплаченными',
  annul: 'Не удалось аннулировать акты',
  delete: 'Не удалось удалить',
  mark_paid_without_sms: 'Не удалось отметить акты оплаченными',
}

export const actionMethods: { [key in TaskAction]?: string } = {
  assign_contractor: 'assign_contractor',
  sign_terms: 'sign_terms',
  accept: 'accept',
  autopay: 'autopay',
  cancel_autopay: 'cancel_autopay',
  pay: 'pay',
  mark_paid: 'pay',
  mark_paid_with_receipt: 'pay',
  annul: 'annul',
  delete: 'delete',
  is_suspended: 'is_suspended',
  mark_paid_without_sms: 'mark_paid',
}

const TaskMultiActionDialog = observer(({ store }: { store: TasksMultiActionsStore }) => {
  let { isMobile } = useMediaContext()

  let action = store.actionDialogProps!

  let { title, render, signText } = dialogProps[action.action]!
  let content = render(store)

  if (action.action === 'accept') {
    signText = store.actionPayload.forceTaskPayments
      ? 'Введите код из смс для подписания актов и подтверждения автооплаты'
      : 'Введите код из смс для подписания актов'
  } else if (action.action === 'autopay') {
    signText = 'Введите код из смс для подтверждения автооплаты'
  }

  let result
  if (action.result) {
    result = (
      <Flex gap="2rem" align="center" style={{ height: 70 }}>
        <ProgressBar
          style={{ width: 240 }}
          success={action.result.completed}
          total={action.result.total}
        />
        <div style={{ color: 'var(--color-secondary)' }}>
          Подождите, действие выполняется...
        </div>
      </Flex>
    )
  } else if (action.action === 'accept') {
    if (!store.actionDialogProps?.signId) {
      result = (
        <Button
          style={{ width: 240 }}
          onTap={() =>
            store.sendCode({
              autopay: store.actionPayload.forceTaskPayments,
            })
          }
          isLoading={store.sendCodeStateRef && !store.actionDialogProps?.signId}
        >
          Принять и&nbsp;оплатить
        </Button>
      )
    } else {
      result = (
        <SignInput skipTextPrefix onSign={store.onSign.bind(store)} text={signText!} />
      )
    }
  } else {
    result = (
      <SignInput
        skipTextPrefix={action.action === 'autopay'}
        onSign={store.onSign.bind(store)}
        text={signText!}
      />
    )
  }

  if (action.action === 'is_suspended' && store.suspendedContinueAction) {
    result = (
      <Flex justify="end" gap="20px">
        <Button design="normal" onTap={() => store.closeDialog()}>
          Отмена
        </Button>
        <Button
          onTap={() =>
            store.openActionDialog(store.suspendedContinueAction, action.tasks)
          }
        >
          Продолжить
        </Button>
      </Flex>
    )
  }

  return (
    <Modal
      size={isMobile ? '97%' : 1000}
      isOpen={true}
      onClose={() => store.closeDialog()}
    >
      <Col align="stretch" gap="var(--gap-m)" style={{ height: '100%' }}>
        <H2>{title}</H2>
        {content}
        {result}
      </Col>
    </Modal>
  )
})

interface ActionsPayload {
  forceTaskPayments?: boolean
  annulComment?: string
}

interface TaskMultiActionStoreProps {
  currentUser: UserStore
  api: MobxApi
  toast: ReturnType<typeof useStateToast>
  signDialog: SignDialogStore
  onCompleteAction?: () => void
  channel?: Channel
}

class TasksMultiActionsStore {
  constructor({
    currentUser,
    api,
    toast,

    onCompleteAction,
    channel,
  }: TaskMultiActionStoreProps) {
    this.currentUser = currentUser
    this.api = api
    this.toast = toast
    this.onCompleteAction = onCompleteAction
    this.suspendedContinueAction = ''

    let callback = (result: TaskMultiActionResult) => {
      if (this.actionDialogIsOpen) {
        let { signId, action } = this.actionDialogProps!
        if (signId === result.sign_id) this.handleActionResult(action, result)
      }
    }
    channel?.bind('multi-action-result', callback)
    this.dispose = () => channel?.unbind('multi-action-result', callback)

    makeAutoObservable(this, { onCompleteAction: false })
  }

  currentUser: UserStore
  api: MobxApi
  onCompleteAction?: () => void
  toast: ReturnType<typeof useStateToast>
  dispose: () => void
  suspendedContinueAction: any

  // вызов экшена без диалога
  multiAction(action: TaskAction, tasks: Entity<TaskEntity>[]) {
    this.baseMultiAction(action, { ids: tasks.map((t) => t.id) })
  }

  // вызов экшена с диалогом
  // openDialog() {
  // eslint-disable-next-line
  openDialog(action: TaskAction, tasks: Entity<TaskEntity>[]) {
    // let currency = normalizeAmount(tasks[0].data.act.amount).currency
    // if (payActions.includes(action) && currency !== 'RUB') {
    //   this.openExchangeRateDialog(currency, action, tasks)
    // } else {
      this.openActionDialog(action, tasks)
    // }
  }

  exchangeRateDialogIsOpen = false
  exchangeRateDialogProps?: ExchangeRateDialogProps

  openExchangeRateDialog(
    currency: Currency,
    action: TaskAction,
    tasks: Entity<TaskEntity>[]
  ) {
    let fetchCurrencyState = fromPromise(
      fetchApi({ url: 'exchange_rates', data: { currency } })
    )
    fetchCurrencyState.then(
      mobxAction(({ value }) => {
        this.exchangeRateDialogIsOpen = true
        this.exchangeRateDialogProps = { currency, exchangeRate: value, tasks, action }
      })
    )
  }

  confirmExchangeRate(exchangeRate: string) {
    this.exchangeRateDialogIsOpen = false
    let { tasks, action } = this.exchangeRateDialogProps!
    this.openActionDialog(action, tasks, { exchange_rate: exchangeRate })
  }

  sendCodeStateRef?: ActionState<Data>

  actionState = makeInitialActionState<TaskMultiActionResult>()
  actionDialogIsOpen = false
  actionDialogProps?: ActionDialogProps
  actionPayload: ActionsPayload = {}

  checkSuspendedStep(action: TaskAction, tasks: Entity<TaskEntity>[]) {
    if (!this.suspendedContinueAction) {
      // экраны на которых нужно показываеть шаг с приостановленными исполнителями
      if (suspendedActions.includes(action)) {
        const isSuspendedConstractors = tasks.find(
          (t) => t.data.contractor?.data.is_suspended
        )
        if (isSuspendedConstractors) {
          this.actionDialogProps = {
            action: 'is_suspended',
            tasks,
            info: {},
          }
          this.suspendedContinueAction = action
        }
      }
    }
  }

  openActionDialog(action: TaskAction, tasks: Entity<TaskEntity>[], payload?: any) {
    this.actionDialogProps = {
      action,
      tasks,
      info: {},
      payload,
    }

    // проверяем приостановленных исполнителей и показываем экран, если они есть
    // this.checkSuspendedStep(action, tasks)

    if (action !== 'accept') {
      this.sendCode().then(
        mobxAction(() => {
          this.actionDialogIsOpen = true
        })
      )
    } else {
      this.actionDialogIsOpen = true
    }
  }

  sendCode(flags?: any) {
    const { tasks, action, payload } = this.actionDialogProps!
    this.sendCodeStateRef = fromPromise(
      fetchApi({
        method: 'POST',
        url: `tasks/code_for_multi_${actionMethods[action]}`,
        data: { ids: tasks.map((t) => t.id), ...payload, ...flags },
      })
    )
    return this.sendCodeStateRef.then(
      mobxAction(({ action_info, sign_id }) => {
        this.actionState = makeInitialActionState<TaskMultiActionResult>()
        this.actionDialogProps = {
          action,
          tasks,
          signId: sign_id,
          info: action_info,
        }
      }),
      (e) => {
        this.toast.error({ title: 'Ошибка', description: e.message })
        this.closeDialog()
      }
    )
  }

  closeDialog() {
    this.actionDialogIsOpen = false
    this.actionDialogProps = undefined
    this.sendCodeStateRef = undefined
    this.actionPayload = {}
    this.suspendedContinueAction = ''
  }

  onSign(signPayload: Object | undefined) {
    const { action, signId } = this.actionDialogProps!
    let payload: any = { sign_id: signId, ...signPayload }
    if (action === 'accept') {
      payload.autopay = this.actionPayload.forceTaskPayments
    }
    if (action === 'annul') {
      payload.comment = this.actionPayload.annulComment
    }
    return this.baseMultiAction(action, payload)
  }

  baseMultiAction(action: TaskAction, payload: Object) {
    this.actionState = fromPromise(
      fetchApi({
        method: action === 'delete' ? 'DELETE' : 'POST',
        url: `tasks/multi_${actionMethods[action]}`,
        data: payload,
      })
    ) as ActionState<TaskMultiActionResult>
    this.actionState.then(
      (result: TaskMultiActionResult) => {
        this.handleActionResult(action, result)
        this.onCompleteAction && this.onCompleteAction()
      },
      (error: any) =>
        this.toast.error({ title: errorMessages[action], description: error.message })
    )
    return this.actionState
  }

  handleActionResult(action: TaskAction, result: TaskMultiActionResult) {
    result.tasks?.forEach((task: Data) =>
      this.api.entities.tasks?.get(task.id)?.setData(task)
    )
    if (this.actionDialogIsOpen) this.actionDialogProps!.result = result
    if (result.total === result.completed) {
      this.toast.success({ title: successMessages[action] })
      this.closeDialog()
    }
  }

  render() {
    return (
      <Observer>
        {() => (
          <>
            {this.exchangeRateDialogIsOpen && <ExchangeRateDialog store={this} />}
            {this.actionDialogIsOpen && <TaskMultiActionDialog store={this} />}
          </>
        )}
      </Observer>
    )
  }
}

export default TasksMultiActionsStore
