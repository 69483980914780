import { reaction, makeAutoObservable } from 'mobx'

import { ActionState, initialActionState, Data } from 'mobx/mobx'

type AutoSaveFn<T> = (
  value: T,
  options: { abortSignal?: AbortSignal }
) => ActionState<Data>

interface AutoSaveStoreProps<T> {
  initialValue?: T
  saveFn: AutoSaveFn<T>
  delay?: number
}

class AutoSaveStore<T> {
  constructor({ initialValue, saveFn, delay = 0 }: AutoSaveStoreProps<T>) {
    this.value = initialValue!
    this.saveFn = saveFn

    let disposeInvalidate = reaction(
      () => this.value,
      () => {
        this.status = 'saving'
      }
    )
    let disposeSave = reaction(
      () => this.value,
      (value) => {
        this.abortController?.abort()
        if (window.AbortController) this.abortController = new AbortController()
        this.saveFn(value, { abortSignal: this.abortController?.signal }).then(
          () => {
            this.status = 'saved'
          },
          () => {
            this.status = 'error'
          }
        )
      },
      { delay }
    )
    this.dispose = () => {
      disposeInvalidate()
      disposeSave()
    }

    makeAutoObservable(this)
  }

  dispose: () => void
  saveFn: AutoSaveFn<T>
  abortController?: AbortController
  value!: T
  status: 'initial' | 'saving' | 'saved' | 'error' = 'initial'
  saveState = initialActionState
}

export default AutoSaveStore
