import React from 'react'

import { useUserStore } from 'stores/context'
import { observer } from 'mobx-react-lite'

import Banner from 'components/Banner'

const ADMIN_BANNER_BACKGROUND = 'rgba(255, 248, 215, 1)'
const ADMIN_BANNER_COLOR = 'var(--color-primary)'

export const AdminLoginBanner = observer(() => {
  const currentUser = useUserStore()
  const isOpen = currentUser?.user!.data.flags.is_admin_user_login
  return isOpen ? (
    <Banner
      icon="danger"
      title="Вы находитесь в режиме просмотра личного кабинета"
      style={{
        background: ADMIN_BANNER_BACKGROUND,
        color: ADMIN_BANNER_COLOR,
      }}
    />
  ) : null
})

export default AdminLoginBanner
