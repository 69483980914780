import React from 'react'

import { makeAutoObservable, runInAction } from 'mobx'
import { Time } from '@internationalized/date'

//@ts-ignore
import { FormStore } from 'shadowform'

import { createContext } from 'react'
import { useContext } from 'react'

import { useLocalObservable } from 'mobx-react-lite'
import { useStateToast } from 'components/ui'

import { PageProps } from 'app.types'
import MobxApi from 'mobx/MobxApi'

import { debounce } from 'lodash'
import { fetchApi } from 'api'
import { ScenarioProjectEntityWrite } from 'entities'
import { parseDate, serializeDate, serializeTime } from 'utils/datetime'

interface IStaticFormParams {
  initialValues?: Record<string, any>
  onChange: () => void
}

const createStaticForm = ({ initialValues = {}, onChange }: IStaticFormParams) => {
  const formStore = new FormStore({
    initialValues: {
      id: initialValues.id || null,
      tasksCount: initialValues.tasks_count || 0,
      totalAmount: initialValues.total_amount || 0,
      name: initialValues.name || '',
      comment: initialValues.comment || '',
      location: initialValues.location || '',
      periodFrom: initialValues.periodFrom || null,
      periodTo: initialValues.periodTo || null,
      timeFrom: initialValues.timeFrom
        ? new Time(
            parseInt(initialValues.timeFrom.split(':')[0]),
            parseInt(initialValues.timeFrom.split(':')[1])
          )
        : null,
      timeTo: initialValues.timeTo
        ? new Time(
            parseInt(initialValues.timeTo.split(':')[0]),
            parseInt(initialValues.timeTo.split(':')[1])
          )
        : null,
    },
    fields: {
      id: {},
      tasksCount: {},
      totalAmount: {},
      name: {},
      location: {},
      periodFrom: {},
      periodTo: {},
      timeFrom: {},
      timeTo: {},
      comment: {},
    },
  })

  const handleChange = debounce(() => onChange(), 1_000)

  formStore.fields.comment.on('change', handleChange)
  formStore.fields.location.on('change', handleChange)
  formStore.fields.name.on('change', handleChange)
  formStore.fields.periodFrom.on('change', handleChange)
  formStore.fields.periodTo.on('change', handleChange)
  formStore.fields.timeFrom.on('change', handleChange)
  formStore.fields.timeTo.on('change', handleChange)

  return formStore
}

class ProjectItemStore {
  tasks: any = []

  saveTask?: Promise<void>

  constructor(
    readonly projectId: number,
    readonly pageProps: PageProps,
    readonly mobxApi: MobxApi,
    readonly toast: ReturnType<typeof useStateToast>
  ) {
    makeAutoObservable(this)
  }

  closeModal = async () => {
    if (!this.saveTask) {
      this.handleChange()
    }
    await this.saveTask
    this.pageProps.history.push('/projects')
  }

  getFormValues = () => ({
    comment: this.staticForm.fields.comment.value,
    location: this.staticForm.fields.location.value,
    name: this.staticForm.fields.name.value,
    periodFrom: this.staticForm.fields.periodFrom.value,
    periodTo: this.staticForm.fields.periodTo.value,
    timeFrom: this.staticForm.fields.timeFrom.value,
    timeTo: this.staticForm.fields.timeTo.value,
    tasksCount: this.staticForm.fields.tasksCount.value,
    totalAmount: this.staticForm.fields.totalAmount.value,
    id: this.staticForm.fields.id.value,
  })

  private saveChanges = async (payload: ScenarioProjectEntityWrite) => {
    try {
      this.mobxApi.entities['scenario_projects'].get(payload.id)?.setData(payload)
      await fetchApi({
        url: `scenario_projects/${this.projectId}`,
        method: 'PUT',
        data: payload,
      })
    } catch (e: any) {
      this.toast.error({
        title: 'Произошла ошибка при сохранении проекта',
        description: e.message,
      })
    }
  }

  staticForm?: FormStore

  private handleChange = () => {
    let {
      comment,
      location,
      name,
      periodFrom,
      periodTo,
      timeFrom,
      timeTo,
      tasksCount,
      totalAmount,
      id,
    } = this.getFormValues()
    const since_date = periodFrom ? serializeDate(periodFrom) : null
    const upto_date = (periodTo ? serializeDate(periodTo) : null) || since_date
    const saveTask = this.saveChanges({
      id,
      comment,
      location:
        typeof location === 'string'
          ? {
              address: location,
              lat: 0,
              lng: 0,
            }
          : {
              address: location.value || '',
              lat: location.data?.geo_lat || 0,
              lng: location.data?.geo_lon || 0,
            },
      since_date,
      upto_date,
      since_time: timeFrom ? serializeTime(timeFrom) : null,
      upto_time: timeTo ? serializeTime(timeTo) : null,
      tasks_count: tasksCount,
      total_amount: totalAmount,
      title: name,
    })
    this.saveTask = saveTask
    return saveTask
  }

  public init = async () => {
    let initialValues: any = {}
    try {
      Object.assign(
        initialValues,
        await fetchApi({
          url: `scenario_projects/${this.projectId}`,
          method: 'GET',
        })
      )
    } catch (e: any) {
      this.toast.error({
        title: 'Произошла ошибка при загрузке проекта',
        description: e.message,
      })
      return
    }
    runInAction(() => {
      this.tasks = initialValues.tasks
      this.staticForm = createStaticForm({
        initialValues: {
          comment: initialValues.comment,
          location: initialValues.location?.address,
          name: initialValues.title,
          periodFrom: initialValues.since_date
            ? parseDate(initialValues.since_date).toDate()
            : null,
          periodTo: initialValues.upto_date
            ? parseDate(initialValues.upto_date).toDate()
            : null,
          timeFrom: initialValues.since_time,
          timeTo: initialValues.upto_time,
          tasksCount: initialValues.task_count,
          totalAmount: initialValues.task_amount,
          id: initialValues.id,
        },
        onChange: this.handleChange,
      })
    })
  }
}

const ProjectItemContext = createContext<ProjectItemStore>(null as never)

interface IProjectItemStoreProviderProps extends DialogProps {
  pageProps: PageProps
  mobxApi: MobxApi
  children: React.ReactNode
}

export interface DialogProps {
  projectId: number
}

export const ProjectItemStoreProvider = ({
  pageProps,
  mobxApi,
  children,
  projectId,
}: IProjectItemStoreProviderProps) => {
  const toast = useStateToast()
  const store = useLocalObservable(
    () => new ProjectItemStore(projectId, pageProps, mobxApi, toast)
  )

  return (
    <ProjectItemContext.Provider value={store}>{children}</ProjectItemContext.Provider>
  )
}

export const useProjectItemStore = () => useContext(ProjectItemContext)

export default useProjectItemStore
