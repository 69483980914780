const toFormData = (data: Object) => {
  let formData = new FormData()
  Object.entries(data).forEach(([key, value]) => {
    if (value === null || value === undefined) return
    let formValue = typeof value === 'boolean' ? String(value) : value
    formData.append(key, formValue)
  })
  return formData
}

export default toFormData
