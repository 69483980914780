import React, { useRef } from 'react'

const useFileDialog = (
  onSelect: (...files: File[]) => void,
  { accept }: { accept?: string } = {}
) => {
  let fileInputRef = useRef<HTMLInputElement>(null)
  let onChange = () => {
    let files = fileInputRef.current?.files
    if (!files?.length) return
    onSelect(...Array.from(files))
    if (fileInputRef.current) fileInputRef.current.value = ''
  }
  let render = () => (
    <input
      type="file"
      style={{ display: 'none' }}
      onChange={onChange}
      ref={fileInputRef}
      accept={accept}
    />
  )
  let open = () => fileInputRef.current?.click()
  return { render, open }
}

export default useFileDialog
