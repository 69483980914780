import React, { useMemo, useRef, useEffect, useImperativeHandle, useState } from 'react'
import { makeAutoObservable, reaction } from 'mobx'
import { observer } from 'mobx-react-lite'
import { fromPromise } from 'mobx-utils'
// @ts-ignore
import { FormStore } from 'shadowform'

import { fetchApi } from 'api'
import { Collection, Entity, initialActionState } from 'mobx/mobx'
import { CustomFieldEntity, CustomFieldOption, CustomFieldKind } from 'entities'

import {
  List,
  ListHeader,
  ListItem,
  ListCell,
  IconButton,
  Flex,
  Col,
  Modal,
  H2,
  BaseButton,
  Button,
  Field,
  FormRow,
  Input,
  SelectInput,
  useStateToast,
  ConfirmDialog,
  useConfirmDialog,
} from 'components/ui'
import numForm from 'utils/numForm'

import { ReactComponent as AddIcon } from '@material-design-icons/svg/round/add.svg'
import { ReactComponent as ClearIcon } from '@material-design-icons/svg/round/clear.svg'
import { ReactComponent as DeleteIcon } from '@material-design-icons/svg/round/delete.svg'
import { ReactComponent as EditIcon } from '@material-design-icons/svg/round/edit.svg'

const customFieldNames = {
  text: 'Текст',
  number: 'Число',
  date: 'Дата',
  checkbox: 'Флажок',
  dropdown: 'Значение из списка',
}

interface CustomFieldsStoreProps {
  fields: Collection<CustomFieldEntity>
  toast: ReturnType<typeof useStateToast>
}

class CustomFieldsStore {
  constructor({ fields, toast }: CustomFieldsStoreProps) {
    this.fields = fields
    this.toast = toast
    makeAutoObservable(this)
  }

  fields: Collection<CustomFieldEntity>
  toast: ReturnType<typeof useStateToast>
  modalIsOpen = false
  view: 'create' | 'edit' | 'list' = 'list'
  field?: Entity<CustomFieldEntity>
  actionState = initialActionState

  getPayload(values: any) {
    let { kind, name, options } = values
    return { kind, name, options_attributes: options }
  }

  createField(values: any) {
    this.actionState = this.fields.create({ payload: this.getPayload(values) })
    this.actionState.then(
      () => (this.view = 'list'),
      (err) => this.toast.error({ title: 'Не удалось создать', description: err.message })
    )
  }

  updateField(values: any) {
    this.actionState = this.field!.update({ payload: this.getPayload(values) })
    this.actionState.then(
      () => (this.view = 'list'),
      (err) =>
        this.toast.error({ title: 'Не удалось сохранить', description: err.message })
    )
  }

  openList() {
    this.view = 'list'
    this.modalIsOpen = true
  }

  openCreateForm() {
    this.view = 'create'
    this.modalIsOpen = true
  }

  openEditForm(field: Entity<CustomFieldEntity>) {
    this.view = 'edit'
    this.field = field
    this.modalIsOpen = true
  }

  render() {
    return this.modalIsOpen && <CustomFieldsModal store={this} />
  }
}

interface CustomFieldsListItemProps {
  field: Entity<CustomFieldEntity>
  onDelete: () => void
  onChange: () => void
}

const CustomFieldListItem = observer((props: CustomFieldsListItemProps) => {
  let { field, onDelete, onChange } = props
  let { name, kind } = field.data
  return (
    <ListItem padding={false}>
      <ListCell col="name" style={{ fontWeight: 600 }}>
        {name}
      </ListCell>
      <ListCell col="kind">{customFieldNames[kind]}</ListCell>
      <ListCell col="actions">
        <Flex gap="var(--gap-s)">
          <IconButton tooltip="Изменить" onTap={onChange}>
            <EditIcon style={{ width: 20, height: 20 }} />
          </IconButton>
          <IconButton tooltip="Удалить" onTap={onDelete}>
            <DeleteIcon style={{ width: 20, height: 20 }} />
          </IconButton>
        </Flex>
      </ListCell>
    </ListItem>
  )
})

const contractorWordForms = {
  one: 'исполнителя',
  two: 'исполнителей',
  many: 'исполнителей',
}

const CustomFieldList = observer(({ store }: { store: CustomFieldsStore }) => {
  let toast = useStateToast()
  let { fields } = store

  let confirmDialog = useConfirmDialog<{
    field: Entity<CustomFieldEntity>
    count: number
  }>({
    render: ({ cancel, confirm, data }) => (
      <ConfirmDialog
        header="Удаление поля"
        buttons={
          <>
            <Button design="normal" onTap={cancel}>
              Отмена
            </Button>
            <Button onTap={confirm}>Удалить</Button>
          </>
        }
        onCancel={cancel}
      >
        Поле <span style={{ fontWeight: 600 }}>{data.field.data.name}</span> задано у{' '}
        {numForm(data.count, contractorWordForms)}.
        <br />
        Вы уверены что хотите удалить это поле?
      </ConfirmDialog>
    ),
    onConfirm: (data) => deleteField(data.field),
  })

  let onTapDeleteField = (field: Entity<CustomFieldEntity>) => {
    let state = field.action({ action: 'counter', options: { method: 'GET' } })
    state.then(({ count }: any) => {
      if (count > 0) confirmDialog.open({ count, field })
      else deleteField(field)
    })
  }

  let deleteField = (field: Entity<CustomFieldEntity>) => {
    fields
      .delete({ id: field.id, optimistic: true })
      .then(null, (err) =>
        toast.error({ title: 'Не удалось удалить', description: err.message })
      )
  }

  let cols = {
    name: {},
    kind: { width: 200 },
    actions: { width: 60 },
  }
  return (
    <List cols={cols}>
      <ListHeader padding={false}>
        <ListCell col="name">Название</ListCell>
        <ListCell col="kind">Тип</ListCell>
        <ListCell col="actions" />
      </ListHeader>
      {fields.items.map((field) => (
        <CustomFieldListItem
          key={field.id}
          field={field}
          onChange={() => store.openEditForm(field)}
          onDelete={() => onTapDeleteField(field)}
        />
      ))}
      {confirmDialog.render()}
    </List>
  )
})

interface CustomFieldOptionValue {
  id?: number
  name: string
  kind: 'text'
  _destroy?: boolean
}

interface OptionsInputProps {
  value: Array<CustomFieldOptionValue>
  onChange: (value: Array<CustomFieldOptionValue>) => void
}

const initialOption: CustomFieldOptionValue = { name: '', kind: 'text' }

interface OptionInputProps {
  option: CustomFieldOptionValue
  onChange: (value: string) => void
  onRemove: () => void
}

const OptionInput = observer(
  // @ts-ignore
  ({ option, onRemove, onChange }: OptionInputProps, ref) => {
    let confirmDialog = useConfirmDialog<{ count: number }>({
      render: ({ cancel, confirm, data }) => (
        <ConfirmDialog
          header="Удаление значения"
          buttons={
            <>
              <Button design="normal" onTap={cancel}>
                Отмена
              </Button>
              <Button onTap={confirm}>Удалить</Button>
            </>
          }
          onCancel={cancel}
        >
          Это значение задано у {numForm(data.count, contractorWordForms)}. Вы уверены что
          хотите удалить это значение?
        </ConfirmDialog>
      ),
      onConfirm: onRemove,
    })

    const [counterState, setCounterState] = useState(initialActionState)
    const remove = () => {
      if (option.id) {
        let state = fetchApi({ url: `contractors/custom_fields/${option.id}/counter` })
        state.then(({ count }: any) => {
          if (count > 0) confirmDialog.open({ count })
          else onRemove()
        })
        setCounterState(fromPromise(state))
      } else {
        onRemove()
      }
    }

    return (
      <Flex key={option.id} align="center" gap=".5rem" style={{ alignSelf: 'stretch' }}>
        <Input
          value={option.name}
          onChange={onChange}
          style={{ flexGrow: 1 }}
          ref={ref}
        />
        <IconButton
          onTap={remove}
          tooltip="Удалить значение"
          isDisabled={counterState.state === 'pending'}
        >
          <ClearIcon />
        </IconButton>
        {confirmDialog.render()}
      </Flex>
    )
  },
  { forwardRef: true }
)

const OptionsInput = observer(
  // @ts-ignore
  ({ value, onChange }: OptionsInputProps, ref) => {
    let lastInputRef = useRef<any>()
    let focus = () => lastInputRef.current?.focus()
    useImperativeHandle(ref, () => ({ focus }), [])

    const changeOption = (option: CustomFieldOptionValue, name: string) => {
      onChange(value.map((o) => (o === option ? { ...o, name } : o)))
    }
    const addOption = () => {
      onChange([...value, { ...initialOption }])
      setTimeout(focus)
    }
    const removeOption = (removed: CustomFieldOptionValue) => {
      if (removed.id) {
        onChange(
          value.map((option) =>
            option === removed ? { ...option, _destroy: true } : option
          )
        )
      } else {
        onChange(value.filter((option) => option !== removed))
      }
    }

    return (
      <>
        <Col gap="var(--gap-s)" align="start">
          {value.map(
            (option, index) =>
              !option._destroy && (
                <OptionInput
                  // @ts-ignore
                  option={option}
                  // @ts-ignore
                  onChange={(value) => changeOption(option, value)}
                  onRemove={() => removeOption(option)}
                  ref={index === value.length - 1 ? lastInputRef : undefined}
                />
              )
          )}
        </Col>
        <BaseButton
          size="s"
          design="normal"
          onTap={addOption}
          style={{ marginTop: '1rem', fontWeight: 600, color: 'var(--color-active)' }}
        >
          <Flex gap=".25rem" align="center">
            <AddIcon style={{ height: 21, width: 21, fill: 'var(--color-active)' }} />
            <div>Добавить значение</div>
          </Flex>
        </BaseButton>
      </>
    )
  },
  { forwardRef: true }
)

const CustomFieldsListView = observer(({ store }: { store: CustomFieldsStore }) => {
  return (
    <Col gap="var(--gap-m)">
      <H2>Дополнительные поля</H2>
      {store.fields.isEmpty ? (
        <div style={{ color: 'var(--color-secondary)' }}>
          Дополнительных полей не создано
        </div>
      ) : (
        <CustomFieldList store={store} />
      )}
      <div>
        <Button onTap={() => (store.view = 'create')}>Создать поле</Button>
      </div>
    </Col>
  )
})

const kindOptions = Object.entries(customFieldNames).map(([key, value]) => ({
  value: key,
  label: value,
}))

const createForm = (initialValues?: Object) =>
  new FormStore({
    fields: {
      name: {
        isRequired: true,
        requiredError: 'Нужно указать название поля',
      },
      kind: {},
      options: {
        validations: {
          atLeastOneOption: {
            validate: (value: Array<CustomFieldOption>, values: any) =>
              values.kind === 'dropdown' ? value.length > 0 : true,
            error: 'Поле должно иметь хотя бы одно значение',
          },
          notEmptyNames: {
            validate: (value: Array<CustomFieldOption>) =>
              value.every((option) => option.name.length > 0),
            error: 'Значения не должны быть пустыми',
          },
        },
      },
    },
    initialValues: { kind: 'text', options: [], ...initialValues },
  })

const CustomFieldsFormView = observer(({ store }: { store: CustomFieldsStore }) => {
  let form = useMemo(() => {
    let initialValues: any
    if (store.view === 'edit' && store.field) {
      let { name, kind, options } = store.field.data
      initialValues = { name, kind }
      if (options) initialValues.options = options.items.map((o) => o.data)
    }
    return createForm(initialValues)
  }, [])

  let optionsInputRef = useRef<any>()

  useEffect(() => {
    let dispose = reaction(
      () => form.values.kind,
      (kind: string) => {
        if (kind === 'dropdown') {
          form.fields.options.change([{ ...initialOption }])
          setTimeout(() => optionsInputRef.current?.focus())
        } else {
          form.fields.options.change([])
        }
      }
    )
    return dispose
  }, [])

  let formElem = (
    <Col gap="var(--gap-m)">
      <Field field={form.fields.name}>
        {({ inputProps, error }) => (
          <FormRow label="Название" size="l" error={error}>
            <Input
              {...inputProps}
              isWide
              size="l"
              placeholder="Название поля"
              autofocus={true}
            />
          </FormRow>
        )}
      </Field>
      <Field field={form.fields.kind}>
        {({ inputProps, error }) => (
          <FormRow label="Тип поля" size="l" error={error}>
            {store.view === 'edit' ? (
              <span style={{ fontSize: '1.5rem' }}>
                {customFieldNames[form.values.kind as CustomFieldKind]}
              </span>
            ) : (
              <SelectInput
                {...inputProps}
                value={
                  inputProps.value
                    ? kindOptions.find((o) => o.value === inputProps.value)
                    : null
                }
                onChange={(option) =>
                  form.fields.kind.change(option ? option.value : null)
                }
                size="l"
                options={kindOptions}
                isSearchable={false}
              />
            )}
          </FormRow>
        )}
      </Field>
      {form.values.kind === 'dropdown' && (
        <Field field={form.fields.options} showValidationErrors="never">
          {({ inputProps, error }) => (
            <FormRow label="Значения" size="l" error={error}>
              <OptionsInput {...inputProps} ref={optionsInputRef} />
            </FormRow>
          )}
        </Field>
      )}
    </Col>
  )

  return (
    <Col gap="var(--gap-m)">
      <H2>{store.view === 'edit' ? 'Изменение поля' : 'Cоздание поля'}</H2>
      {formElem}
      <Flex justify="end" gap="1rem" style={{ marginTop: '3rem' }}>
        <Button
          design="normal"
          onTap={() => (store.view = 'list')}
          isDisabled={store.actionState.state === 'pending'}
        >
          Отменить
        </Button>
        <Button
          isDisabled={!form.isValid}
          isLoading={store.actionState.state === 'pending'}
          onTap={() =>
            store.view === 'edit'
              ? store.updateField(form.values)
              : store.createField(form.values)
          }
        >
          {store.view === 'edit' ? 'Сохранить' : 'Создать'}
        </Button>
      </Flex>
    </Col>
  )
})

const CustomFieldsModal = observer(({ store }: { store: CustomFieldsStore }) => {
  let content =
    store.view === 'list' ? (
      <CustomFieldsListView store={store} />
    ) : (
      <CustomFieldsFormView store={store} />
    )

  return (
    <Modal isOpen={true} onClose={() => (store.modalIsOpen = false)} size={600}>
      {content}
    </Modal>
  )
})

export { CustomFieldsStore }
