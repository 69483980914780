import React from 'react'
import cl from 'clsx'
import { CurrentBreakpoint, useBreakpoint } from 'hooks'
import { Card } from 'ui-kit'

import css from './styles.module.css'
import { TableCellProps, TableHeaders, TableRow } from './components'

type DesktopViewConfig = {
  colNames?: (string | null)[]
  thClassNames?: (string | null)[]
  rows: TableCellProps[][]
}

export type TableProps = {
  desktop: DesktopViewConfig
  mobile: React.ReactNode[]
  className?: string
}

const Table: React.FunctionComponent<TableProps> = ({ desktop, mobile, className }) => {
  const { colNames, thClassNames, rows } = desktop

  const point = useBreakpoint()

  if (point === CurrentBreakpoint.Desktop) {
    return (
      <table className={cl(css.table, className && className)}>
        <TableHeaders colNames={colNames} classNames={thClassNames} />
        <tbody>
          {rows.map((cells, i) => (
            <TableRow cells={cells} key={i} />
          ))}
        </tbody>
      </table>
    )
  }

  return (
    <>
      {mobile.map((node: React.ReactNode, i) => (
        <Card className={css.card} key={i}>
          {node}
        </Card>
      ))}
    </>
  )
}

export default Table
