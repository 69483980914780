import React from 'react'
import { makeAutoObservable } from 'mobx'
import { observer } from 'mobx-react-lite'
import cx from 'clsx'

import c from './styles.module.css'

import { Modal, Flex, Button, BaseButton } from 'components/ui'
import { useTheme } from 'ui'

const notificationsPopupState = { isOpen: false }
makeAutoObservable(notificationsPopupState)

const MobileNotificationsPopup = observer(() => {
  const { config } = useTheme()
  let close = () => {
    notificationsPopupState.isOpen = false
  }
  let enable = () => {
    // @ts-ignore
    window.ReactNativeWebView?.postMessage('getFCM')
    window.postMessage('getFCM', '*')
    close()
  }
  if (!notificationsPopupState.isOpen) return null
  return (
    <Modal size="90%" isOpen={true} onClose={close}>
      <Flex direction="column" gap="2rem">
        <div className={cx(c.image, c[config.id])} />
        <div className={c.text}>
          Включите уведомления, чтобы быстрее получать деньги за работу
        </div>
        <Button className={c.button} onTap={enable}>
          Включить
        </Button>
        <BaseButton className={c.link} onTap={close}>
          Не надо
        </BaseButton>
      </Flex>
    </Modal>
  )
})

export default MobileNotificationsPopup
export { notificationsPopupState }
