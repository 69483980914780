import React, { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { reaction, makeAutoObservable } from 'mobx'
import { compact, range, keyBy } from 'lodash'

import { ReactComponent as ChevronRightIcon } from '@material-design-icons/svg/round/chevron_right.svg'
import { ReactComponent as ChevronLeftIcon } from '@material-design-icons/svg/round/chevron_left.svg'

import { MobxApi, useMobxApi, Entity, Collection, initialActionState } from 'mobx/mobx'
import { OfferEntity, OfferResponseEntity, WorkPlaceEntity, OfferSlot } from 'entities'
import { useUserStore } from 'stores/context'

import { Chips } from 'components'
import {
  Layout,
  LayoutHeading,
  ActionStateView,
  Col,
  List,
  ListHeader,
  ListItem,
  ListCell,
  StatusBadge,
  Placeholder,
  Flex,
  useStateToast,
  Button,
} from 'components/ui'
import { formatPhone } from 'utils/formatting'
import { AbilityButton } from 'components/abilityButtons'

import dayjs from 'utils/dayjs'
import { printDate, serializeDate } from 'utils/datetime'

import { OfferList } from './OfferList'
import { responseStatusMap } from './maps'

interface CompanyOffersViewProps {
  offers: Collection<OfferEntity>
  responses: Collection<OfferResponseEntity>
}

const OffersSection = observer(({ offers }: { offers: Collection<OfferEntity> }) => {
  return <OfferList offers={offers.items} />
})

const cols = {
  contractor: { grow: 1 },
  offer: { grow: 1 },
  created_date: { width: 120 },
  offer_date: { width: 120 },
  status: { width: 100 },
  actions: { width: 200 },
}

const messages = {
  invite: 'Отклик исполнителя принят',
  assign: 'Отклик исполнителя принят',
  decline: 'Отклик отклонён',
}

const ResponseListItem = observer(
  ({ response }: { response: Entity<OfferResponseEntity> }) => {
    let currentUser = useUserStore()
    let { offer, contractor, created, status } = response.data

    let toast = useStateToast()
    let [actionState, setActionState] = useState(initialActionState)
    let action = (action: 'invite' | 'decline' | 'assign') => {
      let state = response.action({ action })
      state.then(
        (data) => {
          response.setData(data)
          toast.success({
            title: messages[action],
          })
        },
        (err) => toast.error({ title: 'Ошибка', description: err.message })
      )
      setActionState(state)
    }

    let workPlace = compact([
      offer.data.city,
      offer.data.metro ? `м. ${offer.data.metro}` : offer.data.address,
    ]).join(', ')

    let actions = (
      <Flex gap=".5rem" align="start" wrap>
        {response.data.actions.includes('invite') && (
          <AbilityButton
            ability={currentUser.hasAbility('offers/responses.invite')}
            size="xs"
            design="normal"
            tooltip="Исполнителю будет отправлено приглашение работать с вашей компанией"
            isLoading={actionState.state === 'pending'}
            onTap={() => action('invite')}
            style={{ marginBottom: '.5rem' }}
          >
            Принять
          </AbilityButton>
        )}
        {response.data.actions.includes('assign') && (
          <AbilityButton
            ability={currentUser.hasAbility('offers/responses.assign')}
            size="xs"
            design="normal"
            isLoading={actionState.state === 'pending'}
            onTap={() => action('assign')}
            style={{ marginBottom: '.5rem' }}
          >
            Назначить задание
          </AbilityButton>
        )}
        {response.data.actions.includes('decline') && (
          <AbilityButton
            ability={currentUser.hasAbility('offers/responses.decline')}
            size="xs"
            design="normal"
            isLoading={actionState.state === 'pending'}
            onTap={() => action('decline')}
          >
            Отклонить
          </AbilityButton>
        )}
      </Flex>
    )

    return (
      <ListItem
        as={Link}
        to={{
          pathname: `/offers/responses/${response.data.id}`,
          state: { url: '/offer', goBackLink: '/offers' },
        }}
        key={response.id}
        style={{ alignItems: 'start' }}>
        <ListCell col="contractor">
          <div>{contractor.data.name}</div>
          <div style={{ color: 'var(--color-secondary)' }}>
            {formatPhone(contractor.data.mobile_phone)}
          </div>
        </ListCell>
        <ListCell col="offer">
          <div>{offer.data.title}</div>
          <div style={{ color: 'var(--color-secondary)' }}>{workPlace}</div>
        </ListCell>
        <ListCell col="offer_date">
          {response.data.offer_date && printDate(response.data.offer_date)}
        </ListCell>
        <ListCell col="created_date">{printDate(created)}</ListCell>
        <ListCell col="status">
          <StatusBadge status={status} map={responseStatusMap} />
        </ListCell>
        <ListCell col="actions">{actions}</ListCell>
      </ListItem>
    )
  }
)

const ResponsesSection = observer(
  ({ responses }: { responses: Collection<OfferResponseEntity> }) => {
    if (responses.isEmpty) {
      return <Placeholder>Исполнители ещё не откликнулись на ваши задания</Placeholder>
    }
    let header = (
      <ListHeader>
        <ListCell col="contractor">Исполнитель</ListCell>
        <ListCell col="offer">Задание</ListCell>
        <ListCell col="offer_date">Дата выхода</ListCell>
        <ListCell col="created_date">Дата отклика</ListCell>
        <ListCell col="status">Статус</ListCell>
        <ListCell col="actions" />
      </ListHeader>
    )
    return (
      <List cols={cols}>
        {header}
        {responses.items.map((response) => (
          <ResponseListItem key={response.id} response={response} />
        ))}
      </List>
    )
  }
)

class WorkplacesStore {
  constructor(api: MobxApi) {
    this.startDate = dayjs().startOf('week').toDate()
    this.api = api

    this.fetch()

    reaction(
      () => this.startDate,
      () => this.fetch()
    )

    makeAutoObservable(this)
  }

  startDate: Date
  api: MobxApi
  fetchState = initialActionState

  fetch() {
    this.fetchState = this.api.fetch({
      type: 'offers/workplaces',
      payload: { start_date: serializeDate(this.startDate) },
    })
  }

  next() {
    this.startDate = dayjs(this.startDate).add(7, 'days').toDate()
  }

  prev() {
    this.startDate = dayjs(this.startDate).subtract(7, 'days').toDate()
  }
}

const OfferSlotCell = observer(({ slot }: { slot: OfferSlot }) => {
  return (
    <Col>
      <div>Смен: {slot.total_count}</div>
      <div style={{ color: 'var(--color-green)' }}>Назначено: {slot.assigned_count}</div>
      <div style={{ color: '#F2994A' }}>Откликов: {slot.responses_count}</div>
    </Col>
  )
})

const cellStyle = {
  width: 120,
  padding: '.5rem',
  borderRight: '1px solid var(--color-grey-1)',
  flexShrink: 0,
  flexGrow: 0,
}

const WorkplaceView = observer(
  ({
    workplace,
    store,
  }: {
    workplace: Entity<WorkPlaceEntity>
    store: WorkplacesStore
  }) => {
    let dates = range(0, 7).map((i) => dayjs(store.startDate).add(i, 'days').toDate())

    let buttons = (
      <Flex gap="1rem">
        <Button design="normal" size="s" onTap={() => store.prev()}>
          <Flex align="center">
            <ChevronLeftIcon fill="var(--color-secondary)" />
            <div>Назад</div>
          </Flex>
        </Button>
        <Button design="normal" size="s" onTap={() => store.next()}>
          <Flex align="center">
            <div>Вперед</div>
            <ChevronRightIcon fill="var(--color-secondary)" />
          </Flex>
        </Button>
      </Flex>
    )

    return (
      <Col gap="var(--gap-m)">
        <Flex gap=".5rem">
          <div>Объект:</div>
          <div style={{ fontWeight: 600 }}>{workplace.data.title}</div>
        </Flex>
        {buttons}
        <div>
          <Flex style={{ borderBottom: '1px solid var(--color-grey-1)' }}>
            <div style={{ ...cellStyle, width: 150 }} />
            {dates.map((date) => (
              <div style={{ ...cellStyle, width: 120, textAlign: 'center' }}>
                <div>{dayjs(date).format('dd')}</div>
                <div style={{ fontWeight: 600 }}>{printDate(date)}</div>
              </div>
            ))}
          </Flex>
          {workplace.data.offers.items.map((offer) => {
            let slotsByDate = keyBy(offer.data.slots, 'date')
            return (
              <Flex>
                <div style={{ ...cellStyle, width: 150 }}>{offer.data.title}</div>
                {dates.map((date) => {
                  let slot = slotsByDate[serializeDate(date)]
                  return (
                    <div style={{ ...cellStyle, width: 120 }}>
                      {slot && <OfferSlotCell slot={slot} />}
                    </div>
                  )
                })}
              </Flex>
            )
          })}
        </div>
      </Col>
    )
  }
)

const WorkplacesView = observer(() => {
  let api = useMobxApi()
  let store = useMemo(() => new WorkplacesStore(api), [])

  return (
    <ActionStateView state={store.fetchState}>
      {(workplaces) =>
        workplaces.isEmpty ? (
          <Placeholder>У вас нет объектов со сменами</Placeholder>
        ) : (
          <WorkplaceView workplace={workplaces.items[0]} store={store} />
        )
      }
    </ActionStateView>
  )
})

const CompanyOffersView = observer(({ offers, responses }: CompanyOffersViewProps) => {
  let [section, setSection] = useState<'offers' | 'responses' | 'workplaces'>('responses')

  let content
  if (section === 'offers') {
    content = <OffersSection offers={offers} />
  } else if (section === 'responses') {
    content = <ResponsesSection responses={responses} />
  } else {
    content = <WorkplacesView />
  }

  return (
    <Col gap="var(--gap-m)">
      <Chips
        value={section}
        onChange={(value) => setSection(value)}
        items={[
          { id: 'responses', title: 'Отклики' },
          { id: 'offers', title: 'Предложения' },
          // { id: 'workplaces', title: 'Планирование смен' },
        ]}
      />
      {content}
    </Col>
  )
})

const CompanyOffersPage = observer(() => {
  let api = useMobxApi()
  let fetchState = useMemo(
    () => [api.fetch({ type: 'offers' }), api.fetch({ type: 'offers/responses' })],
    []
  )
  return (
    <Layout smallPaddingTop>
      <LayoutHeading style={{ marginBottom: 'var(--gap-m)' }}>
        Маркетплейс
      </LayoutHeading>
      <ActionStateView state={fetchState}>
        {([offers, responses]) => (
          <CompanyOffersView offers={offers} responses={responses} />
        )}
      </ActionStateView>
    </Layout>
  )
})

export default CompanyOffersPage
