// import React from 'react'

import { extendComponentStyles } from 'ui/styled'
import { Menu, MenuItem } from 'ui/menu'
import type { MenuStyleProps, MenuItemStyleProps } from 'ui/menu'

const menuItemStyles = (...[_props, { isSelected }]: MenuItemStyleProps) => {
  const root = {
    padding: 8,
    borderRadius: 6,
    background: isSelected ? 'var(--konsol-color-blue-08)' : 'white',
    color: isSelected
      ? 'var(--konsol-color-text-accent)'
      : 'var(--konsol-color-text-primary)',
    cursor: 'pointer',
  }
  return { root }
}

const KonsolMenuItem = extendComponentStyles(MenuItem, menuItemStyles)

const menuStyles = (...[_props]: MenuStyleProps) => {
  const list: React.CSSProperties = {
    background: 'white',
    borderRadius: 6,
    boxShadow: '0px 4px 16px 0px rgba(124, 130, 146, 0.16)',
    boxSizing: 'border-box',
    padding: 8,
    fontSize: 'var(--konsol-font-size)',
  }
  return { list }
}

const KonsolMenu = extendComponentStyles(Menu, menuStyles)

export { KonsolMenu as Menu, KonsolMenuItem as MenuItem }
