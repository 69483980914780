import React, { useEffect } from 'react'

import { Flex, Select } from 'ui'

import IndicatorList from '../../components/IndicatorList'

import useAnalytics from 'pages/analytics/context/AnalyticsProvider'
import { ActionStateView, Placeholder, useStateToast } from 'components/ui'

import { Observer, observer, useLocalObservable } from 'mobx-react-lite'
import { makeAutoObservable } from 'mobx'
import { ActionState } from 'mobx/types'
import { fromPromise } from 'mobx-utils'
import { fetchApi } from 'api'

export const SegmentView = observer(() => {
  const toast = useStateToast()

  const analyticsManager = useAnalytics()

  const segmentManager = useLocalObservable(
    () =>
      new (class {
        fetchDataTask?: ActionState<any>

        segmentId?: number

        setSegmentId = (segmentId: number) => (this.segmentId = segmentId)

        get linkFilters() {
          return this.segmentId
            ? [
                {
                  name: 'segment',
                  value: this.segmentId.toString(),
                },
              ]
            : []
        }

        constructor() {
          makeAutoObservable(this)
        }

        private createFetchDataTask = () =>
          fromPromise(
            (async () => {
              try {
                const data = await fetchApi({
                  url: 'segments/contractors',
                  method: 'GET',
                })
                return data
              } catch (error: any) {
                toast.error({
                  title: `Не удалось загрузить список сегментов`,
                  description: error.message,
                })
              }
              return null
            })()
          ) as ActionState<any>

        fetchData = () => {
          this.fetchDataTask = this.createFetchDataTask()
        }

        clearData = () => {
          this.fetchDataTask = undefined
        }
      })()
  )

  useEffect(() => {
    segmentManager.fetchData()
    return () => segmentManager.clearData()
  }, [])

  useEffect(() => {
    if (segmentManager.segmentId) {
      analyticsManager.fetchData('segment', segmentManager.segmentId.toString())
    }
    return () => analyticsManager.clearData()
  }, [segmentManager.segmentId])

  return segmentManager.fetchDataTask ? (
    <ActionStateView state={segmentManager.fetchDataTask}>
      {(segments) => {
        if (!segments) {
          return <Placeholder>Произошла ошибка</Placeholder>
        }

        const segmentItems = segments.map((item: any) => ({
          label: item.title,
          value: item.id,
        }))

        return (
          <Flex direction="col" gap="25px">
            <Select
              style={{
                maxWidth: 400,
              }}
              placeholder="Выберите сценарий для рассчета"
              options={segmentItems}
              value={segmentItems.find(
                (item: any) => item.value === segmentManager.segmentId
              )}
              onChange={(item: any) => segmentManager.setSegmentId(item.value)}
            />
            {analyticsManager.fetchDataTask && (
              <ActionStateView state={analyticsManager.fetchDataTask}>
                {(data) => (
                  <Observer>
                    {() =>
                      data ? (
                        <IndicatorList
                          data={data}
                          linkFilters={segmentManager.linkFilters}
                        />
                      ) : (
                        <Placeholder>Произошла ошибка</Placeholder>
                      )
                    }
                  </Observer>
                )}
              </ActionStateView>
            )}
          </Flex>
        )
      }}
    </ActionStateView>
  ) : null
})

export default SegmentView
