// @ts-ignore
import { FormStore } from 'shadowform'

import { UserRegistrationEntity } from 'entities'
import { Entity } from 'mobx/MobxApi'
import extraFieldsConfig from '../configs/ExtraFieldsConfig'

const createRegistrationForm = (registration: Entity<UserRegistrationEntity>) => {
  let fields: { [key: string]: any } = {
    citizenship_country_code: {
      isRequired: true,
      requiredError: 'Необходимо заполнить это поле',
    },
  }

  if (registration.data.form_options.citizenship_country_tax_residence) {
    fields['tax_residence_country_code'] = {
      isRequired: true,
      requiredError: 'Необходимо заполнить это поле',
    }
  }

  let initialValues: { [key: string]: any } = {
    ...registration.data.self_employed_data,
  }

  if (registration.data.form_options.personal_data) {
    registration.data.form_options.personal_data.forEach((field) => {
      let { name, type, is_required, default_value } = field
      let { config, initialValue } = extraFieldsConfig[type]
      fields[name] = { ...config, isRequired: is_required }
      initialValues[name] = default_value || initialValue
    })
  }
  return new FormStore({ fields, initialValues })
}

export default createRegistrationForm
