import React, { useState, forwardRef, useEffect } from 'react'

import { useStyles, StyleProps, omitStyleProps, StylesMap } from 'ui/styled'

type AutogrowInputStyleProps = [AutogrowInputProps, { isFocused: boolean }]

interface AutogrowInputProps
  extends React.HTMLProps<HTMLInputElement>,
    StyleProps<AutogrowInputStyleProps> {
  inputRef?: any
}

const autogrowInputStyles: StylesMap = {
  root: {
    display: 'inline-grid',
    gridArea: '1 / 1 / 1 / 2', // 1 row 2 cols
    gridTemplateColumns: '0px min-content',
    boxSizing: 'border-box',
  },
  replica: {
    whiteSpace: 'pre',
    visibility: 'hidden',
    gridArea: '1 / 1 / 2 / 3',
    fontSize: 'inherit',
  },
  input: {
    fontSize: 'inherit',
    fontFamily: 'inherit',
    background: 'transparent',
    border: 0,
    margin: 0,
    outline: 0,
    padding: 0,
    gridArea: '1 / 1 / 2 / 3',
    minWidth: 2, // this is to display caret
    width: '100%',
  },
}

const AutogrowInput = forwardRef<HTMLInputElement, AutogrowInputProps>((props, ref) => {
  const { inputRef, value, onFocus, onBlur, ...restProps } = omitStyleProps(props)
  const [isFocused, setIsFocused] = useState(false)
  const styles = useStyles(autogrowInputStyles, [props, { isFocused }])
  const onInputFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setIsFocused(true)
    if (onFocus) onFocus(e)
  }
  const onInputBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setIsFocused(false)
    if (onBlur) onBlur(e)
  }
  useEffect(() => {
    return () => {
      if (isFocused && onBlur) onBlur({} as any)
    }
  }, [])
  return (
    <div style={styles.root} ref={ref}>
      <div style={styles.replica}>{value}</div>
      <input
        ref={inputRef}
        value={value}
        autoComplete="off"
        tabIndex={0}
        style={styles.input}
        onFocus={onInputFocus}
        onBlur={onInputBlur}
        {...restProps}
      />
    </div>
  )
})

export default AutogrowInput
