import React from 'react'
import { observer } from 'mobx-react-lite'

import { Entity } from 'mobx/mobx'
import { TaskAction, TaskEntity } from 'entities'
import EventList from 'components/EventList'

const taskEvents: { [key in TaskAction]?: string } = {
  create: 'Предложено исполнителю',
  assign_contractor: 'Исполнитель выбран',
  sign_terms: ' ',
  cancel: 'Отменено',
  start: 'Принято исполнителем',
  decline: 'Исполнитель отказался от задания',
  done: 'Отчет подготовлен',
  make_work_acceptance: 'Инвойс подписан исполнителем',
  accept: 'Инвойс подписан заказчиком',
  reject: 'Отчет отклонен',
  pay: 'Оплачено',
  autopay: 'Оплата одобрена',
  cancel_autopay: 'Отмена одобрения оплаты',
  complete: 'Оплата получена',
  complete_with_receipt: 'Чек приложен',
  complete_with_auto_receipt: 'Чек сформирован',
  annul_fns_receipt: 'Чек аннулирован',
  make_fns_receipt: 'Чек сформирован',
  annul: 'Соглашение об аннулировании подписано заказчиком',
  accept_annul: 'Соглашение об аннулировании подписано исполнителем',
  annul_by_one_side: 'Акт аннулирован заказчиком',
}

export const sectionsEvents: { [key: string]: TaskAction[] } = {
  start: ['create', 'sign_terms', 'cancel', 'start', 'decline'],
  report: ['done', 'reject'],
  act: ['make_work_acceptance', 'accept', 'annul_by_one_side'],
  payment: [
    'autopay',
    'cancel_autopay',
    'pay',
    'mark_paid_with_receipt',
    'mark_paid',
    'complete',
    'complete_with_receipt',
    'complete_with_auto_receipt',
    'annul_fns_receipt',
    'make_fns_receipt',
  ],
  annul: ['annul', 'accept_annul'],
}

const getEventListItems = (task: Entity<TaskEntity>, eventTypes: Array<TaskAction>) =>
  task.data.events
    .filter((event) => eventTypes.includes(event.kind))
    .map((item) => ({
      ...item,
      kind: taskEvents[item.kind] || item.kind,
    }))

interface TaskEventListProps {
  section: 'start' | 'report' | 'act' | 'payment' | 'annul'
  task: Entity<TaskEntity>
}

const TaskEventList = observer(({ task, section }: TaskEventListProps) => (
  <EventList items={getEventListItems(task, sectionsEvents[section])} />
))

export default TaskEventList
