import React, { useEffect } from 'react'

import { observer } from 'mobx-react-lite'

import {
  List,
  Placeholder,
  Menu as MenuUi,
  MenuButton,
  Button as ButtonUi,
  MenuList,
  Flex,
} from 'components/ui'
import {
  MultiActionButton,
  SelectAllCheckbox,
  SortFiltersPanel,
} from 'components/FilteredList2'

import TaskListRow from './TaskListRow'

import useTaskListStore, { taskWordFormsWith } from '../../hooks/useTaskListStore'

import { ReactComponent as DropdownIcon } from '@material-design-icons/svg/round/arrow_drop_down.svg'

import css from './styles.module.css'
import { Checkbox } from 'ui'

import { useMediaContext } from 'App/MediaContext'
import { SortableListHeader } from 'components/FilteredList'

interface ITaskListProps {
  items: any
}

const TaskList = observer(({ items }: ITaskListProps) => {
  const { isMobile } = useMediaContext()

  const listStore = useTaskListStore()

  useEffect(() => {
    listStore.setItems(items)
  }, [])

  const cols: any = {
    checkbox: {
      width: () => 35,
      props: {
        style: {
          maxWidth: 30,
          marginLeft: 5,
        },
      },
      title: (
        <SelectAllCheckbox
          store={listStore.selectedStore}
          Checkbox={(props: any) => (
            <Checkbox {...props} value={props.value && !!listStore.items.length} />
          )}
        />
        /*<Checkbox
          isDisabled={!listStore.items.length}
          isIndeterminate={headerIndeterminate}
          value={listStore.isAllSelected && !!listStore.items.length}
          onChange={listStore.toggleSelectAll}
        />*/
      ),
    },
    contractor: {
      title: 'Исполнитель',
      sorting: 'contractor',
      width: (fullWidth: number) => (fullWidth - 35 - 175) / 6,
    },
    units: {
      title: 'Работа',
      width: (fullWidth: number) => (fullWidth - 35 - 175) / 6,
    },
    dateRange: {
      title: 'Период',
      sorting: 'dateRange',
      width: (fullWidth: number) => (fullWidth - 35 - 175) / 6,
    },
    status: {
      title: 'Статус',
      sorting: 'status',
      width: (fullWidth: number) => (fullWidth - 35 - 175) / 6,
    },
    act: {
      title: 'Акт',
      width: (fullWidth: number) => (fullWidth - 35 - 175) / 6,
    },
    amount: {
      title: 'Стоимость',
      sorting: 'amount',
      width: () => 175,
    },
  }

  return (
    <div className={css.container}>
      {listStore._selected.render()}
      <List>
        {!!listStore.items.length && (
          <SortFiltersPanel
            showMobile
            sorting={listStore.sortModel.sorting}
            onChange={listStore.sortModel.setSorting}
            cols={cols}
            style={{
              marginLeft: -5,
            }}
            wordForms={taskWordFormsWith}
            sticky
            selectedStore={listStore.selectedStore}
            actions={
              <>
                <MultiActionButton store={listStore.selectedStore} action="suggest">
                  Отправить
                </MultiActionButton>
                <MultiActionButton store={listStore.selectedStore} action="cancel">
                  Отменить
                </MultiActionButton>
                <MultiActionButton store={listStore.selectedStore} action="complete">
                  Завершить
                </MultiActionButton>
                <MultiActionButton store={listStore.selectedStore} action="not_complete">
                  Не выполнено
                </MultiActionButton>
                <MultiActionButton store={listStore.selectedStore} action="create_act">
                  Создать акты
                </MultiActionButton>
                <MenuUi>
                  <MenuButton
                    isDisabled={listStore.selectedStore.isFetching()}
                    as={ButtonUi}
                    design="normal"
                    size={isMobile ? 'xs' : 's'}
                    style={{ transform: 'none' }}
                  >
                    <Flex gap=".5rem" align="center">
                      Ещё
                      <DropdownIcon
                        style={{ marginRight: '-1rem', fill: 'var(--color-icon)' }}
                      />
                    </Flex>
                  </MenuButton>
                  <MenuList>
                    <MultiActionButton
                      store={listStore.selectedStore}
                      action="duplicate"
                      as="menuItem"
                    >
                      Создать копии
                    </MultiActionButton>
                    <MultiActionButton
                      store={listStore.selectedStore}
                      action="delete_draft"
                      as="menuItem"
                      style={{ color: 'var(--color-red)' }}
                    >
                      Удалить черновики
                    </MultiActionButton>
                  </MenuList>
                </MenuUi>
              </>
            }
          />
        )}
        <SortableListHeader
          showMobile
          sorting={listStore.sortModel.sorting}
          onChange={listStore.sortModel.setSorting}
          cols={cols}
          style={{
            marginLeft: -5,
          }}
        />
        {listStore.items.length === 0 && <Placeholder>Заданий не найдено</Placeholder>}
        {listStore.items.map((item, idx) => (
          <TaskListRow
            item={item}
            key={idx}
            isSelected={listStore.isSelected(item)}
            onSelectChange={() => listStore.toggleSelect(item)}
          />
        ))}
      </List>
    </div>
  )
})

export default TaskList
