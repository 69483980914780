import { useCallback, useState, useMemo } from 'react'
import { useEvent } from 'react-use'

const getNextIndex = (index: number, length: number) =>
  index < length - 1 ? index + 1 : 0

const getPrevIndex = (index: number, length: number) =>
  index > 0 ? index - 1 : length - 1

interface UseListProps<T> {
  items: T[]
  isItemDisabled: (item: T) => boolean
}

interface UseListReturnType<T> {
  items: T[]
  selectedIndex: number
  setSelectedIndex: (index: number) => void
  selectNextItem: () => void
  selectPrevItem: () => void
  selectFirstItem: () => void
  selectLastItem: () => void
}

const useList = <T>(props: UseListProps<T>): UseListReturnType<T> => {
  const { items, isItemDisabled } = props
  const [selectedIndex, setSelectedIndex] = useState(-1)

  const enabledItems = useMemo(
    () => items.filter((item) => !isItemDisabled(item)),
    [items, isItemDisabled]
  )

  const getEnabledIndex = useCallback(
    (originalIndex: number) =>
      originalIndex === -1
        ? -1
        : enabledItems.findIndex((item) => item === items[originalIndex]),
    [items, enabledItems]
  )

  const getOriginalIndex = useCallback(
    (enabledIndex: number) =>
      enabledIndex === -1
        ? -1
        : items.findIndex((item) => item === enabledItems[enabledIndex]),
    [items, enabledItems]
  )

  const selectNextItem = useCallback(() => {
    const nextIndex =
      enabledItems.length > 0
        ? getOriginalIndex(
            getNextIndex(getEnabledIndex(selectedIndex), enabledItems.length)
          )
        : -1
    setSelectedIndex(nextIndex)
  }, [setSelectedIndex, enabledItems, selectedIndex, getEnabledIndex, getOriginalIndex])

  const selectPrevItem = useCallback(() => {
    const nextIndex =
      enabledItems.length > 0
        ? getOriginalIndex(
            getPrevIndex(getEnabledIndex(selectedIndex), enabledItems.length)
          )
        : -1
    setSelectedIndex(nextIndex)
  }, [setSelectedIndex, enabledItems, selectedIndex, getEnabledIndex, getOriginalIndex])

  const selectFirstItem = useCallback(() => {
    const nextIndex = enabledItems.length > 0 ? getOriginalIndex(0) : -1
    setSelectedIndex(nextIndex)
  }, [enabledItems, getOriginalIndex])

  const selectLastItem = useCallback(() => {
    const nextIndex =
      enabledItems.length > 0 ? getOriginalIndex(enabledItems.length - 1) : -1
    setSelectedIndex(nextIndex)
  }, [enabledItems, getOriginalIndex])

  return {
    items,
    selectedIndex,
    setSelectedIndex,
    selectNextItem,
    selectPrevItem,
    selectFirstItem,
    selectLastItem,
  }
}

const useListKeyboardEvents = <T>(
  list: UseListReturnType<T>,
  onSelect: (item: T, index: number) => void
) => {
  const {
    items,
    selectedIndex,
    selectNextItem,
    selectPrevItem,
    selectFirstItem,
    selectLastItem,
  } = list
  const onKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      const handlers: { [key: string]: (e: React.KeyboardEvent) => void } = {
        ArrowDown: selectNextItem,
        ArrowUp: selectPrevItem,
        Home: selectFirstItem,
        End: selectLastItem,
        Enter: () => {
          if (selectedIndex !== -1) onSelect(items[selectedIndex], selectedIndex)
        },
      }
      const handler = handlers[event.key]
      if (handler) {
        event.preventDefault()
        handler(event)
      }
    },
    [
      selectNextItem,
      selectPrevItem,
      selectFirstItem,
      selectLastItem,
      items,
      selectedIndex,
      onSelect,
    ]
  )
  useEvent('keydown', onKeyDown)
}

export { useList, useListKeyboardEvents }
