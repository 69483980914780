import React from 'react'
import cl from 'clsx'
import { P1 } from 'ui-kit'

import css from './styles.module.css'

export enum TextInputSize {
  Large = 'large',
  Medium = 'medium',
  Small = 'small',
}

export type TextInputProps = {
  size?: TextInputSize
  value?: string
  helperText?: string
  error?: boolean
  disabled?: boolean
  placeholder?: string
  label?: string
  lIcon?: React.ReactNode
  rIcon?: React.ReactNode
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
  className?: string
}

const TextInput: React.FunctionComponent<TextInputProps> = ({
  size = TextInputSize.Medium,
  value = '',
  helperText,
  error,
  disabled,
  placeholder,
  label,
  lIcon,
  rIcon,
  onChange,
  onFocus,
  onBlur,
  className,
}) => (
  <>
    {label && <P1 className={css.labelText}>{label}</P1>}
    <div
      className={cl(
        css.textInputWrapper,
        disabled && css.disabled,
        error && css.error,
        css[size],
        className && className
      )}
    >
      {lIcon && <div className={css.lIcon}>{lIcon}</div>}
      <input
        type="text"
        value={value}
        disabled={disabled}
        placeholder={placeholder}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        className={cl(
          css.textInput,
          lIcon && css.withLPadding,
          rIcon && css.withRPadding
        )}
      />
      {rIcon && <div className={css.rIcon}>{rIcon}</div>}
      {helperText && <P1 className={css.helperText}>{helperText}</P1>}
    </div>
  </>
)

export default TextInput
