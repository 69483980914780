import * as React from 'react'
import { Button, Col, Flex, H2, Modal } from 'components/ui'
import { DocumentEntity } from 'entities'
import { observer } from 'mobx-react-lite'
import { Entity } from 'mobx/MobxApi'
import { DocumentsStore } from './DocumentsStore'
import DocumentsForSignList from 'components/documents/DocumentsForSignList'
import numForm from 'utils/numForm'
import { documentWordForms } from 'components/documents/statuses'

interface ConfirmDeleteDialogProps {
  store: DocumentsStore
  documents: Array<Entity<DocumentEntity>>
  onClose: () => void
}

export const ConfirmDeleteDialog = observer(
  ({ store, documents, onClose }: ConfirmDeleteDialogProps) => {
    return (
      <Modal size={1000} isOpen={true} onClose={onClose}>
        <Col gap="var(--gap-m)">
          <H2>Удаление документов</H2>
          <div style={{ fontWeight: 500, fontSize: '1.15rem' }}>
            Вы уверены что хотите удалить {numForm(documents.length, documentWordForms)}?
          </div>
          <DocumentsForSignList documents={documents} />
          <Flex gap="1rem">
            <Button
              design="normal"
              onClick={onClose}
              isDisabled={store.deleteState.state === 'pending'}
            >
              Отмена
            </Button>
            <Button
              onClick={() => store.deleteDocuments(documents)}
              isLoading={store.deleteState.state === 'pending'}
            >
              Удалить
            </Button>
          </Flex>
        </Col>
      </Modal>
    )
  }
)

interface ConfirmArchiveDialogProps {
  store: DocumentsStore
  documents: Entity<DocumentEntity>[]
  onClose: () => void
}

export const ConfirmArchiveDialog = observer(
  ({ store, documents, onClose }: ConfirmArchiveDialogProps) => {
    return (
      <Modal size={1000} isOpen={true} onClose={onClose}>
        <Col gap="var(--gap-m)">
          <H2>Архивирование документов</H2>
          <div style={{ fontWeight: 500, fontSize: '1.15rem' }}>
            Вы уверены что хотите отправить в архив{' '}
            {numForm(documents.length, documentWordForms)}?
          </div>
          <DocumentsForSignList documents={documents} />
          <Flex gap="1rem">
            <Button
              design="normal"
              onClick={onClose}
              isDisabled={store.archiveState.state === 'pending'}
            >
              Отмена
            </Button>
            <Button
              onClick={() => store.archiveDocuments(documents)}
              isLoading={store.archiveState.state === 'pending'}
            >
              Отправить в архив
            </Button>
          </Flex>
        </Col>
      </Modal>
    )
  }
)
