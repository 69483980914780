import { fetchApi } from 'api'
import { fromPromise } from 'mobx-utils'
import toFormData from 'utils/toFormData'

const registrationApi = {
  getData: () =>
    fromPromise(
      fetchApi({
        withPrefix: false,
        url: 'contractor/registration',
        method: 'GET',
      })
    ),
  cancelJoin: () =>
    fromPromise(
      fetchApi({
        withPrefix: false,
        url: 'registration/cancel_join',
        method: 'PUT',
      })
    ),
  joinAsIp: (data: Object) =>
    fromPromise(
      fetchApi({
        withPrefix: false,
        url: 'registration/join_as_individual_entrepreneur',
        method: 'PUT',
        data,
      })
    ),
  joinAsNonResident: () =>
    fromPromise(
      fetchApi({
        withPrefix: false,
        url: 'registration/join_as_non_resident',
        method: 'PUT',
      })
    ),
  joinAsSelfEmployed: (data: Object) =>
    fromPromise(
      fetchApi({
        withPrefix: false,
        url: 'registration/join_as_self_employed',
        method: 'PUT',
        data,
      })
    ),
  joinAsGPH: () =>
    fromPromise(
      fetchApi({
        withPrefix: false,
        url: 'registration/join_as_gph',
        method: 'PUT',
      })
    ),
  registerSelfEmployed: (data: Object) =>
    fromPromise(
      fetchApi({
        withPrefix: false,
        url: 'registration/register_self_employed',
        method: 'PUT',
        data,
      })
    ),
  uploadDocuments: (data: Object) =>
    fromPromise(
      fetchApi({
        withPrefix: false,
        url: `registration/documents`,
        method: 'PATCH',
        data: toFormData(data),
      })
    ),
  submitDocuments: (data: any, additional?: boolean) =>
    fromPromise(
      fetchApi({
        withPrefix: false,
        url: `registration/submit${additional ? '_additional' : ''}_documents`,
        method: 'PUT',
        data,
      })
    ),
  submitPersonalData: (data: Object) =>
    fromPromise(
      fetchApi({
        withPrefix: false,
        url: `registration/personal_data`,
        method: 'PUT',
        data,
      })
    ),
  submitPaymentDetails: (data: Object) =>
    fromPromise(
      fetchApi({
        withPrefix: false,
        url: `registration/payment_details`,
        method: 'PUT',
        data: toFormData(data),
      })
    ),
  skipPaymentDetails: () =>
    fromPromise(
      fetchApi({
        withPrefix: false,
        url: `registration/skip_payment_details`,
        method: 'PUT',
      })
    ),
}

export default registrationApi
