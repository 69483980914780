import { fetchApi } from 'api'
import { action, makeObservable, observable } from 'mobx'

export interface ICompanyStore {
  isEnabledStopList: boolean
  switchStopListStatus: () => void
  setStopListStatus: (status: boolean) => void
}

class CompanyStore implements ICompanyStore {
  isEnabledStopList = true

  constructor() {
    makeObservable(this, {
      isEnabledStopList: observable,
      switchStopListStatus: action.bound,
      setStopListStatus: action.bound,
    })
  }

  async switchStopListStatus() {
    try {
      if (!this.isEnabledStopList) {
        await fetchApi({
          url: 'contractors_blacklists/enable',
          method: 'POST',
        })
      } else {
        await fetchApi({
          url: 'contractors_blacklists/disable',
          method: 'POST',
        })
      }

      this.setStopListStatus(!this.isEnabledStopList)
    } catch (err) {
      console.error(err)
    }
  }

  setStopListStatus(status: boolean) {
    this.isEnabledStopList = status
  }
}

export default CompanyStore
