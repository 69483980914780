import React, { useEffect } from 'react'
import registrationApi from 'pages/registration/api'
import { usePusherSubscription } from 'App/pusher'
import RegistrationFooter from 'pages/registration/components/Footer'

const cyberitySuccess = {
  TYPE: 'idCheck.applicantStatus',
  STATUS: 'completed',
}

const launchWebSdk = (
  accessToken: string,
  config: any,
  onMessage: any,
  onError: any
): void => {
  let snsWebSdkInstance = window.snsWebSdk
    .init(accessToken, () => {
      console.error('Error: need cyberity accessToken')
    })
    .withBaseUrl('https://api.cyberity.ru')
    .withConf(config)
    .on('idCheck.onMessage', onMessage)
    .on('idCheck.onError', onError)
    .build()

  snsWebSdkInstance.launch('#cyberity-websdk-container')
}

const CyberityStep = ({ registration }: { registration: any }) => {
  const { token } = registration.data.cyberity_verification

  usePusherSubscription('update-registration', () => {
    registrationApi.getData()
  })

  // TODO: set to sentry
  const errorHandler = (err: any) => console.log('errorHandler: ', err)

  const messageHandler = (type: any, payload: any) => {
    const { reviewStatus } = payload
    if (type === cyberitySuccess.TYPE && reviewStatus === cyberitySuccess.STATUS) {
      registrationApi.getData()
    }
  }

  useEffect(() => {
    launchWebSdk(token, { lang: 'ru' }, messageHandler, errorHandler)
  }, [])

  return (
    <div>
      <div id="cyberity-websdk-container"></div>
      <RegistrationFooter />
    </div>
  )
}

export default CyberityStep
