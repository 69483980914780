const envUrl = process.env.REACT_APP_BACKEND_URL

export const baseUrl =
  envUrl || localStorage.getItem('base_url') || `${window.location.origin}/api`

export const getBaseUrl = ({ withPrefix = true } = {}) => {
  if (!withPrefix) return baseUrl
  // @ts-ignore
  let userKind = window.userStore.kind
  let prefix = userKind ? `/${userKind}` : ''
  return `${baseUrl}${prefix}`
}

export const getDefaultHeaders = () => {
  const headers: { [key: string]: string } = {}
  // @ts-ignore
  if (window.userStore.kind === 'company') {
    // @ts-ignore
    headers['X-Company-Id'] = window.userStore.userData.companyId
  }
  return headers
}
