import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { compact } from 'lodash'

import { Entity, ActionState, Data, initialActionState } from 'mobx/mobx'
import { OfferEntity, OfferForDateEntity, OfferResponseEntity } from 'entities'
import { useMediaContext } from 'App/MediaContext'
import { useUserStore } from 'stores/context'

import { Amount } from 'components'
import {
  List,
  ListHeader,
  ListItem,
  ListCell,
  StatusBadge,
  Button,
  useStateToast,
} from 'components/ui'
import { nbsp } from 'utils/typo'
import { printDate } from 'utils/datetime'

import { responseStatusMap, offerPuclishedStatusMap } from './maps'

import {
  OfferData,
  offerDataFromOffer,
  offerDataFromOfferForDate,
  offerDataFromResponse,
} from './govno'
import { fetchApi } from 'api'

let cols: any = {
  published_status: { grow: 2 },
  name: { grow: 4 },
  company: { grow: 3 },
  date: { width: 70 },
  amount: {
    width: 90,
    style: { textAlign: 'right', justifyContent: 'flex-end', paddingRight: '.5rem' },
  },
  workAreas: { width: 160 },
  city: { width: 122 },
  workPlace: { width: 200 },
  status: { width: 80 },
}

let styles = {
  item: {
    boxShadow: '0px 0px 1px rgb(0 0 0 / 15%), 0px 2px 12px rgb(0 0 0 / 10%)',
    borderRadius: 10,
    WebkitTapHighlightColor: 'transparent',
  },
  row: { marginBottom: '.35rem', alignItems: 'start' },
  label: {
    color: 'var(--color-secondary)',
    width: 130,
    flexShrink: 0,
    marginRight: '.5rem',
  },
}

interface OfferListItemProps {
  offer: OfferData
  withDate: boolean
  url: string
  respond?: () => ActionState<Data>
  buttonText?: string
  onOfferClick?: (event: string) => void
}

const OfferListItem = observer(
  ({ offer, withDate, url, respond, buttonText, onOfferClick }: OfferListItemProps) => {
    let currentUser = useUserStore()
    let { isMobile } = useMediaContext()
    let toast = useStateToast()

    let workPlace = compact([
      `г. ${offer.city}`,
      offer.metro ? `м.${nbsp}${offer.metro}` : undefined,
      offer.address,
    ]).join(', ')

    let [respondState, setRespondState] = useState(initialActionState)
    let onRespond = () => {
      if (onOfferClick) {
        onOfferClick('marketplace_offer_response')
      }
      let state = respond!()
      state.then(
        () => toast.success({ title: 'Отклик отправлен' }),
        () => toast.error({ title: 'Не удалось отправить отклик' })
      )
      setRespondState(state)
    }

    if (isMobile) {
      let renderItem = (label: React.ReactNode, children: React.ReactNode) => (
        <ListCell row style={styles.row}>
          {label}: {children}
        </ListCell>
      )
      return (
        <ListItem
          key={offer.id}
          as={Link}
          to={url}
          style={styles.item}
          wrap
          onClick={() => onOfferClick && onOfferClick('marketplace_offer_click')}
        >
          <ListCell row>
            <ListCell style={{ fontWeight: 600, fontSize: '1.25rem' }}>
              {offer.title}
            </ListCell>
            <ListCell
              width={100}
              style={{
                fontWeight: 600,
                textAlign: 'right',
                fontSize: '1.25rem',
                color: 'var(--color-active)',
              }}
            >
              <Amount value={offer.amount} />
            </ListCell>
          </ListCell>
          {currentUser.kind === 'company' && (
            <ListCell row style={{ marginTop: '.5rem' }}>
              <StatusBadge
                status={offer.published_status}
                map={offerPuclishedStatusMap}
                style={{ fontWeight: 600 }}
              />
            </ListCell>
          )}
          {currentUser.kind === 'contractor' &&
            renderItem('Компания', offer.company.data.name)}
          {offer.date && renderItem('Дата выхода', printDate(offer.date))}
          {renderItem('Специальность', offer.work_areas.join(', '))}
          {renderItem('Место работы', workPlace)}
          {currentUser.kind === 'contractor' && (
            <ListCell row style={{ marginTop: '.5rem' }}>
              {offer.status && (
                <StatusBadge
                  status={offer.status}
                  map={responseStatusMap}
                  style={{ fontWeight: 600 }}
                />
              )}
              {offer.actions.includes('respond') && (
                <Button
                  isLoading={respondState.state === 'pending'}
                  size="s"
                  style={{ width: '100%' }}
                  onTap={(e: any) => {
                    e.preventDefault()
                    onRespond()
                  }}
                >
                  {buttonText}
                </Button>
              )}
            </ListCell>
          )}
        </ListItem>
      )
    } else {
      return (
        <ListItem
          key={offer.id}
          style={{ alignItems: 'start' }}
          as={Link}
          to={url}
          onClick={() => onOfferClick && onOfferClick('marketplace_offer_click')}
        >
          <ListCell col="name">{offer.title}</ListCell>
          {currentUser.kind === 'contractor' && (
            <ListCell col="company">{offer.company.data.name}</ListCell>
          )}
          {withDate && <ListCell col="date">{printDate(offer.date)}</ListCell>}
          <ListCell col="amount">
            <Amount value={offer.amount} />
          </ListCell>
          <ListCell col="workAreas">{offer.work_areas.join(', ')}</ListCell>
          <ListCell col="city">{offer.city}</ListCell>
          <ListCell col="workPlace">{workPlace}</ListCell>
          {currentUser.kind === 'company' && (
            <ListCell col="published_status">
              <StatusBadge
                status={offer.published_status}
                map={offerPuclishedStatusMap}
                style={{ fontWeight: 600 }}
              />
            </ListCell>
          )}
          {currentUser.kind === 'contractor' && (
            <ListCell col="status">
              {offer.status && (
                <StatusBadge status={offer.status} map={responseStatusMap} />
              )}
            </ListCell>
          )}
        </ListItem>
      )
    }
  }
)

const OfferList = observer(({ offers }: { offers: Entity<OfferEntity>[] }) => {
  let { isMobile } = useMediaContext()
  let currentUser = useUserStore()
  let header = !isMobile && (
    <ListHeader>
      <ListCell col="name">Название</ListCell>
      {currentUser.kind === 'contractor' && <ListCell col="company">Компания</ListCell>}
      <ListCell col="amount">Стоимость</ListCell>
      <ListCell col="workAreas">Вид услуги</ListCell>
      <ListCell col="city">Город</ListCell>
      <ListCell col="workPlace">Место работы</ListCell>
      {currentUser.kind === 'contractor' && <ListCell col="status">Отклик</ListCell>}
      {currentUser.kind === 'company' && <ListCell col="published_status"></ListCell>}
    </ListHeader>
  )

  const handleSendEvent = (event: string) => {
    fetchApi({
      url: 'users/events',
      method: 'PUT',
      data: { event },
    })
  }

  return (
    <List cols={cols} gap={isMobile ? '1rem' : 0}>
      {header}
      {offers.map((offer) => (
        <OfferListItem
          url={`/offers/${offer.id}`}
          key={offer.id}
          offer={offerDataFromOffer(offer)}
          withDate={false}
          respond={() => {
            let state = offer.action({ action: 'respond' })
            state.then((data: Data) => offer.setData(data))
            return state
          }}
          buttonText="Откликнуться"
          onOfferClick={handleSendEvent}
        />
      ))}
    </List>
  )
})

const OfferForDateList = observer(
  ({ offers }: { offers: Entity<OfferForDateEntity>[] }) => {
    let { isMobile } = useMediaContext()
    let header = !isMobile && (
      <ListHeader>
        <ListCell col="name">Название</ListCell>
        <ListCell col="company">Компания</ListCell>
        <ListCell col="date">Дата выхода</ListCell>
        <ListCell col="amount">Стоимость</ListCell>
        <ListCell col="workAreas">Специальность</ListCell>
        <ListCell col="city">Город</ListCell>
        <ListCell col="workPlace">Место работы</ListCell>
        <ListCell col="status">Отклик</ListCell>
      </ListHeader>
    )
    return (
      <List cols={cols} gap={isMobile ? '1rem' : 0}>
        {header}
        {offers.map((offer) => (
          <OfferListItem
            url={`/offers/recommendations/${offer.id}`}
            key={offer.id}
            offer={offerDataFromOfferForDate(offer)}
            withDate={true}
            respond={() => {
              let state = offer.action({
                action: 'respond',
                url: `offers/recommendations/${offer.id}/respond`,
              })
              state.then((data: Data) => offer.setData(data))
              return state
            }}
            buttonText="Взять задание"
          />
        ))}
      </List>
    )
  }
)

const OfferResponseList = observer(
  ({ responses }: { responses: Entity<OfferResponseEntity>[] }) => {
    let { isMobile } = useMediaContext()

    let header = !isMobile && (
      <ListHeader>
        <ListCell col="name">Название</ListCell>
        <ListCell col="company">Компания</ListCell>
        <ListCell col="slot">Дата выхода</ListCell>
        <ListCell col="amount">Стоимость</ListCell>
        <ListCell col="workAreas">Вид услуги</ListCell>
        <ListCell col="city">Город</ListCell>
        <ListCell col="workPlace">Место работы</ListCell>
        <ListCell col="status">Отклик</ListCell>
      </ListHeader>
    )

    return (
      <List cols={cols} gap={isMobile ? '1rem' : 0}>
        {header}
        {responses.map((response) => (
          <OfferListItem
            url={`/offers/responses/${response.id}`}
            withDate={true}
            key={response.id}
            offer={offerDataFromResponse(response)}
          />
        ))}
      </List>
    )
  }
)

export { OfferList, OfferForDateList, OfferResponseList }
