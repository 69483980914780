import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'

import { useMediaContext } from 'App/MediaContext'
import { UserRegistrationEntity } from 'entities'
import { Entity, initialActionState } from 'mobx/MobxApi'
import { useUserStore } from 'stores/context'
import registrationApi from 'pages/registration/api'
import { setRegistrationData } from 'pages/registration/forms/utils'
import {
  PaymentDetailsForm,
  PaymentDetailsFormStore,
} from 'pages/contractors/ContractorPaymentDetails'
import { Link, Notification, Button, Flex, H1 } from 'ui'

import css from './styles.module.css'
import RegistrationFooter from 'pages/registration/components/Footer'

interface RegistrationProps {
  registration: Entity<UserRegistrationEntity>
}

const PaymentDetailsStep = observer(({ registration }: RegistrationProps) => {
  let { isMobile } = useMediaContext()
  const currentUser = useUserStore()

  let [submitState, setSubmitState] = useState<any>(initialActionState)

  let submit = () => {
    let { bank_card_number } = store.payload
    if (bank_card_number) bank_card_number = bank_card_number.replaceAll(' ', '')

    const state = registrationApi.submitPaymentDetails({
      ...store.payload,
      bank_card_number,
    })
    state.then((data: any) => {
      setRegistrationData(registration, data, currentUser)
    })

    setSubmitState(state)
  }
  let skip = () => {
    const state = registrationApi.skipPaymentDetails()
    state.then((data: any) => setRegistrationData(registration, data, currentUser))

    setSubmitState(state)
  }

  let [store] = useState(
    () =>
      new PaymentDetailsFormStore({
        methods: registration.data.payment_methods,
        commission: registration.data.payment_commission,
        recipientName: registration.data.self_employed_data?.full_name || '',
      })
  )

  return (
    <>
      <Flex gap="2rem" direction="col">
        <H1>Укажите номер карты</H1>
        {registration.data.error && (
          <>
            <Notification kind="error">{registration.data.error}</Notification>
            <br />
          </>
        )}
        {store.kind === 'bank_account' ? (
          <>
            <div>
              Реквизиты нужны, чтобы получать оплату. Их можно узнать в свойствах счёта в
              приложении вашего банка.
            </div>

            <div style={{ marginTop: '1rem' }}>
              Please contact us at <Link href='mailto:hello@restaff.pro' target='_blank'>hello@restaff.pro</Link> if you have any issues
            </div>
          </>
        ) : (
          <div>Номер карты нужен, чтобы получать оплату</div>
        )}
        <PaymentDetailsForm store={store} />
        {submitState.state === 'rejected' && (
          <div style={{ color: 'var(--color-red)' }}>{submitState.value.message}</div>
        )}
        <Flex
          direction={isMobile ? 'col' : 'row'}
          gap="1rem"
          align={isMobile ? 'start' : 'center'}
          justify="space-between"
        >
          {registration.data.allow_skip_payment_details ? (
            <Button
              variant="text"
              isDisabled={submitState.state === 'pending'}
              onTap={skip}
              className={css.link}
            >
              Заполнить позже
            </Button>
          ) : (
            <div />
          )}
          <Button
            isDisabled={!store.isValid}
            isLoading={submitState.state === 'pending'}
            onTap={submit}
          >
            Сохранить
          </Button>
        </Flex>
      </Flex>
      <RegistrationFooter />
    </>
  )
})

export default PaymentDetailsStep
