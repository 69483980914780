import React, { useEffect } from 'react'
import { observer, useLocalObservable, Observer } from 'mobx-react-lite'
import { action } from 'mobx'
import { fromPromise } from 'mobx-utils'
// @ts-ignore
import { useStyles } from 'floral'

import type { ActionState } from 'mobx/types'

import { FloralProps } from './floral.types'
import Reveal from './Reveal'
import { ErrorPlaceholder, LoadingPlaceholder } from './Placeholder'

type ActionStateViewChildrenFunc = (value: any) => React.ReactNode

interface ActionStateViewProps extends FloralProps {
  animate?: boolean
  state: ActionState<any> | ActionState<any>[]
  Error: React.Component
  Loading: React.Component
  children: React.ReactNode | ActionStateViewChildrenFunc
}

const composeState = (state: ActionState<any> | ActionState<any>[]) =>
  Array.isArray(state) ? fromPromise(Promise.all(state)) : state

const ActionStateView = (props: ActionStateViewProps) => {
  let { state, Error, Loading, children, animate } = props
  let styles = useStyles(null, [props])
  let local = useLocalObservable(() => ({ state: composeState(state) }))
  useEffect(
    action(() => {
      local.state = composeState(state)
    }),
    [state]
  )
  if (local.state.state === 'pending') {
    //@ts-ignore
    return <Loading style={styles.loader} />
  }
  if (local.state.state === 'rejected') {
    //@ts-ignore
    return <Error style={styles.error}>{String(local.state.value)}</Error>
  }
  return (
    <Reveal animation={animate ? 'slideDown' : 'none'}>
      <Observer>
        {() => (
          <>
            {(typeof children === 'function' ? children(local.state.value) : children) ||
              null}
          </>
        )}
      </Observer>
    </Reveal>
  )
}

ActionStateView.defaultProps = {
  animate: true,
  Error: ErrorPlaceholder,
  Loading: LoadingPlaceholder,
}

export default observer(ActionStateView)
