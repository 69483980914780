// @ts-ignore
import { FormStore, FieldStore } from 'shadowform'

import { useStateToast } from 'components/ui'
import { UserRegistrationEntity } from 'entities'
import { omit, pickBy } from 'lodash'
import { makeAutoObservable } from 'mobx'
import { initialActionState, Entity } from 'mobx/MobxApi'
import UserStore from 'stores/UserStore'
import createRegistrationForm from '../forms/createRegistrationForm'
import registrationApi from '../api'
import { setRegistrationData } from '../forms/utils'

export default class RegistrationFormStore {
  constructor(
    registration: Entity<UserRegistrationEntity>,
    toast: ReturnType<typeof useStateToast>,
    currentUser: UserStore
  ) {
    this.registration = registration
    this.toast = toast
    this.currentUser = currentUser

    this.form = createRegistrationForm(registration)

    makeAutoObservable(this)
  }

  currentUser: UserStore
  toast: ReturnType<typeof useStateToast>
  registration: Entity<UserRegistrationEntity>
  form: FormStore
  isChanged: boolean = false
  submitState = initialActionState

  get citizenshipOptions() {
    return this.registration.data.form_options.citizenship_options.map(
      ({ value, name }) => ({ value, label: name })
    )
  }

  formIsLoading(form: FormStore) {
    return Object.values(form.fields).some((f: FieldStore) => f.uploader?.isLoading)
  }

  get isValid() {
    return this.form.isValid && !this.formIsLoading(this.form)
  }

  get isLoading() {
    return this.submitState.state === 'pending'
  }

  getSubmitValues(form: FormStore) {
    return omit(this.form.values, Object.keys(pickBy(form.fields, (f) => f.uploader)))
  }

  get values() {
    let values: { [key: string]: any } = this.getSubmitValues(this.form)
    return values
  }

  submit() {
    this.submitState = registrationApi.submitPersonalData(this.values)
    this.submitState.then(
      (data: any) => setRegistrationData(this.registration, data, this.currentUser),
      (err: any) => this.toast.error({ title: 'Ошибка', description: err.message })
    )
  }
}
