import React from 'react'
import MaskedInput from 'react-text-mask'

import s from './style.module.css'

interface IInputProps {
  value: string
  onChange: (value: string) => void
  onEnter?: () => void
  type?: string
  autoFocus?: boolean
  placeholder?: string
  mask?: any
}

interface IElementProps extends Omit<IInputProps, 'onChange'> {
  className: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void
  guide: boolean
}

const Input = ({
  mask,
  value,
  onChange,
  onEnter,
  type,
  autoFocus,
  placeholder,
  ...restProps
}: IInputProps) => {
  const _placeholder = type === 'email' && !placeholder ? 'Укажите почту' : placeholder

  const element = mask ? MaskedInput : 'input'
  const elementProps: IElementProps = {
    className: s.input,
    value,
    type,
    autoFocus,
    mask,
    guide: false,
    placeholder: _placeholder,
    onChange: (e) => onChange(e.target.value),
    onKeyDown: (e) => {
      if (onEnter && e.key === 'Enter') onEnter()
    },
    ...restProps,
  }

  // @ts-ignore
  return React.createElement(element, elementProps)
}

export default Input
