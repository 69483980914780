import { fetchApi } from 'api'
import { fromPromise } from 'mobx-utils'

export const sendFeedback = (feedback: string) =>
  fromPromise(
    fetchApi({
      withPrefix: false,
      url: 'user/feedback', //TODO: add url
      method: 'POST',
      data: {
        comment: feedback,
      },
    })
  )
