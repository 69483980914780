import React, { forwardRef } from 'react'

import {
  Button,
  ButtonProps,
  ButtonPropsWithDefault,
  ButtonSpinnerProps,
} from 'ui/button'
import { extendComponentStyles, StylesMap } from 'ui/styled'
import { TapState } from 'ui/taply'
import extendProps from 'ui/utils/extendProps'

import { ReactComponent as CloseIcon } from 'ui/konsol/icons/close.svg'
import { ReactComponent as DoneIcon } from 'ui/konsol/icons/done.svg'

import { Spinner } from './spinner'

export type ButtonSize = 's' | 'm' | 'l'

export type ButtonVariant = 'primary' | 'secondary' | 'simple' | 'text' | 'ok' | 'cancel'

interface KonsolButtonExtraProps {
  size?: ButtonSize
  variant?: ButtonVariant
}

interface KonsolButtonProps extends ButtonProps, KonsolButtonExtraProps {}

const konsolButtonDefaultProps = {
  size: 'm' as ButtonSize,
  variant: 'primary' as ButtonVariant,
  Spinner: ({ style }: ButtonSpinnerProps) => <Spinner style={style} />,
}

type KonsolButtonPropsWithDefault = ButtonPropsWithDefault &
  KonsolButtonProps &
  typeof konsolButtonDefaultProps

const konsolButtonStyles = (
  props: KonsolButtonPropsWithDefault,
  tapState: TapState
): StylesMap => {
  const { size, variant, isDisabled, isLoading, children } = props
  const { isFocused, isHovered, isPressed } = tapState

  const root: React.CSSProperties = {
    outline: 'none',
    boxShadow: isFocused ? 'var(--konsol-focus-shadow)' : '',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 6,
    transitionTimingFunction: 'ease-out',
    transitionDuration: '.15s',
    transitionProperty: 'background, opacity',
  }

  if (children === undefined) {
    // кнопка с иконкой
    if (size === 'l') {
      root.minWidth = 48
    } else if (size === 'm') {
      root.minWidth = 40
    } else if (size === 's') {
      root.minWidth = 32
    }
  } else {
    root.padding = variant === 'text' ? '0 16px' : '0 32px'
  }

  if (variant === 'primary') {
    Object.assign(root, {
      color: 'white',
      fill: 'white',
      background: 'var(--konsol-gradient-primary)',
    })
    if (isPressed) {
      root.background = 'var(--konsol-color-button-pressed)'
    } else if (isHovered || isLoading) {
      root.opacity = 0.7
    }
  } else if (variant === 'secondary') {
    Object.assign(root, {
      color: 'var(--konsol-color-text-accent)',
      fill: 'var(--konsol-color-text-accent)',
    })
    if (isPressed) root.background = 'var(--konsol-color-blue-24)'
    else if (isHovered) root.background = 'var(--konsol-color-blue-16)'
    else root.background = 'var(--konsol-color-blue-08)'
  } else if (variant === 'simple') {
    Object.assign(root, {
      color: 'var(--konsol-color-text)',
      fill: 'var(--konsol-color-text)',
    })
    if (isPressed) root.background = 'var(--konsol-color-grey-24)'
    else if (isHovered) root.background = 'var(--konsol-color-grey-16)'
    else root.background = 'white'
  } else if (variant === 'text') {
    Object.assign(root, {
      color: 'var(--konsol-color-text-accent)',
      fill: 'var(--konsol-color-text-accent)',
    })
    if (isPressed) root.background = 'var(--konsol-color-blue-16)'
    else if (isHovered) root.background = 'var(--konsol-color-blue-08)'
    else root.background = 'transparent'
  } else if (variant === 'ok') {
    Object.assign(root, {
      color: 'var(--konsol-color-green)',
      fill: 'var(--konsol-color-green)',
    })
    if (isPressed) root.background = 'var(--konsol-color-green-16)'
    else if (isHovered) root.background = 'var(--konsol-color-green-08)'
    else root.background = 'transparent'
  } else if (variant === 'cancel') {
    Object.assign(root, {
      color: 'var(--konsol-color-red)',
      fill: 'var(--konsol-color-red)',
    })
    if (isPressed) root.background = 'var(--konsol-color-red-16)'
    else if (isHovered) root.background = 'var(--konsol-color-red-08)'
    else root.background = 'transparent'
  }

  if (isDisabled) {
    root.background = variant === 'text' ? 'transparent' : 'var(--konsol-color-grey-16)'
    root.color = 'var(--konsol-color-text-disabled)'
  }

  if (size === 'l') {
    Object.assign(root, { minHeight: 48, fontSize: 'var(--konsol-font-size)' })
  } else if (size === 'm') {
    Object.assign(root, { minHeight: 40, fontSize: 'var(--konsol-font-size)' })
  } else if (size === 's') {
    Object.assign(root, { minHeight: 30, fontSize: 'var(--konsol-font-size-small)' })
  }

  return { root }
}

const KonsolButton = extendComponentStyles(
  extendProps<typeof Button, KonsolButtonExtraProps>(Button, konsolButtonDefaultProps),
  konsolButtonStyles
)

type OkButtonProps = Pick<
  KonsolButtonProps,
  'children' | 'onTap' | 'isDisabled' | 'isLoading' | 'as' | 'className' | 'size'
>

const OkButton = forwardRef<HTMLDivElement, OkButtonProps>((props, ref) => (
  <KonsolButton ref={ref} icon={<DoneIcon />} variant="ok" {...props} />
))

type CancelButtonProps = OkButtonProps

const CancelButton = forwardRef<HTMLDivElement, CancelButtonProps>((props, ref) => (
  <KonsolButton ref={ref} icon={<CloseIcon />} variant="cancel" {...props} />
))

export { KonsolButton as Button, OkButton, CancelButton }
