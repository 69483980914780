import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'

import { Collection, Entity } from 'mobx/MobxApi'
import { SubtaskTemplateEntity } from 'entities'

import { Amount } from 'components'
import { Flex, SelectInput } from 'components/ui'
import { thinsp } from 'utils/typo'
import ButtonSelectAddWork from './components/ButtonSelectAddWork'
import { useUserStore } from 'stores/context'

interface SubtaskTemplateSelectorProps
  extends Omit<React.ComponentProps<typeof SelectInput>, 'options'> {
  templates?: Collection<SubtaskTemplateEntity>
  value?: number | Object
  onChange?: (id: number) => void
  onEnter?: (value: string) => void
  isCompany?: boolean
}

const SubtaskTemplateOption = observer(
  ({ template }: { template: Entity<SubtaskTemplateEntity> }) => (
    <div style={{ width: '100%' }}>
      <Flex justify='space-between'>
        <div>{template.data.id}. {template.data.title}</div>
        <Flex style={{ flexWrap: 'nowrap', whiteSpace: 'nowrap' }}>
          <Amount
            value={{ value: template.data.unit_cost, currency: template.data.currency }}
          />
          {thinsp}/{thinsp}
          {template.data.unit.title.toLowerCase()}
        </Flex>
      </Flex>
      <div style={{ color: 'grey', fontSize: '12px' }}>{template.data.description}</div>
    </div>
  )
)

const SubtaskTemplateSelector = observer(
  ({ templates, value, onChange, onEnter, ...restProps }: SubtaskTemplateSelectorProps) => {
    const currentUser = useUserStore()
    const [inputValue, setInputValue] = useState('')

    const isCompany = currentUser.kind === 'company'

    let options = templates
      ? templates.items.map((template: any) => ({
        value: template.id,
        template,
        label: template.data.title,
        description: template.data.description
      }))
      : []
    let selectValue: any
    if (value) {
      if (typeof value === 'number') {
        selectValue = options.find((option: { value: number }) => value === option.value)
      } else if (typeof value === 'object') {
        selectValue = value
      }
    } else {
      selectValue = null
    }

    const filterOption = (option: any, inputValue: any) => {
      const { label, data } = option;
      return label.indexOf(inputValue) > -1 || data.description.indexOf(inputValue) > -1
    };

    const onCreateTask = (e: KeyboardEvent) => {
      if (isCompany) {
        if (e.key === 'Enter' && onEnter) {
          const isEmpty = options.filter(item => item.label.indexOf(inputValue) > -1).length === 0
          if (inputValue.length > 0 && isEmpty) {
            e.preventDefault()
            onEnter(inputValue)
          }
        }
      }

      // NEED FIX THIS
      if (e.key === 'Backspace' && inputValue.length === 0) {
        e.preventDefault()
      }
    }

    const noOptionsMessage = () => isCompany ? <ButtonSelectAddWork onClick={() => onEnter && onEnter(inputValue)} /> : 'Ничего не найдено'

    return (
      <SelectInput
        onInputChange={setInputValue}
        onKeyDown={onCreateTask}
        placeholder="Вид работы"
        noOptionsMessage={noOptionsMessage}
        isClearable={true}
        components={{
          Option: (props: any) => (
            <SubtaskTemplateOption template={props.option.data.template} />
          ),
          ...restProps.components,
        }}
        {...restProps}
        options={options}
        value={selectValue}
        onChange={(option: any) => onChange && onChange(option && option.value)}
        filterOption={filterOption}
      />
    )
  }
)

export default SubtaskTemplateSelector
