import React from 'react'
import clsx from 'clsx'

import css from './styles.module.css'

interface TLinkProps {
  href?: string
  onClick?: () => void
  className?: string
  style?: Record<string, number | string>
  target?: '_blank'
  icon?: React.ReactNode
  variant?: 'normal' | 'dashed'
  children?: string | React.ReactNode
  download?: boolean
}

export const Link = ({
  href,
  onClick,
  target,
  className,
  style,
  icon,
  variant = 'normal',
  children,
  download = false,
}: TLinkProps) => {
  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault()
    if (onClick) onClick()
  }

  const linkContent = (
    <>
      {icon && <span className={css.icon}>{icon}</span>}
      <span className={clsx(css.content, icon && css.withIcon)}>{children}</span>
    </>
  )

  // если относительный урл, подключаем Link из react-router-dom
  // if (href && href?.indexOf('http') === -1) {
  //   return (
  //     <a
  //       onClick={(e) => {
  //         e.preventDefault()
  //         history.push(href)
  //       }}
  //       className={clsx(css.link, className, css[variant])}
  //       style={style}
  //     >
  //       {linkContent}
  //     </a>
  //   )
  // }

  return (
    <a
      href={href}
      onClick={onClick && handleClick}
      target={target}
      rel="noopener noreferrer"
      className={clsx(css.link, className, css[variant])}
      style={style}
      download={download}
    >
      {linkContent}
    </a>
  )
}
