import React, { useState } from 'react'
import cl from 'clsx'
import KebabMenu, { KebabMenuItem } from './KebabMenu'
import styles from './styles.module.css'

interface KebabProps {
  menuItems: KebabMenuItem[]
  className?: string
}

const Kebab = (props: KebabProps) => {
  const [isActive, setActive] = useState(false)

  const createClickHandler =
    (newState = !isActive) =>
    () =>
      setActive(newState)

  return (
    <>
      {isActive && (
        <div className={styles.backdrop} onClick={createClickHandler(false)} />
      )}
      <button
        className={cl(
          styles.root,
          isActive && styles.active,
          props.className && props.className
        )}
        onClick={createClickHandler()}
      >
        <svg
          width="3"
          height="15"
          viewBox="0 0 3 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M1.6 3.05C0.964872 3.05 0.45 2.53513 0.45 1.9C0.45 1.26487 0.964873 0.75 1.6 0.75C2.23513 0.75 2.75 1.26487 2.75 1.9C2.75 2.53513 2.23513 3.05 1.6 3.05ZM1.6 8.05C0.964872 8.05 0.45 7.53513 0.45 6.9C0.45 6.26487 0.964872 5.75 1.6 5.75C2.23513 5.75 2.75 6.26487 2.75 6.9C2.75 7.53513 2.23513 8.05 1.6 8.05ZM0.45 11.9C0.45 12.5351 0.964872 13.05 1.6 13.05C2.23513 13.05 2.75 12.5351 2.75 11.9C2.75 11.2649 2.23513 10.75 1.6 10.75C0.964872 10.75 0.45 11.2649 0.45 11.9Z"
            fill="#272E40"
          />
        </svg>
        {isActive && <KebabMenu items={props.menuItems} />}
      </button>
    </>
  )
}

export default Kebab
