import React, { useEffect, useState } from 'react'
import { Switch, Route, Redirect, useLocation } from 'react-router-dom'
import Sidebar from 'react-sidebar'
import { observer } from 'mobx-react-lite'
import { compact } from 'lodash'

import { useMediaContext } from 'App/MediaContext'
import { useUserStore } from 'stores/context'

import { SideBar as UiSideBar } from 'components'
import MobileHeader from 'components/MobileHeader'
import TaskPage from 'pages/tasks/TaskPage'
import SetPasswordPage from 'components/SetPasswordPage'
import GlobalBanners from 'components/GlobalBanners'

// import ScenarioTaskPage from 'pages/scenario_tasks/PageCompanyTasks'

import { TaskPage as ScenarioTaskPage } from 'pages/scenario_tasks'

import { queryObject, Url } from 'utils/url'
import GlobalNotification from 'components/GlobalNotification'

const UserRoutes = observer(() => {
  let location = useLocation()
  let [isSidebarOpen, onSetSidebarOpen] = useState(window.innerWidth > 1100)
  let [isTransitionEnabled, setTransitionEnabled] = useState(false)
  let { isWide } = useMediaContext()
  let currentUser = useUserStore()

  useEffect(() => onSetSidebarOpen(isWide), [isWide])
  useEffect(() => {
    setTimeout(() => setTransitionEnabled(true), 300)
  }, [])
  useEffect(() => {
    !isWide && onSetSidebarOpen(false)
  }, [location, isWide])
  useEffect(() => {
    // disable scroll on mobile when sidebar is open
    document.getElementsByTagName('html')[0].style.overflow =
      !isWide && isSidebarOpen ? 'hidden' : 'auto'
  }, [isWide, isSidebarOpen])

  let routes = [
    // <Route path="/acts/:id(\d+)" component={require('pages/tasks/TaskPage').default} />,
    // Помощь
    <Route path="/help" exact component={require('pages/help/index').HelpPage} />,
  ]

  if (currentUser.kind === 'contractor') {
    let contractorRoutes = [
      // Задания
      <Route
        path="/tasks"
        exact
        component={
          currentUser.user!.data.flags.scenario_tasks
            ? require('pages/scenario_tasks').TasksPage
            : require('pages/tasks/TaskListPage').TaskListPage
        }
      />,
      <Route
        path="/tasks/page/:page"
        exact
        component={
          currentUser.user!.data.flags.scenario_tasks
            ? require('pages/scenario_tasks').TasksPage
            : require('pages/tasks/TaskListPage').TaskListPage
        }
      />,
      <Route
        path="/scenario_tasks/:id(\d+)"
        component={require('pages/scenario_tasks').TaskPage}
      />,
      <Route
        path="/tasks/:id(\d+)"
        component={require('pages/tasks/TaskPage').default}
      />,

      // Акты
      // <Route
      //   path="/acts"
      //   exact
      //   component={require('pages/tasks/TaskListPage').ActListPage}
      // />,
      // <Route
      //   path="/acts/page/:page"
      //   exact
      //   component={require('pages/tasks/TaskListPage').ActListPage}
      // />,
      <Route
        path="/dashboard"
        exact
        component={require('pages/dashboard/DashboardPage').default}
      />,
      <Route
        path="/dashboard/scenario_tasks/:id"
        exact
        render={(props) => (
          <ScenarioTaskPage
            {...props}
            backLink={{ to: '/dashboard', title: 'На главную' }}
          />
        )}
      />,
      <Route
        path="/dashboard/tasks/:id"
        exact
        render={(props) => (
          <TaskPage {...props} backLink={{ to: '/dashboard', title: 'На главную' }} />
        )}
      />,
      <Route
        path="/dashboard/acts/:id"
        exact
        render={(props) => (
          <TaskPage {...props} backLink={{ to: '/dashboard', title: 'На главную' }} />
        )}
      />,
      <Route
        path="/registration/completed"
        exact
        component={require('pages/registration/RegistrationCompletedPage').default}
      />,
      <Route
        path="/notifications"
        exact
        component={require('pages/notifications/NotificationsPage').default}
      />,

      // Компании
      <Route
        exact
        path="/companies"
        component={require('pages/companies/index').default}
      />,
      <Route
        path="/companies/:id"
        component={require('pages/companies/CompanyPage').ContractorCompanyPage}
      />,

      // Профиль контрактора
      <Route
        path="/contractors/:id"
        component={require('pages/contractors/ContractorPage').default}
      />,

      // Поиск заданий
      // <Route
      //   path="/offers"
      //   exact
      //   component={require('pages/offers/ContractorOffersPage').default}
      // />,
      // <Route
      //   path="/offers/recommendations/:id"
      //   component={require('pages/offers/OfferPage').OfferForDatePage}
      // />,
      // <Route
      //   path="/offers/responses/:id"
      //   component={require('pages/offers/OfferPage').OfferResponsePage}
      // />,
      // <Route
      //   path="/offers/:id"
      //   component={require('pages/offers/OfferPage').OfferPage}
      // />,

      // Налоговая копилка
      currentUser.contractorTaxBox && !currentUser.taxBoxIsFlexible && (
        <Route
          path="/taxes"
          exact
          component={require('pages/taxes/TaxBoxPage').default}
        />
      ),
      currentUser.contractorTaxSavingsAccount && (
        <Route
          path="/taxes"
          exact
          component={require('pages/taxes/TaxAccountPage').default}
        />
      ),
      currentUser.taxBoxIsFlexible && (
        <Route path="/taxes" exact component={require('pages/taxes').FlexibleTaxBox} />
      ),
      currentUser.taxBoxIsFlexible && (
        <Route
          path="/taxes/settings"
          exact
          component={require('pages/taxes').FlexibleTaxBoxSettings}
        />
      ),
      // Создание актов
      // <Route
      //   path="/acts/new"
      //   exact
      //   component={require('pages/tasks/TaskCreatePage').ContractorActCreatePage}
      // />,
    ]
    routes.push(...compact(contractorRoutes))
  } else {
    let companyRoutes = [
      // Создание актов
      // <Route
      //   path="/acts/new"
      //   exact
      //   component={require('pages/tasks/TaskCreatePage').ActCreatePage}
      // />,
      // // Акты
      // <Route
      //   exact
      //   path="/acts"
      //   component={require('pages/tasks/CompanyTaskListPage').ActListPage}
      // />,
      // <Route
      //   exact
      //   path="/acts/:state"
      //   component={require('pages/tasks/CompanyTaskListPage').ActListPage}
      // />,
      // <Route
      //   exact
      //   path="/acts/page/:page"
      //   component={require('pages/tasks/CompanyTaskListPage').ActListPage}
      // />,
      // <Route
      //   exact
      //   path="/acts/page/:page/:state"
      //   component={require('pages/tasks/CompanyTaskListPage').ActListPage}
      // />,
      // Аналитика рисков
      currentUser.hasFeature('contractors_risk_profiles') && (
        <Route path="/analytics" component={require('pages/analytics').default} />
      ),
      !currentUser.hasFeature('contractors_risk_profiles') &&
        currentUser.hasFeature('contractors_risk_placeholder') && (
          <Route
            path="/analytics"
            component={require('pages/analytics_banner').default}
          />
        ),
      // Сценарии
      <Route
        exact
        path="/onboarding_scenarios"
        component={require('pages/onboarding_scenarios').default}
      />,
      // Контракторы
      <Route
        path="/contractors/:contractorId/tasks/:id"
        render={(props) => {
          const { back } = queryObject(Url(props.location.search))
          const to = `/contractors/${props.match.params.contractorId}#${back}`

          return (
            <TaskPage
              {...props}
              backLink={{
                to,
                title: 'К исполнителю',
              }}
            />
          )
        }}
      />,
      <Route
        path="/contractors/:id(\d+)"
        component={require('pages/contractors/ContractorPage').default}
      />,
      <Route
        path="/contractors"
        component={require('pages/contractors/ContractorListPage').default}
      />,

      // Приглашения
      <Route path="/invites" component={require('pages/invites/InvitesPage').default} />,

      // Документы
      currentUser.hasFeature('documents') && (
        <Route
          path="/documents"
          component={require('pages/documents/DocumentsPage').default}
        />
      ),

      // Текущая компания
      <Route
        path="/company"
        component={require('pages/companies/CompanyPage').MemberCompanyPage}
      />,

      // Пользователь
      <Route path="/profile" component={require('pages/profile').default} />,

      // Справочник работ
      currentUser.hasFeature('templates') && (
        <Route
          path="/templates"
          component={require('pages/templates/TemplateListPage').default}
        />
      ),

      <Route
        path="/auth/tinkoff"
        exact
        component={require('pages/tinkoff/auth').default}
      />,
      <Route
        path="/auth/tinkoff/complete"
        exact
        component={require('pages/tinkoff/complete').default}
      />,
    ]

    // Поиск исполнителей
    if (currentUser.hasFeature('offers')) {
      companyRoutes.push(
        <Route
          path="/offers"
          exact
          component={require('pages/offers/CompanyOffersPage').default}
        />,
        <Route
          path="/offers/responses/:id"
          component={require('pages/offers/CompanyOfferResponsePage').default}
        />,
        <Route
          path="/offers/:id"
          component={require('pages/offers/OfferPage').OfferPage}
        />
      )
    }

    // Новые проекты и задания
    if (currentUser.hasFeature('scenario_tasks')) {
      companyRoutes.push(
        <Route
          path="/projects"
          exact
          component={require('pages/scenario_projects').default}
        />,
        <Route
          path="/projects/:id"
          exact
          component={require('pages/scenario_projects').default}
        />,
        <Route
          path="/projects/:projectId/tasks/create"
          exact
          component={require('pages/scenario_tasks/PageCompanyTasksEdit').default}
        />,
        <Route
          path="/projects/:projectId/tasks/:id"
          exact
          component={require('pages/scenario_tasks/PageCompanyTasksEdit').default}
        />,
        // Создание, редактирование заданий
        <Route
          path="/tasks/create"
          component={require('pages/scenario_tasks/PageCompanyTasksEdit').default}
        />,
        <Route
          path="/tasks"
          exact
          component={require('pages/scenario_tasks/PageCompanyTasks').default}
        />,
        <Route
          path="/tasks/:id(\d+)"
          exact
          component={require('pages/scenario_tasks/PageCompanyTasksEdit').default}
        />
      )
    } else if (currentUser.hasFeature('projects')) {
      // Старые проекты и задания
      companyRoutes.push(
        // создание таска
        currentUser.hasAbility('tasks.create') && (
          <Route
            path="/tasks/new"
            component={require('pages/tasks/TaskCreatePage').TaskCreatePage}
          />
        ),
        // редактирование задачи
        <Route
          path="/tasks/:id(\d+)"
          component={require('pages/tasks/TaskPage').default}
        />,
        // список задач
        <Route
          path="/tasks"
          component={require('pages/tasks/CompanyTaskListPage').TaskListPage}
        />,
        // проекты
        <Route
          path="/projects"
          exact
          component={require('pages/bundles/BundleListPage').ProjectListPage}
        />,
        <Route
          path="/projects/page/:page"
          exact
          component={require('pages/bundles/BundleListPage').ProjectListPage}
        />,
        currentUser.hasAbility('tasks.create') && (
          <Route
            path="/projects/:project_id/tasks/new"
            exact
            component={require('pages/tasks/TaskCreatePage').TaskCreatePage}
          />
        ),
        <Route
          path="/projects/:project_id/tasks/:id"
          render={(props) => (
            <TaskPage
              {...props}
              backLink={{
                to: `/projects/${props.match.params.project_id}`,
                title: 'К проекту',
              }}
            />
          )}
        />,
        <Route
          path="/projects/:project_id/acts/:id"
          render={(props) => (
            <TaskPage
              {...props}
              backLink={{
                to: `/projects/${props.match.params.project_id}`,
                title: 'К проекту',
              }}
            />
          )}
        />,
        <Route
          path="/projects/:id"
          component={require('pages/bundles/BundlePage').ProjectPage}
        />
      )
    }

    // Пачки актов
    // if (currentUser.hasFeature('bundles')) {
    //   companyRoutes.push(
    //     <Route
    //       path="/bundles"
    //       exact
    //       component={require('pages/bundles/BundleListPage').ActBundleListPage}
    //     />,
    //     <Route
    //       path="/bundles/page/:page"
    //       exact
    //       component={require('pages/bundles/BundleListPage').ActBundleListPage}
    //     />,
    //     <Route
    //       path="/bundles/:id"
    //       exact
    //       component={require('pages/bundles/BundlePage').ActBundlePage}
    //     />,
    //     <Route
    //       path="/bundles/:bundle_id/acts/:id"
    //       render={(props) => (
    //         <TaskPage
    //           {...props}
    //           backLink={{
    //             to: `/bundles/${props.match.params.bundle_id}`,
    //             title: 'К пачке актов',
    //           }}
    //         />
    //       )}
    //     />
    //   )
    // }

    routes.push(...compact(companyRoutes))
  }

  let banners = (
    <>
      {/* NEED FIX */}
      <GlobalBanners />
      <GlobalNotification />
    </>
  )

  // if not password
  if (currentUser.user?.data.need_password) {
    return <SetPasswordPage userEmail={currentUser.user?.data.email} />
  }

  return (
    <Sidebar
      shadow={false}
      touch={false}
      sidebar={
        <UiSideBar
          onOpen={onSetSidebarOpen}
          isOpen={isSidebarOpen}
          showCloseButton={false}
        />
      }
      open={isSidebarOpen}
      docked={isWide}
      transitions={isTransitionEnabled}
      onSetOpen={onSetSidebarOpen}
      styles={
        isWide
          ? {
              sidebar: { background: 'white', overflowY: 'initial', zIndex: '402' },
              overlay: { zIndex: '401' },
              content: { overflow: 'auto' },
            }
          : {
              root: { position: 'static', overflow: 'visible' },
              sidebar: {
                background: 'white',
                overflowY: 'initial',
                zIndex: '402',
                position: 'fixed',
                height: '100%',
              },
              overlay: { zIndex: '401' },
              content: { position: 'static', overflow: 'visible' },
            }
      }
    >
      {isWide ? (
        banners
      ) : (
        <MobileHeader onOpenSidebar={() => onSetSidebarOpen(true)} banners={banners} />
      )}
      <Switch>
        {routes}
        <Redirect exact to="/not-found" />
      </Switch>
    </Sidebar>
  )
})

export default UserRoutes
