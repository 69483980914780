import React from 'react'
import s from './style.module.css'
import { getInitials, getColor } from './utils'
import { CSSProperties } from 'styled-components'
import cl from 'clsx'

interface Props {
  size?: string
  name: string
  className?: string
  style?: CSSProperties
}
export default function GeneratedAvatar({ name, className, style }: Props) {
  const initials = name && getInitials(name)
  const { color, background } = getColor(name) || {
    color: 'var(--color-secondary)',
    background: 'var(--color-avatar-bg)',
  }
  return (
    <div className={cl(s.generated, className)} style={{ ...style, color, background }}>
      {initials}
    </div>
  )
}
