import React from 'react'

import { Flex, Button } from 'ui'

import EditUnitForm from './components/EditUnitForm'
import { map, sum } from 'lodash'
import { Amount } from 'components'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { makeAutoObservable, toJS } from 'mobx'

import { List, ListCell, ListHeader } from 'components/ui/List'
import { Placeholder } from 'components/ui'

interface IUnitsProps {
  data: any
  unitOptions: any[]
  onChange: (units: any) => void
}

interface IUnit {
  id: number
  title: string
  cost: number
  unit_type: string | null
  quantity: number
}

const Units = observer(({ data, unitOptions, onChange }: IUnitsProps) => {
  const store = useLocalObservable(
    () =>
      new (class {
        _unitMap = new Map<number, IUnit>()

        get unitList() {
          return [...this._unitMap.values()]
        }

        get totalAmount() {
          return sum(
            map(
              this.unitList,
              (item: any) => parseFloat(item.quantity) * parseFloat(item.cost)
            )
          )
        }

        createIndex = () => {
          return Math.max(...this.unitList.map(({ id }) => id), 0) + 1
        }

        constructor() {
          makeAutoObservable(this)
          if (data) {
            data.forEach((unit: any) => this._unitMap.set(unit.id, unit))
          }
        }

        createUnit = () => {
          const id = this.createIndex()
          this._unitMap.set(id, {
            id,
            title: '',
            cost: 0,
            unit_type: null,
            quantity: 0,
          })
          onChange(toJS(this.unitList))
        }

        removeUnit = (id: number) => {
          this._unitMap.delete(id)
          onChange(toJS(this.unitList))
        }

        updateUnit = (id: number, unit: IUnit) => {
          this._unitMap.set(id, { ...unit, id })
          onChange(toJS(this.unitList))
        }
      })()
  )

  const { totalAmount, unitList } = store

  const cols = {
    quantity: {
      width: (fullWidth: number) => (fullWidth - 135) / 4,
    },
    unit: {
      width: (fullWidth: number) => (fullWidth - 135) / 4,
    },
    cost: {
      width: (fullWidth: number) => (fullWidth - 135) / 4,
    },
    title: {
      width: (fullWidth: number) => (fullWidth - 135) / 4,
    },
    remove: {
      width: () => 35,
    },
  }

  return (
    <>
      <Flex justify="space-between" align="center">
        <Flex gap="16px" align="center">
          <div>Работы</div>
          <Button variant="text" size="s" onClick={store.createUnit}>
            Добавить работу
          </Button>
        </Flex>
        <div>
          <b>
            Итого:{' '}
            <Amount
              value={{ value: String(totalAmount), currency: 'RUB' }}
              style={{ fontWeight: 600 }}
            />
          </b>
        </div>
      </Flex>
      <List cols={cols} style={{ marginTop: '20px', minHeight: '425px' }}>
        <ListHeader style={{ padding: 0, marginBottom: '10px' }}>
          <ListCell col="title">Наименование</ListCell>
          <ListCell col="cost">Цена</ListCell>
          <ListCell col="unit">Единица измерения</ListCell>
          <ListCell col="quantity">Количество</ListCell>
          <ListCell col="remove" />
        </ListHeader>
        {unitList.map((unit: any, idx: any) => (
          <EditUnitForm
            key={idx}
            data={unit}
            unitOptions={unitOptions}
            onChange={(data: any) => store.updateUnit(unit.id, data)}
            onRemove={() => store.removeUnit(unit.id)}
          />
        ))}
        {!unitList.length && <Placeholder>Добавьте работы в задание</Placeholder>}
      </List>
    </>
  )
})

export default Units
