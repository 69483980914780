import React from 'react'
import { Button, P1 } from 'ui-kit'
import { Modal } from 'ui-kit'

import css from './styles.module.css'

import howToPayUrl from '../../how-to-pay.png'

type SelfPayModalProps = {
  open: boolean
  onClose: () => void
}

const SelfPayModal: React.FunctionComponent<SelfPayModalProps> = ({ open, onClose }) => (
  <Modal
    title="Как оплатить налог самостоятельно"
    className={css.modal}
    open={open}
    onClose={onClose}
  >
    <P1 className={css.paragraph}>
      1. Установите на телефон официальное приложение ФНС для самозанятыx – «Мой налог».
      <span className={css.linksWrapper}>
        {/* TODO: Refactor modal component so it could accept links */}
        <a
          href="https://apps.apple.com/ru/app/мой-налог/id1437518854"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className={css.appStoreBadge} />
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=com.gnivts.selfemployed"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className={css.googlePlayBadge} />
        </a>
      </span>
    </P1>
    <P1 className={css.paragraph}>2. Войдите указав ваш ИНН или номер телефона.</P1>
    <P1 className={css.paragraph}>3. Оплатите налог удобным для вас способом.</P1>
    <img src={howToPayUrl} alt="" />
    <Button onClick={onClose} label="Понятно" fullWidth></Button>
  </Modal>
)

export default SelfPayModal
