import React from 'react'
import { Link } from 'react-router-dom'

import { Placeholder } from 'components/ui'

export default function NotFoundPage() {
  return (
    <Placeholder style={{ height: '100%' }}>
      <div>Ничего такого нет. Не опечатались?</div>
      <Link
        style={{
          fontSize: 16,
          color: 'var(--color-active)',
          fontWeight: 'normal',
          marginTop: '5rem',
          display: 'block',
        }}
        to="/"
      >
        На главную страницу
      </Link>
    </Placeholder>
  )
}
