import dayjs from './dayjs'

import { nbsp } from './typo'

import { Time } from '@internationalized/date';

const shortFormat = `D${nbsp}MMM`

const longFormat = `DD${nbsp}MMM${nbsp}YYYY`

/**
 * Правка парсера даты после https://app.clickup.com/t/2n9ytj7
 * Так как легаси код сохраняет даты через строковый шаблон, иногда,
 * со стороны backend на prod дата прилетает как 10.05.2012
 */
export const parseDate = (str: string) => {
  if (str.match(/(?:\d\d\.\d\d\.\d\d\d\d)/g)) {
    return dayjs(str, 'DD.MM.YYYY', true);
  } else {
    return dayjs(str, 'YYYY-MM-DD', true);
  }
};

export const serializeDate = (date: Date, tmpl = `YYYY-MM-DD`) => dayjs(date).format(tmpl)

type PrintOptions = { mode?: 'short' | 'full' }

export const serializeTime = (time: Time) => {
  let h = time.hour.toString();
  let m = time.minute.toString();
  h = h.length === 1 ? '0' + h : h;
  m = m.length === 1 ? '0' + m : m;
  return `${h}:${m}`;
};

export const formatTime = (time: string | undefined | null) => {
  if (!time) {
    return '...';
  }
  let [h, m] = time.split(':')
  if (!h || !m) {
    return '...';
  }
  h = h.length === 1 ? '0' + h : h;
  m = m.length === 1 ? '0' + m : m;
  return `${h}:${m}`;
};

export const fromNow = (date: Date | string) => {
  const now = dayjs()
  return now.diff(date, 'day')
}

// YYYY-MM-DD => 1 окт. || 1 окт. 1999г.
export const printDate = (
  date: Date | string | null | undefined,
  { mode = 'full' }: PrintOptions | undefined = {}
) => {
  if (!date) return ''
  let dayjsDate = date instanceof Date ? dayjs(date) : parseDate(date)
  return dayjsDate.format(mode === 'short' ? shortFormat : longFormat)
}

export const printPeriod = (
  since: Date | string | null | undefined,
  to: Date | string | null | undefined,
  short = false
) => {
  const mode = short ? 'short' : 'full'
  if (!since || !to) return ''
  if (since === to) return printDate(since, { mode })
  let [fromStr, toStr] = [printDate(since, { mode }), printDate(to, { mode })]
  return short ? `${fromStr} – ${toStr}` : `с ${fromStr} по ${toStr}`
}

export const iso8601toTime = (date: string) => dayjs(date).unix()

// iso8601 => 1 окт. || 1 окт. 1999г.
export const printIsoDate = (
  date: Date | string | null | undefined,
  short: boolean = true
) => {
  if (!date) return ''
  return dayjs(date).format(short ? shortFormat : longFormat)
}

// iso8601 => 1 окт. 12:53
export const printIsoDateTime = (date: string | Date | undefined) => {
  if (!date) return ''
  return dayjs(date).format(`DD.MM.YYYY в HH:mm`)
}
