import React, { useEffect, useState } from 'react'
import { fromPromise } from 'mobx-utils'
import { ActionState } from 'mobx/types'
import { observer } from 'mobx-react-lite'

import { useUserStore } from 'stores/context'
import { fetchApi } from 'api'

import { LayoutSimple } from 'components/ui'
import { Title, Input, FormGroup } from 'components/ui/LayoutSimple'

import s from './style.module.css'

const savePassword = (password: string, email: string) =>
  fetchApi({
    method: 'POST',
    url: 'company/user/profile/password',
    withPrefix: false,
    data: {
      password,
      email,
    },
  })

const SetPasswordPage = observer(({ userEmail = '' }: { userEmail?: string }) => {
  let currentUser = useUserStore()

  const [password, setPassword] = useState('')
  const [repeatPassword, setRepeatPassword] = useState('')
  const [email, setEmail] = useState(userEmail)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<any>()
  const [systemError, setSystemError] = useState<any>()
  const [checkState, setCheckState] = useState<ActionState<any>>(fromPromise.resolve())

  const onSubmit = () => {
    if (password && repeatPassword && email) {
      if (password && password !== repeatPassword) {
        setError(<>Пароли не совпадают</>)
        return
      }

      setIsLoading(true)
      setError('')
      setSystemError('')
      const state = fromPromise(savePassword(password, email))
      state.then(
        () => {
          setIsLoading(false)
          window.location.replace('/')
        },
        () => {
          setIsLoading(false)
        }
      )
      setCheckState(state)
    }
  }

  useEffect(() => {
    if (checkState.state === 'rejected') {
      // @ts-ignore
      setSystemError(<span className={s.errorText}>{checkState.value.message}</span>)
    }
  }, [checkState.state])

  useEffect(() => {
    if (error || systemError) {
      setError('')
      setSystemError('')
    }
  }, [password, repeatPassword, email])

  const userLogout = () => {
    currentUser.logout()
  }

  return (
    <LayoutSimple
      notice={
        <>
          Мы&nbsp;постоянно работаем над улучшением безопасности в&nbsp;Консоли,
          и&nbsp;с&nbsp;сегодняшнего дня вводится двухфакторная аутентификация для
          сотрудников компаний
        </>
      }
    >
      <Title>
        Придумайте пароль
        <br />
        для&nbsp;входа
      </Title>
      <FormGroup
        error={systemError}
        isLoading={isLoading}
        isDisabled={!email || !password || !repeatPassword || error || systemError}
        submitText="Сохранить"
        onSubmit={onSubmit}
        additionalLink={
          <button type="button" onClick={userLogout}>
            Войти с&nbsp;другим номером
          </button>
        }
      >
        <Input
          value={password}
          onChange={setPassword}
          type="password"
          placeholder="Введите пароль"
          onEnter={() => {
            if (password && repeatPassword && email) {
              onSubmit()
            }
          }}
          autoFocus
        />
        <Input
          value={repeatPassword}
          onChange={setRepeatPassword}
          type="password"
          placeholder="Введите пароль еще раз"
          onEnter={() => {
            if (password && repeatPassword && email) {
              onSubmit()
            }
          }}
        />
        {error && <div className={s.error}>{error}</div>}
        <div className={s.email}>
          <Input
            value={email}
            onChange={setEmail}
            type="email"
            onEnter={() => {
              if (password && repeatPassword && email) {
                onSubmit()
              }
            }}
          />
          <div className={s.notice}>
            Почта нужна, чтобы восстановить пароль, если вы&nbsp;его забыли
          </div>
        </div>
      </FormGroup>
    </LayoutSimple>
  )
})

export default SetPasswordPage
