import React from 'react'

import { useMemo } from 'react'

import { PageProps } from 'app.types'
import { useStateToast } from 'components/ui'
import { createProvider } from 'ui'
import { makeAutoObservable } from 'mobx'

import MobxApi from 'mobx/MobxApi'
import { useMobxApi } from 'mobx/MobxApiContext'
import { fetchApi } from 'api'
import { ActionState } from 'mobx/types'
import { fromPromise } from 'mobx-utils'

interface IPageParams {
  mobxApi: MobxApi
  pageProps: PageProps
  toast: ReturnType<typeof useStateToast>
}

type FILTER_BY = 'company' | 'company_onboarding_scenario' | 'segment'

export class AnalyticsManager {
  fetchDataTask?: ActionState<any>

  get toast() {
    return this.params.toast
  }

  constructor(readonly params: IPageParams) {
    makeAutoObservable(this)
  }

  private createFetchDataTask = (filter_by: FILTER_BY, filter_value: string | null) =>
    fromPromise(
      (async () => {
        try {
          const data = await fetchApi({
            url: 'contractor_risk_profiles',
            method: 'GET',
            data: {
              filter_by,
              filter_value,
            },
          })
          return data
        } catch (error: any) {
          this.toast.error({
            title: `Не удалось загрузить аналитику рисков`,
            description: error.message,
          })
        }
        return null
      })()
    ) as ActionState<any>

  fetchData = (filter_by: FILTER_BY, filter_value: string | null = null) => {
    this.fetchDataTask = this.createFetchDataTask(filter_by, filter_value)
  }

  clearData = () => {
    this.fetchDataTask = undefined
  }
}

const [AnalyticsProviderInternal, useAnalytics] = createProvider(
  (params: IPageParams) => new AnalyticsManager(params)
)

export const AnalyticsProvider = ({
  pageProps,
  children,
}: {
  pageProps: PageProps
  children: React.ReactNode
}) => {
  const toast = useStateToast()
  const mobxApi = useMobxApi()

  const params = useMemo(
    () => ({
      pageProps,
      toast,
      mobxApi,
    }),
    []
  )

  return (
    <AnalyticsProviderInternal payload={params}>{children}</AnalyticsProviderInternal>
  )
}

export { useAnalytics }

export default useAnalytics
