import React, { useState } from 'react'

import { useTheme, Link } from 'ui'
import Toggle from 'components/ui/animation/Toggle'

import css from './styles.module.css'

const { config } = useTheme()

const faq = [
  {
    Q: 'Как вы работаете с ФНС?',
    A: (
      <>
        Restaff в&nbsp;рамках закона является оператором электронной площадки
        и&nbsp;официальным партнером ФНС. Деятельность платформы была проверена налоговой
        службой и&nbsp;одобрена. Вы&nbsp;можете найти Restaff в&nbsp;официальном реестре
        ФНС на&nbsp;их&nbsp;сайте{' '}
        <Link href="https://npd.nalog.ru/aggregators/" target="_blank">
          https://npd.nalog.ru/aggregators/
        </Link>
      </>
    ),
  },
  {
    Q: 'Останется ли у меня возможность декларировать чеки самостоятельно?',
    A: <>Да, конечно. Вы всегда сможете декларировать доходы самостоятельно</>,
  },
  {
    Q: 'Повредит ли это как-то работе с другими платформами?',
    A: (
      <>
        Нет, вы можете дать право нескольким платформам декларировать доходы от вашего
        имени, и с этим не будет никаких проблем
      </>
    ),
  },
  {
    Q: 'Что будет если вы некорректно задекларируете доход?',
    A: (
      <>
        Вы всегда можете обратиться в нашу службу поддержки и мы аннулируем декларацию.
        Также вы можете сделать это самостоятельно через приложение «Мой Налог»
      </>
    ),
  },
  {
    Q: 'Смогу ли я потом отключить вашу платформу?',
    A: (
      <span
        dangerouslySetInnerHTML={{
          __html: config.text.pages.registration.faq.platform_detachment,
        }}
      />
    ),
  },
]

const Faq = (props: any) => {
  let [isOpen, setIsOpen] = useState(false)
  return (
    <div style={props.style}>
      <div style={{ marginBottom: '12px' }}>
        <Link variant="dashed" onClick={() => setIsOpen((v) => !v)}>
          Ответы на частые вопросы
        </Link>
      </div>
      <Toggle isOpen={isOpen}>
        <ul className={css.faq}>
          {faq.map(({ Q, A }) => (
            <li className={css.faqItem}>
              <div className={css.faqQuestion}>{Q}</div>
              <div className={css.faqAnswer}>{A}</div>
            </li>
          ))}
        </ul>
      </Toggle>
    </div>
  )
}

export default Faq
