import { makeAutoObservable } from 'mobx'

import { Entity, initialActionState } from 'mobx/mobx'
import { CompanyEntity, DocumentEntity } from 'entities'
import { useStateToast } from 'components/ui'
import { SignDialogStore, UserStore } from 'stores/context'
import DocumentStore from 'stores/DocumentStore'

class CompanyStore {
  constructor(
    company: Entity<CompanyEntity>,
    toast: ReturnType<typeof useStateToast>,
    currentUser: UserStore,
    signDialog: SignDialogStore
  ) {
    this.company = company
    this.toast = toast
    this.signDialog = signDialog
    this.currentUser = currentUser
    makeAutoObservable(this)
  }

  company: Entity<CompanyEntity>
  toast: ReturnType<typeof useStateToast>
  currentUser: UserStore
  signDialog: SignDialogStore

  acceptInviteState = initialActionState

  acceptInvite() {
    this.acceptInviteState = this.company.action({ action: 'accept_invite' })
    this.acceptInviteState.then((data) => {
      this.company.setData(data)
      this.toast.success({
        title: 'Приглашение принятно',
        description: 'Теперь подпишите договор с компанией',
      })
      let contract = this.unsignedContracts[0]
      if (contract) this.openContractSignDialog(contract)
    })
    return this.acceptInviteState
  }

  get unsignedContracts() {
    return this.company.data.contracts.items.filter(
      (c) => c.data.status === 'created' || c.data.status === 'signed_by_legal_entity'
    )
  }

  openContractSignDialog(contract: Entity<DocumentEntity>) {
    let contractStore = new DocumentStore({
      document: contract,
      toast: this.toast,
      currentUser: this.currentUser,
      signDialog: this.signDialog
    })
    contractStore.openSignDialog()
  }
}

export default CompanyStore
