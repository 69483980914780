import React from 'react'
import { ITableProps } from '../Table'
import { DateCell } from '../DateCell'

import s from './styles.module.css'
import { Link } from 'ui'

const TableDesktop = ({ cols, rows }: ITableProps) => {
  return (
    <>
      <table className={s.tableDesktop}>
        <thead>
          {cols.map((colData) => {
            let style

            if (colData.width) {
              style = {
                width: colData.width,
              }
            }

            return (
              <th className={s.tableCol} style={style}>
                {colData.text}
              </th>
            )
          })}
        </thead>
        <tbody>
          {rows.map((rowData) => (
            <tr>
              {rowData.map((cellData) => (
                <td className={s.tableCell}>
                  {cellData.date && <DateCell date={cellData.date} />}
                  {cellData.url ? (
                    <Link href={cellData.url}>{cellData.text}</Link>
                  ) : (
                    cellData.text
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </>
  )
}

export default TableDesktop
