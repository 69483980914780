import React from 'react'
import cx from 'clsx'
import { Tooltip } from '@chakra-ui/react'

import { ReactComponent as HelpIcon } from '@material-design-icons/svg/round/help_outline.svg'

import s from './styles.module.css'

const statusColors: { [key: string]: string } = {
  green: 'var(--color-green)',
  grey: 'var(--color-secondary)',
  red: 'var(--color-red)',
}

interface StatusProps {
  color?: string
  hint?: React.ReactNode
  children?: React.ReactNode
  className?: string
  style?: React.CSSProperties
}

const Status = ({ color, hint, children, className, style }: StatusProps) => {
  let elem = (
    <span
      className={cx(s.status, className)}
      // @ts-ignore: allow css variables in style
      style={
        {
          ...style,
          '--status-color':
            color !== undefined && statusColors[color] ? statusColors[color] : '',
        } as any
      }
    >
      {children}
      {hint && (
        <HelpIcon
          style={{
            fill: 'var(--color-icon)',
            cursor: 'pointer',
            width: 16,
            height: 16,
            display: 'inline-block',
            marginLeft: '0.25rem',
          }}
        />
      )}
    </span>
  )
  if (hint) {
    return (
      <Tooltip
        // @ts-ignore
        label={hint}
        placement="top"
        aria-label="button"
      >
        {elem}
      </Tooltip>
    )
  } else {
    return elem
  }
}

export type StatusValue = { color?: string; text: string; hint?: React.ReactNode }

export type StatusMapGeneric<T extends string> = { [key in T]: StatusValue }

export type StatusMap = { [key: string]: StatusValue }

interface StatusBadgeProps {
  status: string
  map: StatusMap
  className?: string
  children?: React.ReactNode
  style?: React.CSSProperties
}

const StatusBadge = ({ status, map, className, children, style }: StatusBadgeProps) => {
  let { color, text, hint } = map[status] || {}
  return (
    <Status color={color} hint={hint} className={className} style={style}>
      {children || text || status}
    </Status>
  )
}

export default StatusBadge
export { Status }
