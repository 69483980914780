import { rest } from 'msw'
import { json } from './helpers'

import { ScenarioProjectDocument, ScenarioProjectEntityRead } from 'entities'

interface IProjectDocuments {
  items: ScenarioProjectDocument[]
  page: number
  per_page: number
  total_count: number
  total_pages: number
}

const projectDocuments: IProjectDocuments = {
  items: [
    {
      id: 1,
      title: 'Пати на хате',
      tasks_count: 5,
      location: { address: 'г Москва, Малый Песчаный пер', lat: 12.123, lng: 12.123 },
      since_date: null,
      upto_date: null,
      since_time: null,
      upto_time: null,
      total_amount: 100500,
    },
    {
      id: 2,
      title: 'Макдональдс',
      tasks_count: 3,
      location: { address: 'г Москва, Малый Песчаный пер', lat: 12.123, lng: 12.123 },
      since_date: null,
      upto_date: null,
      since_time: null,
      upto_time: null,
      total_amount: 100500,
    },
  ],
  page: 1,
  per_page: 10,
  total_count: 0,
  total_pages: 0,
}

export const projectEntity: ScenarioProjectEntityRead = {
  id: 1,
  title: 'Проект',
  location: { address: 'г Москва, Малый Песчаный пер', lat: 12.123, lng: 12.123 },
  since_date: '2022-02-01',
  upto_date: '2022-03-05',
  since_time: null,
  upto_time: null,
  total_amount: 100500,
  tasks_count: 1,
  comment: '123',
  tasks: [
    {
      id: 1,
      project: { title: 'Поект 1' },
      units: [{ title: 'РАБота' }],
      since_date: '2022-01-02',
      upto_date: '2022-05-02',
      status: 'completed',
      act: {
        id: 42,
      },
      amount: 5000,
      contractor: {
        name: 'Фамилия Имя Отчество',
        mobile_phone: '88005553535',
      },
      allowed_company_actions: ['complete', 'not_complete'],
    },
  ],
}

const projectCreateEmpty = {
  comment: null,
  id: 1,
  location: null,
  since_date: null,
  since_time: null,
  tasks: [],
  title: 'Новый проект',
  total_amount: '0.0',
  upto_date: null,
  upto_time: null,
}

export default [
  rest.get('/api/company/scenario_projects', json(projectDocuments)),
  rest.get('/api/company/scenario_projects/:id', json(projectEntity)),
  rest.post('/api/company/scenario_projects', json(projectCreateEmpty)),
  rest.put('/api/company/scenario_projects', json({})),
]
