import { useToast } from '@chakra-ui/react'

interface ToastProps {
  title?: string
  description?: React.ReactNode
}

const errorToast = (
  toast: ReturnType<typeof useToast>,
  { title, description }: ToastProps
) =>
  toast({
    title,
    // @ts-ignore
    description,
    status: 'error',
    duration: 3000,
    position: 'top',
    isClosable: true,
  })

const successToast = (
  toast: ReturnType<typeof useToast>,
  { title, description }: ToastProps
) =>
  toast({
    title,
    // @ts-ignore
    description,
    status: 'success',
    duration: 3000,
    position: 'top',
    isClosable: true,
  })

const warningToast = (
  toast: ReturnType<typeof useToast>,
  { title, description }: ToastProps
) =>
  toast({
    title,
    // @ts-ignore
    description,
    status: 'warning',
    duration: 3000,
    position: 'top',
    isClosable: true,
  })

const useStateToast = () => {
  let toast = useToast()
  return {
    error: (props: ToastProps) => errorToast(toast, props),
    success: (props: ToastProps) => successToast(toast, props),
    warning: (props: ToastProps) => warningToast(toast, props),
  }
}

export default useStateToast
