import React from 'react'
import cl from 'clsx'
import css from './styles.module.css'

export type PageBoxProps = {
  children: React.ReactNode
  className?: string
}

const PageBox: React.FunctionComponent<PageBoxProps> = ({ children, className }) => (
  <div className={cl(css.pageBox, className && className)}>{children}</div>
)

export default PageBox
