import React, { forwardRef } from 'react'
import cx from 'clsx'

import { useStyles, StyleProps } from 'ui/styled'

import css from './styles.module.css'

type TextSize = 's' | 'm' | 'l'

const colorMap = {
  primary: 'var(--konsol-color-text)',
  secondary: 'var(--konsol-color-text-secondary)',
  accent: 'var(--konsol-color-text-accent)',
  error: 'var(--konsol-color-red)',
  success: 'var(--konsol-color-green)',
}

type TextColor = 'primary' | 'secondary' | 'accent' | 'error' | 'success'

interface TextProps extends StyleProps<[TextProps]> {
  children: React.ReactNode
  size?: TextSize
  color?: TextColor
  className?: string
  as?: string
}

const defaultProps = {
  color: 'primary' as TextColor,
  size: 'm' as TextSize,
  as: 'div',
}

const textStyles = (props: TextProps & typeof defaultProps) => ({
  root: { color: colorMap[props.color] },
})

const Text = forwardRef<HTMLElement, TextProps>((_props: TextProps, ref) => {
  const props = _props as TextProps & typeof defaultProps
  const { children, size, as: elem } = props
  const styles = useStyles(textStyles, [props])
  return React.createElement(
    elem,
    {
      className: cx(css.root, css[size], props.className),
      style: styles.root,
      ref,
    },
    children
  )
})

Text.defaultProps = defaultProps

export { Text }
