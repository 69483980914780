import React, { useRef, useState, useEffect, forwardRef } from 'react'

import { ReactComponent as IosSpinnerIcon } from 'assets/ios-spinner.min.svg'

interface LoadingIndicatorProps {
  delay?: number
  [key: string]: any
}

const LoadingIndicator = forwardRef(
  ({ delay = 0, ...restProps }: LoadingIndicatorProps, ref: any) => {
    let [show, setShow] = useState(delay === 0 ? true : false)
    let timeoutRef = useRef<number>(null)
    useEffect(() => {
      if (delay) setTimeout(() => setShow(true), delay)
      return () => {
        timeoutRef.current && clearTimeout(timeoutRef.current)
      }
    }, [])
    return show ? <IosSpinnerIcon ref={ref} {...restProps} /> : null
  }
)

export default LoadingIndicator
