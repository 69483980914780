import React, { useState } from 'react'

import { useMediaContext } from 'App/MediaContext'

import Modal from './Modal'
import { Col, Row } from './Flex'
import { H2 } from './Heading'

interface ConfirmDialogProps {
  header?: React.ReactNode
  children?: React.ReactNode
  buttons?: React.ReactNode
  onCancel: () => void
}

const ConfirmDialog = (props: ConfirmDialogProps) => {
  let { isMobile } = useMediaContext()
  let { header, children, buttons, onCancel } = props
  return (
    <Modal
      isOpen={true}
      onClose={onCancel}
      size={isMobile ? '92%' : 500}
      styles={{
        content: { padding: '1.5rem' },
      }}
      isCentered={true}
    >
      <Col gap="var(--gap-m)">
        {header && <H2>{header}</H2>}
        <div>{children}</div>
        {buttons && (
          <Row gap="1rem" justify="end" align="center">
            {buttons}
          </Row>
        )}
      </Col>
    </Modal>
  )
}

export interface ConfirmDialogRenderProps<T> {
  data: T
  confirm: () => void
  cancel: () => void
}

export interface UseConfirmDialogProps<T> {
  render: (props: ConfirmDialogRenderProps<T>) => React.ReactNode
  onConfirm: (data: T) => void
}

export interface ConfirmDialogHandle<T = undefined> {
  open: (data?: T) => void
  render: () => React.ReactNode
}

const useConfirmDialog = <T,>({
  render,
  onConfirm,
}: UseConfirmDialogProps<T>): ConfirmDialogHandle<T> => {
  let [data, setData] = useState<T>()
  let [isOpen, setIsOpen] = useState(false)
  let open = (data?: T) => {
    setData(data)
    setIsOpen(true)
  }
  let cancel = () => setIsOpen(false)
  let confirm = () => {
    setIsOpen(false)
    onConfirm(data!)
  }
  return {
    render: () => (isOpen ? render({ data: data!, cancel, confirm }) : null),
    open,
  }
}

export default ConfirmDialog
export { useConfirmDialog }
