import React from 'react'

import cx from 'clsx'

import c from './styles.module.css'

export type BadgeColor = 'red' | 'green' | 'purple' | 'blue' | 'orange'

interface BadgeProps {
  children?: React.ReactNode
  color: BadgeColor
}

export const Badge = ({ color, children }: BadgeProps) => (
  <span
    className={cx(
      c.badge,
      c[`badge${color.charAt(0).toUpperCase() + color.slice(1).toLowerCase()}`]
    )}
  >
    {children}
  </span>
)
