import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { Checkbox, Flex } from '..'

const gap = 'var(--gap-s)'

export const ListItemSelectionCol = observer(
  ({ onClick, value }: { onClick: (e: React.MouseEvent) => void; value: boolean }) => {
    return (
      <Flex
        onClick={(e) => {
          e.preventDefault()
          onClick(e)
        }}
        style={{
          alignSelf: 'stretch',
          paddingLeft: gap,
          paddingRight: gap,
          marginLeft: `calc(-1 * ${gap})`,
        }}
        align="center"
      >
        <Checkbox value={value} />
      </Flex>
    )
  }
)
