import React, { useState, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { Link } from 'react-router-dom'
import { Tooltip } from '@chakra-ui/react'

import { useUserStore } from 'stores/context'
import { useMobxApi, initialActionState, Collection, Entity } from 'mobx/mobx'
import { ActBundleEntity, ProjectEntity } from 'entities'
import { useMediaContext } from 'App/MediaContext'
import { usePusherSubscription } from 'App/pusher'

import type { PageProps } from 'app.types'
import { PaginationStore, PaginationView } from 'components/PaginationView'
import { Amount } from 'components'
import {
  Placeholder,
  Layout,
  LayoutHeading,
  Flex,
  List,
  ListHeader,
  ListItem,
  ListCell,
  LoadingIndicator,
  useStateToast,
} from 'components/ui'

import { AbilityButton } from 'components/abilityButtons'
import { AnimatedList, CollapseAnimation } from 'components/ui/animation'
import ProgressBar from 'components/ui/ProgressBar'
import ErrorDialog from 'components/ErrorDialog'
import DialogStore from 'stores/DialogStore'

import useFileDialog from 'utils/useFileDialog'
import { thinsp } from 'utils/typo'
import { formatAmount } from 'utils/amount'

interface BundleListItemProps {
  bundle: Entity<ActBundleEntity> | Entity<ProjectEntity>
  onDelete: () => void
}

const styles: { [key: string]: React.CSSProperties } = {
  pseudolink: {
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    borderBottom: '1px dashed rgba(0,0,0,.2)',
  },
}

const BundleListItem = observer(({ bundle }: BundleListItemProps) => {
  if (bundle.data.is_loading) {
    return (
      <ListItem style={{ alignItems: 'start', cursor: 'progress' }} hover={false}>
        <ListCell col="number">{bundle.data.id}</ListCell>
        <ListCell col="title" style={{ color: 'var(--color-secondary)' }}>
          {bundle.data.title}
        </ListCell>
        <ListCell col="loading" style={{ height: 37 }}>
          <Flex gap=".5rem" align="center">
            <LoadingIndicator height={21} width={21} />
            <div style={{ color: 'var(--color-secondary)' }}>Акты формируются</div>
          </Flex>
        </ListCell>
      </ListItem>
    )
  }

  let amount =
    bundle.type === 'bundles' && bundle.data.amount_with_commission ? (
      <Tooltip
        label={`Сумма с учётом комиссии: ${formatAmount(
          bundle.data.amount_with_commission
        )}`}
      >
        <span style={styles.pseudolink}>
          <Amount value={bundle.data.amount} />
          {thinsp}
        </span>
      </Tooltip>
    ) : (
      <Amount value={bundle.data.amount} />
    )

  return (
    <ListItem
      style={{ alignItems: 'start' }}
      as={Link}
      to={`/${bundle.type}/${bundle.id}`}
    >
      <ListCell col="number">{bundle.data.id}</ListCell>
      <ListCell col="title">{bundle.data.title}</ListCell>
      <ListCell col="count">{bundle.data.count}</ListCell>
      <ListCell col="amount">{amount}</ListCell>
      {bundle.type === 'projects' && (
        <ListCell col="done">
          <ProgressBar
            total={bundle.data.count}
            success={(bundle as Entity<ProjectEntity>).data.progress.done}
          />
        </ListCell>
      )}
      <ListCell col="accepted">
        <ProgressBar total={bundle.data.count} success={bundle.data.progress.accepted} />
      </ListCell>
      <ListCell col="payment">
        <ProgressBar
          total={bundle.data.count}
          success={bundle.data.progress.paid}
          errors={bundle.data.progress.errors}
        />
      </ListCell>
    </ListItem>
  )
})

interface BundleListProps {
  bundles: Collection<ActBundleEntity> | Collection<ProjectEntity>
}

const BundleList = observer(({ bundles }: BundleListProps) => {
  let toast = useStateToast()
  usePusherSubscription('update-bundle', (event: any) => {
    let bundle = bundles.map[event.id]
    if (bundle) {
      if (bundle.data.is_loading && !event.is_loading) {
        toast.success({ title: `Акты в пачке №${bundle.data.id} сформированы` })
      }
      bundle.setData(event)
    }
  })
  let cols: { [key: string]: any } = {
    number: { width: 40, style: { color: 'var(--color-secondary)' } },
    title: { style: { overflowWrap: 'anywhere', minWidth: 100 } },
    count: { width: 65 },
    amount: { width: 120, style: { textAlign: 'right', paddingRight: '.5rem' } },
    done: { width: 140 },
    accepted: { width: 140 },
    payment: { width: 140 },
    actions: { width: 40 },
    loading: { width: `calc(${40 + 120 + 140 + 140}px + 2.5rem)` },
  }
  return (
    <List cols={cols}>
      <ListHeader>
        <ListCell col="number">№</ListCell>
        <ListCell col="title">Название</ListCell>
        <ListCell col="count">
          {bundles.type === 'bundles' ? 'Актов' : 'Заданий'}
        </ListCell>
        <ListCell col="amount">Сумма</ListCell>
        {bundles.type === 'projects' && <ListCell col="done">Выполнены</ListCell>}
        <ListCell col="accepted">Подписаны</ListCell>
        <ListCell col="payment">Оплачены</ListCell>
      </ListHeader>
      <AnimatedList
        items={bundles.items as any}
        getId={(item) => item.id}
        renderItem={(item: any, props: any) => {
          return (
            <CollapseAnimation openValue={props.open} key={item.id}>
              <BundleListItem
                bundle={item}
                onDelete={() => bundles.delete({ id: item.id, optimistic: true })}
              />
            </CollapseAnimation>
          )
        }}
      />
    </List>
  )
})

interface BundleListPageProps extends PageProps {
  type: 'bundles' | 'projects'
}

const BundleListPage = observer(({ history, match, type }: BundleListPageProps) => {
  let page = Number(match.params.page) || 1
  let { isMobile } = useMediaContext()
  let toast = useStateToast()
  let currentUser = useUserStore()
  let api = useMobxApi()

  let paginationStore = useMemo(
    () =>
      new PaginationStore<ActBundleEntity>(
        ({ page, abortSignal }) =>
          api.fetch({
            type: `${type}/page`,
            url: `${type}/page/${page}`,
            options: { signal: abortSignal },
          }),
        page
      ),
    []
  )
  useMemo(() => paginationStore.setPage(page), [page])
  let fetchedValue = paginationStore.fetchedValue

  let errorDialog = useMemo(() => new DialogStore({ component: ErrorDialog }), [])
  let [createState, setCreateState] = useState(initialActionState)
  let createFromCsv = (file: File) => {
    if (!file.name.match(/\.(csv|xls|xlsx)/)) {
      return alert('Поддерживаем реестры в форматах: .csv, .xls, .xslx')
    }
    let formData = new FormData()
    formData.append('file', file)
    let state = paginationStore.fetchedValue!.data.items.create({
      payload: formData,
      mode: 'prepend',
      url: `${type}/create_from_file`,
    })
    setCreateState(state)
    state.then(
      () => {
        if (page !== 1) history.push(`/${type}/page/1`)
      },
      (error: any) => {
        let title = 'Ошибка загрузки пачки актов'
        if (error.data?.code === 'failed_validation') {
          errorDialog.open({
            title,
            message: error.message,
            errors: error.data.errors,
          })
        } else {
          toast.error({ title, description: error.message })
        }
      }
    )
  }
  let createProject = () => {
    let state = paginationStore.fetchedValue!.data.items.create({ mode: 'prepend' })
    setCreateState(state)
    state.then(
      () => {
        if (page !== 1) history.push(`/${type}/page/1`)
      },
      (error: any) =>
        toast.error({ title: 'Не удалось создать проект', description: error.message })
    )
  }
  let fileDialog = useFileDialog(createFromCsv, { accept: '.csv,.xls,.xlsx' })

  return (
    <Layout>
      <Flex
        justify="space-between"
        align="center"
        gap="1em"
        style={{ marginBottom: '4rem' }}
      >
        <LayoutHeading count={fetchedValue && fetchedValue.data.total_count}>
          {type === 'bundles' ? 'Пачки актов' : 'Проекты'}
        </LayoutHeading>
        {type === 'bundles' && (
          <AbilityButton
            ability={currentUser.hasAbility('bundles.create')}
            onTap={fileDialog.open}
            isDisabled={!paginationStore.fetchedValue}
            isLoading={createState.state === 'pending'}
            size={isMobile ? 's' : 'm'}
          >
            {isMobile ? 'Загрузить' : 'Загрузить из файла'}
          </AbilityButton>
        )}
        {type === 'projects' && (
          <AbilityButton
            ability={currentUser.hasAbility('projects.create')}
            onTap={createProject}
            isDisabled={!paginationStore.fetchedValue}
            isLoading={createState.state === 'pending'}
            size={isMobile ? 's' : 'm'}
          >
            Создать
          </AbilityButton>
        )}
        {fileDialog.render()}
        {errorDialog.render()}
      </Flex>
      <PaginationView
        store={paginationStore}
        render={(bundles, page) =>
          bundles.isEmpty ? (
            <Placeholder>
              {type === 'bundles' ? 'Загрузите пачки актов' : 'Создайте проект'}
            </Placeholder>
          ) : (
            <BundleList bundles={bundles} key={page} />
          )
        }
        onChangePage={(page) => history.push(`/${type}/page/${page}`)}
      />
    </Layout>
  )
})

const ActBundleListPage = observer((props: PageProps) => (
  <BundleListPage {...props} type="bundles" />
))

const ProjectListPage = observer((props: PageProps) => (
  <BundleListPage {...props} type="projects" />
))

export { ActBundleListPage, ProjectListPage }
