import React, { useState, useEffect } from 'react'
import { makeAutoObservable, reaction } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useMeasure } from 'react-use'
// @ts-ignore
import { useStyles } from 'floral'
import { ReactComponent as ExpandIcon } from '@material-design-icons/svg/round/expand_more.svg'

import { Entity, makeInitialActionState, initialActionState } from 'mobx/mobx'
import { TaskEntity, TaskAction, TaskPayAction } from 'entities'
import UserStore from 'stores/UserStore'
import SignDialogStore, { OpenSignDialogProps } from 'stores/SignDialogStore'
import DocumentStore from 'stores/DocumentStore'
import TasksMultiActionsStore from 'components/tasks/TasksMultiActionsStore'
import { useMediaContext } from 'App/MediaContext'
import {
  PaymentDetailsView,
  PaymentDetailsEditView,
} from 'pages/contractors/ContractorPaymentDetails'

import { normalizeAmount } from 'utils/amount'
import SignInput from 'components/SignDialog/SignInput'
import { Amount, FileThumb } from 'components'
import {
  Flex,
  BaseButton,
  Modal,
  ModalInsert,
  ActionStateView,
  useStateToast,
  Checkbox,
  H1,
  LoaderMark,
} from 'components/ui'
import { Toggle } from 'components/ui/animation'

import { getTaskProblems, getTaskActionState } from './taskProblems'
import { H3, Button as UiButton } from 'ui'
import { P1, Checkbox as CheckboxUiKit, Divider } from 'ui-kit'
import css from './styles.module.css'

enum TaxSavingItemKind {
  Task = 'TASK',
  Debt = 'DEBT',
  Penalty = 'PENALTY',
  Receipt = 'RECEIPT',
}

export interface TaxSavingsItem {
  kind: TaxSavingItemKind
  title: string
  amount: string
  description: string
}

export interface TaxBoxWithholdings {
  tax_savings?: {
    amount: string
    items: TaxSavingsItem[]
  }
  transfer_commission_amount?: string // Комиссия за перевод на карту
  payment_amount_after_withholdings: string // Сумма выплаты после удержаний
}

export interface TaxAccountWithholdings {
  default: {
    tax_savings_amount?: string
    transfer_commission?: string
    payment_amount_after_witholdings: string
  }
  extra_tax_savings: {
    title: string
    description: string
    selected: boolean
    extra_savings_amount: string
    tax_savings_amount: string
    transfer_commission: string
    payment_amount_after_witholdings: string
  }
}

export interface TaskReportData {
  comment: string
  subtasks: Array<{ id: number; quantity: number }>
  attachment_ids: Array<number>
}

const errorMessages: { [key: string]: string } = {
  respond: 'Не получилось откликнуться на задание',
  assign_contractor: 'Не получилось выбрать исполнителя',
  sign_terms: 'Не получилось подписать',
  cancel: 'Не получилось отменить',
  start: 'Не получилось принять',
  decline: 'Не получилось отказаться',
  done: 'Не получилось отправить отчёт',
  make_work_acceptance: 'Не получилось подписать акт',
  reject: 'Не получилось отклонить',
  accept: 'Не получилось подписать акт',
  complete: 'Не получилось подтвердить оплату',
  complete_with_receipt: 'Не получилось отправить чек',
  complete_with_auto_receipt: 'Не получилось сформировать чек',
  annul_fns_receipt: 'Не получилось аннулировать чек',
  make_fns_receipt: 'Не получилось сформировать чек',
  annul: 'Не удалось подписать соглашение об аннулировании',
  accept_annul: 'Не удалось подписать соглашение об аннулировании',
}

const successMessages: { [key: string]: string } = {
  respond: 'Вы откликнулись на задание',
  assign_contractor: 'Исполнитель выбран',
  sign_terms: 'Задание предложено',
  cancel: 'Задание отменено',
  start: 'Задание принято',
  decline: 'Вы отказались от задания',
  accept: 'Инвойс подписан',
  reject: 'Отчет отклонён',
  complete: 'Вы подтвердили оплату',
  complete_with_receipt: 'Чек отправлен',
  complete_with_auto_receipt: 'Чек сформирован',
  annul_fns_receipt: 'Чек аннулирован',
  make_fns_receipt: 'Чек сформирован',
  annul: 'Соглашение об аннулировании подписано',
  accept_annul: 'Акт аннулирован',
}

export const taskEvents: { [key in TaskAction]?: string } = {
  create: 'Предложено исполнителю',
  sign_terms: 'Подписано',
  cancel: 'Отменено',
  start: 'Принято исполнителем',
  decline: 'Исполнитель отказался от задания',
  done: 'Отчет подготовлен',
  make_work_acceptance: 'Инвойс подписан исполнителем',
  accept: 'Инвойс подписан заказчиком',
  reject: 'Акт отклонен',
  pay: 'Оплачено',
  autopay: 'Оплата одобрена',
  cancel_autopay: 'Отмена одобрения оплаты',
  complete: 'Оплата получена',
  complete_with_receipt: 'Чек приложен',
  complete_with_auto_receipt: 'Чек сформирован',
  annul_fns_receipt: 'Чек аннулирован',
  make_fns_receipt: 'Чек сформирован',
  annul: 'Соглашение об аннулировании подписано заказчиком',
  accept_annul: 'Соглашение об аннулировании подписано исполнителем',
  annul_by_one_side: 'Акт аннулирован заказчиком',
}

const acceptStyles = (
  props: any,
  isMobile: boolean
): { [key: string]: React.CSSProperties } => ({
  row: {
    borderBottom: '1px solid var(--color-row-bottom)',
    padding: '1rem 0',
  },
  footer: isMobile
    ? {
        position: isMobile ? 'fixed' : 'static',
        background: 'white',
        bottom: 0,
        left: 0,
        width: '100%',
        padding: '1.5rem 20px',
        borderTop: '1px solid rgba(0,0,0,.1)',
      }
    : {
        paddingTop: '1.5rem',
        paddingBottom: '1.5rem',
        borderTop: '1px solid rgba(0,0,0,.1)',
      },
})

const ContractorTaxAccountAccept = observer(({ store }: { store: TaskActionsStore }) => {
  let { isMobile } = useMediaContext()
  let options: any = store.fetchTaxAccountWithholdingsState.value
  let [extraSavingsIsChecked, setExtraSavingsIsChecked] = useState(
    options.extra_tax_savings?.selected
  )
  let {
    tax_savings_amount,
    transfer_commission_amount,
    payment_amount_after_witholdings,
  } = extraSavingsIsChecked ? options.extra_tax_savings : options.default
  let paymentAmount = store.task.data.act.payment_amount

  let rowStyle = { fontSize: isMobile ? '1rem' : 16, fontWeight: 500 }

  let amounts = (
    <>
      {paymentAmount !== payment_amount_after_witholdings && (
        <Flex gap="2rem" justify="space-between" style={rowStyle}>
          <div>Сумма по инвойсу</div>
          <Amount value={paymentAmount} />
        </Flex>
      )}
      {options.default.tax_savings_amount && (
        <Flex gap=".5rem" direction="column">
          <Flex gap="2rem" justify="space-between" style={rowStyle}>
            <div>Отложим в копилку</div>
            <Amount value={options.default.tax_savings_amount} />
          </Flex>
          <div style={{ color: 'var(--color-secondary)' }}>
            С каждого платежа часть суммы откладывается в копилку, чтобы вы смогли
            оплатить налог
          </div>
        </Flex>
      )}
      {options.extra_tax_savings && (
        <ModalInsert style={{ background: '#ffccd8' }}>
          <Flex gap=".5rem" direction="column">
            <div style={{ fontWeight: 500 }}>{options.extra_tax_savings.title}</div>
            <div>{options.extra_tax_savings.description}</div>
            <Checkbox
              styles={{ root: { fontSize: '1rem' }, label: { flexGrow: 1 } }}
              value={extraSavingsIsChecked}
              onChange={setExtraSavingsIsChecked}
              label={
                <Flex gap="2rem" justify="space-between" style={rowStyle}>
                  <div>Отложить дополнительно</div>
                  <Amount value={options.extra_tax_savings.extra_savings_amount} />
                </Flex>
              }
            />
          </Flex>
        </ModalInsert>
      )}
      {transfer_commission_amount && (
        <Flex gap="2rem" justify="space-between" style={{ ...rowStyle }}>
          <div>Комиссия за перевод на карту</div>
          <Amount value={transfer_commission_amount} />
        </Flex>
      )}
      <Flex gap="2rem" justify="space-between" style={{ ...rowStyle, fontSize: 18 }}>
        <div>Сумма выплаты</div>
        <Amount value={payment_amount_after_witholdings} />
      </Flex>
    </>
  )

  let footerStyle = {
    borderTop: '1px solid var(--color-grey-1)',
    paddingTop: '2rem',
    paddingBottom: '2rem',
  }
  let footer = (
    <ModalInsert style={footerStyle}>
      <SignInput
        text="принять задание"
        onSign={(payload) => store.contractorAccept(payload, tax_savings_amount)}
      />
    </ModalInsert>
  )

  return (
    <Flex direction="column" gap="1.5rem">
      <FileThumb
        data={{
          title: 'Инвойс на оплату',
          url: store.actDocument!.url,
        }}
        status={store.actDocument!.status}
      />
      {amounts}
      {footer}
    </Flex>
  )
})

const ContractorTaxBoxAccept = observer((props: { store: TaskActionsStore }) => {
  let store = props.store
  let paymentAmount = store.task.data.act.payment_amount
  let [expandSavings, setExpandSavings] = useState(false)
  let { isMobile } = useMediaContext()
  let s = useStyles(acceptStyles, [props, isMobile])

  let fileThumb = (
    <FileThumb
      data={{ title: 'Инвойс на оплату', url: store.actDocument!.url }}
      status={store.actDocument!.status}
    />
  )

  let amounts
  let actAmount = normalizeAmount(store.task.data.act.amount)
  if (actAmount.currency !== 'RUB') {
    amounts = (
      <div style={{ flexGrow: 1 }}>
        <Flex
          gap="1rem"
          justify="space-between"
          style={{
            fontWeight: 500,
            fontSize: 18,
            ...s.row,
          }}
        >
          <div>Сумма по инвойсу</div>
          <Amount value={actAmount} />
        </Flex>
        <div>
          Оплата инвойса будет произведена в рублях по курсу Московской биржи на
          момент оплаты
        </div>
      </div>
    )
  } else {
    let { tax_savings, transfer_commission_amount, payment_amount_after_withholdings } =
      store.fetchTaxBoxWithholdingsState.value as TaxBoxWithholdings

    const renderSavingsItems = tax_savings && (
      <div>
        <Flex gap="1rem" direction="column" style={{ paddingTop: '1rem' }}>
          {tax_savings!.items.map((item) => (
            <Flex direction="column" gap=".25rem">
              <Flex gap="1rem" justify="space-between" style={{ fontWeight: 500 }}>
                <div>{item.title}</div>
                <div>
                  -<Amount value={item.amount} />
                </div>
              </Flex>
              <div style={{ color: 'var(--color-secondary)' }}>{item.description}</div>
            </Flex>
          ))}
        </Flex>
      </div>
    )

    const createCheckboxChangeHandler =
      (store: TaskActionsStore, kind: TaxSavingItemKind) => () => {
        store.setTaxSavingFields(kind, !store.taxSavingFields[kind])
      }

    const taxSavingTask = tax_savings?.items?.find((item) => {
      return item.kind === TaxSavingItemKind.Task
    })

    const otherTaxSavings = tax_savings?.items?.filter((item) => {
      return item.kind !== TaxSavingItemKind.Task
    })

    let taxSavings = tax_savings && (
      <div style={s.row}>
        <BaseButton
          onTap={() => setExpandSavings((v) => !v)}
          style={{ width: '100%', borderRadius: '.5rem' }}
        >
          {taxSavingTask && store.activatedFlexibleTaxBox && (
            <Flex direction="column" gap=".25rem">
              <Flex gap="1rem" justify="space-between" style={{ fontWeight: 500 }}>
                <P1 className={css.taxTitle}>{taxSavingTask.title}</P1>
                <div>
                  -
                  <Amount
                    value={taxSavingTask.amount}
                    style={{ fontWeight: 500, fontSize: 18 }}
                  />
                </div>
              </Flex>
              <Divider />
            </Flex>
          )}

          {(otherTaxSavings!.length > 0 || !store.activatedFlexibleTaxBox) && (
            <Flex
              gap="1rem"
              justify="space-between"
              style={{ fontWeight: 500, fontSize: 18, marginTop: 32 }}
            >
              <Flex gap=".5rem" align="center">
                {!store.activatedFlexibleTaxBox ? <ExpandIcon /> : null}
                {store.activatedFlexibleTaxBox
                  ? 'Другие налоговые отчисления'
                  : 'Удержания в копилку'}
              </Flex>
              <div>
                {store.activatedFlexibleTaxBox ? (
                  <>
                    {store.taxSavingAmount - Number(taxSavingTask?.amount) > 0 ? '-' : ''}
                    <Amount
                      value={store.taxSavingAmount - Number(taxSavingTask?.amount)}
                    />
                  </>
                ) : (
                  <>
                    -<Amount value={tax_savings.amount} />
                  </>
                )}
              </div>
            </Flex>
          )}
        </BaseButton>
        {store.activatedFlexibleTaxBox && otherTaxSavings!.length > 0 ? (
          <>
            <Flex gap="1rem" direction="column" style={{ paddingTop: '1rem' }}>
              <P1>
                У вас есть другие активные налоговые начисления, отметьте их чтобы
                отложить в копилку деньги для их погашения
              </P1>

              {otherTaxSavings?.map((item, i) => (
                <Flex direction="column" gap=".25rem" key={i}>
                  <Flex gap="1rem" justify="space-between" style={{ fontWeight: 500 }}>
                    <CheckboxUiKit
                      label={item.title}
                      checked={store.taxSavingFields[item.kind]}
                      name={item.kind}
                      onChange={createCheckboxChangeHandler(store, item.kind)}
                    />
                    <div>
                      -<Amount value={item.amount} />
                    </div>
                  </Flex>
                  <Divider />
                </Flex>
              ))}
            </Flex>
          </>
        ) : (
          <Toggle isOpen={expandSavings}>{renderSavingsItems}</Toggle>
        )}
      </div>
    )

    amounts = (
      <div style={{ flexGrow: 1 }}>
        {paymentAmount !== payment_amount_after_withholdings && (
          <Flex
            gap="1rem"
            justify="space-between"
            style={{
              fontWeight: 500,
              fontSize: 18,
              ...s.row,
            }}
          >
            <div>Сумма по инвойсу</div>
            <Amount value={paymentAmount} />
          </Flex>
        )}
        {taxSavings}
        <Flex direction="column" gap="1rem" style={{ padding: '1rem 0' }}>
          {transfer_commission_amount && (
            <Flex gap="1rem" justify="space-between" style={{ fontWeight: 500 }}>
              <div>Комиссия за перевод на карту</div>
              <div>
                <Amount value={transfer_commission_amount} />
              </div>
            </Flex>
          )}
          <Flex
            gap="1rem"
            justify="space-between"
            style={{ fontWeight: 500, fontSize: 21 }}
          >
            <div>Сумма выплаты</div>
            {store.activatedFlexibleTaxBox ? (
              <Amount value={Number(paymentAmount) - store.taxSavingAmount} />
            ) : (
              <Amount value={payment_amount_after_withholdings} />
            )}
          </Flex>
        </Flex>
      </div>
    )
  }

  let [footerRef, { height }] = useMeasure()
  let signInput = (
    <SignInput
      text="принять задание"
      onSign={(payload) => {
        if (store.activatedFlexibleTaxBox) {
          if (payload) {
            // @ts-ignore
            payload.payable_tax_kinds = Object.keys(store.taxSavingFields).reduce(
              (acc: any, key: any) => {
                // @ts-ignore
                if (store.taxSavingFields[key]) {
                  acc.push(key)
                }

                return acc
              },
              []
            )
          }

          return store.contractorAccept(payload)
        } else {
          return store.contractorAccept(payload)
        }
      }}
    />
  )
  let footer = isMobile ? (
    <>
      <div style={{ height }} />
      <div style={s.footer} ref={footerRef as any}>
        {signInput}
      </div>
    </>
  ) : (
    <ModalInsert style={s.footer}>{signInput}</ModalInsert>
  )

  return (
    <Flex direction="column" gap="1rem">
      {fileThumb}
      {amounts}
      {footer}
    </Flex>
  )
})

interface ContractorAcceptDialogProps {
  store: TaskActionsStore
  checkPaymentDetails?: boolean
}

const ContractorAcceptLoader = observer(({ store }: { store: TaskActionsStore }) => {
  const [state, setState] = useState<'loader' | 'mark'>('loader')
  const [title, setTitle] = useState<any>('')
  const [message, setMessage] = useState<any | any[]>('')
  const [collapse, setCollapse] = useState(true)

  useEffect(() => {
    store.contractorAcceptLoaderAction.then(
      () => {
        setState('mark')
        if (typeof store.task.data.message === 'string') {
          setMessage(store.task.data.message)
        } else if (store.task.data.message) {
          const { title, description } = store.task.data.message
          title && setTitle(title)
          description && setMessage(description)
        }
      },
      (error: any) => {
        store.toast.error({
          title: error.data.message || 'Произошла ошибка',
        })
        store.contractorAcceptDialogIsOpen = false
        store.contractorAcceptLoaderIsOpen = false
      }
    )
  }, [store.contractorAcceptLoaderAction])

  const handleAnimationEnd = () => {
    if (state === 'mark') {
      setCollapse(false)
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
      onClick={(e: any) => {
        e.preventDefault()
        e.stopPropagation()
      }}
    >
      <div
        style={{
          flex: 1,
          minHeight: 100,
          position: 'relative',
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <LoaderMark appear state={state} onAnimationEnd={handleAnimationEnd} />
        </div>
      </div>
      <div
        style={{
          flex: 1,
          paddingBottom: 20,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-between',
          minHeight: 175,
          visibility: collapse ? 'hidden' : 'visible',
        }}
      >
        <H3
          style={{
            marginTop: 20,
          }}
        >
          {title || 'Акт успешно подписан'}
        </H3>
        <div
          style={{
            fontWeight: 400,
            color: '#7C8292',
            marginBottom: 15,
            marginTop: 15,
            display: 'flex',
          }}
        >
          {Array.isArray(message) ? (
            <ul style={{ paddingLeft: '1em' }}>
              {message.map((msg, idx) => (
                <li key={idx}>{msg}</li>
              ))}
            </ul>
          ) : (
            <span style={{ textAlign: 'center' }}>
              {message || 'Компания переведет оплату после получения акта'}
            </span>
          )}
        </div>
        <UiButton
          style={{ width: '100%' }}
          onClick={() => {
            store.contractorAcceptLoaderIsOpen = false
            store.contractorAcceptDialogIsOpen = false
          }}
        >
          Понятно
        </UiButton>
      </div>
    </div>
  )
})

const ContractorAcceptDialog = observer(
  ({ store, checkPaymentDetails }: ContractorAcceptDialogProps) => {
    let { isMobile } = useMediaContext()

    let contractor = store.task.data.contractor!
    let [step, setStep] = useState(
      checkPaymentDetails ? 'check_payment_details' : 'accept'
    )
    let confirm = () => {
      store.confirmContractorAccept()
      setStep('accept')
    }
    let close = () => {
      store.contractorAcceptDialogIsOpen = false
    }
    let content
    if (step === 'check_payment_details') {
      content = (
        <Flex direction="column" gap="2rem">
          <H1>Подписание инвойса</H1>
          <div>
            Проверьте реквизиты. Если в реквизитах ошибка, то деньги не придут на карту.
          </div>
          <PaymentDetailsView
            contractor={contractor}
            onChange={() => setStep('edit_payment_details')}
            canChange={true}
          />
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: isMobile ? 'stretch' : 'flex-end',
              gap: 5,
            }}
          >
            <UiButton
              variant="secondary"
              onTap={close}
              style={{
                alignSelf: isMobile ? 'normal' : 'start',
                flex: isMobile ? 1 : 'unset',
              }}
            >
              Отмена
            </UiButton>
            <UiButton
              onTap={confirm}
              style={{
                alignSelf: isMobile ? 'normal' : 'start',
                flex: isMobile ? 1 : 'unset',
              }}
            >
              Подтвердить
            </UiButton>
          </div>
        </Flex>
      )
    } else if (step === 'edit_payment_details') {
      content = (
        <PaymentDetailsEditView
          contractor={contractor}
          onClose={() => setStep('check_payment_details')}
        />
      )
    } else {
      content = (
        <>
          <H1 style={{ marginBottom: '2rem' }}>Подписание инвойса</H1>
          <ActionStateView
            state={
              store.hasTaxAccount
                ? store.fetchTaxAccountWithholdingsState
                : store.fetchTaxBoxWithholdingsState
            }
            styles={{ loader: { height: isMobile ? 400 : 225 } }}
            animate={false}
          >
            {() =>
              store.hasTaxAccount ? (
                <ContractorTaxAccountAccept store={store} />
              ) : (
                <ContractorTaxBoxAccept store={store} />
              )
            }
          </ActionStateView>
        </>
      )
    }

    if (store.contractorAcceptLoaderIsOpen) {
      content = <ContractorAcceptLoader store={store} />
    }

    const stepStyles = step === 'accept' ? { content: { paddingBottom: 0 } } : undefined

    return (
      <Modal
        size={store.contractorAcceptLoaderIsOpen ? 350 : 650}
        fullScreen={isMobile}
        styles={{
          content: {
            ...stepStyles?.content,
          },
        }}
        isOpen={true}
        onClose={() => {
          store.contractorAcceptDialogIsOpen = false
          store.contractorAcceptLoaderIsOpen = false
        }}
      >
        <style>{'.__jivoMobileButton { display: none }'}</style>
        {content}
      </Modal>
    )
  }
)

interface TaskActionsStoreProps {
  currentUser: UserStore
  task: Entity<TaskEntity>
  toast: ReturnType<typeof useStateToast>
  signDialog: SignDialogStore
}

class TaskActionsStore {
  activatedFlexibleTaxBox: boolean
  taxSavingFields: Record<TaxSavingItemKind, boolean>
  taxSavingAmount: number
  forceTaxSaving: boolean

  constructor({ currentUser, task, toast, signDialog }: TaskActionsStoreProps) {
    this.currentUser = currentUser
    this.task = task
    this.toast = toast
    this.signDialog = signDialog
    this.multiActions = new TasksMultiActionsStore({
      currentUser,
      api: task.api,
      toast,
      signDialog,
    })
    this.contractStore =
      task.data.contract &&
      new DocumentStore({
        document: task.data.contract,
        toast,
        currentUser,
        signDialog,
      })
    this.activatedFlexibleTaxBox = Boolean(
      currentUser.user?.data?.contractor?.data?.tax_box?.data?.is_flexible
    )

    this.forceTaxSaving = Boolean(
      currentUser.user?.data?.contractor?.data?.tax_box?.data?.force_tax_saving
    )

    this.taxSavingFields = {
      [TaxSavingItemKind.Task]: true,
      [TaxSavingItemKind.Debt]: true,
      [TaxSavingItemKind.Penalty]: true,
      [TaxSavingItemKind.Receipt]: true,
    }

    this.taxSavingAmount = 0
    makeAutoObservable(this)

    reaction(
      () => this.fetchTaxBoxWithholdingsState.value,
      (value: any) => {
        if (value && this.activatedFlexibleTaxBox) {
          this.taxSavingAmount = Number(value.tax_savings.amount)
          this.forceTaxSaving = value.force_tax_saving
          Object.keys(this.taxSavingFields).forEach((fieldKey: any) => {
            if (!this.forceTaxSaving) {
              this.setTaxSavingFields(fieldKey, false)
            } else {
              this.setTaxSavingFields(fieldKey, true)
            }
            if (
              !value.tax_savings.items.find((item: any) => {
                return item.kind === fieldKey
              })
            ) {
              this.setTaxSavingFields(fieldKey, false)
            }
          })
        }
      }
    )
    reaction(
      () => this.taxSavingFields.DEBT,
      (checked: any) => {
        const taxSavingItem =
          this.fetchTaxBoxWithholdingsState.value.tax_savings.items.find((item: any) => {
            return item.kind === TaxSavingItemKind.Debt
          })
        if (taxSavingItem) {
          if (checked) {
            this.taxSavingAmount += Number(taxSavingItem.amount)
          } else {
            this.taxSavingAmount -= Number(taxSavingItem.amount)
          }
        }
      }
    )
    reaction(
      () => this.taxSavingFields.PENALTY,
      (checked: any) => {
        const taxSavingItem =
          this.fetchTaxBoxWithholdingsState.value.tax_savings.items.find((item: any) => {
            return item.kind === TaxSavingItemKind.Penalty
          })
        if (taxSavingItem) {
          if (checked) {
            this.taxSavingAmount += Number(taxSavingItem.amount)
          } else {
            this.taxSavingAmount -= Number(taxSavingItem.amount)
          }
        }
      }
    )
    reaction(
      () => this.taxSavingFields.RECEIPT,
      (checked: any) => {
        const taxSavingItem =
          this.fetchTaxBoxWithholdingsState.value.tax_savings.items.find((item: any) => {
            return item.kind === TaxSavingItemKind.Receipt
          })
        if (taxSavingItem) {
          if (checked) {
            this.taxSavingAmount += Number(taxSavingItem.amount)
          } else {
            this.taxSavingAmount -= Number(taxSavingItem.amount)
          }
        }
      }
    )
  }

  currentUser: UserStore
  task: Entity<TaskEntity>
  toast: { success: any; error: any }
  signDialog: SignDialogStore
  multiActions: TasksMultiActionsStore
  contractStore?: DocumentStore

  setTaxSavingFields = (kind: TaxSavingItemKind, value: boolean) => {
    this.taxSavingFields[kind] = value
  }

  openDialog() {
    if (this.currentUser.kind === 'contractor') {
      if (this.hasEnabledAction('accept_annul')) {
        this.openAcceptAnnulDialog()
      } else if (this.hasEnabledAction('make_work_acceptance')) {
        this.openContractorAcceptDialog()
      }
    } else {
      if (
        this.hasEnabledAction('sign_terms') &&
        this.currentUser.hasAbility('tasks.sign_terms')
      ) {
        this.openTermsSignDialog('sign_terms')
      } else if (
        this.hasEnabledAction('accept') &&
        this.currentUser.hasAbility('tasks.accept')
      ) {
        this.openMultiAcceptDialog()
      }
    }
  }

  get problems() {
    return getTaskProblems(this.task)
  }

  hasEvent(kind: TaskAction) {
    return this.task.data.events && this.task.data.events.find((e) => e.kind === kind)
  }

  hasAction(kind: TaskAction) {
    return this.task.data.actions && this.task.data.actions.includes(kind)
  }

  isActionDisabled(action: TaskAction) {
    return getTaskActionState(action, this.problems).isDisabled
  }

  hasEnabledAction(action: TaskAction) {
    return this.hasAction(action) && !this.isActionDisabled(action)
  }

  getActionState(action: TaskAction) {
    return getTaskActionState(action, this.problems)
  }

  actionState = initialActionState

  get isLoading() {
    return this.actionState.state === 'pending'
  }

  action(action: string, payload?: any) {
    this.actionState = this.task.action({ action, payload })
    this.actionState.then(
      (data) => {
        this.task.setData(data)
        if (action === 'make_work_acceptance') {
          if (this.task.data.message) {
            this.toast.success(this.task.data.message)
          } else {
            this.toast.success({ title: 'Инвойс подписан.' })
          }
        } else if (successMessages[action]) {
          this.toast.success({ title: successMessages[action] })
        }
      },
      (error) => {
        this.toast.error({ title: errorMessages[action], description: error.message })
      }
    )
    return this.actionState
  }

  sendCodeForAction(action: string) {
    return this.task.action({
      action: `code_for_${action}`,
      options: { method: 'POST' },
    })
  }

  // Этот отправляет code_for_${action}
  openActionDialog(action: TaskAction, props: Omit<OpenSignDialogProps, 'onSign'>) {
    this.actionState = initialActionState
    this.sendCodeForAction(action)
    this.signDialog.open({ ...props, onSign: (payload) => this.onSign(action, payload) })
  }

  // Этот отправляет code_for_sign
  openSignDialog(action: TaskAction, props: Omit<OpenSignDialogProps, 'onSign'>) {
    this.actionState = initialActionState
    this.sendCodeForAction('sign')
    this.signDialog.open({ ...props, onSign: (payload) => this.onSign(action, payload) })
  }

  onSign(action: TaskAction, payload: Object | undefined) {
    return this.action(action, payload)
  }

  // Подписание ТЗ
  openTermsSignDialog(action: TaskAction) {
    this.openSignDialog(action, {
      title: 'Подписание ТЗ',
      document: { title: 'Техническое задание', url: this.termsDocument!.url },
      text: 'подписать ТЗ',
    })
  }

  // Отправка отчета
  submitReport(data: TaskReportData) {
    this.action('done', { report: data }).then(() => this.openContractorAcceptDialog())
  }

  // Подписание акта
  openMultiAcceptDialog() {
    this.multiActions.openDialog('accept', [this.task])
  }

  // Подписание акта контрактором
  get hasTaxAccount() {
    return this.currentUser.user!.data.contractor!.data.tax_savings_account
  }

  contractorAcceptDialogIsOpen = false
  checkPaymentDetails? = false
  fetchTaxBoxWithholdingsState = makeInitialActionState<TaxBoxWithholdings>()
  fetchTaxAccountWithholdingsState = makeInitialActionState<TaxAccountWithholdings>()

  contractorAcceptLoaderIsOpen = false
  contractorAcceptLoaderAction: any = null

  openContractorAcceptDialog(options: { checkPaymentDetails?: boolean } = {}) {
    this.checkPaymentDetails = options.checkPaymentDetails
    if (!this.checkPaymentDetails) this.confirmContractorAccept()
    this.contractorAcceptDialogIsOpen = true
  }

  confirmContractorAccept() {
    if (this.hasTaxAccount) {
      this.fetchTaxAccountWithholdingsState = this.task.action({
        action: 'withholdings',
        options: { method: 'GET' },
      })
    } else {
      this.fetchTaxBoxWithholdingsState = this.task.action({
        action: 'withholdings',
        options: { method: 'GET' },
      })
    }
    this.sendCodeForAction('sign')
  }

  contractorAccept(payload: Object | undefined, tax_savings_amount?: string) {
    let data: any = { ...payload }
    if (tax_savings_amount) data.tax_savings_amount = tax_savings_amount
    let action = this.task.action({ action: 'make_work_acceptance', payload })
    action.then((data) => {
      this.task.setData(data)
    })
    this.contractorAcceptLoaderAction = action
    this.contractorAcceptLoaderIsOpen = true
    return action
  }

  // Оплата
  openPayDialog(action: TaskPayAction) {
    this.multiActions.openDialog(action, [this.task])
  }

  openAutoPayDialog() {
    this.multiActions.openDialog('autopay', [this.task])
  }

  // Аннулирование
  openAnnulDialog() {
    this.multiActions.openDialog('annul', [this.task])
  }

  openAcceptAnnulDialog() {
    this.openActionDialog('accept_annul', {
      title: 'Аннулирование акта',
      document: {
        title: 'Соглашение об аннулировании',
        url: this.task.data.annulment!.agreement_url,
      },
      text: 'подписать соглашение об аннулировании',
    })
  }

  // Удаление
  delete() {
    return this.task.delete().then(
      () => {
        this.toast.success({
          title: this.task.data.kind === 'task' ? 'Задание удалено' : 'Акт удалён',
        })
      },
      (error) =>
        this.toast.error({ title: 'Не удалось удалить', description: error.message })
    )
  }

  get termsDocument() {
    let taskData = this.task.data.task!
    let url =
      taskData.terms_of_reference_blurb_pdf_url ||
      taskData.terms_of_reference_original_pdf_url
    if (!url) return undefined
    let status = this.hasEvent('start')
      ? 'подписано'
      : this.hasEvent('sign_terms')
      ? 'подписано заказчиком'
      : 'сформировано'
    return { url, status }
  }

  get actDocument() {
    let actData = this.task.data.act
    let url =
      actData.work_acceptance_blurb_pdf_url || actData.work_acceptance_original_pdf_url
    if (!url) return undefined
    let actStatusMap: { [key: string]: string } = {
      not_accepted: 'сформирован',
      accepted_by_company: 'подписан заказчиком',
      accepted_by_contractor: 'подписан исполнителем',
      accepted: 'подписан',
      annulled: 'аннулирован',
      rejected: 'отклонён',
      rejected_by_contractor: 'отклонён исполнителем',
    }
    let status = actStatusMap[actData.status] || actData.status
    return { url, status }
  }

  render() {
    return (
      <>
        {this.contractorAcceptDialogIsOpen && (
          <ContractorAcceptDialog
            store={this}
            checkPaymentDetails={this.checkPaymentDetails}
          />
        )}
        {this.multiActions.render()}
      </>
    )
  }
}

export default TaskActionsStore
