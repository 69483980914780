import React, { useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import cx from 'clsx'

import { usePusherSubscription } from 'App/pusher'
import { Layout } from 'components/ui'
import { UserRegistrationEntity } from 'entities'
import { Entity } from 'mobx/MobxApi'
import FormStep from '../FormStep'
import StepsController from '../../steps/StepsController'
import { ConnectToPlatformStore } from '../../store/ConnectToPlatformStore'

import {
  ADDITIONAL_DOCUMENTS_REQUIRED,
  CHECK_ERROR,
  CHECK_IN_PROGRESS,
  CYBERITY_VERIFICATION_REQUIRED,
  DOCUMENTS_REQUIRED,
  PERSONAL_DATA_REQUIRED,
} from '../../constants/statuses'

import css from './styles.module.css'
import ConnectToPlatformStep from '../ConnectToPlatformStep'
import registrationApi from 'pages/registration/api'

interface IRegistrationViewProps {
  registration: Entity<UserRegistrationEntity>
  store: ConnectToPlatformStore
}

const RegistrationView = observer(({ registration, store }: IRegistrationViewProps) => {
  usePusherSubscription('update-registration', () => {
    registrationApi.getData()
  })

  const { status } = registration.data

  const steps: any = [
    {
      id: 'connect',
      title: (
        <>
          Подключиться
          <br />
          к&nbsp;платформе
        </>
      ),
      render: () => <ConnectToPlatformStep registration={registration} store={store} />,
    },
    {
      id: 'personal_data',
      title: (
        <>
          Анкета
          <br />
          и&nbsp;документы
        </>
      ),
      render: () => <FormStep registration={registration} store={store} />,
      supportStatuses: [
        PERSONAL_DATA_REQUIRED,
        CYBERITY_VERIFICATION_REQUIRED,
        DOCUMENTS_REQUIRED,
        ADDITIONAL_DOCUMENTS_REQUIRED,
      ],
    },
    {
      id: 'check_in_progress',
      title: (
        <>
          Проверка
          <br />
          документов
        </>
      ),
      render: () => <FormStep registration={registration} store={store} />,
      supportStatuses: [CHECK_IN_PROGRESS, CHECK_ERROR],
    },
  ]

  // if (company) {
  //   steps.push({
  //     id: 'contract',
  //     title: 'Договор',
  //     render: () => <ContractStep registration={registration} />,
  //     // показываем contract если с бэка пришел один статусов ниже
  //     supportStatuses: [
  //       CONTRACT,
  //       CANCELLED
  //     ]
  //   })
  // }

  // возвращаем шаг по статусу который указан в supportStatuses
  const getCurrentStep = useMemo(
    () => steps.find((item: any) => item.supportStatuses?.includes(status)) || steps[0],
    [steps, status]
  )

  return (
    <Layout smallPaddingTop className={cx('konsol-ui', css.root)}>
      <StepsController steps={steps} step={getCurrentStep} />
    </Layout>
  )
})

export default RegistrationView
