import React from 'react'

import s from './style.module.css'

const Card = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className={s.card}>{children}</div>
  )
}

export default Card