import React from 'react'
import { observer } from 'mobx-react-lite'
import cx from 'clsx'

import { ReactComponent as SberIcon } from 'assets/icon-sber.svg'
import { ReactComponent as QrSberIcon } from 'assets/qr-sber.svg'
import { ReactComponent as QrFnsIcon } from 'assets/qr-fns.svg'

import { CountriesIcons } from 'components/ui/CountriesIcons'

import { ConnectToPlatformStore } from '../../store/ConnectToPlatformStore'
import { useMediaContext } from 'App/MediaContext'

import { GoToSberbankButton } from '../../GoToSberbankButton'

import { Button, Flex, H1 } from 'components/ui'

import { Link } from 'ui'

import s from '../../styles.module.css'
import { fetchApi } from 'api'

const SelfRegisterSelfEmployedStep = observer(
  ({ store }: { store: ConnectToPlatformStore }) => {
    let { isMobile } = useMediaContext()

    return (
      <div>
        <H1 className={s.contentTitle}>Получение статуса самозанятого</H1>
        <p className={cx(s.helpText, { [s.helpTextMobile]: isMobile })}>
          Стать самозанятым можно с помощью приложения Сбербанк Онлайн или через
          официальное приложение налоговой службы «Мой налог». Выберите наиболее
          подходящий для вас способ.
        </p>
        <div className={s.registrationCard}>
          <div className={s.registrationCardTitle}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <SberIcon className={s.sberIcon} />
              Сбербанк Онлайн
            </div>
            {!isMobile && (
              <CountriesIcons
                countries={{ ru: true, kg: true, am: true, kz: true, by: true }}
              />
            )}
          </div>
          <div className={s.registrationCardContent}>
            {!isMobile && <QrSberIcon className={s.qrIcon} />}
            <div>
              Клиенты Сбербанка могут стать самозанятыми через приложение Сбербанк Онлайн
              <ul
                className={s.requirementsList}
                {...(isMobile && { style: { marginBottom: '16px' } })}
              >
                <li className={s.requirementsListItem}>Должен быть ИНН</li>
                <li className={s.requirementsListItem}>
                  Для граждан РФ, Белоруссии, Армении, Кыргызстана и Казахстана
                </li>
                <li className={s.requirementsListItem}>Нужно быть клиентом Сбербанка</li>
              </ul>
              {isMobile && (
                <>
                  <CountriesIcons
                    countries={{ ru: true, kg: true, am: true, kz: true, by: true }}
                  />
                  <GoToSberbankButton />
                </>
              )}
            </div>
          </div>
          {!isMobile && (
            <p className={`${s.registrationCardFooterText}`}>
              Считайте QR-код для регистрации самозанятого через Сбербанк Онлайн
            </p>
          )}
        </div>
        <div className={cx(s.registrationCard, s.registrationCardLast)}>
          <div className={s.registrationCardTitle}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div className={s.fnsIcon} />
              Мой налог
            </div>
            {!isMobile && <CountriesIcons countries={{ ru: true }} />}
          </div>
          <div className={s.registrationCardContent}>
            {!isMobile && <QrFnsIcon className={s.qrIcon} />}
            <div>
              Граждане РФ могут стать самозанятыми через приложение Мой налог
              <ul
                className={s.requirementsList}
                {...(isMobile && { style: { marginBottom: '16px' } })}
              >
                <li className={s.requirementsListItem}>Должен быть ИНН</li>
                <li className={s.requirementsListItem}>Для граждан РФ</li>
                <li className={s.requirementsListItem}>Потребуется паспорт</li>
              </ul>
              {isMobile && (
                <>
                  <CountriesIcons countries={{ ru: true }} />
                  <p className={s.helpTextMobile} style={{ marginTop: '20px' }}>
                    Скачать приложение Мой налог:
                  </p>
                  <Flex
                    gap="16px"
                    align="center"
                    justify="start"
                    style={{ marginTop: '10px' }}
                  >
                    <a
                      className={s.link}
                      href="https://apps.apple.com/ru/app/мой-налог/id1437518854"
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => {
                        fetchApi({
                          url: 'users/events',
                          method: 'PUT',
                          data: { event: 'registration_moi_nalog' },
                        })
                      }}
                    >
                      <div className={s.appStoreBadge} />
                    </a>
                    <a
                      className={s.link}
                      href="https://play.google.com/store/apps/details?id=com.gnivts.selfemployed"
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => {
                        fetchApi({
                          url: 'users/events',
                          method: 'PUT',
                          data: { event: 'registration_moi_nalog' },
                        })
                      }}
                    >
                      <div className={s.googlePlayBadge} />
                    </a>
                  </Flex>
                </>
              )}
            </div>
          </div>
          {!isMobile && (
            <p className={`${s.registrationCardFooterText}`}>
              Считайте QR-код для регистрации самозанятого через Мой налог
            </p>
          )}
        </div>
        <Flex
          direction={isMobile ? 'column' : 'row'}
          gap="1rem"
          align={isMobile ? 'start' : 'center'}
        >

          <Button
            style={{
              fontSize: '16px',
              lineHeight: '18px',
              padding: '16px 31px',
            }}
            onTap={() => store.selectKind('self_employed')}
          >
            Всё получилось, продолжить
          </Button>
        </Flex>

        <div style={{ marginTop: '1rem' }}>
          Please contact us at <Link href='mailto:hello@restaff.pro' target='_blank'>hello@restaff.pro</Link> if you have any issues
        </div>
      </div>
    )
  }
)

export default SelfRegisterSelfEmployedStep
