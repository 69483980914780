import React, { useState, useImperativeHandle } from 'react'
import { observer } from 'mobx-react-lite'
import { without } from 'lodash'

import { ReactComponent as CancelIcon } from '@material-design-icons/svg/round/cancel.svg'

import { FilterConfig } from '../../FiltersStore'
import CheckboxFilter from '../CheckboxFilter'
import NumberRangeFilter from '../NumberRangeFilter'
import MultiSelectFilter, { MultiSelectValue } from '../MultiSelectFilter'
import TextFilter from '../TextFilter'
import DateFilter, { dateOptions } from '../DateFilter'
import { FilterButton } from '../FilterButton'

import { Menu, MenuItem } from '../../ui'
import { printDate } from 'utils/datetime'

import { FilterValue, NumberRangeFilterValue, DateFilterValue } from 'entities'

import { Flex, IconButton } from 'components/ui'

import { NOT_FILLED_TEXT, isNotFilledValue } from '../../constants'

interface FilterViewProps {
  style?: React.CSSProperties
  config: FilterConfig
  value: FilterValue
  onChange: (value: FilterValue) => void
  onRemove: () => void
}

const renderNumberRangeFilterValue = (value: NumberRangeFilterValue) => {
  let { from, to } = value
  return [from ? `от ${from}` : '', to ? `до ${to}` : ''].join(' ')
}

const renderDateFilterValue = (value: DateFilterValue) => {
  let { type } = value
  if (type === 'period') {
    let { from, to } = value
    return [from ? `от ${printDate(from)}` : '', to ? `до ${printDate(to)}` : ''].join(
      ' '
    )
  } else {
    return dateOptions[type]
  }
}

export const FilterView = observer(
  ({ style, config, value, onChange, onRemove }: FilterViewProps, ref: any) => {
    let [isOpen, setIsOpen] = useState(false)
    useImperativeHandle(
      ref,
      () => ({
        open: () => setIsOpen(true),
      }),
      []
    )

    let { title, withNotFilled } = config
    let content: (props: any) => React.ReactNode
    if (config.kind === 'checkbox') {
      content = ({ close }: any) => {
        return <CheckboxFilter value={value as any} onChange={onChange} onClose={close} />
      }
    } else if (config.kind === 'numberRange') {
      content = ({ close }: any) => {
        return (
          <NumberRangeFilter
            value={value as NumberRangeFilterValue}
            onChange={onChange}
            onClose={close}
            withNotFilled={withNotFilled}
          />
        )
      }
    } else if (config.kind === 'select') {
      let options = config.options

      content = () =>
        options.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            style={{ fontWeight: option.value === value ? 600 : 400 }}
          >
            {option.label}
          </MenuItem>
        ))
    } else if (config.kind === 'multiselect') {
      let options = config.options

      content = ({ close }: any) => {
        return (
          <MultiSelectFilter
            value={value as MultiSelectValue | undefined}
            onChange={onChange}
            onClose={close}
            options={options}
            withNotFilled={withNotFilled}
          />
        )
      }
    } else if (config.kind === 'text' || config.kind === 'number') {
      content = ({ close }: any) => (
        <TextFilter
          value={value as string}
          onChange={onChange}
          onClose={close}
          kind={config.kind}
          withNotFilled={withNotFilled}
        />
      )
    } else {
      // kind === 'date'
      content = ({ close }: any) => (
        <DateFilter
          value={value as DateFilterValue}
          onChange={onChange}
          onClose={close}
          withNotFilled={withNotFilled}
        />
      )
    }

    let caption: any
    if (value !== undefined) {
      let renderedValue: any = value
      if (isNotFilledValue(value)) {
        renderedValue = NOT_FILLED_TEXT
      } else if (config.kind === 'numberRange') {
        renderedValue = renderNumberRangeFilterValue(value as NumberRangeFilterValue)
      } else if (config.kind === 'select') {
        renderedValue = config.options.find((o) => o.value === value)!.label
      } else if (config.kind === 'multiselect') {
        renderedValue = (value as MultiSelectValue)
          .map((value) =>
            value === 'not_filled'
              ? NOT_FILLED_TEXT
              : config.options.find((o) => o.value === value)!.label
          )
          .join(', ')
      } else if (config.kind === 'checkbox') {
        renderedValue = value ? 'Да' : 'Нет'
      } else if (config.kind === 'date') {
        renderedValue = renderDateFilterValue(value as DateFilterValue)
      }

      caption = (
        <>
          {title}: {renderedValue}
        </>
      )
    } else {
      caption = title
    }

    let removeButton = (
      <IconButton
        onTap={onRemove}
        tooltip="Удалить фильтр"
        style={{ marginRight: '-.5rem', pointerEvents: 'all' }}
      >
        <CancelIcon style={{ width: 21, height: 21 }} />
      </IconButton>
    )

    let button = (ref: any, { open }: any) => (
      <div ref={ref} style={style}>
        <FilterButton onTap={open}>
          <Flex gap=".5rem" justify="space-between" align="center">
            <div
              style={{
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
              }}
            >
              {caption}
            </div>
            {removeButton}
          </Flex>
        </FilterButton>
      </div>
    )

    let onSelect
    if (config.kind === 'multiselect') {
      onSelect = (id: string) => {
        let multiSelectValue = value as MultiSelectValue | undefined
        onChange(
          multiSelectValue
            ? multiSelectValue.includes(id)
              ? without(multiSelectValue, id)
              : [...multiSelectValue, id]
            : [id]
        )
      }
    } else {
      onSelect = onChange
    }

    return (
      <Menu
        menu={content}
        onSelect={onSelect}
        placement={{ offset: 5 }}
        isOpen={isOpen}
        onChangeIsopen={setIsOpen}
        matchWidth={true}
        maxHeight={300}
        closeOnSelect={config.kind !== 'multiselect'}
      >
        {button}
      </Menu>
    )
  },
  { forwardRef: true }
)
