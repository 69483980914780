import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { observer, useLocalObservable } from 'mobx-react-lite'

import { ActionState, Entity, useMobxApi } from 'mobx/mobx'

import { H1, Flex, Button } from 'ui'
import { Layout, PageHeader, useStateToast } from 'components/ui'

import ProjectsList from './components/ProjectsList'
import ProjectItem from './components/ProjectItem'

import { PageProps } from 'app.types'
import { fetchApi } from 'api'

import classes from './styles.module.css'

import clsx from 'clsx'
import { comparer, makeAutoObservable, reaction } from 'mobx'
import { PaginatedStore, PaginatedView } from 'components/FilteredList2'
import { PaginationEntity } from 'entities'

const PageHeaderLabel = () => {
  const history = useHistory()
  return (
    <Flex gap="24px">
      <div onClick={() => history.push('/tasks')}>
        <H1 className={classes.pageHeaderLabel}>Задания</H1>
      </div>
      <div>
        <H1 className={clsx(classes.pageHeaderLabel, classes.active)}>Проекты</H1>
      </div>
    </Flex>
  )
}

const ProjectsPage = observer((props: PageProps) => {
  const history = useHistory()

  const mobxApi = useMobxApi()
  const toast = useStateToast()

  const listManager = useLocalObservable(
    () =>
      new (class {
        private _paginated: PaginatedStore<any>

        get query() {
          return {
            filters: {},
            sorting: {},
            search: '',
          }
        }

        get paginatedStore() {
          return this._paginated
        }

        constructor() {
          makeAutoObservable(this)

          this._paginated = new PaginatedStore<any>(
            ({ page, abortSignal }) =>
              mobxApi.fetch({
                type: 'scenario_projects/page',
                url: `scenario_projects/filter`,
                payload: { ...this.query, page },
                options: { signal: abortSignal, method: 'POST' },
              }) as ActionState<Entity<PaginationEntity<any>>>
          )

          reaction(
            () => this.query,
            () => this._paginated.setPage(1),
            { fireImmediately: true, equals: comparer.structural }
          )
        }
      })(),
    []
  )

  useEffect(() => {
    if (listManager.paginatedStore.fetchedValue) {
      listManager.paginatedStore.fetch()
    }
  }, [props.match.params.id])

  const handleCreate = async () => {
    try {
      const { total_count: total } = await fetchApi({
        url: 'scenario_projects/filter',
        method: 'POST',
        data: {
          ...listManager.query,
        },
      })
      const { id } = await fetchApi({
        method: 'POST',
        url: 'scenario_projects',
        data: {
          title: `Проект ${total + 1}`,
        },
      })
      history.push(`/projects/${id}`)
    } catch (e: any) {
      toast.error({
        title: 'Произошла ошибка при создании проекта',
        description: e.message,
      })
    }
  }

  return (
    <Layout smallPaddingTop>
      {!props.match.params.id && (
        <>
          <PageHeader
            title={<PageHeaderLabel />}
            controls={
              <>
                <Button onTap={handleCreate}>Новый проект</Button>
              </>
            }
          />
          <PaginatedView
            store={listManager.paginatedStore}
            render={(collection: any) => (
              <ProjectsList projects={collection} pageProps={props} />
            )}
            onChangePage={(page) => {
              if (listManager.paginatedStore.fetchedValue) {
                listManager.paginatedStore.setPage(page)
              }
            }}
          />
        </>
      )}
      {!!props.match.params.id && (
        <ProjectItem
          mobxApi={mobxApi}
          pageProps={props}
          projectId={Number(props.match.params.id)}
        />
      )}
    </Layout>
  )
})

export default ProjectsPage
