import { fetchApi } from 'api'
import { normalizePhone } from 'utils/validations'

export const checkPhone = (phone: string) =>
  fetchApi({
    method: 'POST',
    url: 'authorizations',
    withPrefix: false,
    data: { mobile_phone: '+' + normalizePhone(phone) },
  })

export const checkSms = (code: string) =>
  fetchApi({
    method: 'PUT',
    url: 'authorizations/verify',
    withPrefix: false,
    data: { code },
  })

export const loginAuth = (phone: string, password: string) =>
  fetchApi({
    method: 'POST',
    url: 'authorizations/password_login',
    withPrefix: false,
    data: {
      mobile_phone: '+' + normalizePhone(phone),
      password
    },
  })