import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { makeAutoObservable, reaction, comparer, computed } from 'mobx'
import { observer } from 'mobx-react-lite'
import { cloneDeep, findKey } from 'lodash'
import { Base64 } from 'js-base64'
import { fromPromise } from 'mobx-utils'
import { fetchApi } from 'api'

import { ReactComponent as SearchIcon } from '@material-design-icons/svg/round/search.svg'
import { ReactComponent as DropdownIcon } from '@material-design-icons/svg/round/arrow_drop_down.svg'
import { ReactComponent as ExportIcon } from 'assets/export.svg'
import { ReactComponent as TriangleIcon } from 'assets/triangle.svg'

import ErrorDialog from 'components/ErrorDialog'
import { PageProps } from 'app.types'
import { useMediaContext } from 'App/MediaContext'
import { usePusherSubscription } from 'App/pusher'
import { useMobxApi, MobxApi, Entity, Collection, ActionState, initialActionState } from 'mobx/mobx'
import { TaskEntity, TaskAction, PaginationEntity, SegmentEntity } from 'entities'
import {
  useUserStore,
  UserStore,
  useSignDialogStore,
  SignDialogStore,
} from 'stores/context'
import TasksMultiActionsStore, {
  actionMethods,
} from 'components/tasks/TasksMultiActionsStore'

import {
  Layout,
  LayoutHeading,
  Placeholder,
  Col,
  Input,
  ActionStateView,
  Flex,
  useStateToast,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from 'components/ui'
import {
  PaginatedStore,
  PaginatedView,
  FiltersStore,
  FilterMenuGroups,
  FilterConfigs,
  SelectedItemsStore,
  ActionConfigs,
  MultiActionButton,
  SortFiltersPanel,
} from 'components/FilteredList2'
import { AbilityButton } from 'components/abilityButtons'
import TaskList, { Sorting } from 'components/tasks/NewTaskList'
import DialogStore from 'stores/DialogStore'
import SelectCompanyAccountDialog from 'components/SelectCompanyAccountDialog'
import exportToFile from 'utils/exportToFile'
import numForm from 'utils/numForm'
import { Chips } from 'components'
import { MenuLabel } from 'components/ui/Menu'
import { Divider } from '@chakra-ui/react'
import useFileDialog from 'utils/useFileDialog'

import s from './styles.module.css'

const actWordForms = { one: 'акт', two: 'акта', many: 'актов' }
const taskWordForms = { one: 'задание', two: 'задания', many: 'заданий' }

const actWordFormsWith = { one: 'актом', two: 'актами', many: 'актами' }
const taskWordFormsWith = { one: 'заданием', two: 'заданиями', many: 'заданиями' }

const makeFilterConfigs = (
  type: 'tasks' | 'acts',
  segments: Collection<SegmentEntity>
) => {
  let filters: FilterConfigs = {
    amount: {
      title: 'Сумма инвойса',
      kind: 'numberRange',
    },
    act_status: {
      title: 'Статус акта',
      kind: 'multiselect',
      options: [
        { value: 'not_accepted', label: 'Не подписан' },
        { value: 'accepted_by_company', label: 'Подписан компанией' },
        { value: 'accepted_by_contractor', label: 'Подписан исполнителем' },
        { value: 'accepted', label: 'Подписан' },
        { value: 'rejected', label: 'Отклонён' },
        { value: 'annulled', label: 'Аннулирован' },
      ],
    },
  }

  if (type === 'tasks') {
    filters.task_status = {
      title: 'Статус задания',
      kind: 'multiselect',
      options: [
        { value: 'assigned', label: 'Предложено исполнителю' },
        { value: 'signed_by_company', label: 'Предложено исполнителю' },
        { value: 'signed_by_contractor', label: 'Принято исполнителем' },
        { value: 'in_progress', label: 'В работе' },
        { value: 'declined', label: 'Исполнитель отказался' },
        { value: 'done', label: 'Выполнено' },
        { value: 'cancelled', label: 'Отменено' },
        { value: 'draft', label: 'Черновик' },
      ],
    }
  }
  if (type === 'acts') {
    filters.act_date = {
      title: 'Дата акта',
      kind: 'date',
    }
  }
  Object.assign(filters, {
    payment_status: {
      title: 'Статус платежа',
      kind: 'multiselect',
      options: [
        { value: 'not_paid', label: 'Не оплачено' },
        { value: 'autopay', label: 'Оплата одобрена' },
        { value: 'paid', label: 'Оплачено' },
        { value: 'error', label: 'Ошибка' },
      ],
    },
    act_created_date: {
      title: 'Дата создания акта',
      kind: 'date',
    },
    payment_date: {
      title: 'Дата платежа',
      kind: 'date',
    },
  })
  if (!segments.isEmpty) {
    filters.contractors = {
      title: 'Исполнители',
      kind: 'select',
      options: segments.items.map((item) => ({ value: item.id, label: item.data.title })),
    }
  }

  return filters
}

const parseBase64Json = (state: string) => {
  try {
    return JSON.parse(Base64.decode(state))
  } catch (e) {
    return undefined
  }
}

interface TaskListStoreProps {
  type: 'tasks' | 'acts'
  filterConfigs: FilterConfigs
  initialState?: any
  api: MobxApi
  currentUser: UserStore
  signDialog: SignDialogStore
  segments: Collection<SegmentEntity>
  toast: ReturnType<typeof useStateToast>
  sorting?: Sorting
  page: number
  search: string
}

class TaskListStore {
	errorDialog = new DialogStore({ component: ErrorDialog })

  constructor(props: TaskListStoreProps) {
    const {
      type,
      filterConfigs,
      api,
      currentUser,
      signDialog,
      toast,
      initialState,
      sorting = { col: 'number', direction: 'desc' },
      page,
      search = '',
    } = props

    this.api = api
    this.currentUser = currentUser
    this.toast = toast
    this.type = type
    this.sorting = sorting
    this.page = page
    this.search = search

    this.paginated = new PaginatedStore<TaskEntity>(
      ({ page, abortSignal }) =>
        api.fetch({
          type: 'tasks/page',
          url: `${type}/filter`,
          payload: { ...this.query, page },
          options: { signal: abortSignal, method: 'POST' },
        }) as ActionState<Entity<PaginationEntity<TaskEntity>>>
    )

    this.sections =
      type === 'tasks'
        ? {
            all: { id: 'all', title: 'Все задания', filters: [] },
            for_sign_terms: {
              id: 'for_sign_terms',
              title: 'На одобрении',
              filters: [
                { name: 'task_status', value: ['assigned', 'signed_by_contractor'] },
              ],
            },
          }
        : {
            all: { id: 'all', title: 'Все акты', filters: [] },
            need_contractor_sign: {
              id: 'need_contractor_sign',
              title: 'Нужна подпись исполнителя',
              filters: [
                { name: 'act_status', value: ['not_accepted', 'accepted_by_company'] },
              ],
            },
            for_sign: {
              id: 'for_sign',
              title: 'На подпись',
              filters: [
                { name: 'act_status', value: ['not_accepted', 'accepted_by_contractor'] },
              ],
            },
            for_pay: {
              id: 'for_pay',
              title: 'На оплату',
              filters: [
                { name: 'act_status', value: ['accepted_by_company', 'accepted'] },
                { name: 'payment_status', value: ['not_paid', 'autopay'] },
              ],
            },
            paid: {
              id: 'paid',
              title: 'Оплачены',
              filters: [{ name: 'payment_status', value: ['paid'] }],
            },
          }

    this.filters = new FiltersStore({
      configs: filterConfigs,
      toast,
      initialState,
    })

    this.multiActions = new TasksMultiActionsStore({
      currentUser,
      api,
      toast,
      signDialog,
    })


    interface TaskMultiActionConfig {
      action: TaskAction
      sign: boolean
      refetch?: boolean
      forAll?: boolean
    }
    let actionConfigs: TaskMultiActionConfig[] = [
      { action: 'sign_terms', sign: true },
      { action: 'accept', sign: true },
      { action: 'autopay', sign: true },
      { action: 'cancel_autopay', sign: false },
      { action: 'pay', sign: true },
      { action: 'mark_paid', sign: true },
      { action: 'mark_paid_with_receipt', sign: true },
      { action: 'mark_paid_without_sms', sign: false, refetch: true, forAll: true },
      { action: 'annul', sign: true },
      { action: 'delete', sign: false, refetch: true },
    ]
    let actions: ActionConfigs<TaskEntity> = {}
    actionConfigs.forEach(({ action, sign, refetch, forAll }) => {
      actions[action] = {
        ability: currentUser.hasAbility(`tasks.${action}`) || forAll,
        getItemsForAction: (selectedItems) =>
          api.fetch({
            type: 'tasks',
            url: `${type}/for_multi_${actionMethods[action]}`,
            options: { method: 'POST' },
            payload: selectedItems
              ? { ids: selectedItems.map((item) => item.id) }
              : { filters: this.filters.state, search: this.search },
          }) as ActionState<Collection<TaskEntity>>,
        action: (tasks: Entity<TaskEntity>[]) => {
          if (sign) {
            return this.multiActions.openDialog(action, tasks)
          } else {
            let state = this.multiActions.baseMultiAction(action, {
              ids: tasks.map((t) => t.id),
            })
            if (refetch) state.then(() => this.paginated.fetch())
            return state
          }
        },
      }
    })

    this.selected = new SelectedItemsStore({
      list: computed(() => {
        if (this.paginated.fetchedValue) {
          let { items, total_pages, total_count } = this.paginated.fetchedValue.data
          return { items: items.items, pages: total_pages, count: total_count }
        } else {
          return { items: [], pages: 0, count: 0 }
        }
      }),
      actions,
      noItemsMessage: () =>
        `Не выбрано ${type === 'tasks' ? 'заданий' : 'актов'}, ` +
        'доступных для этого действия.',
      someItemsMessage: ({ actionCount, selectedCount }) =>
        'Действие можно выполнить только с ' +
        numForm(actionCount, type === 'tasks' ? taskWordFormsWith : actWordFormsWith) +
        ` из ${selectedCount}.`,
    })

    reaction(
      () => this.query,
      () => this.paginated.setPage(this.page),
      { fireImmediately: true, equals: comparer.structural, delay: 555 }
    )

    reaction(
      () => this.search,
      (search) => {
        this.delayedSearch = search
      },
      { delay: 555 }
    )

    makeAutoObservable(this)
  }

  api: MobxApi
  currentUser: UserStore
  toast: ReturnType<typeof useStateToast>
  type: 'acts' | 'tasks'
  search = ''
  delayedSearch = this.search
  sorting: Sorting
  page: number

  sections: any
  setSection(section: string) {
    this.filters.setState(this.sections[section].filters)
  }

  get section() {
    return findKey(this.sections, (value) =>
      comparer.structural(value.filters, this.filters.state)
    )
  }

  multiActions: TasksMultiActionsStore
  paginated: PaginatedStore<TaskEntity>
  filters: FiltersStore
  selected: SelectedItemsStore<TaskEntity>

  get serializedState() {
    return Base64.encode(JSON.stringify(this.filters.state))
  }

  get query() {
    return {
      filters: cloneDeep(this.filters.state), // clone needed to observe deep changes
      search: this.delayedSearch || this.search,
      sorting: this.sorting,
    }
  }

  createState = initialActionState

	createFromFile(file: File) {
		if (!file.name.match(/\.(csv|xls|xlsx)/)) {
			return alert('Поддерживаем реестры в форматах: .csv, .xls, .xslx')
		}
		let formData = new FormData()
		formData.append('file', file)
		let state = fromPromise(
			fetchApi({ url: 'tasks/import', data: formData, method: 'POST' })
		)
		this.createState = state
		state.then(
			() => this.paginated.fetch(),
			(error: any) => {
				let title = 'Ошибка загрузки заданий'
				if (error.data?.code === 'failed_validation') {
					this.errorDialog.open({
						title,
						message: error.message,
						// TODO: remove string variant
						errors: Array.isArray(error.data.errors)
							? error.data.errors
							: [error.data.errors],
					})
				} else {
					this.toast.error({ title, description: error.message })
				}
			}
		)
	}


  exportDialog = new DialogStore({ component: SelectCompanyAccountDialog })

  async exportToFile(format: string) {
    if (format === '1c') {
      try {
        let company = this.currentUser.company!
        await company.fetch()
        let accounts = this.currentUser.company!.data.bank_details ?? []
        if (accounts.length === 0) {
          this.toast.error({ title: 'Не удалось экспортировать' })
          return
        } else if (accounts.length > 1) {
          this.exportDialog.open({
            accounts,
            onSelect: (id) => {
              this.doExport(format, { account_id: id })
              this.exportDialog.close()
            },
          })
        } else {
          this.doExport(format, { account_id: accounts[0].id })
        }
      } catch (e) {}
    } else {
      this.doExport(format)
    }
  }

  doExport(format: string, data?: any) {
    let query =
      this.selected.isAllSelected || this.selected.isEmpty
        ? { filters: this.filters.state }
        : { ids: this.selected.selectedItemsList.map((item) => item.id) }
    exportToFile({
      url: this.type,
      format,
      data,
      ...query,
      toast: this.toast,
      filename: `konsol_export_${this.type}.${format}`,
    })
  }
}

interface TasksListViewProps extends PageProps {
  type: 'tasks' | 'acts'
  segments: Collection<SegmentEntity>
}

const useQuery = (): any => {
  const { search } = useLocation()
  return React.useMemo(() => new URLSearchParams(search), [search])
}

const TaskListView = observer(({ type, match, segments }: TasksListViewProps) => {
  let history = useHistory()
  const query = useQuery()
  let { isMobile } = useMediaContext()
  let api = useMobxApi()
  let toast = useStateToast()
  let currentUser = useUserStore()

  const { page = 1, state } = match.params
  const search = query.get('search')

  usePusherSubscription('update-task', (event: any) => {
    let task = api.entities.tasks.get(event.id)
    if (task) {
      task.setData(event)
    }
  })

  let [filterConfigs] = useState(() => makeFilterConfigs(type, segments))
  let signDialog = useSignDialogStore()
  let [store] = useState(() => {
    let initialState = state ? parseBase64Json(state) : undefined
    let sorting: Sorting = { col: 'number', direction: 'desc' }
    let store = new TaskListStore({
      type,
      filterConfigs,
      currentUser,
      api,
      signDialog,
      toast,
      segments,
      initialState,
      sorting,
      page: Number(page),
      search,
    })
    reaction(
      () => store.serializedState,
      (serializedState) => {
        history.push(
          `/${type}/${page > 1 ? `page/${page}/` : ''}${serializedState}${
            store.search && store.search.length > 0 ? `?search=${store.search}` : ''
          }`
        )
      }
    )
    return store
  })

  let buttons = []
  if (currentUser.hasFeature('templates')) {
    buttons.push(
      <AbilityButton
        ability={currentUser.hasAbility('tasks.create')}
        onTap={() => history.push(type === 'tasks' ? '/tasks/new' : '/acts/new')}
        size={isMobile ? 's' : 'm'}
      >
        {isMobile ? 'Создать' : type === 'tasks' ? 'Создать задание' : 'Создать акт'}
      </AbilityButton>
    )
  }

  const menuItems = (
    <>
      <MenuItem onClick={() => store.exportToFile('1c')}>
        Экспортировать файл для банка
      </MenuItem>
      <MenuItem onClick={() => store.exportToFile('xlsx')}>
        Экспортировать в xlsx
      </MenuItem>
      <MenuItem onClick={() => store.exportToFile('json')}>Экспортировать в 1C</MenuItem>
    </>
  )

  let menu = type === 'acts' && (
    <>
      <Menu>
        <MenuButton
          as={Button}
          icon={
            <div className={s.exportIcon}>
              <ExportIcon />
            </div>
          }
          size="s"
          design="normal"
          style={{ padding: '4px 12px', transform: 'none' }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            Экспорт <TriangleIcon style={{ marginLeft: 6, flexShrink: 0 }} />
          </div>
        </MenuButton>
        <MenuList>{menuItems}</MenuList>
      </Menu>
      {store.exportDialog.render()}
    </>
  )

  let [menuGroups] = useState(() => {
    let groups: FilterMenuGroups = [{ filters: [...Object.keys(filterConfigs)] }]
    return groups
  })

  let payAction = currentUser.companyPayAction
  let payActionNames = {
    pay: 'Оплатить',
    mark_paid: 'Отметить оплаченными',
    mark_paid_with_receipt: 'Сформировать чеки',
  }
  let hasBundlesAutopay = currentUser.hasFeature('bundles_autopay')

  let onChangePage = (page: number) => {
    const url =
      `/${type}/page/${page}` +
      (state ? `/${state}` : '') +
      (store.search?.length > 0 ? `?search=${store.search}` : '')
    store.paginated.setPage(Number(page))
    history.push(url)
  }

  const disallowRolesMarkPaidWithoutSms = ['manager', 'manager_with_sign', 'readonly']
  const allowMarkPaidWithoutSms = !disallowRolesMarkPaidWithoutSms.includes(
    currentUser.role as string
  )

  let fileDialog = useFileDialog((file) => store.createFromFile(file), {
    accept: '.xlsx',
  })

  return (
    <Col gap={isMobile ? 'var(--gap-s)' : 'var(--gap-m)'}>
      <Flex justify="space-between" align="center" gap="1rem">
        <Col gap=".5rem">
          <LayoutHeading>{type === 'tasks' ? 'Задания' : 'Акты'}</LayoutHeading>
        </Col>
        <Flex gap=".5rem">
          {buttons}
          <AbilityButton
            ability={currentUser.hasAbility('tasks.create')}
            onTap={fileDialog.open}
            isDisabled={!store.paginated.fetchedValue}
            isLoading={store.createState.state === 'pending'}
            size={isMobile ? 's' : 'm'}
          >
            {isMobile ? 'Загрузить' : 'Загрузить реестр заданий'}
          </AbilityButton>
          {fileDialog.render()}
        </Flex>
      </Flex>
      <Chips
        items={Object.values(store.sections)}
        value={store.section}
        onChange={(section) => store.setSection(section)}
      />
      <Input
        icon={<SearchIcon />}
        value={store.search}
        onChange={(value) => {
          store.search = value
          const url =
            `/${type}/page/${page}` +
            (state ? `/${state}` : '') +
            (store.search.length > 0 ? `?search=${value}` : '')
          history.push(url)
        }}
        style={{ maxWidth: 600 }}
        placeholder="Поиск по ФИО, телефону или номеру акта"
        isClearable={true}
      />
      {store.multiActions.render()}
      {store.selected.render()}
      {store.errorDialog.render()}

      <SortFiltersPanel
        sticky
        selectedStore={store.selected}
        filtersStore={store.filters}
        menuGroups={menuGroups}
        wordForms={type === 'acts' ? actWordForms : taskWordForms}
        stickyActions={menu}
        actions={
          <>
            {hasBundlesAutopay && (!isMobile || type !== 'tasks') && (
              <MultiActionButton
                ability={currentUser.hasAbility('tasks.autopay')}
                action="accept"
                store={store.selected}
              >
                Принять и оплатить
              </MultiActionButton>
            )}
            <Menu>
              <MenuButton
                as={Button}
                isDisabled={store.selected.isFetching()}
                design={isMobile ? 'text' : 'normal'}
                size={isMobile ? 'xs' : 's'}
                style={{ transform: 'none' }}
              >
                <Flex gap=".5rem" align="center">
                  Ещё
                  <DropdownIcon
                    style={{ marginRight: '-1rem', fill: 'var(--color-icon)' }}
                  />
                </Flex>
              </MenuButton>
              <MenuList>
                {hasBundlesAutopay && isMobile && type === 'tasks' && (
                  <MultiActionButton
                    action="accept"
                    ability={currentUser.hasAbility('tasks.autopay')}
                    store={store.selected}
                    as="menuItem"
                  >
                    Принять и оплатить
                  </MultiActionButton>
                )}
                {currentUser.hasFeature('mark_as_paid') && (
                  <MultiActionButton
                    action="mark_paid_without_sms"
                    ability={allowMarkPaidWithoutSms}
                    store={store.selected}
                    as="menuItem"
                  >
                    Отметить оплаченным
                  </MultiActionButton>
                )}
                <MultiActionButton
                  action="delete"
                  store={store.selected}
                  as="menuItem"
                  style={{ color: 'var(--color-red)' }}
                >
                  Удалить
                </MultiActionButton>
                {isMobile && type === 'acts' && (
                  <>
                    <Divider />
                    <MenuLabel>Экспорт</MenuLabel>
                    {menuItems}
                  </>
                )}
              </MenuList>
            </Menu>
          </>
        }
      />
      <PaginatedView
        store={store.paginated}
        render={(collection) =>
          collection.isEmpty ? (
            <Placeholder>Нет {type === 'acts' ? 'актов' : 'заданий'}</Placeholder>
          ) : (
            <TaskList
              tasks={collection}
              baseUrl={`/${type}`}
              isSelectable={store.selected.isSelectable}
              selectedItemsStore={store.selected}
              hasCompany={false}
              hasContractor={true}
              hasTaskStatus={type === 'tasks'}
              hasDashboardActions={false}
              isSortable={true}
              sorting={store.sorting}
              onChangeSorting={(sorting) => {
                store.sorting = sorting
              }}
              hasDrafts={false}
            />
          )
        }
        onChangePage={onChangePage}
      />
    </Col>
  )
})

interface TaskListPageProps extends PageProps {
  type: 'tasks' | 'acts'
}

const BaseTaskListPage = observer(({ type, ...props }: TaskListPageProps) => {
  let mobxApi = useMobxApi()
  let [fetchState] = useState(() =>
    mobxApi.fetch({ type: 'segments', url: 'segments/contractors' })
  )
  return (
    <Layout smallPaddingTop={true}>
      <ActionStateView state={fetchState}>
        {(segments) => <TaskListView type={type} segments={segments} {...props} />}
      </ActionStateView>
    </Layout>
  )
})

const TaskListPage = (props: any) => <BaseTaskListPage type="tasks" {...props} />
const ActListPage = (props: any) => <BaseTaskListPage type="acts" {...props} />

export { TaskListPage, ActListPage }
