import React from 'react'

export type ReactRef<T> = React.Ref<T> | React.RefObject<T> | React.MutableRefObject<T>

const mergeRefs =
  <T>(...refs: (ReactRef<T> | undefined)[]) =>
  (value: T) => {
    refs.forEach((ref) => {
      if (ref == null) return
      if (typeof ref === 'function') {
        ref(value)
        return
      }
      try {
        ;(ref as React.MutableRefObject<T>).current = value
      } catch (e) {}
    })
  }

export default mergeRefs
