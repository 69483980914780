import React from 'react'
import { Flex } from 'ui'
import { CompanyRisksCard } from '../CompanyRisksCard'
import { ITableProps } from '../Table'
import { v4 as uuid } from 'uuid'

import s from './styles.module.css'

const TableMobile = ({ cols, rows }: ITableProps) => (
  <Flex direction="col" gap="8px" className={s.tableMobile}>
    {rows.map((row) => (
      <CompanyRisksCard cols={cols} row={row} key={uuid()} />
    ))}
  </Flex>
)
export default TableMobile
