import React from 'react'
import { Amount, Button, H4, InlineText, P1 } from 'ui-kit'
import { Modal } from 'ui-kit'

import css from './styles.module.css'

type EstimatedTaxModalProps = {
  open: boolean
  taxesItems?: any
  onClose: () => void
}

const EstimatedTaxModal: React.FunctionComponent<EstimatedTaxModalProps> = ({
  open,
  taxesItems = [],
  onClose,
}) => {
  return (
    <Modal title="Расчёт налога" open={open} onClose={onClose}>
      <P1 className={css.paragraph}>
        Сумма налога рассчитывается в зависимости от вашего дохода за каждый месяц и
        уплачивается автоматически до 25 числа следующего месяца.
      </P1>
      {taxesItems.map((item: any, i: number) => (
        <div className={css.taxItem} key={i}>
          <H4 className={css.caption}>{item.title}</H4>
          <div className={css.taxItemRow}>
            <InlineText>Доход за период</InlineText>
            <Amount value={item.income_amount}></Amount>
          </div>
          <div className={css.taxItemRow}>
            <InlineText>
              Сумма налога{item.is_estimated && ' (предварительно)'}
            </InlineText>
            <Amount value={item.tax_amount}></Amount>
          </div>
          <div className={css.taxItemRow}>
            <InlineText>Срок оплаты</InlineText>
            <InlineText>{item.due_date}</InlineText>
          </div>
        </div>
      ))}
      <Button onClick={onClose} label="Понятно" fullWidth></Button>
    </Modal>
  )
}

export default EstimatedTaxModal
