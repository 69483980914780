import React from 'react'
import { observer, useLocalObservable } from 'mobx-react-lite'

import { Collection, Entity } from 'mobx/MobxApi'
import { ContractorEntity } from 'entities'

import { Avatar } from 'components'
import { SelectInput, Flex } from 'components/ui'

interface ContractorSelectorProps
  extends Omit<React.ComponentProps<typeof SelectInput>, 'options'> {
  contractors: Collection<ContractorEntity>
  value?: number
  onChange?: (id: number) => void
}

const ContractorOption = observer(
  ({ contractor }: { contractor: Entity<ContractorEntity> }) => {
    let { full_name, avatar_url, contracts } = contractor.data
    return (
      <Flex gap="1rem">
        <Avatar size="24px" name={full_name} value={avatar_url} />
        <div>
          {full_name}
          {contracts.isEmpty && ' (нет договора)'}
        </div>
      </Flex>
    )
  }
)

const ContractorSelector = observer(
  ({ contractors, value, onChange, ...restProps }: ContractorSelectorProps) => {
    let options = useLocalObservable(() =>
      contractors.items
        .filter((c) => !c.data.is_suspended)
        .map((contractor) => ({
          value: contractor.id,
          contractor,
          label: contractor.data.full_name,
          isDisabled: contractor.data.contracts.isEmpty,
        }))
    )
    return (
      <SelectInput
        placeholder="Выберите исполнителя"
        noOptionsMessage={() => 'Нет исполнителей'}
        isClearable={true}
        components={{
          Option: (props: any) => (
            <ContractorOption contractor={props.option.data.contractor} />
          ),
          ...restProps.components,
        }}
        {...restProps}
        options={options}
        value={
          value !== undefined &&
          options.find((option: { value: number }) => value === option.value)
        }
        onChange={(option: any) => onChange && onChange(option && option.value)}
      />
    )
  }
)

export default ContractorSelector
