import { useMemo, useEffect } from 'react'
import { toJS, autorun, observable } from 'mobx'

const localStorageObservable = <T>(key: string, initialValue?: (() => T) | T) => {
  let stored = localStorage[key]
  let value
  try {
    if (stored) value = JSON.parse(stored)
  } catch (e) {}
  if (value === undefined) {
    value =
      typeof initialValue === 'function' ? (initialValue as () => T)() : initialValue
  }
  let res = observable(value)
  let dispose = autorun(() => {
    let json = JSON.stringify(toJS(res))
    localStorage.setItem(key, json)
  })
  return { res, dispose }
}

const useLocalStorageObservable = <T>(key: string, initialValue: (() => T) | T) => {
  let { res, dispose } = useMemo(() => localStorageObservable(key, initialValue), [])
  useEffect(() => dispose, [])
  return res
}

export default localStorageObservable
export { useLocalStorageObservable }
