import React, { useEffect, useMemo, useState } from 'react'
import { observer } from 'mobx-react-lite'

import { useMobxApi, Entity } from 'mobx/mobx'
import { useUserStore } from 'stores/context'
import { useMediaContext } from 'App/MediaContext'
import {
  ActionStateView,
  Layout,
  Flex,
  Col,
  H1,
  H3,
  Checkbox,
  Input,
  Button,
  useStateToast,
} from 'components/ui'
import { LinkButton } from 'components/ui/Link'
import { Avatar } from 'components'
import { formatPhone } from 'utils/formatting'
import { validateEmail } from 'utils/validations'

import { ReactComponent as EmailIcon } from '@material-design-icons/svg/round/email.svg'
import { ReactComponent as PhoneIcon } from '@material-design-icons/svg/round/phone.svg'

type UserNotificationEvent =
  | 'signed_by_contractor' // contract signed
  | 'task_done_and_accepted_by_contractor'
  | 'task_started_by_contractor'

type UserNotificationSettings = { [key in UserNotificationEvent]: boolean }

interface UserProfile {
  id: undefined
  email: string
  notification_settings: UserNotificationSettings
}

const notificationEvents: { [key in UserNotificationEvent]: string } = {
  signed_by_contractor: 'Исполнитель подписал договор',
  task_started_by_contractor: 'Исполнитель приступил к выполнению задания',
  task_done_and_accepted_by_contractor: 'Исполнитель подписал акт',
}

interface CompanyMemberProfileViewProps {
  profile: Entity<UserProfile>
}

const CompanyMemberProfileView = observer(
  ({ profile }: CompanyMemberProfileViewProps) => {
    let currentUser = useUserStore()
    let toast = useStateToast()
    let { isMobile } = useMediaContext()

    let { name, mobile_phone } = currentUser.user!.data
    let { email, notification_settings } = profile.data

    let updateNotificationSetting = (key: UserNotificationEvent, value: boolean) => {
      profile.update({
        url: 'user/profile',
        options: { method: 'PUT' },
        optimistic: true,
        payload: { notification_settings: { ...notification_settings, [key]: value } },
      })
    }

    let [isEditingEmail, setIsEditingEmail] = useState(false)
    let [emailValue, setEmailValue] = useState('')
    let emailIsValid = validateEmail(emailValue)

    const [isChangingPassword, setChangingPassword] = useState(false)
    const [currentPassword, setCurrentPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [repeatPassword, setRepeatPassword] = useState('')
    const [repeatPasswordError, setRepeatPasswordError] = useState(false)
    const [isPasswordFormValid, setIsPasswordFormValid] = useState(false)
    const [passwordError, setPasswordError] = useState('')

    const updatePassword = () => {
      profile
        .update({
          url: 'user/profile/password',
          options: { method: 'PUT' },
          optimistic: true,
          payload: {
            current_password: currentPassword,
            password: newPassword
          },
        })
        .then(
          () => {
            toast.success({ title: 'Пароль изменён' })
            setCurrentPassword('')
            setNewPassword('')
            setRepeatPassword('')
            setChangingPassword(false)
          },
          (err) => setPasswordError(err.message)
        )
    }

    useEffect(() => {
      let isRepeatPasswordValid = false
      if (newPassword.length > 0 && repeatPassword.length > 0) {
        isRepeatPasswordValid = newPassword === repeatPassword
        setRepeatPasswordError(!isRepeatPasswordValid)
      }
      setIsPasswordFormValid(currentPassword.length > 0 && isRepeatPasswordValid)
      setPasswordError('')
    }, [currentPassword, newPassword, repeatPassword])

    let updateEmail = () => {
      profile
        .update({
          url: 'user/profile',
          options: { method: 'PUT' },
          optimistic: true,
          payload: { email: emailValue },
        })
        .then(
          () => toast.success({ title: 'Email изменён' }),
          (err) => toast.error({ title: 'Ошибка', description: err.message })
        )
      setIsEditingEmail(false)
    }

    let emailElem = (
      <Flex gap=".5rem">
        <EmailIcon
          fill="var(--color-secondary)"
          style={{ flexShrink: 0, paddingTop: 4 }}
        />
        {isEditingEmail ? (
          <Flex
            direction={isMobile ? 'column' : 'row'}
            gap="1rem"
            style={{ flexGrow: 1 }}
          >
            <Input
              value={emailValue}
              onChange={setEmailValue}
              autofocus
              placeholder="Введите адрес"
              isWide
              style={{ maxWidth: 250 }}
            />
            <Flex gap=".5rem">
              <Button size="xs" isDisabled={!emailIsValid} onTap={updateEmail}>
                Сохранить
              </Button>
              <Button size="xs" design="normal" onTap={() => setIsEditingEmail(false)}>
                Отмена
              </Button>
            </Flex>
          </Flex>
        ) : (
          <Flex gap="1rem" style={{ overflow: 'hidden' }}>
            <div
              style={{
                padding: '4px 0',
                borderBottom: '1px solid transparent',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {email}
            </div>
            <LinkButton
              onTap={() => {
                setEmailValue(email)
                setIsEditingEmail(true)
              }}
            >
              Изменить
            </LinkButton>
          </Flex>
        )}
      </Flex>
    )

    const passwordElem = (
      <Flex gap=".5rem">
        {isChangingPassword ? (
          <Flex direction="column" gap="20px">
            <Input
              value={currentPassword}
              onChange={setCurrentPassword}
              autofocus
              type="password"
              placeholder="Текущий пароль"
              isWide
              style={{ maxWidth: 300 }}
            />
            <Input
              value={newPassword}
              onChange={setNewPassword}
              placeholder="Новый пароль"
              type="password"
              isWide
              style={{ maxWidth: 300 }}
              isInvalid={repeatPasswordError}
            />
            <Input
              value={repeatPassword}
              onChange={setRepeatPassword}
              placeholder="Новый пароль еще раз"
              type="password"
              isWide
              style={{ maxWidth: 300 }}
              isInvalid={repeatPasswordError}
            />
            {repeatPasswordError && (
              <div style={{ color: 'var(--color-red)' }}>Пароли не совпадают</div>
            )}
            {passwordError.length > 0 && (
              <div style={{ color: 'var(--color-red)' }}>{passwordError}</div>
            )}
            <Flex gap=".5rem">
              <Button size="xs" isDisabled={!isPasswordFormValid} onTap={updatePassword}>
                Сохранить
              </Button>
              <Button size="xs" design="normal" onTap={() => setChangingPassword(false)}>
                Отмена
              </Button>
            </Flex>
          </Flex>
        ) : (
          <div>
            <Button
              size="xs"
              design="normal"
              onTap={() => setChangingPassword(true)}
            >
              Изменить пароль
            </Button>
          </div >
        )}
      </Flex>
    )

    return (
      <Layout smallPaddingTop>
        <Col gap="var(--gap-l)">
          <Flex gap="2rem" align="center">
            <Avatar name={name} />
            <H1>{name}</H1>
          </Flex>
          <Col gap="var(--gap-s)">
            <H3>Данные пользователя</H3>
            <Flex gap=".5rem" align="center">
              <PhoneIcon fill="var(--color-secondary)" />
              <div>{formatPhone(mobile_phone!)}</div>
            </Flex>
            {emailElem}
            {passwordElem}
          </Col>
        </Col>
      </Layout>
    )
  }
)

const CompanyMemberProfilePage = observer(() => {
  let api = useMobxApi()
  let fetchState = useMemo(() => api.fetch({ type: 'user/profile' }), [])
  return (
    <ActionStateView state={fetchState}>
      {(profile) => <CompanyMemberProfileView profile={profile} />}
    </ActionStateView>
  )
})

export default CompanyMemberProfilePage
