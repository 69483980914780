import React, { useCallback, useEffect, useState } from 'react'
import { useUserStore } from 'stores/context'
import { TaxBox } from 'entities'
import { fetchApi } from 'api'
import { Checkbox, H4, Notification, NotificationType, PageBox, PageHeader } from 'ui-kit'
import { TaxPaymentPermissionModal } from 'pages/taxes'
import { useStateToast } from 'components/ui'
import { isNull } from 'lodash'
import { getErrorMessage } from 'utils/error'

import css from './styles.module.css'

type FlexibleTaxBoxSettingsProps = {}

const FlexibleTaxBoxSettings: React.FunctionComponent<
  FlexibleTaxBoxSettingsProps
> = () => {
  const currentUser = useUserStore()
  const taxBoxId = currentUser.taxBoxId

  const toast = useStateToast()

  const [taxBox, setTaxBox] = useState<null | TaxBox>(null)
  const [openTaxPaymentPermissionModal, setOpenTaxPaymentPermissionModal] =
    useState(false)

  const getTaxBoxData = useCallback(async () => {
    try {
      const result = (await fetchApi({
        url: `tax_boxes/${taxBoxId}`,
        method: 'GET',
      })) as TaxBox

      setTaxBox(result)
    } catch (err) {
      console.error(err)
    }
  }, [taxBoxId])

  const handleForceTaxSavingChange = async () => {
    try {
      const result = await fetchApi({
        url: `tax_boxes/${taxBoxId}`,
        method: 'PUT',
        data: {
          force_tax_saving: !taxBox?.force_tax_saving,
        },
      })

      setTaxBox((prevState) => {
        if (!isNull(prevState)) {
          return {
            ...prevState,
            force_tax_saving: result.force_tax_saving,
          }
        }
        return null
      })
    } catch (err) {
      console.error(err)
    }
  }

  const handleTaxOfficeRequest = async () => {
    try {
      const result = await fetchApi({
        url: `/tax_boxes/${taxBoxId}/send_request`,
        method: 'PUT',
      })
      if (result.status === 'request_sent') {
        setOpenTaxPaymentPermissionModal(true)
      } else if (result.status === 'success') {
        toast.success({ title: 'Разрешение подтверждено' })
        setTaxPaymentPermission(true)
        setOpenTaxPaymentPermissionModal(false)
      }
    } catch (err: unknown) {
      toast.error({ title: 'Ошибка', description: getErrorMessage(err) })
    }
  }

  const toggleTaxPaymentPermissionModalVisibility = () => {
    setOpenTaxPaymentPermissionModal((prevState) => !prevState)
  }

  const setTaxPaymentPermission = (status: boolean) => {
    setTaxBox((prevState) => {
      if (!isNull(prevState)) {
        return {
          ...prevState,
          tax_payment_permission: status,
        }
      }
      return null
    })
  }

  useEffect(() => {
    getTaxBoxData()
  }, [])

  return (
    <PageBox>
      <PageHeader title="Настройка копилки" backLinkUrl="/taxes" />
      <Notification
        type={NotificationType.Question}
        linkText="Как работает копилка?"
        linkHref="https://help.konsol.pro/tax-money-box-2"
      ></Notification>
      {taxBox && (
        <>
          {!taxBox.tax_payment_permission && (
            <Notification
              type={NotificationType.Fail}
              title="Подтвердите разрешение на оплату налогов от вашего имени"
              description="Без этого мы не сможем получать ваши налоговые начисления и оплачивать их за вас"
              buttonLabel="Дать разрешение"
              onClick={handleTaxOfficeRequest}
              className={css.failNotification}
            ></Notification>
          )}
          <H4 className={css.additionalHeading}>Дополнительно</H4>
          <Checkbox
            name="force_tax_saving"
            checked={taxBox.force_tax_saving}
            onChange={handleForceTaxSavingChange}
            label="Откладывать средства на оплату налогов полученных вне Консоли"
          />
          {!taxBox.tax_payment_permission && taxBoxId && (
            <TaxPaymentPermissionModal
              id={taxBoxId}
              open={openTaxPaymentPermissionModal}
              onClose={toggleTaxPaymentPermissionModalVisibility}
              setTaxPaymentPermission={setTaxPaymentPermission}
            />
          )}
        </>
      )}
    </PageBox>
  )
}

export default FlexibleTaxBoxSettings
