import React from 'react'
import cx from 'clsx'
import { observer } from 'mobx-react-lite'
import c from './TaskStatusBadge.module.css'

type ScenarioTaskStatus = any;


// статусы на бэке:
// state: draft, initial: true # черновик
// state:suggested # предложено исполнителю
// state:declined # исполнитель отказался
// state:cancelled # компания отменила
// state:in_progress # исполнитель принял
// state:not_completed # не выполнено исполнителем
// state:completed # выполнено исполнителем
//
// draft, suggested не показываем лейбл статуса в таске
const mapStatusToTitle: { [K in ScenarioTaskStatus]: string } = {
  in_progress: 'Выполняется',
  completed: 'Выполнено',
  created: 'Предложено',
  declined: 'Вы отказались',
  not_completed: 'Не выполнено',
  cancelled: 'Отменено',
}

export const TaskStatusBadge = observer(
  ({
    status,
    className,
    style,
  }: {
    status: ScenarioTaskStatus
    className?: string
    style?: React.CSSProperties
  }) => {
    return (
      <span
        className={cx(
          c.badge,
          {
            [c.green]: status === 'completed',
            [c.red]: ['not_completed', 'declined', 'cancelled'].includes(status),
            [c.grey]: status === 'created',
            [c.purple]: status === 'in_progress',
          },
          className
        )}
        style={style}
      >
        {mapStatusToTitle[status]}
      </span>
    )
  }
)
