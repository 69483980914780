import React from 'react'

import { Flex, H2, H4, Link } from 'ui'

import { ReactComponent as LockIcon } from 'assets/lock.svg'

import classes from './styles.module.css'

import clsx from 'clsx'
import { Badge } from 'components/Badge'
import { formatAmount } from 'utils/amount'

export const AnalyticsAccessBanner = () => {

  return (
    <Flex direction="col">
      <Flex direction="row" align="center">
        <div className={classes.lockIcon}>
          <LockIcon />
        </div>
        <H2>Получите доступ к аналитике</H2>
      </Flex>
      <Flex className={clsx(classes.content, classes.text)} direction="col" gap="10px">
        <H4>Что такое aналитика рисков?</H4>
        <Flex direction="col" gap="5px">
          <span
            style={{
              color: 'var(--konsol-color-grey)',
            }}
          >
            Дополнительный функционал для анализа сотрудничества компании с самозанятыми
            по различным критериям:
          </span>
          <ul
            style={{
              color: 'var(--konsol-color-grey)',
              marginLeft: '1em',
            }}
          >
            <li>продолжительность сотрудничества</li>
            <li>единственный источник дохода</li>
            <li>периодичность выплат</li>
            <li>одинаковые платежи</li>
            <li>массовая регистрация самозанятых</li>
          </ul>
        </Flex>
        <Link href="https://support.konsol.pro/risk-analytics">
          Подробнее об аналитике рисков
        </Link>
      </Flex>
      <Flex className={classes.content} direction="col" gap="20px">
        <Flex direction="col" gap="5px">
          <H4>Стоимость подключения:</H4>
          <div>
            <Badge color="blue">
              {`${formatAmount(150)}/мес. за активного исполнителя`}
            </Badge>
          </div>
          <span
            className={classes.text}
            style={{
              color: 'var(--konsol-color-grey)',
            }}
          >
            Индивидуальный тариф, если более 500 исполнителей
          </span>
        </Flex>
        <Flex direction="col" gap="5px">
          <H4>Как подключить</H4>
          <span
            className={classes.text}
            style={{
              color: 'var(--konsol-color-grey)',
            }}
          >
            <div style={{ marginTop: '1rem' }}>
              Please contact us at <Link href='mailto:hello@restaff.pro' target='_blank'>hello@restaff.pro</Link> if you have any issues
            </div>
          </span>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default AnalyticsAccessBanner
