import { IComputedValue, makeAutoObservable, observable } from 'mobx'

class FilteredStore<T, Q = string, P = Q> {
  constructor({
    items,
    filterFunc,
    parseQuery,
    query,
  }: {
    items: IComputedValue<Array<T>>
    filterFunc: (item: T, query: P) => boolean
    parseQuery?: (query: Q) => P | undefined
    query?: Q
  }) {
    this.allItems = items
    this.filterFunc = filterFunc
    this.parseQuery = parseQuery
    if (query) this.query = query
    makeAutoObservable(this, { parseQuery: observable })
  }

  allItems: IComputedValue<Array<T>>
  filterFunc: (item: T, filter: P) => boolean
  parseQuery?: (query: Q) => P | undefined
  query?: Q = undefined

  get parsedQuery() {
    if (!this.query) return undefined
    return this.parseQuery ? this.parseQuery(this.query) : ((this.query as unknown) as P)
  }

  get items() {
    let items = this.allItems.get()
    return this.parsedQuery === undefined
      ? items
      : items.filter((item) => this.filterFunc(item, this.parsedQuery!))
  }
}

export default FilteredStore
