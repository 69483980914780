import React from 'react'

import { Flex, Button } from 'ui'

import { ReactComponent as ArrowLeftIcon } from 'assets/icon-arrow-left.svg'

interface IPageHeader {
  onBack?: () => void
  children?: React.ReactNode
  title: string | React.ReactNode
  controls?: React.ReactNode
  className?: string
  style?: React.CSSProperties
}

export const PageHeader = ({
  onBack,
  title,
  controls,
  children,
  className,
  style,
}: IPageHeader) => {
  return (
    <Flex className={className} style={style} direction="col">
      <Flex
        justify="space-between"
        align="center"
        gap="15px"
        style={{ marginBottom: '30px' }}
      >
        <Flex gap="15px">
          {onBack && (
            <Button icon={<ArrowLeftIcon />} onClick={onBack} variant="text" size="s" />
          )}
          {title}
        </Flex>
        <Flex gap="15px">{controls}</Flex>
      </Flex>
      {children}
    </Flex>
  )
}

export default PageHeader
