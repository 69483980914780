import React from 'react'
// @ts-ignore
import mixpanel from 'mixpanel-browser'

import { Provider } from './context'
import { isDevelopment } from 'thirdParty'

const defaults = {
  track_pageview: false, // Rarely makes sense to track page views in React apps
}

const MixpanelProvider = ({ children, config, name }: any) => {
  config = Object.assign({}, defaults, config)

  mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, config, name)

  if (isDevelopment()) {
    mixpanel.track = () => {} // убираем трекинг для разработки и стейджей
  }

  return <Provider value={mixpanel}>{children}</Provider>
}

export default MixpanelProvider
