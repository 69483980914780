import { IComputedValue, makeAutoObservable, reaction } from 'mobx'
import { difference } from 'lodash'

class SelectableStore<T> {
  constructor({ items, isDefaultSelectedAll }: { items: IComputedValue<Array<T>>, isDefaultSelectedAll?: boolean }) {
    this.items = items
    this.isDefaultSelectedAll = isDefaultSelectedAll
    makeAutoObservable(this)

    this.selectedItems = new Set(this.isDefaultSelectedAll ? this.items.get() : undefined)

    this.dispose = reaction(
      () => items.get(),
      (value, prevValue) => {
        difference(prevValue, value).forEach((item) => this.selectedItems.delete(item))
      }
    )
  }

  dispose: () => void
  items: IComputedValue<Array<T>>
  selectedItems: Set<T>
  isDefaultSelectedAll?: boolean

  select(item: T) {
    this.selectedItems.add(item)
  }

  unselect(item: T) {
    this.selectedItems.delete(item)
  }

  get selectedCount() {
    return this.selectedItems.size
  }

  get isEmpty() {
    return this.selectedCount === 0
  }

  get isAllSelected() {
    return this.selectedCount === this.items.get().length
  }

  isSelected(item: T) {
    return this.selectedItems.has(item)
  }

  toggleSelect(item: T) {
    if (this.isSelected(item)) this.unselect(item)
    else this.select(item)
  }

  toggleSelectAll() {
    if (this.selectedItems.size > 0) {
      this.selectedItems.clear()
    } else {
      this.items.get().forEach((item) => this.selectedItems.add(item))
    }
  }
}

export default SelectableStore
