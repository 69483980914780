import React from 'react';
import Error from './components/Error';

import Loader from './components/Loader';
import Mark from './components/Mark';

interface ILoaderMarkProps {
    className?: string;
    appear?: boolean;
    margin?: boolean;
    state: 'loader' | 'mark' | 'error';
    onAnimationEnd?: () => void;
    style?: React.CSSProperties;
}

export const LoaderMark = ({
    className,
    style,
    appear,
    margin,
    state,
    onAnimationEnd,
}: ILoaderMarkProps) => {
    if (state === 'loader') {
        return (
            <Loader
                className={className}
                style={style}
                appear={appear}
                margin={margin}
                onAnimationEnd={onAnimationEnd}
            />
        );
    } else if (state === 'mark') {
        return (
            <Mark
                className={className}
                style={style}
                appear={appear}
                onAnimationEnd={onAnimationEnd}
            />
        );
    } else {
        return (
            <Error
                className={className}
                style={style}
            />
        );
    }
};

export default LoaderMark;
