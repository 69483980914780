import React from 'react'

import s from './styles.module.css'

interface IDateProps {
  date: string
}

const DateCell = ({ date }: IDateProps) => {
  const parsedDate = new Date(date).toLocaleString('ru-RU', {
    hour12: false,
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  })

  const splittedDate = parsedDate.split(', ')

  return (
    <>
      {splittedDate[0]}&nbsp;
      <span className={s.time}>{splittedDate[1]}</span>
    </>
  )
}

export default DateCell
