export const json = (json: Object) => (req: any, res: any, ctx: any) =>
  res(ctx.delay(500), ctx.json(json))

export const error = (status: number, error: Object) => (req: any, res: any, ctx: any) =>
  res(ctx.delay(500), ctx.status(status), ctx.json(error))

export const paginate = (items: any[], page: number, itemsPerPage = 5) => {
  let start = itemsPerPage * (page - 1)
  let totalPages = Math.ceil(items.length / itemsPerPage)
  return {
    items: items.slice(start, start + itemsPerPage),
    page,
    per_page: itemsPerPage,
    total_pages: totalPages,
    total_count: items.length,
  }
}
