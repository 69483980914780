import React from 'react'
import { Button } from 'components/ui'
import { fetchApi } from 'api'

const TinkoffAuth = () => {
  let connect = () =>
    fetchApi({
      url: '/banks/tinkoff/auth',
      method: 'POST',
    }).then((response) => (window.location = response.authorize_url))

  return (
    <div
      style={{
        display: 'flex',
        flex: 1,
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Button design="outline" onTap={connect}>
        Подключить счёт в Тиньков банке
      </Button>
    </div>
  )
}

export default TinkoffAuth
