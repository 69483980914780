import React, { useEffect } from 'react'

import { Flex, Select } from 'ui'

import IndicatorList from '../../components/IndicatorList'

import useAnalytics from 'pages/analytics/context/AnalyticsProvider'
import { ActionStateView, Placeholder, useStateToast } from 'components/ui'

import { Observer, observer, useLocalObservable } from 'mobx-react-lite'
import { makeAutoObservable } from 'mobx'
import { ActionState } from 'mobx/types'
import { fromPromise } from 'mobx-utils'
import { fetchApi } from 'api'

export const OnboardingScenarioView = observer(() => {
  const toast = useStateToast()

  const analyticsManager = useAnalytics()

  const scenarioManager = useLocalObservable(
    () =>
      new (class {
        fetchDataTask?: ActionState<any>

        onboardingScenarioId?: number

        setOnboardingScenarioId = (onboardingScenarioId: number) =>
          (this.onboardingScenarioId = onboardingScenarioId)

        get linkFilters() {
          return this.onboardingScenarioId
            ? [
                {
                  name: 'onboarding_scenario',
                  value: this.onboardingScenarioId.toString(),
                },
              ]
            : []
        }

        constructor() {
          makeAutoObservable(this)
        }

        private createFetchDataTask = () =>
          fromPromise(
            (async () => {
              try {
                const data = await fetchApi({
                  url: 'onboarding_scenarios',
                  method: 'GET',
                })
                return data
              } catch (error: any) {
                toast.error({
                  title: `Не удалось загрузить список сценариев`,
                  description: error.message,
                })
              }
              return null
            })()
          ) as ActionState<any>

        fetchData = () => {
          this.fetchDataTask = this.createFetchDataTask()
        }

        clearData = () => {
          this.fetchDataTask = undefined
        }
      })()
  )

  useEffect(() => {
    scenarioManager.fetchData()
    return () => scenarioManager.clearData()
  }, [])

  useEffect(() => {
    if (scenarioManager.onboardingScenarioId) {
      analyticsManager.fetchData(
        'company_onboarding_scenario',
        scenarioManager.onboardingScenarioId.toString()
      )
    }
    return () => analyticsManager.clearData()
  }, [scenarioManager.onboardingScenarioId])

  return scenarioManager.fetchDataTask ? (
    <ActionStateView state={scenarioManager.fetchDataTask}>
      {(onboardingScenarios) => {
        if (!onboardingScenarios) {
          return <Placeholder>Произошла ошибка</Placeholder>
        }

        const scenarioItems = onboardingScenarios.map((item: any) => ({
          label: item.archived ? `${item.title} (В архиве)` : item.title,
          value: item.id,
        }))

        return (
          <Flex direction="col" gap="25px">
            <Select
              style={{
                maxWidth: 400,
              }}
              placeholder="Выберите сценарий для рассчета"
              options={scenarioItems}
              value={scenarioItems.find(
                (item: any) => item.value === scenarioManager.onboardingScenarioId
              )}
              onChange={(item: any) =>
                scenarioManager.setOnboardingScenarioId(item.value)
              }
            />
            {analyticsManager.fetchDataTask && (
              <ActionStateView state={analyticsManager.fetchDataTask}>
                {(data) => (
                  <Observer>
                    {() =>
                      data ? (
                        <IndicatorList
                          data={data}
                          linkFilters={scenarioManager.linkFilters}
                        />
                      ) : (
                        <Placeholder>Произошла ошибка</Placeholder>
                      )
                    }
                  </Observer>
                )}
              </ActionStateView>
            )}
          </Flex>
        )
      }}
    </ActionStateView>
  ) : null
})

export default OnboardingScenarioView
