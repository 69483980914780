import React, { useMemo, useState } from 'react'
import { computed, makeAutoObservable, IComputedValue, action } from 'mobx'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { useHistory } from 'react-router'
import { History } from 'history'
import { uniq, sortBy, compact } from 'lodash'
import { fromPromise } from 'mobx-utils'
import { Heading } from '@chakra-ui/react'
import { Channel } from 'pusher-js'

import type { PageProps } from 'app.types'
import { fetchApi } from 'api'
import {
  useUserStore,
  UserStore,
  useSignDialogStore,
  SignDialogStore,
} from 'stores/context'
import {
  useMobxApi,
  MobxApi,
  Entity,
  Collection,
  Data,
  initialActionState,
} from 'mobx/mobx'
import { usePusherSubscription, usePusherChannelContext } from 'App/pusher'
import {
  ActBundleEntity,
  ProjectEntity,
  SubtaskTemplateEntity,
  TaskEntity,
  TaskPayAction,
  TaskAction,
  TaskStatus,
  ActStatus,
  PaymentStatus,
  ContractorEntity,
  TaskProblem,
} from 'entities'

import { AnimatedList, CollapseAnimation } from 'components/ui/animation'
import FilterDropdown from 'components/FilterDropdown'
import { Amount } from 'components'
import {
  Modal,
  ActionStateView,
  InplaceInput,
  Layout,
  Input,
  BackLink,
  List,
  Flex,
  Placeholder,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  ConfirmDialog,
  useConfirmDialog,
  useStateToast,
} from 'components/ui'
import { AbilityButton, AbilityMenuItem } from 'components/abilityButtons'
import {
  FilteredStore,
  SortableStore,
  SelectableStore,
  SortableListHeader,
  SelectAllCheckbox,
} from 'components/FilteredList'
import { makeSectionsCounters } from 'components/FilteredList/SectionedStore'
import TasksMultiActionsStore from 'components/tasks/TasksMultiActionsStore'
import { TaskListItem, taskListCols } from 'components/tasks/TaskList'
import {
  taskStatusOrder,
  actStatusOrder,
  paymentStatusOrder,
} from 'components/tasks/statuses'
import Dots from 'components/Dots'
import ModalTaskList from 'components/tasks/ModalTaskList'
import { getTaskProblems, getTaskActionState } from 'components/tasks/taskProblems'

import { ReactComponent as MoreIcon } from '@material-design-icons/svg/round/more_horiz.svg'
import { ReactComponent as DropdownIcon } from '@material-design-icons/svg/round/arrow_drop_down.svg'

import { sumAmounts } from 'utils/amount'
import { printIsoDateTime } from 'utils/datetime'
import numForm from 'utils/numForm'
import { endash } from 'utils/typo'

import ExportActionsStore from './ExportActions'

const ConfirmDeleteDialog = observer(({ store }: { store: BundleStore }) => {
  let tasks = store.itemsForActions.delete.get()
  let close = () => {
    store.confirmDeleteDialogIsOpen = false
  }
  let caption = (
    <>
      Вы действительно хотите удалить{' '}
      {numForm(
        tasks.length,
        store.bundle.type === 'projects' ? taskWordForms : actWordForms
      )}
      ?
    </>
  )
  return (
    <Modal isOpen={true} onClose={close} size={1000}>
      <Flex direction="column" gap="var(--gap-m)">
        <Heading>Удаление</Heading>
        <ModalTaskList caption={caption} tasks={tasks} />
        <Flex gap="1rem">
          <Button
            onTap={() => store.multiDelete()}
            isLoading={store.deleteState.state === 'pending'}
          >
            Удалить
          </Button>
          <Button
            design="normal"
            onTap={close}
            isDisabled={store.deleteState.state === 'pending'}
          >
            Отмена
          </Button>
        </Flex>
      </Flex>
    </Modal>
  )
})

const payActionNames = {
  pay: 'Оплатить',
  mark_paid: 'Отметить оплаченными',
  mark_paid_with_receipt: 'Отметить оплаченными и сформировать чеки',
}

interface BundleFilter {
  taskStatus?: TaskStatus
  actStatus?: ActStatus
  paymentStatus?: PaymentStatus
  contractor?: number
}

interface BundleStoreProps {
  bundle: Entity<ActBundleEntity> | Entity<ProjectEntity>
  api: MobxApi
  toast: ReturnType<typeof useStateToast>
  currentUser: UserStore
  signDialog: SignDialogStore
  history: History
  channel?: Channel
}

class BundleStore {
  constructor({
    bundle,
    api,
    toast,
    currentUser,
    signDialog,
    history,
    channel,
  }: BundleStoreProps) {
    this.history = history
    this.toast = toast
    this.bundle = bundle
    this.api = api

    this.payAction = currentUser.companyPayAction
    this.multiActions = new TasksMultiActionsStore({
      currentUser,
      api,
      toast,
      onCompleteAction: () => this.onCompleteAction(),
      signDialog,
      channel,
    })
    this.exportActions = new ExportActionsStore(currentUser, toast)

    let counters: any = {
      total: (item: any) => item.data.act.status !== 'annulled',
      accepted: (item: any) => item.data.act.status === 'accepted',
      paid: (item: any) => item.data.payment.status === 'paid',
      errors: (item: any) => item.data.payment.status === 'error',
    }
    if (bundle.type === 'projects') {
      counters.done = (item: any) => item.data.task?.status === 'done'
    }
    this.counters = makeSectionsCounters(
      computed(() => this.collection.items),
      counters
    )

    this.filtered = new FilteredStore<Entity<TaskEntity>, BundleFilter>({
      items: computed(() => this.collection.items),
      filterFunc: (item, filter) => {
        if (filter.taskStatus && item.data.task!.status !== filter.taskStatus)
          return false
        if (filter.actStatus && item.data.act.status !== filter.actStatus) return false
        if (filter.paymentStatus && item.data.payment.status !== filter.paymentStatus) {
          return false
        }
        if (filter.contractor && item.data.contractor?.id !== filter.contractor)
          return false
        return true
      },
      query: {
        taskStatus: undefined,
        actStatus: undefined,
        paymentStatus: undefined,
        contractor: undefined,
      },
    })

    this.sorted = new SortableStore({
      items: computed(() => this.filtered.items),
      sortFunc: (item, sorting) => {
        if (sorting === 'number') return item.data.document_number?.number
        if (sorting === 'title') return item.data.title
        if (sorting === 'contractor') {
          return item.data.contractor ? item.data.contractor.data.name : ''
        }
        if (sorting === 'amount') return item.data.display_amount
        if (sorting === 'taskStatus') return taskStatusOrder[item.data.task!.status]
        if (sorting === 'actStatus') return actStatusOrder[item.data.act.status]
        if (sorting === 'paymentStatus') {
          return paymentStatusOrder[item.data.payment.status]
        }
        return undefined
      },
    })

    this.selected = new SelectableStore({
      items: computed(() => this.filtered.items),
    })

    let actions: TaskAction[] = [
      'accept',
      'autopay',
      this.payAction,
      'delete',
      'annul',
      'cancel_autopay',
      'mark_paid_without_sms',
    ]
    if (bundle.type === 'projects') actions.unshift('sign_terms')
    // @ts-ignore
    this.itemsForActions = {}
    actions.forEach((action) => {
      this.itemsForActions[action] = computed(() => this.getActsWithAction(action))
    })

    makeAutoObservable(this)
  }

  history: History
  toast: ReturnType<typeof useStateToast>
  bundle: Entity<ActBundleEntity> | Entity<ProjectEntity>
  counters: ReturnType<typeof makeSectionsCounters>
  filtered: FilteredStore<Entity<TaskEntity>, BundleFilter>
  sorted: SortableStore<Entity<TaskEntity>>
  selected: SelectableStore<Entity<TaskEntity>>
  payAction: TaskPayAction
  multiActions: TasksMultiActionsStore
  exportActions: ExportActionsStore
  api: MobxApi

  get collection(): Collection<TaskEntity> {
    // @ts-ignore
    return this.bundle.type === 'bundles' ? this.bundle.data.acts : this.bundle.data.tasks
  }

  get contractorOptions() {
    let contractors = compact(this.collection.items.map((act) => act.data.contractor))
    return sortBy(uniq(contractors), (c) => c.data.name).map((c) => ({
      value: c.id,
      label: c.data.name,
    }))
  }

  get filterKey() {
    return Object.values(this.filtered.query!).join('-')
  }

  setFilter(filter: Partial<BundleFilter>) {
    Object.assign(this.filtered.query!, filter)
  }

  get selectedItemsProblems() {
    let items: { [key: number]: TaskProblem[] } = {}
    this.selected.selectedItems.forEach((item) => {
      items[item.id] = getTaskProblems(item)
    })
    return items
  }

  // Мульти-экшены
  getActsWithAction(action: TaskAction) {
    let res: Entity<TaskEntity>[] = []

    const isMarkPaidWithoutSms = action === 'mark_paid_without_sms'

    this.selected.selectedItems.forEach((act) => {
      if (
        (act.data.actions.includes(action) &&
          !getTaskActionState(action, this.selectedItemsProblems[act.id]).isDisabled) ||
        isMarkPaidWithoutSms
      ) {
        if (
          (isMarkPaidWithoutSms &&
            act.data.status === 'accepted' &&
            act.data.payment.status !== 'paid') ||
          !isMarkPaidWithoutSms
        ) {
          res.push(act)
        }
      }
    })
    return res
  }

  getBundle() {
    this.api.fetch({ type: 'bundles', id: this.bundle.id }).then((bundle: any) => {
      this.bundle.setData(bundle)
    })
  }

  itemsForActions: { [key in TaskAction]: IComputedValue<Entity<TaskEntity>[]> }

  multiAction(action: TaskAction) {
    let tasks = this.itemsForActions[action].get()
    if (action === 'delete') {
      this.openConfirmDeleteDialog()
    } else if (action === 'mark_paid_without_sms') {
      this.multiActions.multiAction(action, tasks)
      this.getBundle()
    } else if (action === 'cancel_autopay') {
      this.multiActions.multiAction(action, tasks)
    } else {
      this.multiActions.openDialog(action, tasks)
    }
  }

  onCompleteAction() {
    Object.assign(this.filtered.query!, {
      taskStatus: undefined,
      actStatus: undefined,
      paymentStatus: undefined,
    })
  }

  // Мульти удаление
  confirmDeleteDialogIsOpen = false
  deleteState = initialActionState

  openConfirmDeleteDialog() {
    this.deleteState = initialActionState
    this.confirmDeleteDialogIsOpen = true
  }

  multiDelete() {
    let itemsToDelete = this.itemsForActions.delete.get()
    let ids = itemsToDelete.map((t) => t.id)
    this.deleteState = fromPromise(
      fetchApi({
        method: 'DELETE',
        url: `tasks/multi_delete`,
        data: { ids },
      })
    )
    this.deleteState.then(
      action(({ not_deleted = [] }: Data) => {
        let notDeletedIds = not_deleted.map((item: Data) => item.id)
        let deletedItems = itemsToDelete.filter(
          (item) => !notDeletedIds.includes(item.id)
        )
        this.collection.remove(...deletedItems)
        not_deleted.map((item: Data) => this.collection.map[item.id]?.setData(item))
        if (not_deleted.length === 0) {
          this.toast.success({ title: 'Акты удалены' })
        } else {
          this.toast.warning({
            title: `Получилось удалить только ${numForm(
              deletedItems.length,
              actWordForms
            )} из ${itemsToDelete.length}`,
          })
        }
        this.confirmDeleteDialogIsOpen = false
        this.filtered.query!.contractor = undefined
      }),
      (error: any) =>
        this.toast.error({ title: 'Не удалось удалить', description: error.message })
    )
  }

  createState = initialActionState

  createDraft() {
    this.createState = this.collection.create({
      payload: { project_id: this.bundle.id },
      mode: 'prepend',
      url: 'tasks/drafts',
    })
    return this.createState
  }

  deleteTask(task: Entity<TaskEntity>) {
    this.collection.delete({ id: task.id, optimistic: true })
  }

  cloneTask(task: Entity<TaskEntity>) {
    this.collection.create({ url: `tasks/${task.id}/clone`, mode: 'prepend' })
  }

  validateDraft(draft: Entity<TaskEntity>) {
    let { title, contractor, since, upto } = draft.data
    let subtasks = draft.data.task!.subtasks
    if (!subtasks || subtasks.length === 0) return 'Укажите вид работ'
    if (title === null || title === '') return 'Название не указано'
    if (!contractor) return 'Укажите исполнителя'
    if (!since || !upto) return 'Период не заполнен'
    return false
  }

  assignTask(task: Entity<TaskEntity>) {
    let error = this.validateDraft(task)
    if (error) {
      this.toast.error({ title: 'Не удалось создать задание', description: error })
      return
    }
    let state = task.action({ action: 'assign_contractor' })
    state.then(
      (data: Data) => task.setData(data),
      (error) =>
        this.toast.error({
          title: 'Не удалось создать задание',
          description: error.message,
        })
    )
    return state
  }

  deleteBundle() {
    this.bundle.delete().then(
      () => {
        this.history.push(`/${this.bundle.type}/`)
        this.toast.success({
          title: 'Пачка актов удалена',
        })
      },
      (error) =>
        this.toast.error({
          title: 'Не удалось удалить',
          description: error.message,
        })
    )
  }
}

const styles: { [key: string]: React.CSSProperties } = {
  description: {
    fontSize: 18,
    maxWidth: 500,
    whiteSpace: 'pre-wrap',
    padding: '4px 0',
  },
  addDescription: {
    fontSize: 18,
    color: 'var(--color-secondary)',
    cursor: 'pointer',
    padding: '4px 0',
    borderBottom: '1px solid transparent',
    display: 'inline-block',
  },
  title: { maxWidth: 800, fontSize: '2.25rem', fontWeight: 'bold', flexGrow: 1 },
  titleInput: { fontSize: '2.25rem', fontWeight: 'bold', maxWidth: 800 },
  selectedItems: { height: '4rem', marginLeft: '1rem' },
  selectedText: {
    fontSize: '1.5rem',
    fontWeight: 500,
    fontVariantNumeric: 'tabular-nums',
  },
  selectedAmount: {
    color: 'var(--color-secondary)',
    fontSize: 12,
    marginTop: 4,
  },
  pseudolink: {
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    borderBottom: '1px dashed rgba(0,0,0,.2)',
  },
}

const actWordForms = { one: 'акт', two: 'акта', many: 'актов' }
const taskWordForms = { one: 'задание', two: 'задания', many: 'заданий' }
const errorWordForms = { one: 'ошибка', two: 'ошибки', many: 'ошибок' }
const taskWordFormsWith = { one: 'заданием', two: 'заданиями', many: 'заданиями' }
const actWordFormsWith = { one: 'актом', two: 'актами', many: 'актами' }

interface BundleActionButtonProps {
  ability: boolean
  kind?: 'button' | 'item'
  taskKind?: 'task' | 'act'
  store: BundleStore
  title: React.ReactNode
  action: TaskAction
}

const BundleActionButton = observer(
  ({
    ability,
    kind = 'button',
    store,
    title,
    action,
    taskKind = 'act',
  }: BundleActionButtonProps) => {
    let selectedCount = store.selected.selectedItems.size
    let actionCount = store.itemsForActions[action].get().length
    let tooltip
    if (actionCount === 0) {
      tooltip = `Не выбрано ${
        taskKind === 'task' ? 'заданий' : 'актов'
      } доступных для этого действия`
    } else if (actionCount < selectedCount) {
      tooltip =
        'Действие можно выполнить только с ' +
        numForm(actionCount, taskKind === 'task' ? taskWordFormsWith : actWordFormsWith) +
        ` из ${selectedCount}`
    }
    let isDisabled = actionCount === 0
    if (kind === 'button') {
      return (
        <AbilityButton
          ability={ability}
          tooltip={tooltip}
          onTap={() => store.multiAction(action)}
          isDisabled={isDisabled}
          as="div"
          size="s"
          design="normal"
        >
          {title}
        </AbilityButton>
      )
    }
    return (
      <AbilityMenuItem
        ability={ability}
        tooltip={tooltip}
        as="div"
        isDisabled={isDisabled}
        onClick={() => store.multiAction(action)}
      >
        {title}
      </AbilityMenuItem>
    )
  }
)

const BundleHeader = observer(({ store }: { store: BundleStore }) => {
  let currentUser = useUserStore()
  let [isAddingDescription, setIsAddingDescription] = useState(false)

  let bundle = store.bundle
  let total = store.counters.total.get()
  let acceptedCount = store.counters.accepted.get()
  let paidCount = store.counters.paid.get()
  let doneCount = store.counters.done?.get()
  let errorsCount = store.counters.errors.get()

  let amount =
    bundle.type === 'bundles' && bundle.data.amount_with_commission ? (
      <span>
        <Amount value={bundle.data.amount} /> (С учётом комиссии {endash}{' '}
        <Amount value={bundle.data.amount_with_commission} />)
      </span>
    ) : (
      <Amount
        value={sumAmounts(store.collection.items.map((i) => i.data.display_amount))}
      />
    )

  let status = (
    <Dots>
      {compact([
        <div>
          {bundle.type === 'bundles' ? 'Пачка актов' : 'Проект'} № {bundle.data.id} от{' '}
          {printIsoDateTime(bundle.data.created)}
          {total > 0 && <> на сумму {amount}</>}
        </div>,
        bundle.type === 'projects' && (
          <div style={{ color: 'var(--color-green)' }}>
            Выполнено {doneCount} из {total}
          </div>
        ),
        <div style={{ color: 'var(--color-green)' }}>
          Подписано {acceptedCount} из {total}
        </div>,
        <div style={{ color: 'var(--color-active)' }}>
          Оплачено {paidCount} из {total}
        </div>,
        errorsCount > 0 && (
          <div style={{ color: 'var(--color-red)' }}>
            {numForm(errorsCount, errorWordForms)}
          </div>
        ),
      ])}
    </Dots>
  )

  let canUpdate = currentUser.hasAbility(`${bundle.type}.update`)

  let onChangeTitle = (value: string) => {
    if (value.trim()) {
      bundle.update({ payload: { title: value.trim() }, optimistic: true })
    }
  }
  let title = (
    <InplaceInput
      toggleOnClick={canUpdate}
      value={bundle.data.title}
      onChange={onChangeTitle}
      style={styles.title}
      components={{
        Input: React.forwardRef((props, ref) => (
          <Input {...props} styles={{ input: styles.titleInput }} ref={ref} />
        )),
      }}
    />
  )

  let deleteConfirmDialog = useConfirmDialog({
    render: ({ confirm, cancel }) => (
      <ConfirmDialog
        onCancel={cancel}
        header="Удаление пачки"
        buttons={
          <>
            <Button onTap={cancel} design="normal">
              Отмена
            </Button>
            <Button onTap={confirm}>Удалить</Button>
          </>
        }
      >
        Вы уверены что хотите удалить пачку актов "{store.bundle.data.title}"?
      </ConfirmDialog>
    ),
    onConfirm: () => store.deleteBundle(),
  })
  let canBeDeleted = store.bundle.data.actions.includes('delete')
  let deleteMenuItem = (
    <AbilityMenuItem
      ability={currentUser.hasAbility('bundles.destroy')}
      isDisabled={!canBeDeleted}
      tooltip={!canBeDeleted && 'Пачка не может быть удалена'}
      onClick={deleteConfirmDialog.open}
      style={{ color: 'var(--color-red)' }}
    >
      Удалить пачку
    </AbilityMenuItem>
  )

  let actions = (
    <Flex gap="1rem" align="start">
      {bundle.type === 'projects' && (
        <AbilityButton
          ability={
            currentUser.hasAbility('tasks.create') &&
            currentUser.hasAbility('projects.update')
          }
          isLoading={store.createState.state === 'pending'}
          onTap={() => store.createDraft()}
        >
          Создать задание
        </AbilityButton>
      )}
      <div style={{ position: 'relative' }}>
        <Menu placement="bottom-end">
          <MenuButton
            as={Button}
            style={{ minWidth: '3rem', padding: 0 }}
            design="normal"
          >
            <MoreIcon style={{ fill: 'var(--color-active)' }} />
          </MenuButton>
          <MenuList style={{ minWidth: 275 }}>
            {bundle.data.actions.includes('download_original_file') && (
              <MenuItem onClick={() => store.exportActions.downloadOriginalFile(bundle)}>
                Скачать исходный реестр
              </MenuItem>
            )}
            <MenuItem
              isDisabled={store.collection.isEmpty}
              onClick={() => store.exportActions.exportTo1c(bundle)}
            >
              Экспортировать файл для банка
            </MenuItem>
            <MenuItem
              isDisabled={store.collection.isEmpty}
              onClick={() => store.exportActions.exportToXlsx(bundle)}
            >
              Экспортировать акты (XLSX)
            </MenuItem>
            {currentUser.hasFeature('bundles_export_to_json') && (
              <MenuItem
                isDisabled={store.collection.isEmpty}
                onClick={() => store.exportActions.exportToJson(bundle)}
              >
                Экспортировать акты в 1С
              </MenuItem>
            )}
            {deleteMenuItem}
          </MenuList>
        </Menu>
        {store.exportActions.render()}
        {deleteConfirmDialog.render()}
      </div>
    </Flex>
  )

  let onChangeDescription = (value: string) => {
    bundle.update({ payload: { description: value.trim() }, optimistic: true })
    setIsAddingDescription(false)
  }
  let description = (
    <InplaceInput
      value={bundle.data.description || ''}
      placeholder="Добавить описание"
      toggleOnClick={canUpdate}
      onChange={onChangeDescription}
      onCancel={() => setIsAddingDescription(false)}
      style={styles.description}
      initialIsEditing={isAddingDescription}
      isMultiline={true}
      components={{
        Input: React.forwardRef((props, ref) => (
          <Input maxRows={5} {...props} ref={ref} />
        )),
      }}
    />
  )

  return (
    <Flex direction="column" gap=".5rem" style={{ flexGrow: 1 }}>
      {status}
      <Flex justify="space-between" gap="2rem" align="center">
        {title}
        {actions}
      </Flex>
      {description}
    </Flex>
  )
})

interface ProjectTaskActionsProps {
  task: Entity<TaskEntity>
  store: BundleStore
  contractors?: Collection<ContractorEntity>
  templates?: Collection<SubtaskTemplateEntity>
}

const ProjectTaskActions = observer(({ task, store }: ProjectTaskActionsProps) => {
  let currentUser = useUserStore()
  let [assignState, setAssignState] = useState(() => initialActionState)
  return (
    <div onClick={(e: any) => e.preventDefault()} style={{ position: 'relative' }}>
      <Menu placement="bottom-end" isLazy={true}>
        <MenuButton
          style={{ outline: 'none', display: 'block' }}
          as={IconButton}
          design="normal"
          isLoading={assignState.state === 'pending'}
        >
          <MoreIcon />
        </MenuButton>
        <MenuList style={{ minWidth: 275 }}>
          {task.data.status === 'draft' && (
            <AbilityMenuItem
              onClick={() => {
                let state = store.assignTask(task)
                if (state) setAssignState(state)
              }}
              ability={currentUser.hasAbility('tasks.update')}
            >
              Предложить исполнителю
            </AbilityMenuItem>
          )}
          <AbilityMenuItem
            ability={currentUser.hasAbility('tasks.create')}
            onClick={() => store.cloneTask(task)}
          >
            Создать копию
          </AbilityMenuItem>
          {task.data.status === 'draft' && (
            <AbilityMenuItem
              ability={currentUser.hasAbility('tasks.destroy')}
              isDisabled={!task.data.actions.includes('delete')}
              onClick={() => store.deleteTask(task)}
            >
              <span style={{ color: 'var(--color-red)' }}>Удалить черновик</span>
            </AbilityMenuItem>
          )}
        </MenuList>
      </Menu>
    </div>
  )
})

interface BundleTaskListItemProps {
  task: Entity<TaskEntity>
  store: BundleStore
  contractors?: Collection<ContractorEntity>
  templates?: Collection<SubtaskTemplateEntity>
}

const BundleTaskListItem = observer(
  ({ task, store, templates, contractors }: BundleTaskListItemProps) => {
    let bundle = store.bundle
    let actions = bundle.type === 'projects' && (
      <ProjectTaskActions task={task} store={store} />
    )
    // Remove dependency from the whole SelectableStore,
    // now rendering depends only on value of single computed observable
    let local = useLocalObservable(() => ({
      get isSelected() {
        return store.selected.isSelected(task)
      },
    }))
    let type = bundle.type
    return (
      <TaskListItem
        url={`/${type}/${bundle.id}/${type === 'bundles' ? 'acts' : 'tasks'}/${task.id}`}
        key={task.id}
        options={{
          kind: type === 'bundles' ? 'acts' : 'tasks',
          who: true,
          isSelectable: true,
          isEditable: type === 'projects',
          actionButtons: false,
        }}
        task={task}
        isSelected={local.isSelected}
        onToggleSelected={() => store.selected.toggleSelect(task)}
        templates={templates}
        contractors={contractors}
        actions={actions}
      />
    )
  }
)

const BundleMultiActions = observer(({ store }: { store: BundleStore }) => {
  let currentUser = useUserStore()
  let actions = []
  const hasAutopay = currentUser.hasFeature('bundles_autopay')
  const isMarkAsPaid = currentUser.hasFeature('mark_as_paid')
  const disallowRolesMarkPaidWithoutSms = ['manager', 'manager_with_sign', 'readonly']
  const allowMarkPaidWithoutSms = !disallowRolesMarkPaidWithoutSms.includes(
    currentUser.role as string
  )

  actions.push(
    <BundleActionButton
      ability={currentUser.hasAbility('tasks.accept')}
      store={store}
      title="Подписать акты"
      action="accept"
    />,
    hasAutopay && (
      <BundleActionButton
        ability={currentUser.hasAbility('tasks.autopay')}
        store={store}
        title="Принять и оплатить"
        action="autopay"
      />
    ),
    <BundleActionButton
      store={store}
      ability={currentUser.hasAbility(`tasks.${store.payAction}`)}
      title={payActionNames[store.payAction]}
      action={store.payAction}
    />,
    <Menu strategy="fixed" autoSelect={false}>
      <MenuButton as={Button} size="s" design="normal" style={{ transform: 'none' }}>
        <Flex gap=".5rem" align="center">
          Ещё
          <DropdownIcon style={{ marginRight: '-1rem', fill: 'var(--color-icon)' }} />
        </Flex>
      </MenuButton>
      <MenuList>
        {isMarkAsPaid && (
          <BundleActionButton
            ability={allowMarkPaidWithoutSms}
            kind="item"
            store={store}
            title="Отметить оплаченными"
            action="mark_paid_without_sms"
          />
        )}
        <BundleActionButton
          ability={currentUser.hasAbility('tasks.destroy')}
          kind="item"
          store={store}
          title={<span style={{ color: 'var(--color-red)' }}>Удалить</span>}
          action="delete"
        />
      </MenuList>
    </Menu>
  )
  return <>{actions}</>
})

interface BundleViewProps {
  bundle: Entity<ActBundleEntity> | Entity<ProjectEntity>
  contractors?: Collection<ContractorEntity>
  templates?: Collection<SubtaskTemplateEntity>
}

const BundleView = observer((props: BundleViewProps) => {
  let { bundle, contractors, templates } = props
  let history = useHistory()
  let api = useMobxApi()
  let currentUser = useUserStore()
  let signDialog = useSignDialogStore()
  let toast = useStateToast()
  let channel = usePusherChannelContext()
  let [store] = useState(
    () =>
      new BundleStore({ bundle, api, toast, currentUser, signDialog, history, channel })
  )

  usePusherSubscription('update-bundle', (event: any) => {
    if (bundle.id === event.id) {
      bundle.setData(event)
      if (bundle.data.is_loading && !event.is_loading) {
        toast.success({ title: `Акты сформированы` })
        bundle.fetch()
      }
    }
  })

  usePusherSubscription('update-task', (event: any) => {
    let task = store.collection.map[event.id]
    if (task) {
      task.setData(event)
    }
  })

  if (bundle.type === 'projects') {
    let project = bundle as Entity<ProjectEntity>
    usePusherSubscription('new-task', (event: any) => {
      api.fetch({ type: 'tasks', id: event.id }).then((task: Entity<TaskEntity>) => {
        if (task.data.projects.map[bundle.id]) project.data.tasks!.allItems.unshift(task)
      })
    })
  }

  let content
  if (store.bundle.data.is_loading) {
    content = <Placeholder>Подождите, акты формируются</Placeholder>
  } else if (store.collection.isEmpty) {
    content = (
      <Placeholder>
        {bundle.type === 'bundles' ? 'Нет актов в пачке' : 'Нет заданий в проекте'}
      </Placeholder>
    )
  } else {
    let filters = (
      <Flex gap="1.5rem">
        {bundle.type === 'projects' && (
          <FilterDropdown
            label="Статус задания"
            value={store.filtered.query?.taskStatus}
            onChange={(value) =>
              store.setFilter({ taskStatus: value as TaskStatus | undefined })
            }
            options={[
              { value: 'draft', label: 'Черновик' },
              { value: 'assigned', label: 'Предложено исполнителю' },
              { value: 'cancelled', label: 'Отменено' },
              { value: 'started', label: 'Принято исполнителем' },
              { value: 'in_progress', label: 'В работе' },
              { value: 'declined', label: 'Исполнитель отказался' },
              { value: 'done', label: 'Выполнено' },
            ]}
          />
        )}
        <FilterDropdown
          label="Статус акта"
          value={store.filtered.query?.actStatus}
          onChange={(value) =>
            store.setFilter({ actStatus: value as ActStatus | undefined })
          }
          options={[
            { value: 'accepted', label: 'Подписан всеми' },
            { value: 'not_accepted', label: 'Не подписан' },
            { value: 'accepted_by_company', label: 'Подписан компанией' },
            { value: 'accepted_by_contractor', label: 'Подписан исполнителем' },
          ]}
        />
        <FilterDropdown
          label="Статус платежа"
          value={store.filtered.query?.paymentStatus}
          onChange={(value) =>
            store.setFilter({ paymentStatus: value as PaymentStatus | undefined })
          }
          options={[
            { value: 'not_paid', label: 'Не оплачен' },
            { value: 'in_progress', label: 'Отправлен' },
            { value: 'paid', label: 'Оплачен' },
            { value: 'error', label: 'Ошибка' },
          ]}
        />
        <FilterDropdown
          label="Исполнитель"
          value={store.filtered.query?.contractor}
          onChange={(value?: number) => store.setFilter({ contractor: value })}
          options={store.contractorOptions}
          width={350}
        />
      </Flex>
    )

    let selectedCount = store.selected.selectedItems.size
    let filteredCount = store.filtered.items.length
    let selectedText, selectedAmount, actions
    if (selectedCount > 0) {
      selectedText = (
        <>
          {numForm(
            selectedCount,
            bundle.type === 'bundles' ? actWordForms : taskWordForms
          )}
          {selectedCount < filteredCount && (
            <span style={{ color: 'var(--color-secondary)' }}> из {filteredCount}</span>
          )}
        </>
      )
      selectedAmount = sumAmounts(
        Array.from(store.selected.selectedItems).map((i) => i.data.display_amount)
      )
      actions = <BundleMultiActions store={store} />
    } else {
      selectedText = numForm(
        filteredCount,
        bundle.type === 'bundles' ? actWordForms : taskWordForms
      )
      selectedAmount = sumAmounts(store.filtered.items.map((i) => i.data.display_amount))
    }
    let selectedItems = (
      <Flex align="center" style={styles.selectedItems} gap="1.5rem">
        <div style={{ lineHeight: '1.25rem' }}>
          <div style={styles.selectedText}>{selectedText}</div>
          <div style={styles.selectedAmount}>
            {filteredCount > 0 && (
              <>
                на сумму <Amount value={selectedAmount} />
              </>
            )}
          </div>
        </div>
        {actions}
      </Flex>
    )

    let renderItem = (item: Entity<TaskEntity>) => (
      <BundleTaskListItem
        key={item.id}
        task={item}
        store={store}
        contractors={contractors}
        templates={templates}
      />
    )

    let cols: { [key: string]: any } = {
      selection: { title: <SelectAllCheckbox store={store.selected} /> },
      number: { title: '№', sorting: 'number' },
      title: { title: 'Название', sorting: 'title' },
      who: { title: 'Исполнитель', sorting: 'contractor' },
      period: { title: 'Период' },
      amount: { title: 'Стоимость', sorting: 'amount' },
    }
    if (bundle.type === 'projects') {
      cols.taskStatus = { title: 'Задание', sorting: 'taskStatus' }
    }
    Object.assign(cols, {
actStatus: { title: 'Инвойс', sorting: 'actStatus' },
      paymentStatus: { title: 'Платёж', sorting: 'paymentStatus' },
    })
    if (bundle.type === 'projects') {
      Object.assign(cols, { actions: { title: '' } })
    }
    let list = store.filtered.items.length > 0 && (
      <List cols={{ selection: { width: 20 }, ...taskListCols }}>
        <SortableListHeader
          sorting={store.sorted.sorting}
          onChange={store.sorted.setSorting}
          cols={cols}
          key={0}
        />
        <AnimatedList
          key={store.filterKey}
          items={store.sorted.items}
          getId={(item) => item.id}
          renderItem={(item: any, props: any) => (
            <CollapseAnimation openValue={props.open} key={item.id}>
              {renderItem(item)}
            </CollapseAnimation>
          )}
        />
      </List>
    )

    let confirmDeleteDialog = store.confirmDeleteDialogIsOpen && (
      <ConfirmDeleteDialog store={store} />
    )

    content = (
      <>
        {filters}
        {selectedItems}
        {list}
        {confirmDeleteDialog}
        {store.multiActions.render()}
      </>
    )
  }

  return (
    <Layout smallPaddingTop>
      <BackLink to={`/${bundle.type}`}>
        {bundle.type === 'bundles' ? 'К пачкам актов' : 'К проектам'}
      </BackLink>
      <Flex gap="1.5rem" direction="column">
        <BundleHeader store={store} />
        {content}
      </Flex>
    </Layout>
  )
})

const ActBundlePage = observer(({ match }: PageProps) => {
  let id = Number(match.params.id)
  let mobxApi = useMobxApi()

  let fetchState = useMemo(() => mobxApi.fetch({ type: 'bundles', id }), [id, mobxApi])
  return (
    <ActionStateView state={fetchState}>
      {(bundle) => <BundleView bundle={bundle} />}
    </ActionStateView>
  )
})

const ProjectPage = observer(({ match }: PageProps) => {
  let id = Number(match.params.id)
  let mobxApi = useMobxApi()
  let fetchState = useMemo(() => mobxApi.fetch({ type: 'projects', id }), [id, mobxApi])
  let contractorsFetchState = useMemo(
    () => mobxApi.fetch({ type: 'contractors' }),
    [mobxApi]
  )
  let templatesFetchState = useMemo(
    () => mobxApi.fetch({ type: 'subtasks/templates' }),
    [mobxApi]
  )
  return (
    <ActionStateView state={[fetchState, contractorsFetchState, templatesFetchState]}>
      {([bundle, contractors, templates]) => (
        <BundleView bundle={bundle} contractors={contractors} templates={templates} />
      )}
    </ActionStateView>
  )
})

export { ActBundlePage, ProjectPage }
