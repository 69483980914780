import React from 'react'
import { observer } from 'mobx-react-lite'

import { useUserStore } from 'stores/context'
import { useDialogStore } from 'stores/DialogStore'
import { PaymentDetailsEditModal } from 'pages/contractors/ContractorPaymentDetails'
import { Card, Button } from 'components/ui'

const NoPaymentDetailsCard = observer(() => {
  let currentUser = useUserStore()
  let dialog = useDialogStore({ component: PaymentDetailsEditModal })
  let card = (
    <Card
      kind="error"
      title="Не указаны реквизиты"
      actions={
        <Button
          size="s"
          onTap={() => dialog.open({ contractor: currentUser.user!.data.contractor! })}
          style={{ width: '100%' }}
        >
          Указать реквизиты
        </Button>
      }
    >
      Вам необходимо указать платёжные реквизиты, чтобы вы могли получать оплату за
      выполненные работы.
    </Card>
  )
  return (
    <>
      {card}
      {dialog.render()}
    </>
  )
})

export default NoPaymentDetailsCard
