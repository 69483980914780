import React, { useEffect, useState } from 'react'

import { Flex, Field as FormRow, Input } from 'ui'

import { Observer, observer, useLocalObservable } from 'mobx-react-lite'
import { makeAutoObservable, runInAction, toJS } from 'mobx'
import { useStateToast } from 'components/ui'
import { fetchApi } from 'api'

import { List, AutoSizer } from 'react-virtualized'

import css from './styles.module.css'

import cx from 'clsx'

const SelectConstractors = observer(({ store }: any) => {
  const [loading, setLoading] = useState(true)

  const { form } = store

  const toast = useStateToast()

  const listManager = useLocalObservable(
    () =>
      new (class {
        _search: string = ''
        _selectedContractorIds = new Set<string>()
        _selectedSegmentIds = new Set<string>()

        _contractors: any[] = []
        _segments: any[] = []

        get contractorUnselectedList() {
          let result = this._contractors.slice(0)
          result = result.filter((item: any) => {
            return !this._selectedContractorIds.has(item.id)
          })
          if (this._search) {
            result = result.filter((item: any) => {
              const search = this._search.toLowerCase()
              const name = item.full_name.toLowerCase()
              return name.includes(search)
            })
          }
          return toJS(result)
        }

        get contractorSelectedList() {
          let result = this._contractors.slice(0)
          result = result.filter((item: any) => {
            return this._selectedContractorIds.has(item.id)
          })
          return toJS(result)
        }

        get contractorList() {
          return [...this.contractorSelectedList, ...this.contractorUnselectedList]
        }

        get segmentList() {
          return toJS(this._segments)
        }

        _loadContractors = async () => {
          try {
            const data = (await fetchApi({
              url: `contractors/filter`,
              data: {
                filters: [
                  {
                    name: 'status',
                    value: 'all_with_suspended',
                  },
                ],
                no_paginate: true,
                minimal: true,
              },
              method: 'POST',
            })) as any
            runInAction(() => {
              this._contractors = data
            })
          } catch (e: any) {
            toast.error({
              title: 'Не удалось загрузить список исполнителей',
              description: e.message,
            })
          }
        }

        _loadSegments = async () => {
          try {
            const data = (await fetchApi({
              url: 'segments/contractors',
            })) as any
            runInAction(() => {
              this._segments = data
            })
          } catch (e: any) {
            toast.error({
              title: 'Не удалось загрузить список сегментов',
              description: e.message,
            })
          }
        }

        constructor() {
          makeAutoObservable(this)
          this._selectedContractorIds = new Set(form.fields.constractorsIds.value)
        }

        onSegmentSelected = async (segmentId: string) => {
          try {
            const segment = this._segments.find((item: any) => item.id === segmentId)
            if (segment) {
              const { filters } = segment
              const contractorsInSegment = await fetchApi({
                url: 'contractors/filter',
                data: {
                  no_paginate: true,
                  minimal: true,
                  filters,
                },
                method: 'POST',
              })
              const ids = contractorsInSegment.map((item: any) => item.id)
              runInAction(() => {
                if (this._selectedSegmentIds.has(segmentId)) {
                  ids.forEach((id: any) => this._selectedContractorIds.delete(id))
                  this._selectedSegmentIds.delete(segmentId)
                } else {
                  ids.forEach((id: any) => this._selectedContractorIds.add(id))
                  this._selectedSegmentIds.add(segmentId)
                }
                form.fields.constractorsIds.change([...this._selectedContractorIds])
              })
            }
          } catch (e: any) {
            toast.error({
              title: 'Не удалось загрузить список исполнителей в сегменте',
              description: e.message,
            })
          }
        }

        onSearch = (search: string) => {
          this._search = search
        }

        onConstractorClick = (contractorId: string) => {
          if (this._selectedContractorIds.has(contractorId)) {
            this._selectedContractorIds.delete(contractorId)
          } else {
            this._selectedContractorIds.add(contractorId)
          }
          form.fields.constractorsIds.change([...this._selectedContractorIds])
        }
      })()
  )

  const renderContractor = (idx: number, style?: React.CSSProperties) => {
    const constractor = listManager.contractorList[idx]
    return (
      <div
        key={constractor.id}
        className={cx(css.constractorItem, {
          [css.active]: listManager._selectedContractorIds.has(constractor.id),
        })}
        style={{
          ...style,
          height: 55,
        }}
        onClick={() => listManager.onConstractorClick(constractor.id)}
      >
        <b>{constractor.full_name}</b>
        <div>{constractor.mobile_phone}</div>
        {/*<div className={css.iconRemove}>
          <Button
            variant="text"
            icon={<DeleteIcon />}
            style={{
              color: 'red',
            }}
          />
        </div>*/}
      </div>
    )
  }

  const renderSegment = (segment: any, idx: number) => (
    <Flex
      key={idx}
      align="center"
      justify="space-between"
      className={cx(css.segmentItem, {
        [css.active]: listManager._selectedSegmentIds.has(segment.id),
      })}
      onClick={() => listManager.onSegmentSelected(segment.id)}
    >
      <div>
        <span className={css.segmentItemTitle}>{segment.title}</span>
        <span className={css.segmentItemCount}>{segment.count}</span>
      </div>
    </Flex>
  )

  useEffect(() => {
    const process = async () => {
      let isOk = true
      try {
        await listManager._loadContractors()
        await listManager._loadSegments()
      } catch {
        isOk = false
      } finally {
        if (isOk) {
          setLoading(false)
        }
      }
    }
    process()
  }, [])

  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          padding: 50,
          fontSize: 18,
          height: 'calc(100vh - 400px)',
          minHeight: '500px',
          color: 'var(--color-grey-4)',
        }}
      >
        Загрузка...
      </div>
    )
  }

  return (
    <>
      <FormRow label="Поиск по исполнителям">
        <Input
          isWide
          onChange={listManager.onSearch}
          value={listManager._search}
          placeholder="Выберите исполнителя"
        />
      </FormRow>
      <Flex wrap className={css.container}>
        <div className={css.segments}>
          <Flex wrap className={css.segmentsInner}>
            {listManager.segmentList.map(renderSegment)}
          </Flex>
        </div>
        <div className={css.constractors}>
          {/* @ts-ignore */}
          <AutoSizer>
            {({ height }) => (
              <Observer>
                {() => (
                  <>
                    {/* @ts-ignore */}
                    <List
                      height={height}
                      width={277}
                      rowCount={listManager.contractorList.length}
                      rowHeight={59}
                      rowRenderer={({ index, style }) => renderContractor(index, style)}
                    />
                  </>
                )}
              </Observer>
            )}
          </AutoSizer>
        </div>
      </Flex>
    </>
  )
})

export default SelectConstractors
