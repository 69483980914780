import { rest } from 'msw'

import { json, error, paginate } from './helpers'

import { contract } from './contracts'

const contract_not_created = 'Договор не загружен'
const contract_not_signed = 'Договор не подписан'
const moi_nalog_not_connected = 'Договор не подписан'

const contractors = [
  {
    id: 35443,
    contracts: [
      {
        id: 1,
        title: 'Название',
        date: '2020-09-20',
        signed_by_contractor: false,
        signed_by_legal_entity: false,
        status: 'signed_by_all',
      },
    ],
    status: 'has_problems',
    problem_messages: [contract_not_signed],
    inn: '13371337',
    full_name: 'Констанция Константинопольская',
    name: 'Констанция Константинопольская',
    mobile_phone: '+7 (902) 880 88 88',
    kind: 'non_resident',
    added_at: '2020-09-20',
    citizenship: 'Россия',
  },
  {
    id: 130003,
    contracts: [
      {
        id: 2,
        title: 'Название',
        signed_by_contractor: true,
        signed_by_legal_entity: false,
        status: 'signed_by_contractor',
      },
    ],
    status: 'has_problems',
    problem_messages: [contract_not_signed],
    full_name: 'Константин Константинопольский',
    name: 'Константин Константинопольский',
    mobile_phone: '+7 (915) 000 11 22',
    kind: 'self_employed',
    moi_nalog: { status: 'connected' },
    added_at: '2020-09-21',
    is_suspended: true,
    citizenship: 'Россия',
  },
  {
    id: 130004,
    contracts: [
      {
        id: 3,
        title: 'Договор на оказание услуг №136',
        date: '2020-09-20',
        signed_by_contractor: false,
        signed_by_legal_entity: true,
        status: 'signed_by_legal_entity',
      },
      {
        id: 33,
        title: 'Дополнительный договор на оказание услуг №136',
        date: '2020-09-20',
        signed_by_contractor: false,
        signed_by_legal_entity: true,
        status: 'signed_by_legal_entity',
      },
    ],
    status: 'has_problems',
    problem_messages: [moi_nalog_not_connected],
    full_name: 'Аверьянов Валентин',
    name: 'Аверьянов Валентин',
    mobile_phone: '+7 (915) 000 11 22',
    kind: 'self_employed',
    moi_nalog: { status: 'not_connected' },
    citizenship: 'Италия',
  },
  {
    id: 130005,
    contracts: [
      {
        id: 4,
        title: 'Название',
        signed_by_contractor: true,
        signed_by_legal_entity: true,
        status: 'signed_by_all',
      },
    ],
    full_name: 'Верстальщик Макетов Сергеевич',
    name: 'Верстальщик Макетов Сергеевич',
    mobile_phone: '+7 (915) 000 11 22',
    kind: 'self_employed',
    status: 'has_problems',
    problem_messages: [moi_nalog_not_connected],
  },
  {
    id: 130006,
    full_name: 'Геродот Али',
    name: 'Геродот Али',
    mobile_phone: '+7 (915) 000 11 22',
    contracts: [],
    status: 'has_problems',
    problem_messages: [contract_not_created],
    kind: 'gph',
  },
  {
    id: 130007,
    full_name: 'Демцов Иерей',
    name: 'Демцов Иерей',
    mobile_phone: '+7 (915) 000 11 22',
    contracts: [],
    status: 'has_problems',
    problem_messages: [contract_not_created],
  },
  {
    id: 130008,
    full_name: 'Ежевикин Руслан',
    name: 'Ежевикин Руслан',
    mobile_phone: '+7 (915) 000 11 22',
    contracts: [],
    status: 'has_problems',
    problem_messages: [contract_not_created],
  },
  {
    id: 130009,
    full_name: 'Ёжила Фронтендер',
    name: 'Ёжила Фронтендер',
    mobile_phone: '+7 (915) 000 11 22',
    contracts: [],
    status: 'has_problems',
    problem_messages: [contract_not_created],
  },
  {
    id: 130010,
    full_name: 'Оппенгеймер',
    name: 'Оппенгеймер',
    mobile_phone: '+7 (915) 000 11 22',
    contracts: [],
    status: 'has_problems',
    problem_messages: [contract_not_created],
  },
  {
    id: 130014,
    avatar_url: null,
    full_name: 'Правдин-Семёнов Иван Николаевич',
    mobile_phone: '+7 (915) 000 11 22',
    contracts: [],
    status: 'has_problems',
    problem_messages: [contract_not_created],
  },
  {
    id: 130024,
    full_name: 'Степанов Естеслав',
    mobile_phone: '+7 (915) 000 11 22',
    contracts: [],
    status: 'has_problems',
    problem_messages: [contract_not_created],
  },
  {
    id: 130034,
    full_name: 'Уникальный Экспонат',
    mobile_phone: '+7 (915) 000 11 22',
    contracts: [],
    status: 'has_problems',
    problem_messages: [contract_not_created],
  },
  {
    id: 130044,
    full_name: 'Феофан Фёдр',
    name: 'Феофан Фёдр',
    mobile_phone: '+7 (915) 000 11 22',
    contracts: [],
    status: 'has_problems',
    problem_messages: [contract_not_created],
  },
  {
    id: 130054,
    full_name: 'Цапля Александр',
    mobile_phone: '+7 (915) 000 11 22',
    contracts: [],
    status: 'has_problems',
    problem_messages: [contract_not_created],
  },
  {
    id: 130064,
    full_name: 'Юлий Цезарь',
    mobile_phone: '+7 (915) 000 11 22',
    contracts: [],
    status: 'has_problems',
    problem_messages: [contract_not_created],
  },
  {
    id: 130074,
    full_name: 'Я Я Я',
    mobile_phone: '+7 (915) 000 11 22',
    contracts: [],
    status: 'has_problems',
    problem_messages: [contract_not_created],
  },
  {
    id: 130084,
    full_name: 'Smith Andrew',
    mobile_phone: '+7 (915) 000 11 22',
    contracts: [],
    status: 'has_problems',
    problem_messages: [contract_not_created],
  },
  {
    id: 130094,
    full_name: 'Zerro',
    mobile_phone: '+7 (915) 000 11 22',
    contracts: [],
    status: 'has_problems',
    problem_messages: [contract_not_created],
  },
  {
    id: 130104,
    full_name: 'Lincoln Abraham',
    mobile_phone: '+7 (915) 000 11 22',
    contracts: [],
    status: 'has_problems',
    problem_messages: [contract_not_created],
  },
]

const custom_fields = [
  { id: '1', value: 'Тут какой-то текст' },
  { id: '2', value: '2021-08-27' },
  { id: '3', value: '4' },
  { id: '4', value: false },
  { id: '5', value: 123.25 },
]

const card_payment_details = {
  kind: 'card',
  card: {
    masked_card_number: '1234 12xx xxxx 5555',
  },
}

const contractor = {
  id: 34353,
  name: 'Констанция Константинопольская',
  full_name: 'Константинопольская Констанция Константиновна',
  mobile_phone: '+7 (902) 880 88 88',
  payment_commission: {
    card: 'Тут комиссия 90%, потому что санкции',
    bank_account: 'Тут нет комиссия ваще',
  },
  payment_methods: ['bank_account'],
  moi_nalog: {
    status: 'not_connected',
  },
  payment_details: card_payment_details,
  id_cards: [
    {
      title: 'Паспорт гражданина РФ',
      fields: [
        {
          title: 'Дата рождения',
          value: '1991-05-01',
        },
        {
          title: 'Серия и номер',
          value: '6506 320880',
        },
        {
          title: 'Кем выдан паспорт',
          value:
            'Отделом УФМС России по Свердловской области в Ленинском районе г. Екатеринбурга',
        },
        {
          title: 'Дата выдачи',
          value: '2013-05-01',
        },
        {
          title: 'Код подразделения',
          value: '660-008',
        },
        {
          title: 'Место рождения',
          value: 'г. Екатеринбург',
        },
        {
          title: 'Адрес',
          value: 'г. Екатеринбург, ул. Сакко и Ванцетти, д 32, кв 16',
        },
      ],
    },

    {
      title: 'СТС',
      fields: [
        {
          title: 'Серия и номер',
          value: '77УТ 243401',
        },
      ],
    },

    {
      title: 'Водительское удостоверение',
      fields: [
        {
          title: 'issue_date',
          value: '2020-08-24',
        },
        {
          title: 'validity',
          value: '2020-08-24',
        },
        {
          title: 'Серия и номер',
          value: '77УТ 243401',
        },
      ],
    },
    {
      title: 'СНИЛС',
      fields: [
        {
          title: 'Номер',
          value: '123-456-789 00',
        },
      ],
    },
  ],
  id_card: {
    person_id: '688 996 666 000 888',
    birthday: '1991-05-01',
    series: '6506',
    number: '320880',
    issuer:
      'Отделом УФМС России по Свердловской области в Ленинском районе г. Екатеринбурга',
    date_of_issue: '2013-05-01',
    department: '660-008',
    place_of_birth: 'г. Екатеринбург',
    full_registration_address: 'г. Екатеринбург, ул. Сакко и Ванцетти, д 32, кв 16',
  },

  kind: 'self_employed',
  ogrnip: '320595800050771',
  email: 'station@gmail.com',
  arrival_date: '2020-08-24',
  tasks: [
    {
      id: 375540,
      title:
        'Модерация официальных групп брендов Эльдорадо, М-видео, Mastercard в социальных сетях и оперативное реагирование в интернете',
      since: '2020-08-24',
      upto: '2020-08-24',
      company: {
        id: 35443,
        name: 'Х6 решения',
        phone: 'Х6 решения',
      },
      document_number: { number: '12345' },
      amount: 12000,
      task: { status: 'done' },
      act: { status: 'not_accepted' },
      payment: { status: 'not_paid' },
    },
    {
      id: 375542,
      title: 'Test',
      since: '2020-08-24',
      upto: '2020-08-24',
      company: {
        id: 35443,
        name: 'Х6 решения',
        phone: 'Х6 решения',
      },
      document_number: { number: '12345' },
      task: { status: 'done' },
      act: { status: 'not_accepted' },
      payment: { status: 'not_paid' },
    },
  ],
  attachments: [
    { id: 123, title: 'Название документа', file_url: 'http://google.com' },
    { id: 456, title: 'Название документа 2', file_url: 'http://google.com' },
    { id: 789, title: 'Название документа 3', file_url: 'http://google.com' },
  ],
  contracts: [contract],
  documents: [],
  custom_fields,
  // custom_fields: []
  is_new: true, // npd
}

const getPage = (req: any, res: any, ctx: any) => {
  if (req.body.no_paginate) {
    return res(ctx.delay(500), ctx.json(contractors))
  } else {
    return res(ctx.delay(500), ctx.json(paginate(contractors, req.body.page)))
  }
}

export { contractor }

export default [
  rest.get('/api/company/contractors', json(contractors)),

  rest.post('/api/company/contractors/filter', getPage),

  rest.get('/api/contractor/contractors/:id', json(contractor)),
  rest.put(
    '/api/contractor/contractors/:id/payment_details',
    // json({ payment_details: card_payment_details })
    error(500, { error: { message: 'Проверьте данные' } })
  ),

  rest.get('/api/company/contractors/:id', json(contractor)),
  rest.put('/api/company/contractors/:id/suspend', json({ is_suspended: true })),
  rest.put('/api/company/contractors/:id/unsuspend', json({ is_suspended: false })),

  rest.put(
    '/api/contractor/contractors/:id/connect_moi_nalog',
    json({ moi_nalog: { status: 'request_sent' } })
  ),

  rest.post('/api/company/contractors/export_to_xlsx', (req: any, res: any, ctx: any) =>
    res(
      ctx.delay(500),
      ctx.set(
        'content-disposition',
        `attachment; filename="CONTRACTORS.xlsx"; filename*=UTF-8''ORIGINAL.xlsx`
      ),
      ctx.body('CONTENT')
    )
  ),
]
