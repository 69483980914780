import React from 'react'

import { Col } from 'components/ui'
import { H3 } from 'ui'

import { observer } from 'mobx-react-lite'

import s from './style.module.css'

import cl from 'clsx'

interface ContractorRisksProps {
  blacklisted: boolean
}

const ContractorStopListRisks = observer((props: ContractorRisksProps) => {
  const { blacklisted } = props
  return (
    <Col gap="var(--gap-m)">
      <H3>Проверка по стоп-листу</H3>
      <Col style={{ marginTop: '1rem' }} align="start">
        <div className={cl(s.stopListLabel, blacklisted && s.stopListLabelWarning)}>
          {blacklisted ? 'Бывший работник' : 'Проверка пройдена'}
        </div>
      </Col>
    </Col>
  )
})

export default ContractorStopListRisks
