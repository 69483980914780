import React from 'react'
import { createPortal } from 'react-dom'
import { makeAutoObservable } from 'mobx'
import { observer } from 'mobx-react-lite'
//@ts-ignore
import { useStyles } from 'floral'

import { MobxApi, Collection } from 'mobx/mobx'
import { Attachment } from 'entities'
import { useMediaContext } from 'App/MediaContext'
import { Flex, IconButton, Reveal } from 'components/ui'
import { DragnDropButton, FileThumb } from 'components'

import { ReactComponent as DeleteGreyIcon } from 'assets/delete-grey.svg'

class AttachmentsStore {
  constructor(
    api: MobxApi,
    initialAttachments: Collection<Attachment> | undefined = undefined
  ) {
    this.attachments = initialAttachments || new Collection({ api, type: 'attachments' })
    makeAutoObservable(this)
  }

  attachments: Collection<Attachment>

  upload(files: File[]) {
    // remove files with same name
    let names = files.map(({ name }) => name)
    this.attachments.items.forEach((att) => {
      if (names.includes(att.data.title)) this.delete(att.id)
    })
    files.forEach((file) => {
      let payload = new FormData()
      payload.append('file', file)
      payload.append('title', file.name)
      this.attachments.create({
        optimistic: true,
        payload,
        data: { file, title: file.name },
      })
      // TODO handle create errors
    })
  }

  delete(id: number) {
    this.attachments.delete({ id, optimistic: true })
  }

  get isLoading() {
    return this.attachments.items.some((att) => !att.isCreated)
  }

  get isEmpty() {
    return this.attachments.items.length === 0
  }

  get ids() {
    return this.attachments.items.map(({ id }) => id)
  }
}

interface AttachmentsZoneProps {
  store: AttachmentsStore
  title: string
  multiple?: boolean
  accept?: string
}

let attachmentsZoneStyles = (
  props: AttachmentsZoneProps,
  { isMobile }: { isMobile: boolean }
) => ({
  root: {
    width: isMobile ? 'auto' : '50vw',
  },
  files: {
    display: 'grid',
    gridTemplateColumns: isMobile ? '1fr' : 'repeat(3, 1fr)',
    gridColumnGap: 8,
    gridRowGap: 24,
  },
})

const AttachmentsZone = observer((props: AttachmentsZoneProps) => {
  let { store, title, multiple, accept } = props
  let { isMobile } = useMediaContext()
  let styles = useStyles(attachmentsZoneStyles, [props, { isMobile }])
  return (
    <Flex direction="column" gap="2.5rem" style={styles.root}>
      <DragnDropButton
        accept={accept}
        multiple={multiple}
        onChange={(files: File[]) => store.upload(files)}
        files={[]}
        title={title}
      />
      <div style={styles.files}>
        {store.attachments.items.map((att) => {
          // TODO error
          let error = undefined
          return (
            <Flex direction="row" gap="1rem" key={att.id}>
              <FileThumb
                animated={!att.isCreated}
                data={{ title: att.data.title, url: att.data.file_url }}
                error={error}
              />
              {att.createState.state !== 'pending' && (
                <IconButton
                  onTap={() => store.delete(att.id)}
                  style={{ flexShrink: 0 }}
                  tooltip="Удалить"
                >
                  <DeleteGreyIcon />
                </IconButton>
              )}
            </Flex>
          )
        })}
      </div>
    </Flex>
  )
})

const dropShadeStyles = () => ({
  root: {
    position: 'fixed',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    pointerEvents: 'none',
    zIndex: 'var(--zi-blur)',
  },
  content: {
    fontSize: '5rem',
    fontWeight: '900',
    color: '#5317fe',
    animationDuration: '12s',
    animationIterationCount: 1,
    opacity: 0.4,
  },
})

const DropShade = (props: { isDragOver: boolean }) => {
  let { isDragOver } = props
  let styles = useStyles(dropShadeStyles, [props])
  if (!isDragOver) return null
  return createPortal(
    <div style={styles.root}>
      <Reveal animation="scale" style={styles.content}>
        Ловлю
      </Reveal>
    </div>,
    window.document.body
  )
}

// @ts-ignore
AttachmentsZone.defaultProps = {
  accept: 'image/*,.pdf',
}

export { AttachmentsStore, AttachmentsZone, DropShade }
