import React, { useEffect, useState } from 'react'
import { useHarmonicIntervalFn } from 'react-use'

import {
  Text,
  Link,
  Appear,
  Row,
  Col,
  Notification,
  //@ts-ignore
  CodeInput,
  Spinner,
  Field,
  BackLink,
} from 'ui'

import numForm from 'utils/numForm'
import { register, verifyRegistration } from '../requests'

const FIELDS = 4
const SMS_RESEND_TIME = 59 // seconds

interface ICodeStepProps {
  phone: string
  permalink: string
  query: Object
  onComplete: (value: any) => void
  onBack: () => void
}

const secondsWordForms = { one: 'секунду', two: 'секунды', many: 'секунд' }

const CodeStep = (props: ICodeStepProps) => {
  const { phone, permalink, query, onComplete, onBack } = props

  const [code, setCode] = useState('')
  const [resendTimer, setResendTimer] = useState(SMS_RESEND_TIME)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')

  const onChangeCode = (code: string) => {
    setCode(code)
    setError('')
    if (code.length === FIELDS) {
      setIsLoading(true)
      verifyRegistration({ code }).then(
        (user: any) => {
          localStorage.removeItem('registration_connect_to_platform_state')
          onComplete(user)
          setIsLoading(false)
        },
        (error: any) => {
          setResendTimer(SMS_RESEND_TIME)
          setCode('')
          setError(error.message)
          setIsLoading(false)
        }
      )
    }
  }

  useHarmonicIntervalFn(() => {
    setResendTimer((val) => (val > 0 ? val - 1 : val))
  }, 1000)

  const resendCode = () => {
    setCode('')
    setError('')
    register({ permalink, phone, query })
    setResendTimer(SMS_RESEND_TIME)
  }

  useEffect(() => {
    if (error) setCode('')
  }, [error])

  return (
    <Col gap="20px" align="start">
      <Appear animation={Appear.presets.slideUp}>
        <Field label="Введите код из СМС">
          <Row gap="12px">
            <CodeInput fields={FIELDS} value={code} autoFocus onChange={onChangeCode} />
            {isLoading && <Spinner fill="var(--konsol-color-primary)" size="l" />}
          </Row>
        </Field>
      </Appear>
      <Appear delay={100} animation={Appear.presets.slideUp}>
        {resendTimer ? (
          <Text color="secondary">
            Отправить повторно можно через&nbsp;
            <Text color="primary" as="span" style={{ fontVariant: 'tabular-nums' }}>
              {numForm(resendTimer, secondsWordForms)}
            </Text>
          </Text>
        ) : (
          <Link onClick={resendCode}>Отправить код повторно</Link>
        )}
      </Appear>
      {error && code.length === 0 && (
        <Appear animation={Appear.presets.scale}>
          <Notification kind="error">{error}</Notification>
        </Appear>
      )}
      <Appear delay={100} animation={Appear.presets.slideUp}>
        <BackLink onClick={onBack}>Ввести другой телефон</BackLink>
      </Appear>
    </Col>
  )
}

export default CodeStep
