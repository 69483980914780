import { fetchApi } from 'api'
import { useStateToast } from 'components/ui'
import React, { useState } from 'react'
import { Button, Modal, P1 } from 'ui-kit'
import step1 from './assets/permit1.png'
import step2 from './assets/permit2.png'
import step3 from './assets/permit3.png'
import step4 from './assets/permit4.png'

type TaxPaymentPermissionModalProps = {
  id: string
  open: boolean
  onClose: () => void
  setTaxPaymentPermission: (permission: boolean) => void
}

const TaxPaymentPermissionModal: React.FunctionComponent<
  TaxPaymentPermissionModalProps
> = ({ id, open, onClose, setTaxPaymentPermission }) => {
  const toast = useStateToast()
  const [blocked, setBlocked] = useState(false)

  const handleClick = async () => {
    setBlocked(true)
    try {
      const result = await fetchApi({
        url: `/tax_boxes/${id}/send_request`,
        method: 'PUT',
      })

      if (result.status === 'request_sent') {
        toast.error({ title: 'Не смогли подтвердить разрешение' })
      } else if (result.status === 'success') {
        toast.success({ title: 'Разрешение подтверждено' })
        setTaxPaymentPermission(true)
        onClose()
      }
    } catch (err: any) {
      toast.error({ title: 'Ошибка', description: err.message })
    }
    setBlocked(false)
  }

  return (
    <Modal open={open} title="Разрешение на оплату налогов" onClose={onClose}>
      <P1>Вам отправлен запрос в приложение «Мой Налог»</P1>
      <P1>1. Откройте приложение «Мой Налог» и перейдите в раздел «Прочее»</P1>
      <img src={step1} alt="" />
      <P1> 2. Выберите «Партнёры»</P1>
      <img src={step2} alt="" />
      <P1> 3. Найдите запрос от Платформы Restaff</P1>
      <img src={step3} alt="" />
      <P1>4. Дайте разрешение на оплату налогов</P1>
      <img src={step4} alt="" />

      <Button
        onClick={handleClick}
        blocked={blocked}
        label="Проверить"
        fullWidth
      ></Button>
    </Modal>
  )
}

export default TaxPaymentPermissionModal
