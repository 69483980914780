import NumberInput from 'components/NumberInput'
import { BaseButton } from 'components/ui'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { toFixed } from 'utils/validations'

const roundButtonStyles = ({ isDisabled }: any, { isHovered, isPressed }: any) => ({
  root: {
    width: 24,
    height: 24,
    borderRadius: '50%',
    background: '#eef0f3',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'opacity, background 150ms ease-in-out',
    cursor: isDisabled ? 'not-allowed' : 'pointer',
    opacity: isDisabled ? 0.4 : 1,
    filter: isHovered || isPressed ? 'brightness(0.95)' : 'none',
  },
})

const RoundButton = (props: React.ComponentProps<typeof BaseButton>) => (
  <BaseButton {...props} styles={[roundButtonStyles, props.styles]} />
)

interface QuantityInputProps {
  value: number
  onChange: (value: number) => void
  min?: number
  max?: number
  allowDecimal?: boolean
  style?: React.CSSProperties
}

const QuantityInput = observer(
  ({
    value,
    onChange,
    min = Number.MIN_VALUE,
    max = Number.MAX_VALUE,
    style,
  }: QuantityInputProps) => {
    const decimalLimit = 4

    const handleEnterType = (e: any) => {
      if (e.key === 'Enter') {
        e.target.blur()
      }
    }

    return (
      <div
        style={{
          ...style,
          display: 'flex',
          alignItems: 'stretch',
          justifyContent: 'stretch',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <RoundButton
            onTap={() => onChange(toFixed(Math.max(value - 1, min!), decimalLimit))}
            isDisabled={value <= min!}
            style={{ marginRight: 15 }}
          >
            −
          </RoundButton>
        </div>
        <NumberInput
          allowDecimal={true}
          decimalLimit={decimalLimit}
          value={String(value)}
          onChange={(value) => {
            onChange(Math.max(min, Math.min(parseInt(value) || 0, max)))
          }}
          onKeyDown={handleEnterType}
          styles={{ root: { flex: 1 }, input: { textAlign: 'center' } }}
        />
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <RoundButton
            onTap={() => onChange(toFixed(value + 1, decimalLimit))}
            isDisabled={value >= max!}
            style={{ marginLeft: 15 }}
          >
            +
          </RoundButton>
        </div>
      </div>
    )
  }
)

export default QuantityInput
