import React from 'react'
import { Tooltip } from '@chakra-ui/react'
//@ts-ignore
import Select, { components as selectComponents } from 'react-select'
//@ts-ignore
import CreatableSelect from 'react-select/creatable'

import { ReactComponent as ClearIcon } from '@material-design-icons/svg/round/clear.svg'
import { ReactComponent as ExpandIcon } from '@material-design-icons/svg/round/expand_more.svg'

import { useMediaContext } from 'App/MediaContext'

interface FormInputProps {
  value?: any
  onChange?: (value: any) => void
  onFocus?: () => void
  onBlur?: () => void
  isDisabled?: boolean
  isInvalid?: boolean
}

interface SelectInputProps extends FormInputProps, React.ComponentProps<Select> {
  options: Array<any>
  components?: { Option: React.ComponentType<any> }
  size?: 'l' | 'm'
  isCreatable?: boolean
  styles?: any
  [key: string]: any
}

const SelectInput = ({
  value,
  options,

  components,
  onChange,
  onFocus,
  onBlur,
  size,
  isInvalid,
  isDisabled,
  isCreatable,
  styles,
  ...restProps
}: SelectInputProps) => {
  let { isMobile } = useMediaContext()
  let resultStyles = {
    valueContainer: (base: any) => ({
      ...base,
      fontSize: size === 'l' ? '1.5rem' : '1rem',
      padding: 0,
    }),
    menu: (base: any) => ({
      ...base,
      padding: 0,
      borderRadius: '6px',
      fontSize: size === 'l' ? '1.5rem' : '1rem',
      minWidth: isMobile ? 'none' : 350,
      boxShadow: '0px 4px 20px #EEEEFA, 0px 0px 3px rgba(0,0,0,0.35)',
    }),
    group: (base: any) => ({
      ...base,
      paddingTop: 0,
      '&:not(:last-child)': {
        borderBottom: '1px solid var(--color-underline)',
      },
      '&:not(:first-child)': {
        paddingTop: '4px',
      },
    }),
    container: (base: any) => ({ ...base, fontWeight: 'normal', flex: 1 }),
    indicatorSeparator: (base: any) => ({ ...base, display: 'none' }),
    clearIndicator: (base: any) => ({ ...base, padding: 4 }),
    dropdownIndicator: (base: any) => ({ ...base, padding: 4 }),
    control: (base: any, state: any) => ({
      ...base,
      border: 'none',
      borderRadius: 0,
      background: 'transparent',
      boxShadow: 'none',
      cursor: 'pointer',
      flexWrap: 'no-wrap',
      minHeight: 30,
      borderBottom: `1px solid ${
        isInvalid
          ? 'var(--color-red)'
          : state.isFocused
          ? 'var(--color-active)'
          : 'var(--color-underline)'
      }`,
      '&:hover': {
        borderColor: isInvalid ? 'var(--color-red)' : 'var(--color-active)',
      },
    }),
    placeholder: (base: any) => ({
      ...base,
      color: 'var(--color-secondary)',
      fontWeight: size === 'l' ? 500 : 'normal',
    }),
    option: (base: any, state: any) => ({
      ...base,
      display: 'flex',
      alignItems: 'center',
      backgroundColor: state.isFocused ? 'var(--konsol-color-light-background)' : '#fff',
      color: state.isDisabled ? 'var(--color-secondary)' : 'black',
      cursor: state.isDisabled ? 'default' : 'pointer',
      borderRadius: '6px',
      padding: '10px 15px',
    }),
    menuList: (base: any) => ({
      ...base,
      padding: '10px 10px',
    }),
    ...styles,
  }
  let SelectComponent = isCreatable ? CreatableSelect : Select

  let _options = options

  // добавляем разделители, если value приходит пустым (рисуем линию)
  if (options.find((item) => !item.value)) {
    _options = [
      {
        label: '',
        options: [],
      },
    ]

    options.forEach((item) => {
      if (!item.value) {
        _options.push({
          label: '',
          options: [],
        })
      } else {
        _options[_options.length - 1].options.push(item)
      }
    })
  }

  return (
    <SelectComponent
      value={value}
      onChange={(option: any) => onChange && onChange(option)}
      onFocus={onFocus}
      onBlur={onBlur}
      options={_options}
      styles={resultStyles}
      isDisabled={isDisabled}
      components={{
        Option: (props: any) => (
          <selectComponents.Option {...props}>
            {React.createElement(components!.Option, { option: props })}
          </selectComponents.Option>
        ),
        ClearIndicator: (props: any) => (
          <Tooltip label="Удалить" placement="top">
            <ClearIcon
              {...props.innerProps}
              style={{ width: 21, height: 21, fill: 'var(--color-secondary)' }}
            />
          </Tooltip>
        ),
        DropdownIndicator: (props: any) => (
          <ExpandIcon
            {...props.innerProps}
            style={{ width: 21, height: 21, fill: 'var(--color-secondary)' }}
          />
        ),
      }}
      formatCreateLabel={(inputValue: string) => <div>Создать "{inputValue}"</div>}
      {...restProps}
    />
  )
}

SelectInput.defaultProps = {
  size: 'm',
  components: {
    Option: ({ option }: any) => option.label || null,
  },
}

export default SelectInput
