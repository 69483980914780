import React from 'react'

import { Col, H3 } from 'components/ui'
import { Entity } from 'mobx/mobx'
import { ContractorEntity } from 'entities'
import { FileThumb } from 'components'

import styles from './style.module.css'
import { observer } from 'mobx-react-lite'

interface ContractorReportsProps {
  contractor: Entity<ContractorEntity>
}

const ContractorReports = observer((props: ContractorReportsProps) => {
  const { attachments } = props.contractor.data

  return (
    <Col gap="var(--gap-m)">
      <H3>Файлы документов</H3>
      <Col style={{ marginTop: '1rem', marginLeft: '-0.4rem' }} align="start">
        {attachments.map((attachment) => (
          <FileThumb
            key={attachment.id}
            data={{ title: attachment.title, url: attachment.file_url }}
            classes={{
              root: styles.fileThumbRoot,
              img: styles.fileThumbImg,
              title: styles.fileThumbTitle,
            }}
          />
        ))}
      </Col>
    </Col>
  )
})

export default ContractorReports
