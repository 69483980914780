import { useRef, useEffect } from 'react'

const useSmoothScrollTo = (id: string) => {
  const ref = useRef<HTMLInputElement>(null)
  useEffect(() => {
    const listener = () => {
      if (ref.current && window.location.hash === id) {
        window.location.hash = ''
        ref.current.scrollIntoView({ behavior: 'smooth' })
      }
    }
    window.addEventListener('hashchange', listener, true)
    return () => {
      window.removeEventListener('hashchange', listener)
    }
  }, [])
  return {
    'id': id,
    ref
  }
}

export default useSmoothScrollTo