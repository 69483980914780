import { CompanyMemberRole } from 'entities'

const companyMemberRoles: {
  [key in CompanyMemberRole]: { name: string; description: string }
} = {
  owner: { name: 'Админ', description: 'Может всё' },
  admin: { name: 'Админ', description: 'Может всё' },
  manager: { name: 'Менеджер', description: 'Может создавать документы' },
  manager_with_sign: {
    name: 'Менеджер с правом подписи',
    description: 'Может создавать и подписывать документы',
  },
  accountant: { name: 'Бухгалтер', description: 'Может отправлять деньги' },
  accountant_with_sign: {
    name: 'Бухгалтер с правом подписи',
    description: 'Может подписывать документы и отправлять деньги',
  },
  readonly: { name: 'Только просмотр', description: 'Доступ только для просмотра' },
}

export default companyMemberRoles
