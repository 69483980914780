import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Entity } from 'mobx/MobxApi'

import { UserRegistrationEntity } from 'entities'
import { useMediaContext } from 'App/MediaContext'
import { Field, Flex, FormRow, H1, useStateToast } from 'components/ui'
import { useUserStore } from 'stores/context'
import RegistrationFormStore from 'pages/registration/store/RegistrationFormStore'

import { Appear, Button, Checkbox, Select } from 'ui'
import ExtraFieldRenderer from '../ExtraFieldRenderer'

interface RegistrationProps {
  registration: Entity<UserRegistrationEntity>
}

const RegistrationForm = observer(({ registration }: RegistrationProps) => {
  const { isMobile } = useMediaContext()
  const toast = useStateToast()
  const currentUser = useUserStore()
  const [store] = useState(
    () => new RegistrationFormStore(registration, toast, currentUser)
  )

  const { personal_data, citizenship_country_tax_residence } =
    registration.data.form_options
  const size: any = isMobile ? 'm' : 'l'

  const [sameField, setSameField] = useState(false)

  const is_russia = store.form.fields.citizenship_country_code.value === 'ru'

  let citizenship = (
    <>
      <Field field={store.form.fields.citizenship_country_code}>
        {({ inputProps, error }) => (
          <FormRow
            label="Гражданство"
            size={size}
            error={error}
            caption="Укажите гражданство"
          >
            <Select
              {...inputProps}
              size={size}
              isClearable={false}
              placeholder="Укажите страну"
              value={
                store.form.values.citizenship_country_code
                  ? store.citizenshipOptions.find(
                      ({ value }) => value === store.form.values.citizenship_country_code
                    )
                  : undefined
              }
              onChange={(option: any) => {
                const isRu = option.value === 'ru'
                if (sameField) {
                  if (isRu) {
                    setSameField(false)
                  } else {
                    if (citizenship_country_tax_residence) {
                      store.form.fields.tax_residence_country_code.change(
                        option ? option.value : undefined
                      )
                    }
                  }
                }
                if (isRu && citizenship_country_tax_residence) {
                  store.form.fields.tax_residence_country_code.change(null)
                }
                store.form.fields.citizenship_country_code.change(
                  option ? option.value : null
                )
              }}
              options={store.citizenshipOptions}
            />
          </FormRow>
        )}
      </Field>
      {citizenship_country_tax_residence && (
        <Field field={store.form.fields.tax_residence_country_code}>
          {({ inputProps, error }) => (
            <>
              <FormRow
                label="Страна налогового резидентства"
                size={size}
                error={error}
                caption={
                  !is_russia ? (
                    <>
                      <Checkbox
                        label="Совпадает со страной гражданства"
                        value={sameField}
                        onChange={() => {
                          let checked = !sameField
                          setSameField(checked)
                          if (checked) {
                            store.form.fields.tax_residence_country_code.change(
                              store.form.fields.citizenship_country_code.value
                            )
                          }
                        }}
                      />
                    </>
                  ) : (
                    'Укажите страну налогового резидентства'
                  )
                }
              >
                <Select
                  {...inputProps}
                  isDisabled={sameField}
                  size={size}
                  isClearable={false}
                  placeholder="Укажите страну"
                  value={
                    store.form.values.tax_residence_country_code
                      ? store.citizenshipOptions.find(
                          ({ value }) =>
                            value === store.form.values.tax_residence_country_code
                        )
                      : undefined
                  }
                  onChange={(option: any) =>
                    store.form.fields.tax_residence_country_code.change(
                      option ? option.value : undefined
                    )
                  }
                  options={store.citizenshipOptions.filter((item) => item.value !== 'ru')}
                />
              </FormRow>
            </>
          )}
        </Field>
      )}
    </>
  )

  return (
    <>
      <Flex direction="column" gap="4rem" style={{ maxWidth: 650 }}>
        <Flex gap="2rem" direction="column">
          <Appear delay={100} animation={Appear.presets.slideDown}>
            <H1>Укажите ваши данные</H1>
          </Appear>
          {personal_data &&
            personal_data.map((field, index: number) => {
              return (
                <Appear
                  delay={100 + 100 * (index + 1)}
                  animation={Appear.presets.slideDown}
                >
                  <ExtraFieldRenderer
                    config={field}
                    field={store.form.fields[field.name]}
                    size={size}
                  />
                </Appear>
              )
            })}
        </Flex>
        <Appear
          delay={personal_data ? personal_data.length * 100 : 0}
          animation={Appear.presets.slideDown}
        >
          <Flex gap="2rem" direction="column">
            {citizenship}
          </Flex>
        </Appear>
        <Button
          isDisabled={!store.isValid}
          isLoading={store.isLoading}
          onTap={() => store.submit()}
          style={{ alignSelf: 'end', marginBottom: '50px' }}
        >
          Далее
        </Button>
      </Flex>
      {/* <RegistrationFooter /> */}
    </>
  )
})

export default RegistrationForm
