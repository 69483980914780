import React, { useState, useEffect, useRef, forwardRef } from 'react'
import { range } from 'lodash'
// @ts-ignore
import { useStyles } from 'floral'

import '../../animations.css'
import { ReactComponent as SpinnerIcon } from './spinner.svg'
import mergeRefs from 'components/ui/mergeRefs'

interface CodeInputProps {
  value: string
  onChange: (value: string) => void
  autoFocus?: boolean
  isLoading?: boolean
  className?: string
  size?: number
}

const codeInputStyles = (props: CodeInputProps, isFocused: boolean) => ({
  root: {
    position: 'relative',
    minWidth: 200,
    width: '100%',
    display: 'flex',
    // justifyContent: 'space-around',
    alignItems: 'center',
    height: 48,
    borderRadius: 6,
    boxShadow: isFocused
      ? 'var(--konsol-focus-shadow)'
      : '0 0 0 0 var(--color-grey-1)',
    padding: '0 4px',
    transition: 'box-shadow .2s',
    background: '#F4F7F9',
  },
  input: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    border: 'none',
    padding: '0 10px',
    margin: 0,
    color: 'transparent',
    background: 'transparent',
    caretColor: 'transparent',
  },

  digit: {
    fontSize: 24,
    fontWeight: 'bold',
    width: 34,
    textAlign: 'center',
    color: 'black',
    pointerEvents: 'none',
  },
  dot: {
    margin: '0px 13px',
    backgroundColor: 'var(--color-grey-2)',
    width: 8,
    minWidth: 8,
    height: 8,
    borderRadius: '50%',
    pointerEvents: 'none',
  },
  spinner: {
    animation: 'spin 2.2s infinite linear',
    width: 24,
    height: 24,
    fill: 'var(--color-grey-2)',
    margin: '0 auto'
  },
})

const CodeInput = forwardRef((props: CodeInputProps, ref) => {
  let { value, onChange, autoFocus, isLoading, className, size = 4 } = props
  let [isFocused, setIsFocused] = useState(false)
  let styles = useStyles(codeInputStyles, [props, isFocused])
  let inputRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    if (autoFocus) inputRef.current?.focus()
  }, [])

  let content
  if (isLoading) {
    content = <SpinnerIcon style={styles.spinner} />
  } else {
    content = (
      <>
        <input
          autoFocus={autoFocus}
          ref={mergeRefs(inputRef, ref)}
          type="number"
          // @ts-ignore
          inputmode="numeric"
          pattern="[0-9]*"
          style={styles.input}
          value={props.value}
          onChange={(e) => onChange(e.target.value)}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
        />
        {range(size).map((i) =>
          value[i] ? (
            <div style={styles.digit}>{value[i]}</div>
          ) : (
            <div style={styles.dot} />
          )
        )}
      </>
    )
  }

  return (
    <div style={styles.root} className={className}>
      {content}
    </div>
  )
})

export default CodeInput
